import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { VISIBILITY_OPTIONS } from 'constants/COMMUNITY_STATUS';
import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';
import { useState } from 'react';
import { EditorView } from "@codemirror/view";

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        article: state.CsArticles.selectedArticle,
    };
}

const CSCommentEdit = connect(mapStateToProps)(function ({
    comment,
    onConfirm = function () {},
    article,
}) {
    const { CS_UPDATE_COMMENT_REQUEST } = useActions();
    const initialState = {
        body: comment.body,
        visibility: comment.visibility,
        member_id: comment.member_id,
    };
    const [updatedComment, handleFieldChange] = useInputState(initialState);
    const [description, setDescription] = useState(comment.body)
    function onSave(event) {
        event.preventDefault();
        try {
            let payload = {
                id: comment.id,
                ...updatedComment,
                body: description,
            };
            if(!!article?.region_ident){
                payload.region_ident = article.region_ident;
            }
            CS_UPDATE_COMMENT_REQUEST(payload);
            onConfirm();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section>
            <form onSubmit={onSave}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">
                                Comment
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={updatedComment.body}
                                    reExtensions={[EditorView.lineWrapping]}
                                    onChange={(value) => setDescription(value)}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={updatedComment.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <span className='text-gray'>{article?.region_ident}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Member ID</td>
                            <td>
                                <input
                                    name="member_id"
                                    value={updatedComment.member_id}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        {updatedComment !== initialState && (
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default CSCommentEdit;
