import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    drawerOpen: true,
    path: '',
    title: '',
    modalContent: null,
    modalVisible: false,
    loading: false,
    modalStyle: {},
};

function uiReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.TOGGLE_DRAWER: {
            return {
                ...state,
                drawerOpen: !state?.drawerOpen,
            };
        }
        case ACTION_TYPES.CLOSE_DRAWER: {
            return {
                ...state,
                drawerOpen: false,
            };
        }
        case ACTION_TYPES.OPEN_DRAWER: {
            return {
                ...state,
                drawerOpen: true,
            };
        }
        case ACTION_TYPES.ROUTE_CHANGE: {
            return {
                ...state,
                ...(action?.data || {}),
            };
        }
        case ACTION_TYPES.TOGGLE_MODAL: {
            return {
                ...state,
                modalVisible: !state.modalVisible,
            };
        }
        case ACTION_TYPES.SET_MODAL_CONTENT: {
            return {
                ...state,
                modalContent: action.data,
                modalStyle: {},
            };
        }
        case ACTION_TYPES.SET_MODAL_STYLE: {
            return {
                ...state,
                modalStyle: action.data,
            };
        }
        default: {
            return state;
        }
    }
}

export default uiReducer;
