import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PEMemberDetails from './MemberDetails';
import useQueryList from 'hooks/useQuery';
import SingleFilter from 'components/SingleFilter';

const { default: InformationTab } = require('components/InformationTab');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        members: state.CSMembers.members,
        page: state.CSMembers.page,
        per_page: state.CSMembers.per_page,
        total: state.CSMembers.total,
        total_pages: state.CSMembers.total_pages,
        isLoading: state.CSMembers.isLoadingMembers,
        selectedMember: state.CSMembers.selectedMember,
        brandsFilter: state.CSMembers.brandsFilter,
        brandsOptions: state.PEBrands.brandsOptions,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const CSMembers = connect(mapStateToProps)(function (props) {
    const {
        members,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedMember,
        brandsFilter,
        brandsOptions,
        selectedRegionFilter,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);

    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);
    const navigate = useNavigate();

    const {
        CS_FETCH_MEMBERS_REQUEST,
        CS_GET_MEMBER_REQUEST,
        CS_SELECT_MEMBER,
        CS_SELECT_MEMBER_BRANDS_FILTER
    } = useActions();

    function onNext() {
        CS_FETCH_MEMBERS_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
            brand: brandsFilter,
        });
    }

    function onPrev() {
        CS_FETCH_MEMBERS_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
            brand: brandsFilter,
        });
    }

    function onPageSelected(pageSelected) {
        CS_FETCH_MEMBERS_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
            brand: brandsFilter,
        });
    }
    useEffect(() => {
        CS_FETCH_MEMBERS_REQUEST({
            per_page,
            page,
            searchTerm,
            brand: brandsFilter,
        });
        // eslint-disable-next-line
    }, [brandsFilter, selectedRegionFilter]);

    function onDetailSelection(member) {
        setDetailComponent(null);
        CS_SELECT_MEMBER(member);
        if (member) {
            navigate(`/community/members/${member.id}`);
        } else {
            CS_FETCH_MEMBERS_REQUEST({
                per_page,
                page,
                searchTerm,
                brand: brandsFilter,
            });
            navigate(`/community/members`);
        }
    }

    function onPerPageSelected({ perPage }) {
        CS_FETCH_MEMBERS_REQUEST({ per_page: perPage, searchTerm, brand: brandsFilter, });
    }

    useEffect(() => {
        if (selectedMember) {
            setDetailComponent(<PEMemberDetails />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedMember]);

    useEffect(() => {
        if (id) {
            try {
                CS_GET_MEMBER_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            CS_SELECT_MEMBER(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        CS_FETCH_MEMBERS_REQUEST({searchTerm, brand: brandsFilter});
        // eslint-disable-next-line
    }, []);

    function filters() {
        return [
            <SingleFilter
                selectedValue={brandsFilter}
                options={[
                    { value: 'all', label: 'All Brands' },
                    ...brandsOptions,
                ]}
                onSelect={(value) => CS_SELECT_MEMBER_BRANDS_FILTER(value)}
            />,
        ];
    }

    function DataTable() {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Brand</th>
                            <th>User ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map((member) => (
                            <tr key={`member-${member.id}`}>
                                <td>
                                    <SelectableField
                                        text={member.name}
                                        url={`/community/members/${member.id}`}
                                    />
                                </td>
                                <td>
                                    <SelectableField
                                        text={member.email}
                                        url={`/community/members/${member.id}`}
                                    />
                                </td>
                                <td>
                                    {member.brand_ident}
                                </td>
                                <td>{member.brand_user_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <InformationTab
            sectionName="Members"
            subsectionName={selectedMember?.name}
            dataTable={<DataTable />}
            showPrograms={false}
            onDetailSelected={onDetailSelection}
            onPerPageSelected={onPerPageSelected}
            perPage={per_page}
            detailComponent={detailComponent}
            showSearchField={true}
            customFilters={filters()}
        />
    );
});

export default CSMembers;
