const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsProgramWorker } = require('../workers/vhsProgramWorker');

function* VHS_PROGRAMS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_POST_PROGRAM_REQUEST,
            ACTION_TYPES.VHS_POST_PROGRAM_EPISODE_REQUEST,
            ACTION_TYPES.VHS_FETCH_ALL_PROGRAMS_REQUEST,
            ACTION_TYPES.VHS_FETCH_PROGRAMS_REQUEST,
            ACTION_TYPES.VHS_FETCH_PROGRAM_REQUEST,
            ACTION_TYPES.VHS_UPDATE_PROGRAM_REQUEST,
            ACTION_TYPES.VHS_DEL_PROGRAM_EPISODE_REQUEST,
            ACTION_TYPES.VHS_UPDATE_PROGRAM_EPISODE_REQUEST
        ],
        vhsProgramWorker,
        apiUrl,
        ...params
    );
}

export default VHS_PROGRAMS_REQUEST;

