const { PlusIcon } = require('@heroicons/react/outline');

function TagGroupSection({
    selectedTags = [],
    tagGroup,
    onAdd = function () {},
    onRemove = function () {},
    onSelectTagGroup = function () {},
}) {
    return (
        <div className="mt-8 mb-8 flex-col card-box p-4">
            <div className="flex items-center relative">
                <span className="text-lg mr-4 uppercase">{tagGroup.name}</span>
            </div>
            <div className="mt-4 flex gap-2">
                <div className="tags-container-border tags-bg p-4 pt-2 pl-2 flex flex-wrap rounded-xl w-4/12">
                    {selectedTags
                        .filter((t) =>
                            tagGroup.tags.some((tg) => tg.id === t.id)
                        )
                        .map((tag) => (
                            <button
                                key={`added-tag-${tag.id}`}
                                className="primary-color-box rounded-full tags-border p-4 h-6 flex items-center justify-center text-white ml-2 mt-2 text-sm"
                                onClick={() => {
                                    onRemove(tag.id);
                                }}
                            >
                                {tag.name}
                            </button>
                        ))}
                </div>
                <div className="tags-container-border tags-bg p-4 pt-2 pl-2 flex flex-wrap w-8/12 rounded-xl">
                    
                    {tagGroup.tags &&
                        tagGroup.tags
                            .filter(
                                (t) =>
                                    !selectedTags.some((sel) => sel.id === t.id)
                            )
                            .map((tag) => (
                                <button
                                    key={`freetag-${tag.id}`}
                                    className="primary-color-box rounded-full tags-border p-4 h-6 flex items-center justify-center text-white ml-2 mt-2 text-sm"
                                    onClick={() => {
                                        onAdd(tag.id);
                                    }}
                                >
                                    {tag.name}
                                </button>
                            ))}
                    <button
                        className="rounded-md circle-button mt-2 ml-2 h-10 w-10 flex items-center justify-center"
                        onClick={() => {
                            onSelectTagGroup(tagGroup);
                        }}
                    >
                        <PlusIcon color="white" height={15} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TagGroupSection;
