import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Routing from 'routing';
import store from 'store';
import './fonts/SFPRODISPLAYBOLD.OTF';
import './fonts/SFPRODISPLAYMEDIUM.OTF'
import './index.css';
import reportWebVitals from './reportWebVitals';

function addScripts() {
    const e = document.createElement('script');
    e.async = 1;
    e.src = 'https://' +
    (document.location.hostname.toLowerCase() === 'muuv-admin.sebrands.com'
        ? ''
        : 'stage-') +
    'vhs.sebrands.com/vhs/scripts/boot/admin.js';
    document.head.prepend(e);
}

addScripts();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Routing />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
