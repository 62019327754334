const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');
const { default: api } = require('../api/api');

function* getLocationOptions(apiUrl) {
    const json = yield api.get(`/vhs/locations?per_page=1000`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_FETCH_ALL_LOCATION_RESPONSE,
        data: json.results,
    });
}

function* getLocations(per_page = 10, page = 1,apiUrl) {
    const json = yield api.get(`/vhs/locations?per_page=${per_page}&page=${page}`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_FETCH_LOCATIONS_RESPONSE,
        data: json,
    });
}

function* getLocation(locationID, apiUrl) {
    const json = yield api.get(`/vhs/locations/${locationID}`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_FETCH_LOCATION_RESPONSE,
        data: json,
    });
}

function* updateLocation(data, apiUrl) {
    const json = yield api.put(`/vhs/locations/${data.id}`,data, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_UPDATE_LOCATION_RESPONSE,
        data: json,
    });
}

function* getHistory(page = 1, per_page = 10, locationId, apiUrl) {
    const json = yield api.get(
        `/vhs/activities?affected_type=Location&affected_id=${locationId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_LOCATION_HISTORY_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* vhsLocationWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_LOCATION_REQUEST:
            yield getLocationOptions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_LOCATIONS_REQUEST:
            yield getLocations(action.data?.per_page, action.data?.page, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_LOCATION_REQUEST:
            yield getLocation(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_LOCATION_REQUEST:
            yield updateLocation(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_LOCATION_HISTORY_REQUEST:
            yield getHistory(
                action.data?.page,
                action.data?.per_page,
                action.data?.locationId,
                apiUrl
            )
            break;
        default:
            break;
    }
}

export default vhsLocationWorker;
