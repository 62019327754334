import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import VHSMemberDetails from './MemberDetails';
import useQueryList from 'hooks/useQuery';


const mapStateToProps = function (state) {
    return {
        members: state.vhsMembers.members,
        page: state.vhsMembers.page,
        per_page: state.vhsMembers.per_page,
        total_pages: state.vhsMembers.total_pages,
        total: state.vhsMembers.total,
        selectedMember: state.vhsMembers.selectedMember,
        isLoading: state.vhsMembers.isLoadingMembers,
    };
};

const VHSMembers = connect(mapStateToProps)(function (props) {
    const { members, page, per_page, total_pages, selectedMember, total, isLoading } =
        props;

    const {
        VHS_FETCH_MEMBERS_REQUEST,
        VHS_FETCH_MEMBER_REQUEST,
        VHS_SELECT_MEMBER,
    } = useActions();
    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);

    const navigate = useNavigate();
    const [detailComponent, setDetailComponent] = useState(null);

    const onNextPage = () => {
        VHS_FETCH_MEMBERS_REQUEST({ per_page, page: page + 1, searchTerm });
    };

    const onPrevPage = () => {
        VHS_FETCH_MEMBERS_REQUEST({ per_page, page: page - 1, searchTerm });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_MEMBERS_REQUEST({ per_page, page: pageSelected, searchTerm });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_MEMBERS_REQUEST({ per_page: perPage, searchTerm });
    };

    const onDetailSelection = (member) => {
        setDetailComponent(null);
        VHS_SELECT_MEMBER(member);
        if (member) {
            navigate(`/vhs/members/${member.id}`);
        } else {
            VHS_FETCH_MEMBERS_REQUEST({
                per_page,
                page,
                searchTerm
            });
            navigate(`/vhs/members`);
        }
    };

    useEffect(() => {
        if (selectedMember) {
            setDetailComponent(<VHSMemberDetails />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedMember]);

    useEffect(() => {
        if (id) {
            try {
                VHS_FETCH_MEMBER_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            VHS_SELECT_MEMBER(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        VHS_FETCH_MEMBERS_REQUEST({searchTerm});
        // eslint-disable-next-line
    }, []);

    const dataTable = function () {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable tableClass="" isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="email">Email</th>
                            <th>Brand</th>
                            <th>User ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map((member) => (
                            <tr key={`member-${member.id}`}>
                                <td>
                                    <SelectableField
                                        text={`${member.first_name} ${member.last_name}`}
                                        url={`/vhs/members/${member.id}`}
                                    />
                                </td>
                                <td className="email">
                                    <SelectableField
                                        text={member.email}
                                        url={`/vhs/members/${member.id}`}
                                    />
                                </td>
                                <td>
                                    {member.brand_ident}
                                </td>
                                <td>{member.brand_user_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                />
            </section>
        );
    };

    return (
        <section>
            <InformationTab
                sectionName="Members"
                subsectionName={selectedMember?.name}
                showPrograms={false}
                dataTable={dataTable()}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                onDetailSelected={onDetailSelection}
                detailComponent={detailComponent}
                showSearchField={true}
            />
        </section>
    );
});

export default VHSMembers;
