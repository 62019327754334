/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import useActions from 'hooks/useActions';
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import AnswersTable from './AnswerTable';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useQueryList from 'hooks/useQuery';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AnswerDetail from './AnswerDetail';
import {REEL_OPTIONS, SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';
import AnswerNew from './AnswerNew/Index';
import AlertModal from 'components/Modal/AlertModal';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        answers: state.CSAnswers.answers,
        isLoading: state.CSAnswers.isLoadingAnswers,
        per_page: state.CSAnswers.answersPerPage,
        page: state.CSAnswers.answersPage,
        total: state.CSAnswers.answersTotal,
        total_pages: state.CSAnswers.answersTotalPages,
        selectedAnswer: state.CSAnswers.selectedAnswer,
        sortingFilter: state.CSAnswers.answersOrder,
        reelFilter: state.CSAnswers.answersReelFilter,
        question: state.CSQuestions.selectedQuestion,
    };
}

const CSQuestionAnswers = connect(mapStateToProps)(function (props) {
    const {
        answers = [],
        isLoading,
        per_page,
        page,
        total,
        total_pages,
        selectedAnswer,
        sortingFilter,
        reelFilter,
        question,
    } = props;
    const navigate = useNavigate();
    const {
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST,
        CS_SELECT_QUESTION_ANSWER,
        CS_DELETE_ANSWER_REQUEST,
        CS_FETCH_ANSWER_REQUEST,
        CS_SELECT_QUESTION_ANSWER_ORDER_FILTER,
        CS_SELECT_QUESTION_ANSWER_REEL_FILTER,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();
    const [subtitle, setSubtitle] = useState("");
    const [showDelete, setShowDelete] = useState(true);
    const [detailComponent, setDetailComponent] = useState(null);

    const { id, answerId } = useParams();
    const location = useLocation();
    const { term: searchTerm }  = useQueryList(['term']);

    const onDetailSelection = (answer) => {
        setSubtitle(null);
        setDetailComponent(null);
        CS_SELECT_QUESTION_ANSWER(answer);
        setShowDelete(true);
        if (answer) {
            navigate(`/community/questions/${id}/answers/${answer.id}`);
        } else {
            CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
                questionId: id,
                page,
                per_page,
                searchTerm,
                sorting: sortingFilter,
                reel: reelFilter,
            });
            navigate(`/community/questions/${id}`);
        }
    };

    function onDeleteAction() {
        CS_DELETE_ANSWER_REQUEST(selectedAnswer?.id);
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
            questionId: id,
            page,
            per_page,
            searchTerm,
            sorting: sortingFilter,
            reel: reelFilter,
        });
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Answer?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    useEffect(() => {
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
            questionId: id,
            page,
            per_page,
            searchTerm,
            sorting: sortingFilter,
            reel: reelFilter,
        });
    }, []);

    useEffect(() => {
        if (selectedAnswer) {
            setSubtitle(question?.title);
            setDetailComponent(<AnswerDetail />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedAnswer]);

    useEffect(() => {
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
            questionId: id,
            page,
            per_page,
            sorting: sortingFilter,
            searchTerm,
            reel: reelFilter,
        });
    }, [sortingFilter,reelFilter]);

    useEffect(() => {
        if (id && answerId) {
            try {
                CS_FETCH_ANSWER_REQUEST(answerId);
            } catch (err) {
                console.log(err);
            }
        }
        if(location.pathname.split('/').at(-1) === 'new'){
            onAdd();
        }
         else {
            CS_SELECT_QUESTION_ANSWER(null);
            setDetailComponent(null);
        }
    }, [id,answerId]);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'Reel or Not' },
                    ...REEL_OPTIONS,
                ]}
                selectedValue={reelFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_ANSWER_REEL_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    ...SORTING_OPTIONS,
                ]}
                selectedValue={sortingFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_ANSWER_ORDER_FILTER(value)
                }
            />,
        ]
    }

    const onNextPage = () => {
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
            questionId: id,
            searchTerm,
            per_page,
            page: page + 1,
            sorting: sortingFilter,
            reel: reelFilter,
        });
    }

    const onPrevPage = () => {
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
            questionId: id,
            searchTerm,
            per_page,
            page: page - 1,
            sorting: sortingFilter,
            reel: reelFilter,
        });
    }
    const onPageSelect = (pageSelected) => {
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
            questionId: id,
            searchTerm,
            per_page,
            page: pageSelected,
            sorting: sortingFilter,
            reel: reelFilter,
        });
    }

    const onPerPageSelected = ({ perPage }) => {
        CS_FETCH_ANSWERS_PER_QUESTION_REQUEST({
            questionId: id,
            searchTerm,
            per_page: perPage,
            page: page,
            sorting: sortingFilter,
            reel: reelFilter,
        });
    }

    const onAdd = () => {
        setSubtitle('Add New Answer');
        setDetailComponent(<AnswerNew/>);
        setShowDelete(false);
    }

    const goToNew = () => {
        navigate(`answers/new`)
    }

    return (
        <section>
            <InformationTab
                sectionName="Answers"
                subsectionName={subtitle}
                customFilters={filters()}
                dataTable={
                    <div>
                        <TableResults results={total} addTitle="Add New Answer" onAdd={goToNew}/>
                        <AnswersTable
                            onDetailSelection={onDetailSelection}
                            answers={answers}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </div>
                }
                showPrograms={false}
                showSearchField={true}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                detailComponent={detailComponent}
                onDetailSelected={onDetailSelection}
                onDeleteDetail={onDeleteDetail}
                deleteText={'Answer'}
                showDelete={showDelete}
                showBreadcrumbs={(id && answerId) || location.pathname.split('/').at(-1) === 'new' ? true : false}
            />
        </section>
    );
});

export default CSQuestionAnswers;
