import api from '../api/api';

const { put } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* getMember(id) {
    const json = yield api.get(`/referrals/members/${id}`);
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_MEMBER_RESPONSE,
            data: json,
        });
    }
}

function* getMembers(per_page = 10, page = 1, selectedProgram = 'all', searchTerm = '') {
    let url = `/referrals/members?per_page=${per_page}&page=${page}`;
    if (selectedProgram !== 'all') {
        url += `&programs=${selectedProgram}`;
    }
    if(!!searchTerm){
        url += `&term=${searchTerm}`;
    }
    const json = yield api.get(url);
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_MEMBERS_RESPONSE,
            data: {
                list: json.results || [],
                total: json.total,
                total_pages: json.total_pages,
                page: json.page,
                per_page: json.per_page,
            },
        });
    }
}

function* updateMember(data) {
    const { id } = data;
    const json = yield api
        .put('/referrals/members/' + id, data)
        .then((res) => res);
    if (json) {
        yield put({
            type: ACTION_TYPES.UPDATE_MEMBERS_RESPONSE,
            data: json,
        });
    }
}

function* getActivity(uuid, page = 1, perPage = 10) {
    const json = yield api.get(
        `/referrals/track_events?uuid=${uuid}&page=${page}&per_page=${perPage}`
    );
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_MEMBER_ACTIVITY_RESPONSE,
            data: {
                list: json.results || [],
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getConversions(memberId, page = 1, perPage = 10) {
    const json = yield api.get(
        `/referrals/conversions?referring_member_id=${memberId}&page=${page}&per_page=${perPage}`
    );
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_MEMBER_CONVERSIONS_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* updateConversion(conversion, newStatus) {
    const { id } = conversion;
    const json = yield api.put('/referrals/conversions/' + id, {
        status: newStatus,
    });
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.UPDATE_MEMBER_CONVERSION_RESPONSE,
            data: json,
        });
    }
}

function* getHistory(memberId, page = 1, per_page = 10) {
    const json = yield api.get(
        `/referrals/activities?affected_type=Member&affected_id=${memberId}&page=${page}&per_page=${per_page}`
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_MEMBER_HISTORY_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* membersWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MEMBER_REQUEST:
            yield getMember(action.data);
            break;
        case ACTION_TYPES.FETCH_MEMBERS_REQUEST:
            yield getMembers(
                action.data.per_page,
                action.data.page,
                action.data.selectedProgram,
                action.data.searchTerm
            );
            break;
        case ACTION_TYPES.UPDATE_MEMBERS_REQUEST: {
            yield updateMember(action.data);
            break;
        }
        case ACTION_TYPES.FETCH_MEMBER_ACTIVITY_REQUEST: {
            yield getActivity(
                action.data.uuid,
                action.data.page,
                action.data.perPage
            );
            break;
        }
        case ACTION_TYPES.FETCH_MEMBER_CONVERSIONS_REQUEST: {
            yield getConversions(
                action.data.memberId,
                action.data.page,
                action.data.perPage
            );
            break;
        }
        case ACTION_TYPES.UPDATE_MEMBER_CONVERSION_REQUEST: {
            yield updateConversion(
                action.data.conversion,
                action.data.newStatus
            );
            break;
        }
        case ACTION_TYPES.FETCH_MEMBER_HISTORY_REQUEST: {
            yield getHistory(
                action.data.memberId,
                action.data.page,
                action.data.per_page
            );
            break;
        }
        default:
            break;
    }
}

export default membersWorker;
