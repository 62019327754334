import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import csCommentsWorker from '../workers/csCommentsWorker.js';

function* CS_COMMENTS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST,
            ACTION_TYPES.CS_FETCH_COMMENT_REQUEST,
            ACTION_TYPES.CS_DELETE_COMMENT_REQUEST,
            ACTION_TYPES.CS_UPDATE_COMMENT_REQUEST,
            ACTION_TYPES.CS_POST_COMMENT_REQUEST,
            ACTION_TYPES.CS_FETCH_COMMENT_FLAGS_REQUEST,
            ACTION_TYPES.CS_UPDATE_COMMENT_FLAGS_REASON_REQUEST,
            ACTION_TYPES.CS_FETCH_COMMENT_FILES_REQUEST,
            ACTION_TYPES.CS_POST_COMMENT_FILE_REQUEST,
            ACTION_TYPES.CS_DELETE_COMMENT_FILE_REQUEST,
            ACTION_TYPES.CS_ASSIGN_COMMENT_ASSET_REQUEST,
            ACTION_TYPES.CS_UNASSIGN_COMMENT_ASSET_REQUEST,
            ACTION_TYPES.CS_FETCH_COMMENT_TRANSLATIONS_REQUEST,
            ACTION_TYPES.CS_POST_COMMENT_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_DELETE_COMMENT_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_UPDATE_COMMENT_TRANSLATION_REQUEST,
        ],
        csCommentsWorker,
        apiUrl,
        ...params
    );
}

export default CS_COMMENTS_REQUEST;
