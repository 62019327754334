import conversionsWorker from '../workers/conversionsWorker';

const { takeEvery } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* CONVERSIONS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.FETCH_CONVERSIONS_REQUEST,
            ACTION_TYPES.FETCH_CONVERSION_REQUEST,
            ACTION_TYPES.UPDATE_CONVERSIONS_REQUEST,
            ACTION_TYPES.FETCH_CONVERSION_ACTIVITY_REQUEST,
            ACTION_TYPES.FETCH_CONVERSION_ACTIVITY_REQUEST,
            ACTION_TYPES.FETCH_CONVERSIONS_CHART_REQUEST,
            ACTION_TYPES.POST_CONVERSION_REQUEST,
        ],
        conversionsWorker,
        apiUrl,
        ...params
    );
}

export default CONVERSIONS_REQUEST;
