import { useReducer } from 'react';

function reducer(state, action) {
    switch (action.type) {
        case 'balance': {
            return {
                ...state,
                balance: action.payload,
            };
        }
        case 'program_id': {
            return {
                ...state,
                program_id: action.payload,
            };
        }
        case 'is_enabled': {
            return {
                ...state,
                is_enabled: action.payload,
            };
        }
        case 'share_code': {
            return {
                ...state,
                share_code: action.payload,
            };
        }
        default:
            return state;
    }
}

function MemberEdit({ member, programOptions, onSave }) {
    const initialState = {
        balance: member.balance,
        program_id: member.program_id,
        is_enabled: member.is_enabled,
        share_code: member.share_code,
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    const onFieldChange = (event) => {
        dispatch({ type: event.target.name, payload: event.target.value });
    };

    const saveChanges = (event) => {
        event.preventDefault();
        onSave({
            id: member.id,
            ...state,
        });
    };

    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Balance</td>
                            <td>
                                <input
                                    name="balance"
                                    value={state.balance}
                                    onChange={onFieldChange}
                                    required
                                    type="number"
                                    step="0.01"
                                    min="0"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Program</td>
                            <td>
                                <select
                                    name="program_id"
                                    value={state.program_id}
                                    onChange={onFieldChange}
                                    required
                                >
                                    {programOptions.map((option) => (
                                        <option
                                            key={`credit-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Enabled</td>
                            <td>
                                <select
                                    value={state.is_enabled}
                                    onChange={onFieldChange}
                                    name="is_enabled"
                                    required
                                >
                                    <option value={true}>Enabled</option>
                                    <option value={false}>Disabled</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Share Code</td>
                            <td>
                                <input
                                    required
                                    name="share_code"
                                    value={state.share_code}
                                    onChange={onFieldChange}
                                />
                                <div>
                                    (Warning: Changing this may cause links to
                                    break)
                                </div>
                            </td>
                        </tr>
                        {initialState !== state &&
                        <tr>
                            <td colSpan="2">
                            <button type="submit" className="mt-4 button save-changes-button">
                                Save Changes
                            </button>
                        </td>
                        </tr>
                    }
                    </tbody>
                </table>
               
            </form>
        </section>
    );
}

export default MemberEdit;
