import {
    CheckCircleIcon,
    PencilIcon,
    XCircleIcon,
} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';

function EditableSection({
    title,
    value = '',
    className = '',
    isArea = false,
    isSaving,
    note,
    placeholder = '',
    fieldClass = '',
    onSave = function () {},
}) {
    const [localValue, setLocalValue] = useState(value);
    const [editing, setEditing] = useState(false);

    const onEdit = () => {
        setEditing(true);
    };

    const onCancel = () => {
        setLocalValue(value || '');
        setEditing(false);
    };

    const onChange = (event) => {
        setLocalValue(event.target.value);
    };

    const onConfirm = () => {
        setEditing(false);
        if (localValue !== value) {
            onSave(localValue);
        }
    };

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <div className={className}>
            <div className="flex justify-between mb-4 items-center">
                <span>{title}</span>
                <div className="flex">
                    {!isSaving && !editing && (
                        <span
                            className="h-8 w-8 flex items-center justify-center bg-black br-4 rounded opacity-75 cursor-pointer"
                            onClick={onEdit}
                        >
                            <PencilIcon fill="#FFFFFF" className="h-6 w-6" />
                        </span>
                    )}
                    {!isSaving && editing && (
                        <span
                            className="h-8 w-8 flex items-center justify-center bg-black br-4 rounded opacity-75 cursor-pointer"
                            onClick={onCancel}
                        >
                            <XCircleIcon fill="#FFFFFF" className="h-6 w-6" />
                        </span>
                    )}
                    {!isSaving && editing && (
                        <span
                            className="h-8 w-8 flex items-center justify-center bg-black br-4 rounded ml-2 opacity-75 cursor-pointer"
                            onClick={onCancel}
                        >
                            <CheckCircleIcon
                                fill="#FFFFFF"
                                className="h-6 w-6"
                                onClick={onConfirm}
                            />
                        </span>
                    )}
                    {isSaving && (
                        <span className="h-8 pl-4 pr-4 flex items-center justify-center bg-black br-4 rounded ml-2 text-white opacity-75">
                            Saving...
                        </span>
                    )}
                </div>
            </div>
            {!isArea && (
                <input
                    disabled={!editing}
                    value={localValue}
                    onChange={onChange}
                    className={`rounded-none border-solid border-2 border-black w-full ${
                        editing ? 'bg-white' : 'bg-gray-300'
                    }`}
                />
            )}
            {isArea && (
                <textarea
                    rows="5"
                    disabled={!editing}
                    value={localValue}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={`p-2 rounded-none border-solid border-2 border-black w-full resize-none ${fieldClass} ${
                        editing ? 'bg-white' : 'bg-gray-300'
                    }`}
                />
            )}
            {note && <div className="text-xs">{note}</div>}
        </div>
    );
}

export default EditableSection;
