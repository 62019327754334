const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');
const { default: api } = require('../api/api');

function* getProgramOptions(apiUrl) {
    const json = yield api.get(`/vhs/programs?per_page=1000`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_FETCH_ALL_PROGRAMS_RESPONSE,
        data: json.results,
    });
}

function* getPrograms(per_page = 10, page = 1, apiUrl) {
    const json = yield api.get(`/vhs/programs?per_page=${per_page}&page=${page}`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_FETCH_PROGRAMS_RESPONSE,
        data: json,
    });
}

function* getProgram(programID, apiUrl) {
    const json = yield api.get(`/vhs/programs/${programID}`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_FETCH_PROGRAM_RESPONSE,
        data: json,
    });
}

function* postProgram(program, apiUrl) {
    const json = yield api.post('/vhs/programs', program, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_POST_PROGRAM_RESPONSE,
            data: json,
        });
    }
}

function* postProgramEpisode(data, apiUrl) {
    const { program, episode } = data;
    const body = {
        playlist_id: program.playlist_id,
        episode_id: episode?.id ?? null
    };

    const json = yield api.post('/vhs/playlist_episodes', body, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_POST_PROGRAM_EPISODE_RESPONSE,
            data: { program, episode, progEp: json },
        });
    }
}

function* updateProgram(data, apiUrl) {
    const json = yield api.put(`/vhs/programs/${data.id}`, data, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_UPDATE_PROGRAM_RESPONSE,
        data: json,
    });
}

function* delProgEpisode(data, apiUrl) {
    yield api.del(`/vhs/playlist_episodes/${data.entry.id}`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_DEL_PROGRAM_EPISODE_RESPONSE,
        data: data.entry,
    });
}

function* updateProgEpisode(data, apiUrl) {
    const json = yield api.put(`/vhs/playlist_episodes/${data.entry.id}`, data.entry, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_UPDATE_PROGRAM_EPISODE_RESPONSE,
        data: { program: data.program, progEp: json },
    });
}

function* vhsProgramWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_PROGRAMS_REQUEST:
            yield getProgramOptions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_PROGRAMS_REQUEST:
            yield getPrograms(action.data?.per_page, action.data?.page, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_PROGRAM_REQUEST:
            yield getProgram(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_POST_PROGRAM_REQUEST:
            yield postProgram(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_POST_PROGRAM_EPISODE_REQUEST:
            yield postProgramEpisode(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_PROGRAM_REQUEST:
            yield updateProgram(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_DEL_PROGRAM_EPISODE_REQUEST:
            yield delProgEpisode(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_PROGRAM_EPISODE_REQUEST:
            yield updateProgEpisode(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default vhsProgramWorker;
