import { XIcon, DownloadIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
const publicURL = process.env.REACT_APP_PUBLIC_URL;
pdfjs.GlobalWorkerOptions.workerSrc = `${publicURL}/js/pdf.worker.min.js`;

function ResourceElement({ type, file }) {
    const [transcriptText, setTranscriptText] = useState('');
    const [numPages, setNumPages] = useState(null);
    function tryLoadPlayer() {
        if (window.vhs && window.vhs.is_loaded) return window.vhs.loadPlayer();
        setTimeout(tryLoadPlayer, 500);
    }
    
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (type === 'video') {
            tryLoadPlayer();
        }
        if ((type === 'transcript' || type === 'text' || type === 'document' || type === 'pdf')) {
            try {
                var xhr = new XMLHttpRequest();
                xhr.onload = function (res) {
                    setTranscriptText(res?.currentTarget?.response);
                };
                xhr.open('GET', file.media);
                xhr.send();
            } catch (err) {
                console.log(err);
            }
        }
        // eslint-disable-next-line
    }, [type, file]);

    return (
        <>
            {type === 'video' && (
                <div>
                    <div
                        className="vhs-video"
                        data-episode_id={file?.assetable_id}
                        //data-vtt="https://sebrands.zoom.us/rec/play/vtt?fid=TOWtF6i-jtZbv6-rpzZrzkJURb351dbhyT2sAPyjfWq-6ZrDi6AJXj0qRgGuuYVCsyJF28bDHXSL9Rtd.9mlrc-digfon6KNn&action=play"
                        //data-poster="https://barmethod.com/wp-content/uploads/2021/08/Shelly-TargetedSeat-thumbnail.jpg"
                        data-video={file.media}
                        //data-hls="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8"
                        data-notrack="1"
                    >
                        <img
                            alt="video"
                            //src="https://barmethod.com/wp-content/uploads/2021/08/Shelly-TargetedSeat-thumbnail.jpg"
                        />
                    </div>
                </div>
            )}
            {type === 'audio' && (
                <div
                    style={{ minHeight: '300px' }}
                    className="flex justify-center items-center"
                >
                    <audio src={file.media} controls>
                        Your browser does not support the <code>audio</code>{' '}
                        element.
                    </audio>
                </div>
            )}
            {type === 'poster' && (
                <div>
                    <img
                        src={file.media}
                        alt={file.name}
                        style={{ maxHeight: '600px', maxWidth: '800px', borderRadius: '10px', minHeight: '280px' }}
                    ></img>
                </div>
            )}
            {(type === 'transcript' || type === 'text') && (
                <div
                    style={{ maxHeight: '600px', maxWidth: '800px' }}
                    className="whitespace-pre-line overflow-auto border-solid border-4 p-4 border-black"
                >
                    {transcriptText}
                </div>
            )}
            {(type === 'document' || type === 'pdf') && (
                <div
                    style={{ maxHeight: '600px', maxWidth: '800px', overflowY: 'scroll' }}
                    className="whitespace-pre-line overflow-auto border-solid border-4 p-4 border-black"
                >
                    <Document file={file.media} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                </div>
            )}
        </>
    );
}

function ResourceModal({ type = 'video', name, url, visible, file, onClose }) {
    return visible ? (
        <section className="modal-container">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="flex-1">
                        <span className='text-lg font-bold'>
                            {type}
                        </span>
                        <span className='text-sm text-light-gray ml-4 font-light'>
                            {file?.name}
                        </span>
                    </div>
                    <a
                        href={file.media}
                        alt={file.name}
                        download
                        target='_blank' 
                        rel='noreferrer'
                    >
                        <DownloadIcon className="h-6 cursor-pointer text-black" />
                    </a>
                    <XIcon
                        className="h-6 ml-6 cursor-pointer"
                        onClick={onClose}
                    />
                </div>
                <div className="mt-4 flex items-center justify-center">
                    <ResourceElement type={type} file={file} />
                </div>
            </div>
        </section>
    ) : null;
}

export default ResourceModal;
