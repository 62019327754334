function TableButton({
    children,
    text,
    backgroundColor,
    textColor,
    className,
    action = function () {},
}) {
    return (
        <button
            className={`rounded-md p-2 h-10 flex items-center justify-center ml-2 mt-2 ${backgroundColor} ${textColor} ${className}`}
            onClick={action}
        >
            {children}
            {text}
        </button>
    );
}

export default TableButton;
