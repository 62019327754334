import ACTION_TYPES from 'constants/ACTION_TYPES';
import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';
import VHS_EVENTS_ACTIONS from 'constants/VHS_EVENTS_ACTIONS';
import moment from 'moment';
import { put } from 'redux-saga/effects';
import api from '../api/api';

function* getEpisodes(
    page = 1,
    per_page = 10,
    visibility = 'all',
    series = 'all',
    brand = 'all',
    episodeLocation = 'all',
    apiUrl,
    sorting = VHS_EPISODES_SORTING[0].value,
    searchTerm = ''
) {
    let url = `/vhs/episodes?page=${page}&per_page=${per_page}&sort=${sorting}`;
    if (visibility !== 'all') {
        url += `&visibility=${visibility}`;
    }
    if (series !== 'all') {
        url += `&series=${series}`;
    }
    if (brand !== 'all') {
        url += `&brands=${brand}`;
    }
    if (episodeLocation !== 'all') {
        url += `&locations=${episodeLocation}`;
    }
    if(!!searchTerm){
        url += `&term=${searchTerm}`;
    }

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_EPISODES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getEpisode(episodeId, apiUrl) {
    let url = `/vhs/episodes/${episodeId}`;
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_EPISODE_RESPONSE,
            data: json,
        });
    }
}

function* getEpisodeAnalytics(
    episodeId,
    page = 1,
    per_page = 10,
    actions = VHS_EVENTS_ACTIONS[0].value,
    apiUrl
) {
    let url = `/vhs/track_events?episodes=${episodeId}&page=${page}&per_page=${per_page}`;
    if (actions !== 'all') {
        url += `&actions=${actions}`;
    }

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_EPISODES_ACTIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getInstructors(brand_ident, apiUrl) {
    const json = yield api.get(
        `/vhs/instructors?brands=${brand_ident}&per_page=1000`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTOR_RESPONSE,
            data: json.results,
        });
    }
}

function* getTagGroups(brand_ident, apiUrl) {
    const json = yield api.get(
        `/vhs/tag_groups?brands=${brand_ident}&per_page=1000`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_EPISODES_TAG_GROUPS_RESPONSE,
            data: json.results,
        });
    }
}

function* postEpisode(episode, apiUrl) {
    const json = yield api.post(`/vhs/episodes`, episode, apiUrl);
    if (json) {
        yield put({ type: ACTION_TYPES.VHS_POST_EPISODE_RESPONSE, data: json });
    }
}

function* addTag(episodeId, tagId, apiUrl) {
    const json = yield api.post(
        '/vhs/content_tags',
        {
            content_type: 'Episode',
            content_id: episodeId,
            tag_id: tagId,
        },
        apiUrl
    );
    if (json) {
        yield getEpisode(episodeId, apiUrl);
    }
}

function* removeTag(contentId, tagId, apiUrl) {
    const json = yield api.del(
        `/vhs/content_tags/Episode/${contentId}/${tagId}`,
        apiUrl
    );
    if (json) {
        yield getEpisode(contentId, apiUrl);
    }
}

function* updateEpisode(episode, apiUrl) {
    const { id } = episode;
    delete episode.id;
    const json = yield api.put(`/vhs/episodes/${id}`, episode, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_UPDATE_EPISODE_RESPONSE,
            data: json,
        });
    }
}

function* deleteEspisode(episodeId, apiUrl) {
    const json = yield api.del(`/vhs/episodes/${episodeId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_SELECT_EPISODE,
            data: null,
        });
        yield getEpisodes(null, null, null, null, null, null, apiUrl);
    }
}

function* getChartData(days = 30, actions = 'all', episodeId, apiUrl) {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(Number.parseInt(days), 'd');
    const initialMillis = today.toDate().getTime();

    let url = `/vhs/chart?stop_int=${todayMillis}&start_int=${initialMillis}&episodes=${episodeId}`;

    actions !== 'all' && (url += `&actions=${actions}`);
    days === '1' && (url += '&group_by=hour');

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_EPISODES_ACTIVITY_CHART_RESPONSE,
            data: json.results,
        });
    }
}

function* getEpisodeFiles(episodeId, apiUrl) {
    const json = yield api.get(
        `/vhs/assets?assetable_type=Episode&assetable_id=${episodeId}&per_page=100`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_EPISODE_FILES_RESPONSE,
            data: json.results,
        });
    }
}

function* postAsset(episodeId, brandIdent, asset_type, slot, file, done, apiUrl) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brand_ident', brandIdent);
    formData.append('assetable_id', episodeId);
    formData.append('asset_type', asset_type);
    formData.append('slot', slot);
    formData.append('assetable_type', 'Episode');
    const json = yield api.postForm('/vhs/assets',formData,apiUrl);
    if (json.status_code && ![200, 201, 202].includes(json.status_code)) {
        alert(json.message);
    } else {
        if (done) done();

        yield getEpisodeFiles(episodeId, apiUrl);
    }
}

function* postFile(episodeId, brandIdent, zoomId, done, apiUrl) {
    const json = yield api.post('/vhs/zoom/get_recording',
    {
        episode_id: episodeId,
        brand_ident: brandIdent,
        zoom_id: zoomId 
    }
    , apiUrl);
    if (json.status_code && ![200, 201, 202].includes(json.status_code)) {
        alert(json.message);
    } else {
        if (done) done()
        alert("Fetching recording files, check back a little later.");
        yield getEpisodeFiles(episodeId, apiUrl);
    }
}

function* deleteAsset(assetId, apiUrl) {
    const json = yield api.del('/vhs/assets/' + assetId, apiUrl);
    if (json) {
        console.log('file Deleted');
    }
}

function* assignAsset(episodeId, assetId, apiUrl) {
    const json = yield api.post(`/vhs/assets/${assetId}/assign`, {}, apiUrl);
    if (json) {
        yield getEpisode(episodeId, apiUrl);
    }
}

function* getHistory(episodeId, page = 1, per_page = 10,apiUrl) {
    const json = yield api.get(
        `/vhs/activities?affected_type=Episode&affected_id=${episodeId}&page=${page}&per_page=${per_page}`
    ,apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_EPISODE_HISTORY_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* vhsEpisodesWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_EPISODES_REQUEST:
            yield getEpisodes(
                action.data?.page,
                action.data?.per_page,
                action.data?.visibility,
                action.data?.series,
                action.data?.brand,
                action.data?.episodeLocation,
                apiUrl,
                action.data?.sorting,
                action.data?.searchTerm,
            );
            break;
        case ACTION_TYPES.VHS_FETCH_EPISODE_REQUEST:
            yield getEpisode(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_EPISODE_INSTRUCTORS_REQUEST:
            yield getInstructors(action.data?.brand, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_EPISODES_ACTIONS_REQUEST:
            yield getEpisodeAnalytics(
                action.data?.episodeId,
                action.data?.page,
                action.data?.per_page,
                action.data?.actions,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_POST_EPISODE_REQUEST:
            yield postEpisode(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_EPISODE_REQUEST:
            yield updateEpisode(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_EPISODES_TAG_GROUPS_REQUEST:
            yield getTagGroups(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_ADD_TAG_TO_EPISODE_REQUEST:
            yield addTag(action.data.episodeId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.VHS_REMOVE_TAG_FROM_EPISODE_REQUEST:
            yield removeTag(action.data.episodeId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.VHS_DELETE_EPISODE_REQUEST:
            yield deleteEspisode(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_EPISODES_ACTIVITY_CHART_REQUEST:
            yield getChartData(
                action.data?.period,
                action.data?.actions,
                action.data?.episodeId,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_EPISODE_FILES_REQUEST:
            yield getEpisodeFiles(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_POST_EPISODE_FILE_REQUEST:
            yield postAsset(
                action.data?.episodeId,
                action.data?.brandIdent,
                action.data?.assetType,
                action.data?.slot,
                action.data?.file,
                action.data?.done,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_POST_EPISODE_RECORDING_REQUEST:
            yield postFile(
                action.data?.episodeId,
                action.data?.brandIdent,
                action.data?.zoomId,
                action.data?.done,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_DELETE_EPISODE_FILE_REQUEST:
            yield deleteAsset(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_ASSIGN_ASSET_REQUEST:
            yield assignAsset(
                action.data?.episodeId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_EPISODE_HISTORY_REQUEST: {
            yield getHistory(
                action.data.episodeId,
                action.data.page,
                action.data.per_page,
                apiUrl
            );
            break;
        }
        default:
            break;
    }
}

export default vhsEpisodesWorker;
