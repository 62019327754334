import LoadingIcon from 'components/Icons/Loading';

function LoadingTable({
    children,
    isLoading,
    tableClass = 'table-auto w-full text-left relative',
}) {
    return (
        <div className="relative">
            {isLoading && (
                <div className="h-full w-full absolute top-0 left-0 bg-gray-200 z-10 opacity-75 flex justify-center items-center">
                    <LoadingIcon />
                </div>
            )}
            <table className={tableClass}>{children}</table>
        </div>
    );
}

export default LoadingTable;
