const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsWorker } = require('../workers/vhsWorker');

function* VHS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_REQUEST,
            ACTION_TYPES.VHS_CREATE_EPISODE_FROM_CLASS_REQUEST,
            ACTION_TYPES.VHS_CREATE_ASSET_FROM_ZOOM_REQUEST,
            ACTION_TYPES.VHS_FETCH_DASHBOARD_CHART_DATA_REQUEST,
            ACTION_TYPES.VHS_FETCH_RECENT_EPISODES_REQUEST,
        ],
        vhsWorker,
        apiUrl,
        ...params
    );
}

export default VHS_REQUEST;
