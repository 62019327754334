import InformationTab from 'components/InformationTab';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import { useNavigate } from 'react-router';
import ConversionsTable from 'routing/Referrals/Conversions/ConversionsTable';

function ProgramConversions({
    conversions,
    perPage,
    currentPage,
    total,
    totalPages,
    onPerPageSelected = function () {},
    onNext = function () {},
    onPrev = function () {},
    onPageSelected = function () {},
    onApprove = function () {},
    isLoading = false,
}) {
    const navigate = useNavigate();
    return (
        <section>
            <InformationTab
                hasChart
                sectionName="Conversions"
                showPrograms={false}
                perPage={perPage}
                onPerPageSelected={onPerPageSelected}
                showBreadcrumbs={false}
                dataTable={
                    <section>
                        <TableResults results={total} />
                        <ConversionsTable
                            conversions={conversions}
                            excludedColumns={['brand', 'program']}
                            onDetailSelected={(conversion) => {
                                navigate(
                                    '/referrals/conversions/' + conversion?.id
                                );
                            }}
                            onUpdateConversion={onApprove}
                            isLoading={isLoading}
                        />

                        <TableNavigator
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onNext={onNext}
                            onPrev={onPrev}
                            onPageSelected={onPageSelected}
                        />
                    </section>
                }
            />
        </section>
    );
}

export default ProgramConversions;
