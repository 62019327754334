/* eslint-disable react-hooks/exhaustive-deps */
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import useQueryList from 'hooks/useQuery';
import EpisodesTable from '../EpisodesTable';

function mapStateToProps(state) {
    return {
        episodes: state.vhsEpisodes.episodes,
        isLoading: state.vhsEpisodes.isLoadingEpisodes,
        per_page: state.vhsEpisodes.per_page,
        page: state.vhsEpisodes.page,
        total: state.vhsEpisodes.total,
        total_pages: state.vhsEpisodes.total_pages,
        selectedEpisode: state.vhsEpisodes.selectedEpisode,
        visibilityFilter: state.vhsEpisodes.visibilityFilter,
        seriesFilter: state.vhsEpisodes.seriesFilter,
        seriesOptions: state.vhsSeries.seriesOptions,
        locationFilter: state.vhsEpisodes.locationFilter,
        locationOptions: state.vhsLocations.locationOptions,
        brandsFilter: state.vhsEpisodes.brandsFilter,
        brandsOptions: state.vhsBrands.brandsOptions,
        instructorsFilter: state.vhsEpisodes.instructorsFilter,
        instructorsOptions: state.vhsInstructors.instructorsOptions,
        episodesOrder: state.vhsEpisodes.episodesOrder,
    };
}

const VHSEpisodesSearchMini = connect(mapStateToProps)(function (props) {
    const {
        episodes = [],
        per_page,
        page,
        total,
        total_pages,
        visibilityFilter,
        seriesFilter,
        locationFilter,
        brandsFilter,
        seriesOptions,
        locationOptions,
        episodesOrder,
        isLoading,
        episodeAddCallback = null
    } = props;

    const {
        VHS_FETCH_EPISODES_REQUEST,
        VHS_SELECT_EPISODES_VISIBILITY_FILTER,
        VHS_SELECT_EPISODES_SERIES_FILTER,
        VHS_SELECT_EPISODES_LOCATION_FILTER,
    } = useActions();

    const { term: searchTerm }  = useQueryList(['term']);

    const onNextPage = () => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page,
            page: page + 1,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page,
            page: page - 1,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page,
            page: pageSelected,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page: perPage,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    useEffect(() => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            page,
            per_page,
            sorting: episodesOrder,
            searchTerm,
        });
    }, [seriesFilter, visibilityFilter, brandsFilter, episodesOrder, locationFilter]);

    useEffect(() => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            sorting: episodesOrder,
            searchTerm
        });
    }, []);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Visibility' },
                    ...VISIBILITY_OPTIONS,
                ]}
                selectedValue={visibilityFilter}
                onSelect={(value) =>
                    VHS_SELECT_EPISODES_VISIBILITY_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Locations' },
                    ...locationOptions,
                ]}
                selectedValue={locationFilter}
                onSelect={(value) =>
                    VHS_SELECT_EPISODES_LOCATION_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Series' },
                    ...seriesOptions,
                ]}
                selectedValue={seriesFilter}
                onSelect={(value) => VHS_SELECT_EPISODES_SERIES_FILTER(value)}
            />
        ];
    }

    return (
        <section>
            <InformationTab
                sectionName="Episodes"
                customFilters={filters()}
                dataTable={
                    <div>
                        <TableResults results={total}/>
                        <EpisodesTable
                            episodes={episodes}
                            isLoading={isLoading}
                            useMiniDetails={true}
                            episodeAddCallback={episodeAddCallback}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </div>
                }
                showPrograms={false}
                subsectionName={null}
                onPerPageSelected={onPerPageSelected}
                perPage={per_page}
                detailComponent={null}
                onDeleteDetail={() => {}}
                deleteText=""
                showDelete={false}
                showSearchField={true}
                showBreadcrumbs={false}
            />
        </section>
    );
});

export default VHSEpisodesSearchMini;
