import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    online: true,
    user: null,
    services_permissions: [],
    redirect: null,
    loading: true,
    selectedRegion: null,
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_USER_REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACTION_TYPES.GET_USER_RESPONSE: {
            return {
                ...state,
                user: action.data.user,
                services_permissions: action.data.services_permissions,
                loading: false,
                redirect: null,
            };
        }
        case ACTION_TYPES.GO_LOGIN: {
            return {
                ...state,
                redirect: action.data,
            };
        }
        case ACTION_TYPES.LOGOUT: {
            return {
                ...state,
                user: null,
                redirect: null,
            };
        }
        case ACTION_TYPES.REMOVE_REDIRECT: {
            return {
                ...state,
                redirect: null,
            };
        }
        case ACTION_TYPES.SET_USER_SELECTED_REGION: {
            return {
                ...state,
                selectedRegion: action.data.region,
            };
        }
        default: {
            return state;
        }
    }
}

export default userReducer;
