import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import { useEffect, useReducer } from 'react';
import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

function reducer(state, action) {
    switch (action.type) {
        case 'name':
            return { ...state, name: action.data };
        case 'description':
            return { ...state, description: action.data };
        case 'email':
            return { ...state, email: action.data };
        case 'favorite':
            return { ...state, favorite: action.data };
        case 'visibility':
            return { ...state, visibility: action.data };
        case 'start_int':
            return { ...state, start_int: action.data };
        case 'brand_ident':
            return { ...state, brand_ident: action.data };
        default:
            return state;
    }
}

const InstructorNew = connect(mapStateToProps)(function (props) {
    const { brandsOptions } = props;
    const initialState = {
        name: '',
        avatar_url: '',
        description: '',
        email: '',
        favorite: '',
        visibility: VISIBILITY_OPTIONS[0].value,
        start_int: '',
        brand_ident: '',
    };
    const { VHS_POST_INSTRUCTOR_REQUEST } = useActions();

    const [updatedInstructor, dispatch] = useReducer(reducer, initialState);

    function onFieldChange(event) {
        dispatch({
            type: event.target.name,
            data: event.target.value,
        });
    }

    function handleMarkdownChange(name,value){
        dispatch({
            type: name,
            data: value,
        });
    }

    function onConfirmChanges(event) {
        event.preventDefault();
        try {
            const instructor = {
                ...updatedInstructor,
                start_int: new Date(updatedInstructor.start_int).getTime(),
            };
            VHS_POST_INSTRUCTOR_REQUEST(instructor);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (brandsOptions && brandsOptions.length > 0) {
            dispatch({ type: 'brand_ident', data: brandsOptions[0].value });
        }
    }, [brandsOptions]);

    return (
        <section>
            <form onSubmit={onConfirmChanges}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={updatedInstructor.name}
                                    onChange={onFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Avatar Url</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="avatar_url"
                                    value={updatedInstructor.avatar_url}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Description
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={updatedInstructor.description}
                                    onChange={(value) => handleMarkdownChange('description',value)}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Email</td>
                            <td>
                                <input
                                    name="email"
                                    value={updatedInstructor.email}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Favorite Barre Move
                            </td>
                            <td>
                                <input
                                    name="favorite"
                                    value={updatedInstructor.favorite}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={updatedInstructor.visibility}
                                    onChange={onFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            value={option.value}
                                            key={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Started</td>
                            <td>
                                <input
                                    name="start_int"
                                    value={updatedInstructor.start_int}
                                    type="datetime-local"
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={updatedInstructor.brand_ident}
                                    onChange={onFieldChange}
                                >
                                    {brandsOptions.map((brand) => (
                                        <option
                                            key={`brand-${brand.value}`}
                                            value={brand.value}
                                        >
                                            {brand.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Instructor
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default InstructorNew;
