/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import useActions from 'hooks/useActions';
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import CommentsTable from './CommentsTable';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useQueryList from 'hooks/useQuery';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CommentDetail from './CommentDetail';
import CSCommentNew from './CommentNew';
import {SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';
import AlertModal from 'components/Modal/AlertModal';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        comments: state.CSComments.commentsSelectedArticle,
        isLoading: state.CSComments.isLoadingComments,
        per_page: state.CSComments.commentsPerPage,
        page: state.CSComments.commentsPage,
        total: state.CSComments.commentsTotal,
        total_pages: state.CSComments.commentsTotalPages,
        selectedComment: state.CSComments.selectedComment,
        sortingFilter: state.CSComments.commentsOrder,
        article: state.CsArticles.selectedArticle,
    };
}

const ArticleComments = connect(mapStateToProps)(function (props) {
    const {
        comments = [],
        isLoading,
        per_page,
        page,
        total,
        total_pages,
        selectedComment,
        sortingFilter,
        article,
    } = props;
    const navigate = useNavigate();
    const {
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST,
        CS_SELECT_COMMENT,
        CS_DELETE_COMMENT_REQUEST,
        CS_FETCH_COMMENT_REQUEST,
        CS_SELECT_COMMENT_ORDER_FILTER,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const [subtitle, setSubtitle] = useState("");
    const [showDelete, setShowDelete] = useState(true);
    const [detailComponent, setDetailComponent] = useState(null);

    const { id, commentId } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);
    const location = useLocation();

    const onDetailSelection = (comment) => {
        setSubtitle(null);
        setDetailComponent(null);
        CS_SELECT_COMMENT(comment);
        setShowDelete(true);
        if (comment) {
            navigate(`/community/articles/${id}/comments/${comment.id}`);
        } else {
            CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
                articleId: id,
                page,
                per_page,
                searchTerm,
            });
            navigate(`/community/articles/${id}`);
        }
    };

    function onDeleteAction() {
        CS_DELETE_COMMENT_REQUEST(selectedComment?.id);
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
            articleId: id,
            page,
            per_page,
            searchTerm,
        });
        navigate(`/community/articles/${id}`);
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Comment?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    useEffect(() => {
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
            articleId: id,
            page,
            per_page,
            searchTerm,
            sorting: sortingFilter,
        });
    }, []);

    useEffect(() => {
        if (selectedComment) {
            setSubtitle(article?.title);
            setDetailComponent(<CommentDetail />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedComment]);

    useEffect(() => {
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
            articleId: id,
            page,
            per_page,
            sorting: sortingFilter,
            searchTerm,
        });
    }, [sortingFilter]);

    useEffect(() => {
        if (commentId) {
            try {
                CS_FETCH_COMMENT_REQUEST(commentId);
            } catch (err) {
                console.log(err);
            }
        }
        if(location.pathname.split('/').at(-1) === 'new'){
            onAdd();
        }else {
            CS_SELECT_COMMENT(null);
            setDetailComponent(null);
        }
    }, [commentId]);

    function filters() {
        return [
            <SingleFilter
                options={[
                    ...SORTING_OPTIONS,
                ]}
                selectedValue={sortingFilter}
                onSelect={(value) =>
                    CS_SELECT_COMMENT_ORDER_FILTER(value)
                }
            />,
        ]
    }

    const onNextPage = () => {
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
            articleId: id,
            searchTerm,
            per_page,
            page: page + 1,
            sorting: sortingFilter,
        });
    }

    const onPrevPage = () => {
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
            articleId: id,
            searchTerm,
            per_page,
            page: page - 1,
            sorting: sortingFilter,
        });
    }
    const onPageSelect = (pageSelected) => {
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
            articleId: id,
            searchTerm,
            per_page,
            page: pageSelected,
            sorting: sortingFilter,
        });
    }

    const onPerPageSelected = ({ perPage }) => {
        CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST({
            articleId: id,
            searchTerm,
            per_page: perPage,
            page: page,
            sorting: sortingFilter,
        });
    }

    const onAdd = () => {
        setSubtitle('Add New Comment');
        setDetailComponent(<CSCommentNew/>);
        setShowDelete(false);
    }

    const goToAdd = () => {
        navigate('comments/new')
    }

    return (
        <section>
            <InformationTab
                sectionName="Comments"
                subsectionName={subtitle}
                customFilters={filters()}
                dataTable={
                    <div>
                        <TableResults results={total} addTitle="Add New Comment" onAdd={goToAdd}/>
                        <CommentsTable
                            onDetailSelection={onDetailSelection}
                            comments={comments}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </div>
                }
                showPrograms={false}
                showSearchField={true}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                detailComponent={detailComponent}
                onDeleteDetail={onDeleteDetail}
                onDetailSelected={onDetailSelection}
                deleteText={'Comment'}
                showDelete={showDelete}
                showBreadcrumbs={(id && commentId) || location.pathname.split('/').at(-1) === 'new' ? true : false}
            />
        </section>
    );
});

export default ArticleComments;
