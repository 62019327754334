import ACTION_TYPES from 'constants/ACTION_TYPES';
import {SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';


const initialState = {
    selectedQuestion: null,
    isLoadingQuestions: false,
    questionsList: [],
    questionsTotal: 0,
    questionsTotalPages: 1,
    questionsPage: 1,
    questionsPerPage: 10,
    answeredFilter: 'all',
    featuredFilter: 'all',
    trendingFilter: 'all',
    reelFilter: 'all',
    brandsFilter: 'all',
    questionsListOrder: SORTING_OPTIONS[0].value,
    flagsSelectedQuestion: [],
    isLoadingFlags: false,
    flagsPerPage: 10,
    flagsPage: 1,
    flagsTotal: 0,
    flagsTotalPages: 1,
    flagsOrder: SORTING_OPTIONS[0].value,
    flagsStatus: 'all',
    tagGroups: [],
    files: [],
    isLoadingFiles: false,
    translations: [],
    translations_page: 1,
    translations_per_page: 10,
    translations_total: 0,
    translations_total_pages: 1,
    translations_is_loading: false,
    selectedTranslation: null,
};

function QuestionsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_QUESTION_REQUEST: {
            return {
                ...state,
                isLoadingQuestions: true,
            };
        }
        case ACTION_TYPES.CS_QUESTION_RESPONSE: {
            return {
                ...state,
                isLoadingQuestions: false,
                questionsList: action.data.list,
                questionsTotal: action.data.total,
                questionsTotalPages: action.data.total_pages,
                questionsPage: action.data.page,
                questionsPerPage: action.data.per_page,
            };
        }
        case ACTION_TYPES.CS_SELECT_QUESTION: {
            return {
                ...state,
                selectedQuestion: action.data,
            };
        }
        case ACTION_TYPES.CS_FETCH_QUESTION_RESPONSE: {
            return {
                ...state,
                selectedQuestion: action.data,
            }
        }
        case ACTION_TYPES.CS_UPDATE_QUESTION_RESPONSE: {
            return {
                ...state,
                selectedQuestion: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_ANSWERED_FILTER: {
            return{
                ...state,
                questionsPage:0,
                answeredFilter:action.data
            }
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_FEATURED_FILTER: {
            return{
                ...state,
                questionsPage:0,
                featuredFilter:action.data
            }
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_TRENDING_FILTER: {
            return{
                ...state,
                questionsPage:0,
                trendingFilter:action.data
            }
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_REEL_FILTER: {
            return{
                ...state,
                questionsPage:0,
                reelFilter:action.data
            }
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_BRANDS_FILTER: {
            return{
                ...state,
                questionsPage:0,
                brandsFilter:action.data
            }
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_ORDER_FILTER: {
            return{
                ...state,
                questionsListOrder:action.data
            }
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_FLAGS_STATUS_FILTER: {
            return {
                ...state,
                questionsPage:0,
                flagsStatus: action.data,
            }
        }
        case ACTION_TYPES.CS_SELECT_QUESTION_FLAGS_ORDER_FILTER: {
            return {
                ...state,
                flagsOrder: action.data,
            }
        }
        case ACTION_TYPES.CS_FETCH_QUESTION_FLAGS_RESPONSE:{
            return {
                ...state,
                isLoadingFlags: false,
                flagsSelectedQuestion: action.data.list,
                flagsTotal: action.data.total,
                flagsTotalPages: action.data.total_pages,
                flagsPage: action.data.page,
                flagsPerPage: action.data.per_page,
            };
        }
        case ACTION_TYPES.CS_UPDATE_QUESTION_FLAGS_REASON_RESPONSE: {
            return {
                ...state,
                flagsSelectedQuestion: [...state.flagsSelectedQuestion, ...action?.data],
            };
        }
        case ACTION_TYPES.CS_FETCH_QUESTION_TAG_GROUPS_RESPONSE: {
            return {
                ...state,
                tagGroups: action.data,
            };
        }
        case ACTION_TYPES.CS_FETCH_QUESTION_FILES_REQUEST: {
            return {
                ...state,
                isLoadingFiles: true,
            };
        }
        case ACTION_TYPES.CS_FETCH_QUESTION_FILES_RESPONSE: {
            return {
                ...state,
                files: action.data,
                isLoadingFiles: false,
            };
        }
        case ACTION_TYPES.CS_FETCH_QUESTION_TRANSLATIONS_REQUEST: {
            return {
                ...state,
                translations_is_loading: true,
            };
        }
        case ACTION_TYPES.CS_FETCH_QUESTION_TRANSLATIONS_RESPONSE: {
            return {
                ...state,
                translations: action.data.list,
                translations_page: action.data.page,
                translations_per_page: action.data.per_page,
                translations_total: action.data.total,
                translations_total_pages: action.data.total_pages,
                translations_is_loading: false,
            };
        }
        case ACTION_TYPES.CS_POST_QUESTION_TRANSLATION_RESPONSE:
        case ACTION_TYPES.CS_UPDATE_QUESTION_TRANSLATION_RESPONSE:
        case ACTION_TYPES.CS_SELECT_QUESTION_TRANSLATION: {
            return {
                ...state,
                selectedTranslation: action.data,
            };
        }
        case ACTION_TYPES.CS_POST_QUESTION_RESPONSE:{
            return {
                ...state,
                selectedQuestion: action.data,
            };
        }
        default:
            return state;
    }
}

export default QuestionsReducer