import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import VHS_EVENTS_ACTIONS from 'constants/VHS_EVENTS_ACTIONS';
import useActions from 'hooks/useActions';
import useVhsAnalyticsChartData from 'hooks/useVhsAnalyticsChartData';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AnalyticsDetail from './AnalyticsDetail';
import AnalyticsTable from './AnalyticsTable';
import { DownloadIcon } from '@heroicons/react/outline';
import AnalyticsExport from './AnalyticsExport';

function mapStateToProps(state) {
    return {
        activities: state.vhsAnalytics.activities,
        isLoadingActivities: state.vhsAnalytics.isLoadingActivities,
        page: state.vhsAnalytics.page,
        per_page: state.vhsAnalytics.per_page,
        total_pages: state.vhsAnalytics.total_pages,
        total: state.vhsAnalytics.total,
        actionsFilter: state.vhsAnalytics.actionsFilter,
        chartData: state.vhsAnalytics.chartData,
        periodFilter: state.vhsAnalytics.periodFilter,
        selectedActivity: state.vhsAnalytics.selectedActivity,
    };
}

const VHSAnalytics = connect(mapStateToProps)(function (props) {
    const {
        activities,
        page,
        per_page,
        total_pages,
        total,
        actionsFilter,
        chartData,
        periodFilter,
        selectedActivity,
        isLoadingActivities
    } = props;

    const {
        VHS_SELECT_ACTIVITY,
        VHS_FETCH_ACTIVITIES_REQUEST,
        VHS_FETCH_ACTIVITY_REQUEST,
        VHS_SELECT_ACTIVITY_ACTIONS_FILTER,
        VHS_FETCH_ACTIVITY_CHART_REQUEST,
        VHS_SELECT_ACTIVITY_FILTER,

    } = useActions();

    const navigate = useNavigate();
    const [parsedChartData, loadParsedChartData] = useVhsAnalyticsChartData();
    const { id } = useParams();

    const [detailComponent, setDetailComponent] = useState();
    const [subtitle, setSubtitle] = useState('');
    // eslint-disable-next-line
    const [sectionName, setSectionName] = useState('Analytics');
    const [chartName, setChartName] = useState('');
    const [showExportModal, setShowExportModal] = useState(false);

    const onDetailSelection = (activity) => {
        setSubtitle(null);
        setDetailComponent(null);
        VHS_SELECT_ACTIVITY(activity);
        if (activity) {
            navigate(`/vhs/analytics/${activity.id}`);
        } else {
            VHS_FETCH_ACTIVITIES_REQUEST({ per_page, page });
            navigate(`/vhs/analytics`);
        }
    };

    const onNextPage = () => {
        VHS_FETCH_ACTIVITIES_REQUEST({
            per_page,
            page: page + 1,
            actions: actionsFilter,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_ACTIVITIES_REQUEST({
            per_page,
            page: page - 1,
            actions: actionsFilter,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_ACTIVITIES_REQUEST({
            per_page,
            page: pageSelected,
            actions: actionsFilter,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_ACTIVITIES_REQUEST({
            per_page: perPage,
            actions: actionsFilter,
        });
    };

    const onPeriodSelected = ({ period }) => {
        VHS_SELECT_ACTIVITY_FILTER(period);
        VHS_FETCH_ACTIVITY_CHART_REQUEST({
            period,
            actions: actionsFilter,
        });
        const label = VHS_EVENTS_ACTIONS.find(
            (act) => act.value === actionsFilter
        )?.label;
        setChartName(
            `${
                label === 'All' ? 'All Actions' : label
            } in the last ${period} Days`
        );
    };

    useEffect(() => {
        loadParsedChartData(chartData, actionsFilter);
        // eslint-disable-next-line
    }, [chartData]);

    useEffect(() => {
        VHS_FETCH_ACTIVITIES_REQUEST({
            actions: actionsFilter,
            per_page,
            page,
        });
        VHS_FETCH_ACTIVITY_CHART_REQUEST({
            period: periodFilter,
            actions: actionsFilter,
        });
        const label = VHS_EVENTS_ACTIONS.find(
            (act) => act.value === actionsFilter
        )?.label;
        setChartName(
            `${
                label === 'All' ? 'All Actions' : label
            } in the last ${periodFilter} Days`
        );
        setSectionName(label === 'All' ? 'All Actions' : label);
        // eslint-disable-next-line
    }, [actionsFilter]);

    useEffect(() => {
        if (selectedActivity) {
            setSubtitle(selectedActivity?.action);
            setDetailComponent(<AnalyticsDetail />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedActivity]);

    useEffect(() => {
        if (id) {
            try {
                VHS_FETCH_ACTIVITY_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        VHS_FETCH_ACTIVITIES_REQUEST({ actions: actionsFilter });
        // eslint-disable-next-line
    }, []);

    function onSelectActions(action) {
        VHS_SELECT_ACTIVITY_ACTIONS_FILTER(action);
    }

    function onModalClose() {
        setShowExportModal(false);
    }

    function filters() {
        return [
            <DownloadIcon
                onClick={() => setShowExportModal(true)}
                className="h-6 cursor-pointer text-black"
            />,
            <SingleFilter
                selectedValue={actionsFilter}
                options={VHS_EVENTS_ACTIONS}
                onSelect={onSelectActions}
            />
        ];
    }

    return (
        <>
            <InformationTab
                showPrograms={false}
                sectionName={sectionName}
                chartName={chartName}
                onDetailSelected={onDetailSelection}
                detailComponent={detailComponent}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                subsectionName={subtitle}
                customFilters={filters()}
                dataTable={
                    <section>
                        <TableResults results={total} />
                        <AnalyticsTable activities={activities} isLoading={isLoadingActivities} />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </section>
                }
                hasChart
                chartData={parsedChartData}
                onPeriodSelected={onPeriodSelected}
                selectedPeriod={periodFilter}
            />

            <AnalyticsExport
                total={total}
                sheetsData={activities}
                visible={showExportModal}
                onClose={onModalClose}
            />
        </>
    );
});

export default VHSAnalytics;
