import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MemberDetails from './MemberDetails';
import useQueryList from 'hooks/useQuery';
import LinkButton from 'components/LinkButton';


function mapStateToProps(state) {
    return {
        members: state.members.list,
        selectedMember: state.members.selectedMember,
        programOptions: state.programs.programOptions,
        page: state.members.page,
        total: state.members.total,
        total_pages: state.members.total_pages,
        brandOptions: state.brands.brandOptions,
        per_page: state.members.per_page,
        selectedProgram: state.members.program,
        isLoading: state.members.isLoadingMembers,
    };
}

const Members = connect(mapStateToProps)(function (props) {
    const {
        members,
        selectedMember,
        programOptions,
        brandOptions,
        page,
        total_pages,
        per_page,
        selectedProgram,
        total,
        isLoading,
    } = props;
    const [detailComponent, setDetailComponent] = useState(null);
    const {
        SELECT_MEMBER,
        FETCH_MEMBERS_REQUEST,
        SELECT_MEMBERS_PROGRAM,
        FETCH_MEMBER_REQUEST,
    } = useActions();
    const navigate = useNavigate();
    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);

    const [programsSet, setProgramsSet] = useState({});
    const [brandsSet, setBrandsSet] = useState({});

    const onDetailSelection = (member) => {
        setDetailComponent(null);
        SELECT_MEMBER(member);
        if (member) {
            navigate(`/referrals/members/${member.id}`);
        } else {
            FETCH_MEMBERS_REQUEST({ per_page, page, selectedProgram, searchTerm });
            navigate(`/referrals/members`);
        }
    };

    const onNextPage = () => {
        FETCH_MEMBERS_REQUEST({ per_page, page: page + 1, selectedProgram, searchTerm });
    };

    const onPrevPage = () => {
        FETCH_MEMBERS_REQUEST({ per_page, page: page - 1, selectedProgram, searchTerm });
    };

    const onPageSelect = (pageSelected) => {
        FETCH_MEMBERS_REQUEST({
            per_page,
            page: pageSelected,
            selectedProgram,
            searchTerm
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        FETCH_MEMBERS_REQUEST({ per_page: perPage, selectedProgram, searchTerm });
    };

    const onProgramSelected = (programId) => {
        SELECT_MEMBERS_PROGRAM(programId);
        FETCH_MEMBERS_REQUEST({ selectedProgram: programId, searchTerm });
    };

    useEffect(() => {
        if (selectedMember) {
            setDetailComponent(
                <MemberDetails member={selectedMember} brandsSet={brandsSet} />
            );
        } else {
            setDetailComponent(null);
        }
    }, [selectedMember, brandsSet]);

    useEffect(() => {
        if (programOptions) {
            const pr = {};
            programOptions.forEach((element) => {
                pr[element.value] = element.label;
            });
            setProgramsSet(pr);
        }
    }, [programOptions]);

    useEffect(() => {
        const populateBrandsSet = () => {
            const setObject = {};
            brandOptions.forEach((brand) => {
                if (brand) {
                    setObject[brand.value] = brand.label;
                }
            });
            setBrandsSet(setObject);
        };
        populateBrandsSet();
    }, [brandOptions]);

    useEffect(() => {
        if (id) {
            try {
                const intId = Number.parseInt(id);
                FETCH_MEMBER_REQUEST(intId);
            } catch (err) {
                console.log(err);
            }
        } else {
            SELECT_MEMBER(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        FETCH_MEMBERS_REQUEST({ selectedProgram, per_page, searchTerm });
        return () => {
            SELECT_MEMBER(null);
            setDetailComponent(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataTable = () => {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable isLoading={isLoading} className="w-full">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Brand</th>
                            <th>User ID</th>
                            <th>Share Code</th>
                            <th>Program</th>
                            <th>Balance</th>
                            <th>Enabled</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map((member) => (
                            <tr key={`member-${member.id}`}>
                                <td className="email">
                                    <SelectableField
                                        url={`/referrals/members/${member.id}`}
                                        action={() => onDetailSelection(member)}
                                        text={member?.email}
                                    />
                                </td>
                                <td>{member.brand_ident}</td>
                                <td>{member.brand_user_id}</td>
                                <td>{member.share_code}</td>
                                <td>
                                    <SelectableField
                                        url={
                                            '/referrals/programs/' +
                                            member.program_id
                                        }
                                        text={programsSet[member.program_id]}
                                    />
                                </td>
                                <td>${member.balance}</td>
                                <td>
                                    {member.is_enabled ? 'Enabled' : 'Disabled'}
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        action={() => onDetailSelection(member)}
                                    >
                                        See Details
                                 </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                    currentPage={page}
                    totalPages={total_pages}
                />
            </section>
        );
    };
    return (
        <InformationTab
            sectionName="Members"
            dataTable={dataTable()}
            subsectionName={selectedMember?.email}
            onDetailSelected={onDetailSelection}
            detailComponent={detailComponent}
            onPerPageSelected={onPerPageSelected}
            onProgramSelected={onProgramSelected}
            perPage={per_page}
            selectedProgram={selectedProgram}
            showSearchField={true}
        />
    );
});

export default Members;
