import { combineReducers } from 'redux';
import admins from './admins';
import brands from './brands';
import conversions from './conversions';
import members from './members';
import programs from './programs';
import ui from './ui';
import user from './user';
import views from './views';

import vhs from './vhs';
import vhsAnalytics from './vhsAnalytics';
import vhsBrands from './vhsBrands';
import vhsEpisodes from './vhsEpisodes';
import vhsInstructors from './vhsInstructors';
import vhsMembers from './vhsMembers';
import vhsSeries from './vhsSeries';
import vhsTags from './vhsTags';
import vhsLocations from './vhsLocations';
import vhsMeetings from './vhsMeetings';
import vhsPrograms from './vhsPrograms';

import PEAssignments from './PEAssignments';
import PEBrands from './PEBrands';
import PECategories from './PECategories';
import PEMatrices from './PEMatrices';
import PEMembers from './PEMembers';
import PEPhases from './PEPhases';
import PEPrograms from './PEPrograms';
import PESequences from './PESequences';
import PESeries from './PESeries';
import PETags from './PETags';

import CSModerator from './CSModerator';
import CsArticles from './CsArticles';
import CSQuestions from './CSQuestions';
import CSAnswers from './CSAnswers';
import CSComments from './CSComments';
import CSTags from './CSTags';
import CSMembers from './CSMembers';
import CSFlags from './CSFlags';

export default combineReducers({
    admins,
    user,
    ui,
    //referrals
    programs,
    brands,
    members,
    conversions,
    views,
    //vhs
    vhs,
    vhsAnalytics,
    vhsBrands,
    vhsEpisodes,
    vhsInstructors,
    vhsMembers,
    vhsSeries,
    vhsTags,
    vhsLocations,
    vhsMeetings,
    vhsPrograms,
    // PE
    PEAssignments,
    PEBrands,
    PECategories,
    PEMatrices,
    PEMembers,
    PEPhases,
    PEPrograms,
    PESequences,
    PESeries,
    PETags,
    //CS
    CSModerator,
    CsArticles,
    CSComments,
    CSQuestions,
    CSAnswers,
    CSTags,
    CSMembers,
    CSFlags,
});
