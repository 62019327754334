import { ArrowRightIcon, XCircleIcon } from '@heroicons/react/outline';
import InformationTab from 'components/InformationTab';
import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import TableButton from 'components/TableButton';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import { useEffect, useState } from 'react';
import TranslationsEdit from '../TranslationsEdit';
import TranslationsNew from '../TranslationsNew';

function TranslationsTable({
    translations = [],
    selectedTranslation = null,
    isLoading,
    page,
    perPage,
    total,
    totalPages,
    onSelect = function () {},
    onDelete = function () {},
    onUpdate = function () {},
    onNext = function () {},
    onPrev = function () {},
    onPageSelected = function () {},
    onPerPageSelected = function () {},
    onCreate = function () {},
}) {
    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState(null);

    function onTitleClick() {
        setDetailComponent(null);
        setSubtitle(null);
        onSelect(null);
    }
    function handleCreate(translation) {
        // Make sure never send lang null to API
        if( translation.lang_code === null ){
            translation.lang_code = '';
        }
        if( translation.lang_terr === null ){
            translation.lang_terr = '';
        }

        onCreate(translation);
        // ## Close form / show list again.
        setTimeout(() => {
            onTitleClick();
        }, 1000);
    }
    function handleUpdate(translation) {
        // Make sure never send lang null to API
        if( translation.lang_code === null ){
            translation.lang_code = '';
        }
        if( translation.lang_terr === null ){
            translation.lang_terr = '';
        }

        onUpdate(translation, true);
        // ## Close form / show list again.
        setTimeout(() => {
            onTitleClick();
        }, 1000);
    }

    function onNew() {
        setDetailComponent(<TranslationsNew onAdd={handleCreate} />);
        setSubtitle('Add New Translation');
    }

    useEffect(() => {
        if (selectedTranslation) {
            setDetailComponent(
                <TranslationsEdit
                    onSave={handleUpdate}
                    translation={selectedTranslation}
                />
            );
            setSubtitle(selectedTranslation.name);
        }
        // eslint-disable-next-line
    }, [selectedTranslation]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Translation" onAdd={onNew}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Language</th>
                            <th>Territory</th>
                            <th>Field</th>
                            <th>Text</th>
                            <th>Status</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {translations.map((translation) => (
                            <tr key={`tranlsation-${translation.id}`}>
                                <td>{translation.lang_code}</td>
                                <td>{translation.lang_terr}</td>
                                <td>{translation.field_name}</td>
                                <td>
                                    <div className='limited-text-overflow-10'>
                                        {translation.translated_text}
                                    </div>
                                </td>
                                <td className='uppercase'>{translation.status}</td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        action={() => onSelect(translation)}
                                    >
                                        View{' '}
                                        <ArrowRightIcon
                                            height={'1.3rem'}
                                            className="ml-2"
                                        />
                                    </LinkButton>
                                </td>
                                <td>
                                    <TableButton
                                        className="button delete-button"
                                        action={() => onDelete(translation)}
                                    >
                                        <XCircleIcon
                                            height={'1.3rem'}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Delete
                                    </TableButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    totalPages={totalPages}
                    currentPage={page}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <InformationTab
            sectionName="Translations"
            subsectionName={subtitle}
            detailComponent={detailComponent}
            dataTable={<DataTable />}
            showPrograms={false}
            onPerPageSelected={onPerPageSelected}
            perPage={perPage}
            onDetailSelected={onTitleClick}
            showBreadcrumbs={false}
        />
    );
}

export default TranslationsTable;
