import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    locationOptions: [],
    locations: [],
    isLoadingLocations: false,
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    selectedLocation: null,
    history: [],
    history_page: 1,
    history_per_page: 10,
    history_total: 0,
    history_total_pages: 1,
    isLoadingHistory: false,
};

function VHSLocationReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_LOCATION_RESPONSE: {
            const options = [{ value: '', label: 'No Location' }].concat(
                action.data?.map((location) => ({
                    value: location.id,
                    label: location.name,
                }))
            );
            return {
                ...state,
                locationOptions: options,
            };
        }
        case ACTION_TYPES.VHS_FETCH_LOCATIONS_RESPONSE:{
            return {
                ...state,
                locations: action.data.results,
                total: action.data.total,
                isLoadingLocations: false,
                total_pages: action.data.total_pages,
                page: action.data.page,
            }
        }
        case ACTION_TYPES.VHS_SELECT_LOCATION:{
            return {
                ...state,
                selectedLocation: action.data,
            }
        }
        case ACTION_TYPES.VHS_FETCH_LOCATION_RESPONSE:{
            return {
                ...state,
                selectedLocation: action.data,
            }
        }
        case ACTION_TYPES.VHS_UPDATE_LOCATION_RESPONSE:{
            return {
                ...state,
                selectedLocation: action.data,
            }
        }
        case ACTION_TYPES.VHS_FETCH_LOCATION_HISTORY_REQUEST:{
            return {
                ...state,
                isLoadingHistory: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_LOCATION_HISTORY_RESPONSE:{
            return {
                ...state,
                history: action.data.list,
                history_page: action.data.page,
                history_per_page: action.data.per_page,
                history_total: action.data.total,
                history_total_pages: action.data.total_pages,
                isLoadingHistory: false,
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSLocationReducer;
