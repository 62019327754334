import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { REEL_OPTIONS, VISIBILITY_OPTIONS } from 'constants/COMMUNITY_STATUS';
import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';
import { useState } from 'react';
import { EditorView } from "@codemirror/view";

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
        question: state.CSQuestions.selectedQuestion,
    };
}

const CSAnswerEdit = connect(mapStateToProps)(function ({
    answer,
    onConfirm = function () {},
    question,
}) {
    const { CS_UPDATE_ANSWER_REQUEST } = useActions();
    const initialState = {
        body: answer.body || '',
        is_reel: ~~answer.is_reel,
        visibility: answer.visibility,
        member_id: answer.member_id,
        link_text: answer.link_text || '',
        link_url: answer.link_url || '',
        thread_text: answer.thread_text || '',
    };
    const [updatedAnswer, handleFieldChange] = useInputState(initialState);
    const [description, setDescription] = useState(initialState.body);
    function onSave(event) {
        event.preventDefault();
        try {
            const payload = {
                id: answer.id,
                ...updatedAnswer,
                body: description,
            };
            if(!!question?.region_ident){
                payload.region_ident = question.region_ident;
            }
            if(payload?.is_reel === 1){
                payload.is_reel = '1';
            }else if(payload?.is_reel === 0){
                payload.is_reel = '0';
            }
            CS_UPDATE_ANSWER_REQUEST(payload);
            onConfirm();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section>
            <form onSubmit={onSave}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">
                                Body
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={updatedAnswer.body}
                                    reExtensions={[EditorView.lineWrapping]}
                                    onChange={setDescription}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Reel</td>
                            <td>
                                <select
                                    name="is_reel"
                                    value={updatedAnswer.is_reel}
                                    onChange={handleFieldChange}
                                >
                                    {REEL_OPTIONS.map((option) => (
                                        <option
                                            key={`reel-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={updatedAnswer.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <span className='text-gray'>{question?.region_ident}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Link Description</td>
                            <td>
                            <input
                                    name="link_text"
                                    className='w-full'
                                    value={updatedAnswer?.link_text}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Link URL</td>
                            <td>
                            <input
                                name="link_url"
                                className='w-full'
                                value={updatedAnswer?.link_url}
                                onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Thread Text</td>
                            <td>
                                <input
                                    name="thread_text"
                                    className='w-full'
                                    value={updatedAnswer?.thread_text}
                                    onChange={handleFieldChange}
                                    />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Member ID</td>
                            <td>
                                <input
                                    name="member_id"
                                    value={updatedAnswer.member_id}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        {updatedAnswer !== initialState && (
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default CSAnswerEdit;
