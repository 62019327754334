import ActivityRecord from 'components/ActivityRecord';
import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MemberConversions from '../MemberConversions';
import MemberEdit from '../MemberEdit';
import MemberOverview from '../MemberOverview';
import MembersHistory from '../MembersHistory';

function mapStateToProps(state) {
    return {
        programOptions: state.programs.programOptions,
        conversions: state.members.conversions,
        conversionsPage: state.members.conversions_page,
        conversionsPerPage: state.members.conversions_per_page,
        conversionsTotalPages: state.members.conversions_total_pages,
        conversionsTotal: state.members.conversions_total,
        isLoadingConversions: state.members.isLoadingConversions,
        activity: state.members.activity,
        activityPage: state.members.activity_page,
        activityPerPage: state.members.activity_per_page,
        activityTotalPages: state.members.activity_total_pages,
        isLoadingActivity: state.members.isLoadingActivity,
    };
}

const MemberDetails = connect(mapStateToProps)(function (props) {
    const {
        member,
        programOptions,
        brandsSet,
        conversions,
        conversionsPage,
        conversionsPerPage,
        conversionsTotal,
        conversionsTotalPages,
        isLoadingConversions,
        activityPage,
        activityPerPage,
        activityTotalPages,
        activity,
        isLoadingActivity,
    } = props;

    const {
        UPDATE_MEMBERS_REQUEST,
        FETCH_MEMBER_CONVERSIONS_REQUEST,
        FETCH_MEMBER_ACTIVITY_REQUEST,
        UPDATE_MEMBER_CONVERSION_REQUEST,
        FETCH_MEMBER_HISTORY_REQUEST,
    } = useActions();

    const [selectedTab, setSelectedTab] = useState(0);

    const onApproveConversion = (conversion, newState) => {
        UPDATE_MEMBER_CONVERSION_REQUEST({ conversion, newState });
    };

    const onSave = (updatedMember) => {
        UPDATE_MEMBERS_REQUEST(updatedMember);
        setSelectedTab(0);
    };

    const onConversionsPageSelected = (page) => {
        FETCH_MEMBER_CONVERSIONS_REQUEST({
            memberId: member.id,
            page,
            perPage: conversionsPerPage,
        });
    };

    const onNextConversionsPage = () => {
        FETCH_MEMBER_CONVERSIONS_REQUEST({
            memberId: member.id,
            page: conversionsPage + 1,
            perPage: conversionsPerPage,
        });
    };

    const onPrevConversionsPage = () => {
        FETCH_MEMBER_CONVERSIONS_REQUEST({
            memberId: member.id,
            page: conversionsPage - 1,
            perPage: conversionsPerPage,
        });
    };

    const onConversionsPerPageSelected = ({ perPage }) => {
        FETCH_MEMBER_CONVERSIONS_REQUEST({
            memberId: member.id,
            page: 1,
            perPage: perPage,
        });
    };

    const onActivityPerPageChange = (perPage) => {
        FETCH_MEMBER_ACTIVITY_REQUEST({
            uuid: member.uuid,
            perPage,
            page: 1,
        });
    };

    const onActivityNext = () => {
        FETCH_MEMBER_ACTIVITY_REQUEST({
            uuid: member.uuid,
            perPage: activityPerPage,
            page: activityPage + 1,
        });
    };

    const onActivityPrev = () => {
        FETCH_MEMBER_ACTIVITY_REQUEST({
            uuid: member.uuid,
            perPage: activityPerPage,
            page: activityPage - 1,
        });
    };

    const onActivityPageSelected = (page) => {
        FETCH_MEMBER_ACTIVITY_REQUEST({
            uuid: member.uuid,
            perPage: activityPerPage,
            page,
        });
    };

    useEffect(() => {
        FETCH_MEMBER_CONVERSIONS_REQUEST({ memberId: member?.id });
        FETCH_MEMBER_ACTIVITY_REQUEST({ uuid: member?.uuid });
        FETCH_MEMBER_HISTORY_REQUEST({ memberId: member?.id });
        // eslint-disable-next-line
    }, []);

    return (
        <Tabs
            selectedTab={selectedTab}
            onTabChange={(selected) => setSelectedTab(selected)}
            sectionNames={[
                'Overview',
                'Settings',
                'Conversions',
                'Activity',
                'History',
            ]}
            sectionContents={[
                <MemberOverview
                    member={member}
                    programOptions={programOptions}
                    brandsSet={brandsSet}
                    conversionsTotal={conversionsTotal}
                />,
                <MemberEdit
                    member={member}
                    programOptions={programOptions}
                    onSave={onSave}
                />,
                <MemberConversions
                    conversions={conversions}
                    page={conversionsPage}
                    perPage={conversionsPerPage}
                    totalPages={conversionsTotalPages}
                    total={conversionsTotal}
                    onPerPageSelected={onConversionsPerPageSelected}
                    onPageSelected={onConversionsPageSelected}
                    onNext={onNextConversionsPage}
                    onPrev={onPrevConversionsPage}
                    onApprove={onApproveConversion}
                    isLoading={isLoadingConversions}
                />,
                <ActivityRecord
                    activities={activity}
                    perPage={activityPerPage}
                    page={activityPage}
                    totalPages={activityTotalPages}
                    onPerPageChange={onActivityPerPageChange}
                    onPrev={onActivityPrev}
                    onNext={onActivityNext}
                    onPageSelected={onActivityPageSelected}
                    isLoading={isLoadingActivity}
                />,
                <MembersHistory />,
            ]}
        />
    );
});

export default MemberDetails;
