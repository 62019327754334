import ACTION_TYPES from 'constants/ACTION_TYPES';
import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';

const initialState = {
    seriesOptions: [],
    series: [],
    isLoadingSeries: false,
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    episodes: [],
    episodes_page: 1,
    episodes_per_page: 10,
    episodes_total_pages: 1,
    episodes_total: 0,
    isLoadingEpisodes: false,
    activities: [],
    activities_page: 1,
    activities_per_page: 10,
    activities_total_pages: 1,
    activities_total: 0,
    isLoadingActivity: false,
    selectedSeries: null,
    visibilityFilter: 'all',
    brandsFilter: 'all',
    episodesOrder: VHS_EPISODES_SORTING[0].value,
    activitiesFilter: 'all',
    chartData: [],
    periodFilter: '30',
    history: [],
    history_page: 1,
    history_per_page: 10,
    history_total: 0,
    history_total_pages: 1,
    isLoadingHistory: false,
};

function VHSSeriesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_SERIES_OPTIONS_RESPONSE: {
            const options = action.data?.map((ser) => ({
                value: ser.id,
                label: ser.name,
            }));
            return {
                ...state,
                seriesOptions: options,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_REQUEST: {
            return {
                ...state,
                isLoadingSeries: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_RESPONSE: {
            return {
                ...state,
                series: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingSeries: false,
            };
        }
        case ACTION_TYPES.VHS_GET_SERIES_RESPONSE: {
            return {
                ...state,
                selectedSeries: action.data,
            };
        }
        case ACTION_TYPES.VHS_UPDATE_SERIES_RESPONSE: {
            return {
                ...state,
                selectedSeries: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_EPISODES_REQUEST: {
            return {
                ...state,
                isLoadingEpisodes: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_EPISODES_RESPONSE: {
            return {
                ...state,
                episodes: action.data.list,
                episodes_page: action.data.page,
                episodes_per_page: action.data.per_page,
                episodes_total_pages: action.data.total_pages,
                episodes_total: action.data.total,
                isLoadingEpisodes: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_ACTIONS_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_ACTIONS_RESPONSE: {
            return {
                ...state,
                activities: action.data.list,
                activities_page: action.data.page,
                activities_per_page: action.data.per_page,
                activities_total_pages: action.data.total_pages,
                activities_total: action.data.total,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.VHS_POST_SERIES_RESPONSE: {
            return {
                ...state,
                selectedSeries: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_SERIES_VISIBILITY_FILTER: {
            return {
                ...state,
                page:0,
                visibilityFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_SERIES_BRANDS_FILTER: {
            return {
                ...state,
                page:0,
                brandsFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_SERIES_EPISODES_ORDER: {
            return {
                ...state,
                episodesOrder: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_SERIES_ACTIVITIES_FILTER: {
            return {
                ...state,
                page:0,
                activitiesFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_ACTIVITY_CHART_RESPONSE: {
            return {
                ...state,
                chartData: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_SERIES_PERIOD_FILTER: {
            return {
                ...state,
                periodFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_HISTORY_REQUEST: {
            return {
                ...state,
                isLoadingHistory: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_SERIES_HISTORY_RESPONSE: {
            return {
                ...state,
                history: action.data.list,
                history_page: action.data.page,
                history_per_page: action.data.per_page,
                history_total: action.data.total,
                history_total_pages: action.data.total_pages,
                isLoadingHistory: false,
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSSeriesReducer;
