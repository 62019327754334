import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchMembers(page = 1, per_page = 10, apiUrl,searchTerm = '',brand) {
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);
    let endpoint = `/pe/members?page=${page}&per_page=${per_page}`;
    if(!!searchTerm){
        endpoint += `&term=${searchTerm}`;
    }
    if(!!brand && brand!=='all'){
        endpoint += `&brands=${brand}`;
    }

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_MEMBERS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getMember(memberId, apiUrl) {
    const json = yield api.get(`/pe/members/${memberId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_MEMBER_RESPONSE,
            data: json,
        });
    }
}

function* peMembersWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_MEMBERS_REQUEST:
            yield fetchMembers(
                action.data?.page,
                action.data?.per_page,
                apiUrl,
                action.data?.searchTerm,
                action.data?.brand
            );
            break;
        case ACTION_TYPES.PE_GET_MEMBER_REQUEST:
            yield getMember(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default peMembersWorker;
