import { CheckCircleIcon } from '@heroicons/react/outline';
import useActions from 'hooks/useActions';
import { React, useEffect, useRef, useState } from 'react';
import Resumable from 'resumablejs';
import './fileUploader.css';

const apiURLS = {
    VHS: {
        apiURL: process.env.REACT_APP_VHS_API_URL,
        uploadsURL : '/vhs/uploads',
        uploadChunksURL : '/vhs/upload_chunks'
    },
    PE: {
        apiURL: process.env.REACT_APP_PE_API_URL,
        uploadsURL : '/pe/uploads',
        uploadChunksURL : '/pe/upload_chunks'
    },
    CS: {
        apiURL: process.env.REACT_APP_CS_API_URL,
        uploadsURL : '/community/uploads',
        uploadChunksURL : '/community/upload_chunks'
    }
}

function FileUploader({
    onClose,
    episodeId,
    brandIdent,
    onComplete = function () {},
    assetType = 'Episode',
    typeOfService = 'VHS',
    isInModal = false,
}) {
    const uploadsURL = apiURLS[typeOfService].apiURL + apiURLS[typeOfService].uploadsURL;
    const uploadChunksURL = apiURLS[typeOfService].apiURL + apiURLS[typeOfService].uploadChunksURL;
    const slot = useRef('video');
    const asset_type = useRef('video');
    const [completed, setCompleted] = useState(false);
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(0);
    const [isUploading, setIsUploading] = useState(false);
    const [resetAssign, setResetAssign] = useState(false);
    let token = localStorage.getItem('admin-token');    
    const { VHS_POST_EPISODE_FILE_REQUEST, PE_POST_ASSIGNMENT_FILE_REQUEST, CS_POST_ARTICLE_FILE_REQUEST } = useActions();

    function resetStates() {
        setCompleted(false);
        removeDragClass();
        setResetAssign(!resetAssign);
    }
    function handleClose() {
        resetStates();;
        onClose();
    }

    async function createUpload(upload) {
        // TODO: Handle errors
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-jwt', token);
        headers.append('Admin', 'wTzpZD856nxLAaSt');
        const resp = await fetch(uploadsURL, {
            method: 'POST',
            headers,
            credentials: 'include',
            body: JSON.stringify(upload),
        });
        const data = await resp.json();
        return data;
    }

    async function updateUpload(upload_id, data) {
        // TODO: Handle errors
        // fetch and use latest token here.
        let latestToken = localStorage.getItem("admin-token");
        resumable.opts.headers["x-jwt"] = latestToken;
    
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-jwt', latestToken);
        headers.append('Admin', 'wTzpZD856nxLAaSt');
        await fetch(`${uploadsURL}/${upload_id}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(data),
            credentials: 'include',
        });
    }

    async function completeUpload(upload_id) {
        // TODO: Handle errors
        await updateUpload(upload_id, { is_completed: true });
    }

    async function saveUploadProgress(upload_id, prog) {
        // TODO: Handle errors
        await updateUpload(upload_id, { progress: prog });
    }

    const resumable = new Resumable({
        target: uploadChunksURL,
        dragOverClass: 'dragover',
        chunkSize: 4194304, // Make sure this is HALF of what LB can accept
        maxFiles: 1,
        withCredentials: true,
        forceChunkSize: true,
        permanentErrors: [400, 404, 409, 415, 500, 501],
        fileType: [
            'mp4',
            'mkv',
            'mov',
            'avi',
            'wmv',
            'webm',
            'divx',
            'mp3',
            'wav',
            'jpg',
            'jpeg',
            'png',
            'gif',
            'png',
            'tiff',
            'bmp',
            'jpe',
            'pct',
            'pgm',
            'vtt',
            'srt',
            'm4a',
            'pdf',
        ],
        setChunkTypeFromFile: true,
        generateUniqueIdentifier: async function (file) {
            if (file.type.includes('audio')) {
                slot.current = 'audio';
                asset_type.current = 'audio';
            } else if (file.type.includes('video')) {
                slot.current = 'video';
                asset_type.current = 'video';
            } else if (
                file?.type.includes('text') ||
                file?.name.endsWith('.vtt') ||
                file?.name.endsWith('.srt')
            ) {
                slot.current = 'transcript';
                asset_type.current = 'transcript';
            } else if (file?.type.includes('image')) {
                slot.current = 'poster';
                asset_type.current = 'image';
            } else if (file?.name.endsWith('.pdf')) {
                slot.current = 'document';
                asset_type.current = 'document';
            }
            else {
                slot.current = 'unknown';
                asset_type.current = 'unknown';
            }
            if (
                file.size < 5000001 &&
                (slot.current !== 'audio' && slot.current !== 'video')
            ) return null;
            const upload = await createUpload({
                brand_ident: brandIdent,
                slot: slot.current,
                asset_type: asset_type.current,
                assetable_type: assetType,
                assetable_id: episodeId,
            });
            // NOTE: You don't have to save the upload id this way, you can
            // use whatever make sense in React. But this is needed for
            // marking this upload as completed or saving progress.
            file._upload = upload;
            return upload.asset_uuid;
        },
        query: {
            ...brandIdent && {brand_ident: brandIdent},
            slot: slot.current,
            asset_type: asset_type.current,
            assetable_type: assetType,
            assetable_id: episodeId,
        },
        headers: {
            'Admin': 'wTzpZD856nxLAaSt',
            'x-jwt': token,
        },
    });
    const dropRef = useRef();
    const buttonRef = useRef();

    useEffect(() => {
        !isUploading &&
            buttonRef.current &&
            !completed &&
            resumable.assignBrowse(buttonRef.current);
        !isUploading &&
            dropRef.current &&
            !completed &&
            resumable.assignDrop(dropRef.current);


            resumable.on('fileAdded', (event) => {
                setCompleted(false);
                setIsUploading(true);
                if (
                    slot.current === 'audio' ||
                    slot.current === 'video' ||
                    event.file?.size > 5000000
                ) {
                    resumable.opts.query.slot = slot.current;
                    resumable.opts.query.asset_type = asset_type.current;
                    resumable.upload();
                } else {
                    if(typeOfService === 'VHS'){
                        VHS_POST_EPISODE_FILE_REQUEST({
                            episodeId,
                            brandIdent: brandIdent,
                            assetType: asset_type.current,
                            slot: slot.current,
                            file: event.file,
                            done: () => {
                                setCompleted(true);
                                setIsUploading(false);
                                onComplete();
                            },
                        })
                    }
                    else if(typeOfService === 'PE'){
                        PE_POST_ASSIGNMENT_FILE_REQUEST({
                            assignmentId: episodeId,
                            assetType: asset_type.current,
                            slot: slot.current,
                            file: event.file,
                            done: () => {
                                setCompleted(true);
                                setIsUploading(false);
                                onComplete();
                            },
                        })
    
                    }
                    else if(typeOfService === 'CS'){
                        CS_POST_ARTICLE_FILE_REQUEST({
                            articleId: episodeId,
                            assetType: asset_type.current,
                            slot: slot.current,
                            file: event.file,
                            done: () => {
                                setCompleted(true);
                                setIsUploading(false);
                                onComplete();
                            },
                        })
                    }
                }
            });
            resumable.on('fileError', () => {
                setIsUploading(false);
                setProgress(0);
                progressRef.current = 0;
                resumable.cancel();
            });
            resumable.on('error', (message, file) => {
                setIsUploading(false);
                setProgress(0);
                progressRef.current = 0;
                resumable.cancel();
            });
            
            resumable.on('fileProgress', () => {
                const p = resumable.progress();
                const newProgress = p ? p * 100 : 0;
                setProgress(newProgress.toFixed(2));
                progressRef.current = newProgress.toFixed(2);
            });
            resumable.on('progress', () => {
                if (resumable.files[0]?.file?._upload?.id){
                    saveUploadProgress(
                        resumable.files[0]?.file?._upload?.id,
                        progressRef.current
                    );}
                else{
                    setIsUploading(false);
                    resumable.cancel();
                }
            });
    
            resumable.on('complete', function () {
                if (resumable.files[0]?.file?._upload?.id) {
                    completeUpload(resumable.files[0]?.file?._upload?.id);
                    setIsUploading(false);
                    setCompleted(true);
                    onComplete();
                }
                else{
                    setIsUploading(false);
                    resumable.cancel();
                }
            });

        return () => {
        };
        // eslint-disable-next-line
    }, [resetAssign]);

    function removeDragClass(event) {
        if(event){
            event.target?.parentNode?.classList?.remove('resumable-drag-over');
        }else{
            document.getElementById('file-uploader-container')?.classList?.remove('resumable-drag-over');
        }
    }

    return (
        <>
            {!isInModal && !isUploading && !completed && (
                <button
                    className="bg-green-add p-2 mr-4 text-white mb-4"
                    onClick={handleClose}
                >
                    Close Upload
                </button>
            )}
            <div className='file-uploader-outside-wrapper'>
            <h3>Upload New File</h3>
            <div className="file-uploader-container" id="file-uploader-container">
                {!isUploading && !completed && (
                    <div
                        onDragOver={(event) => {
                            event.target?.parentNode?.classList?.add(
                                'resumable-drag-over'
                            );
                        }}
                        onDragEnd={removeDragClass}
                        onDragLeave={removeDragClass}
                        onDragCapture={removeDragClass}
                        onDragExit={removeDragClass}
                        id="resumable-drop-target"
                        className="flex items-center justify-center relative"
                        ref={dropRef}
                        style={{ width: '100%', height: '100%' }}
                    >
                        <div className='phrase-container'>
                            <div className="rrr phrase">Drag & Drop Here</div>
                            <span
                                id="resumable-browse-button"
                                ref={buttonRef}
                                className="rrr"
                            >
                                Or Select a File
                            </span>
                            <span className="drag-over-help" id="drag-over-help">
                                Drop To Upload The File
                            </span>
                        </div>
                    </div>
                )}
                {isUploading && (
                    <div style={{ width: '100%' }}>
                        <div className="resumable-progress-bar-container flex justify-start items-start relative">
                            <div
                                className="resumable-progress-bar"
                                style={{ width: `${progress}%` }}
                            ></div>
                            <div className="absolute flex justify-center items-center w-full h-full font-bold">{`${progress}%`}</div>
                        </div>
                    </div>
                )}
                {completed && (
                    <div className="flex justify-center items-center">
                        <CheckCircleIcon height="4rem" color="#3e8f1a" />
                        <span className="font-medium ml-2">
                            Upload completed successfully
                        </span>
                        <button 
                            className="bg-green-add p-2 mr-4 text-white ml-4" 
                            onClick={resetStates}
                        > 
                            Upload New File
                        </button>
                    </div>
                )}
            </div>
            </div>
        </>
    );
}

export default FileUploader;
