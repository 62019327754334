const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    users: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    isLoadingUsers: false,
    selectedUser: null,
    brandFilter: 'all',
    regionFilter: 'all',
    serviceFilter: 'all',
    config: {
        brands: [],
        services: [],
        regions: [],
        languages: [],
    },
};

function adminsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ADMINS_REQUEST: {
            return {
                ...state,
                isLoadingUsers: true,
            };
        }
        case ACTION_TYPES.FETCH_ADMINS_RESPONSE: {
            return {
                ...state,
                users: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingUsers: false,
            };
        }
        case ACTION_TYPES.GET_ADMIN_RESPONSE: {
            return {
                ...state,
                selectedUser: action.data,
            };
        }
        case ACTION_TYPES.POST_ADMIN_RESPONSE: {
            return {
                ...state,
                selectedUser: action.data,
            };
        }
        case ACTION_TYPES.UPDATE_ADMIN_RESPONSE: {
            return {
                ...state,
                selectedUser: action.data,
            };
        }
        case ACTION_TYPES.SELECT_ADMINS_BRANDS_FILTER: {
            return {
                ...state,
                page: 1,
                brandFilter: action.data,
            };
        }
        case ACTION_TYPES.SELECT_ADMINS_REGIONS_FILTER: {
            return {
                ...state,
                page: 1,
                regionFilter: action.data,
            };
        }
        case ACTION_TYPES.SELECT_ADMINS_SERVICES_FILTER: {
            return {
                ...state,
                page: 1,
                serviceFilter: action.data,
            };
        }
        case ACTION_TYPES.SELECT_ADMIN: {
            return {
                ...state,
                selectedUser: action.data,
            };
        }
        case ACTION_TYPES.FETCH_ADMIN_CONFIG_REPONSE: {
            const brands = action.data?.brands?.map((brand) => ({
                label: brand.name,
                value: brand.ident,
                isEnabled: brand.is_enabled,
            }));
            const regions = action.data?.regions?.map((region) => ({
                label:  (region.ident && region.ident !== 'all') ? region.name + ' (' + region.ident + ')' : region.name,
                value: region.ident,
                isEnabled: region.is_enabled,
            }));
            const services = action.data?.services?.map((service) => ({
                label: service.name,
                value: service.ident,
                isEnabled: service.is_enabled,
            }));
            return {
                ...state,
                config: {
                    brands: brands ? brands : [],
                    regions: regions ? regions : [],
                    services: services ? services : [],
                    languages: action.data.languages ? action.data.languages : [],
                },
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}

export default adminsReducer;
