import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchPrograms(page = 1, per_page = 10, searchTerm = '',brand, apiUrl) {
    let endpoint = `/pe/programs?page=${page}&per_page=${per_page}`;
    if (searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    if(!!brand && brand!=='all'){
        endpoint += `&brands=${brand}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_PROGRAMS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postProgram(program, apiUrl) {
    const json = yield api.post(`/pe/programs`, program, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_PROGRAM_RESPONSE,
            data: json,
        });
    }
}

function* updateProgram(program, callback, apiUrl) {
    const json = yield api.put(`/pe/programs/${program.id}`, program, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_PROGRAM_RESPONSE,
            data: json,
        });
        callback && callback();
    }
}

function* deleteProgram(programId, page, perPage, searchTerm, apiUrl) {
    const json = yield api.del(`/pe/programs/${programId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_PROGRAM_RESPONSE,
            data: json,
        });
    }
    yield fetchPrograms(page, perPage, searchTerm, apiUrl);
}

function* getProgram(programId, apiUrl) {
    const json = yield api.get(`/pe/programs/${programId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_PROGRAM_RESPONSE,
            data: json,
        });
    }
}

function* cloneProgram(programId, apiUrl) {
    const json = yield api.post(`/pe/programs/${programId}/clone`, {}, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_CLONE_PROGRAM_RESPONSE,
            data: json,
        });
    }
}

function* getPhases(programId, apiUrl) {
    const json = yield api.get(
        `/pe/program_phases?programs=${programId}&include=program-phase`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_PROGRAM_PHASES_RESPONSE,
            data: json.results,
        });
    }
}

function* postProgramPhase(program_id, phase_id, apiUrl) {
    const json = yield api.post(
        `/pe/program_phases`,
        { program_id, phase_id },
        apiUrl
    );
    if (json) {
        yield getPhases(program_id, apiUrl);
    }
}

function* updatePosition(programPhaseId, programId, position, apiUrl) {
    const json = yield api.put(
        `/pe/program_phases/${programPhaseId}`,
        { position },
        apiUrl
    );
    if (json) {
        yield getPhases(programId, apiUrl);
    }
}

function* removeProgramPhase(programPhaseId, programId, apiUrl) {
    const json = yield api.del(`/pe/program_phases/${programPhaseId}`, apiUrl);
    if (json) {
        yield getPhases(programId, apiUrl);
    }
}

function* addTag(programId, tagId, apiUrl) {
    const json = yield api.post(
        '/pe/content_tags',
        {
            content_type: 'Program',
            content_id: programId,
            tag_id: tagId,
        },
        apiUrl
    );
    if (json) {
        yield getProgram(programId, apiUrl);
    }
}

function* removeTag(contentId, tagId, apiUrl) {
    const json = yield api.del(
        `/pe/content_tags/Program/${contentId}/${tagId}`,
        apiUrl
    );
    if (json) {
        yield getProgram(contentId, apiUrl);
    }
}

function* getTagGroups(apiUrl) {
    const json = yield api.get(`/pe/tag_groups?per_page=1000`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_PROGRAMS_TAG_GROUPS_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchTranslations(programId, page = 1, per_page = 10, apiUrl) {
    const json = yield api.get(
        `/pe/translations?resource_type=Program&resource_id=${programId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_PROGRAM_TRANSLATIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postTranslation(resource_id, translation, callback, apiUrl) {
    const json = yield api.post(
        `/pe/translations`,
        {
            resource_id,
            resource_type: 'Program',
            ...translation,
        },
        apiUrl
    );
    if (json) {
        put({
            type: ACTION_TYPES.PE_POST_PROGRAM_TRANSLATION_RESPONSE,
            data: json,
        });
        callback && callback();
    }
}

function* deleteTranslation(translationId, programId, page, perPage, apiUrl) {
    const json = yield api.del(`/pe/translations/${translationId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_SELECT_PROGRAM_TRANSLATION,
            data: null,
        });
        yield fetchTranslations(programId, page, perPage, apiUrl);
    }
}

function* updateTranslation(translation, callback, apiUrl) {
    const json = yield api.put(
        `/pe/translations/${translation.id}`,
        translation,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_PROGRAM_TRANSLATION_RESPONSE,
            data: json,
        });
        callback && callback();
    }
}

function* peProgramsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_PROGRAMS_REQUEST:
            yield fetchPrograms(
                action.data?.page,
                action.data?.per_page,
                action.data?.searchTerm,
                action.data?.brand,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_POST_PROGRAM_REQUEST:
            yield postProgram(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_UPDATE_PROGRAM_REQUEST:
            yield updateProgram(action?.data, action?.data?.callback, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_PROGRAM_REQUEST:
            yield deleteProgram(
                action.data?.programId,
                action.data?.page,
                action.data?.perPage,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_GET_PROGRAM_REQUEST:
            yield getProgram(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_CLONE_PROGRAM_REQUEST:
            yield cloneProgram(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_FETCH_PROGRAM_PHASES_REQUEST:
            yield getPhases(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_PROGRAM_PHASE_REQUEST:
            yield postProgramPhase(
                action.data?.programId,
                action.data?.phaseId,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_UPDATE_PROGRAM_PHASE_REQUEST:
            yield updatePosition(
                action.data?.programPhaseId,
                action.data?.programId,
                action.data?.position,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_DELETE_PROGRAM_PHASE_REQUEST:
            yield removeProgramPhase(
                action.data?.programPhaseId,
                action.data?.programId,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_ADD_TAG_TO_PROGRAM_REQUEST:
            yield addTag(action.data.programId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.PE_REMOVE_TAG_FROM_PROGRAM_REQUEST:
            yield removeTag(action.data.programId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.PE_FETCH_PROGRAMS_TAG_GROUPS_REQUEST:
            yield getTagGroups(apiUrl);
            break;
        case ACTION_TYPES.PE_FETCH_PROGRAM_TRANSLATIONS_REQUEST:
            yield fetchTranslations(
                action.data?.programId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_POST_PROGRAM_TRANSLATION_REQUEST:
            yield postTranslation(
                action.data?.resourceId,
                action.data?.translation,
                action.data?.callback,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_DELETE_PROGRAM_TRANSLATION_REQUEST:
            yield deleteTranslation(
                action.data?.translationId,
                action.data?.programId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_UPDATE_PROGRAM_TRANSLATION_REQUEST:
            yield updateTranslation(action?.data?.translation, action?.data?.callback, apiUrl);
            break;
        default:
            break;
    }
}
export default peProgramsWorker;
