const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    categories: [],
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    selectedCategory: null,
    isLoadingCategories: false,
    assignments: [],
    assignments_page: 1,
    assignments_per_page: 10,
    assignments_total: 0,
    assignments_total_pages: 1,
    assignments_is_loading: false,
    categoriesOptions: [],
};

function PECategoriesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_CATEGORIES_REQUEST: {
            return {
                ...state,
                isLoadingCategories: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_CATEGORIES_RESPONSE: {
            return {
                ...state,
                categories: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
                isLoadingCategories: false,
            };
        }
        case ACTION_TYPES.PE_UPDATE_CATEGORY_RESPONSE:
        case ACTION_TYPES.PE_POST_CATEGORY_RESPONSE:
        case ACTION_TYPES.PE_GET_CATEGORY_RESPONSE:
        case ACTION_TYPES.PE_SELECT_CATEGORY: {
            return { ...state, selectedCategory: action.data };
        }
        case ACTION_TYPES.PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST: {
            return {
                ...state,
                assignments_is_loading: true,
            };
        }
        case ACTION_TYPES.PE_DELETE_CATEGORY_RESPONSE: {
            return {
                ...state,
                selectedCategory: false,
            };
        }
        case ACTION_TYPES.PE_FETCH_CATEGORY_ASSIGNMENTS_RESPONSE: {
            return {
                ...state,
                assignments: action.data.list,
                assignments_page: action.data.page,
                assignments_per_page: action.data.per_page,
                assignments_total: action.data.total,
                assignments_total_pages: action.data.total_pages,
                assignments_is_loading: false,
            };
        }
        case ACTION_TYPES.PE_FETCH_ALL_CATEGORIES_RESPONSE: {
            const options = action.data?.map((cat) => ({
                value: cat.id,
                label: cat.name,
                region_ident: cat.region_ident,
            }));
            return {
                ...state,
                categoriesOptions: options ?? [],
            };
        }
        default:
            return state;
    }
}

export default PECategoriesReducer;
