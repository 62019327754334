import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        actions: state.PEAssignments.config,
        categoriesOptions: state.PECategories.categoriesOptions,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEAssignmentsNew = connect(mapStateToProps)(function (props) {
    const { actions, categoriesOptions, categoryId, selectedRegionFilter } = props;
    const initialState = {
        name: '',
        technical_name: '',
        description: '',
        category_id: '',
        region_ident: '',
        poster_url: '',
        action: '',
        duration_ms: '',
        link_text: '',
        link_url: '',
    };

    const [assignment, setAssignment] = useState(initialState);
    const [actionOptions, setActionOptions] = useState([]);

    const { PE_POST_ASSIGNMENT_REQUEST, PE_FETCH_ALL_CATEGORIES_REQUEST } = useActions();

    function returnRegionByCategoryId(categoryId) {
        let regionIdent = null;
        if (categoriesOptions && categoriesOptions.length > 0 && categoryId) {
            regionIdent = 
                categoriesOptions.find(
                    (cat) => parseInt(cat.value) === parseInt(categoryId)
                )?.region_ident;
        }
        return regionIdent;
    }


    function handleFieldChange(event) {
        let newState = {
            ...assignment,
            [event.target.name]: event.target.value,
        };
        if(
            event.target.name === 'category_id' && 
            !!event.target.value
        ){
            newState.region_ident = returnRegionByCategoryId(event.target.value);
        }
        setAssignment(newState);
    }

    function onCreate(event) {
        event.preventDefault();
        const newAssignment = { ...assignment };
        const selectedOption = actions.find(
            (action) => action.value === assignment.action
        );
        const options =
            selectedOption && selectedOption.fields
                ? selectedOption.fields
                : [];
        const action_obj = {};
        for (let key in assignment) {
            if (!initialState.hasOwnProperty(key)) {
                delete newAssignment[key];
            }
            if (options.some((fieldOpt) => fieldOpt.name === key)) {
                action_obj[key] = assignment[key];
            }
        }
        if (Object.keys(action_obj).length > 0) {
            newAssignment['action_obj'] = action_obj;
        }
        newAssignment.duration_ms = newAssignment.duration_ms ? parseInt(newAssignment.duration_ms * 1000 * 60) : null;
        PE_POST_ASSIGNMENT_REQUEST(newAssignment);
    }

    useEffect(() => {
        if (categoriesOptions && categoriesOptions.length > 0) {
            if (categoryId) {
                setAssignment((latest) => ({
                    ...latest,
                    category_id: categoryId,
                    region_ident: returnRegionByCategoryId(categoryId),
                }));
            } else {
                setAssignment((latest) => ({
                    ...latest,
                    category_id: categoriesOptions[0].value,
                    region_ident: categoriesOptions[0].region_ident,
                }));
            }
        }
        // eslint-disable-next-line
    }, [categoriesOptions, categoryId]);

    useEffect(() => {
        if (assignment.action) {
            const selectedOption = actions.find(
                (action) => action.value === assignment.action
            );
            if (selectedOption) {
                const { fields } = selectedOption;
                setActionOptions(fields);
            } else {
                setActionOptions([]);
            }
        } else {
            setActionOptions([]);
        }
    }, [actions, assignment.action]);

    useEffect(() => {
        PE_FETCH_ALL_CATEGORIES_REQUEST();
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    return (
        <section>
            <form onSubmit={onCreate}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    name="name"
                                    value={assignment.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Technical Name</td>
                            <td>
                                <input
                                    name="technical_name"
                                    value={assignment.technical_name}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Description
                            </td>
                            <td>
                                <textarea
                                    name="description"
                                    value={assignment.description}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Category</td>
                            <td>
                                <select
                                    name="category_id"
                                    value={assignment.category_id}
                                    onChange={handleFieldChange}
                                >
                                    {categoriesOptions?.map((category) => (
                                        <option
                                            key={`category-option-${category.value}`}
                                            value={category.value}
                                        >
                                            {category.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <span className='text-gray'>{assignment?.region_ident}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Poster URL</td>
                            <td>
                                <input
                                    name="poster_url"
                                    value={assignment.poster_url}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Link Description</td>
                            <td>
                                <input
                                    name="link_text"
                                    value={assignment.link_text}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Link URL</td>
                            <td>
                                <input
                                    name="link_url"
                                    value={assignment.link_url}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Duration in Minutes</td>
                            <td>
                                <input
                                    name="duration_ms"
                                    value={assignment.duration_ms}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Action</td>
                            <td>
                                <select
                                    name="action"
                                    value={assignment.action}
                                    onChange={handleFieldChange}
                                >
                                    {actions?.map((action) => (
                                        <option
                                            key={`action-option-${action.value}`}
                                            value={action.value}
                                        >
                                            {action.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        {actionOptions?.map((actOpt) => (
                            <tr key={`act-opt-${actOpt.name}`}>
                                <td className="font-bold label">
                                    {actOpt.label}
                                </td>
                                <td>
                                    <input
                                        name={actOpt.name}
                                        value={assignment[actOpt.name]}
                                        onChange={handleFieldChange}
                                    />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Assignment
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default PEAssignmentsNew;
