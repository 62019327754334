import ActivityRecord from 'components/ActivityRecord';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        activity: state.views.activity,
        activityPage: state.views.activity_page,
        activityPerPage: state.views.activity_per_page,
        activityTotalPages: state.views.activity_total_pages,
        isLoadingActivity: state.views.isLoadingActivity
    };
}

const ViewsDetail = connect(mapStateToProps)(function ({
    activity,
    activityPage,
    activityPerPage,
    activityTotalPages,
    view,
    isLoadingActivity
}) {
    const { FETCH_VIEW_ACTIVITY_REQUEST } = useActions();


    const onActivityPerPageChange = (perPage) => {
        FETCH_VIEW_ACTIVITY_REQUEST({
            uuid: view.uuid,
            perPage,
            page: 1,
        });
    };

    const onActivityNext = () => {
        FETCH_VIEW_ACTIVITY_REQUEST({
            uuid: view.uuid,
            perPage: activityPerPage,
            page: activityPage + 1,
        });
    };

    const onActivityPrev = () => {
        FETCH_VIEW_ACTIVITY_REQUEST({
            uuid: view.uuid,
            perPage: activityPerPage,
            page: activityPage - 1,
        });
    };

    const onActivityPageSelected = (page) => {
        FETCH_VIEW_ACTIVITY_REQUEST({
            uuid: view.uuid,
            perPage: activityPerPage,
            page,
        });
    };

    useEffect(() => {
        if (view.uuid) {
            FETCH_VIEW_ACTIVITY_REQUEST({ uuid: view.uuid });
        }
        // eslint-disable-next-line
    }, [view]);

    return !view ? null : (
        <div>
            <table className="overview-table">
                <tbody>
                    <tr>
                        <td className="uppercase label">Timestamp</td>
                        <td>{view.created_int}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Uuid</td>
                        <td>{view.uuid}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">User Id</td>
                        <td>{view.member?.id}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">User Email</td>
                        <td>{view.member?.email}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Share Code</td>
                        <td>{view.share_code}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">
                            Referring User Id
                        </td>
                        <td>{view.referring_member?.id}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">
                            Referring User Email
                        </td>
                        <td>{view.referring_member?.email}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Program</td>
                        <td>{view.program?.name}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Brand</td>
                        <td>{view.brand_ident}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Url</td>
                        <td>{view.url}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Referrer</td>
                        <td>{view.referrer}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">IP Address</td>
                        <td>{view.ip_address}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">User Agent</td>
                        <td>{view.user_agent}</td>
                    </tr>
                </tbody>
            </table>
            <ActivityRecord
                activities={activity}
                perPage={activityPerPage}
                page={activityPage}
                totalPages={activityTotalPages}
                className="mt-8"
                onNext={onActivityNext}
                onPrev={onActivityPrev}
                onPageSelected={onActivityPageSelected}
                onPerPageChange={onActivityPerPageChange}
                isLoading={isLoadingActivity}
            />
        </div>
    );
});

export default ViewsDetail;
