import Tabs from 'components/Tabs';
import { useState } from 'react';
import MeetingsOverview from './MeetingsOverview';

function MeetingsDetails () {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <section>
            <Tabs
                sectionNames={['Overview']}
                sectionContents={[
                    <MeetingsOverview />,
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
};

export default MeetingsDetails;
