import { XCircleIcon } from '@heroicons/react/outline';
import LinkButton from 'components/LinkButton';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import PESequencesModalContent from 'routing/ProgramEngine/Sequences/SequencesModalContent';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        phase: state.PEPhases.selectedPhase,
        sequences: state.PEPhases.sequences,
    };
}

const PEPhasesOverview = connect(mapStateToProps)(function (props) {
    const { phase, sequences } = props;

    const [weeks, setWeeks] = useState(new Map());

    const { SET_MODAL_CONTENT, TOGGLE_MODAL } = useActions();

    function addWeek() {
        const weekNumber = weeks.size + 1;
        const newWeeks = new Map(weeks);
        newWeeks.set(weekNumber, []);
        setWeeks(newWeeks);
    }

    useEffect(() => {
        const newWeeks = new Map();

        sequences.forEach((sequence) => {
            if (newWeeks.has(sequence.week)) {
                newWeeks.get(sequence.week).push(sequence);
            } else {
                newWeeks.set(sequence.week, [sequence]);
            }
        });
        setWeeks(newWeeks);
        // eslint-disable-next-line
    }, [sequences]);

    function Week({ week, sequences = [] }) {
        useEffect(() => {
            console.log(sequences);
        }, [sequences]);
        const {
            PE_ADD_PHASE_SEQUENCE_REQUEST,
            PE_REMOVE_PHASE_SEQUENCE_REQUEST,
        } = useActions();
        function addSequence(sequenceId) {
            PE_ADD_PHASE_SEQUENCE_REQUEST({
                phaseId: phase.id,
                sequenceId,
                week,
            });
        }
        return (
            <div className="bg-light-section p-4 mb-8">
                <div className="font-bold text-xl mb-4">{`Week ${week}`}</div>
                <table>
                    <thead>
                        <tr>
                            <th>Sequence Name</th>
                            <th>Tags</th>
                            <th>Remove</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sequences.map((sequence, index) => (
                            <tr key={`sequence-${sequence.sequence_id}-${index}`}>
                                <td>
                                    <SelectableField
                                        text={sequence.name}
                                        url={`/program-engine/sequences/${sequence.sequence_id}`}
                                    />
                                </td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {sequence.sequence?.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${sequence.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <TableButton
                                        className="button delete-button"
                                        action={() => {
                                            PE_REMOVE_PHASE_SEQUENCE_REQUEST({
                                                phaseId: phase.id,
                                                phaseSequenceId: sequence.id,
                                            });
                                        }}
                                    >
                                        <XCircleIcon
                                            height={'1.3rem'}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Remove
                                    </TableButton>
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/sequences/${sequence.sequence_id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="flex">
                    <button
                        className="bg-green-add p-2 mt-2 ml-2 text-white flex items-center justify-center"
                        onClick={() => {
                            SET_MODAL_CONTENT(
                                <PESequencesModalContent
                                    onSelect={addSequence}
                                />
                            );
                            TOGGLE_MODAL();
                        }}
                    >
                        Add Sequence
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className="font-bold text-xl">{weeks.size} Weeks</div>
                <button
                    className="bg-green-add p-2 text-white flex items-center justify-center"
                    onClick={addWeek}
                >
                    Add Week
                </button>
            </div>
            {[...weeks?.keys()].map((week) => (
                <Week
                    week={week}
                    sequences={weeks.get(week)}
                    key={`week-${week}`}
                />
            ))}
        </div>
    );
});

export default PEPhasesOverview;
