import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';
import moment from 'moment';
import { useState } from 'react';
import Select from 'react-select';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    const loggedUser = state.user.user

    let seriesOptions = state.vhsSeries.seriesOptions
    const isAdmin = (loggedUser.permissions?.brands?.includes('all') || loggedUser.permissions?.brands?.includes('tbm'))
        && (loggedUser.permissions?.services?.includes('all') || loggedUser.permissions?.services?.includes('usr'))

    if (!isAdmin) {
        seriesOptions = seriesOptions.filter(ser => ser.value !== 2)
    }

    return {
        seriesOptions,
        brandsOptions: state.vhsBrands.brandsOptions,
        instructorsOptions: state.vhsInstructors.instructorsOptions,
        locationOptions: state.vhsLocations.locationOptions,
    };
}

const EpisodeEdit = connect(mapStateToProps)(function ({
    episode,
    seriesOptions,
    brandsOptions,
    instructorsOptions,
    locationOptions,
    onConfirm = function () { },
}) {
    const { VHS_UPDATE_EPISODE_REQUEST } = useActions();
    const initialState = {
        name: episode.name,
        description: episode.description,
        start_int: moment(episode.start_int).format('YYYY-MM-DDTHH:mm'),
        stop_int: moment(episode.stop_int).format('YYYY-MM-DDTHH:mm'),
        visibility: episode.visibility,
        instructor_id: typeof (episode.instructor_ids) !== 'object' ? [episode.instructor_ids] : episode.instructor_ids,
        instructors: function () { return instructorsOptions.filter(val => this.instructor_id?.includes(val.value)) },
        location_id: episode.location_id,
        series_id: episode.series_id,
        brand_ident: episode.brand_ident,
        zoom_url: episode.zoom_url,
    };
    const [updatedEpisode, handleFieldChange] = useInputState(initialState);
    const [instructors, setInstructors] = useState({
        instructors: initialState.instructors(),
        instructor_id: initialState.instructor_id
    })
    const [description, setDescription] = useState(episode.description)
    function onSave(event) {
        event.preventDefault();
        try {
            VHS_UPDATE_EPISODE_REQUEST({
                id: episode.id,
                ...updatedEpisode,
                ...instructors,
                description,
                start_int: new Date(updatedEpisode.start_int).getTime(),
                stop_int: new Date(updatedEpisode.stop_int).getTime(),
            });
            onConfirm();
        } catch (err) {
            console.log(err);
        }
    }

    const saveInstructors = (selected) => {
        const newState = {
            instructors: [...selected],
            instructor_id: selected.map(val => val.value)
        };
        setInstructors(newState);
    }

    return (
        <section>
            <form onSubmit={onSave}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    name="name"
                                    value={updatedEpisode.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Description
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={updatedEpisode.description}
                                    onChange={setDescription}
                                    toolbars={['bold', 'italic', 'underline', 'header', 'olist', 'ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Start</td>
                            <td>
                                <input
                                    name="start_int"
                                    type="datetime-local"
                                    value={updatedEpisode.start_int}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Stop</td>
                            <td>
                                <input
                                    name="stop_int"
                                    type="datetime-local"
                                    value={updatedEpisode.stop_int}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Duration</td>
                            <td style={{ padding: '1rem 0.5rem' }}>
                                <div
                                    style={{ width: '70%', minWidth: '500px' }}
                                >
                                    <em style={{ fontWeight: 'normal' }}>
                                        Duration will be taken from the primary
                                        video, if available, or the time between
                                        the start and stop times above.
                                    </em>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={updatedEpisode.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Instructor</td>
                            <td>
                                <Select
                                    isMulti
                                    options={instructorsOptions}
                                    value={instructors.instructors}
                                    onChange={(selected) => saveInstructors(selected)}
                                    labelledBy="Select"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Location</td>
                            <td>
                                <select
                                    name="location_id"
                                    value={updatedEpisode.location_id ?? ''}
                                    onChange={handleFieldChange}
                                >
                                    {locationOptions.map((location) => (
                                        <option
                                            key={`location-${location.value}`}
                                            value={location.value}
                                        >
                                            {location.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Series</td>
                            <td>
                                <select
                                    name="series_id"
                                    value={updatedEpisode.series_id}
                                    onChange={handleFieldChange}
                                >
                                    {seriesOptions.map(ser =>
                                    (
                                        <option
                                            key={`series-${ser.value}`}
                                            value={ser.value}
                                        >
                                            {ser.label}
                                        </option>
                                    )
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={updatedEpisode.brand_ident}
                                    onChange={handleFieldChange}
                                >
                                    {brandsOptions.map((ser) => (
                                        <option
                                            key={`series-${ser.value}`}
                                            value={ser.value}
                                        >
                                            {ser.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Zoom URL</td>
                            <td>
                                <textarea
                                    name="zoom_url"
                                    value={updatedEpisode.zoom_url}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        {updatedEpisode !== initialState && (
                            <tr>
                                <td colSpan="2">
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </form>
        </section>
    );
});

export default EpisodeEdit;
