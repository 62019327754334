/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from 'components/Tabs';
import { useState, useEffect } from 'react';
import CSAnswerOverview from '../AnswerOverview';
import CSAnswerEdit from '../AnswerEdit';
import CSAnswerFlags from '../AnswerFlags';
import CSAnswerFiles from '../AnswerFiles';
import CSAnswerTranslations from '../AnswerTranslations';
import useActions from 'hooks/useActions';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        answer: state.CSAnswers.selectedAnswer,
    };
}

const AnswerDetail = connect(mapStateToProps)(function (props) {
    const { answer } = props;
    console.log({answer});
    const [selectedTab, setSelectedTab] = useState();

    const { CS_FETCH_ANSWER_FILES_REQUEST } =
        useActions();

    function onSave() {
        setSelectedTab(0);
    }

    useEffect(() => {
        CS_FETCH_ANSWER_FILES_REQUEST(answer?.id);
    }, []);

    return (
        <section>
            <Tabs
                sectionNames={[
                    'Overview',
                    'Settings',
                    'Flags',
                    'Files',
                    'Translations',
                ]}
                sectionContents={[
                   <CSAnswerOverview />,
                   <CSAnswerEdit answer={answer} onConfirm={onSave} />,
                   <CSAnswerFlags />,
                   <CSAnswerFiles />,
                   <CSAnswerTranslations />,
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
});

export default AnswerDetail;
