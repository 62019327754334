import { useState } from 'react';
import { connect } from 'react-redux';
import useActions from 'hooks/useActions';

function mapStateToProps(state) {
    return {
        config: state.admins.config,
        user: state.user.user,
    };
}

const PECategoryEdit = connect(mapStateToProps)(function (props) {

    const { 
        config,
        user,
        category,
    } = props;
    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const [name, setName] = useState(category.name);
    const [region, setRegion] = useState(category.region_ident);

    const { PE_UPDATE_CATEGORY_REQUEST } = useActions();

    function saveChanges(event) {
        event.preventDefault();
        PE_UPDATE_CATEGORY_REQUEST({
            id: category.id,
            name,
            region_ident: region,
        });
    }

    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className='edit-table'>
                    <tr>
                        <td className="font-bold label">Name</td>
                        <td>
                            <input
                                name="name"
                                value={name}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                                required
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="font-bold label">Name</td>
                        <td>
                        <select
                            name="region_ident"
                            value={region}
                            onChange={(event) =>
                                setRegion(event.target.value)
                            }
                        >
                            {regionsToShow.map((reg) => (
                                <option
                                    key={`category-${reg}`}
                                    value={reg}
                                >
                                    {reg}
                                </option>
                            ))}
                        </select>
                        </td>
                    </tr>
                    {
                    (
                        name !== category.name ||
                        region !== category.region_ident 
                    ) && (
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Save Changes
                                </button>
                            </td>
                        </tr>
                    )}
                </table>
            </form>
        </section>
    );
});

export default PECategoryEdit;
