import { useState, useCallback, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import useActions from 'hooks/useActions';
import { connect } from 'react-redux';
import VHS_EVENTS_ACTIONS from 'constants/VHS_EVENTS_ACTIONS';
import xlsx from 'json-as-xlsx';
import LoadingIcon from '../../../../components/Icons/Loading';

const series_options = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Recorded Livestream Classes' },
    { value: 2, label: 'Pre-Recorded Classes' }
];

function mapStateToProps(state) {
    return {
        exportData: state.vhsAnalytics.exportData,
        isLoadingExportData: state.vhsAnalytics.isLoadingExportData,
    };
}

const AnalyticsExport =  connect(mapStateToProps)(function (props) {
    const { exportData, onClose, visible, isLoadingExportData } = props;
    const [ from, setFrom ] = useState('');
    const [ to, setTo ] = useState('');
    const [ seriesFilter, setSeriesFilter ] = useState( 0 )
    const [ actionsFilter, setActionsFilter ] = useState( "minutes_watched" );
    const { VHS_EXPORT_TRACKING_DATA_REQUEST, VHS_EXPORT_TRACKING_DATA_RESET } = useActions();

    useEffect(() => {
        if ( !isLoadingExportData && exportData?.data?.episode_minutes) {
            const data = [{
                sheet: "Total",
                columns: [
                    { label: "Time Period", value: () => `${from} - ${to}` },
                    { label: "Series", value: () => series_options[seriesFilter].label},
                    { label: `Total ${actionsFilter} Count`, value: "total_count" },
                    { label: `Total ${actionsFilter} Minutes`, value: "total_minutes"},
                ],
                content: [exportData.data]
            }, {
                sheet: "Episodes Breakdown",
                columns: [
                    { label: "Id", value: "id" },
                    { label: "Title", value: "title"},
                    { label: `${actionsFilter} Count`, value: "views" },
                    { label: `${actionsFilter} Minutes`, value: "minutes"},
                ],
                content: exportData.data?.episode_minutes
            }]

            const settings = {
                fileName: `${actionsFilter}/${from} - ${to}/${series_options[seriesFilter].label}`,
                writeMode: "writeFile"
            };

            const resetExportParameters = () => {
                setTo("");
                setFrom("");
                setActionsFilter("minutes_watched")
                setSeriesFilter(0)
                VHS_EXPORT_TRACKING_DATA_RESET()
            }

            resetExportParameters();
            xlsx(data, settings)
        }
    }, [isLoadingExportData, exportData, actionsFilter, from, to, seriesFilter, VHS_EXPORT_TRACKING_DATA_RESET])



    const exportFile =  useCallback ( async (e) => {
        e.preventDefault();
        const fetchTrackingData =  async ( ) => {
            VHS_EXPORT_TRACKING_DATA_REQUEST({
                action: actionsFilter,
                series_id: seriesFilter,
                from: from,
                to: to
            })
        }

        await fetchTrackingData()
    }, [VHS_EXPORT_TRACKING_DATA_REQUEST, actionsFilter, from, seriesFilter, to]);

    const onSelectSeries = ( event ) => setSeriesFilter(event.target.value)
    const onSelectAction = ( event ) => setActionsFilter( event.target.value )

    const handleClose = () => {
        setFrom("");
        setTo("")
        onClose();
    }

    return visible ? (
        <section className="modal-container">
            <div className="!p-10 modal-content w-6/12">
                <div className="modal-header">
                    <div className="flex-1">
                        <span className='text-lg font-bold'>
                            Analytics Export
                        </span>
                    </div>
                    <XIcon
                        className="h-6 ml-6 cursor-pointer"
                        onClick={handleClose}
                    />
                </div>
                { isLoadingExportData &&
                    <div className="h-full w-full absolute top-0 left-0 bg-gray-200 z-10 opacity-75 flex justify-center items-center">
                        <LoadingIcon/>
                    </div>
                }
                <form onSubmit={exportFile} className="w-full mt-4">
                    <table className='edit-table shadow-none'>
                        <tbody>
                            <tr>
                                <td className='text-bold label'>Action</td>
                                <td>
                                    <select
                                        name="action"
                                        value={actionsFilter}
                                        onChange={onSelectAction}
                                        className="w-full"
                                    >
                                        {VHS_EVENTS_ACTIONS.filter( e => e.value !== "all")
                                            .map((ser) => (
                                            <option
                                                key={`series-${ser.value}`}
                                                value={ser.value}
                                            >
                                                {ser.label}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className='text-bold label'>Series</td>
                                <td>
                                    <select
                                        name="series"
                                        value={seriesFilter}
                                        onChange={onSelectSeries}
                                        className="w-full"
                                    >
                                        {series_options.map((ser) => (
                                            <option
                                                key={`series-${ser.value}`}
                                                value={ser.value}
                                            >
                                                {ser.label}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className='text-bold label'>From</td>
                                <td>
                                    <input
                                        name="from"
                                        type="date"
                                        value={from}
                                        required
                                        onChange={(e) => setFrom(e.target.value)}
                                        className="w-full"
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td className='text-bold label'>To</td>
                                <td>
                                    <input
                                        name="to"
                                        type="date"
                                        value={to}
                                        required
                                        onChange={(e) => from < e.target.value && setTo(e.target.value)}
                                        className="w-full"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="submit" className="mt-4 button save-changes-button">Export</button>
                </form>
            </div>
        </section>
    ) : null;
});

export default AnalyticsExport;
