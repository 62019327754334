import DateField from 'components/DateField';
import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import useQueryList from 'hooks/useQuery';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import ViewsDetail from './ViewsDetail';
import LinkButton from 'components/LinkButton';

function mapStateToProps(state) {
    return {
        selectedProgram: state.views.program,
        per_page: state.views.per_page,
        page: state.views.page,
        total: state.views.total,
        total_pages: state.views.total_pages,
        views: state.views.list,
        selectedView: state.views.selectedView,
        brandOptions: state.brands.brandOptions,
        selectedPeriod: state.views.selectedPeriod,
        chartData: state.views.chartData,
        isLoading: state.views.isLoadingViews,
    };
}

const Views = connect(mapStateToProps)(function (props) {
    const {
        selectedProgram,
        per_page,
        page,
        total_pages,
        total,
        views,
        selectedView,
        brandOptions,
        selectedPeriod,
        chartData,
        isLoading,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);
    const [mappedChartData, setMappedChartData] = useState([
        { name: 'none', views: 0 },
    ]);

    const {
        FETCH_VIEWS_REQUEST,
        SELECT_VIEW,
        SELECT_VIEWS_PROGRAM,
        FETCH_VIEW_REQUEST,
        FETCH_VIEWS_CHART_REQUEST,
        SELECT_VIEWS_PERIOD,
    } = useActions();

    const { id } = useParams();
    const { program } = useQueryList(['program']);
    const navigate = useNavigate();

    const [brandsSet, setBrandsSet] = useState({});

    const onDetailSelection = (view) => {
        setDetailComponent(null);
        SELECT_VIEW(view);
        if (view) {
            navigate(`/referrals/views/${view.id}`);
        } else {
            FETCH_VIEWS_REQUEST({ per_page, page, selectedProgram });
            navigate(`/referrals/views`);
        }
    };

    const onNextPage = () => {
        FETCH_VIEWS_REQUEST({ per_page, page: page + 1, selectedProgram });
    };

    const onPrevPage = () => {
        FETCH_VIEWS_REQUEST({ per_page, page: page - 1, selectedProgram });
    };

    const onPageSelect = (pageSelected) => {
        FETCH_VIEWS_REQUEST({
            per_page,
            page: pageSelected,
            selectedProgram,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        FETCH_VIEWS_REQUEST({ per_page: perPage, selectedProgram });
    };

    const onPeriodSelected = ({ period }) => {
        SELECT_VIEWS_PERIOD({ selectedPeriod: period });
        FETCH_VIEWS_CHART_REQUEST({ selectedProgram, selectedPeriod: period });
    };

    const onProgramSelected = (programId) => {
        SELECT_VIEWS_PROGRAM(programId);
        FETCH_VIEWS_CHART_REQUEST({
            selectedProgram: programId,
            selectedPeriod,
        });
        FETCH_VIEWS_REQUEST({ selectedProgram: programId });
    };

    useEffect(() => {
        setMappedChartData([
            ...chartData.map((track) => ({
                name: track.name,
                value: track.views || 0,
                type: 'views',
            })),
        ]);
    }, [chartData]);

    useEffect(() => {
        const brOpts = {};
        brandOptions.forEach((element) => {
            brOpts[element.value] = element.label;
        });
        setBrandsSet(brOpts);
    }, [brandOptions]);

    useEffect(() => {
        if (selectedView) {
            const element = <ViewsDetail view={selectedView} />;
            setDetailComponent(element);
        } else {
            setDetailComponent(null);
        }
    }, [selectedView]);

    useEffect(() => {
        if (id) {
            try {
                const intId = Number.parseInt(id);
                FETCH_VIEW_REQUEST(intId);
            } catch (err) {
                console.log(err);
            }
        } else {
            SELECT_VIEW(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [views, id]);

    useEffect(() => {
        FETCH_VIEWS_REQUEST({ selectedProgram, per_page });
        FETCH_VIEWS_CHART_REQUEST({ selectedProgram, selectedPeriod });
        function selectProgram() {
            if (program) {
                onProgramSelected(program);
            }
        }
        selectProgram();
        return () => {
            SELECT_VIEW(null);
            setDetailComponent(null);
        };
        // eslint-disable-next-line
    }, []);

    const dataTable = () => {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Visitor</th>
                            <th>Program</th>
                            <th>Brand</th>
                            <th>Referring Member</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {views.map((view) => (
                            <tr key={`view-${view.id}`}>
                                <td>
                                    <DateField timeMillis={view.created_int} />
                                </td>
                                <td className="email">
                                    <SelectableField
                                        url={`/referrals/views/${view.id}`}
                                        action={() => onDetailSelection(view)}
                                        text={
                                            view.member?.email ||
                                            view.member?.uuid ||
                                            view.uuid
                                        }
                                    />
                                </td>
                                <td>
                                    <SelectableField
                                        url={`/referrals/programs/${view.program?.id}`}
                                        text={view.program?.name}
                                    />
                                </td>
                                <td>
                                    {brandsSet[view.brand_ident]}
                                </td>
                                <td className="email">
                                    <SelectableField
                                        url={`/referrals/members/${view.referring_member?.id}`}
                                        text={view.referring_member?.email}
                                    />
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        action={() => onDetailSelection(view)}
                                    >
                                        See Details
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPageSelected={onPageSelect}
                    onPrev={onPrevPage}
                />
            </section>
        );
    };

    return (
        <section>
            <InformationTab
                sectionName="Views"
                subsectionName={
                    selectedView?.member?.email || selectedView?.uuid
                }
                onDetailSelected={onDetailSelection}
                hasChart
                chartData={mappedChartData}
                dataTable={dataTable()}
                detailComponent={detailComponent}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                onProgramSelected={onProgramSelected}
                selectedProgram={selectedProgram}
                onPeriodSelected={onPeriodSelected}
                selectedPeriod={selectedPeriod}
            />
        </section>
    );
});

export default Views;
