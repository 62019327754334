import DetailOverview from 'components/DetailOverview';
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        comment: state.CSComments.selectedComment,
    };
}

const CSCommentOverview = connect(mapStateToProps)(function ({
    comment,
}) {

    return (
        <section>
        {comment.poster_url && (
                <img
                    alt={comment.title}
                    src={comment.poster_url}
                    style={{ maxWidth: '25rem' }}
                    className="mb-4 mt-4"
                />
            )}
        <DetailOverview
            fields={[
                { name: 'Body', value: comment.body, type: 'markdown' },
                { name: 'Posted By', value: comment.member.name },
                { name: 'Posted At', value: comment.posted_int, type: 'date' },
                { name: 'Visibility', value: comment.visibility },
                { name: 'Region', value: comment.region_ident },
                { name: 'Likes', value: comment.liked_count },
                { name: 'Flags', value: comment.flag_ids.length },
            ]}
        />
        </section>
    );
});

export default CSCommentOverview;
