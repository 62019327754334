import csTagsWorker from '../workers/csTagsWorker';

const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');

function* CS_TAGS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_TAG_GROUP_REQUEST,
            ACTION_TYPES.CS_TAG_REQUEST,
            ACTION_TYPES.CS_POST_TAG_GROUP_REQUEST,
            ACTION_TYPES.CS_POST_TAG_REQUEST,
            ACTION_TYPES.CS_UPDATE_TAG_REQUEST,
            ACTION_TYPES.CS_UPDATE_TAG_GROUP_REQUEST,
            ACTION_TYPES.CS_FETCH_TAG_GROUPS_REQUEST,
            ACTION_TYPES.CS_FETCH_ALL_TAG_GROUPS_REQUEST,
            ACTION_TYPES.CS_DELETE_TAG_GROUP_REQUEST,
            ACTION_TYPES.CS_DELETE_TAG_REQUEST,
        ],
        csTagsWorker,
        apiUrl,
        ...params
    );
}

export default CS_TAGS_REQUEST;
