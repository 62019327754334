import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import pePhasesWorker from '../workers/pePhasesWorker';

function* PE_PHASES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_PHASES_REQUEST,
            ACTION_TYPES.PE_GET_PHASE_REQUEST,
            ACTION_TYPES.PE_POST_PHASE_REQUEST,
            ACTION_TYPES.PE_UPDATE_PHASE_REQUEST,
            ACTION_TYPES.PE_DELETE_PHASE_REQUEST,
            ACTION_TYPES.PE_FETCH_PHASES_SEQUENCES_REQUEST,
            ACTION_TYPES.PE_ADD_PHASE_SEQUENCE_REQUEST,
            ACTION_TYPES.PE_REMOVE_PHASE_SEQUENCE_REQUEST,
        ],
        pePhasesWorker,
        apiUrl,
        ...params
    );
}

export default PE_PHASES_REQUEST;
