import Drawer from 'components/Drawer';
import React from 'react';
const version = process.env.REACT_APP_VERSION;

function RouteWrapper({ children, hideDrawer, contentClass = '' }) {
    return (
        <main>
            <section className="flex flex-row routes-container overflow-auto">
                {!hideDrawer && (
                    <Drawer />
                )}
                <div
                    className={`flex-grow p-4 ${
                        !hideDrawer ? 'md:pl-12' : 'p-8'
                    }  overflow-auto pt-8 ${contentClass}`}
                >
                    {children}
                </div>
            </section>
            <div className="footer-version">
                    {`Version ${version}`}
            </div>
        </main>
    );
}

export default RouteWrapper;
