import ACTION_TYPES from 'constants/ACTION_TYPES';
import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';

const initialState = {
    instructors: [],
    isLoadingInstructors: false,
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    selectedInstructor: null,
    activities: [],
    activites_page: 1,
    activities_per_page: 10,
    activities_total_pages: 1,
    activities_total: 0,
    isLoadingActivity: false,
    episodes: [],
    episodes_page: 1,
    episodes_per_page: 10,
    episodes_total_pages: 1,
    episodes_total: 0,
    isLoadingEpisodes: false,
    instructorsOptions: [],
    visibilityFilter: 'all',
    brandsFilter: 'all',
    actionsFilter: 'all',
    episodesOrder: VHS_EPISODES_SORTING[0].value,
    periodFilter: '30',
    chartData: [],
    history: [],
    history_page: 1,
    history_per_page: 10,
    history_total: 0,
    history_total_pages: 1,
    isLoadingHistory: false,
    recentReassignments: null
};

function VHSInstructorsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_OPTIONS_RESPONSE: {
            const options = action.data?.map((instructor) => ({
                value: instructor.id,
                label: instructor.name,
            }));
            return {
                ...state,
                instructorsOptions: options,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTORS_REQUEST: {
            return {
                ...state,
                isLoadingInstructors: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTORS_RESPONSE: {
            return {
                ...state,
                instructors: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingInstructors: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIONS_RESPONSE: {
            return {
                ...state,
                activities: action.data.list,
                activities_page: action.data.page,
                activities_per_page: action.data.per_page,
                activities_total_pages: action.data.total_pages,
                activities_total: action.data.total,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST: {
            return {
                ...state,
                isLoadingEpisodes: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_EPISODES_RESPONSE: {
            return {
                ...state,
                episodes: action.data.list,
                episodes_page: action.data.page,
                episodes_per_page: action.data.per_page,
                episodes_total_pages: action.data.total_pages,
                episodes_total: action.data.total,
                isLoadingEpisodes: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_RESPONSE: {
            return {
                ...state,
                selectedInstructor: action.data,
            };
        }
        case ACTION_TYPES.VHS_UPDATE_INSTRUCTOR_RESPONSE: {
            return {
                ...state,
                selectedInstructor: action.data,
            };
        }
        case ACTION_TYPES.VHS_POST_INSTRUCTOR_RESPONSE: {
            return {
                ...state,
                selectedInstructor: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_INSTRUCTOR: {
            return {
                ...state,
                selectedInstructor: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_INSTRUCTOR_BRANDS_FILTER: {
            return {
                ...state,
                page:0,
                brandsFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_INSTRUCTOR_VISIBILITY_FILTER: {
            return {
                ...state,
                page:0,
                visibilityFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_INSTRUCTOR_ACTIONS_FILTER: {
            return {
                ...state,
                page:0,
                actionsFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_INSTRUCTOR_EPISODES_ORDER: {
            return {
                ...state,
                episodesOrder: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_RESPONSE: {
            return {
                ...state,
                chartData: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_INSTRUCTOR_PERIOD_FILTER: {
            return {
                ...state,
                periodFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST:{
            return {
                ...state,
                isLoadingHistory: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_HISTORY_RESPONSE:{
            return {
                ...state,
                history: action.data.list,
                history_page: action.data.page,
                history_per_page: action.data.per_page,
                history_total: action.data.total,
                history_total_pages: action.data.total_pages,
                isLoadingHistory: false,
            };
        }
        case ACTION_TYPES.VHS_REPLACE_INSTRUCTORS_REQUEST: {
            return {
                ...state,
                recentReassignments: null
            };
        }
        case ACTION_TYPES.VHS_REPLACE_INSTRUCTORS_RESPONSE: {
            return {
                ...state,
                recentReassignments: action.data.updatedEpisodes
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSInstructorsReducer;
