import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import LocationDetails from './LocationsDetails';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        locations: state.vhsLocations.locations,
        isLoading: state.vhsLocations.isLoadingLocations,
        page: state.vhsLocations.page,
        total: state.vhsLocations.total,
        per_page: state.vhsLocations.per_page,
        total_pages: state.vhsLocations.total_pages,
        selectedLocation: state.vhsLocations.selectedLocation,
    };
}

const VHSLocations = connect(mapStateToProps)(function (props) {
    const {
        locations,
        per_page,
        page,
        total_pages,
        selectedLocation,
        total,
        isLoading,
    } = props;
    const navigate = useNavigate();

    const {
        VHS_FETCH_LOCATIONS_REQUEST,
        VHS_SELECT_LOCATION,
        VHS_FETCH_LOCATION_REQUEST,
    } = useActions();

    const { id } = useParams();

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState('');

    const onDetailSelection = (location) => {
        setSubtitle(null);
        setDetailComponent(null);
        VHS_SELECT_LOCATION(location);
        if (location) {
            navigate(`/vhs/locations/${location.id}`);
        } else {
            VHS_FETCH_LOCATIONS_REQUEST({ per_page, page });
            navigate(`/vhs/locations`);
        }
    };

    const onNextPage = () => {
        VHS_FETCH_LOCATIONS_REQUEST({ per_page, page: page + 1 });
    };

    const onPrevPage = () => {
        VHS_FETCH_LOCATIONS_REQUEST({ per_page, page: page - 1 });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_LOCATIONS_REQUEST({ per_page, page: pageSelected });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_LOCATIONS_REQUEST({ per_page: perPage });
    };

    useEffect(() => {
        if (selectedLocation) {
            setSubtitle(selectedLocation?.name);
            setDetailComponent(<LocationDetails />);
        } else {
            setDetailComponent(null);
            setSubtitle(null);
            VHS_FETCH_LOCATIONS_REQUEST({ per_page, page });
        }
        // eslint-disable-next-line
    }, [selectedLocation]);

    useEffect(() => {
        if (id) {
            try {
                VHS_FETCH_LOCATION_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            VHS_SELECT_LOCATION(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    const dataTable = function () {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable
                    isLoading={isLoading}
                    tableClass="table-fixed w-full text-left"
                >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Brand</th>
                            <th>Remote ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map((location) => (
                            <tr key={`location-${location.id}`}>
                                <td>
                                    <SelectableField
                                        text={location.name}
                                        url={`/vhs/locations/${location.id}`}
                                    />
                                </td>
                                <td>{`${location.address1}, ${location.address2} `}<br/>{`${location.city}, ${location.state} ${location.zip}`}</td>
                                <td style={{wordBreak:'break-word'}}>{location.email}</td>
                                <td>{location.phone}</td>
                                <td>{location.brand_name}</td>
                                <td>{location.alt_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                />
            </section>
        );
    };

    return (
        <section>
            <InformationTab
                sectionName="Locations"
                showPrograms={false}
                dataTable={dataTable()}
                onDetailSelected={onDetailSelection}
                detailComponent={detailComponent}
                subsectionName={subtitle}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
            />
        </section>
    );
});

export default VHSLocations;
