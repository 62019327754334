import DateField from 'components/DateField';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import LinkButton from 'components/LinkButton';
import { useNavigate } from 'react-router';


const ActivityRecord = function ({
    className = '',
    activities = [],
    page = 1,
    perPage = 10,
    totalPages = 1,
    onPerPageChange = function () {},
    onPageSelected = function () {},
    onNext = function () {},
    onPrev = function () {},
    showPrograms = true,
    title = 'All activity by this Visitor',
    isLoading = false,
}) {
    const perPageOptions = [10, 30, 100];
    const navigate = useNavigate();
    return (
        <section className={className}>
            <div className="flex justify-between mb-4">
                <span className="font-medium">{title}</span>
                <select
                    className="muuv-select"
                    value={perPage}
                    onChange={(event) => {
                        onPerPageChange(event.target.value);
                    }}
                >
                    {perPageOptions.map((option) => (
                        <option
                            key={`act-pag-${option}`}
                            value={option}
                        >{`${option} Per Page`}</option>
                    ))}
                </select>
            </div>
            <LoadingTable isLoading={isLoading} tableClass="">
                <thead>
                    <tr>
                        <th>Timestamp</th>
                        <th>Action</th>
                        {showPrograms && <th>Program</th>}
                        <th>Referring Member</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {activities.map((activity) => (
                        <tr key={`activity-${activity.id}`}>
                            <td>
                                <DateField timeMillis={activity.created_int} />
                            </td>
                            <td>{activity.action}</td>
                            {showPrograms && (
                                <td>
                                    <SelectableField
                                        url={`/referrals/programs/${activity.program?.id}`}
                                        text={activity.program.name}
                                    />
                                </td>
                            )}
                            <td>
                                <SelectableField
                                    url={`/referrals/members/${activity.referring_member?.id}`}
                                    text={activity.referring_member?.email}
                                />
                            </td>
                            <td>
                                <LinkButton
                                        className="table-view-button"
                                        action={() => navigate(`/referrals/views/${activity.id}`)}
                                    >
                                        See Details
                                 </LinkButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
            <TableNavigator
                currentPage={page}
                totalPages={totalPages}
                onPrev={onPrev}
                onNext={onNext}
                onPageSelected={onPageSelected}
            />
        </section>
    );
};

export default ActivityRecord;
