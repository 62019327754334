import TranslationsTable from 'components/Translations/TranslationsTable';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        assignment: state.PEAssignments.selectedAssignment,
        translations: state.PEAssignments.translations,
        page: state.PEAssignments.translations_page,
        per_page: state.PEAssignments.translations_per_page,
        total: state.PEAssignments.translations_total,
        total_pages: state.PEAssignments.translations_total_pages,
        isLoading: state.PEAssignments.translations_is_loading,
        selectedTranslation: state.PEAssignments.selectedTranslation,
    };
}

const PEAssignmentTranslations = connect(mapStateToProps)(function (props) {
    const {
        assignment,
        translations,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedTranslation,
    } = props;

    const {
        PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST,
        PE_POST_ASSIGNMENT_TRANSLATION_REQUEST,
        PE_UPDATE_ASSIGNMENT_TRANSLATION_REQUEST,
        PE_DELETE_ASSIGNMENT_TRANSLATION_REQUEST,
        PE_SELECT_ASSIGNMENT_TRANSLATION,
    } = useActions();

    function onNext() {
        PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST({
            assignmentId: assignment?.id,
            page: page + 1,
            per_page,
        });
    }

    function onPrev() {
        PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST({
            assignmentId: assignment?.id,
            page: page - 1,
            per_page,
        });
    }

    function onPageSelected(selectedPage) {
        PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST({
            assignmentId: assignment?.id,
            page: selectedPage,
            per_page,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST({
            assignmentId: assignment?.id,
            per_page: perPage,
        });
    }

    function onDelete(translation) {
        PE_DELETE_ASSIGNMENT_TRANSLATION_REQUEST({
            assignmentId: assignment?.id,
            page,
            per_page,
            translationId: translation.id,
        });
    }

    function onUpdate(translation, goToList = false) {
        PE_UPDATE_ASSIGNMENT_TRANSLATION_REQUEST({
            translation,
            assignmentId: assignment?.id,
            page,
            per_page,
            goToList,
        });
    }

    function onCreate(translation) {
        PE_POST_ASSIGNMENT_TRANSLATION_REQUEST({
            resourceId: assignment.id,
            translation,
        });
    }

    useEffect(() => {
        PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST({
            assignmentId: assignment?.id,
        });
        return () => {
            PE_SELECT_ASSIGNMENT_TRANSLATION(null);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <TranslationsTable
            page={page}
            perPage={per_page}
            onNext={onNext}
            onPrev={onPrev}
            onPageSelected={onPageSelected}
            onPerPageSelected={onPerPageSelected}
            isLoading={isLoading}
            total={total}
            totalPages={total_pages}
            onDelete={onDelete}
            translations={translations}
            onSelect={PE_SELECT_ASSIGNMENT_TRANSLATION}
            onCreate={onCreate}
            onUpdate={onUpdate}
            selectedTranslation={selectedTranslation}
            showBreadcrumbs={false}
        />
    );
});

export default PEAssignmentTranslations;
