import { CheckIcon } from '@heroicons/react/outline';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';

const { default: InformationTab } = require('components/InformationTab');
const { default: LoadingTable } = require('components/LoadingTable');
const { default: TableNavigator } = require('components/TableNavigator');
const { default: TableResults } = require('components/TableResults');
const { default: useActions } = require('hooks/useActions');
const { useEffect } = require('react');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        phases: state.PEPhases.phases,
        page: state.PEPhases.page,
        per_page: state.PEPhases.per_page,
        total: state.PEPhases.total,
        total_pages: state.PEPhases.total_pages,
        isLoading: state.PEPhases.isLoadingPhases,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEPhasesModalContent = connect(mapStateToProps)(function (props) {
    const {
        phases,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        onSelect = function () {},
        selectedRegionFilter,
    } = props;

    const { PE_FETCH_PHASES_REQUEST } = useActions();

    function onNext() {
        PE_FETCH_PHASES_REQUEST({
            page: page + 1,
            per_page,
        });
    }

    function onPrev() {
        PE_FETCH_PHASES_REQUEST({
            page: page - 1,
            per_page,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_PHASES_REQUEST({
            per_page,
            page: pageSelected,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_PHASES_REQUEST({
            per_page: perPage,
        });
    }

    useEffect(() => {
        PE_FETCH_PHASES_REQUEST();
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Plans</th>
                            <th>Sequences</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {phases.map((phase) => (
                            <tr>
                                <td>
                                    <SelectableField
                                        text={phase.name}
                                        url={`/program-engine/phases/${phase.id}`}
                                    />
                                </td>
                                <td>{phase.plans}</td>
                                <td>{phase.sequences}</td>
                                <td>
                                    <TableButton
                                        className="select-button"
                                        action={() => {
                                            onSelect(phase);
                                        }}
                                    >
                                        <CheckIcon
                                            height={'1.3rem'}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Select
                                    </TableButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <section className="p-4">
            <InformationTab
                dataTable={<DataTable />}
                showPrograms={false}
                sectionName="Phases"
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
            />
        </section>
    );
});

export default PEPhasesModalContent;
