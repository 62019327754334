import { useNavigate } from 'react-router-dom';

function LinkButton({ text, children, url, className, action }) {
    const navigate = useNavigate();

    const onClick = function (event) {
        event.preventDefault();
        if (action) {
            action();
        } else {
            navigate(url);
        }
    };

    return (
        <a className={`link-button ${className}`} href={url} onClick={onClick}>
            {children}
            {text}
        </a>
    );
}

export default LinkButton;
