import {
    //BanIcon,
    CheckIcon,
    PhotographIcon,
    PlayIcon,
} from '@heroicons/react/outline';
import DateField from 'components/DateField';
import FileUploader from 'components/FileUploader';
import FileFetcher from 'components/FileFetcher';
import LoadingTable from 'components/LoadingTable';
import ResourceModal from 'components/ResourceModal';
import useActions from 'hooks/useActions';
import { useState } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        episode: state.vhsEpisodes.selectedEpisode,
        files: state.vhsEpisodes.files,
        isLoading: state.vhsEpisodes.isLoadingFiles,
    };
}

const EpisodeFiles = connect(mapStateToProps)(function (props) {
    const { episode, files, isLoading } = props;
    const [showModal, setShowModal] = useState(false);
    const [showFetchFiles, setShowFetchFiles] = useState(false);
    const [resourceType, setResourceType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    //const { VHS_DELETE_EPISODE_FILE_REQUEST } = useActions();

    const { 
        VHS_FETCH_EPISODE_FILES_REQUEST,
        VHS_ASSIGN_ASSET_REQUEST,
        SET_MODAL_CONTENT,
        TOGGLE_MODAL,
    } = useActions();

    function openResource(file) {
        if (file.slot === 'video' && file.status === 'combining') return;
        setResourceType(file.slot);
        setSelectedFile(file);
        setShowModal(true);
    }

    function onClose() {
        setSelectedFile(null);
        setShowModal(false);
    }

    function onComplete() {
        VHS_FETCH_EPISODE_FILES_REQUEST(episode.id);
    }

    function onCloseUploadModal() {
        TOGGLE_MODAL(false);
    }

    function showNewUploadModal() {
        SET_MODAL_CONTENT(
            <FileUploader
                onClose={onCloseUploadModal}
                episodeId={episode.id}
                brandIdent={episode.brand_ident}
                onComplete={onComplete}
                isInModal={true}
            />
        )
        TOGGLE_MODAL(true);
    }

    function msToMMSS(ms) {
        var minutes = Math.floor(ms / 60000);
        var seconds = ((ms % 60000) / 1000).toFixed(0);
        return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    function fileSize(sizeBytes) {
        if (!sizeBytes) return '';
        if (sizeBytes < 1000000) {
            return (sizeBytes / 1024).toFixed(1) + ' kb';
        } else if (sizeBytes < 1000000000) {
            return (sizeBytes / 1048576).toFixed(1) + ' mb';
        } else {
            return (sizeBytes / 1073741824).toFixed(1) + ' gb';
        }
    }

    function assignAsset(file) {
        console.log({
            episodeId: episode.id,
            assetId: file.id,
        });
        VHS_ASSIGN_ASSET_REQUEST({
            episodeId: episode.id,
            assetId: file.id,
        });
    }

    function thumbnail(file) {
        switch (file.slot) {
            case 'video': {
                return (
                    <>
                        {file.status !== 'combining' && (
                            <div
                                style={{
                                    maxHeight: '4rem',
                                    maxWidth: '5rem',
                                    minHeight: '4rem',
                                    minWidth: '5rem',
                                    margin: '0 auto',
                                }}
                                className="bg-gray-200 flex justify-center items-center"
                            >
                                <PlayIcon height="2rem" />
                            </div>
                        )}
                        {file.status === 'combining' && (
                            <span className="font-bold">Processing...</span>
                        )}
                    </>
                );
            }
            case 'poster': {
                return (
                    <div
                        style={{
                            maxHeight: '4rem',
                            maxWidth: '5rem',
                            minHeight: '4rem',
                            minWidth: '5rem',
                            margin: '0 auto',
                        }}
                        className="bg-gray-200 flex justify-center items-center"
                    >
                        {file.media && (
                            <img
                                src={file.media}
                                style={{
                                    maxHeight: '4rem',
                                    maxWidth: '5rem',
                                    minHeight: '4rem',
                                    minWidth: '5rem',
                                    margin: '0 auto',
                                }}
                                alt={file.name}
                            />
                        )}
                        {!file.media && <PhotographIcon height="2rem" />}
                    </div>
                );
            }
            case 'audio': {
                return (
                    <div
                        style={{
                            maxHeight: '4rem',
                            maxWidth: '5rem',
                            minHeight: '4rem',
                            minWidth: '5rem',
                            margin: '0 auto',
                        }}
                        className="bg-gray-200 block flex justify-center items-center"
                    >
                        <PlayIcon height="2rem" />
                    </div>
                );
            }
            default:
                return <span className="font-bold">View</span>;
        }
    }

    function assignement(file) {
        switch (file.slot) {
            case 'video':
                return (
                    <div>
                        {episode.video_id === file.id ? (
                            <>
                                {/*<button className="custom-button unassign-background">
                                <BanIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />{' '}
                                Unassign
                        </button>*/}
                            </>
                        ) : (
                            <button
                                className="custom-button reencode-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Video
                            </button>
                        )}
                    </div>
                );
            case 'poster':
                return (
                    <div>
                        {episode.poster_id === file.id ? (
                            <>
                                {/*<button className="custom-button unassign-background">
                                <BanIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />{' '}
                                Unassign
                        </button>*/}
                            </>
                        ) : (
                            <button
                                className="custom-button make-poster-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Poster
                            </button>
                        )}
                    </div>
                );
            case 'audio':
                return (
                    <div>
                        {episode.audio_id === file.id ? (
                            <>
                                {/*<button className="custom-button unassign-background">
                                <BanIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />{' '}
                                Unassign
                        </button>*/}
                            </>
                        ) : (
                            <button
                                className="custom-button make-poster-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Audio
                            </button>
                        )}
                    </div>
                );
            case 'transcript':
                return (
                    <div>
                        {episode.transcript_id === file.id ? (
                            <>
                                {/*<button className="custom-button unassign-background">
                                <BanIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />{' '}
                                Unassign
                        </button>*/}
                            </>
                        ) : (
                            <button
                                className="custom-button make-poster-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Transcript
                            </button>
                        )}
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <section>
            {showFetchFiles && (
                <div className="mb-4">
                    <FileFetcher
                        onClose={() => setShowFetchFiles(false)}
                        episodeId={episode.id}
                        brandIdent={episode.brand_ident}
                        onComplete={onComplete}
                    />
                </div>
            )}
            <div className="flex justify-between items-center mb-4">
                <div className="font-bold">Primary Files</div>
                <div>
                    <button
                        className="bg-green-add p-2 mr-4 text-white"
                        onClick={() => setShowFetchFiles(true)}
                    >
                        Fetch Recording Files
                    </button>
                    <button
                        className="button add-button p-2 mr-4 text-white"
                        onClick={showNewUploadModal}
                    >
                        Upload New File
                    </button>
                </div>
            </div>
            <LoadingTable isLoading={isLoading} tableClass=" ">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>View</th>
                        <th>Assignment</th>
                        <th>File Type</th>
                        <th>Source</th>
                        <th>Created</th>
                        <th>Duration</th>
                        <th>Size</th>
                        {/*<th></th>*/}
                    </tr>
                </thead>
                <tbody>
                    {files.map((file) => (
                        <tr
                            key={`file-${file.id}`}
                            className={`${
                                [
                                    episode.hls_id,
                                    episode.audio_id,
                                    episode.poster_id,
                                    episode.transcript_id,
                                    episode.video_id,
                                ].includes(file.id)
                                    ? 'highlighted-tr'
                                    : ''
                            }`}
                        >
                            <td>{file.id}</td>
                            <td
                                onClick={() => openResource(file)}
                                className="cursor-pointer text-center font-bold"
                            >
                                {thumbnail(file)}
                            </td>
                            <td>{assignement(file)}</td>
                            <td>
                                <div className="font-bold">{file.slot}</div>
                                <div>{file.mime_type}</div>
                            </td>
                            <td>{file.parent_id}</td>
                            <td>
                                <DateField timeMillis={file.created_int} />
                            </td>
                            <td>
                                {file.duration ? msToMMSS(file.duration) : ''}
                            </td>
                            <td>{fileSize(file.file_size)}</td>
                            {/*<td>
                                {![
                                    episode.hls_id,
                                    episode.audio_id,
                                    episode.poster_id,
                                    episode.transcript_id,
                                    episode.video_id,
                                ].includes(file.id) && (
                                    <button
                                        className="button delete-button"
                                        onClick={() =>
                                            VHS_DELETE_EPISODE_FILE_REQUEST(
                                                file.id
                                            )
                                        }
                                    >
                                        Delete
                                    </button>
                                )}
                                    </td>*/}
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>

            <ResourceModal
                onClose={onClose}
                type={resourceType}
                visible={showModal}
                file={selectedFile}
            />
        </section>
    );
});

export default EpisodeFiles;
