const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: peMatricesWorker } = require('../workers/peMatricesWorker');

function* PE_MATRICES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_MATRICES_REQUEST,
            ACTION_TYPES.PE_GET_MATRIX_REQUEST,
            ACTION_TYPES.PE_UPDATE_MATRIX_REQUEST,
            ACTION_TYPES.PE_POST_MATRIX_REQUEST,
            ACTION_TYPES.PE_DELETE_MATRIX_REQUEST,
            ACTION_TYPES.PE_CLONE_MATRIX_REQUEST,
            ACTION_TYPES.PE_GET_MATRIX_CONFIG_REQUEST,
            ACTION_TYPES.PE_GET_MATRIX_RESULTS_REQUEST,
        ],
        peMatricesWorker,
        apiUrl,
        ...params
    );
}

export default PE_MATRICES_REQUEST;
