import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchComments(page = 1, per_page = 10, articleId, searchTerm = '', sorting = 'all', apiUrl){
    let url = `/community/posts?post_type=comment&include=flags&parent_id=${articleId}&page=${page}&per_page=${per_page}`;
    if (!!searchTerm) {
        url += `&term=${searchTerm}`;
    }
    if (sorting !== 'all') {
        url += `&sort=${sorting}`;
    }
    const json = yield api.get(url, apiUrl, 'all', SERVICE_TYPES.COMMUNITY);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_COMMENTS_PER_ARTICLE_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postComment(comment, apiUrl) {
    const json = yield api.post(`/community/posts`, comment, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_COMMENT_RESPONSE,
            data: json,
        });
    }
}

function* updateComment(comment, apiUrl) {
    const { id } = comment;
    delete comment.id;
    const json = yield api.put(`/community/posts/${id}`, comment, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_COMMENT_RESPONSE,
            data: json,
        });
    }
}

function* deleteComment(commentId,apiUrl) {
    const json = yield api.del(`/community/posts/${commentId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_DELETE_COMMENT_RESPONSE,
            data: json,
        });
    }
}

function* getComment(commentId, apiUrl) {
    const json = yield api.get(
        `/community/posts/${commentId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_COMMENT_RESPONSE,
            data: json,
        });
    }
}

function* fetchFlags(page = 1, per_page = 10, commentId, status = 'all', sorting = 'all', apiUrl){
    let url = `/community/flags?resource_type=Post&parent_id=${commentId}&page=${page}&per_page=${per_page}`;
    if (sorting !== 'all') {
        url += `&sort=${sorting}`;
    }
    if (status !== 'all') {
        url += `&status=${status}`;
    }
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_COMMENT_FLAGS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* updateReasonFlags(flag,apiUrl){
    const { id } = flag;
    delete flag.id;
    const json = yield api.put(`/community/flags/${id}`, flag, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_COMMENT_FLAGS_REASON_RESPONSE,
            data: json,
        });
    }
}

function* getCommentFiles(commentId, apiUrl) {
    const json = yield api.get(
        `/community/assets?assetable_type=Post&assetable_id=${commentId}&per_page=100`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_COMMENT_FILES_RESPONSE,
            data: json.results,
        });
    }
}

function* postAsset(commentId, brandIdent, asset_type, slot, file, done, apiUrl) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brand_ident', brandIdent);
    formData.append('assetable_id', commentId);
    formData.append('asset_type', asset_type);
    formData.append('slot', slot);
    formData.append('assetable_type', 'Comment');
    const json = yield api.postForm('/community/assets',formData,apiUrl);
    if (json.status_code && ![200, 201, 202].includes(json.status_code)) {
        alert(json.message);
    } else {
        if (done) done();

        yield getCommentFiles(commentId, apiUrl);
    }
}

function* deleteAsset(assetId, commentId, apiUrl) {
    const json = yield api.del('/community/assets/' + assetId, apiUrl);
    if (json) {
        console.log('file Deleted');
        if(commentId){
            yield getCommentFiles(commentId, apiUrl);
        }
    }
}

function* assignAsset(commentId, assetId, apiUrl) {
    const json = yield api.post(`/community/assets/${assetId}/assign`, {}, apiUrl);
    if (json) {
        yield getComment(commentId, apiUrl);
    }
}
function* unAssignAsset(commentId, assetId, apiUrl) {
    const json = yield api.post(`/community/assets/${assetId}/unassign`, {}, apiUrl);
    if (json) {
        yield getComment(commentId, apiUrl);
    }
}

function* fetchTranslations(commentId, page = 1, per_page = 10, apiUrl) {
    const json = yield api.get(
        `/community/translations?resource_type=comment&resource_id=${commentId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_COMMENT_TRANSLATIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postTranslation(resource_id, translation, apiUrl) {
    const json = yield api.post(
        `/community/translations`,
        {
            resource_id,
            resource_type: 'Post',
            ...translation,
        },
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_COMMENT_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}

function* deleteTranslation(
    translationId,
    commentId,
    page,
    perPage,
    apiUrl
) {
    const json = yield api.del(`/community/translations/${translationId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_SELECT_COMMENT_TRANSLATION,
            data: null,
        });
        yield fetchTranslations(commentId, page, perPage, apiUrl);
    }
}

function* updateTranslation(translation, apiUrl) {
    const json = yield api.put(
        `/community/translations/${translation.id}`,
        translation,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_COMMENT_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}


function* csCommentsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST:
            yield fetchComments(
                action.data?.page,
                action.data?.per_page,
                action.data?.articleId,
                action.data?.searchTerm,
                action.data?.sorting,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FETCH_COMMENT_REQUEST:
            yield getComment(action.data, apiUrl)
            break;
        case ACTION_TYPES.CS_POST_COMMENT_REQUEST:
            yield postComment(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_UPDATE_COMMENT_REQUEST:
            yield updateComment(
                action.data,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_DELETE_COMMENT_REQUEST:
            yield deleteComment(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_FETCH_COMMENT_FLAGS_REQUEST:
            yield fetchFlags(
                action.data?.page,
                action.data?.per_page,
                action.data?.commentId,
                action.data?.status,
                action.data?.sorting,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UPDATE_COMMENT_FLAGS_REASON_REQUEST:
            yield updateReasonFlags(
                action.data,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FETCH_COMMENT_FILES_REQUEST:
                yield getCommentFiles(action.data, apiUrl);
                break;
        case ACTION_TYPES.CS_POST_COMMENT_FILE_REQUEST:
            yield postAsset(
                action.data?.commentId,
                action.data?.brandIdent,
                action.data?.assetType,
                action.data?.slot,
                action.data?.file,
                action.data?.done,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_DELETE_COMMENT_FILE_REQUEST:
            yield deleteAsset(
                action.data?.assetId,
                action.data?.commentId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_ASSIGN_COMMENT_ASSET_REQUEST:
            yield assignAsset(
                action.data?.commentId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UNASSIGN_COMMENT_ASSET_REQUEST:
            yield unAssignAsset(
                action.data?.commentId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FETCH_COMMENT_TRANSLATIONS_REQUEST:
            yield fetchTranslations(
                action.data?.commentId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_POST_COMMENT_TRANSLATION_REQUEST:
            yield postTranslation(
                action.data?.resourceId,
                action.data?.translation,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_DELETE_COMMENT_TRANSLATION_REQUEST:
            yield deleteTranslation(
                action.data?.translationId,
                action.data?.commentId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UPDATE_COMMENT_TRANSLATION_REQUEST:
            yield updateTranslation(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default csCommentsWorker;
