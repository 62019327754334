import ACTION_TYPES from 'constants/ACTION_TYPES';
import moment from 'moment';
import { put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchDashboardChartData(apiUrl) {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(30, 'd');
    const initialMillis = today.toDate().getTime();
    const json = yield api.get(
        `/vhs/chart?actions=play-minutes_watched-zoom_click&start_int=${initialMillis}&stop_int=${todayMillis}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_DASHBOARD_CHART_DATA_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchRecentEpisodes(apiUrl) {
    let url = `/vhs/episodes?per_page=${5}&sort=start_at-DESC`;
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_RECENT_EPISODES_RESPONSE,
            data: json.results,
        });
    }
}

function* createAssetFromZoomMeetings(data, apiUrl) {
    const json = yield api.post(`/vhs/admin/create_zoom_assets_for_meetings`, data, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_RESPONSE,
            data: json,
        });
    }
}
function* createAssetFromZoomMeeting(data, apiUrl) {
    const json = yield api.post(`/vhs/admin/create_asset_from_zoom`, data, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_CREATE_ASSET_FROM_ZOOM_RESPONSE,
            data: json,
        });
    }
}
function* createEpisodeFromClass (data, apiUrl) {
    const json = yield api.post(`/vhs/admin/create_episode_from_class`, data, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_CREATE_EPISODE_FROM_CLASS_RESPONSE,
            data: json,
        });
    }
}

function* vhsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_REQUEST: {
            yield createAssetFromZoomMeetings(action.data, apiUrl);
            break;
        }
        case ACTION_TYPES.VHS_CREATE_EPISODE_FROM_CLASS_REQUEST: {
            yield createEpisodeFromClass(action.data, apiUrl);
            break;
        }
        case ACTION_TYPES.VHS_CREATE_ASSET_FROM_ZOOM_REQUEST: {
            yield createAssetFromZoomMeeting(action.data, apiUrl);
            break;
        }
        case ACTION_TYPES.VHS_FETCH_RECENT_EPISODES_REQUEST: {
            yield fetchRecentEpisodes(apiUrl);
            break;
        }
        case ACTION_TYPES.VHS_FETCH_DASHBOARD_CHART_DATA_REQUEST: {
            yield fetchDashboardChartData(apiUrl);
            break;
        }
        default:
            break;
    }
}

export default vhsWorker;
