const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: csMembersWorker } = require('../workers/csMembersWorker');

function* CS_MEMBERS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_FETCH_MEMBERS_REQUEST,
            ACTION_TYPES.CS_GET_MEMBER_REQUEST,
            ACTION_TYPES.CS_FETCH_EXPERT_COACHES_REQUEST,
        ],
        csMembersWorker,

        apiUrl,
        ...params
    );
}

export default CS_MEMBERS_REQUEST;
