import { PlusIcon, XIcon } from '@heroicons/react/outline';
import SelectableField from 'components/SelectableField';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddMatrixRule from '../AddMatrixRule';
import MatrixResultsDetails from '../MatrixResultDetails';

function mapStateToProps(state) {
    return {
        matrix: state.PEMatrices.selectedMatrix,
    };
}

const PEMatrixOverview = connect(mapStateToProps)(function (props) {
    const { matrix } = props;
    const [updatedMatrix, setUpdatedMatrix] = useState(matrix);
    const {
        TOGGLE_MODAL,
        SET_MODAL_CONTENT,
        PE_UPDATE_MATRIX_REQUEST,
        SET_MODAL_STYLE,
    } = useActions();

    const smallModalStyle = {
        height: '25rem',
        width: '40rem',
    };

    function GreenPlusIcon({
        className = '',
        style = {},
        action = function () {},
    }) {
        return (
            <div
                className={`cursor-pointer bg-green-500 flex items-center justify-center w-8 h-8 rounded-full ${className}`}
                style={style}
                onClick={action}
            >
                <PlusIcon color="white" className="h-4" />
            </div>
        );
    }

    function RedRemoveIcon({ onClick }) {
        return (
            <div
                className="cursor-pointer bg-green-500 flex items-center justify-center w-8 h-8 rounded-full"
                style={{ backgroundColor: '#f24726' }}
                onClick={onClick}
            >
                <XIcon
                    color="white"
                    className="h-4 min-h-4 min-w-4"
                    style={{ minWidth: '2rem' }}
                />
            </div>
        );
    }

    function Rule({
        parameter = 'Category',
        operator = '=',
        value = 'Workouts - Anytime Fitness',
        className = '',
        onRemove = function () {},
    }) {
        return (
            <div className={`flex text-white m-1 ${className}`}>
                <div
                    className="pl-4 pr-2 flex items-center rounded-l-full"
                    style={{ backgroundColor: 'rgb(65, 141, 209)' }}
                >
                    {parameter}
                </div>
                <div
                    className="px-2 ml-1 mr-1 flex items-center"
                    style={{ backgroundColor: 'rgb(191, 145, 69)' }}
                >
                    {operator}
                </div>
                <div
                    className="pr-4 pl-2 flex items-center rounded-r-full mr-1"
                    style={{ backgroundColor: 'rgb(68, 68, 68)' }}
                >
                    {value}
                </div>
                <RedRemoveIcon onClick={onRemove} />
            </div>
        );
    }

    function addGlobalRule(rule) {
        const newMatrix = { ...updatedMatrix };
        newMatrix.rules_obj.global.push(rule);
        PE_UPDATE_MATRIX_REQUEST(newMatrix);
        TOGGLE_MODAL(false);
    }

    function removeGlobalRule(rule) {
        const newMatrix = { ...updatedMatrix };
        newMatrix.rules_obj.global = newMatrix.rules_obj.global.filter(
            (globalRule) => globalRule !== rule
        );
        PE_UPDATE_MATRIX_REQUEST(newMatrix);
    }

    function addGlobal() {
        SET_MODAL_CONTENT(<AddMatrixRule onConfirm={addGlobalRule} />);
        SET_MODAL_STYLE(smallModalStyle);
        TOGGLE_MODAL(true);
    }

    function addColumnRule(index, rule) {
        const newMatrix = { ...updatedMatrix };
        newMatrix.rules_obj.columns[index].push(rule);
        PE_UPDATE_MATRIX_REQUEST(newMatrix);
        TOGGLE_MODAL(false);
    }

    function removeColumnRule(index, rule) {
        const newMatrix = { ...updatedMatrix };
        newMatrix.rules_obj.columns[index] = newMatrix.rules_obj.columns[
            index
        ].filter((colRule) => colRule !== rule);
        PE_UPDATE_MATRIX_REQUEST(newMatrix);
    }

    function addToColumn(index) {
        SET_MODAL_CONTENT(
            <AddMatrixRule onConfirm={(rule) => addColumnRule(index, rule)} />
        );
        SET_MODAL_STYLE(smallModalStyle);
        TOGGLE_MODAL(true);
    }

    function addRowRule(index, rule) {
        const newMatrix = { ...updatedMatrix };
        newMatrix.rules_obj.rows[index].push(rule);
        PE_UPDATE_MATRIX_REQUEST(newMatrix);
        TOGGLE_MODAL(false);
    }

    function removeRowRule(index, rule) {
        const newMatrix = { ...updatedMatrix };
        newMatrix.rules_obj.rows[index] = newMatrix.rules_obj.rows[
            index
        ].filter((rowRule) => rowRule !== rule);
        PE_UPDATE_MATRIX_REQUEST(newMatrix);
    }

    function addToRow(index) {
        SET_MODAL_CONTENT(
            <AddMatrixRule onConfirm={(rule) => addRowRule(index, rule)} />
        );
        SET_MODAL_STYLE(smallModalStyle);
        TOGGLE_MODAL(true);
    }

    function showResultsDetails(queryResults,xAxisIndex,yAxisIndex) {
        SET_MODAL_CONTENT(
            <MatrixResultsDetails query={queryResults} xAxis={xAxisIndex} yAxis={yAxisIndex}/>
        );
        TOGGLE_MODAL(true);
    }

    useEffect(() => {
        function refreshMatrix() {
            if (matrix) {
                const matrixObj = { ...matrix };
                if (!matrixObj.rules_obj) {
                    matrixObj.rules_obj = {
                        global: [],
                        columns: [[], [], [], []],
                        rows: [[], [], [], [], []],
                    };
                }
                setUpdatedMatrix(matrixObj);
            }
        }
        refreshMatrix();
    }, [matrix]);

    return (
        <section>
            <div>
                <span className="text-2xl font-bold mr-4">General Rules</span>
                {matrix?.rules_obj?.global?.map((globalRule) => (
                    <Rule
                        key={`gen-rule-${globalRule.join('-')}`}
                        className="text-xs"
                        parameter={globalRule[0]}
                        operator={globalRule[1]}
                        value={globalRule[3] ? globalRule[3] : globalRule[2]}
                        onRemove={() => removeGlobalRule(globalRule)}
                    />
                ))}
                <GreenPlusIcon action={addGlobal} />
            </div>
            <div className="mt-8">
                <div className="mb-8 font-bold text-2xl">Table</div>
                <table>
                    <thead>
                        <tr>
                            <th>User Preferences</th>
                            {updatedMatrix?.rules_obj?.columns?.map(
                                (col, index) => (
                                    <th
                                        key={`col-${index}`}
                                        className="relative"
                                    >
                                        <GreenPlusIcon
                                            className="top-header-icon absolute"
                                            style={{
                                                top: '-2rem',
                                                left: 'calc(50% - 1rem)',
                                            }}
                                            action={() => addToColumn(index)}
                                        />
                                        {col.map((colRul) => (
                                            <Rule
                                                key={`col-${index}-rule-
                                                    ${colRul.join('-')}`}
                                                parameter={colRul[0]}
                                                operator={colRul[1]}
                                                value={
                                                    colRul[3]
                                                        ? colRul[3]
                                                        : colRul[2]
                                                }
                                                className="text-xs leading-none"
                                                onRemove={() =>
                                                    removeColumnRule(
                                                        index,
                                                        colRul
                                                    )
                                                }
                                            />
                                        ))}
                                    </th>
                                )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {updatedMatrix?.rules_obj?.rows?.map((row, index) => (
                            <tr key={`row-${index}`} className="h-12">
                                <td className="relative">
                                    <GreenPlusIcon
                                        className="top-header-icon absolute"
                                        style={{
                                            left: '-2rem',
                                            top: 'calc(50% - 1rem)',
                                        }}
                                        action={() => addToRow(index)}
                                    />
                                    {row.map((rule) => (
                                        <Rule
                                            key={`row-${index}-rule-${rule.join(
                                                '-'
                                            )}`}
                                            parameter={rule[0]}
                                            operator={rule[1]}
                                            value={rule[3] ? rule[3] : rule[2]}
                                            className="text-xs leading-none"
                                            onRemove={() =>
                                                removeRowRule(index, rule)
                                            }
                                        />
                                    ))}
                                </td>
                                {updatedMatrix?.result_counts[index]?.map(
                                    (countObj, subindex) => (
                                        <td key={`row-${index}-count-${subindex}`}>
                                         {countObj.n > 0 ?
                                            <SelectableField
                                                text={`${countObj.n} results`}
                                                action={() => showResultsDetails(countObj.q,index,subindex)}
                                            />
                                            :
                                            `${countObj.n} results`
                                        }
                                        </td>
                                    )
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    );
});

export default PEMatrixOverview;
