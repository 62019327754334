import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import useActions from 'hooks/useActions';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        brandsOptions: state.PEBrands.brandsOptions,
        config: state.admins.config,
        user: state.user.user,
    };
}

const PEProgramsNew = connect(mapStateToProps)(function (props) {
    const { brandsOptions, config, user } = props;
    const { PE_POST_PROGRAM_REQUEST } = useActions();

    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const initialState = {
        name: '',
        brand_ident: null,
        region_ident: null,
        start_date: null,
        is_published: false,
        is_showing_in_enrolled: false
    };
    const [program, setProgram] = useState(initialState);
    const [description,setDescription] = useState('');

    function handleFieldChange(event) {
        let newProgram = {};
            newProgram = {
                ...program,
                [event.target.name]: event.target.value,
            };
        setProgram(newProgram);
    }

    useEffect(() => {
        if (regionsToShow && regionsToShow.length > 0) {
            setProgram((program) => ({
                ...program,
                region_ident: regionsToShow[0],
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onCreate(event) {
        event.preventDefault();
        PE_POST_PROGRAM_REQUEST({
            ...program,
            description,
            brand_id: program.brand_ident,
            start_date: program.start_date,
            is_published: program.is_published,
            is_showing_in_enrolled: program.is_showing_in_enrolled
        });
    }

    return (
        <section>
            <form onSubmit={onCreate}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={program.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Technical Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="technical_name"
                                    value={program.technical_name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Description
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={description}
                                    onChange={setDescription}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select 
                                    name="brand_ident" 
                                    onChange={handleFieldChange}
                                >
                                    <option value={"None"} defaultChecked>None</option>
                                    {brandsOptions.map((brand) => (
                                        
                                            <option
                                                id={`brand-${brand.value}`}
                                                key={`brand-${brand.value}`}
                                                value={brand.value}
                                            >
                                            {brand.label}
                                            </option>

                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <select 
                                    name="region_ident" 
                                    value={program.region_ident}
                                    onChange={handleFieldChange}
                                >
                                {regionsToShow.map((reg) => (
                                    <option
                                        key={`program-${reg}`}
                                        value={reg}
                                    >
                                        {reg}
                                    </option>
                                ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                <span className="block">Start Date</span>
                            </td>
                            <td>
                                <input
                                    type="date"
                                    name="start_date"
                                    value={program.start_date}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Showing in Coaching Dashboard</td>
                            <td>
                            <select name="is_published" defaultValue={program.is_published} onChange={handleFieldChange}>
                                <option value="false">Unpublished</option>
                                <option value="true">Published</option>
                            </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Showing in Enrolled Programs</td>
                            <td>
                            <select name="is_showing_in_enrolled" onChange={handleFieldChange} defaultValue={program.is_showing_in_enrolled}>
                                <option value="false">Unpublished</option>
                                <option value="true">Published</option>
                                
                            </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Program
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default PEProgramsNew;
