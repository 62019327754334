import ACTION_TYPES from 'constants/ACTION_TYPES';
import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';
import VHS_EVENTS_ACTIONS from 'constants/VHS_EVENTS_ACTIONS';
import moment from 'moment';
import { put } from 'redux-saga/effects';
import api from '../api/api';

function* getSeriesOptions(apiUrl) {
    const json = yield api.get('/vhs/series?per_page=1000', apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_SERIES_OPTIONS_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchSeries(
    page = 1,
    per_page = 10,
    visibility = 'all',
    brands = 'all',
    apiUrl,
    searchTerm = ''
) {
    let url = `/vhs/series?page=${page}&per_page=${per_page}`;
    if (visibility !== 'all') {
        url += `&visibility=${visibility}`;
    }
    if (brands !== 'all') {
        url += `&brands=${brands}`;
    }
    if(!!searchTerm){
        url += `&term=${searchTerm}`;
    }

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_SERIES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* fetchSeriesAnalytics(
    page = 1,
    per_page = 10,
    seriesId,
    actions = VHS_EVENTS_ACTIONS[0].value,
    apiUrl
) {
    let url = `/vhs/track_events?page=${page}&per_page=${per_page}&series=${seriesId}`;
    if (actions !== 'all') {
        url += `&actions=${actions}`;
    }
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_SERIES_ACTIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* fetchSeriesEpisodes(
    page = 1,
    per_page = 10,
    seriesId,
    order = VHS_EPISODES_SORTING[0].value,
    apiUrl
) {
    const json = yield api.get(
        `/vhs/episodes?page=${page}&per_page=${per_page}&series=${seriesId}&sort=${order}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_SERIES_EPISODES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* postSeries(series, apiUrl) {
    const json = yield api.post(`/vhs/series`, series, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_POST_SERIES_RESPONSE,
            data: json,
        });
    }
}

function* getSeries(seriesId, apiUrl) {
    const json = yield api.get(`/vhs/series/${seriesId}`, apiUrl);
    if (json) {
        yield put({ type: ACTION_TYPES.VHS_GET_SERIES_RESPONSE, data: json });
    }
}

function* updateSeries(series, apiUrl) {
    const { id } = series;
    delete series.id;
    const json = yield api.put(`/vhs/series/${id}`, series, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_UPDATE_SERIES_RESPONSE,
            data: json,
        });
    }
}

function* deleteSeries(seriesId, apiUrl) {
    const json = yield api.del(`/vhs/series/${seriesId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_SELECT_SERIES,
            data: null,
        });
        yield fetchSeries(null, null, null, null, apiUrl);
    }
}

function* getChartData(days = 30, actions = 'all', seriesId, apiUrl) {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(Number.parseInt(days), 'd');
    const initialMillis = today.toDate().getTime();

    let url = `/vhs/chart?stop_int=${todayMillis}&start_int=${initialMillis}&series=${seriesId}`;

    actions !== 'all' && (url += `&actions=${actions}`);
    days === '1' && (url += '&group_by=hour');

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_SERIES_ACTIVITY_CHART_RESPONSE,
            data: json.results,
        });
    }
}

function* getHistory(page = 1, per_page = 10, seriesId, apiUrl) {
    const json = yield api.get(
        `/vhs/activities?affected_type=Series&affected_id=${seriesId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_SERIES_HISTORY_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* vhsSeriesWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_SERIES_OPTIONS_REQUEST:
            yield getSeriesOptions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_SERIES_REQUEST:
            yield fetchSeries(
                action.data?.page,
                action.data?.per_page,
                action.data?.visibility,
                action.data?.brand,
                apiUrl,
                action.data?.searchTerm,
            );
            break;
        case ACTION_TYPES.VHS_FETCH_SERIES_ACTIONS_REQUEST:
            yield fetchSeriesAnalytics(
                action.data?.page,
                action.data?.per_page,
                action.data?.seriesId,
                action.data?.actions,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_SERIES_EPISODES_REQUEST:
            yield fetchSeriesEpisodes(
                action.data?.page,
                action.data?.per_page,
                action.data?.seriesId,
                action.data?.order,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_POST_SERIES_REQUEST:
            yield postSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_GET_SERIES_REQUEST:
            yield getSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_SERIES_REQUEST:
            yield updateSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_DELETE_SERIES_REQUEST:
            yield deleteSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_SERIES_ACTIVITY_CHART_REQUEST:
            yield getChartData(
                action.data?.period,
                action.data?.actions,
                action.data?.seriesId,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_SERIES_HISTORY_REQUEST: {
            yield getHistory(
                action.data?.page,
                action.data?.per_page,
                action.data?.seriesId,
                apiUrl
            )
            break;
        }
        default:
            break;
    }
}

export default vhsSeriesWorker;
