const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');
const { default: api } = require('../api/api');

function* getTagGroupOptions(apiUrl) {
    const json = yield api.get(`/vhs/tag_groups?per_page=1000`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_ALL_TAG_GROUPS_RESPONSE,
            data: json.results,
        });
    }
}
function* getTagOptions(apiUrl) {
    const json = yield api.get(`/vhs/tags?per_page=1000`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_ALL_TAGS_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchTagGroups(page = 1, per_page = 30, apiUrl) {
    const json = yield api.get(
        `/vhs/tag_groups?page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_TAG_GROUPS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                total_pages: json.total_pages,
                total: json.total,
                per_page: json.per_page,
            },
        });
    }
}

function* getTagGroup(tagGroupId, apiUrl) {
    const json = yield api.get(`/vhs/tag_groups/${tagGroupId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_TAG_GROUP_RESPONSE,
            data: json,
        });
    }
}

function* getTag(tagId, apiUrl) {
    const json = yield api.get(`/vhs/tags/${tagId}`, apiUrl);
    if (json) {
        yield put({ type: ACTION_TYPES.VHS_TAG_RESPONSE, data: json });
    }
}

function* postTagGroup(tagGroup, apiUrl) {
    const json = yield api.post('/vhs/tag_groups', tagGroup, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_POST_TAG_GROUP_RESPONSE,
            data: json,
        });
        yield getTagGroupOptions(apiUrl);
    }
}

function* postTag(tag, apiUrl) {
    const json = yield api.post('/vhs/tags', tag, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_POST_TAG_RESPONSE,
            data: json,
        });
    }
}

function* updateTagGroup(tagGroup, apiUrl) {
    const json = yield api.put(
        `/vhs/tag_groups/${tagGroup.id}`,
        tagGroup,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_UPDATE_TAG_GROUP_RESPONSE,
            data: json.data,
        });
        yield getTagGroupOptions(apiUrl);
    }
}

function* updateTag(tag, apiUrl) {
    const json = yield api.put(`/vhs/tags/${tag.id}`, tag, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_UPDATE_TAG_RESPONSE,
            data: json,
        });
    }
}

function* deleteTagGroup(tagGroupId, apiUrl) {
    const json = yield api.del(`/vhs/tag_groups/${tagGroupId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_SELECT_TAG_GROUP,
            data: null,
        });
        yield fetchTagGroups(null, null, apiUrl);
    }
}

function* deleteTag(tagId, apiUrl) {
    const json = yield api.del(`/vhs/tags/${tagId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_SELECT_TAG,
            data: null,
        });
        yield fetchTagGroups(null, null, null);
    }
}

function* replaceTags(tagSelectionData, apiUrl) {
    const json = yield api.put(`/vhs/tags/replaceTags/`, tagSelectionData, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_REPLACE_TAGS_RESPONSE,
            data: json,
        });
    }
}

function* vhsTagsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_TAGS_REQUEST:
            yield getTagOptions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_ALL_TAG_GROUPS_REQUEST:
            yield getTagGroupOptions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_TAG_GROUPS_REQUEST:
            yield fetchTagGroups(
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_POST_TAG_GROUP_REQUEST:
            yield postTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_POST_TAG_REQUEST:
            yield postTag(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_TAG_GROUP_REQUEST:
            yield updateTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_TAG_REQUEST:
            yield updateTag(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_TAG_GROUP_REQUEST:
            yield getTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_TAG_REQUEST:
            yield getTag(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_DELETE_TAG_GROUP_REQUEST:
            yield deleteTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_DELETE_TAG_REQUEST:
            yield deleteTag(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_REPLACE_TAGS_REQUEST:
            yield replaceTags(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default vhsTagsWorker;
