const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsMembersWorker } = require('../workers/vhsMembersWorker');

function* VHS_MEMBERS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_FETCH_MEMBER_PERMISSIONS_REQUEST,
            ACTION_TYPES.VHS_FETCH_MEMBER_REQUEST,
            ACTION_TYPES.VHS_FETCH_MEMBERS_REQUEST,
            ACTION_TYPES.VHS_FETCH_MEMBER_ACTIVITY_REQUEST,
            ACTION_TYPES.VHS_FETCH_MEMBER_ATTEND_REQUEST,
            ACTION_TYPES.VHS_FETCH_MEMBER_FAVORITED_REQUEST,
            ACTION_TYPES.VHS_FETCH_MEMBER_HISTORY_REQUEST,
        ],
        vhsMembersWorker,
        apiUrl,
        ...params
    );
}

export default VHS_MEMBERS_REQUEST;
