const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initalState = {
    matrices: [],
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    selectedMatrix: null,
    isLoadingMatrices: false,
    config: {},
    results_list: [],
    results_total: 0,
    results_isLoading: false,
    results_total_pages:0,
    results_per_page: 10,
    results_page: 1,
};

function PEMatricesReducer(state = initalState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_DELETE_MATRIX_RESPONSE:
        case ACTION_TYPES.PE_FETCH_MATRICES_REQUEST: {
            return {
                ...state,
                isLoadingMatrices: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_MATRICES_RESPONSE: {
            return {
                ...state,
                matrices: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
                isLoadingMatrices: false,
            };
        }
        case ACTION_TYPES.PE_GET_MATRIX_CONFIG_RESPONSE: {
            return {
                ...state,
                config: action.data,
            };
        }
        case ACTION_TYPES.PE_UPDATE_MATRIX_RESPONSE:
        case ACTION_TYPES.PE_POST_MATRIX_RESPONSE:
        case ACTION_TYPES.PE_SELECT_MATRIX:
        case ACTION_TYPES.PE_CLONE_MATRIX_RESPONSE:
        case ACTION_TYPES.PE_GET_MATRIX_RESPONSE: {
            return {
                ...state,
                selectedMatrix: action.data,
            };
        }
        case ACTION_TYPES.PE_GET_MATRIX_RESULTS_REQUEST: {
            return {
                ...state,
                results_isLoading: true,
            };
        }
        case ACTION_TYPES.PE_GET_MATRIX_RESULTS_RESPONSE: {
            return {
                ...state,
                results_list: action.data.results,
                results_total: action.data.total,
                results_total_pages:action.data.total_pages,
                results_per_page: action.data.per_page,
                results_page: action.data.page,
                results_isLoading: false,
            };
        }
        default:
            return state;
    }
}

export default PEMatricesReducer;
