import { PlusIcon } from '@heroicons/react/outline';
import ProgramEntryList from "../ProgramEntryList";
import VHSEpisodesSearchMini from 'routing/vhs/Episodes/EpisodesSearchMini';
import useActions from 'hooks/useActions';

function VHSProgramPlanning({ program }) {
    const { VHS_POST_PROGRAM_EPISODE_REQUEST, VHS_DEL_PROGRAM_EPISODE_REQUEST } = useActions();

    function onAddRestDay() {
        try {
            VHS_POST_PROGRAM_EPISODE_REQUEST({ program, episode: null });
        } catch (err) {
            console.log(err);
        }
    }

    function onAddWorkoutDay(episode) {
        try {
            VHS_POST_PROGRAM_EPISODE_REQUEST({ program, episode });
        } catch (err) {
            console.log(err);
        }
    }

    function onRemove(entry) {
        try {
            VHS_DEL_PROGRAM_EPISODE_REQUEST({ program, entry });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="flex gap-2">
            <div className="w-3/4 relative">
                <div className="flex flex-col gap-2">
                    <div className="card-box-bordered p-4 relative">
                        <div className="flex justify-between items-center">
                            <label>Add A Rest Day</label>
                            <small>Add an entry to the program that represents a 'Rest Day'</small>
                            <button className="button add-button p-2 text-white" onClick={onAddRestDay}>
                                <PlusIcon height="1rem" color="white"/>
                            </button>
                        </div>
                    </div>
                    <div className="card-box-bordered p-4">
                        <h4>Add A Workout Episode</h4>
                        <small>Use the following table to find and add episodes to the program by clicking the + button inline with the episode you wish to add.</small>
                        <VHSEpisodesSearchMini episodeAddCallback={onAddWorkoutDay}/>
                    </div>
                </div>
            </div>
            <div className="w-1/4">
                <ProgramEntryList program={program} showControls={true} onRemove={onRemove}/>
            </div>
        </div>
    );
}

export default VHSProgramPlanning;
