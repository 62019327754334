const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    members: [],
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    isLoadingMembers: false,
    selectedMember: null,
    brandsFilter: 'all',
    expertCoaches: [],
};

function CSMembersReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_FETCH_MEMBERS_REQUEST: {
            return {
                ...state,
                isLoadingMembers: true,
            };
        }
        case ACTION_TYPES.CS_FETCH_MEMBERS_RESPONSE: {
            return {
                ...state,
                members: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
                isLoadingMembers: false,
            };
        }
        case ACTION_TYPES.CS_GET_MEMBER_RESPONSE:
        case ACTION_TYPES.CS_SELECT_MEMBER: {
            return {
                ...state,
                selectedMember: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_MEMBER_BRANDS_FILTER:{
            return{
                ...state,
                page: 1,
                brandsFilter: action.data,
            }
        }
        case ACTION_TYPES.CS_FETCH_EXPERT_COACHES_RESPONSE:{
            return {
                ...state,
                expertCoaches: action.data,
            }
        }
        default:
            return state;
    }
}

export default CSMembersReducer;
