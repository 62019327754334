import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';
import moment from 'moment';

const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');
const { default: api } = require('../api/api');

function* getInstructorsOptions(apiUrl) {
    const json = yield api.get('/vhs/instructors?per_page=1000', apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTOR_OPTIONS_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchInstructors(
    page = 1,
    per_page = 10,
    visibility = 'all',
    brand = 'all',
    apiUrl,
    searchTerm = ''
) {
    let url = `/vhs/instructors?page=${page}&per_page=${per_page}`;

    if (visibility !== 'all') {
        url += `&visibility=${visibility}`;
    }
    if (brand !== 'all') {
        url += `&brands=${brand}`;
    }
    if(!!searchTerm){
        url += `&term=${searchTerm}`;
    }

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTORS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getInstructor(instructorId, apiUrl) {
    const json = yield api.get(`/vhs/instructors/${instructorId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTOR_RESPONSE,
            data: json,
        });
    }
}

function* postInstructor(instructor, apiUrl) {
    const json = yield api.post('/vhs/instructors', instructor, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_POST_INSTRUCTOR_RESPONSE,
            data: json,
        });
    }
}

function* updateInstructor(instructor, apiUrl) {
    const json = yield api.put(
        `/vhs/instructors/${instructor.id}`,
        instructor,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_UPDATE_INSTRUCTOR_RESPONSE,
            data: json,
        });
    }
}

function* fetchInstructorEpisodes(
    instructorId,
    page = 1,
    per_page = 10,
    order = VHS_EPISODES_SORTING[0].value,
    apiUrl
) {
    const json = yield api.get(
        `/vhs/episodes?page=${page}&per_page=${per_page}&instructors=${instructorId}&sort=${order}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTOR_EPISODES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* fetchInstructorActivity(
    instructorId,
    page = 1,
    per_page = 10,
    apiUrl
) {
    const json = yield api.get(
        `/vhs/track_events?page=${page}&per_page=${per_page}&instructors=${instructorId}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* deleteInstructor(instructorId, apiUrl) {
    const json = yield api.del(`/vhs/instructors/${instructorId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_SELECT_INSTRUCTOR,
            data: null,
        });
        yield fetchInstructors(null, null, null, null, apiUrl);
    }
}

function* getChartData(days = 30, actions = 'all', instructorId, apiUrl) {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(Number.parseInt(days), 'd');
    const initialMillis = today.toDate().getTime();

    let url = `/vhs/chart?stop_int=${todayMillis}&start_int=${initialMillis}&instructors=${instructorId}`;

    actions !== 'all' && (url += `&actions=${actions}`);
    days === '1' && (url += '&group_by=hour');

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_RESPONSE,
            data: json.results,
        });
    }
}

function* getHistory(page = 1, per_page = 10, instructorId, apiUrl) {
    const json = yield api.get(
        `/vhs/activities?affected_type=Instructor&affected_id=${instructorId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_INSTRUCTOR_HISTORY_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* reassignInstructors(instructorSelectionData, apiUrl) {
    const json = yield api.put(`/vhs/instructors/reassignInstructors/`, instructorSelectionData, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_REPLACE_INSTRUCTORS_RESPONSE,
            data: json,
        });
    }
}

function* vhsInstructorsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_OPTIONS_REQUEST:
            yield getInstructorsOptions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_INSTRUCTORS_REQUEST:
            yield fetchInstructors(
                action.data?.page,
                action.data?.per_page,
                action.data?.visibility,
                action.data?.brand,
                apiUrl,
                action.data?.searchTerm
            );
            break;
        case ACTION_TYPES.VHS_POST_INSTRUCTOR_REQUEST:
            yield postInstructor(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_INSTRUCTOR_REQUEST:
            yield updateInstructor(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_REQUEST:
            yield getInstructor(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST:
            yield fetchInstructorEpisodes(
                action.data?.instructorId,
                action.data?.page,
                action.data?.per_page,
                action.data?.order,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST:
            yield fetchInstructorActivity(
                action.data?.instructorId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_DELETE_INSTRUCTOR_REQUEST:
            yield deleteInstructor(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_REQUEST:
            yield getChartData(
                action.data?.period,
                action.data?.actions,
                action.data?.instructorId,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST:
            yield getHistory(
                action.data?.page,
                action.data?.per_page,
                action.data?.instructorId,
                apiUrl
            )
            break;
        case ACTION_TYPES.VHS_REPLACE_INSTRUCTORS_REQUEST:
            yield reassignInstructors(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default vhsInstructorsWorker;
