import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

const SeriesNew = connect(mapStateToProps)(function (props) {
    const { brandsOptions } = props;

    const { VHS_POST_SERIES_REQUEST } = useActions();

    const [series, onFieldChange] = useInputState({
        name: '',
        description: '',
        visibility: VISIBILITY_OPTIONS[0].value,
        brand_ident: '',
    });

    function onCreate(event) {
        event.preventDefault();
        VHS_POST_SERIES_REQUEST(series);
    }

    useEffect(() => {
        if (brandsOptions && brandsOptions.length > 0) {
            onFieldChange({
                target: {
                    name: 'brand_ident',
                    value: brandsOptions[0].value
                }
            });
        }
        // eslint-disable-next-line
    }, [brandsOptions]);

    return (
        <section>
            <form onSubmit={onCreate}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className='text-bold label'>Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={series.name}
                                    onChange={onFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Description</td>
                            <td>
                                <textarea
                                    className='w-full'
                                    name="description"
                                    value={series.description}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={series.visibility}
                                    onChange={onFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={series.brand_ident}
                                    onChange={onFieldChange}
                                >
                                    {brandsOptions.map((option) => (
                                        <option
                                            key={`brand-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Serie
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default SeriesNew;
