import MarkdownEditor from '@uiw/react-markdown-editor';
const {
    default: MarkdownHint,
} = require('components/StaticElements/MarkdownHint');
const { default: useActions } = require('hooks/useActions');
const { useState } = require('react');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        brandsOptions: state.PEBrands.brandsOptions,
        selectedProgram: state.PEPrograms.selectedProgram,
        config: state.admins.config,
        user: state.user.user,
    };
}

const PEProgramEdit = connect(mapStateToProps)(function (props) {
    const { brandsOptions, selectedProgram, config, user, onSaveCallback } = props;

    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const [name, setName] = useState(selectedProgram?.name);
    const [description, setDescription] = useState(selectedProgram?.description);
    const [technicalName, setTechnicalName] = useState(selectedProgram?.technical_name);
    const [brandIdent, setBrandIdent] = useState(selectedProgram?.brand_ident);
    const [regionIdent, setRegionIdent] = useState(selectedProgram?.region_ident);
    const [startDate, setStartDate] = useState(selectedProgram?.start_date);
    const [published, setPublished] = useState(selectedProgram?.is_published)
    const { PE_UPDATE_PROGRAM_REQUEST } = useActions();

    function saveChanges(event) {
        event.preventDefault();
        PE_UPDATE_PROGRAM_REQUEST({
            id: selectedProgram.id,
            name,
            description,
            technical_name: technicalName,
            brand_ident: brandIdent,
            region_ident: regionIdent,
            start_date: startDate,
            is_published: published,
            callback: onSaveCallback,
        });
    }

    function handleFieldChange(event) {
        if (event.target.name !== 'brand_ident') {
            if (event.target.name === 'start_date')
                setStartDate(event.target.value);
            if (event.target.name === 'is_published')
                setPublished(event.target.value);
        } else {
            if (event.target.value === '0') {
                setBrandIdent('');
            } else {
                setBrandIdent(event.target.value);
            }
        }
    }

    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className="edit-table">
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className="w-full"
                                    name="name"
                                    value={name}
                                    onChange={(event) =>
                                        setName(event.target.value)
                                    }
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Technical Name</td>
                            <td>
                                <input
                                    className="w-full"
                                    name="technical_name"
                                    value={technicalName}
                                    onChange={(event) =>
                                        setTechnicalName(event.target.value)
                                    }
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Description</td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={description}
                                    onChange={setDescription}
                                    toolbars={[
                                        'bold',
                                        'italic',
                                        'underline',
                                        'header',
                                        'olist',
                                        'ulist',
                                    ]}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    onChange={handleFieldChange}
                                    defaultValue={brandIdent}
                                >
                                    <option value={0}>None</option>
                                    {brandsOptions.map((brand) => (
                                        <option
                                            id={`brand-${brand.value}`}
                                            key={`brand-${brand.value}`}
                                            value={brand.value}
                                        >
                                            {brand.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <select
                                    name="region_ident"
                                    value={regionIdent}
                                    onChange={(event) => {
                                        setRegionIdent(event.target.value);
                                    }}
                                >
                                    {regionsToShow.map((reg) => (
                                        <option key={`reg-${reg}`} value={reg}>
                                            {reg}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                <span className="block">Start Date</span>
                            </td>
                            <td>
                                <input
                                    type="date"
                                    name="start_date"
                                    value={startDate}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Showing in Coaching Dashboard
                            </td>
                            <td>
                                <select
                                    name="is_published"
                                    onChange={handleFieldChange}
                                    defaultValue={published}
                                >
                                    <option value="true">Published</option>
                                    <option value="false">Unpublished</option>
                                </select>
                            </td>
                        </tr>
                        {(name !== selectedProgram?.name ||
                            description !== selectedProgram?.description ||
                            technicalName !== selectedProgram?.technical_name ||
                            brandIdent !== selectedProgram?.brand_ident ||
                            regionIdent !== selectedProgram?.region_ident ||
                            startDate !== selectedProgram?.start_date ||
                            published !== selectedProgram?.is_published) && (
                            <tr>
                                <td colSpan={2}>
                                    <button
                                        type="submit"
                                        className="mt-4 button save-changes-button"
                                    >
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
})

export default PEProgramEdit;
