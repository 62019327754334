import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useActions from 'hooks/useActions';
import SIDEBAR_ICONS from 'constants/SIDEBAR_ICONS';


function mapStateToProps(state) {
    return {
        members: state.vhsMembers
    };
}

// Custom offshoot of MenuSection for the sidebar
// Allows for VHS member based permission hiding/showing of subroutes
// Allows for customized behavior/styles if needed
const VHSMenuSection = connect(mapStateToProps)(function({
    id,
    parent,
    isOpen,
    show,
    onShow,
    members
}) {
    const handleShow = (show) => show ? onShow(999) : onShow && onShow(id);
    
    const [permsLoaded, setPermsLoaded] = useState(false);
    const {
        VHS_FETCH_MEMBER_PERMISSIONS_REQUEST
    } = useActions();

    useEffect(() => {
        if(!members?.currentMemberPermissions && !permsLoaded) {
            setPermsLoaded(false);
            VHS_FETCH_MEMBER_PERMISSIONS_REQUEST();
        }
    }, [VHS_FETCH_MEMBER_PERMISSIONS_REQUEST, members, permsLoaded]);

    useEffect(() => {
        if(members?.currentMemberPermissions) {
            setPermsLoaded(true);
        }
    }, [members]);

    function Icon({ name }) {
        return SIDEBAR_ICONS[name];
    }

    function isInRole(role) {
        return members?.currentMemberPermissions?.roles?.indexOf(role) >= 0;
    }

    function routePermReqMet(childRoute) {
        if(!childRoute.role) {
            return true;
        }

        return isInRole(childRoute.role);
    }
    
    return (
        <li
            key={parent.title}
            className="block"
        >
            <NavLink
                className={({ isActive }) => {
                    return isActive
                        ? 'active rounded-md flex w-full p-2 pl-4'
                        : 'hover:rounded-md flex w-full p-2 pl-4';
                }}
                to={parent.path}
            >
                <Icon name={parent.icon} />
                {isOpen && 
                <>
                    <span>{parent.title}</span>
                    {parent?.children?.length > 0 &&
                        <div onClick={(e) => {e.preventDefault();handleShow(show)}} className="arrow-btn">
                            {!show 
                                ? <img src='/assets/chevron-down.png' alt={`close ${parent.title}`} className="chevron chevron-down"/>
                                : <img src='/assets/chevron-up.png' alt={`open ${parent.title}`} className="chevron chevron-up"/>
                            }
                        </div>
                    }
                </>
                }
            </NavLink>
            <ul
                className={
                    'menu overflow-y-auto w-70 text-base-content'
                }
            >
                {isOpen && show && (parent?.children ||
                    []
                ).map((childItem) => {
                    if(!routePermReqMet(childItem)) {
                        return <></>;
                    }
                    return (
                        <li
                            key={`dr-item-${parent.title}-${childItem.title}`}
                            className="block rounded-md pr"
                        >
                            <NavLink
                                className={({ isActive }) => {
                                    return isActive
                                        ? 'active rounded-md flex w-full p-2 pl-12 pr-0'
                                        : 'hover:rounded-md flex w-full p-2 pl-12 pr-1';
                                }}
                                to={childItem.path}
                            >
                                {childItem.title}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
});

export default VHSMenuSection;