/* eslint-disable react-hooks/exhaustive-deps */
import DateField from 'components/DateField';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';

function mapStateToProps(state) {
    return {
        seriesOptions: state.vhsSeries.seriesOptions,
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

function EpisodesTableRaw({
    episodes = [],
    seriesOptions = [],
    brandsOptions = [],
    onDetailSelection = null,
    useMiniDetails = false,
    episodeAddCallback = null,
    isLoading,
}) {
    const navigate = useNavigate();
    const [seriesSet, setSeriesSet] = useSetFromOptions();
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    useEffect(() => {
        setSeriesSet(seriesOptions);
    }, [seriesOptions]);

    useEffect(() => {
        setBrandsSet(brandsOptions);
    }, [brandsOptions]);

    return (
        <section>
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>

                        {useMiniDetails &&
                        <>
                        <th>Scheduled</th>
                        <th>Instructor</th>
                        <th>Visibility</th>
                        <th>Series</th>
                        <th>Location</th>
                        {episodeAddCallback && <th></th>}
                        </>
                        }
                        {!useMiniDetails &&
                        <>
                        <th>Scheduled</th>
                        <th>Duration</th>
                        <th>Instructor</th>
                        <th>Visibility</th>
                        <th>Series</th>
                        <th>Brand</th>
                        <th>Location</th>
                        <th>Tags</th>
                        </>
                        }
                    </tr>
                </thead>
                <tbody>
                    {episodes.map((episode) => (
                        <tr key={`episode-${episode.id}`}>
                            <td>
                            {episode.poster?.url && 
                                <SelectableField
                                    url={`/vhs/episodes/${episode.id}`}
                                >
                                    <img
                                        src={episode.poster?.url}
                                        alt={episode.name}
                                        style={{
                                            maxHeight: '4rem',
                                            maxWidth: '5rem',
                                            minHeight: '4rem',
                                            minWidth: '5rem',
                                        }}
                                    />
                                </SelectableField>
                            }
                            </td>
                            <td>
                                <SelectableField
                                    action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(episode);
                                        }
                                        navigate(`/vhs/episodes/${episode.id}`);
                                    }}
                                    text={episode.name}
                                />
                            </td>
                            <td>
                                <DateField timeMillis={episode.start_int} />
                            </td>
                            {!useMiniDetails &&
                            <td>
                                {episode.duration_ms
                                    ? `${episode.duration_ms / 60000} minutes`
                                    : null}
                            </td>
                            }
                            <td>
                                {episode.instructors && episode.instructors.map(instructor => (
                                <>
                                    <SelectableField
                                        url={`/vhs/instructors/${instructor?.id}`}
                                        text={instructor.name}
                                        key={instructor.uuid}
                                    />
                                    <br></br>
                                </>
                                ))}
                            </td>
                            <td>{episode.visibility}</td>
                            <td>
                                <SelectableField
                                    url={`/vhs/series/${episode.series_id}`}
                                    text={seriesSet[episode.series_id]}
                                />
                            </td>
                            
                            {!useMiniDetails &&
                            <td>
                                {brandsSet[episode.brand_ident]}
                            </td>
                            }

                            <td>
                                {episode.location?.name}
                            </td>

                            {!useMiniDetails &&
                            <td className="tags">
                                <div className="tagbox">
                                    {episode.tags?.map((tag) => (
                                        <div
                                            className="tag"
                                            key={`tag-${episode.id}-${tag.id}`}
                                        >
                                            {tag.name}
                                        </div>
                                    ))}
                                </div>
                            </td>
                            }

                            {useMiniDetails && episodeAddCallback && 
                            <td>
                                <button className="button add-button p-2 text-white" onClick={()=>{ episodeAddCallback(episode) }}>
                                    <PlusIcon height="1rem" color="white"/>
                                </button>
                            </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
}

const EpisodesTable = connect(mapStateToProps)(EpisodesTableRaw);
export default EpisodesTable;
