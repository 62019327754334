import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import SeriesAnalytics from './SeriesAnalytics';
import SeriesEdit from './SeriesEdit';
import SeriesEpisodes from './SeriesEpisodes';
import SeriesOverview from './SeriesOverview';
import SeriesHistory from './SeriesHistory';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedSeries: state.vhsSeries.selectedSeries,
    };
}

const VHSSeriesDetails = connect(mapStateToProps)(function (props) {
    const { selectedSeries } = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const {
        VHS_FETCH_SERIES_EPISODES_REQUEST,
        VHS_FETCH_SERIES_ACTIONS_REQUEST,
        VHS_UPDATE_SERIES_REQUEST,
    } = useActions();

    useEffect(
        () => {
            VHS_FETCH_SERIES_ACTIONS_REQUEST({seriesId: selectedSeries?.id});
            VHS_FETCH_SERIES_EPISODES_REQUEST({seriesId: selectedSeries?.id});
        },
        // eslint-disable-next-line
        []
    );

    function onSaveChanges(updatedSeries) {
        VHS_UPDATE_SERIES_REQUEST(updatedSeries);
        setSelectedTab(0);
    }

    return (
        <section>
            <Tabs
                sectionNames={['Overview', 'Settings', 'Episodes', 'Analytics', 'History']}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
                sectionContents={[
                    <SeriesOverview series={selectedSeries} />,
                    <SeriesEdit
                        series={selectedSeries}
                        onSave={onSaveChanges}
                    />,
                    <SeriesEpisodes />,
                    <SeriesAnalytics />,
                    <SeriesHistory />
                ]}
            />
        </section>
    );
});

export default VHSSeriesDetails;
