/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from 'components/Tabs';
import { useState, useEffect } from 'react';
import ArticleOverview from '../ArticleOverview';
import ArticleEdit from '../ArticleEdit';
import ArticleComments from '../ArticleComments';
import ArticleFlags from '../ArticleFlags';
import ArticleTags from '../ArticleTags';
import ArticleFiles from '../ArticleFiles';
import ArticleTranslations from '../ArticleTranslations';
import useActions from 'hooks/useActions';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        article: state.CsArticles.selectedArticle,
    };
}

const ArticleDetail = connect(mapStateToProps)(function (props) {
    const { article } = props;
    const [selectedTab, setSelectedTab] = useState();
    const { CS_SELECT_COMMENT, CS_FETCH_ARTICLE_FILES_REQUEST, CS_FETCH_TAG_GROUPS_REQUEST } = useActions();
    function onSave() {
        setSelectedTab(0);
    }

    useEffect(() => {
        CS_FETCH_ARTICLE_FILES_REQUEST(article.id);
        CS_FETCH_TAG_GROUPS_REQUEST({regionIdent: article?.region_ident});
        CS_SELECT_COMMENT(null);
    }, []);

    return (
        <section>
            <Tabs
                sectionNames={[
                    'Overview',
                    'Settings',
                    'Comments',
                    'Flags',
                    'Tags',
                    'File',
                    'Translations',
                ]}
                sectionContents={[
                    <ArticleOverview />,
                    <ArticleEdit article={article} onConfirm={onSave} />,
                    <ArticleComments />,
                    <ArticleFlags />,
                    <ArticleTags />,
                    <ArticleFiles />,
                    <ArticleTranslations />,
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
});

export default ArticleDetail;
