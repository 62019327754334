function LoadingIcon() {
    return (
        <svg width="60" height="60" viewBox="0 0 42 42" stroke="#498a98">
            <g fill="none" fillRule="evenodd">
                <g transform="translate(3 3)" strokeWidth="4">
                    <circle strokeOpacity=".6" cx="18" cy="18" r="18" />
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1500ms"
                            repeatCount="indefinite"
                        />
                    </path>
                </g>
            </g>
        </svg>
    );
}

export default LoadingIcon;
