import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchMatrices(page = 1, per_page = 10, searchTerm = '', region_ident = '', apiUrl) {
    let endpoint = `/pe/matrices?page=${page}&per_page=${per_page}`;
    if (searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const regionToFilter = region_ident ? region_ident : selectedRegion;

    const json = yield api.get(endpoint, apiUrl, regionToFilter, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_MATRICES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getMatrix(matrixId, apiUrl) {
    const json = yield api.get(`/pe/matrices/${matrixId}?include=results`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_MATRIX_RESPONSE,
            data: json,
        });
    }
}

function* getConfig(apiUrl) {
    const json = yield api.get(`/pe/matrices/config`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_MATRIX_CONFIG_RESPONSE,
            data: json,
        });
    }
}

function* deleteMatrix(matrixId, page, perPage, searchTerm, apiUrl) {
    const json = yield api.del(`/pe/matrices/${matrixId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_MATRIX_RESPONSE,
            data: null,
        });
    }
    yield fetchMatrices(page, perPage, searchTerm, '', apiUrl);
}

function* updateMatrix(matrix, apiUrl) {
    const json = yield api.put(`/pe/matrices/${matrix.id}?include=results`, matrix, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_MATRIX_RESPONSE,
            data: json,
        });
    }
}

function* postMatrix(matrix, apiUrl) {
    const json = yield api.post(`/pe/matrices`, matrix, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_MATRIX_RESPONSE,
            data: json,
        });
    }
}

function* cloneMatrix(matrixId, apiUrl) {
    const json = yield api.post(`/pe/matrices/${matrixId}/clone`, {}, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_CLONE_MATRIX_RESPONSE,
            data: json,
        });
    }
}

function* getMatrixResults(page = 1, per_page = 10, query, apiUrl) {

    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(`/pe/assignments?${query}&page=${page}&per_page=${per_page}`, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_MATRIX_RESULTS_RESPONSE,
            data: json,
        });
    }
}

function* peMatricesWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_MATRICES_REQUEST:
            yield fetchMatrices(
                action.data?.page,
                action.data?.per_page,
                action.data?.searchTerm,
                action.data?.region_ident,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_GET_MATRIX_REQUEST:
            yield getMatrix(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_MATRIX_REQUEST:
            yield deleteMatrix(
                action.data?.matrixId,
                action.data?.page,
                action.data?.perPage,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_UPDATE_MATRIX_REQUEST:
            yield updateMatrix(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_MATRIX_REQUEST:
            yield postMatrix(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_CLONE_MATRIX_REQUEST:
            yield cloneMatrix(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_GET_MATRIX_CONFIG_REQUEST:
            yield getConfig(apiUrl);
            break;
        case ACTION_TYPES.PE_GET_MATRIX_RESULTS_REQUEST:
            yield getMatrixResults(
                action.data?.page,
                action.data?.per_page,
                action.data?.query,
                apiUrl);
            break;
        default:
            break;
    }
}

export default peMatricesWorker;
