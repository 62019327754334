const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsEpisodesWorker } = require('../workers/vhsEpisodesWorker');

function* VHS_EPISODES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_DELETE_EPISODE_REQUEST,
            ACTION_TYPES.VHS_FETCH_EPISODE_REQUEST,
            ACTION_TYPES.VHS_FETCH_EPISODES_REQUEST,
            ACTION_TYPES.VHS_GET_EPISODE_ANALYTICS_REQUEST,
            ACTION_TYPES.VHS_POST_EPISODE_REQUEST,
            ACTION_TYPES.VHS_UPDATE_EPISODE_REQUEST,
            ACTION_TYPES.VHS_FETCH_EPISODES_ACTIONS_REQUEST,
            ACTION_TYPES.VHS_FETCH_EPISODES_TAG_GROUPS_REQUEST,
            ACTION_TYPES.VHS_ADD_TAG_TO_EPISODE_REQUEST,
            ACTION_TYPES.VHS_REMOVE_TAG_FROM_EPISODE_REQUEST,
            ACTION_TYPES.VHS_FETCH_EPISODES_ACTIVITY_CHART_REQUEST,
            ACTION_TYPES.VHS_FETCH_EPISODE_FILES_REQUEST,
            ACTION_TYPES.VHS_POST_EPISODE_FILE_REQUEST,
            ACTION_TYPES.VHS_POST_EPISODE_RECORDING_REQUEST,
            ACTION_TYPES.VHS_DELETE_EPISODE_FILE_REQUEST,
            ACTION_TYPES.VHS_ASSIGN_ASSET_REQUEST,
            ACTION_TYPES.VHS_FETCH_EPISODE_HISTORY_REQUEST,
        ],
        vhsEpisodesWorker,
        apiUrl,
        ...params
    );
}

export default VHS_EPISODES_REQUEST;
