import DetailOverview from 'components/DetailOverview';

function VHSMemberOverview({ member, brandsSet = {} }) {
    return (
        <DetailOverview
            fields={[
                { name: 'First Name', value: member.first_name },
                { name: 'Last Name', value: member.last_name },
                { name: 'Email', value: member.email },
                { name: 'User Id', value: member.brand_user_id },
                { name: 'Wordpress Id', value: member.brand_wordpress_id },
                {
                    name: 'Brand',
                    value: brandsSet[member.brand_ident],
                },
                {
                    name: 'Referrer',
                    value: member.referrer,
                    type: 'link',
                    url: `/vhs/members/${member.referrer}`,
                },
                { name: 'UUID', value: member.uuid },
                { name: 'User Agent', value: member.user_agent },
                { name: 'IP Address', value: member.ip_address },
                { name: 'Created', value: member.created_int, type: 'date' },
                { name: 'Updated', value: member.updated_int, type: 'date' },
            ]}
        />
    );
}

export default VHSMemberOverview;
