import { PlusIcon } from '@heroicons/react/outline';
import InformationTab from 'components/InformationTab';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        tagGroups: state.CSTags.tagGroups,
        page: state.CSTags.page,
        per_page: state.CSTags.per_page,
        total_pages: state.CSTags.total_pages,
        total: state.CSTags.total,
        brandsOptions: state.PEBrands.brandsOptions,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

function TableSection({ tagGroup, navigate = function () {}, brandsSet = {} }) {
    const { CS_SELECT_TAG_GROUP } = useActions();

    return (
        <section className="mt-8 mb-8 flex-col card-box p-4">
            <div className="flex flex-row">
            <div className="flex flex-col w-1/12">
                <span className="text-lg mr-4 uppercase">{tagGroup.name}</span>
                <span className="text-primary-dark-color">
                    {brandsSet[tagGroup.brand_ident]}
                </span>
            </div>
            <div className="tags-container-border tags-bg p-4 pt-2 pl-2 flex flex-wrap w-11/12 rounded-xl">
                {tagGroup.tags &&
                    tagGroup.tags.map((tag) => (
                        <button
                            key={`tag-${tag.id}`}
                            className="primary-color-box rounded-full tags-border p-4 h-6 flex items-center justify-center text-white ml-2 mt-2 text-sm"
                            onClick={() => {
                                CS_SELECT_TAG_GROUP(tagGroup);
                                navigate(`/community/tags/tag/${tag.id}`);
                            }}
                        >
                            {tag.name}
                        </button>
                ))}
                <button
                    className="rounded-md circle-button mt-2 ml-2 h-10 w-10 flex items-center justify-center"
                    onClick={() => {
                        CS_SELECT_TAG_GROUP(tagGroup);
                        navigate(`/community/tags/tag/new/${tagGroup.id}`);
                    }}
                >
                    <PlusIcon color="white" height={15} />
                </button>
            </div>
            </div>
            <div className='flex justify-end mt-4'>
                <button
                    className="button"
                    onClick={() => {
                        CS_SELECT_TAG_GROUP(tagGroup);
                        navigate(`/community/tags/group/${tagGroup.id}`);
                    }}
                >
                    Edit
                </button>
            </div>
           
        </section>
    );
}

const CSTags = connect(mapStateToProps)(function (props) {
    const { 
        tagGroups, 
        brandsOptions,
        selectedRegionFilter,
    } = props;
    const {
        CS_FETCH_TAG_GROUPS_REQUEST,
        CS_SELECT_TAG_GROUP,
        CS_SELECT_TAG,
    } = useActions();

    const [brandsSet, setBrandsSet] = useSetFromOptions();

    const navigate = useNavigate();

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        CS_SELECT_TAG(null);
        CS_SELECT_TAG_GROUP(null);
        CS_FETCH_TAG_GROUPS_REQUEST();
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <div className='mt-8 mb-4 flex flex-row place-content-between'>
                <h1>All Tags and Groups</h1>
                <button
                    className="button add-button p-2 ml-4 text-white"
                    onClick={() => navigate(`/community/tags/group/new`)}
                >
                    Add a Tag Group
                </button>
                </div>
                {tagGroups.map((tagGroup) => (
                    <TableSection
                        key={'tag-group-' + tagGroup.id}
                        tagGroup={tagGroup}
                        navigate={navigate}
                        brandsSet={brandsSet}
                    />
                ))}
            </section>
        );
    }

    return (
        <section>
            <InformationTab
                showFilters={false}
                sectionName="Tags"
                dataTable={<DataTable />}
            />
        </section>
    );
});

export default CSTags;
