import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        tagGroupOptions: state.vhsTags.tagGroupOptions,
        selectedTag: state.vhsTags.selectedTag,
    };
}

const VHSNewTag = connect(mapStateToProps)(function (props) {
    const { tagGroupOptions, selectedTag } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const { VHS_POST_TAG_REQUEST } = useActions();

    const [newTag, handleFieldChange] = useInputState({
        name: '',
        tag_group_id: id,
    });

    useEffect(() => {
        if (selectedTag) {
            navigate(`/vhs/tags`);
        }
        // eslint-disable-next-line
    }, [selectedTag]);

    const onSubmit = (event) => {
        event.preventDefault();
        VHS_POST_TAG_REQUEST({
            name: newTag.name,
            tag_group_id: newTag.tag_group_id,
        });
    };

    return (
        <section>
            <div className="flex mb-4 items-center h-16">
                <div className="flex flex-1 items-center">
                    <div
                        className="font-bold cursor-pointer  text-lg"
                        onClick={() => {
                            navigate('/vhs/tags');
                        }}
                    >
                        Tags
                    </div>
                    <div className="ml-8 font-medium text-blue-600">
                        New Tag
                    </div>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    name="name"
                                    value={newTag.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Tag Group</td>
                            <td>
                                <select
                                    className="w-full"
                                    name="tag_group_id"
                                    value={newTag.tag_group_id}
                                    onChange={handleFieldChange}
                                >
                                    {tagGroupOptions.map((tagg) => (
                                        <option
                                            key={tagg.value}
                                            value={tagg.value}
                                        >
                                            {tagg.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Tag
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default VHSNewTag;
