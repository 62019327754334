import ACTION_TYPES from 'constants/ACTION_TYPES';
import { List } from 'immutable';

const initialState = {
    selectedMember: null,
    isLoadingMembers: false,
    program: 'all',
    // main members screen
    list: [],
    total: 0,
    total_pages: 1,
    page: 1,
    per_page: 10,
    // conversions for the selected member
    conversions: [],
    conversions_page: 1,
    conversions_per_page: 10,
    conversions_total_pages: 1,
    conversions_total: 0,
    isLoadingConversions: false,
    // activity for the selected member
    activity: [],
    activity_page: 1,
    activity_per_page: 10,
    activity_total_pages: 1,
    activity_total: 0,
    isLoadingActivity: false,
    // history
    history: [],
    history_page: 1,
    history_per_page: 10,
    history_total_pages: 1,
    history_total: 1,
    isLoadingHistory: false,
};

function membersReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MEMBERS_REQUEST: {
            return {
                ...state,
                isLoadingMembers: true,
            };
        }
        case ACTION_TYPES.FETCH_MEMBERS_RESPONSE: {
            return {
                ...state,
                isLoadingMembers: false,
                list: action.data.list.filter((element) => element),
                total: action.data.total,
                page: action.data.page,
                total_pages: action.data.total_pages,
                per_page: action.data.per_page,
            };
        }
        case ACTION_TYPES.SELECT_MEMBER: {
            return {
                ...state,
                selectedMember: action.data,
            };
        }
        case ACTION_TYPES.SELECT_MEMBERS_PROGRAM: {
            return {
                ...state,
                program: action.data,
            };
        }
        case ACTION_TYPES.FETCH_MEMBER_RESPONSE: {
            return {
                ...state,
                selectedMember: action.data,
            };
        }
        case ACTION_TYPES.UPDATE_MEMBERS_RESPONSE: {
            return {
                ...state,
                isLoading: true,
                selectedMember: action.data,
            };
        }
        case ACTION_TYPES.FETCH_MEMBER_CONVERSIONS_REQUEST: {
            return {
                ...state,
                isLoadingConversions: true,
            };
        }
        case ACTION_TYPES.FETCH_MEMBER_CONVERSIONS_RESPONSE: {
            return {
                ...state,
                conversions: action.data.list,
                conversions_per_page: action.data.per_page,
                conversions_page: action.data.page,
                conversions_total_pages: action.data.total_pages,
                conversions_total: action.data.total,
                isLoadingConversions: false,
            };
        }
        case ACTION_TYPES.FETCH_MEMBER_ACTIVITY_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.FETCH_MEMBER_ACTIVITY_RESPONSE: {
            return {
                ...state,
                activity: action.data.list,
                activity_page: action.data.page,
                activity_per_page: action.data.per_page,
                activity_total_pages: action.data.total_pages,
                activity_total: action.data.total,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.UPDATE_MEMBER_CONVERSION_RESPONSE: {
            let list = List.of(...state.conversions);
            list = list.set(
                list.indexOf((element) => element.id === action.data.id),
                action.data
            );
            return {
                ...state,
                conversions: [...list.toArray()],
            };
        }
        case ACTION_TYPES.FETCH_MEMBER_HISTORY_REQUEST: {
            return {
                ...state,
                isLoadingHistory: true,
            };
        }
        case ACTION_TYPES.FETCH_MEMBER_HISTORY_RESPONSE: {
            return {
                ...state,
                history: action.data.list,
                history_page: action.data.page,
                history_per_page: action.data.per_page,
                history_total_pages: action.data.total_pages,
                history_total: action.data.total,
                isLoadingHistory: false,
            };
        }
        default:
            return state;
    }
}

export default membersReducer;
