import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import VHS_EVENTS_ACTIONS from 'constants/VHS_EVENTS_ACTIONS';
import useActions from 'hooks/useActions';
import useVhsAnalyticsChartData from 'hooks/useVhsAnalyticsChartData';
import { useEffect, useState } from 'react';
import AnalyticsTable from 'routing/vhs/Analytics/AnalyticsTable';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        episode: state.vhsEpisodes.selectedEpisode,
        activities: state.vhsEpisodes.activities,
        isLoading: state.vhsEpisodes.isLoadingActivity,
        page: state.vhsEpisodes.activities_page,
        per_page: state.vhsEpisodes.activities_per_page,
        total_pages: state.vhsEpisodes.activities_total_pages,
        activities_total: state.vhsEpisodes.activities_total,
        actionsFilter: state.vhsEpisodes.actionsFilter,
        chartData: state.vhsEpisodes.chartData,
        periodFilter: state.vhsEpisodes.periodFilter,
    };
}

const EpisodesAnalytics = connect(mapStateToProps)(function (props) {
    const {
        activities,
        per_page,
        page,
        total_pages,
        episode,
        actionsFilter,
        activities_total,
        chartData,
        periodFilter,
        isLoading,
    } = props;
    const {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST,
        VHS_SELECT_EPISODES_ACTIONS_FILTER,
        VHS_FETCH_EPISODES_ACTIVITY_CHART_REQUEST,
        VHS_SELECT_EPISODES_PERIOD_FILTER,
    } = useActions();
    const [parsedChartData, loadParsedChartData] = useVhsAnalyticsChartData();
    const [sectionName, setSectionName] = useState('Analytics');
    const [chartName, setChartName] = useState('All Actions');

    const onNextPage = () => {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST({
            episodeId: episode?.id,
            per_page,
            page: page + 1,
            actions: actionsFilter,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST({
            episodeId: episode?.id,
            per_page,
            page: page - 1,
            actions: actionsFilter,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST({
            episodeId: episode?.id,
            per_page,
            page: pageSelected,
            actions: actionsFilter,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST({
            episodeId: episode?.id,
            per_page: perPage,
            actions: actionsFilter,
        });
    };

    const onPeriodSelected = ({ period }) => {
        VHS_SELECT_EPISODES_PERIOD_FILTER(period);
        VHS_FETCH_EPISODES_ACTIVITY_CHART_REQUEST({
            period,
            actions: actionsFilter,
            episodeId: episode?.id,
        });
        const label = VHS_EVENTS_ACTIONS.find(
            (act) => act.value === actionsFilter
        )?.label;
        setChartName(
            `${
                label === 'All' ? 'All Actions' : label
            } in the last ${period} Days`
        );
    };

    useEffect(() => {
        loadParsedChartData(chartData, actionsFilter);
        // eslint-disable-next-line
    }, [chartData]);

    useEffect(() => {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST({
            episodeId: episode?.id,
            per_page: per_page,
            actions: actionsFilter,
        });
        VHS_FETCH_EPISODES_ACTIVITY_CHART_REQUEST({
            period: periodFilter,
            actions: actionsFilter,
            episodeId: episode?.id,
        });
        const label = VHS_EVENTS_ACTIONS.find(
            (act) => act.value === actionsFilter
        )?.label;
        setChartName(
            `${
                label === 'All' ? 'All Actions' : label
            } in the last ${periodFilter} Days`
        );
        setSectionName(label === 'All' ? 'All Actions' : label);
        // eslint-disable-next-line
    }, [actionsFilter]);

    return (
        <section>
            <InformationTab
                showPrograms={false}
                sectionName={sectionName}
                chartName={chartName}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                customFilters={[
                    <SingleFilter
                        selectedValue={actionsFilter}
                        options={VHS_EVENTS_ACTIONS}
                        onSelect={(value) =>
                            VHS_SELECT_EPISODES_ACTIONS_FILTER(value)
                        }
                    />,
                ]}
                dataTable={
                    <section>
                        <TableResults results={activities_total} />
                        <AnalyticsTable
                            activities={activities}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </section>
                }
                hasChart
                chartData={parsedChartData}
                selectedPeriod={periodFilter}
                onPeriodSelected={onPeriodSelected}
                showBreadcrumbs={false}
            />
        </section>
    );
});

export default EpisodesAnalytics;
