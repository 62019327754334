import ROUTING, { pathToTitle } from 'constants/ROUTING';
import useActions from 'hooks/useActions';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import LogoSEB from '../../logo_SEB.svg';
import MountainSEB from '../../mountain_SEB.svg';
import VHSMenuSection from 'routing/vhs/Routing/VHSMenuSection';
import SIDEBAR_ICONS from 'constants/SIDEBAR_ICONS';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';

function DrawerContentWrapper({ children, show = false, overrideShow, isOpen }) {
    
    return (
        <div className={`grow-0 pt-0 drawer-container ${isOpen ? 'open p-5' : 'close p-1'} duration-200`}>
            <div
                className={
                    (show || overrideShow ? '' : 'lg:block h-full') +
                    (overrideShow ? overrideShow : '')
                }
            >
                {children}
            </div>
        </div>
    );
}

function MenuSection({id, parent, isOpen, show, onShow}) {
    const handleShow = (show) => show ? onShow(999) : onShow && onShow(id);
    
    
    return (
        <li
            key={parent.title}
            className="block"
        >
            <NavLink
                className={({ isActive }) => {
                    return isActive
                        ? 'active rounded-md flex w-full p-2 pl-4'
                        : 'hover:rounded-md flex w-full p-2 pl-4';
                }}
                to={parent.path}
            >
                <Icon name={parent.icon} />
                {isOpen && 
                <>
                    <span>{parent.title}</span>
                    {parent?.children?.length > 0 &&
                        <div onClick={(e) => {e.preventDefault();handleShow(show)}} className="arrow-btn">
                            {!show 
                                ? <img src='/assets/chevron-down.png' alt={`close ${parent.title}`} className="chevron chevron-down"/>
                                : <img src='/assets/chevron-up.png' alt={`open ${parent.title}`} className="chevron chevron-up"/>
                            }
                        </div>
                    }
                </>
                }
            </NavLink>
            <ul
                className={
                    'menu overflow-y-auto w-70 text-base-content'
                }
            >
                
                {isOpen && show && (parent?.children ||
                    []
                ).map((childItem) => {
                    return (
                        <li
                            key={`dr-item-${parent.title}-${childItem.title}`}
                            className="block rounded-md pr"
                        >
                            <NavLink
                                className={({ isActive }) => {
                                    return isActive
                                        ? 'active rounded-md flex w-full p-2 pl-12 pr-0'
                                        : 'hover:rounded-md flex w-full p-2 pl-12 pr-1';
                                }}
                                to={childItem.path}
                            >
                                {childItem.title}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
}

function Icon({ name }) {
    return SIDEBAR_ICONS[name];
}

function DrawerContent({ show = false, services_permissions, userData, toggleAction, isOpen, allRegions }) {
    const [showId, setShowId] = useState();
    const handleShow = id => id !== showId ? setShowId(id) : setShowId(999);
    const location = useLocation();
    const activeSection = (parent,id) => (
        (((location.pathname.includes(parent.path) && parent.path !== '/') || location.pathname === parent.path || (location.pathname.includes('referral') && parent.path === '/'))) ? true : false
        )
    useEffect(() => {
        const handleWindowResize = () => {
            if(window.innerWidth < 936 && isOpen){
                toggleAction();
            }
            if(window.innerWidth > 936 && !isOpen){
                toggleAction();
            }
        };
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
        window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        if(window.innerWidth < 936 && isOpen){
            toggleAction();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const {SET_USER_SELECTED_REGION} = useActions();
    const handleSelectUserRegion = (region) => {
        if(region){
            SET_USER_SELECTED_REGION({region});
        } 
    };

    return (
        <DrawerContentWrapper {...{ show }} isOpen={isOpen}>
        <div className='flex flex-col h-full'>
            <div className={`w-full flex mt-6 cursor-pointer ${isOpen ? 'justify-end' : 'justify-center'}`} onClick={() => toggleAction()}>
                <img src='/assets/arrow-left-circle.png' className={`open-menu ${!isOpen ? 'toOpen' : ''}`} alt='Open/Close Menu'/>
            </div>
           
            <div className='flex items-center justify-center mt-12'>
                {isOpen
                ?<img src={LogoSEB} alt="SELF ESTEEM BRANDS"/>
                :<img src={MountainSEB} alt="SELF ESTEEM BRANDS"/> 
                }
            </div>

            {
                (
                    (userData?.permissions?.regions?.length > 1) ||
                    (userData?.permissions?.regions?.includes('all'))
                )
                && 
                (
                    <div className='menu-region-container mt-10 mx-auto'>
                        <select className='uppercase' 
                            onChange={
                                (event) => {
                                    handleSelectUserRegion(event.target.value);
                                }
                            }
                        >
                            {
                                // Super-user regarding the regions.
                                (userData?.permissions?.regions?.includes('all') && allRegions) && 
                                allRegions.map((region) => (
                                    <option value={region.value}>{region.value}</option>
                                ))
                            }
                            {
                                !(userData?.permissions?.regions?.includes('all')) && 
                                userData?.permissions?.regions.map((region) => (
                                    <option value={region}>{region}</option>
                                ))
                            }
                            {
                                // All the regions that the user has access to (meaning "Do not filter the content"), (Not related to the All option (super-user))
                                (userData?.permissions?.regions?.length > 1) && (
                                    <option value='all'>All</option>
                                )
                            }
                        </select>
                    </div>
                )
            }

            <ul className="menu overflow-y-auto mt-10 flex-grow">
                {ROUTING.filter((parentRoute) => {
                    return services_permissions.some((el) => 
                        [parentRoute.key, 'all'].includes(el)
                    )
                }).map((item, index) => {
                    if(item.key === SERVICE_TYPES.VIDEO_HOSTING) {
                        return <VHSMenuSection key={item.title} id={index} show={(activeSection(item,index) || (showId === index) )&& !(showId === 999)} parent={item} isOpen={isOpen} onShow={handleShow} />
                    } else {
                        return <MenuSection key={item.title} id={index} show={(activeSection(item,index) || (showId === index) )&& !(showId === 999)} parent={item} isOpen={isOpen} onShow={handleShow}/>;
                    }
                })}
            </ul>
            <NavLink
                className={`logout-btn flex flex-row items-center ${isOpen ? 'pl-6 p-2' : 'pl-4 mb-4'} rounded-md`}
                to={'/logout'}
            >
                {isOpen && <>
                <div className='w-10/12'>
                    <div style={{color: 'black'}}>{userData.display_name}</div>
                    <div className='text-primary-dark-color'>Log Out</div>
                </div>
                </>}
                <div className={`${isOpen ? 'w-2/12' : 'w-full'}`}>
                    <Icon name={'logout'} />
                </div>
            </NavLink>
        </div>
        </DrawerContentWrapper>
    );
}


function mapStateToProps(state) {
    return {
        user: state.user.user,
        config: state.admins.config,
    };
}
const Drawer = connect(mapStateToProps)(function (props) {
    const { user, config } = props;
    const allRegions = config?.regions;
    const actions = useActions();
    const {SET_USER_SELECTED_REGION} = useActions();
    const isOpen = useSelector((state) => state?.ui?.drawerOpen);
    const isOnline = useSelector((state) => state?.user?.online);
    const location = useLocation();
    const lastPath = useRef('');

    useEffect(() => {
        if(user?.permissions?.regions?.length === 1 && user?.permissions?.regions[0] !== 'all'){
            SET_USER_SELECTED_REGION({region: user?.permissions?.regions[0]});
        }
    }, [user, SET_USER_SELECTED_REGION]);

    function toggleDrawer() {
        actions.TOGGLE_DRAWER();
    }

    useEffect(() => {
        if (lastPath.current !== location.pathname) {
            lastPath.current = location.pathname;
            actions.ROUTE_CHANGE({
                path: location.pathname,
                title: pathToTitle?.[location.pathname],
            });
        }
    }, [location.pathname, actions]);


    return !isOnline
        ? null
        :  <DrawerContent
                key="drawer-large-content"
                services_permissions={user?.permissions?.services}
                userData={user}
                toggleAction={toggleDrawer}
                isOpen={isOpen}
                allRegions={allRegions}
            />;
});

export default Drawer;
