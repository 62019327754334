import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    selectedBrand: null,
    list: [],
    isLoadingBrands: false,
    brandOptions: [],
    total: 0,
    total_pages: 0,
    page: 1,
    per_page: 10,
};

function brandsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ALL_BRANDS_RESPONSE: {
            const brandOptions = action.data.list.map((brand) => ({
                identValue: brand.ident,
                value: brand.id,
                label: brand.name,
            }));
            return {
                ...state,
                brandOptions: brandOptions,
            };
        }
        case ACTION_TYPES.FETCH_BRAND_RESPONSE: {
            return { ...state, selectedBrand: action.data };
        }
        case ACTION_TYPES.FETCH_BRANDS_REQUEST: {
            return {
                ...state,
                isLoadingBrands: true,
            };
        }
        case ACTION_TYPES.FETCH_BRANDS_RESPONSE: {
            return {
                ...state,
                list: action.data.list.filter((element) => element),
                total: action.data.total,
                page: action.data.page,
                total_pages: action.data.total_pages,
                per_page: action.data.per_page,
                isLoadingBrands: false,
            };
        }
        case ACTION_TYPES.POST_BRAND_RESPONSE: {
            return {
                ...state,
                list: [...state.list, action.data],
                total: state.total + 1,
                selectedBrand: action.data,
                brandOptions: [
                    ...state.brandOptions,
                    {
                        label: action.data?.name,
                        value: action.data?.id,
                        identValue: action.data?.ident,
                    },
                ],
            };
        }
        case ACTION_TYPES.SET_BRANDS_PER_PAGE: {
            return {
                ...state,
                per_page: action.data,
            };
        }
        case ACTION_TYPES.UPDATE_BRAND_RESPONSE: {
            return {
                ...state,
                selectedBrand: action.data,
            };
        }
        case ACTION_TYPES.SELECT_BRAND: {
            return {
                ...state,
                selectedBrand: action.data,
            };
        }
        default:
            return state;
    }
}

export default brandsReducer;
