import ADMINS_REQUEST from './ADMINS_REQUEST';
import BRANDS_REQUEST from './BRANDS_REQUEST';
import CONVERSIONS_REQUEST from './CONVERSIONS_REQUEST';
import LOGOUT from './LOGOUT';
import MEMBERS_REQUEST from './MEMBERS_REQUEST';
import PE_ASSIGNMENTS_REQUEST from './PE_ASSIGNMENTS_REQUEST';
import PE_BRANDS_REQUEST from './PE_BRANDS_REQUEST';
import PE_CATEGORIES_REQUEST from './PE_CATEGORIES_REQUEST';
import PE_MATRICES_REQUEST from './PE_MATRICES_REQUEST';
import PE_MEMBERS_REQUEST from './PE_MEMBERS_REQUEST';
import PE_PHASES_REQUEST from './PE_PHASES_REQUEST';
import PE_PROGRAMS_REQUEST from './PE_PROGRAMS_REQUEST';
import PE_SEQUENCES_REQUEST from './PE_SEQUENCES_REQUEST';
import PE_SERIES_REQUEST from './PE_SERIES_REQUEST';
import PE_TAGS_REQUEST from './PE_TAGS_REQUEST';
import CS_MODERATOR_REQUEST from './CS_MODERATOR_REQUEST';
import CS_ARTICLES_REQUEST from './CS_ARTICLES_REQUEST';
import CS_COMMENTS_REQUEST from './CS_COMMENTS_REQUEST';
import CS_QUESTIONS_REQUEST from './CS_QUESTIONS_REQUEST';
import CS_ANSWERS_REQUEST from './CS_ANSWERS_REQUEST';
import CS_TAGS_REQUEST from './CS_TAGS_REQUEST';
import CS_MEMBERS_REQUEST from './CS_MEMBERS_REQUEST';
import CS_FLAGS_REQUEST from './CS_FLAGS_REQUEST';
import PROGRAMS_REQUEST from './PROGRAMS_REQUEST';
import ROUTE_CHANGE from './ROUTE_CHANGE';
import VHS_ANALYTICS_REQUEST from './VHS_ANALYTICS_REQUEST';
import VHS_BRANDS_REQUEST from './VHS_BRANDS_REQUEST';
import VHS_MEETINGS_REQUEST from './VHS_MEETINGS_REQUEST';
import VHS_LOCATION_REQUEST from './VHS_LOCATION_REQUEST';
import VHS_PROGRAMS_REQUEST from './VHS_PROGRAMS_REQUEST';
import VHS_EPISODES_REQUEST from './VHS_EPISODES_REQUEST';
import VHS_INSTRUCTORS_REQUEST from './VHS_INSTRUCTORS_REQUEST';
import VHS_MEMBERS_REQUEST from './VHS_MEMBERS_REQUEST';
import VHS_REQUEST from './VHS_REQUEST';
import VHS_SERIES_REQUEST from './VHS_SERIES_REQUEST';
import VHS_TAGS_REQUEST from './VHS_TAGS_REQUEST';
import VIEWS_REQUEST from './VIEWS_REQUEST';

const watchers = [
    { watcher: ROUTE_CHANGE, url: process.env.REACT_APP_API_URL },
    { watcher: LOGOUT, url: process.env.REACT_APP_API_URL },

    { watcher: ADMINS_REQUEST, url: process.env.REACT_APP_API_URL },
    { watcher: PROGRAMS_REQUEST, url: process.env.REACT_APP_API_URL },
    { watcher: BRANDS_REQUEST, url: process.env.REACT_APP_API_URL },
    { watcher: MEMBERS_REQUEST, url: process.env.REACT_APP_API_URL },
    { watcher: CONVERSIONS_REQUEST, url: process.env.REACT_APP_API_URL },
    { watcher: VIEWS_REQUEST, url: process.env.REACT_APP_API_URL },

    { watcher: VHS_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_ANALYTICS_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_BRANDS_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_LOCATION_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_PROGRAMS_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_MEETINGS_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_EPISODES_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    {
        watcher: VHS_INSTRUCTORS_REQUEST,
        url: process.env.REACT_APP_VHS_API_URL,
    },
    { watcher: VHS_MEMBERS_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_SERIES_REQUEST, url: process.env.REACT_APP_VHS_API_URL },
    { watcher: VHS_TAGS_REQUEST, url: process.env.REACT_APP_VHS_API_URL },

    { watcher: PE_ASSIGNMENTS_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_BRANDS_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_CATEGORIES_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_MATRICES_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_MEMBERS_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_PHASES_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_PROGRAMS_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_SEQUENCES_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_SERIES_REQUEST, url: process.env.REACT_APP_PE_API_URL },
    { watcher: PE_TAGS_REQUEST, url: process.env.REACT_APP_PE_API_URL },

    { watcher: CS_MODERATOR_REQUEST, url: process.env.REACT_APP_CS_API_URL },
    { watcher: CS_ARTICLES_REQUEST, url: process.env.REACT_APP_CS_API_URL },
    { watcher: CS_COMMENTS_REQUEST, url: process.env.REACT_APP_CS_API_URL },
    { watcher: CS_QUESTIONS_REQUEST, url: process.env.REACT_APP_CS_API_URL },
    { watcher: CS_ANSWERS_REQUEST, url: process.env.REACT_APP_CS_API_URL },
    { watcher: CS_TAGS_REQUEST, url: process.env.REACT_APP_CS_API_URL },
    { watcher: CS_MEMBERS_REQUEST, url: process.env.REACT_APP_CS_API_URL },
    { watcher: CS_FLAGS_REQUEST, url: process.env.REACT_APP_CS_API_URL },
];

export default watchers;
