const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const {
    default: vhsInstructorsWorker,
} = require('../workers/vhsInstructorsWorker');

function* VHS_INSTRUCTORS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_POST_INSTRUCTOR_REQUEST,
            ACTION_TYPES.VHS_FETCH_INSTRUCTOR_REQUEST,
            ACTION_TYPES.VHS_FETCH_INSTRUCTORS_REQUEST,
            ACTION_TYPES.VHS_DELETE_INSTRUCTOR_REQUEST,
            ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST,
            ACTION_TYPES.VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST,
            ACTION_TYPES.VHS_UPDATE_INSTRUCTOR_REQUEST,
            ACTION_TYPES.VHS_FETCH_INSTRUCTOR_OPTIONS_REQUEST,
            ACTION_TYPES.VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_REQUEST,
            ACTION_TYPES.VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST,
            ACTION_TYPES.VHS_REPLACE_INSTRUCTORS_REQUEST
        ],
        vhsInstructorsWorker,
        apiUrl,
        ...params
    );
}

export default VHS_INSTRUCTORS_REQUEST;
