const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');
const { default: api } = require('../api/api');

function* getBrandOptions(apiUrl) {
    const json = yield api.get(`/vhs/brands?per_page=1000`, apiUrl);
    yield put({
        type: ACTION_TYPES.VHS_FETCH_ALL_BRANDS_RESPONSE,
        data: json?.results,
    });
}

function* getBrands(page = 1, per_page = 10, apiUrl) {
    const json = yield api.get(
        `/vhs/brands?page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_BRANDS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getBrand(brandIdent, apiUrl) {
    const json = yield api.get(`/vhs/brands/${brandIdent}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* postBrand(brand, apiUrl) {
    const json = yield api.post('/vhs/brands', brand, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_POST_BRAND_RESPONSE,
            data: {
                selectedBrand: json,
            },
        });
    }
}

function* updateBrand(brandIdent, brand, apiUrl) {
    const json = yield api.put(`/vhs/brands/${brandIdent}`, brand, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_UPDATE_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* vhsBrandsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_BRANDS_REQUEST:
            yield getBrandOptions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_BRANDS_REQUEST:
            yield getBrands(action.data?.page, action.data?.per_page, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_BRAND_REQUEST: {
            yield getBrand(action.data, apiUrl);
            break;
        }
        case ACTION_TYPES.VHS_POST_BRAND_REQUEST:
            yield postBrand(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_UPDATE_BRAND_REQUEST:
            yield updateBrand(action.data?.ident, action.data?.brand, apiUrl);
            break;
        default:
            break;
    }
}

export default vhsBrandsWorker;
