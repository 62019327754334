/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from 'components/Tabs';
import { useState, useEffect } from 'react';
import CSCommentOverview from '../CommentOverview';
import CSCommentEdit from '../CommentEdit';
import CSCommentFlags from '../CommentFlags';
import CSCommentsFiles from '../CommentFiles';
import CSCommentTranslations from '../CommentTranslations';
import useActions from 'hooks/useActions';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        comment: state.CSComments.selectedComment,
    };
}

const QuestionDetail = connect(mapStateToProps)(function (props) {
    const { comment } = props;
    const [selectedTab, setSelectedTab] = useState();
    const { CS_FETCH_COMMENT_FILES_REQUEST } = useActions();
    function onSave() {
        setSelectedTab(0);
    }

    useEffect(() => {
        CS_FETCH_COMMENT_FILES_REQUEST(comment?.id);
    }, []);

    return (
        <section>
            <Tabs
                sectionNames={[
                    'Overview',
                    'Settings',
                    'Flags',
                    'Files',
                    'Translations',
                ]}
                sectionContents={[
                   <CSCommentOverview/>,
                   <CSCommentEdit comment={comment}/>,
                   <CSCommentFlags/>,
                   <CSCommentsFiles/>,
                   <CSCommentTranslations/>,
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
});

export default QuestionDetail;
