import ACTION_TYPES from 'constants/ACTION_TYPES';
import {SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';


const initialState = {
    selectedArticle: null,
    isLoadingArticles: false,
    articlesList: [],
    articlesTotal: 0,
    articlesTotalPages: 1,
    articlesPage: 1,
    articlesPerPage: 10,
    commentedFilter: 'all',
    featuredFilter: 'all',
    trendingFilter: 'all',
    brandsFilter: 'all',
    articlesOrder: SORTING_OPTIONS[0].value,
    flagsSelectedArticle: [],
    isLoadingFlags: false,
    flagsPerPage: 10,
    flagsPage: 1,
    flagsTotal: 0,
    flagsTotalPages: 1,
    flagsOrder: SORTING_OPTIONS[0].value,
    flagsStatus: 'all',
    tagGroups: [],
    files: [],
    isLoadingFiles: false,
    translations: [],
    translations_page: 1,
    translations_per_page: 10,
    translations_total: 0,
    translations_total_pages: 1,
    translations_is_loading: false,
    selectedTranslation: null,
};

function articlesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_ARTICLES_REQUEST: {
            return {
                ...state,
                isLoadingArticles: true,
            };
        }
        case ACTION_TYPES.CS_ARTICLES_RESPONSE: {
            return {
                ...state,
                isLoadingArticles: false,
                articlesList: action.data.list,
                articlesTotal: action.data.total,
                articlesTotalPages: action.data.total_pages,
                articlesPage: action.data.page,
                articlesPerPage: action.data.per_page,
            };
        }
        case ACTION_TYPES.CS_SELECT_ARTICLE: {
            return {
                ...state,
                selectedArticle: action.data,
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_RESPONSE: {
            return {
                ...state,
                selectedArticle: action.data,
            }
        }
        case ACTION_TYPES.CS_UPDATE_ARTICLE_RESPONSE: {
            return {
                ...state,
                selectedArticle: action.data,
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_FLAGS_REQUEST: {
            return {
                ...state,
                isLoadingFlags: true,
                
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_FLAGS_RESPONSE: {
            return {
                ...state,
                isLoadingFlags: false,
                flagsSelectedArticle: action.data.list,
                flagsTotal: action.data.total,
                flagsTotalPages: action.data.total_pages,
                flagsPage: action.data.page,
                flagsPerPage: action.data.per_page,
            };
        }
        case ACTION_TYPES.CS_SELECT_COMMENTED_FILTER: {
            return {
                ...state,
                articlesPage:0,
                commentedFilter: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_ARTICLE_FEATURED_FILTER: {
            return {
                ...state,
                articlesPage:0,
                featuredFilter: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_ARTICLE_TRENDING_FILTER: {
            return {
                ...state,
                articlesPage:0,
                trendingFilter: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_ARTICLE_BRANDS_FILTER: {
            return {
                ...state,
                articlesPage:0,
                brandsFilter: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_ARTICLE_ORDER_FILTER: {
            return {
                ...state,
                articlesOrder: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_ARTICLE_FLAGS_STATUS_FILTER: {
            return {
                ...state,
                flagsPage:0,
                flagsStatus: action.data,
            }
        }
        case ACTION_TYPES.CS_UPDATE_ARTICLE_FLAGS_REASON_RESPONSE: {
            return {
                ...state,
                flagsSelectedArticle: {...state.flagsSelectedArticle, ...action.data.results},
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_TAG_GROUPS_RESPONSE: {
            return {
                ...state,
                tagGroups: action.data,
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_FILES_REQUEST: {
            return {
                ...state,
                isLoadingFiles: true,
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_FILES_RESPONSE: {
            return {
                ...state,
                files: action.data,
                isLoadingFiles: false,
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_TRANSLATIONS_REQUEST: {
            return {
                ...state,
                translations_is_loading: true,
            };
        }
        case ACTION_TYPES.CS_FETCH_ARTICLE_TRANSLATIONS_RESPONSE: {
            return {
                ...state,
                translations: action.data.list,
                translations_page: action.data.page,
                translations_per_page: action.data.per_page,
                translations_total: action.data.total,
                translations_total_pages: action.data.total_pages,
                translations_is_loading: false,
            };
        }
        case ACTION_TYPES.CS_POST_ARTICLE_TRANSLATION_RESPONSE:
        case ACTION_TYPES.CS_UPDATE_ARTICLE_TRANSLATION_RESPONSE:
        case ACTION_TYPES.CS_SELECT_ARTICLE_TRANSLATION: {
            return {
                ...state,
                selectedTranslation: action.data,
            };
        }
        case ACTION_TYPES.CS_POST_ARTICLE_RESPONSE:{
            return {
                ...state,
                selectedArticle: action.data,
            };
        }
        default:
            return state;
    }
}

export default articlesReducer