import { useState, useEffect } from "react";
function Tabs({
    sectionNames = [],
    sectionContents = [],
    selectedTab = 0,
    onTabChange = function () {},
}) {
    const [activeTab, setActiveTab] = useState(selectedTab);
    useEffect(() => {
        if (!isNaN(selectedTab) && selectedTab < sectionContents.length) {
            setActiveTab(selectedTab);
        }
    }, [sectionContents.length, selectedTab]);
    return (
        <div className="tabs-container">
            <ul className="tabs-list flex mb-4 bg-white p-1 flex-row gap-2">
            {sectionNames.map((sectionName, index) => (
            
                    
                <li
                    key={`tab-section-name-${sectionName}`}
                    className={`${activeTab === index
                            ? 'active'
                            : ''
                    }`}
                    onClick={() => {
                        setActiveTab(index);
                        onTabChange(index);
                    }}
                >
                    {sectionName}
                </li>
                ))}
            </ul>
            <div className="tab-content-container">
            {sectionContents[activeTab]}
            </div>
        </div>
    );
}

export default Tabs;