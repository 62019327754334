import ProgramEntry from '../ProgramEntry';
import './index.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useActions from 'hooks/useActions';

function VHSProgramEntryList({ program, showControls, onRemove }) {
    const { VHS_UPDATE_PROGRAM_EPISODE_REQUEST } = useActions();

    const sortedEntries = [...program.entries].sort((a, b) => a.sort_index - b.sort_index);

    const updateEntry = (entry) => {
        try {
            VHS_UPDATE_PROGRAM_EPISODE_REQUEST({ program, entry });
        } catch (err) {
            console.log(err);
        }
    }

    const onDragEnd = (result) => {
        // Do nothing if item was dropped outside the list
        if (!result.destination) return;

        const reorderedEntries = Array.from(sortedEntries);
        const [removed] = reorderedEntries.splice(result.source.index, 1);
        reorderedEntries.splice(result.destination.index, 0, removed);

        reorderedEntries.forEach((entry, index) => {
            updateEntry({...entry, sort_index: index })
        })
    };

    return (
        <div className="card-box-bordered p-4">
            <h4 className="uppercase label mb-4">Entries:</h4>
            {!sortedEntries?.length && (
                <>
                    <h5 className="text-center">No entries exist at this time</h5>
                    <h5 className="text-center mb-2">Modify this in the 'Planning' tab</h5>
                </>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="entries">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {sortedEntries.map((entry, index) => (
                                <Draggable key={entry.id} draggableId={`${entry.id}`} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <ProgramEntry entry={entry} showControls={showControls} key={entry.id} onRemove={onRemove} />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default VHSProgramEntryList;
