import TableButton from 'components/TableButton';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        actions: state.PEAssignments.config,
    };
}

const ActionsModalContent = connect(mapStateToProps)(function (props) {
    const { actions, onSelect = function () {} } = props;
    console.log(actions);

    return (
        <section className="p-8">
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Select</th>
                    </tr>
                </thead>
                <tbody>
                    {actions?.map((action) => (
                        <tr key={`action-${action.value}`}>
                            <td>{action.label}</td>
                            <td>
                                <TableButton
                                    text="Select"
                                    className="select-button"
                                    action={() => onSelect(action)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
});

export default ActionsModalContent;
