import { useState } from 'react';

function ProgramEdit({
    program,
    brandOptions = [],
    onConfirm = function () {},
}) {
    const initialState = {
        name: program.name,
        brand_id: program.brand_id,
        is_enabled: program.is_enabled,
        is_auto_approved: program.is_auto_approved,
        is_brand_default: program.is_brand_default,
        credit: program.credit,
        discount: program.discount,
        credit_action: program.credit_action,
        subdomain: program.subdomain,
        validate_purchase_url: program.validate_purchase_url,
        validate_user_url: program.validate_user_url,
    };
    const [updatedProgram, setUpdatedProgram] = useState(initialState);

    const onFieldChange = (event) => {
        setUpdatedProgram((p) => ({
            ...p,
            [event.target.name]: event.target.value,
        }));
    };

    const saveChanges = (event) => {
        const sBrand = brandOptions.find(
            // eslint-disable-next-line
            (brandOption) => brandOption.value == updatedProgram.brand_id
        );
        event.preventDefault();
        onConfirm({
            id: program.id,
            name: updatedProgram.name,
            brand_id: Number.parseInt(updatedProgram.brand_id),
            brand_ident: sBrand ? sBrand.identValue : program.brand_ident,
            redirect_url: updatedProgram.redirect_url,
            credit_action: updatedProgram.credit_action,
            subdomain: updatedProgram.subdomain,
            credit: updatedProgram.credit,
            discount: updatedProgram.discount,
            is_enabled: updatedProgram.is_enabled,
            is_auto_approved: updatedProgram.is_auto_approved,
            is_brand_default: updatedProgram.is_brand_default,
            validate_purchase_url: updatedProgram.validate_purchase_url,
            validate_user_url: updatedProgram.validate_user_url,
        });
    };

    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={updatedProgram.name}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_id"
                                    value={updatedProgram.brand_id}
                                    onChange={onFieldChange}
                                >
                                    {brandOptions.map((option) => (
                                        <option
                                            key={`credit-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Enabled</td>
                            <td>
                                <select
                                    className="muuv-select"
                                    value={updatedProgram.is_enabled}
                                    onChange={onFieldChange}
                                    name="is_enabled"
                                >
                                    <option value={false}>Disabled</option>
                                    <option value={true}>Enabled</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Approve</td>
                            <td>
                                <select
                                    className="muuv-select"
                                    name="is_auto_approved"
                                    value={updatedProgram.is_auto_approved}
                                    onChange={onFieldChange}
                                >
                                    <option value={false}>
                                        Require Approval
                                    </option>
                                    <option value={true}>Auto-Approve</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Brand Default
                            </td>
                            <td>
                                <select
                                    className="muuv-select"
                                    name="is_brand_default"
                                    value={updatedProgram.is_brand_default}
                                    onChange={onFieldChange}
                                >
                                    <option value={false}>Not Default</option>
                                    <option value={true}>
                                        Default for Brand
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Credit</td>
                            <td>
                                <input
                                    name="credit"
                                    value={updatedProgram.credit}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Discount</td>
                            <td>
                                <input
                                    name="discount"
                                    value={updatedProgram.discount}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Credit Action
                            </td>
                            <td>
                                <input
                                    name="credit_action"
                                    value={updatedProgram.credit_action}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Subdomain
                            </td>
                            <td>
                                <input
                                    name="subdomain"
                                    value={updatedProgram.subdomain}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Validate User API
                            </td>
                            <td>
                                <input
                                    name="validate_user_url"
                                    value={updatedProgram.validate_user_url}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Validate Purchase API
                            </td>
                            <td>
                                <input
                                    name="validate_purchase_url"
                                    value={updatedProgram.validate_purchase_url}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        {initialState !== updatedProgram && (
                            <tr>
                                <td colSpan="2">
                                    <button type="submit" className="mt-4 button save-changes-button">
                                    Save Changes
                                    </button>
                            </td>
                            </tr>
                        
                        )}
                    </tbody>
                </table>
                
            </form>
        </section>
    );
}

export default ProgramEdit;
