import useActions from 'hooks/useActions';
import { React, useEffect, useState } from 'react';

function FileFetcher({
    onClose,
    episodeId,
    brandIdent,
}) {
    const [zoomId, setZoomId] = useState("");
    const [activeButton, setActiveButton] = useState(false);
    const { VHS_POST_EPISODE_RECORDING_REQUEST } = useActions();


    useEffect(() => {
        if(zoomId) {
            setActiveButton(true) 
        } else {
            setActiveButton(false)
        }
    }, [zoomId])

    async function handleFetchRecording(e) {
        e.preventDefault()
        VHS_POST_EPISODE_RECORDING_REQUEST({
            episodeId,
            brandIdent: brandIdent,
            zoomId,
            done: () => {
                setZoomId("")
                setActiveButton(false)
            },
        })
    };


    return (
        <>
            <div>
                <button
                    className="bg-green-add p-2 mr-4 text-white mb-4"
                    onClick={onClose}
                >
                    Close Fetch
                </button>
                <form className='flex items-center' onSubmit={(e) => handleFetchRecording(e)}>
                    <input
                        name="zoom_id"
                        value={zoomId}
                        onChange={(e) => setZoomId(e.target.value)}
                        placeholder="Zoom ID"
                        className='w-1/4 ' 
                    />
                    {activeButton && <button 
                        type='submit' 
                        className="bg-green-add ml-4 p-2 text-white" 
                        onSubmit={(e) => handleFetchRecording(e)}
                    >Fetch Recording
                    </button>
                    }
                </form>
            </div>
        </>
    );
}

export default FileFetcher;
