const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsMeetingsWorker } = require('../workers/vhsMeetingsWorker');

function* VHS_MEETINGS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_FETCH_LOCATION_MEETINGS_REQUEST,
            ACTION_TYPES.VHS_FETCH_MEETING_INVITE_REQUEST,
        ],
        vhsMeetingsWorker,
        apiUrl,
        ...params
    );
}

export default VHS_MEETINGS_REQUEST;

