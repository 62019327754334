import ACTION_TYPES from 'constants/ACTION_TYPES';
import { put } from 'redux-saga/effects';
import api from '../api/api';

function* getAdmins(page = 1, per_page = 10, brands = 'all', services = 'all') {
    const json = yield api.get(
        `/users?page=${page}&per_page=${per_page}&brands=${brands}&services=${services}`
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_ADMINS_RESPONSE,
            data: {
                list: json.results,
                per_page: json.per_page,
                page: json.page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getConfig() {
    const json = yield api.get(`/users/config`);
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_ADMIN_CONFIG_REPONSE,
            data: json,
        });
    }
}

function* getAdmin(userId) {
    const json = yield api.get(`/users/${userId}`);
    if (json) {
        yield put({
            type: ACTION_TYPES.GET_ADMIN_RESPONSE,
            data: json,
        });
    }
}

function* postAdmin(user) {
    const json = yield api.post(`/users`, user);
    if (json) {
        yield put({
            type: ACTION_TYPES.POST_ADMIN_RESPONSE,
            data: json,
        });
    }
}

function* updateAdmin(user, callback) {
    const json = yield api.put(`/users/${user.id}`, user);
    if (json) {
        yield put({
            type: ACTION_TYPES.UPDATE_ADMIN_RESPONSE,
            data: json,
        });
        callback && callback();
    }
}

function* deleteAdmin(userId) {
    const json = yield api.del(`/users/${userId}`);
    if (json) {
        yield put({
            type: ACTION_TYPES.DELETE_ADMIN_RESPONSE,
            data: json,
        });
    }
}

function* adminsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ADMINS_REQUEST:
            yield getAdmins(
                action.data?.page,
                action.data?.per_page,
                action.data?.brandFilter,
                action.data?.serviceFilter
            );
            break;
        case ACTION_TYPES.FETCH_ADMIN_CONFIG_REQUEST:
            yield getConfig();
            break;
        case ACTION_TYPES.GET_ADMIN_REQUEST:
            yield getAdmin(action.data);
            break;
        case ACTION_TYPES.POST_ADMIN_REQUEST:
            yield postAdmin(action.data);
            break;
        case ACTION_TYPES.UPDATE_ADMIN_REQUEST:
            yield updateAdmin(action.data?.user, action.data?.callback);
            break;
        case ACTION_TYPES.DELETE_ADMIN_REQUEST:
            yield deleteAdmin(action.data);
            break;
        default:
            break;
    }
}

export default adminsWorker;
