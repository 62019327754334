/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import useActions from 'hooks/useActions';
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useQueryList from 'hooks/useQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { TRENDING_OPTIONS,FEATURED_OPTIONS,SORTING_OPTIONS, ANSWERED_OPTIONS, REEL_OPTIONS} from 'constants/COMMUNITY_STATUS';
import QuestionTable from './QuestionsTable';
import QuestionDetail from './QuestionDetail';
import CSQuestionNew from './QuestionNew';
import AlertModal from 'components/Modal/AlertModal';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        questions: state.CSQuestions.questionsList,
        isLoading: state.CSQuestions.isLoadingQuestions,
        per_page: state.CSQuestions.questionsPerPage,
        page: state.CSQuestions.questionsPage,
        total: state.CSQuestions.questionsTotal,
        total_pages: state.CSQuestions.questionsTotalPages,
        selectedQuestion: state.CSQuestions.selectedQuestion,
        answeredFilter: state.CSQuestions.answeredFilter,
        trendingFilter: state.CSQuestions.trendingFilter,
        featuredFilter: state.CSQuestions.featuredFilter,
        brandFilter: state.CSQuestions.brandsFilter,
        sortingFilter: state.CSQuestions.questionsListOrder,
        brandOptions: state.PEBrands.brandsOptions,
        reelFilter: state.CSQuestions.reelFilter,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const CSQuestions = connect(mapStateToProps)(function (props) {
    const {
        questions = [],
        isLoading,
        per_page,
        page,
        total,
        total_pages,
        selectedQuestion,
        answeredFilter,
        trendingFilter,
        featuredFilter,
        brandFilter,
        brandOptions,
        sortingFilter,
        reelFilter,
        selectedRegionFilter,
    } = props;
    const navigate = useNavigate();
    const {
        CS_QUESTION_REQUEST,
        CS_SELECT_QUESTION_ANSWERED_FILTER,
        CS_SELECT_QUESTION_FEATURED_FILTER,
        CS_SELECT_QUESTION_TRENDING_FILTER,
        CS_SELECT_QUESTION_REEL_FILTER,
        CS_SELECT_QUESTION_BRANDS_FILTER,
        CS_SELECT_QUESTION_ORDER_FILTER,
        CS_SELECT_QUESTION,
        CS_FETCH_QUESTION_REQUEST,
        CS_DELETE_QUESTION_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(true);
    const [detailComponent, setDetailComponent] = useState(null);

    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);

    const onDetailSelection = (question) => {
        setSubtitle(null);
        setDetailComponent(null);
        CS_SELECT_QUESTION(question);
        setShowDelete(true);
        if (question) {
            navigate(`/community/questions/${question.id}`);
        } else {
            CS_QUESTION_REQUEST({
                answered: answeredFilter,
                featured: featuredFilter,
                trending: trendingFilter,
                reel: reelFilter,
                brand: brandFilter,
                page,
                per_page,
                sorting: sortingFilter,
                searchTerm,
            });
            navigate(`/community/questions`);
        }
    };

    function onDeleteAction() {
        CS_DELETE_QUESTION_REQUEST(selectedQuestion?.id);
        CS_QUESTION_REQUEST({
            answered: answeredFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            reel: reelFilter,
            brand: brandFilter,
            page,
            per_page,
            sorting: sortingFilter,
            searchTerm,
        });
        navigate(`/community/questions`);
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Question?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }


    useEffect(() => {
        CS_QUESTION_REQUEST({
            answered: answeredFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            reel: reelFilter,
            brand: brandFilter,
            page,
            per_page,
            sorting: sortingFilter,
            searchTerm,
        });
    }, [answeredFilter, featuredFilter, trendingFilter, sortingFilter, brandFilter, reelFilter, selectedRegionFilter]);

    useEffect(() => {
        CS_QUESTION_REQUEST({
            answered: answeredFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            reel: reelFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            searchTerm,
        });
    }, []);

    useEffect(() => {
        if (selectedQuestion) {
            setSubtitle(selectedQuestion?.title);
            setDetailComponent(<QuestionDetail />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedQuestion]);

    useEffect(() => {
        if (id) {
            try {
                CS_FETCH_QUESTION_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            setSubtitle(null);
            setDetailComponent(null);
        }
    }, [id]);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'Answered or Not' },
                    ...ANSWERED_OPTIONS,
                ]}
                selectedValue={answeredFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_ANSWERED_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'Featured or Not' },
                    ...FEATURED_OPTIONS,
                ]}
                selectedValue={featuredFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_FEATURED_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'Trending or Not' },
                    ...TRENDING_OPTIONS,
                ]}
                selectedValue={trendingFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_TRENDING_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'Reel or Not' },
                    ...REEL_OPTIONS,
                ]}
                selectedValue={reelFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_REEL_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Brands' },
                    ...brandOptions,
                ]}
                selectedValue={brandFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_BRANDS_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    ...SORTING_OPTIONS,
                ]}
                selectedValue={sortingFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_ORDER_FILTER(value)
                }
        />,
        ]
    }

    const onNextPage = () => {
        CS_QUESTION_REQUEST({
            answered: answeredFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            reel: reelFilter,
            searchTerm,
            per_page,
            page: page + 1,
        });
    }

    const onPrevPage = () => {
        CS_QUESTION_REQUEST({
            answered: answeredFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            reel: reelFilter,
            searchTerm,
            per_page,
            page: page - 1,
        });
    }
    const onPageSelect = (pageSelected) => {
        CS_QUESTION_REQUEST({
            answered: answeredFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            reel: reelFilter,
            searchTerm,
            per_page,
            page: pageSelected,
        });
    }

    const onPerPageSelected = ({ perPage }) => {
        CS_QUESTION_REQUEST({
            answered: answeredFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            reel: reelFilter,
            searchTerm,
            per_page: perPage,
        });
    }

    const onAdd = () => {
        setSubtitle('Add New Question');
        setDetailComponent(<CSQuestionNew/>);
        setShowDelete(false);
    }

    return (
        <section>
            <InformationTab
                sectionName="Questions"
                customFilters={filters()}
                dataTable={
                    <div>
                        <TableResults results={total} addTitle="Add New Question" onAdd={onAdd}/>
                        <QuestionTable
                            onDetailSelection={onDetailSelection}
                            questions={questions}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </div>
                }
                showPrograms={false}
                showSearchField={true}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                detailComponent={detailComponent}
                onDeleteDetail={onDeleteDetail}
                onDetailSelected={onDetailSelection}
                deleteText={'Question'}
                subsectionName={subtitle}
                showDelete={showDelete}
            />
        </section>
    );
});

export default CSQuestions;
