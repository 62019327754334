import DetailOverview from 'components/DetailOverview';
import Tabs from 'components/Tabs';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        member: state.PEMembers.selectedMember,
    };
}

const PEMemberDetails = connect(mapStateToProps)(function (props) {
    const { member } = props;

    function Overview() {
        return (
            <DetailOverview
                fields={[
                    { name: 'First Name', value: member.first_name },
                    { name: 'Last Name', value: member.last_name },
                    { name: 'Email', value: member.email },
                    { name: 'User ID', value: member.brand_user_id },
                    {
                        name: 'Brand',
                        value: member.brand_ident,
                    },
                    {
                        name: 'Referrer',
                        value: member.referrer,
                        type: 'link',
                        url: `/program-engine/members/${member.referrer}`,
                    },
                    { name: 'UUID', value: member.uuid },
                    { name: 'User Agent', value: member.user_agent },
                    { name: 'IP Address', value: member.ip_address },
                    {
                        name: 'Created',
                        value: member.created_int,
                        type: 'date',
                    },
                    {
                        name: 'Updated',
                        value: member.updated_int,
                        type: 'date',
                    },
                ]}
            />
        );
    }

    return (
        <section>
            <Tabs
                sectionNames={['Overview']}
                sectionContents={[<Overview />]}
            />
        </section>
    );
});

export default PEMemberDetails;
