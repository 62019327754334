import membersWorker from '../workers/membersWorker';

const { takeEvery } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* MEMBERS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.FETCH_MEMBERS_REQUEST,
            ACTION_TYPES.UPDATE_MEMBERS_REQUEST,
            ACTION_TYPES.FETCH_MEMBER_REQUEST,
            ACTION_TYPES.FETCH_MEMBER_ACTIVITY_REQUEST,
            ACTION_TYPES.FETCH_MEMBER_CONVERSIONS_REQUEST,
            ACTION_TYPES.UPDATE_MEMBER_CONVERSION_REQUEST,
            ACTION_TYPES.FETCH_MEMBER_HISTORY_REQUEST,
        ],
        membersWorker,
        apiUrl,
        ...params
    );
}

export default MEMBERS_REQUEST;
