import ACTION_TYPES from 'constants/ACTION_TYPES';
import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';

const initialState = {
    episodes: [],
    isLoadingEpisodes: false,
    page: 1,
    total_pages: 1,
    per_page: 10,
    total: 0,
    selectedEpisode: null,
    activities: [],
    activities_page: 1,
    activities_per_page: 10,
    activities_total_pages: 1,
    activities_total: 0,
    isLoadingActivity: false,
    instructors: [],
    tagGroups: [],
    visibilityFilter: 'all',
    seriesFilter: 'all',
    locationFilter: 'all',
    brandsFilter: 'all',
    actionsFilter: 'all',
    episodesOrder: VHS_EPISODES_SORTING[0].value,
    periodFilter: '30',
    actionData: [],
    files: [],
    isLoadingFiles: false,
    history: [],
    history_page: 1,
    history_per_page: 10,
    history_total_pages: 1,
    history_total: 0,
    isLoadingHistory: false,
};

function VHSEpisodesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_SELECT_EPISODE: {
            return {
                ...state,
                selectedEpisode: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODE_INSTRUCTORS_RESPONSE: {
            return {
                ...state,
                instructors: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODES_RESPONSE: {
            return {
                ...state,
                episodes: action.data.list,
                total_pages: action.data.total_pages,
                per_page: action.data.per_page,
                page: action.data.page,
                total: action.data.total,
                isLoadingEpisodes: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODES_REQUEST: {
            return {
                ...state,
                isLoadingEpisodes: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODE_RESPONSE: {
            return {
                ...state,
                selectedEpisode: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODES_ACTIONS_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODES_ACTIONS_RESPONSE: {
            return {
                ...state,
                activities: action.data.list,
                activities_page: action.data.page,
                activities_per_page: action.data.per_page,
                activities_total_pages: action.data.total_pages,
                activities_total: action.data.total,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODE_HISTORY_REQUEST: {
            return {
                ...state,
                isLoadingHistory: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODE_HISTORY_RESPONSE: {
            return {
                ...state,
                history: action.data.list,
                history_page: action.data.page,
                history_per_page: action.data.per_page,
                history_total_pages: action.data.total_pages,
                history_total: action.data.total,
                isLoadingHistory: false,
            };
        }
        case ACTION_TYPES.VHS_POST_EPISODE_RESPONSE: {
            return {
                ...state,
                selectedEpisode: action.data,
            };
        }
        case ACTION_TYPES.VHS_UPDATE_EPISODE_RESPONSE: {
            return {
                ...state,
                selectedEpisode: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODES_TAG_GROUPS_RESPONSE: {
            return {
                ...state,
                tagGroups: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_EPISODES_SERIES_FILTER: {
            return {
                ...state,
                page:0,
                seriesFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_EPISODES_LOCATION_FILTER: {
            return {
                ...state,
                page:0,
                locationFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_EPISODES_BRANDS_FILTER: {
            return {
                ...state,
                page:0,
                brandsFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_EPISODES_VISIBILITY_FILTER: {
            return {
                ...state,
                page:0,
                visibilityFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_EPISODES_ACTIONS_FILTER: {
            return {
                ...state,
                page:0,
                actionsFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODES_ACTIVITY_CHART_RESPONSE: {
            return {
                ...state,
                chartData: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_EPISODES_PERIOD_FILTER: {
            return {
                ...state,
                page:0,
                periodFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_EPISODES_ORDER: {
            return {
                ...state,
                page:0,
                episodesOrder: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODE_FILES_REQUEST: {
            return {
                ...state,
                isLoadingFiles: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_EPISODE_FILES_RESPONSE: {
            return {
                ...state,
                files: action.data,
                isLoadingFiles: false,
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSEpisodesReducer;
