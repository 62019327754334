/* eslint-disable react-hooks/exhaustive-deps */
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LinkButton from 'components/LinkButton';


function mapStateToProps(state) {
    return { };
}

function QuestionTableRaw({
    questions = [],
    onDetailSelection = null,
    isLoading,
}) {
    const navigate = useNavigate();

    return (
        <section>
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Question</th>
                        <th>Answers</th>
                        <th className='text-center'>Posted By</th>
                        <th>Tags</th>
                        <th>Status</th>
                        <th>Visibility</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {questions.map((question) => (
                        <tr key={`question-${question.id}`}>
                            <td>
                            {question.poster_url && 
                                <SelectableField
                                    url={`/community/questions/${question.id}`}
                                >
                                    <img
                                        src={question.poster_url}
                                        alt={question.name}
                                        style={{
                                            maxHeight: '4rem',
                                            maxWidth: '5rem',
                                            minHeight: '4rem',
                                            minWidth: '5rem',
                                        }}
                                    />
                                </SelectableField>
                            }
                            </td>
                            <td>
                                <SelectableField
                                    action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(question);
                                        }
                                        navigate(`/community/questions/${question.id}`);
                                    }}
                                    text={<div className='flex flex-col'><strong>{question.title}</strong><span className='link-black'>{question.body}</span></div>}
                                />
                            </td>
                            <td className='text-center'>
                                {question.responses_count}
                            </td>
                            <td className='text-center'>
                                <div className='flex flex-col'>
                                    <strong className='text-lg text-primary-dark-color'>{question.member.name}</strong>
                                    <span className='member-subline text-center uppercase text-xs p-1 mt-2 px-2 self-center font-semibold'>{question.member?.subline}</span>
                                </div>
                            </td>
                            <td className="tags">
                                <div className="tagbox">
                                    {question.tags?.map((tag) => (
                                        <div
                                            className="tag"
                                            key={`tag-${question.id}-${tag.id}`}
                                        >
                                            {tag.name}
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td>
                                <div className='flex flex-col'>
                                {question.is_featured && <span>Featured</span>}
                                {question.is_trending && <span>Trending</span>}
                                {question.is_reel && <span>Reel</span>}
                                {!question.is_featured && !question.is_trending && !question.is_reel && <span>None</span>}
                                </div>
                            </td>
                            <td>
                                <span className='capitalize'>{question.visibility}</span>
                            </td>
                            <td>
                                    <LinkButton
                                        className="table-view-button"
                                        action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(question);
                                        }
                                        navigate(`/community/questions/${question.id}`);
                                    }}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
}

const QuestionTable = connect(mapStateToProps)(QuestionTableRaw);
export default QuestionTable;
