import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    list: [],
    isLoadingViews: false,
    per_page: 10,
    page: 1,
    total_pages: 1,
    total: 0,
    program: 'all',
    selectedView: null,
    activity: [],
    activity_page: 1,
    activity_per_page: 10,
    activity_total_pages: 1,
    isLoadingActivity: false,
    chartData: [],
    selectedPeriod: '30',
};

function viewsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.SELECT_VIEWS_PROGRAM: {
            return {
                ...state,
                program: action.data,
            };
        }
        case ACTION_TYPES.SELECT_VIEW: {
            return { ...state, selectedView: action.data };
        }
        case ACTION_TYPES.FETCH_VIEWS_REQUEST: {
            return {
                ...state,
                isLoadingViews: true,
            };
        }
        case ACTION_TYPES.FETCH_VIEWS_RESPONSE: {
            return {
                ...state,
                list: action.data.list,
                per_page: action.data.per_page,
                page: action.data.page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingViews: false,
            };
        }
        case ACTION_TYPES.FETCH_VIEW_ACTIVITY_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.FETCH_VIEW_ACTIVITY_RESPONSE: {
            return {
                ...state,
                activity: action.data.list,
                activity_page: action.data.page,
                activity_per_page: action.data.per_page,
                activity_total_pages: action.data.total_pages,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.FETCH_VIEWS_CHART_RESPONSE: {
            return {
                ...state,
                chartData: action.data.list,
            };
        }
        case ACTION_TYPES.FETCH_VIEW_RESPONSE: {
            return {
                ...state,
                selectedView: action.data,
            };
        }
        case ACTION_TYPES.SELECT_VIEWS_PERIOD: {
            return {
                ...state,
                selectedPeriod: action.data.selectedPeriod,
            };
        }
        default:
            return state;
    }
}

export default viewsReducer;
