import LineChart from 'components/LineChart';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {};
}

const ProgramEngine = connect(mapStateToProps)(function (props) {
    return (
        <section>
            <h1 className="admin-section-title">
                &#x1f3cb; &nbsp; Program Engine
            </h1>
            <div className="flex space-x-4">
                <div className="w-1/2">
                    <div className="mb-2">Plan Starts in Last 30 Days</div>
                    {<LineChart dataArrays={[[]]} colors={['blue']} />}
                </div>
                <div className="w-1/2">
                    <div className="mb-2">
                        Assignments Completend in Last 30 Days
                    </div>
                    {<LineChart dataArrays={[[]]} colors={['blue']} />}
                </div>
            </div>
        </section>
    );
});

export default ProgramEngine;
