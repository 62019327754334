const ROUTING = [
    //{ title: 'Dashboard', path: '/' },
    {
        key: 'ref',
        title: 'Referrals',
        icon: 'referral',
        path: '/referrals',
        children: [
            { title: 'Views', path: '/referrals/views' },
            { title: 'Conversions', path: '/referrals/conversions' },
            { title: 'Members', path: '/referrals/members' },
            { title: 'Programs', path: '/referrals/programs' },
        ],
    },
    {
        key: 'vhs',
        icon: 'cassette',
        title: 'Video Hosting',
        path: '/vhs',
        children: [
            { title: 'Admin', path: '/vhs/admin', role: 'Administrator' },
            { title: 'Analytics', path: '/vhs/analytics', role: 'Administrator' },
            { title: 'Episodes', path: '/vhs/episodes' },
            { title: 'Programs', path: '/vhs/programs' },
            { title: 'Meetings', path: '/vhs/meetings', role: 'Administrator' },
            { title: 'Series', path: '/vhs/series', role: 'Administrator' },
            { title: 'Instructors', path: '/vhs/instructors' },
            { title: 'Tags', path: '/vhs/tags' },
            { title: 'Members', path: '/vhs/members' },
            { title: 'Locations', path: '/vhs/locations' },
        ],
    },
    {
        key: 'pe',
        icon: 'program-engine',
        title: 'Program Engine',
        path: '/program-engine',
        children: [
            { title: 'Assignments', path: '/program-engine/categories' },
            { title: 'Programs', path: '/program-engine/programs' },
            { title: 'Phases', path: '/program-engine/phases' },
            { title: 'Sequences', path: '/program-engine/sequences' },
            { title: 'Matrices', path: '/program-engine/matrices' },
            { title: 'Series', path: '/program-engine/series' },
            { title: 'Tags', path: '/program-engine/tags' },
            { title: 'Members', path: '/program-engine/members' },
        ],
    },
    {
        key: 'cs',
        icon: 'community',
        title: 'Community',
        path: '/community',
        children: [
            { title: 'Moderator Console', path: '/community/moderator' },
            { title: 'Questions', path: '/community/questions' },
            { title: 'Articles', path: '/community/articles' },
            { title: 'Flags', path: '/community/flags' },
            { title: 'Tags', path: '/community/tags' },
            { title: 'Members', path: '/community/members' },
        ],
    },
    { key: 'usr', icon: 'admin', title: 'Admins', path: '/admins' },
    
];

function reducePaths(allRoutes, currentRoute) {
    const nextAllRoutes = allRoutes;
    nextAllRoutes[currentRoute.path] = currentRoute.title;

    if (currentRoute?.children?.length) {
        const childRoutes = currentRoute.children.reduce(reducePaths, {});

        for (let childRoute in childRoutes) {
            nextAllRoutes[childRoute] = childRoutes[childRoute];
        }
    }

    return nextAllRoutes;
}

export const pathToTitle = ROUTING.reduce(reducePaths, {});

export default ROUTING;
