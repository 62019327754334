export const COMMENT_OPTIONS = [
    { value: 1, label: 'Commented'},
    { value: 0, label: 'Not Commented'},
]

export const FEATURED_OPTIONS = [
    { value: 1, label: 'Featured'},
    { value: 0, label: 'Not Featured'},
]

export const TRENDING_OPTIONS = [
    { value: 1, label: 'Trending'},
    { value: 0, label: 'Not Trending'},
]

export const SORTING_OPTIONS = [
    { value: 'posted_at-DESC', label: 'Most Recent First' },
    { value: 'posted_at-ASC', label: 'Oldest First' },
];

export const CREATED_SORTING_OPTIONS = [
    { value: 'created_at-DESC', label: 'Most Recent First' },
    { value: 'created_at-ASC', label: 'Oldest First' },
];

export const VISIBILITY_OPTIONS = [
    { value: 'published', label: 'Published'},
    { value: 'unpublished', label: 'Unpublished'},
    { value: 'pending', label: 'Pending'},
    { value: 'denied', label: 'Denied'}
];

export const REASON_OPTIONS = [
    { value: 'inappropriate', label: 'Inappropriate'},
    { value: 'spam', label: 'Spam'},
    { value: 'ad', label: 'Ad'},
    { value: 'harassing', label: 'Harassing'},
    { value: 'illegal', label: 'Illegal'},
]

export const STATUS_OPTIONS = [
    { value: 'pending', label: 'Pending' },
    { value: 'allowed_content', label: 'Allowed Content' },
    { value: 'denied_content', label: 'Denied Content' },
]

export const ANSWERED_OPTIONS = [
    { value: 1, label: 'Answered' },
    { value: 0, label: 'Not Answered' },
]

export const REEL_OPTIONS = [
    { value: 1, label: 'Reel' },
    { value: 0, label: 'Not Reel' },
]

export const OVERLAY_TEXT_OPTIONS = [
    { value: '#2D2926', label: 'Black (default)' },
    { value: '#FFFFFF', label: 'White' },
]

export const CONTENT_TYPE_OPTIONS = [
    { value: 'questions', label: 'Questions' },
    { value: 'answers', label: 'Answers' },
    { value: 'comments', label: 'Comments' },
    { value: 'flags', label: 'Flags' },
    { value: 'tags', label: 'Tags' },
]