import DetailOverview from 'components/DetailOverview';

function InstructorOverview({ instructor, brandsSet = {} }) {
    return (
        <section className="flex">
            <img
                alt={instructor.name}
                src={instructor.photo_url}
                style={{ maxHeight: 230, maxWidth: 180, marginRight: '2rem' }}
            />
            <DetailOverview
                fields={[
                    { name: 'Name', value: instructor.name },
                    { name: 'Description', value: instructor.description, type: 'markdown' },
                    { name: 'Email', value: instructor.email },
                    { name: 'Favorite Barre Move', value: instructor.favorite },
                    { name: 'Visibility', value: instructor.visibility },
                    {
                        name: 'Started',
                        value: instructor.start_int,
                        type: 'date',
                    },
                    {
                        name: 'Brand',
                        value: brandsSet[instructor.brand_ident],
                    },
                    {
                        name: 'Created',
                        value: instructor.created_int,
                        type: 'date',
                    },
                    {
                        name: 'Updated',
                        value: instructor.updated_int,
                        type: 'date',
                    },
                ]}
            />
        </section>
    );
}

export default InstructorOverview;
