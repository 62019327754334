import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { useState } from 'react';
import PE_TRANSLATION_FIELDS from 'constants/PE_TRANSLATION_FIELDS';

function TranslationsNew({ onAdd }) {
    const [translation, setTranslation] = useState({
        field_name: PE_TRANSLATION_FIELDS[0] || '',
        translated_text: '',
        lang_code: '',
        lang_terr: '',
    });

    function handleFieldChange(event) {
        const newState = {
            ...translation,
            [event.target.name]: event.target.value,
        };
        setTranslation(newState);
    }

    function handleMarkdownChange(name,value){
        const newState = {
            ...translation,
            [name]: value,
        };
        setTranslation(newState);
    }

    function onCreate(event) {
        event.preventDefault();
        onAdd(translation);
    }

    return (
        <form onSubmit={onCreate}>
            <table>
                <tbody>
                    <tr>
                        <td className="uppercase label">Field</td>
                        <td>
                            <select
                                name="field_name"
                                value={translation?.field_name}
                                onChange={handleFieldChange}
                                className='capitalize'
                            >
                                {PE_TRANSLATION_FIELDS.map((option) => (
                                    <option
                                        key={`field-${option}`}
                                        value={option}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Text</td>
                        <td>
                            <MarkdownEditor
                                    name="translated_text"
                                    value={translation?.translated_text}
                                    onChange={(value) => handleMarkdownChange('translated_text',value)}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                            <MarkdownHint />
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Language Code</td>
                        <td>
                            <input
                                name="lang_code"
                                value={translation?.lang_code}
                                onChange={handleFieldChange}
                                required
                            />
                            <div className="mt-4 text-xs">
                                Example: <b>en</b> or <b>es</b>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">
                            Language Territory
                        </td>
                        <td>
                            <input
                                name="lang_terr"
                                value={translation?.lang_terr}
                                onChange={handleFieldChange}
                            />
                            <div className="mt-4 text-xs">
                                Optional. Example: <b>US</b> or <b>MX</b>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button type="submit" className="mt-4 button save-changes-button">
                Add Translation
            </button>
        </form>
    );
}

export default TranslationsNew;
