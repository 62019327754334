/* eslint-disable react-hooks/exhaustive-deps */
import LoadingTable from 'components/LoadingTable';
import DateField from 'components/DateField';
import { connect } from 'react-redux';
import useActions from 'hooks/useActions';
import { REASON_OPTIONS } from 'constants/COMMUNITY_STATUS'


function mapStateToProps(state) {
    return {};
}

function FlagsTableRaw({
    flags = [],
    isLoading,
}) {
    const {
        CS_UPDATE_ANSWER_FLAGS_REASON_REQUEST,
    } = useActions();


    const handleChangeReason = (body) => {
        CS_UPDATE_ANSWER_FLAGS_REASON_REQUEST(body)
    }
    return (
        <section>
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th>Creator</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Actions</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {flags.map((flag) => (
                        <tr key={`flag-${flag.id}`}>
                            <td>
                                <strong>{flag.member_id}</strong>
                            </td>
                            <td>
                                <DateField timeMillis={flag.created_int}/>
                            </td>
                           <td>
                                {flag.status === 'pending' && <strong style={{color:'#888'}}>Pending</strong>}
                                {flag.status === 'allowed_content' && <strong style={{color:'#3e8f1a'}}>Allowed Content</strong>}
                                {flag.status === 'denied_content' && <strong style={{color:'#f24726'}}>Denied Content</strong>}
                           </td>
                           <td>
                           <div className='flex'>
                           {(flag.status === 'pending' || flag.status === 'denied_content') && 
                                <button
                                    className="bg-green-add p-2 mt-2 ml-2 text-white flex items-center justify-center"
                                    onClick={() => handleChangeReason({status:'allowed_content',id:flag.id})}
                                    >Approve Content
                                </button>
                           }
                           {(flag.status === 'pending' || flag.status === 'allowed_content') && 
                                 <button
                                        className="delete-button p-2 mt-2 ml-2 text-white flex items-center justify-center"
                                        onClick={() => handleChangeReason({status:'denied_content',id:flag.id})}
                                        >Deny Content
                                </button>
                           }
                           </div>
                           </td>
                           <td>
                           {flag.status === 'denied_content' && 
                                <select className="w-full"
                                    name="reason"
                                    onChange={(event) => handleChangeReason({reason:event.target.value,id:flag.id})}
                                    >
                                        <option>Select Reason</option>
                                        {REASON_OPTIONS.map((item,idx) => (
                                            <option key={'reason-'+idx} value={item.value}>{item.label}</option>
                                    ))}
                                    </select>
                           }
                           </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
}

const FlagsTable = connect(mapStateToProps)(FlagsTableRaw);
export default FlagsTable;
