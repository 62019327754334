import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';


function mapStateToProps(state) {
    return {
        instructors: state.vhsInstructors
    };
}

const ReassignInstructors = connect(mapStateToProps)(function(props) {
    const { instructors } = props;

    const {
        VHS_FETCH_INSTRUCTORS_REQUEST,
        VHS_REPLACE_INSTRUCTORS_REQUEST
    } = useActions();

    const [targetInstructor, setTargetInstructor] = useState(null);
    const [newInstructor, setNewInstructor] = useState(null);
    const [waitingCompletion, setWaitingCompletion] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    let submitButtonClasses = "p-2 mr-4 text-white self-end";
    let submitText = "Reassign Instructor";
    if(targetInstructor) {
        if(newInstructor) {
            submitButtonClasses += " bg-green-add";
        } else {
            submitButtonClasses += " delete-button";
            submitText = "Remove Instructor";
        }
    } else {
        submitButtonClasses += " disabled-button";
    }

    useEffect(() => {
        if(!instructors.instructorsOptions) {
            VHS_FETCH_INSTRUCTORS_REQUEST({ per_page:10000, page:1 });
        }
    });

    useEffect(() => {
        if(instructors.recentReassignments !== null && waitingCompletion) {
            setWaitingCompletion(false);
            setTargetInstructor(null);
            setNewInstructor(null);

            if(newInstructor) {
                setSuccessMessage(`Reassignment Successful. All (${instructors.recentReassignments}) episodes assigned ${targetInstructor.label} have been been reassigned ${newInstructor.label}`);
            } else {
                setSuccessMessage(`Reassignment Successful. All (${instructors.recentReassignments}) episodes assigned ${targetInstructor.label} have had this instructor removed.`);
            }
        }
    }, [targetInstructor, newInstructor, waitingCompletion, instructors.recentReassignments]);

    function onSubmit() {
        if(!targetInstructor) {
            return;
        }

        setSuccessMessage(null);
        setWaitingCompletion(true);
        VHS_REPLACE_INSTRUCTORS_REQUEST({ target_instructor_id: targetInstructor.value, new_instructor_id: newInstructor?.value });
    }

    return (
        <div className="content content-pending rounded-xl my-4 p-6">
            <h2 className="text-xl mb-0">Reassign Instructor's Episodes</h2>
            <label className="block">All episodes that have the target instructor will have it removed or reassigned.</label>
            <small className="block">NOTE: This does NOT delete the instructor, and if episodes have multiple instructors those other non-targetted instructors will not be affected.</small>
            <div className="flex mt-4">
                <div className="w-1/3">
                    <label className="block mb-1">
                        Target Instructor <br/>
                        <small>(to find and replace or remove)</small>
                    </label>
                    <Select
                        options={instructors?.instructorsOptions?.filter((instructorOp)=> { return instructorOp.value !== newInstructor?.value })} 
                        value={targetInstructor}
                        onChange={(selected)=> setTargetInstructor(selected)}
                        labelledBy="TargetInstructorSelect"
                    />
                </div>
                <div className="w-1/3 px-2">
                    <label className="block mb-1">
                        Replacement Instructor <br/>
                        <small>(Leave blank to remove target from all episodes)</small>
                    </label>
                    <Select
                        options={instructors?.instructorsOptions?.filter((instructorOp)=> { return instructorOp.value !== targetInstructor?.value })} 
                        value={newInstructor}
                        onChange={(selected)=> setNewInstructor(selected)}
                        labelledBy="NewInstructorSelect"
                    />
                </div>
                <button className={submitButtonClasses} type="button" onClick={onSubmit} disabled={!targetInstructor || waitingCompletion}>{submitText}</button>
            </div>
            {successMessage && 
                <p className="text-xl mt-4">{successMessage}</p>
            }
        </div>
    );
});

export default ReassignInstructors;