const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    sequences: [],
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    selectedSequence: null,
    isLoadingSequences: false,
    tagGroups: [],
    tasks: [],
};

function PESequencesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_SEQUENCES_REQUEST: {
            return {
                ...state,
                isLoadingSequences: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_SEQUENCES_RESPONSE: {
            return {
                ...state,
                sequences: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
                isLoadingSequences: false,
            };
        }
        case ACTION_TYPES.PE_GET_SEQUENCE_RESPONSE:
        case ACTION_TYPES.PE_SELECT_SEQUENCE:
        case ACTION_TYPES.PE_UPDATE_SEQUENCE_RESPONSE:
        case ACTION_TYPES.PE_POST_SEQUENCE_RESPONSE: {
            return {
                ...state,
                selectedSequence: action.data,
            };
        }
        case ACTION_TYPES.PE_FETCH_SEQUENCE_TAG_GROUPS_RESPONSE: {
            return {
                ...state,
                tagGroups: action.data,
            };
        }
        case ACTION_TYPES.PE_GET_SEQUENCE_TASKS_RESPONSE: {
            return {
                ...state,
                tasks: action.data,
            };
        }
        case ACTION_TYPES.PE_POST_SEQUENCE_CHANGE_ORDER_RESPONSE: {
            return {
                ...state,
                tasks: action.data,
            };
        }
        default:
            return state;
    }
}

export default PESequencesReducer;
