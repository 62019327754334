import React from 'react';

function Dashboard() {
    return (
        <section>
        </section>
    );
}

export default Dashboard;
