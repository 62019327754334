/* eslint-disable react-hooks/exhaustive-deps */
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LinkButton from 'components/LinkButton';


function mapStateToProps(state) {
    return {};
}

function CommentsTableRaw({
    comments = [],
    onDetailSelection = null,
    isLoading,
}) {
    const navigate = useNavigate();
    const { id } = useParams();
    return (
        <section>
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Comment</th>
                        <th>Posted By</th>
                        <th>Status</th>
                        <th>Visibility</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {comments.map((comment) => (
                        <tr key={`comment-${comment.id}`}>
                            <td>
                            {comment.poster_url && 
                                <SelectableField
                                    url={`/community/articles/${id}/comments/${comment.id}`}
                                >
                                    <img
                                        src={comment.poster_url}
                                        alt={comment.name}
                                        style={{
                                            maxHeight: '4rem',
                                            maxWidth: '5rem',
                                            minHeight: '4rem',
                                            minWidth: '5rem',
                                        }}
                                    />
                                </SelectableField>
                            }
                            </td>
                            <td>
                                <SelectableField
                                    action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(comment);
                                        }
                                    }}
                                    text={<strong>{comment.body}</strong>}
                                />
                            </td>
                            <td>
                                <strong>{comment.member.name}</strong>
                            </td>
                            <td>
                                {comment?.flags?.length && <><strong style={{color: 'red'}}>Flagged</strong> 🚩</>}
                            </td>
                            <td>
                                <span className='capitalize'>{comment.visibility}</span>
                            </td>
                            <td>
                                    <LinkButton
                                        className="table-view-button"
                                        action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(comment);
                                        }
                                        navigate(`/community/articles/${id}/comments/${comment.id}`);
                                    }}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
}

const CommentsTable = connect(mapStateToProps)(CommentsTableRaw);
export default CommentsTable;
