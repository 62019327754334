import moment from 'moment';

const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');
const { default: api } = require('../api/api');

function* fetchActivites(per_page = 10, page = 1, actions = 'all', apiUrl) {
    let url = `/vhs/track_events?per_page=${per_page}&page=${page}`;
    if (actions !== 'all') {
        url += `&actions=${actions}`;
    }
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_ACTIVITIES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getChartData(days = 30, actions = 'all', apiUrl) {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(Number.parseInt(days), 'd');
    const initialMillis = today.toDate().getTime();

    let url = `/vhs/chart?stop_int=${todayMillis}&start_int=${initialMillis}`;

    actions !== 'all' && (url += `&actions=${actions}`);
    days === '1' && (url += '&group_by=hour');

    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_ACTIVITY_CHART_RESPONSE,
            data: json.results,
        });
    }
}

function* getActivity(id, apiUrl) {
    const json = yield api.get(`/vhs/track_events/${id}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_ACTIVITY_RESPONSE, 
            data: json,
        });
    }
}


function* vhsExportTrackingData( action, series_id, from, to, apiUrl) {
    const json = yield api.post("/vhs/wizards/tracking_export", {
        action, series_id, from, to
    }, apiUrl)

    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_EXPORT_TRACKING_DATA_RESPONSE,
            data: json,
        });
    }
}

function* vhsAnalyticsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ACTIVITIES_REQUEST:
            yield fetchActivites(
                action.data?.per_page,
                action.data?.page,
                action.data?.actions,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_ACTIVITY_CHART_REQUEST:
            yield getChartData(
                action.data?.period,
                action.data?.actions,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_ACTIVITY_REQUEST:
            yield getActivity(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_EXPORT_TRACKING_DATA_REQUEST:
            yield vhsExportTrackingData(
                action.data?.action,
                action.data?.series_id,
                action.data?.from,
                action.data?.to,
                apiUrl
            );
            break;
        default:
            break;
    }
}


export default vhsAnalyticsWorker;
