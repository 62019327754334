import DateField from 'components/DateField';
import SelectableField from 'components/SelectableField';
import { XIcon } from '@heroicons/react/outline';

function VHSProgramEntry({ entry, showControls, onRemove }) {
    return (
        <div key={entry.id} className="flex mb-2 program-entry justify-between items-center">
            {!entry.episode &&
                <div key={entry.id} className="flex w-full mb-2 justify-between">
                    <div className="vhs-rest-day w-11/12">
                        <label>REST DAY</label>
                    </div>
                </div>
            }
            {entry.episode &&
                <div className="flex w-3/4 content-between gap-2">
                    <div className="relative">
                        {entry.episode?.poster?.url &&
                            <SelectableField
                                url={`/vhs/episodes/${entry.episode.id}`}
                            >
                                <img
                                    src={entry.episode.poster?.url}
                                    alt={entry.episode.name}
                                    style={{
                                        maxHeight: '4rem',
                                        maxWidth: '5rem',
                                        minHeight: '4rem',
                                        minWidth: '5rem',
                                    }}
                                />
                            </SelectableField>
                        }
                    </div>
                    <div className="flex flex-col">
                        <SelectableField text={entry.episode?.name ?? ""} url={`/vhs/episodes/${entry.EpisodeId}`} />
                        {entry.episode?.instructor && <SelectableField text={entry.episode.instructor.name ?? "Unknown Instructor"} url={`/vhs/instructor/${entry.episode.instructor.id}`} />}
                        <label><DateField timeMillis={entry.episode?.start_int} /></label>
                    </div>
                </div>
            }
            {showControls &&
                <button className="button delete-button p-2 text-white remove-btn" onClick={() => { onRemove(entry) }}>
                    <XIcon height="1rem" color="white" />
                </button>
            }
        </div>
    );
}

export default VHSProgramEntry;
