import DateField from 'components/DateField';
import SelectableField from 'components/SelectableField';
import MarkdownPreview from '@uiw/react-markdown-preview';

/**
 * fields as {name, value, type, url}
 * type and url are optionals, urls will be use just in case of types 'link' and 'new-tab'
 */
function DetailOverview({ fields = [] }) {
    function fieldElement(field) {
        switch (field.type) {
            case 'date':
                return <DateField timeMillis={field.value} />;
            case 'link':
                return <SelectableField text={field.value} url={field.url} />;
            case 'new-tab':
                return (
                    <a href={field.url} target="_blank" rel="noreferrer">
                        {field.value}
                    </a>
                );
            case 'list-links':
                return field.value.map(item => (
                    <>
                    <SelectableField text={item.name} url={field.urlPattern+""+item.id} /><br></br>
                    </>
                ))
            case 'markdown':
                return <MarkdownPreview source={field.value} />
            case 'html':
                return <div dangerouslySetInnerHTML={{ __html: field.value }} />
            default:
                return field.value;
        }
    }
    return (
        <section>
            <table className='overview-table'>
                <tbody>
                    {fields.map((field) => (
                        <tr key={`overview-tr-${field.name}`}>
                            <td className="uppercase label">
                                {field.name}
                            </td>
                            <td>{fieldElement(field)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}

export default DetailOverview;
