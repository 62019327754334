import {
    ArrowDownIcon,
    ArrowRightIcon,
    ArrowUpIcon,
    XCircleIcon,
} from '@heroicons/react/outline';
import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import TableImage from 'components/TableImage';
import TableNavigator from 'components/TableNavigator';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';
import PEAssignmentModalContent from 'routing/ProgramEngine/Assignments/AssignmentModalContent';
import PEMatricesModalContent from 'routing/ProgramEngine/Matrices/MatrixModalContent';
import PESeriesEdit from './SeriesEdit';

const { default: InformationTab } = require('components/InformationTab');
const { default: TableResults } = require('components/TableResults');
const { default: Tabs } = require('components/Tabs');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        series: state.PESeries.selectedSeries,
        assignments: state.PESeries.assignments,
        page: state.PESeries.assignments_page,
        per_page: state.PESeries.assignments_per_page,
        total: state.PESeries.assignments_total,
        total_pages: state.PESeries.assignments_total_pages,
        isLoading: state.PESeries.assignments_is_loading,
        categoriesOptions: state.PECategories.categoriesOptions,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const TASK_TYPES = {
    Assignment: 'assignments',
    Series: 'series',
    Matrix: 'matrices',
};

const PESeriesDetails = connect(mapStateToProps)(function (props) {
    const {
        series,
        assignments,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        categoriesOptions,
        selectedRegionFilter,
    } = props;

    const {
        PE_SERIES_ASSIGNMENTS_REQUEST,
        TOGGLE_MODAL,
        SET_MODAL_CONTENT,
        PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST,
        PE_DELETE_SERIES_ASSIGNMENT_REQUEST,
        PE_CHANGE_SERIES_ASSIGNMENT_POSITION_REQUEST,
    } = useActions();

    const [categoriesSet, setCategoriesSet] = useSetFromOptions();

    function onNext() {
        PE_SERIES_ASSIGNMENTS_REQUEST({
            seriesId: series.id,
            page: page + 1,
            per_page,
        });
    }

    function onPrev() {
        PE_SERIES_ASSIGNMENTS_REQUEST({
            seriesId: series.id,
            page: page - 1,
            per_page,
        });
    }

    function onPageSelected(pageSelected) {
        PE_SERIES_ASSIGNMENTS_REQUEST({
            seriesId: series.id,
            page: pageSelected,
            per_page,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_SERIES_ASSIGNMENTS_REQUEST({
            seriesId: series.id,
            per_page: perPage,
        });
    }

    useEffect(() => {
        if (categoriesOptions) {
            setCategoriesSet(categoriesOptions);
        }
        // eslint-disable-next-line
    }, [categoriesOptions]);

    useEffect(() => {
        PE_SERIES_ASSIGNMENTS_REQUEST({ seriesId: series.id });
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function OverviewTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Assignment" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <td>Position</td>
                            <td>Poster</td>
                            <td>Name</td>
                            <td>Category</td>
                            <td>Tags</td>
                            <td>Move</td>
                            <td>Remove</td>
                            <td>View</td>
                        </tr>
                    </thead>
                    <tbody>
                        {assignments.map((assign) => (
                            <tr key={`assignment-${assign.id}`}>
                                <td>{assign.position}</td>
                                <TableImage
                                    alt={assign.name}
                                    imageUrl={assign.poster_url}
                                />
                                <td>
                                {assign.task_type === 'Assignment' ? 
                                        (assign.task?.category_id ? 
                                        <SelectableField
                                            text={assign.name}
                                            url={`/program-engine/categories/${assign.task?.category_id}/${
                                                TASK_TYPES[assign.task_type]
                                            }/${assign.task_id}`}
                                        />
                                        :  <span>{assign.name}</span>)
                                        : <SelectableField
                                            text={assign.name}
                                            url={`/program-engine/${
                                                TASK_TYPES[assign.task_type]
                                            }/${assign.task_id}`}
                                        />
                                    }
                                </td>
                                <td>
                                    <SelectableField
                                        text={
                                            categoriesSet[
                                                assign?.task?.category_id
                                            ]
                                        }
                                        url={`/program-engine/categories/${assign?.task?.category_id}`}
                                    />
                                </td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {assign?.task?.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${assign.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex">
                                        {assign.position !== 1 && (
                                            <TableButton
                                                className="order-button"
                                                action={() =>
                                                    PE_CHANGE_SERIES_ASSIGNMENT_POSITION_REQUEST(
                                                        {
                                                            assignmentId:
                                                                assign.id,
                                                            seriesId: series.id,
                                                            page,
                                                            per_page,
                                                            position:
                                                                assign.position
                                                                    ? assign.position -
                                                                      1
                                                                    : 1,
                                                        }
                                                    )
                                                }
                                            >
                                                <ArrowUpIcon
                                                    height={'1.3rem'}
                                                />
                                            </TableButton>
                                        )}
                                        {
                                            <TableButton
                                                className="order-button"
                                                action={() =>
                                                    PE_CHANGE_SERIES_ASSIGNMENT_POSITION_REQUEST(
                                                        {
                                                            assignmentId:
                                                                assign.id,
                                                            seriesId: series.id,
                                                            page,
                                                            per_page,
                                                            position:
                                                                assign.position
                                                                    ? assign.position +
                                                                      1
                                                                    : 1,
                                                        }
                                                    )
                                                }
                                            >
                                                <ArrowDownIcon
                                                    height={'1.3rem'}
                                                />
                                            </TableButton>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <TableButton
                                        className="button delete-button"
                                        action={() =>
                                            PE_DELETE_SERIES_ASSIGNMENT_REQUEST(
                                                {
                                                    assignmentId: assign.id,
                                                    seriesId: series.id,
                                                    page,
                                                    per_page,
                                                }
                                            )
                                        }
                                    >
                                        <XCircleIcon
                                            height={'1.3rem'}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Remove
                                    </TableButton>
                                </td>
                                <td>
                                {assign.task_type === 'Assignment' ? 
                                        (assign.task?.category_id ? 
                                        <LinkButton
                                            className="table-view-button"
                                            url={`/program-engine/categories/${assign.task?.category_id}/${
                                                    TASK_TYPES[assign.task_type]
                                                }/${assign.task_id}`}
                                        >
                                            View{' '}
                                            <ArrowRightIcon
                                                height={'1.3rem'}
                                                className="ml-2"
                                            />
                                        </LinkButton>
                                        :  <span></span>)
                                        : <LinkButton
                                            className="button table-view-button"
                                            url={`/program-engine/${
                                                TASK_TYPES[assign.task_type]
                                            }/${assign.task_id}`}
                                        >
                                            View
                                        </LinkButton>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    function onAssignmentSelected(assignment) {
        PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST({
            seriesId: series.id,
            taskId: assignment.id,
            taskType: 'Assignment'
        });
    }

    function onAdd() {
        SET_MODAL_CONTENT(
            <PEAssignmentModalContent
                forcedLoading={isLoading}
                onSelect={onAssignmentSelected}
            />
        );
        TOGGLE_MODAL();
    }

    function onMatrixSelected(taskId) {
        PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST({
            seriesId: series.id,
            taskId: taskId,
            taskType: 'Matrix'
        });
    }

    function ExtraButtons(){
        return (
            <button
                className="bg-green-add p-2 ml-4 text-white"
                onClick={() => {
                    SET_MODAL_CONTENT(
                        <PEMatricesModalContent
                            onSelect={(task) =>
                                onMatrixSelected(task.id)
                            }
                        />
                    );
                    TOGGLE_MODAL();
                }}
            >
                Add Matrix
            </button>
        )
    }

    function Overview() {
        return (
            <InformationTab
                sectionName="Assignments"
                dataTable={<OverviewTable />}
                showPrograms={false}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                ExtraControls={<ExtraButtons />}
                showBreadcrumbs={false}
            />
        );
    }

    return (
        <Tabs
            sectionNames={['Overview', 'Settings']}
            sectionContents={[<Overview />, <PESeriesEdit series={series} />]}
        />
    );
});

export default PESeriesDetails;
