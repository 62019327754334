import InformationTab from 'components/InformationTab';
import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PEProgramDetails from './ProgramDetails';
import PEProgramsNew from './ProgramsNew';
import useQueryList from 'hooks/useQuery';
import SingleFilter from 'components/SingleFilter';
import AlertModal from 'components/Modal/AlertModal';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        programs: state.PEPrograms.programs,
        page: state.PEPrograms.page,
        per_page: state.PEPrograms.per_page,
        total_pages: state.PEPrograms.total_pages,
        total: state.PEPrograms.total,
        isLoading: state.PEPrograms.isLoadingPrograms,
        selectedProgram: state.PEPrograms.selectedProgram,
        brandsFilter: state.PEPrograms.brandsFilter,
        brandsOptions: state.PEBrands.brandsOptions,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEPrograms = connect(mapStateToProps)(function (props) {
    const {
        programs,
        page,
        per_page,
        total_pages,
        total,
        isLoading,
        selectedProgram,
        brandsFilter,
        brandsOptions,
        selectedRegionFilter,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const {
        PE_FETCH_PROGRAMS_REQUEST,
        PE_GET_PROGRAM_REQUEST,
        PE_DELETE_PROGRAM_REQUEST,
        PE_SELECT_PROGRAM,
        PE_CLONE_PROGRAM_REQUEST,
        PE_SELECT_PROGRAMS_BRANDS_FILTER,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();
    const navigate = useNavigate();
    const { id } = useParams();
    const { term: searchTerm } = useQueryList(['term']);

    function onAdd() {
        setSubtitle('Add New Program');
        setDetailComponent(<PEProgramsNew />);
    }

    function onNext() {
        PE_FETCH_PROGRAMS_REQUEST({ page: page + 1, per_page, searchTerm, brand: brandsFilter });
    }

    function onPrev() {
        PE_FETCH_PROGRAMS_REQUEST({ page: page - 1, per_page, searchTerm, brand: brandsFilter });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_PROGRAMS_REQUEST({ page: pageSelected, per_page, searchTerm, brand: brandsFilter });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_PROGRAMS_REQUEST({
            per_page: perPage,
            searchTerm,
            brand: brandsFilter
        });
    }

    function filters() {
        return [
            <SingleFilter
                selectedValue={brandsFilter}
                options={[
                    { value: 'all', label: 'All Brands' },
                    ...brandsOptions,
                ]}
                onSelect={(value) => PE_SELECT_PROGRAMS_BRANDS_FILTER(value)}
            />,
        ];
    }

    function onDeleteAction() {
        PE_DELETE_PROGRAM_REQUEST({
            programId: selectedProgram?.id,
            page,
            searchTerm,
            perPage: per_page,
        });
        navigate('/program-engine/programs');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Program?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function onDetailSelection(program) {
        setSubtitle(null);
        setDetailComponent(null);
        PE_SELECT_PROGRAM(program);
        setShowDelete(true);
        if (program) {
            navigate(`/program-engine/programs/${program.id}`);
        } else {
            PE_FETCH_PROGRAMS_REQUEST({
                page,
                per_page,
                searchTerm,
                brand: brandsFilter
            });
            navigate(`/program-engine/programs`);
        }
    }

    useEffect(() => {
        if (selectedProgram) {
            setSubtitle(selectedProgram.name);
            setDetailComponent(<PEProgramDetails />);
            setShowDelete(true);
        } else {
            setShowDelete(false);
            setDetailComponent(null);
        }
    }, [selectedProgram]);

    useEffect(() => {
        if (id) {
            try {
                PE_GET_PROGRAM_REQUEST(id);
            } catch (err) {
                PE_SELECT_PROGRAM(null);
                setSubtitle(null);
                setDetailComponent(null);
            }
        } else {
            PE_SELECT_PROGRAM(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        PE_FETCH_PROGRAMS_REQUEST({
            per_page,
            page,
            searchTerm,
            brand: brandsFilter,
        });
        // eslint-disable-next-line
    }, [brandsFilter, selectedRegionFilter]);

    useEffect(() => {
        PE_FETCH_PROGRAMS_REQUEST({ searchTerm, brand: brandsFilter });
        // eslint-disable-next-line
    }, []);

    function DataTable() {
        return (
            <div>
                <TableResults results={total} addTitle="Add New Program" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Brands</th>
                            <th>Published</th>
                            <th>Tags</th>
                            <th>Clone</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {programs.map((program) => (
                            <tr key={`program-${program.id}`}>
                                <td>
                                    <SelectableField
                                        url={`/program-engine/programs/${program.id}`}
                                        text={program.name}
                                    />
                                </td>
                                <td>
                                    {program.brand_name}
                                </td>
                                <td>
                                    {program.is_published
                                        ? 'Published'
                                        : 'Unpublished'}
                                </td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {program.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${program.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <TableButton
                                        className="delete-button bg-green-add"
                                        action={() => {
                                            PE_CLONE_PROGRAM_REQUEST(
                                                program.id
                                            );
                                        }}
                                    >
                                        Clone Program
                                    </TableButton>
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/programs/${program.id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </div>
        );
    }

    return (
        <InformationTab
            showPrograms={false}
            showDelete={showDelete}
            showSearchField={true}
            sectionName="Programs"
            deleteText="Program"
            dataTable={<DataTable />}
            perPage={per_page}
            detailComponent={detailComponent}
            subsectionName={subtitle}
            onPerPageSelected={onPerPageSelected}
            onDetailSelected={onDetailSelection}
            onDeleteDetail={onDeleteDetail}
            customFilters={filters()}
        />
    );
});

export default PEPrograms;
