import useActions from 'hooks/useActions';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
 

function Logout() {
    const { LOGOUT } = useActions();
    const navigate = useNavigate();
    
    useEffect(() => {
        // eslint-disable-next-line
        const confirmed = confirm(
            `Are you sure you want to exit?`
        );
        if (confirmed) {
         LOGOUT();
        }
        else{
            navigate(-1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return <></>;
}

export default Logout;
