import React, {createRef, useState} from "react";
function SelectOrInput ({name, options = [], onChange = function () {}, placeholder = "", CTAText = "Enter Manually", disabled = false}) {
    const [showInput, setShowInput] = useState(false);
    const selectBox = createRef();
    const handleChange = (event) => {
        if(event.target.value === 'input'){
            setShowInput(true)
        }
        else{
            onChange(event);
        }
    }

    const restartInitialState = () => {
        setShowInput(false);
        selectBox.current.selectedIndex = 0;
    }
    return (
        <div className="content-in-row">
        <select onChange={handleChange} name={name} required disabled={showInput || !!disabled} ref={selectBox}>
            {placeholder !== "" ? <option value="">{placeholder}</option> : null}
            <option value={"input"}>{CTAText}</option>
            <option disabled>──────────</option>
            {
                options.map((option,index) => (
                    <option
                        key={"opt-"+option.id}
                        value={option.value}
                    >{option.label}</option>
                ))
            }
        </select>
        {showInput ? <>
            <input
                name={name}
                onChange={onChange}
                placeholder={CTAText}
                style={{width:400}}
                required
                disabled={!!disabled}
            />
            <button
                onClick={restartInitialState}
                className="bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">
                Cancel
            </button>
        </> : null}
        </div>
    )
}

export default SelectOrInput;