import api from '../api/api';

const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');

function* getBrandsOptions(apiUrl) {
    const json = yield api.get(`/pe/brands?page=1&per_page=${1000}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_BRANDS_OPTIONS_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchBrands(page = 1, per_page = 10, apiUrl) {
    const json = yield api.get(
        `/pe/brands?page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_BRANDS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getBrand(brandIdent, apiUrl) {
    const json = yield api.get(`/pe/brands/${brandIdent}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* updateBrand(brand, apiUrl) {
    const json = yield api.put(`/pe/brands/${brand.ident}`, brand, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* deleteBrand(brandIdent, apiUrl) {
    const json = yield api.del(`/pe/brands/${brandIdent}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* postBrand(brand, apiUrl) {
    const json = yield api.post(`/pe/brands`, brand, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* peBrandsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_BRANDS_REQUEST:
            yield fetchBrands(action.data?.page, action.data?.per_page, apiUrl);
            break;
        case ACTION_TYPES.PE_GET_BRAND_REQUEST:
            yield getBrand(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_UPDATE_BRAND_REQUEST:
            yield updateBrand(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_BRAND_REQUEST:
            yield deleteBrand(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_BRAND_REQUEST:
            yield postBrand(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_FETCH_BRANDS_OPTIONS_REQUEST:
            yield getBrandsOptions(apiUrl);
            break;
        default:
            break;
    }
}

export default peBrandsWorker;
