import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedTag: state.PETags.selectedTag,
        tagGroupOptions: state.PETags.tagGroupOptions,
    };
}

const PEEditTag = connect(mapStateToProps)(function (props) {
    const { selectedTag, tagGroupOptions } = props;
    const navigate = useNavigate();
    const { PE_UPDATE_TAG_REQUEST, PE_TAG_REQUEST, PE_DELETE_TAG_REQUEST } =
        useActions();
    const { id } = useParams();
    const [updatedTag, setUpdatedTag] = useState({
        name: selectedTag?.name,
        tag_group_id: selectedTag?.tag_group_id,
    });

    function onSubmit(event) {
        event.preventDefault();
        PE_UPDATE_TAG_REQUEST({
            id: selectedTag.id,
            name: updatedTag.name,
            tag_group_id: updatedTag.tag_group_id,
        });
        navigate('/program-engine/tags/tag');
    }

    function handleFieldChange(event) {
        const newState = {
            ...updatedTag,
            [event.target.name]: event.target.value,
        };
        setUpdatedTag(newState);
    }

    function onDelete() {
        // eslint-disable-next-line
        const confirmed = confirm(
            `Are you sure you want to delete ${selectedTag?.name}?`
        );
        if (confirmed) {
            PE_DELETE_TAG_REQUEST(selectedTag?.id);
            navigate('/program-engine/tags');
        }
    }

    useEffect(() => {
        if (id) {
            try {
                PE_TAG_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (!id && !selectedTag) {
            navigate('/program-engine/tags');
        } else if (selectedTag) {
            setUpdatedTag({
                name: selectedTag.name,
                tag_group_id: selectedTag.tag_group_id,
            });
        }
        // eslint-disable-next-line
    }, [id, selectedTag]);

    return (
        <section>
            <div className="flex mb-4 items-center h-16">
                <div className="flex flex-1 items-center">
                    <div
                        className="font-bold cursor-pointer  text-lg"
                        onClick={() => {
                            navigate('/program-engine/tags');
                        }}
                    >
                        Tags
                    </div>
                    <div className="ml-8 font-medium text-blue-600">
                        {selectedTag?.name}
                    </div>
                </div>
                <button className="button delete-button" onClick={onDelete}>
                    Delete "{selectedTag?.name}"
                </button>
            </div>
            <form onSubmit={onSubmit}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    name="name"
                                    value={updatedTag.name || ''}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Tag Group</td>
                            <td>
                                <select
                                    name="tag_group_id"
                                    value={updatedTag.tag_group_id || ''}
                                    onChange={handleFieldChange}
                                >
                                    {tagGroupOptions.map((tagg) => (
                                        <option
                                            key={tagg.value}
                                            value={tagg.value}
                                        >
                                            {tagg.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {(updatedTag.name !== selectedTag?.name ||
                    updatedTag.tag_group_id !== selectedTag?.tag_group_id) && (
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Save Changes
                                </button>
                            </td>
                        </tr>
                )}
            </form>
        </section>
    );
});

export default PEEditTag;
