import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { VISIBILITY_OPTIONS,FEATURED_OPTIONS,TRENDING_OPTIONS,OVERLAY_TEXT_OPTIONS } from 'constants/COMMUNITY_STATUS';
import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';
import { useState } from 'react';
import { EditorView } from "@codemirror/view";

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
        config: state.admins.config,
        user: state.user.user,
    };
}

const ArticleEdit = connect(mapStateToProps)(function ({
    article,
    brandsOptions,
    onConfirm = function () {},
    config,
    user,
}) {
    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const { CS_UPDATE_ARTICLE_REQUEST } = useActions();
    const initialState = {
        title: article.title,
        body: article.body,
        is_featured: "" + ~~article.is_featured + "",
        is_trending: "" + ~~article.is_trending + "",
        visibility: article.visibility,
        brand_ident: article.brand_ident,
        region_ident: article.region_ident,
        overlay_text_color: article.overlay_text_color || OVERLAY_TEXT_OPTIONS[0].value,
        member_id: article.member_id,
    };
    const [updatedArticle, handleFieldChange] = useInputState(initialState);
    const [description, setDescription] = useState(article.body)
    function onSave(event) {
        event.preventDefault();
        try {
            CS_UPDATE_ARTICLE_REQUEST({
                id: article.id,
                ...updatedArticle,
                body: description,
            });
            onConfirm();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section>
            <form onSubmit={onSave}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Title</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={updatedArticle.title}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Description
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={updatedArticle.body}
                                    reExtensions={[EditorView.lineWrapping]}
                                    onChange={(value) => setDescription(value)}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Featured</td>
                            <td>
                                <select
                                    name="is_featured"
                                    value={updatedArticle.is_featured}
                                    onChange={handleFieldChange}
                                >
                                    {FEATURED_OPTIONS.map((option) => (
                                        <option
                                            key={`featured-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Trending</td>
                            <td>
                                <select
                                    name="is_trending"
                                    value={updatedArticle.is_trending}
                                    onChange={handleFieldChange}
                                >
                                    {TRENDING_OPTIONS.map((option) => (
                                        <option
                                            key={`trending-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={updatedArticle.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Overlay Text Color</td>
                            <td>
                                <select
                                    name="overlay_text_color"
                                    value={updatedArticle.overlay_text_color}
                                    onChange={handleFieldChange}
                                >
                                    {OVERLAY_TEXT_OPTIONS.map((option) => (
                                        <option
                                            key={`overlay-color-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={updatedArticle.brand_ident}
                                    onChange={handleFieldChange}
                                >
                                    {brandsOptions.map((ser) => (
                                        <option
                                            key={`series-${ser.value}`}
                                            value={ser.value}
                                        >
                                            {ser.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <select
                                    name="region_ident"
                                    value={updatedArticle.region_ident}
                                    onChange={handleFieldChange}
                                >
                                    {regionsToShow.map((reg) => (
                                        <option
                                            key={`region-${reg}`}
                                            value={reg}
                                        >
                                            {reg}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Member ID</td>
                            <td>
                                <input
                                    name="member_id"
                                    value={updatedArticle.member_id}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        {updatedArticle !== initialState && (
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default ArticleEdit;
