import ActivityRecord from 'components/ActivityRecord';
import DateField from 'components/DateField';
import SelectableField from 'components/SelectableField';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        activity: state.conversions.activity,
        activity_page: state.conversions.activity_page,
        activity_per_page: state.conversions.activity_per_page,
        activity_total_pages: state.conversions.activity_total_pages,
        isLoadingActivity: state.conversions.isLoadingActivity,
    };
}

const ConversionsDetail = connect(mapStateToProps)(function ({
    conversion,
    onUpdateConversion = function () {},
    activity = [],
    activity_page,
    activity_per_page,
    activity_total_pages,
    programsSet = {},
    brandsSet = {},
    isLoadingActivity = false,
}) {
    const [status, setStatus] = useState(conversion.status);
    const { FETCH_CONVERSION_ACTIVITY_REQUEST } = useActions();

    const updateConversion = () => {
        onUpdateConversion(conversion, status);
    };

    const onActivityNext = () => {
        FETCH_CONVERSION_ACTIVITY_REQUEST({
            uuid: conversion.member?.uuid,
            perPage: activity_per_page,
            page: activity_per_page + 1,
        });
    };

    const onActivityPrev = () => {
        FETCH_CONVERSION_ACTIVITY_REQUEST({
            uuid: conversion.member?.uuid,
            perPage: activity_per_page,
            page: activity_page - 1,
        });
    };

    const onActivityPageSelected = (page) => {
        FETCH_CONVERSION_ACTIVITY_REQUEST({
            uuid: conversion.member?.uuid,
            perPage: activity_per_page,
            page,
        });
    };

    const onPerPageChange = (perPage) => {
        FETCH_CONVERSION_ACTIVITY_REQUEST({
            uuid: conversion.member?.uuid,
            perPage,
        });
    };

    useEffect(() => {
        if (conversion) {
            FETCH_CONVERSION_ACTIVITY_REQUEST({
                uuid: conversion.member?.uuid,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversion]);

    return !conversion ? null : (
        <div>
            <table className="overview-table">
                <tbody>
                    <tr>
                        <td className="uppercase label">Status</td>
                        <td>
                            <select
                                value={status}
                                onChange={(event) => {
                                    setStatus(event.target.value);
                                }}
                            >
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="denied">Denied</option>
                            </select>
                            {status !== conversion.status && (
                                <button
                                    className="mt-4 button save-changes-button"
                                    onClick={updateConversion}
                                >
                                    Save
                                </button>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Timestamp</td>
                        <td>
                            <DateField timeMillis={conversion.created_int} />
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">New User Id</td>
                        <td>
                            <SelectableField text={conversion.member?.id} />
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">New User Email</td>
                        <td>
                            <SelectableField text={conversion.member?.email} url={`/referrals/members/${conversion.member?.id}`}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">
                            Discount Applied
                        </td>
                        <td>{conversion.discount}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">
                            Referring User Id
                        </td>
                        <td>{conversion.referring_member?.id}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">
                            Referring User Email
                        </td>
                        <td>
                            <SelectableField
                                text={conversion.referring_member?.email}
                                url={`/referrals/members/${conversion.referring_member?.id}`}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Credit Applied</td>
                        <td>{conversion.credit}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Share Code</td>
                        <td>{conversion.share_code}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Program</td>
                        <td>
                            <SelectableField
                                url={`/referrals/programs/${conversion.program_id}`}
                                text={programsSet[conversion.program_id]}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Brand</td>
                        <td>
                            {brandsSet[conversion.brand_id]}
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Url</td>
                        <td>
                            <a href={conversion.track_event?.url}>
                                {conversion.track_event?.url}
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Referrer</td>
                        <td>{conversion.track_event?.referrer}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">IP Address</td>
                        <td>{conversion.track_event?.ip_address}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">User Agent</td>
                        <td>{conversion.track_event?.user_agent}</td>
                    </tr>
                </tbody>
            </table>
            <ActivityRecord
                page={activity_page}
                perPage={activity_per_page}
                totalPages={activity_total_pages}
                activities={activity}
                className="mt-8"
                onPerPageChange={onPerPageChange}
                onNext={onActivityNext}
                onPrev={onActivityPrev}
                onPageSelected={onActivityPageSelected}
                isLoading={isLoadingActivity}
            />
        </div>
    );
});

export default ConversionsDetail;
