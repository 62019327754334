import brandsWorker from '../workers/brandsWorker';

const { takeEvery } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* BRANDS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.FETCH_BRAND_REQUEST,
            ACTION_TYPES.FETCH_BRANDS_REQUEST,
            ACTION_TYPES.POST_BRAND_REQUEST,
            ACTION_TYPES.UPDATE_BRAND_REQUEST,
            ACTION_TYPES.FETCH_ALL_BRANDS_REQUEST,
        ],
        brandsWorker,
        apiUrl,
        ...params
    );
}

export default BRANDS_REQUEST;
