function TableResults({ results = 0, onAdd, addTitle }) {
    return (
        <div className="mt-8 mb-4 flex flex-row place-content-between">
            <div><span className="font-bold">{results}</span> <span style={{color: "#7A7A7A"}}>Results</span></div>
            {onAdd && addTitle && (
                <button
                    className="button add-button p-2 ml-4 text-white"
                    onClick={onAdd}
                >
                    {addTitle}
                </button>
            )}
        </div>
    );
}

export default TableResults;
