import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';


function mapStateToProps(state) {
    return {
        createZoomAssetBatchResult: state.vhs.createZoomAssetBatchResult,
        createZoomAssetResult: state.vhs.createZoomAssetResult,
        createEpisodeFromClass: state.vhs.createEpisodeFromClass,
        locationOptions: state.vhsLocations.locationOptions,
    };
}

const EpisodeProcessHelper = connect(mapStateToProps)(function(props) {
    useEffect(() => {
    });
    return (
        <div className="content content-pending rounded-xl my-4 p-6">
            <CreateEpisodeFromClass/>
            <hr className="my-3"/>
            <CreateAssetFromMeeting/>
            <hr className="my-3"/>
            <CreateZoomAssetsBatch/>
        </div>
    );
});


const CreateEpisodeFromClass = connect(mapStateToProps)(function(props){
    const { createEpisodeFromClass } = props;
    const {
        VHS_CREATE_EPISODE_FROM_CLASS_REQUEST,
    } = useActions();

    const [classId, setClassId] = useState('');
    const [waitingCompletion, setWaitingCompletion] = useState(false);
    const [outputMessage, setOutputMessage] = useState(null);

    let submitButtonClasses = "p-2 mr-4 text-white self-end";
    if(waitingCompletion) {
        submitButtonClasses += " disabled-button";
    } else {
        submitButtonClasses += " bg-green-add";
    }


    useEffect(() => {
        if(waitingCompletion && createEpisodeFromClass) {
            setOutputMessage(createEpisodeFromClass);
            setWaitingCompletion(false);
        }
    }, [waitingCompletion, createEpisodeFromClass]);


    function onSubmit() {
        if(classId) {
            setOutputMessage(null);
            setWaitingCompletion(true);
            VHS_CREATE_EPISODE_FROM_CLASS_REQUEST({ class_id: classId });
        }
    }

    return (<>
        <h3 className="text-lg">Re-Trigger MarianaTek Webhook to make Episode</h3>
        <p className="mb-3 text-sm">
            This will create an Episode based off a MarianaTek Class as if the webhook was triggerred.
        </p>
        <div className="flex">
            <div className="w-1/3">
                <label>MarianaTek Class ID:</label>
                <input
                    type={'text'}
                    name="class_id"
                    onChange={(ev)=>setClassId(ev.target.value)}
                    value={classId}
                />
            </div>
            <button className={submitButtonClasses} type="button" onClick={onSubmit} disabled={waitingCompletion}>Run Process</button>
        </div>
        {outputMessage && <>
            <div>
                <p>dssadadsads {outputMessage}</p>
            </div>
        </>}
    </>); 
});

const CreateAssetFromMeeting = connect(mapStateToProps)(function(props){
    const { createZoomAssetResult } = props;
    const {
        VHS_CREATE_ASSET_FROM_ZOOM_REQUEST,
    } = useActions();

    const [meetingId, setMeetingId] = useState('');
    const [waitingCompletion, setWaitingCompletion] = useState(false);
    const [outputMessage, setOutputMessage] = useState(null);

    let submitButtonClasses = "p-2 mr-4 text-white self-end";
    if(waitingCompletion) {
        submitButtonClasses += " disabled-button";
    } else {
        submitButtonClasses += " bg-green-add";
    }


    useEffect(() => {
        if(waitingCompletion && createZoomAssetResult) {
            setOutputMessage(createZoomAssetResult);
            setWaitingCompletion(false);
        }
    }, [waitingCompletion, createZoomAssetResult]);


    function onSubmit() {
        if(meetingId) {
            setOutputMessage(null);
            setWaitingCompletion(true);
            VHS_CREATE_ASSET_FROM_ZOOM_REQUEST({ meeting_id: meetingId });
        }
    }

    return (<>
        <h3 className="text-lg">Re-Trigger Zoom Webhook for Singular Meeting</h3>
        <p className="mb-3 text-sm">
            This will go and download the correct videos from Zoom and upload them to VHS, the same as if the recording had finished and triggerred the process naturally.
        </p>
        <div className="flex">
            <div className="w-1/3">
                <label>Zoom Meeting ID:</label>
                <input
                    type={'text'}
                    name="meeting_id"
                    onChange={(ev)=>setMeetingId(ev.target.value)}
                    value={meetingId}
                />
            </div>
            <button className={submitButtonClasses} type="button" onClick={onSubmit} disabled={waitingCompletion}>Run Process</button>
        </div>
        {outputMessage && <>
            <div>
                <p>{outputMessage}</p>
            </div>
        </>}
    </>); 
});

const CreateZoomAssetsBatch = connect(mapStateToProps)(function(props){
    const { locationOptions, createZoomAssetBatchResult } = props;
    const {
        VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_REQUEST,
    } = useActions();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [locationId, setLocationId] = useState('');
    const [waitingCompletion, setWaitingCompletion] = useState(false);
    const [outputMessage, setOutputMessage] = useState(null);

    let submitButtonClasses = "p-2 mr-4 text-white self-end";
    if(waitingCompletion) {
        submitButtonClasses += " disabled-button";
    } else {
        submitButtonClasses += " bg-green-add";
    }


    useEffect(() => {
        console.log("TRIGGER")
        console.log("waitingCompletion", waitingCompletion);
        console.log("createZoomAssetBatchResult", createZoomAssetBatchResult);
        if(waitingCompletion && createZoomAssetBatchResult) {
            setOutputMessage(createZoomAssetBatchResult);
            setWaitingCompletion(false);
        }
    }, [waitingCompletion, createZoomAssetBatchResult]);


    function onSubmit() {
        if(startDate && endDate && locationId) {
            setOutputMessage(null);
            setWaitingCompletion(true);
            VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_REQUEST({ location_id: locationId, start_date: startDate.toString(), end_date: endDate.toString() });
        }
    }

    return (<>
        <h3 className="text-lg">Re-Trigger Zoom Webhook for Meetings within the date range:</h3>
        <p className="mb-3 text-sm">
            This will go and download the correct videos from Zoom and upload them to VHS, the same as if the recording had finished and triggerred the process naturally. <br/>
            Note: The dates selected work such that the range is inclusive, from 00:00:00 on the Start Date to 23:59:59 on the End Date. <br/>
            These times are based on USA Central time for convenience.
        </p>
        <div className="flex">
            <div className="w-1/4">
                <label>Location ID</label>
                <select
                    name="location_id"
                    value={locationId}
                    onChange={(ev)=>setLocationId(ev.target.value)}
                >
                    {locationOptions.map((location) => (
                        <option
                            key={`location-${location.value}`}
                            value={location.value}
                        >
                            {location.label}
                        </option>
                    ))}
                </select>
            </div>
            <div className="w-1/4">
                <label>Start Date</label>
                <input
                    type={'date'}
                    name="start_date"
                    onChange={(ev)=>setStartDate(ev.target.value)}
                    value={startDate}
                />
            </div>
            <div className="w-1/4">
                <label>End Date</label>
                <input
                    type={'date'}
                    name="end_date"
                    onChange={(ev)=>setEndDate(ev.target.value)}
                    value={endDate}
                />
            </div>
            <button className={submitButtonClasses} type="button" onClick={onSubmit} disabled={waitingCompletion}>Run Process</button>
        </div>
        {outputMessage && <>
            <div>
                <p>{outputMessage}</p>
            </div>
        </>}
    </>); 
});

export default EpisodeProcessHelper;