const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: peMembersWorker } = require('../workers/peMembersWorker');

function* PE_MEMBERS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_MEMBERS_REQUEST,
            ACTION_TYPES.PE_GET_MEMBER_REQUEST,
        ],
        peMembersWorker,

        apiUrl,
        ...params
    );
}

export default PE_MEMBERS_REQUEST;
