import { useState } from 'react';
import MarkdownEditor from '@uiw/react-markdown-editor';
import MarkdownHint from 'components/StaticElements/MarkdownHint';
import PE_TRANSLATION_FIELDS from 'constants/PE_TRANSLATION_FIELDS';

function TranslationsEdit({ translation, onSave }) {
    const [updatedTranslation, setUpdatedTranslation] = useState({
        ...translation,
    });

    function handleFieldChange(event) {
        const newState = {
            ...updatedTranslation,
            [event.target.name]: event.target.value,
        };
        setUpdatedTranslation(newState);
    }

    function handleMarkdownChange(name,value){
        const newState = {
            ...updatedTranslation,
            [name]: value,
        };
        setUpdatedTranslation(newState);
    }

    function onUpdate(event) {
        event.preventDefault();
        onSave(updatedTranslation);
    }

    return (
        <form onSubmit={onUpdate}>
            <table className='edit-table'>
                <tbody>
                    <tr>
                        <td className="font-bold label">Field</td>
                        <td>
                            <select
                                name="field_name"
                                value={updatedTranslation?.field_name}
                                onChange={handleFieldChange}
                                className='capitalize'
                            >
                                {PE_TRANSLATION_FIELDS.map((option) => (
                                    <option
                                        key={`field-${option}`}
                                        value={option}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="font-bold label">Text</td>
                        <td>
                            <MarkdownEditor
                                    name="translated_text"
                                    value={updatedTranslation?.translated_text}
                                    onChange={(value) => handleMarkdownChange('translated_text',value)}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                            <MarkdownHint />
                        </td>
                    </tr>
                    <tr>
                        <td className="font-bold label">Language Code</td>
                        <td>
                            <input
                                name="lang_code"
                                value={updatedTranslation?.lang_code}
                                onChange={handleFieldChange}
                                required
                            />
                            <div className="mt-4 text-xs">
                                Example: <b>en</b> or <b>es</b>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="font-bold label">
                            Language Territory
                        </td>
                        <td>
                            <input
                                name="lang_terr"
                                value={updatedTranslation?.lang_terr || ''}
                                onChange={handleFieldChange}
                            />
                            <div className="mt-4 text-xs">
                                Optional. Example: <b>US</b> or <b>MX</b>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="font-bold label">Lock Translation</td>
                        <td>
                            <select
                                name="is_locked"
                                value={updatedTranslation?.is_locked}
                                onChange={handleFieldChange}
                                className='capitalize'
                            >
                                <option value={false}>Unlocked</option>
                                <option value={true}>Locked</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button type="submit" className="mt-4 button save-changes-button">
                                Save
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    );
}

export default TranslationsEdit;
