import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect, useState } from 'react';
import InstructorAnalytics from './InstructorAnalytics';
import InstructorEdit from './InstructorEdit';
import InstructorEpisodes from './InstructorEpisodes';
import InstructorOverview from './InstructorOverview';
import InstructorHistory from './InstructorHistory';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedInstructor: state.vhsInstructors.selectedInstructor,
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

const VHSInstructorDetails = connect(mapStateToProps)(function (props) {
    const { selectedInstructor, brandsOptions } = props;

    const {
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST,
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST,
        VHS_UPDATE_INSTRUCTOR_REQUEST,
    } = useActions();

    const [selectedTab, setSelectedTab] = useState(0);
    const [brandsSet, loadBrandsSet] = useSetFromOptions();

    function onSaveChanges(updatedInstructor) {
        VHS_UPDATE_INSTRUCTOR_REQUEST(updatedInstructor);
        setSelectedTab(0);
    }

    useEffect(() => {
        loadBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST({
            instructorId: selectedInstructor.id,
        });
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST({
            instructorId: selectedInstructor.id,
        });
        // eslint-disable-next-line
    }, []);

    return (
        <section>
            <Tabs
                sectionNames={['Overview', 'Settings', 'Episodes', 'Analytics', 'History']}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
                sectionContents={[
                    <InstructorOverview
                        instructor={selectedInstructor}
                        brandsSet={brandsSet}
                    />,
                    <InstructorEdit
                        instructor={selectedInstructor}
                        onSave={onSaveChanges}
                    />,
                    <InstructorEpisodes />,
                    <InstructorAnalytics />,
                    <InstructorHistory />,
                ]}
            />
        </section>
    );
});

export default VHSInstructorDetails;
