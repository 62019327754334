import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect, useState } from 'react';
import AdminEdit from '../AdminEdit';

const { default: DetailOverview } = require('components/DetailOverview');
const { default: Tabs } = require('components/Tabs');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedUser: state.admins.selectedUser,
        config: state.admins.config,
    };
}

const AdminDetails = connect(mapStateToProps)(function (props) {
    const { selectedUser, config } = props;
    const [brandsSet, setBrandsSet] = useSetFromOptions();
    const [regionsSet, setRegionsSet] = useSetFromOptions();
    const [servicesSet, setServicesSet] = useSetFromOptions();
    const [selectedTab, setSelectedTab] = useState(0);

    function goOverview() {
        setSelectedTab(0);
    }

    useEffect(() => {
        if (config?.brands) {
            setBrandsSet(config.brands);
        }
        if (config?.regions) {
            setRegionsSet(config.regions);
        }
        if (config?.services) {
            setServicesSet(config.services);
        }
        // eslint-disable-next-line
    }, [config]);

    function overview() {
        let brandsField = selectedUser.brand_permissions.includes('all') ? 'All' : selectedUser?.brand_permissions
        ?.split('-')
        .map((service) => brandsSet[service])
        .join(', ');

        let regionsField = selectedUser.region_permissions.includes('all') ? 'All' : selectedUser?.region_permissions
        ?.split('|')
        .map((service) => regionsSet[service])
        .join(', ');

        let servicesField = selectedUser.service_permissions.includes('all') ? 'All' : selectedUser?.service_permissions
        ?.split('-')
        .map((service) => servicesSet[service])
        .join(', ');
        return (
            <DetailOverview
                fields={[
                    {
                        name: 'Name',
                        value: `${selectedUser.first_name} ${
                            selectedUser.middle_name
                                ? selectedUser.middle_name + ' '
                                : ''
                        }${selectedUser.last_name}`,
                    },
                    {
                        name: 'Email',
                        value: selectedUser.email,
                    },
                    {
                        name: 'Brands',
                        value: brandsField,
                    },
                    {
                        name: 'Services',
                        value: servicesField,
                    },
                    {
                        name: 'Regions',
                        value: regionsField,
                    },
                    {
                        name: 'Created',
                        value: selectedUser.created_int,
                        type: 'date',
                    },
                    {
                        name: 'Updated',
                        value: selectedUser.updated_int,
                        type: 'date',
                    },
                ]}
            />
        );
    }

    return (
        <Tabs
            sectionNames={['Overview', 'Settings']}
            sectionContents={[overview(), <AdminEdit onSaveCallback={goOverview} />]}
            selectedTab={selectedTab}
            onTabChange={(tab) => setSelectedTab(tab)}
        />
    );
});

export default AdminDetails;
