import { XIcon } from '@heroicons/react/outline';
import useActions from 'hooks/useActions';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        content: state.ui.modalContent,
        visible: state.ui.modalVisible,
        modalStyle: state.ui.modalStyle,
    };
}

const CustomModal = connect(mapStateToProps)(function (props) {
    const { content, visible, modalStyle = {} } = props;
    const { TOGGLE_MODAL } = useActions();

    function preventClosing(event) {
        event.stopPropagation();
    }

    return (
        <section
            style={{
                position: 'fixed',
                zIndex: 9999,
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                display: visible ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgba(0,0,0,0.4)',
            }}
            onClick={TOGGLE_MODAL}
        >
            <div
                style={{
                    height: '100%',
                    maxHeight: '700px',
                    width: '100%',
                    maxWidth: '1000px',
                    background: 'white',
                    boxShadow: '0.02em 0.02em 0.3em rgb(0,0,0,0.5)',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    borderRadius: '15px',
                    paddingBottom: '40px',
                    ...modalStyle,
                    ...modalStyle.alertStyle ? {width: '500px', height: '200px'} : {}
                }}
                onClick={preventClosing}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: 20,
                    }}
                >
                    <XIcon
                        onClick={TOGGLE_MODAL}
                        height={25}
                        width={25}
                        cursor="pointer"
                    />
                </div>
                <div className={`flex justify-center${modalStyle.alertStyle ? " items-center h-full" : ""}`}>
                    {content}
                </div>
            </div>
        </section>
    );
});

export default CustomModal;
