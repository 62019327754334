/* eslint-disable react-hooks/exhaustive-deps */
import { VISIBILITY_OPTIONS,REEL_OPTIONS } from 'constants/COMMUNITY_STATUS';
import useActions from 'hooks/useActions';
import { connect } from 'react-redux';
import MarkdownEditor from '@uiw/react-markdown-editor';
import MarkdownHint from 'components/StaticElements/MarkdownHint';
import { useParams } from 'react-router-dom';
import SelectOrInput from 'components/SelectOrInput';

const { useState, useEffect } = require('react');

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
        expertCoaches: state.CSMembers.expertCoaches,
        question: state.CSQuestions.selectedQuestion,
    };
}

const AnswerNew = connect(mapStateToProps)(function (props) {
    const { brandsOptions, expertCoaches, question } = props;
    const { CS_POST_ANSWER_REQUEST, CS_FETCH_EXPERT_COACHES_REQUEST, CS_FETCH_QUESTION_REQUEST } = useActions();
    const { id } = useParams();

    const [answer, setAnswer] = useState({
        body: '',
        visibility: VISIBILITY_OPTIONS[0].value,
        is_reel: REEL_OPTIONS[0].value,
        brand_ident: '',
        member_id: '',
    });
    const [body, setBody] = useState('');
    const [expertCoachesOptions, setExpertCoachesOptions] = useState([]);

    const handleFieldChange = (event) => {
        const newState = {
            ...answer,
            [event.target.name]: event.target.value,
        };
        setAnswer(newState);
    };

    function onCreate(event) {
        event.preventDefault();
        try {
            let payload = {
                ...answer,
                body,
                post_type: 'answer',
                parent_id: id,
            };
            if(!!question?.region_ident){
                payload.region_ident = question.region_ident;
            }
            CS_POST_ANSWER_REQUEST(
                payload
            );
        } catch (err) {
            alert(
                'There was an error when trying to save the answer, check all the fields are filled properly.'
            );
        }
    }

    useEffect(() => {
        if (id && !question) {
            try {
                CS_FETCH_QUESTION_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        }
    }, [id]);

    useEffect(() => {
        CS_FETCH_EXPERT_COACHES_REQUEST({ region_ident: question?.region_ident });
    }, [question]);

    useEffect(() => {
        if (brandsOptions && brandsOptions.length > 0) {
            setAnswer((answer) => ({
                ...answer,
                brand_ident: brandsOptions[0].value,
            }));
        }
    }, [brandsOptions]);

    useEffect(() => {
        if (expertCoaches && expertCoaches.length > 0) {
            const options = expertCoaches.map(opt => (
                {
                    id : opt.id,
                    value: opt.id,
                    label: opt.name
                }
            ))
            setExpertCoachesOptions(options);
        }
    }, [expertCoaches])

    return (
        <section>
            <form onSubmit={onCreate}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">
                                Body
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="body"
                                    value={body}
                                    onChange={setBody}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Reel</td>
                            <td>
                                <select
                                    name="is_reel"
                                    value={answer.is_reel}
                                    onChange={handleFieldChange}
                                >
                                    {REEL_OPTIONS.map((option) => (
                                        <option
                                            key={`reel-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={answer.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={answer.brand_ident}
                                    onChange={handleFieldChange}
                                >
                                    {brandsOptions.map((ser) => (
                                        <option
                                            key={`answer-${ser.value}`}
                                            value={ser.value}
                                        >
                                            {ser.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <span className='text-gray'>{question?.region_ident}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Member id
                            </td>
                            <td>
                                <SelectOrInput
                                    name="member_id"
                                    onChange={handleFieldChange}
                                    options={expertCoachesOptions}
                                    placeholder={"Select an Expert Coach"}
                                    CTAText={"Enter member ID manually"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Answer
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default AnswerNew;
