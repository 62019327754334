/* eslint-disable react-hooks/exhaustive-deps */
import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import { connect } from 'react-redux';
import Select from 'react-select';
import LoadingIcon from '../../../../components/Icons/Loading';

const { useState, useEffect } = require('react');

function mapStateToProps(state) {
    const loggedUser = state.user.user

    let seriesOptions = state.vhsSeries.seriesOptions
    const isAdmin = (loggedUser.permissions?.brands?.includes('all') || loggedUser.permissions?.brands?.includes('tbm'))
        && (loggedUser.permissions?.services?.includes('all') || loggedUser.permissions?.services?.includes('usr'))

    if (!isAdmin) {
        seriesOptions = seriesOptions.filter(ser => ser.value !== 2)
    }

    return {
        instructorsOptions: state.vhsInstructors.instructorsOptions,
        seriesOptions,
        brandsOptions: state.vhsBrands.brandsOptions,
        locationOptions: state.vhsLocations.locationOptions,
    };
}

const EpisodesNew = connect(mapStateToProps)(function (props) {
    const { instructorsOptions, seriesOptions, brandsOptions, locationOptions } = props;
    const { VHS_POST_EPISODE_REQUEST } = useActions();

    const [isLoading, setIsLoading] = useState(false)
    const [episode, setEpisode] = useState({
        name: '',
        description: '',
        start_int: '',
        stop_int: '',
        visibility: VISIBILITY_OPTIONS[0].value,
        instructor_id: [],
        instructors: [],
        series_id: '',
        brand_ident: '',
        zoom_url: '',
        location_id: ''
    });

    const handleFieldChange = (event) => {
        const newState = {
            ...episode,
            [event.target.name]: event.target.value,
        };
        setEpisode(newState);
    };

    function onCreate(event) {
        event.preventDefault();

        try {
            setIsLoading(true)

            VHS_POST_EPISODE_REQUEST({
                ...episode,
                start_int: new Date(episode.start_int).getTime(),
                stop_int: new Date(episode.stop_int).getTime(),
            });
        } catch (err) {
            setIsLoading(false)
            alert(
                'There was an error when trying to save the episode, check all the fields are filled properly.'
            );
        }
    }

    const saveInstructors = (selected) => {
        const newState = {
            ...episode,
            instructors: [...selected],
            instructor_id: selected.map(val => val.value)
        };
        setEpisode(newState);
    }

    useEffect(() => {
        if (seriesOptions && seriesOptions.length > 0) {
            setEpisode((ep) => ({
                ...ep,
                series_id: seriesOptions[0].value,
            }));
        }
    }, [seriesOptions]);

    useEffect(() => {
        if (brandsOptions && brandsOptions.length > 0) {
            setEpisode((ep) => ({
                ...ep,
                brand_ident: brandsOptions[0].value,
            }));
        }
    }, [brandsOptions]);



    return (
        <section>
            {isLoading &&
                <div className="h-full w-full absolute top-0 left-0 bg-gray-200 z-10 opacity-75 flex justify-center items-center">
                    <LoadingIcon />
                </div>
            }
            <form onSubmit={onCreate}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className='text-bold label'>Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={episode.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Description</td>
                            <td>
                                <textarea
                                    className='w-full'
                                    name="description"
                                    value={episode.description}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Start</td>
                            <td>
                                <input
                                    name="start_int"
                                    type="datetime-local"
                                    value={episode.start_int}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Stop</td>
                            <td>
                                <input
                                    name="stop_int"
                                    type="datetime-local"
                                    value={episode.stop_int}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={episode.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Instructor</td>
                            <td>
                                <Select
                                    isMulti
                                    options={instructorsOptions}
                                    value={episode.instructors}
                                    onChange={(selected) => saveInstructors(selected)}
                                    labelledBy="Select"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Location</td>
                            <td>
                                <select
                                    name="location_id"
                                    value={episode.location_id}
                                    onChange={handleFieldChange}
                                >
                                    {locationOptions.map((location) => (
                                        <option
                                            key={`location-${location.value}`}
                                            value={location.value}
                                        >
                                            {location.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Series</td>
                            <td>
                                <select
                                    name="series_id"
                                    value={episode.series_id}
                                    onChange={handleFieldChange}
                                >
                                    {seriesOptions.map(ser =>
                                    (
                                        <option
                                            key={`series-${ser.value}`}
                                            value={ser.value}
                                        >
                                            {ser.label}
                                        </option>
                                    )
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={episode.brand_ident}
                                    onChange={handleFieldChange}
                                >
                                    {brandsOptions.map((ser) => (
                                        <option
                                            key={`series-${ser.value}`}
                                            value={ser.value}
                                        >
                                            {ser.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Zoom URL</td>
                            <td>
                                <textarea
                                    className='w-full'
                                    name="zoom_url"
                                    value={episode.zoom_url}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Episode
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default EpisodesNew;
