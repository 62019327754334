import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DateField from 'components/DateField';
import CustomSelector from 'components/CustomSelector';
import { REASON_OPTIONS} from 'constants/COMMUNITY_STATUS';

function mapStateToProps(state) {
    return {
     };
}

function FlagsTableRaw({
    flags = [],
    onDetailSelection = null,
    isLoading,
    handleChangeReason,
}) {
    const navigate = useNavigate();
    const goToDetail = (item, itemType) => {
        switch (itemType) {
            case 'question':
                navigate(`/community/questions/${item.id}/flags`);
                break;
            case 'answer':
                navigate(`/community/questions/${item.parent_id}/answers/${item.id}/flags`);
                break;
            case 'article':
                navigate(`/community/articles/${item.id}/flags`);
                break;
            case 'comment':
                navigate(`/community/articles/${item.parent_id}/comments/${item.id}/flags`);
                break;
            default:
                break;
        }
    }

    function truncate( str, n, useWordBoundary ){
        if (!str) { return ''; }
        if (str.length <= n) { return str; }
        const subString = str.slice(0, n-1);
        return (useWordBoundary 
          ? subString.slice(0, subString.lastIndexOf(" ")) 
          : subString) + "&hellip;";
      };
    
    
    return (
        <section>
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th>Post Type</th>
                        <th>Content</th>
                        <th>Content Creator</th>
                        <th>Flagger</th>
                        <th>Flagged At</th>
                        <th>Status</th>
                        <th className='text-center'>Actions</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {flags.map((flag) => (
                        <tr key={`flag-${flag.id}`}>
                            <td className='capitalize'>
                                {flag.resource?.post_type}
                            </td>
                            <td>
                            { flag.resource?.post_type === 'question' || flag.resource?.post_type === 'article' ? <>
                                <SelectableField
                                            action={() => goToDetail(flag.resource, flag.resource?.post_type)}
                                            text={<strong>{truncate(flag.resource?.title,100)}</strong>}
                                        />
                            </> : null}
                            { flag.resource?.post_type === 'answer' || flag.resource?.post_type === 'comment' ? <>
                                <SelectableField
                                            action={() => goToDetail(flag.resource, flag.resource?.post_type)}
                                            text={<strong>{truncate(flag.resource?.body,100)}</strong>}
                                        />
                                </> : null}
                            </td>
                            <td>
                            <strong>{flag.resource?.member.name}</strong><br></br>
                                {flag.resource?.member?.subline}
                            </td>
                            <td></td>
                            <td><DateField timeMillis={flag.created_int} /></td>
                            <td>{flag.status === 'pending' && <strong style={{color:'#888'}}>Pending</strong>}
                                {flag.status === 'allowed_content' && <strong style={{color:'#3e8f1a'}}>Allowed Content</strong>}
                                {flag.status === 'denied_content' && <strong style={{color:'#f24726'}}>Denied Content</strong>}</td>
                            <td>
                                <div className='flex'>
                            {(flag.status === 'pending' || flag.status === 'denied_content') && 
                                    <button
                                        className="button p-2 mt-2 ml-2 text-white flex items-center justify-center"
                                        onClick={() => handleChangeReason({status:'allowed_content',id:flag.id})}
                                        >Approve Content
                                    </button>
                            }
                            {(flag.status === 'pending' || flag.status === 'allowed_content') && 
                                    <button
                                            className="button delete-button p-2 mt-2 ml-2 text-white flex items-center justify-center"
                                            onClick={() => handleChangeReason({status:'denied_content',id:flag.id})}
                                            >Deny Content
                                    </button>
                            }
                            </div>
                            </td>
                            <td>
                            { flag.status === 'denied_content' && 
                                (
                                    <CustomSelector 
                                        initialSelectedValue={flag.reason}
                                        options={REASON_OPTIONS}
                                        onSelect={(value)=>{ handleChangeReason({reason:value,id:flag.id}) }}
                                    />
                                )
                            }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
}

const FlagsTable = connect(mapStateToProps)(FlagsTableRaw);
export default FlagsTable;
