import ACTION_TYPES from 'constants/ACTION_TYPES';
import {SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';


const initialState = {
    commentsSelectedArticle: [],
    isLoadingComments: false,
    commentsPerPage: 10,
    commentsPage: 1,
    commentsTotal: 0,
    commentsTotalPages: 1,
    selectedComment: null,
    commentsOrder: SORTING_OPTIONS[0].value,
    flagsSelectedComment: [],
    isLoadingFlags: false,
    flagsPerPage: 10,
    flagsPage: 1,
    flagsTotal: 0,
    flagsTotalPages: 1,
    flagsOrder: SORTING_OPTIONS[0].value,
    flagsStatus: 'all',
    files: [],
    isLoadingFiles: false,
    translations: [],
    translations_page: 1,
    translations_per_page: 10,
    translations_total: 0,
    translations_total_pages: 1,
    translations_is_loading: false,
    selectedTranslation: null,
};

function commentsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST: {
            return {
                ...state,
                isLoadingComments: true,
                
            };
        }
        case ACTION_TYPES.CS_FETCH_COMMENTS_PER_ARTICLE_RESPONSE: {
            return {
                ...state,
                isLoadingComments: false,
                commentsSelectedArticle: action.data.list,
                commentsTotal: action.data.total,
                commentsTotalPages: action.data.total_pages,
                commentsPage: action.data.page,
                commentsPerPage: action.data.per_page,
            };
        }
        case ACTION_TYPES.CS_SELECT_COMMENT: {
            return {
                ...state,
                selectedComment: action.data,
            };
        }
        case ACTION_TYPES.CS_FETCH_COMMENT_RESPONSE: {
            return {
                ...state,
                selectedComment: action.data,
            };
        }
        case ACTION_TYPES.CS_SELECT_COMMENT_ORDER_FILTER: {
            return {
                ...state,
                commentsOrder: action.data,
            };
        }
        case ACTION_TYPES.CS_POST_COMMENT_RESPONSE: {
            return {
                ...state,
                selectedComment: action.data,
            };
        }
        case ACTION_TYPES.CS_UPDATE_COMMENT_RESPONSE: {
            return {
                ...state,
                selectedComment: action.data,
            }
        }
        case ACTION_TYPES.CS_SELECT_COMMENT_FLAGS_ORDER_FILTER: {
            return{
                ...state,
                flagsOrder:action.data
            }
        }
        case ACTION_TYPES.CS_SELECT_COMMENT_FLAGS_STATUS_FILTER: {
            return {
                ...state,
                flagsPage: 1,
                flagsStatus: action.data,
            }
        }
        case ACTION_TYPES.CS_FETCH_COMMENT_FLAGS_RESPONSE:{
            return {
                ...state,
                isLoadingFlags: false,
                flagsSelectedAnswer: action.data.list,
                flagsTotal: action.data.total,
                flagsTotalPages: action.data.total_pages,
                flagsPage: action.data.page,
                flagsPerPage: action.data.per_page,
            };
        }
        case ACTION_TYPES.CS_UPDATE_COMMENT_FLAGS_REASON_RESPONSE: {
            return {
                ...state,
                flagsSelectedAnswer: {...state.flagsSelectedAnswer, ...action.data.results},
            };
        }
        case ACTION_TYPES.CS_FETCH_COMMENT_FILES_REQUEST: {
            return {
                ...state,
                isLoadingFiles: true,
            };
        }
        case ACTION_TYPES.CS_FETCH_COMMENT_FILES_RESPONSE: {
            return {
                ...state,
                files: action.data,
                isLoadingFiles: false,
            };
        }
        case ACTION_TYPES.CS_FETCH_COMMENT_TRANSLATIONS_REQUEST: {
            return {
                ...state,
                translations_is_loading: true,
            };
        }
        case ACTION_TYPES.CS_FETCH_COMMENT_TRANSLATIONS_RESPONSE: {
            return {
                ...state,
                translations: action.data.list,
                translations_page: action.data.page,
                translations_per_page: action.data.per_page,
                translations_total: action.data.total,
                translations_total_pages: action.data.total_pages,
                translations_is_loading: false,
            };
        }
        case ACTION_TYPES.CS_POST_COMMENT_TRANSLATION_RESPONSE:
        case ACTION_TYPES.CS_UPDATE_COMMENT_TRANSLATION_RESPONSE:
        case ACTION_TYPES.CS_SELECT_COMMENT_TRANSLATION: {
            return {
                ...state,
                selectedTranslation: action.data,
            };
        }
        default:
            return state;
    }
}

export default commentsReducer