import useActions from 'hooks/useActions';
import { useState } from 'react';
import ActionsModalContent from 'routing/ProgramEngine/Assignments/ActionsModalContent';
import CategoriesModal from 'routing/ProgramEngine/Categories/CategoriesModal';
import TagsModalContent from 'routing/ProgramEngine/Tags/TagsModalContent';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        config: state.PEMatrices.config,
    };
}

const AddMatrixRule = connect(mapStateToProps)(function (props) {
    const { config, onConfirm = function () {} } = props;
    const [selectedType, setSelectedType] = useState('');
    const [filterType, setFilterType] = useState(null);
    const [selectedOperator, setSelectedOperator] = useState('');

    const { SET_MODAL_CONTENT, SET_MODAL_STYLE } = useActions();

    function onFilterTypeSelected(event) {
        setSelectedType(event.target.value);
        const ftype = config.filters.find(
            (filter) => filter.label === event.target.value
        );
        if (ftype) {
            setFilterType(ftype);
            setSelectedOperator(ftype.operators[0]);
        }
    }

    function onFilterSelected(value, text) {
        onConfirm([selectedType, selectedOperator, value, text]);
    }

    function onActionSelected(action) {
        onFilterSelected(action.value, action.label);
    }

    function onCategorySelected(category) {
        onFilterSelected(category.id, category.name);
    }

    function onTagSelected(tag) {
        onFilterSelected(tag.id, tag.name);
    }

    function openActionsModal() {
        SET_MODAL_CONTENT(<ActionsModalContent onSelect={onActionSelected} />);
        SET_MODAL_STYLE({});
    }

    function openCategoriesModal() {
        SET_MODAL_CONTENT(<CategoriesModal onSelect={onCategorySelected} />);
        SET_MODAL_STYLE({});
    }

    function openTagsModal() {
        SET_MODAL_CONTENT(<TagsModalContent onSelect={onTagSelected} />);
        SET_MODAL_STYLE({});
    }

    function FilterInput({ inputType }) {
        switch (inputType) {
            case 'category_selector':
                return <CategorySelector />;
            case 'action_selector':
                return <ActionSelector />;
            case 'tag_selector':
                return <TagSelector />;
            case 'text':
                return <TextInput />;
            default:
                return <></>;
        }
    }

    function TagSelector() {
        return (
            <button className="save-changes-button" onClick={openTagsModal}>
                Select Tag
            </button>
        );
    }

    function CategorySelector() {
        return (
            <button
                onClick={openCategoriesModal}
                className="save-changes-button"
            >
                Select Category
            </button>
        );
    }

    function ActionSelector() {
        return (
            <button onClick={openActionsModal} className="save-changes-button">
                Select Action
            </button>
        );
    }

    function TextInput() {
        const [textValue, setTextValue] = useState(0);
        return (
            <>
                <input
                    style={{ width: 'auto' }}
                    type="number"
                    min={0}
                    value={textValue}
                    onChange={(event) => setTextValue(event.target.value)}
                />
                <button
                    className="save-changes-button ml-4"
                    onClick={() => onFilterSelected(textValue)}
                >
                    Save
                </button>
            </>
        );
    }

    function FilterSelector() {
        return (
            <div className="p-8 pt-0">
                <div className="text-2xl font-bold mb-16">Add Rule</div>
                <div className="flex p-16">
                    <select
                        className="mr-4"
                        placeholder="Select Filter"
                        value={selectedType}
                        onChange={onFilterTypeSelected}
                    >
                        <option value={''} disabled hidden>
                            Select Filter
                        </option>
                        {config?.filters?.map((filter) => (
                            <option
                                key={`filter-${filter.label}`}
                                value={filter.label}
                            >
                                {filter.label}
                            </option>
                        ))}
                    </select>
                    {filterType && (
                        <select
                            className={`mr-4 ${
                                filterType.operators?.length <= 1
                                    ? 'opacity-50'
                                    : ''
                            }`}
                            placeholder="Select Filter"
                            value={selectedOperator}
                            onChange={(event) => {
                                setSelectedOperator(event.target.value);
                            }}
                            disabled={filterType.operators?.length <= 1}
                        >
                            {filterType.operators?.map((operator) => (
                                <option
                                    key={`operator-${operator}`}
                                    value={operator}
                                >
                                    {operator}
                                </option>
                            ))}
                        </select>
                    )}
                    {filterType && <FilterInput inputType={filterType.input} />}
                </div>
            </div>
        );
    }

    return (
        <section>
            <FilterSelector />
        </section>
    );
});

export default AddMatrixRule;
