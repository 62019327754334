import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableImage from 'components/TableImage';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useQueryList from 'hooks/useQuery';


const { default: InformationTab } = require('components/InformationTab');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        category: state.PECategories.selectedCategory,
        assignments: state.PECategories.assignments,
        page: state.PECategories.assignments_page,
        per_page: state.PECategories.assignments_per_page,
        total: state.PECategories.assignments_total,
        total_pages: state.PECategories.assignments_total_pages,
        isLoading: state.PECategories.assignments_is_loading,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PECategoryOverview = connect(mapStateToProps)(function (props) {
    const {
        category,
        assignments,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedRegionFilter,
    } = props;

    const { PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST } = useActions();
    const navigate = useNavigate();
    const { term: searchTerm } = useQueryList(['term']);

    function onNext() {
        PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST({
            categoryId: category.id,
            page: page + 1,
            per_page,
            searchTerm,
        });
    }

    function onPrev() {
        PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST({
            categoryId: category.id,
            page: page - 1,
            per_page,
            searchTerm,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST({
            categoryId: category.id,
            page: pageSelected,
            per_page,
            searchTerm,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST({
            categoryId: category.id,
            per_page: perPage,
            searchTerm,
        });
    }

    function onAdd() {
        navigate(`/program-engine/categories/${category?.id}/assignments/new`);
    }

    useEffect(() => {
        PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST({ categoryId: category.id, searchTerm });
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function OverviewTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Assignment" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Name</td>
                            <td>Duration</td>
                            <td>Tags</td>
                            <td>View</td>
                        </tr>
                    </thead>
                    <tbody>
                        {assignments.map((assign) => (
                            <tr key={`assignment-${assign.id}`}>
                                <TableImage
                                    alt={assign.name}
                                    imageUrl={assign.poster_url}
                                />
                                <td>
                                    <SelectableField
                                        text={<><b>{assign.name}</b><br/>{assign.technical_name}</>}
                                        url={`/program-engine/categories/${category.id}/assignments/${assign.id}`}
                                    />
                                </td>
                                <td>{assign.duration_ms ? `${assign.duration_ms / 1000 / 60} minutes` : ''}</td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {assign?.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${assign.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>

                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/categories/${category.id}/assignments/${assign.id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <InformationTab
            sectionName="Assignments"
            dataTable={<OverviewTable />}
            showPrograms={false}
            perPage={per_page}
            onPerPageSelected={onPerPageSelected}
            showSearchField={true}
            showBreadcrumbs={false}
        />
    );
});

export default PECategoryOverview;
