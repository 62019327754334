const apiUrl = process.env.REACT_APP_API_URL;

const redirectToLogin = () => {
    /*const backurl = encodeURIComponent(window.location.href);
    console.log('redirecting')
    fetch(`${apiUrl}/create-session?backurl=${backurl}`, {
        credentials: 'include',
    })
        .then(async (resp) => {
            // TODO: Check if resp is success
            window.location.replace(`${apiUrl}/login`);
        })
        .catch((err) => {
            console.log(err);
        });*/
};

let token = localStorage.getItem('admin-token');
function setToken(newToken) {
    token = newToken;
    localStorage.setItem('admin-token', newToken);
}
/**
 * API service containing methods for making requests to the API.
 * @param {string} endpoint - The API endpoint to be called.
 * @param {string} url - The base URL to the API (usually comes from the env variable when omitted).
 * @param {string} region - The region to be passed in the headers as a content filter (omit for all regions).
 * @param {string} service - The service to be passed in the headers in case of region filter is used.
 */
const api = {
    get: (endpoint, url, region, service) => {
        const headers = new Headers();

        if(!!service){
            let regionFilter = 'all';
            if(!!region && region !== 'all'){
                regionFilter = region;
            }
            headers.append(`x-${service}-region`, regionFilter);
        }


        headers.append('x-jwt', token);
        headers.append('Admin', 'wTzpZD856nxLAaSt');
        return fetch(`${url ? url : apiUrl}${endpoint}`, {
            credentials: 'include',
            headers,
        })
            .then((response) => response.json())
            .then((json) => {
                if (
                    json.status_code &&
                    ![200, 201, 202].includes(json.status_code)
                ) {
                    if (
                        json.status_code === 401 ||
                        (json.status_code === 500 &&
                            json.message?.contains('401'))
                    ) {
                        redirectToLogin();
                    } else {
                        alert(json.message);
                    }
                    return null;
                } else {
                    return json;
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },
    post: (endpoint, body, url) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-jwt', token);
        headers.append('Admin', 'wTzpZD856nxLAaSt');
        return fetch(`${url ? url : apiUrl}${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                if (
                    json.status_code &&
                    ![200, 201, 202].includes(json.status_code)
                ) {
                    if (json.status_code === 401) {
                        redirectToLogin();
                    } else {
                        alert(json.message);
                    }
                    return null;
                } else {
                    return json;
                }
            })
            .catch((err) => {});
    },
    postForm: (endpoint, formData, url) => {
        const headers = new Headers();
        headers.append('x-jwt', token);
        headers.append('Admin', 'wTzpZD856nxLAaSt');
        return fetch(`${url ? url : apiUrl}${endpoint}`, {
            method: 'POST',
            body: formData,
            headers,
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                if (
                    json.status_code &&
                    ![200, 201, 202].includes(json.status_code)
                ) {
                    if (json.status_code === 401) {
                        redirectToLogin();
                    } else {
                        alert(json.message);
                    }
                    return null;
                } else {
                    return json;
                }
            })
            .catch((err) => {});
    },
    put: (endpoint, body, url) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('x-jwt', token);
        headers.append('Admin', 'wTzpZD856nxLAaSt');
        return fetch(`${url ? url : apiUrl}${endpoint}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers,
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((json) => {
                if (
                    json.status_code &&
                    ![200, 201, 202].includes(json.status_code)
                ) {
                    if (json.status_code === 401) {
                        redirectToLogin();
                    } else {
                        alert(json.message);
                    }
                    return null;
                } else {
                    return json;
                }
            })
            .catch((err) => {});
    },
    del: (endpoint, url) => {
        const headers = new Headers();
        headers.append('x-jwt', token);
        headers.append('Admin', 'wTzpZD856nxLAaSt');
        return fetch(`${url ? url : apiUrl}${endpoint}`, {
            method: 'DELETE',
            credentials: 'include',
            headers,
        })
            .then((response) => response.json())
            .then((json) => {
                if (
                    json.status_code &&
                    ![200, 201, 202].includes(json.status_code)
                ) {
                    if (json.status_code === 401) {
                        redirectToLogin();
                    } else {
                        alert(json.message);
                    }
                    return null;
                } else {
                    return json;
                }
            })
            .catch((err) => {});
    },
    redirectToLogin: redirectToLogin,
    setToken: setToken,
};

export default api;
