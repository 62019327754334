import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableImage from 'components/TableImage';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PEAssigmentDetails from './AssignmentDetails';
import PEAssignmentsNew from './AssignmentsNew';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


const { default: InformationTab } = require('components/InformationTab');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        assignments: state.PEAssignments.assignments,
        page: state.PEAssignments.page,
        per_page: state.PEAssignments.per_page,
        total: state.PEAssignments.total,
        total_pages: state.PEAssignments.total_pages,
        isLoading: state.PEAssignments.isLoadingAssignments,
        selectedAssignment: state.PEAssignments.selectedAssignment,
        selectedCategory: state.PECategories.selectedCategory,
        categoriesOptions: state.PECategories.categoriesOptions,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEAssignments = connect(mapStateToProps)(function (props) {
    const {
        assignments,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedAssignment,
        categoriesOptions,
        selectedCategory,
        selectedRegionFilter,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [subtitle, setSubtitle] = useState(null);

    const {
        PE_FETCH_ASSIGNMENTS_REQUEST,
        PE_GET_ASSIGNMENT_REQUEST,
        PE_SELECT_ASSIGNMENT,
        PE_DELETE_ASSIGNMENT_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const { id, categoryId } = useParams();
    const { term: searchTerm } = useQueryList(['term']);

    const navigate = useNavigate();
    const location = useLocation();
    const [categoriesSet, setCategoriesSet] = useSetFromOptions();

    function onAdd(categoryId) {
        setSubtitle('Add New Assignment');
        if (typeof categoryId === 'string' || typeof categoryId === 'number') {
            setDetailComponent(<PEAssignmentsNew categoryId={categoryId} />);
        } else {
            setDetailComponent(<PEAssignmentsNew />);
        }
    }

    function onNext() {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
        });
    }

    function onPrev() {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            per_page: perPage,
            searchTerm,
        });
    }

    function onDeleteAction() {
        PE_DELETE_ASSIGNMENT_REQUEST({
            assignmentId: selectedAssignment?.id,
            page,
            searchTerm,
            perPage: per_page,
        });
        navigate(`/program-engine/categories/${categoryId}/overview`);
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Assignment?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    const onDetailSelection = (episode) => {
        setSubtitle(null);
        setDetailComponent(null);
        PE_SELECT_ASSIGNMENT(episode);
        setShowDelete(true);
        if (episode) {
            navigate(`/program-engine/categories/${categoryId}/assignments/${episode.id}`);
        } else {
            PE_FETCH_ASSIGNMENTS_REQUEST({
                per_page,
                page,
                searchTerm,
            });
            navigate(`/program-engine/categories/${categoryId}`);
        }
    };

    useEffect(() => {
        setCategoriesSet(categoriesOptions);
        // eslint-disable-next-line
    }, [categoriesOptions]);

    useEffect(() => {
        if (selectedAssignment) {
            setSubtitle(selectedCategory?.name);
            setDetailComponent(<PEAssigmentDetails />);
            setShowDelete(true);
        } else {
            setShowDelete(false);
            setDetailComponent(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAssignment]);

    useEffect(() => {
        if (id) {
            try {
                PE_GET_ASSIGNMENT_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        }
        if(location.pathname.split('/').at(-1) === 'new' && categoryId){
            onAdd(categoryId);
        }
        else {
            PE_SELECT_ASSIGNMENT(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id, categoryId]);

    useEffect(() => {
        PE_FETCH_ASSIGNMENTS_REQUEST({ searchTerm });
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Assignment" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Tags</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {assignments.map((assignment) => (
                            <tr key={`assignment-${assignment.id}`}>
                                <TableImage
                                    alt={assignment.name}
                                    imageUrl={assignment.poster_url}
                                />
                                <td>
                                    <SelectableField
                                        text={assignment.name}
                                        url={`/program-engine/categories/${categoryId}/assignments/${assignment.id}`}
                                    />
                                </td>
                                <td>
                                    <SelectableField
                                        text={
                                            categoriesSet[
                                                assignment.category_id
                                            ]
                                        }
                                        url={`/program-engine/categories/${assignment.category_id}`}
                                    />
                                </td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {assignment.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${assignment.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/categories/${categoryId}/assignments/${assignment.id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    totalPages={total_pages}
                    currentPage={page}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <InformationTab
            showPrograms={false}
            showDelete={showDelete}
            showSearchField={true}
            sectionName="Assignments"
            subsectionName={subtitle}
            deleteText="Assignment"
            perPage={per_page}
            detailComponent={detailComponent}
            dataTable={<DataTable />}
            onPerPageSelected={onPerPageSelected}
            onDetailSelected={onDetailSelection}
            onDeleteDetail={onDeleteDetail}
        />
    );
});

export default PEAssignments;
