import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchAnswers(page = 1, per_page = 10, questionId, searchTerm = '', sorting = 'all', reel = 'all', apiUrl){
    let url = `/community/posts?post_type=answer&include=flags&parent_id=${questionId}&page=${page}&per_page=${per_page}`;
    if (!!searchTerm) {
        url += `&term=${searchTerm}`;
    }
    if (sorting !== 'all') {
        url += `&sort=${sorting}`;
    }
    if (reel !== 'all') {
        url += `&is_reel=${reel}`;
    }
    const json = yield api.get(url, apiUrl, 'all', SERVICE_TYPES.COMMUNITY);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_ANSWERS_PER_QUESTION_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postAnswer(answer, apiUrl) {
    const json = yield api.post(`/community/posts`, answer, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_ANSWER_RESPONSE,
            data: json,
        });
    }
}

function* getAnswer(answerId, apiUrl) {
    const json = yield api.get(
        `/community/posts/${answerId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_ANSWER_RESPONSE,
            data: json,
        });
    }
}

function* updateAnswer(answer, apiUrl) {
    const { id } = answer;
    delete answer.id;
    const json = yield api.put(`/community/posts/${id}`, answer, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_ANSWER_RESPONSE,
            data: json,
        });
    }
}

function* deleteAnswer(
    answerId,
    apiUrl
) {
    const json = yield api.del(`/community/posts/${answerId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_DELETE_ANSWER_RESPONSE,
            data: null,
        });
    }
}

function* fetchFlags(page = 1, per_page = 10, answerId, status = 'all', sorting = 'all', apiUrl){
    let url = `/community/flags?resource_type=Post&parent_id=${answerId}&page=${page}&per_page=${per_page}`;
    if (sorting !== 'all') {
        url += `&sort=${sorting}`;
    }
    if (status !== 'all') {
        url += `&status=${status}`;
    }
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_ANSWER_FLAGS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* updateReasonFlags(flag,apiUrl){
    const { id } = flag;
    delete flag.id;
    const json = yield api.put(`/community/flags/${id}`, flag, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_ANSWER_FLAGS_REASON_RESPONSE,
            data: json,
        });
    }
}

function* getAnswerFiles(answerId, apiUrl) {
    const json = yield api.get(
        `/community/assets?assetable_type=Post&assetable_id=${answerId}&per_page=100`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_ANSWER_FILES_RESPONSE,
            data: json.results,
        });
    }
}

function* postAsset(answerId, brandIdent, asset_type, slot, file, done, apiUrl) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brand_ident', brandIdent);
    formData.append('assetable_id', answerId);
    formData.append('asset_type', asset_type);
    formData.append('slot', slot);
    formData.append('assetable_type', 'Post');
    const json = yield api.postForm('/community/assets',formData,apiUrl);
    if (json.status_code && ![200, 201, 202].includes(json.status_code)) {
        alert(json.message);
    } else {
        if (done) done();

        yield getAnswerFiles(answerId, apiUrl);
    }
}

function* deleteAsset(assetId, answerId, apiUrl) {
    const json = yield api.del('/community/assets/' + assetId, apiUrl);
    if (json) {
        console.log('file Deleted');
        if(answerId){
            yield getAnswerFiles(answerId, apiUrl);
        }
    }
}

function* assignAsset(answerId, assetId, apiUrl) {
    const json = yield api.post(`/community/assets/${assetId}/assign`, {}, apiUrl);
    if (json) {
        yield getAnswer(answerId, apiUrl);
    }
}
function* unAssignAsset(answerId, assetId, apiUrl) {
    const json = yield api.post(`/community/assets/${assetId}/unassign`, {}, apiUrl);
    if (json) {
        yield getAnswer(answerId, apiUrl);
    }
}

function* fetchTranslations(answerId, page = 1, per_page = 10, apiUrl) {
    const json = yield api.get(
        `/community/translations?resource_type=answer&resource_id=${answerId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_ANSWER_TRANSLATIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postTranslation(resource_id, translation, apiUrl) {
    const json = yield api.post(
        `/community/translations`,
        {
            resource_id,
            resource_type: 'Post',
            ...translation,
        },
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_ANSWER_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}

function* deleteTranslation(
    translationId,
    answerId,
    page,
    perPage,
    apiUrl
) {
    const json = yield api.del(`/community/translations/${translationId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_SELECT_ANSWER_TRANSLATION,
            data: null,
        });
        yield fetchTranslations(answerId, page, perPage, apiUrl);
    }
}

function* updateTranslation(translation, apiUrl) {
    const json = yield api.put(
        `/community/translations/${translation.id}`,
        translation,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_ANSWER_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}

function* csAnswersWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_FETCH_ANSWERS_PER_QUESTION_REQUEST:
            yield fetchAnswers(
                action.data?.page,
                action.data?.per_page,
                action.data?.questionId,
                action.data?.searchTerm,
                action.data?.sorting,
                action.data?.reel,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_POST_ANSWER_REQUEST:
            yield postAnswer(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_DELETE_ANSWER_REQUEST:
            yield deleteAnswer(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_FETCH_ANSWER_REQUEST:
            yield getAnswer(
                action.data,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UPDATE_ANSWER_REQUEST:
            yield updateAnswer(
                action.data,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FETCH_ANSWER_FLAGS_REQUEST:
            yield fetchFlags(
                action.data?.page,
                action.data?.per_page,
                action.data?.answerId,
                action.data?.status,
                action.data?.sorting,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UPDATE_ANSWER_FLAGS_REASON_REQUEST:
            yield updateReasonFlags(
                action.data,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FETCH_ANSWER_FILES_REQUEST:
                yield getAnswerFiles(action.data, apiUrl);
                break;
        case ACTION_TYPES.CS_POST_ANSWER_FILE_REQUEST:
            yield postAsset(
                action.data?.answerId,
                action.data?.brandIdent,
                action.data?.assetType,
                action.data?.slot,
                action.data?.file,
                action.data?.done,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_DELETE_ANSWER_FILE_REQUEST:
            yield deleteAsset(
                action.data?.assetId,
                action.data?.answerId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_ASSIGN_ANSWER_ASSET_REQUEST:
            yield assignAsset(
                action.data?.answerId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UNASSIGN_ANSWER_ASSET_REQUEST:
            yield unAssignAsset(
                action.data?.answerId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FETCH_ANSWER_TRANSLATIONS_REQUEST:
            yield fetchTranslations(
                action.data?.answerId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_POST_ANSWER_TRANSLATION_REQUEST:
            yield postTranslation(
                action.data?.resourceId,
                action.data?.translation,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_DELETE_ANSWER_TRANSLATION_REQUEST:
            yield deleteTranslation(
                action.data?.translationId,
                action.data?.answerId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UPDATE_ANSWER_TRANSLATION_REQUEST:
            yield updateTranslation(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default csAnswersWorker;
