/* eslint-disable react-hooks/exhaustive-deps */
import DateField from 'components/DateField';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        seriesOptions: state.vhsSeries.seriesOptions,
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

function AttendsTableRaw({
    episodes = [],
    seriesOptions = [],
    brandsOptions = [],
    onDetailSelection = null,
    isLoading,
}) {
    const navigate = useNavigate();
    const [seriesSet, setSeriesSet] = useSetFromOptions();
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    useEffect(() => {
        setSeriesSet(seriesOptions);
    }, [seriesOptions]);

    useEffect(() => {
        setBrandsSet(brandsOptions);
    }, [brandsOptions]);

    return (
        <section>
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Scheduled</th>
                        <th>Duration</th>
                        <th>Instructor</th>
                        <th>Visibility</th>
                        <th>Series</th>
                        <th>Brand</th>
                        <th>Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {episodes.map((item) => (
                        <tr key={`episode-${item.episode.id}`}>
                            <td>
                            {item.episode.poster?.url && 
                                <SelectableField
                                    url={`/vhs/episodes/${item.episode.id}`}
                                >
                                    <img
                                        src={item.episode.poster?.url}
                                        alt={item.episode.name}
                                        style={{
                                            maxHeight: '4rem',
                                            maxWidth: '5rem',
                                            minHeight: '4rem',
                                            minWidth: '5rem',
                                        }}
                                    />
                                </SelectableField>
                            }
                            </td>
                            <td>
                                <SelectableField
                                    action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(item.episode);
                                        }
                                        navigate(`/vhs/episodes/${item.episode.id}`);
                                    }}
                                    text={item.episode.name}
                                />
                            </td>
                            <td>
                                <DateField timeMillis={item.episode.start_int} />
                            </td>
                            <td>
                                {item.episode.duration_ms
                                    ? `${item.episode.duration_ms / 60000} minutes`
                                    : null}
                            </td>
                            <td>
                                <SelectableField
                                    url={`/vhs/instructors/${item.episode.instructor?.id}`}
                                    text={item.episode.instructor?.name}
                                />
                            </td>
                            <td>{item.episode.visibility}</td>
                            <td>
                                <SelectableField
                                    url={`/vhs/series/${item.episode.series_id}`}
                                    text={seriesSet[item.episode.series_id]}
                                />
                            </td>
                            <td>
                                {brandsSet[item.episode.brand_ident]}
                            </td>
                            <td className="tags">
                                <div className="tagbox">
                                    {item.episode.tags?.map((tag) => (
                                        <div
                                            className="tag"
                                            key={`tag-${item.episode.id}-${tag.id}`}
                                        >
                                            {tag.name}
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
}

const EpisodesTable = connect(mapStateToProps)(AttendsTableRaw);
export default EpisodesTable;
