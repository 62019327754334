import TranslationsTable from 'components/Translations/TranslationsTable';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        comment: state.CSComments.selectedComment,
        translations: state.CSComments.translations,
        page: state.CSComments.translations_page,
        per_page: state.CSComments.translations_per_page,
        total: state.CSComments.translations_total,
        total_pages: state.CSComments.translations_total_pages,
        isLoading: state.CSComments.translations_is_loading,
        selectedTranslation: state.CSComments.selectedTranslation,
    };
}

const CSCommentTranslations = connect(mapStateToProps)(function (props) {
    const {
        comment,
        translations,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedTranslation,
    } = props;

    const {
        CS_FETCH_COMMENT_TRANSLATIONS_REQUEST,
        CS_POST_COMMENT_TRANSLATION_REQUEST,
        CS_UPDATE_COMMENT_TRANSLATION_REQUEST,
        CS_DELETE_COMMENT_TRANSLATION_REQUEST,
        CS_SELECT_COMMENT_TRANSLATION,
    } = useActions();

    function onNext() {
        CS_FETCH_COMMENT_TRANSLATIONS_REQUEST({
            commentId: comment?.id,
            page: page + 1,
            per_page,
        });
    }

    function onPrev() {
        CS_FETCH_COMMENT_TRANSLATIONS_REQUEST({
            commentId: comment?.id,
            page: page - 1,
            per_page,
        });
    }

    function onPageSelected(selectedPage) {
        CS_FETCH_COMMENT_TRANSLATIONS_REQUEST({
            commentId: comment?.id,
            page: selectedPage,
            per_page,
        });
    }

    function onPerPageSelected({ perPage }) {
        CS_FETCH_COMMENT_TRANSLATIONS_REQUEST({
            commentId: comment?.id,
            per_page: perPage,
        });
    }

    function onDelete(translation) {
        CS_DELETE_COMMENT_TRANSLATION_REQUEST({
            commentId: comment?.id,
            page,
            per_page,
            translationId: translation.id,
        });
    }

    function onUpdate(translation) {
        CS_UPDATE_COMMENT_TRANSLATION_REQUEST(translation);
    }

    function onCreate(translation) {
        CS_POST_COMMENT_TRANSLATION_REQUEST({
            resourceId: comment?.id,
            translation,
        });
    }

    useEffect(() => {
        CS_FETCH_COMMENT_TRANSLATIONS_REQUEST({
            commentId: comment?.id,
        });
        return () => {
            CS_SELECT_COMMENT_TRANSLATION(null);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <TranslationsTable
            page={page}
            perPage={per_page}
            onNext={onNext}
            onPrev={onPrev}
            onPageSelected={onPageSelected}
            onPerPageSelected={onPerPageSelected}
            isLoading={isLoading}
            total={total}
            totalPages={total_pages}
            onDelete={onDelete}
            translations={translations}
            onSelect={CS_SELECT_COMMENT_TRANSLATION}
            onCreate={onCreate}
            onUpdate={onUpdate}
            selectedTranslation={selectedTranslation}
        />
    );
});

export default CSCommentTranslations;
