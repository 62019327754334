import { applyMiddleware, createStore, compose } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'store/reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

let middleware = null;
let composeEnhancers = compose;
if (process.env && process.env.NODE_ENV === 'development') {
    middleware = applyMiddleware(sagaMiddleware, logger);
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    middleware = applyMiddleware(sagaMiddleware);
}

const configureStoreExport = createStore(
    rootReducer,
    composeEnhancers(middleware)
);
sagaMiddleware.run(rootSaga);

export default configureStoreExport;
