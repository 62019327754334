import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import csFlagsWorker from '../workers/csFlagsWorker';

function* CS_FLAGS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_FETCH_FLAGS_REQUEST,
            ACTION_TYPES.CS_FLAGS_CHANGE_REASON_REQUEST,
        ],
        csFlagsWorker,
        apiUrl,
        ...params
    );
}

export default CS_FLAGS_REQUEST;
