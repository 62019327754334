const { useState } = require('react');

function useInputState(initialState) {
    const [element, setElement] = useState(initialState);
    function onFieldChange(event) {
        const newElement = {
            ...element,
            [event?.target?.name]: event?.target.value,
        };
        setElement(newElement);
    }

    return [element, onFieldChange];
}

export default useInputState;
