import { useEffect, useState } from 'react';

function TableNavigator({
    currentPage = 1,
    totalPages = 300,
    onPageSelected = function () {},
    onNext = function () {},
    onPrev = function () {},
}) {
    const [middlePages, setMiddlePages] = useState([]);
    let navIndex = 0;
    useEffect(() => {
        const loadPages = () => {
            let counter = 0;
            const mids = [];
            let i = currentPage - 2;
            if (currentPage > 3) {
                mids.push('...');
            }
            for (i; i < totalPages && counter <= 3; i++) {
                if (i > 1) {
                    mids.push(i);
                    counter++;
                }
            }
            if (i < totalPages) {
                mids.push('...');
            }
            setMiddlePages(mids);
        };
        loadPages();
    }, [currentPage, totalPages]);

    return totalPages <= 1 ? null : (
        <div className='flex items-start'>
        <div className="tab-nav mt-4 mb-4 rounded-lg overflow-hidden flex bg-white">
            {currentPage !== 1 && (
                <span className="tab-nav-element gap-1 p-8" onClick={onPrev}>
                    <img src='/assets/arrow-left-icon.png' alt='Previous page' width={20} height={20}/>
                    Previous
                </span>
            )}

            <span
                className={`tab-nav-element ${
                    1 === currentPage ? 'active' : ''
                }`}
                onClick={() => onPageSelected(1)}
            >
                1
            </span>

            {middlePages.map((midPage) => (
                <span
                    className={`tab-nav-element ${
                        midPage === currentPage
                            ? 'active'
                            : ''
                    }`}
                    key={'midpage-' + navIndex++}
                    onClick={() => midPage !== '...' && onPageSelected(midPage)}
                >
                    {midPage}
                </span>
            ))}
            <span
                className={`tab-nav-element ${
                    totalPages === currentPage
                        ? 'active'
                        : ''
                }`}
                onClick={() => onPageSelected(totalPages)}
            >
                {totalPages}
            </span>
            {currentPage !== totalPages && totalPages !== 1 && (
                <>
                    <span className="tab-nav-element gap-1 p-8" onClick={onNext}>
                    Next
                    <img src='/assets/arrow-right-icon.png' alt='Previous page' width={20} height={20}/>
                    </span>
                </>
            )}
        </div>
        </div>
    );
}

export default TableNavigator;
