const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: adminsWorker } = require('../workers/adminsWorker');

function* ADMINS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.FETCH_ADMINS_REQUEST,
            ACTION_TYPES.FETCH_USER_INFO,
            ACTION_TYPES.GET_ADMIN_REQUEST,
            ACTION_TYPES.POST_ADMIN_REQUEST,
            ACTION_TYPES.UPDATE_ADMIN_REQUEST,
            ACTION_TYPES.FETCH_ADMIN_CONFIG_REQUEST,
            ACTION_TYPES.DELETE_ADMIN_REQUEST,
        ],
        adminsWorker,
        apiUrl,
        ...params
    );
}

export default ADMINS_REQUEST;
