import { useEffect, useState } from 'react';

function NewProgram({ onConfirm, brandOptions = [] }) {
    const [program, setProgram] = useState({
        name: '',
        brand: '',
        is_enabled: false,
        is_auto_approved: false,
        is_brand_default: false,
        credit: 0,
        discount: 0,
        credit_action: '',
        subdomain: '',
        landing_page_script: '',
        redirect_url: '',
        validate_user_url: '',
        validate_purchase_url: '',
    });

    const handleFieldChange = (event) => {
        const newState = {
            ...program,
            [event.target.name]: event.target.value,
        };
        setProgram(newState);
    };

    const saveChanges = (event) => {
        event.preventDefault();
        onConfirm({
            name:                   program.name,
            brand_ident:            program.brand,
            redirect_url:           program.redirect_url,
            validate_user_url:      program.validate_user_url,
            validate_purchase_url:  program.validate_purchase_url,
            landing_page_script:    program.landing_page_script,
            credit_action:          program.credit_action,
            subdomain:              program.subdomain,
            credit:                 program.credit,
            discount:               program.discount,
            is_enabled:             program.is_enabled,
            is_auto_approved:       program.is_auto_approved,
            is_brand_default:       program.is_brand_default
        });
    };

    useEffect(() => {
        const loadBrands = () => {
            setProgram((p) => ({
                ...p,
                brand: brandOptions[0]?.identValue,
            }));
        };
        loadBrands();
    }, [brandOptions]);

    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={program.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    className="w-full"
                                    name="brand"
                                    value={program.brand}
                                    onChange={handleFieldChange}
                                >
                                    {brandOptions.map((option) => (
                                        <option
                                            key={`credit-${option.identValue}`}
                                            value={option.identValue}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Enabled</td>
                            <td>
                                <select
                                    className="w-full"
                                    name="is_enabled"
                                    value={program.is_enabled}
                                    onChange={handleFieldChange}
                                >
                                    <option value={false}>Disabled</option>
                                    <option value={true}>Enabled</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Approve</td>
                            <td>
                                <select
                                    className="w-full"
                                    name="is_auto_approved"
                                    value={program.is_auto_approved}
                                    onChange={handleFieldChange}
                                >
                                    <option value={false}>Require Approval</option>
                                    <option value={true}>Auto-Approve</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand Default</td>
                            <td>
                                <select
                                    className="w-full"
                                    name="is_brand_default"
                                    value={program.is_brand_default}
                                    onChange={handleFieldChange}
                                >
                                    <option value={false}>Not Default</option>
                                    <option value={true}>Default for Brand</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Credit</td>
                            <td>
                                <input
                                    name="credit"
                                    value={program.credit}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Discount</td>
                            <td>
                                <input
                                    name="discount"
                                    value={program.discount}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Credit Action
                            </td>
                            <td>
                                <input
                                    name="credit_action"
                                    value={program.credit_action}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Subdomain
                            </td>
                            <td>
                                <input
                                    name="subdomain"
                                    value={program.subdomain}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Redirect URL
                            </td>
                            <td>
                                <input
                                    name="redirect_url"
                                    value={program.redirect_url}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Validate User URL
                            </td>
                            <td>
                                <input
                                    name="validate_user_url"
                                    value={program.validate_user_url}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Validate Purchase URL
                            </td>
                            <td>
                                <input
                                    name="validate_purchase_url"
                                    value={program.validate_purchase_url}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Landing Page Script
                            </td>
                            <td>
                                <textarea
                                    className='w-full'
                                    name="landing_page_script"
                                    onChange={handleFieldChange}
                                >{ program.landing_page_script }</textarea>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Program
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
}

export default NewProgram;
