import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import PEAssignmentOverview from '../AssignmentOverview';
import PEAssignmentsEdit from '../AssignmentsEdit';
import PEAssignmentTags from '../AssignmentTags';
import PEAssignmentTranslations from '../AssignmentTranslations';
import PEAssigmentFiles from '../AssigmentFiles';
import { useEffect, useState } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        assignment: state.PEAssignments.selectedAssignment,
    };
}

const PEAssigmentDetails = connect(mapStateToProps)(function (props) {
    const { assignment } = props;
    const { PE_FETCH_ASSIGNMENT_FILES_REQUEST } = useActions();
    const [selectedTab, setSelectedTab] = useState(0);
    function onSave() {
        setSelectedTab(0);
    }
    
    useEffect(() => {
        PE_FETCH_ASSIGNMENT_FILES_REQUEST(assignment?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <Tabs
            sectionNames={[
                'Overview',
                'Settings',
                'Tags',
                'Translations',
                'Files',
            ]}
            sectionContents={[
                <PEAssignmentOverview assignment={assignment} />,
                <PEAssignmentsEdit onConfirm={onSave} />,
                <PEAssignmentTags />,
                <PEAssignmentTranslations />,
                <PEAssigmentFiles />
            ]}
            selectedTab={selectedTab}
            onTabChange={(selection) => setSelectedTab(selection)}
        />
    );
});

export default PEAssigmentDetails;
