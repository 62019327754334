import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedTagGroup: state.PETags.selectedTagGroup,
        brandsOptions: state.PEBrands.brandsOptions,
    };
}

const PEEditTagGroup = connect(mapStateToProps)(function (props) {
    const { selectedTagGroup, brandsOptions } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        PE_UPDATE_TAG_GROUP_REQUEST,
        PE_TAG_GROUP_REQUEST,
        PE_DELETE_TAG_GROUP_REQUEST,
    } = useActions();
    const [updatedTagGroup, setUpdatedTagGroup] = useState({
        name: selectedTagGroup?.name,
        position: selectedTagGroup?.position,
        brand_ident: selectedTagGroup?.brand_ident,
    });

    function handleFieldChange(event) {
        const newState = {
            ...updatedTagGroup,
            [event.target.name]: event.target.value,
        };
        setUpdatedTagGroup(newState);
    }

    function onDelete() {
        // eslint-disable-next-line
        const confirmed = confirm(
            `Are you sure you want to delete ${selectedTagGroup?.name}?`
        );
        if (confirmed) {
            PE_DELETE_TAG_GROUP_REQUEST(selectedTagGroup?.id);
            navigate('/program-engine/tags');
        }
    }

    useEffect(() => {
        if (!selectedTagGroup && !id) {
            navigate(`/program-engine/tags`);
        } else if (selectedTagGroup) {
            setUpdatedTagGroup({
                name: selectedTagGroup?.name,
                position: selectedTagGroup?.position,
                brand_ident: selectedTagGroup?.brand_ident,
            });
        }
        // eslint-disable-next-line
    }, [selectedTagGroup]);

    useEffect(() => {
        if (id) {
            try {
                PE_TAG_GROUP_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        }
        // eslint-disable-next-line
    }, [id]);

    function onSubmit(event) {
        event.preventDefault();
        PE_UPDATE_TAG_GROUP_REQUEST({
            id: selectedTagGroup.id,
            name: updatedTagGroup.name,
            position: updatedTagGroup.position,
            brand_ident: updatedTagGroup.brand_ident,
        });
    }

    return (
        <section>
            <div className="flex mb-4 items-center h-16">
                <div className="flex flex-1 items-center">
                    <div
                        className="font-bold cursor-pointer  text-lg"
                        onClick={() => {
                            navigate('/program-engine/tags');
                        }}
                    >
                        Tags
                    </div>
                    <div className="ml-8 font-medium text-blue-600">
                        {selectedTagGroup?.name}
                    </div>
                </div>
                <button className="button delete-button" onClick={onDelete}>
                    Delete "{selectedTagGroup?.name}"
                </button>
            </div>
            <form onSubmit={onSubmit}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    name="name"
                                    value={updatedTagGroup.name || ''}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Position</td>
                            <td>
                                <input
                                    type="number"
                                    name="position"
                                    value={updatedTagGroup.position || 0}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={updatedTagGroup.brand_ident || ''}
                                    onChange={handleFieldChange}
                                >
                                    {brandsOptions.map((brand) => (
                                        <option
                                            key={brand.value}
                                            value={brand.value}
                                        >
                                            {brand.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        {(updatedTagGroup.name !== selectedTagGroup?.name ||
                            updatedTagGroup.brand_ident !==
                                selectedTagGroup?.brand_ident ||
                            // eslint-disable-next-line
                            updatedTagGroup.position != selectedTagGroup?.position) && (
                                <tr>
                                    <td colSpan={2}>
                                        <button type="submit" className="mt-4 button save-changes-button">
                                            Save Changes
                                        </button>
                                    </td>
                                </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default PEEditTagGroup;
