import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import { useNavigate } from 'react-router';
import ConversionsTable from 'routing/Referrals/Conversions/ConversionsTable';

const { default: InformationTab } = require('components/InformationTab');

function MemberConversions({
    conversions,
    perPage = 10,
    page = 1,
    totalPages = 1,
    total = 0,
    onPageSelected = function () {},
    onPerPageSelected = function () {},
    onNext = function () {},
    onPrev = function () {},
    onApprove = function () {},
    isLoading = false,
}) {
    const navigate = useNavigate();

    const detailSelected = (conversion) => {
        navigate('/referrals/conversions/' + conversion?.id);
    };

    return (
        <InformationTab
            dataTable={
                <section>
                    <TableResults results={total} />
                    <ConversionsTable
                        conversions={conversions}
                        excludedColumns={['referring_member']}
                        onDetailSelected={detailSelected}
                        onUpdateConversion={onApprove}
                        isLoading={isLoading}
                    />
                    <TableNavigator
                        currentPage={page}
                        totalPages={totalPages}
                        onPageSelected={onPageSelected}
                        onNext={onNext}
                        onPrev={onPrev}
                    />
                </section>
            }
            showPrograms={false}
            perPage={perPage}
            sectionName="Conversions"
            hasChart
            onPerPageSelected={onPerPageSelected}
        />
    );
}

export default MemberConversions;
