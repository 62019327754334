import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useInputState from 'hooks/useInputState';
import { connect } from 'react-redux';
import { useState } from 'react';

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

const SeriesEdit = connect(mapStateToProps)(function ({
    series,
    onSave = function () {},
    brandsOptions,
}) {
    const initialState = {
        name: series.name,
        description: series.description,
        visibility: series.visibility,
        brand_ident: series.brand_ident,
    };
    const [updatedSeries, onFieldChange] = useInputState(initialState);
    const [description, setDescription] = useState(initialState.description)

    const saveChanges = (event) => {
        event.preventDefault();
        onSave({
            id: series.id,
            name: updatedSeries.name,
            brand_ident: updatedSeries.brand_ident,
            visibility: updatedSeries.visibility,
            description,
        });
    };

 
    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className='text-bold label'>Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={updatedSeries.name}
                                    onChange={onFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Description</td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={updatedSeries.description}
                                    onChange={setDescription}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={updatedSeries.visibility}
                                    onChange={onFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={updatedSeries.brand_ident}
                                    onChange={onFieldChange}
                                >
                                    {brandsOptions.map((option) => (
                                        <option
                                            key={`brand-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        {initialState !== updatedSeries && (
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default SeriesEdit;
