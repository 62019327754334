import InformationTab from 'components/InformationTab';
import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PECategoriesNew from './CategoriesNew';
import PECategoryDetails from './CategoryDetails';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        categories: state.PECategories.categories,
        page: state.PECategories.page,
        per_page: state.PECategories.per_page,
        total: state.PECategories.total,
        total_pages: state.PECategories.total_pages,
        isLoading: state.PECategories.isLoadingCategories,
        selectedCategory: state.PECategories.selectedCategory,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PECategories = connect(mapStateToProps)(function (props) {
    const {
        categories,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedCategory,
        selectedRegionFilter,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const { id } = useParams();
    const { term: searchTerm } = useQueryList(['term']);

    const navigate = useNavigate();

    const {
        PE_FETCH_CATEGORIES_REQUEST,
        PE_FETCH_ALL_CATEGORIES_REQUEST,
        PE_GET_CATEGORY_REQUEST,
        PE_SELECT_CATEGORY,
        PE_DELETE_CATEGORY_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const onAdd = () => {
        setSubtitle('Add New Category');
        setDetailComponent(<PECategoriesNew />);
    };

    function onNext() {
        PE_FETCH_CATEGORIES_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
        });
    }

    function onPrev() {
        PE_FETCH_CATEGORIES_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_CATEGORIES_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_CATEGORIES_REQUEST({
            per_page: perPage,
            searchTerm,
        });
    }

    function onDeleteAction() {
        PE_DELETE_CATEGORY_REQUEST({
            categoryId: selectedCategory?.id,
            page,
            searchTerm,
            perPage: per_page,
        });
        navigate('/program-engine/categories');
        setSubtitle(null);
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Assignment Category?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function onDetailSelection(category) {
        setSubtitle(null);
        setDetailComponent(null);
        PE_SELECT_CATEGORY(category);
        setShowDelete(true);
        if (category) {
            navigate(`/program-engine/categories/${category.id}`);
        } else {
            PE_FETCH_CATEGORIES_REQUEST({
                page,
                per_page,
                searchTerm,
            });
            navigate(`/program-engine/categories`);
        }
    }

    useEffect(() => {
        if (selectedCategory) {
            setSubtitle(selectedCategory.name);
            setDetailComponent(<PECategoryDetails />);
            setShowDelete(true);
        } else {
            setShowDelete(false);
            setDetailComponent(null);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (id) {
            try {
                PE_GET_CATEGORY_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            PE_SELECT_CATEGORY(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        PE_FETCH_CATEGORIES_REQUEST({ searchTerm });
        PE_FETCH_ALL_CATEGORIES_REQUEST();
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Category" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Region</th>
                            <th>Assignments</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category) => (
                            <tr key={`category-${category.id}`}>
                                <td>
                                    <SelectableField
                                        text={category.name}
                                        url={`/program-engine/categories/${category.id}`}
                                    />
                                </td>
                                <td className='text-gray'>{category.region_ident}</td>
                                <td>{category.assignments}</td>

                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/categories/${category.id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onPageSelected={onPageSelected}
                    onNext={onNext}
                    onPrev={onPrev}
                />
            </section>
        );
    }

    return (
        <InformationTab
            showPrograms={false}
            showSearchField={true}
            showDelete={showDelete}
            sectionName="Assignment Categories"
            subsectionName={subtitle}
            deleteText="Assignment Category"
            perPage={per_page}
            detailComponent={detailComponent}
            dataTable={<DataTable />}
            onPerPageSelected={onPerPageSelected}
            onDetailSelected={onDetailSelection}
            onDeleteDetail={onDeleteDetail}
        />
    );
});

export default PECategories;
