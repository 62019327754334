import { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

function CustomSelector({
    options = [],  
    initialSelectedValue = null,
    onSelect = () => {},
}) {
    const [selectedOption, setSelectedOption] = useState({
        value: null, 
        label: 'Select an option' 
    });

    useEffect(() => {
        if(initialSelectedValue){
            const option = options.find((item) => item.value === initialSelectedValue);
            setSelectedOption(option);
        }
    },[initialSelectedValue, options]);
    

    const handleSelect = (option) => {
        console.log('handleSelect', option);
        if(option){
            setSelectedOption(option);
            onSelect(option.value);
        }
    }

    return(
        <div className='relative'>
                <button className="bg-gray-200 text-black px-2 p-1 text-sm font-normal rounded-full peer transition-all duration-200 whitespace-nowrap inline-block group">
                    {selectedOption.label}
                    <ChevronDownIcon
                        className="chevron text-xs transition-all inline-block group-focus:hidden"
                        height='1rem'
                    />
                    <ChevronUpIcon
                        className="chevron text-xs transition-all hidden group-focus:inline-block"
                        height='1rem'
                    />
                </button>
                <div className='min-w-[240px] w-auto absolute top-0 z-10 border-solid border-2 border-gray-300 bg-white rounded-md shadow-lg
                            after:content-[""] after:inline-block after:absolute after:top-0 after:bg-white/40
                            after:w-full after:h-full after:-z-20 after:blur-[2px] after:rounded-lg
                        peer-focus:top-8 peer-focus:opacity-100 peer-focus:visible
                        transition-all duration-300 invisible opacity-0
                '>
                    <ul className='py-0 px-0 flex flex-col gap-1'>
                        {options.map((item,idx) => (
                            <li 
                                className='cursor-pointer bg-white border-solid border-b border-gray-300 text-black p-3 hover:opacity-90 active:[&>i]:bg-dark-primary' 
                                key={'reason-'+idx} 
                                onClick={
                                    () => { handleSelect(item) }
                                }
                            >
                                <i 
                                    className='w-5 h-5 inline-block mr-2 rounded-full border border-gray-300 align-middle data-[selected=true]:bg-dark-primary'
                                    data-selected={`${selectedOption.value === item.value}`}
                                ></i>
                                {item.label}
                            </li>
                        ))}
                        
                    </ul>
                </div>
            </div>
    );
}

export default CustomSelector;
