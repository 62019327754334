import { useState } from 'react';
import {
    BanIcon,
    CheckIcon,
    PhotographIcon,
    PlayIcon,
} from '@heroicons/react/outline';
import DateField from 'components/DateField';
import FileUploader from 'components/FileUploader';
import LoadingTable from 'components/LoadingTable';
import ResourceModal from 'components/ResourceModal';
import useActions from 'hooks/useActions';
import AlertModal from 'components/Modal/AlertModal';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        assignment: state.PEAssignments.selectedAssignment,
        files: state.PEAssignments.files,
        isLoading: state.PEAssignments.isLoadingFiles,
    };
}

const PEAssigmentFiles = connect(mapStateToProps)(function (props) {
    const { assignment, files, isLoading } = props;
    const [showModal, setShowModal] = useState(false);
    const [resourceType, setResourceType] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    //const { VHS_DELETE_EPISODE_FILE_REQUEST } = useActions();

    const { 
        PE_FETCH_ASSIGNMENT_FILES_REQUEST, 
        PE_ASSIGN_ASSET_REQUEST,
        PE_UNASSIGN_ASSET_REQUEST,
        PE_DELETE_ASSIGNMENT_FILE_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL,
     } = useActions();

    function openResource(file) {
        if (file.slot === 'video' && file.status === 'combining') return;
        setResourceType(file.slot);
        setSelectedFile(file);
        setShowModal(true);
    }

    function onClose() {
        setSelectedFile(null);
        setShowModal(false);
    }

    function onComplete() {
        PE_FETCH_ASSIGNMENT_FILES_REQUEST(assignment.id);
    }

    function onCloseUploadModal() {
        TOGGLE_MODAL(false);
    }

    function showNewUploadModal() {
        SET_MODAL_CONTENT(
            <FileUploader
                onClose={onCloseUploadModal}
                episodeId={assignment.id}
                brandIdent={assignment.brand_ident}
                onComplete={onComplete}
                assetType='Assignment'
                typeOfService='PE'
                isInModal={true}
            />
        )
        TOGGLE_MODAL(true);
    }

    function onDeleteFile(fileId) {
        PE_DELETE_ASSIGNMENT_FILE_REQUEST({assetId: fileId, assignmentId: assignment.id});
    }
    function handleDeleteFile(fileId) {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={()=>{ onDeleteFile(fileId) }} title="Do you want to delete this file?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function msToMMSS(ms) {
        var minutes = Math.floor(ms / 60000);
        var seconds = ((ms % 60000) / 1000).toFixed(0);
        return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    function fileSize(sizeBytes) {
        if (!sizeBytes) return '';
        if (sizeBytes < 1000000) {
            return (sizeBytes / 1024).toFixed(1) + ' kb';
        } else if (sizeBytes < 1000000000) {
            return (sizeBytes / 1048576).toFixed(1) + ' mb';
        } else {
            return (sizeBytes / 1073741824).toFixed(1) + ' gb';
        }
    }

    function assignAsset(file) {
        PE_ASSIGN_ASSET_REQUEST({
            assignmentId: assignment.id,
            assetId: file.id,
        });
    }
    function unAssignAsset(file) {
        PE_UNASSIGN_ASSET_REQUEST({
            assignmentId: assignment.id,
            assetId: file.id,
        });
    }

    function thumbnail(file) {
        switch (file.slot) {
            case 'video': {
                return (
                    <>
                        {file.status !== 'combining' && (
                            <div
                                style={{
                                    maxHeight: '4rem',
                                    maxWidth: '5rem',
                                    minHeight: '4rem',
                                    minWidth: '5rem',
                                    margin: '0 auto',
                                }}
                                className="bg-gray-200 flex justify-center items-center"
                            >
                                <PlayIcon height="2rem" />
                            </div>
                        )}
                        {file.status === 'combining' && (
                            <span className="font-bold">Processing...</span>
                        )}
                    </>
                );
            }
            case 'poster': 
            case 'image':{
                return (
                    <div
                        style={{
                            maxHeight: '4rem',
                            maxWidth: '5rem',
                            minHeight: '4rem',
                            minWidth: '5rem',
                            margin: '0 auto',
                        }}
                        className="bg-gray-200 flex justify-center items-center"
                    >
                        {file.media && (
                            <img
                                src={file.media}
                                style={{
                                    maxHeight: '4rem',
                                    maxWidth: '5rem',
                                    minHeight: '4rem',
                                    minWidth: '5rem',
                                    margin: '0 auto',
                                }}
                                alt={file.name}
                            />
                        )}
                        {!file.media && <PhotographIcon height="2rem" />}
                    </div>
                );
            }
            case 'audio': {
                return (
                    <div
                        style={{
                            maxHeight: '4rem',
                            maxWidth: '5rem',
                            minHeight: '4rem',
                            minWidth: '5rem',
                            margin: '0 auto',
                        }}
                        className="bg-gray-200 block flex justify-center items-center"
                    >
                        <PlayIcon height="2rem" />
                    </div>
                );
            }
            default:
                return <span className="font-bold">View</span>;
        }
    }

    function assignement(file) {
        switch (file.slot) {
            case 'video':
                return (
                    <div>
                        {assignment.video_id === file.id ? (
                            <>
                                <button 
                                    className="custom-button unassign-background"
                                    onClick={() => unAssignAsset(file)}
                                >
                                    <BanIcon
                                        style={{ marginRight: '0.25rem' }}
                                        height="1.25rem"
                                    />{' '}
                                    Unassign
                                </button>
                            </>
                        ) : (
                            <button
                                className="custom-button reencode-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Video
                            </button>
                        )}
                    </div>
                );
            case 'poster':
            case 'image':
                return (
                    <div>
                        {assignment.poster_id === file.id ? (
                            <>
                                <button 
                                    className="custom-button unassign-background"
                                    onClick={() => unAssignAsset(file)}
                                >
                                    <BanIcon
                                        style={{ marginRight: '0.25rem' }}
                                        height="1.25rem"
                                    />{' '}
                                    Unassign
                                </button>
                            </>
                        ) : (
                            <button
                                className="custom-button make-poster-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Poster
                            </button>
                        )}
                    </div>
                );
            case 'audio':
                return (
                    <div>
                        {assignment.audio_id === file.id ? (
                            <>
                                <button 
                                    className="custom-button unassign-background"
                                    onClick={() => unAssignAsset(file)}
                                >
                                    <BanIcon
                                        style={{ marginRight: '0.25rem' }}
                                        height="1.25rem"
                                    />{' '}
                                    Unassign
                                </button>
                            </>
                        ) : (
                            <button
                                className="custom-button make-poster-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Audio
                            </button>
                        )}
                    </div>
                );
            case 'transcript':
                return (
                    <div>
                        {assignment.transcript_id === file.id ? (
                            <>
                                <button 
                                    className="custom-button unassign-background"
                                    onClick={() => unAssignAsset(file)}
                                >
                                    <BanIcon
                                        style={{ marginRight: '0.25rem' }}
                                        height="1.25rem"
                                    />{' '}
                                    Unassign
                                </button>
                            </>
                        ) : (
                            <button
                                className="custom-button make-poster-background"
                                onClick={() => assignAsset(file)}
                            >
                                <CheckIcon
                                    style={{ marginRight: '0.25rem' }}
                                    height="1.25rem"
                                />
                                Make Transcript
                            </button>
                        )}
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <section>
            <div className="flex justify-between items-center mb-4">
                <div className="font-bold">Primary Files</div>
                <button
                    className="button add-button p-2 mr-4 text-white"
                    onClick={showNewUploadModal}
                >
                    Upload New File
                </button>
            </div>
            <LoadingTable isLoading={isLoading} tableClass=" ">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>View</th>
                        <th colSpan={2}>Assignment</th>
                        <th>File Type</th>
                        <th>Source</th>
                        <th>Created</th>
                        <th>Duration</th>
                        <th>Size</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map((file) => (
                        <tr
                            key={`file-${file.id}`}
                            className={`${
                                [
                                    assignment.hls_id,
                                    assignment.audio_id,
                                    assignment.poster_id,
                                    assignment.transcript_id,
                                    assignment.video_id,
                                ].includes(file.id)
                                    ? 'highlighted-tr'
                                    : ''
                            }`}
                        >
                            <td>{file.id}</td>
                            <td
                                onClick={() => openResource(file)}
                                className="cursor-pointer text-center font-bold"
                            >
                                {thumbnail(file)}
                            </td>
                            <td colSpan={2}>{assignement(file)}</td>
                            <td>
                                <div className="font-bold">{file.slot}</div>
                                <div>{file.mime_type}</div>
                            </td>
                            <td>{file.parent_id}</td>
                            <td>
                                <DateField timeMillis={file.created_int} />
                            </td>
                            <td>
                                {file.duration ? msToMMSS(file.duration) : ''}
                            </td>
                            <td>{fileSize(file.file_size)}</td>
                            <td>
                                {![
                                    assignment.hls_id,
                                    assignment.audio_id,
                                    assignment.poster_id,
                                    assignment.transcript_id,
                                    assignment.video_id,
                                ].includes(file.id) && (
                                    <button
                                        className="button delete-button"
                                        onClick={() =>
                                            handleDeleteFile(
                                                file.id
                                            )
                                        }
                                    >
                                        Delete
                                    </button>
                                )}
                                    </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>

            <ResourceModal
                onClose={onClose}
                type={resourceType}
                visible={showModal}
                file={selectedFile}
            />
        </section>
    );
});

export default PEAssigmentFiles;
