import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import handleAuthCheck from 'store/sagas/workers/handleAuthCheck';

// ALL our network requests go inside a saga
function* ROUTE_CHANGE(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.LOGOUT,
            ACTION_TYPES.LOGIN,
            ACTION_TYPES.GET_USER_REQUEST,
            ACTION_TYPES.POST_EMAIL_REQUEST,
            ACTION_TYPES.POST_CODE_REQUEST,
        ],
        handleAuthCheck,
        apiUrl,
        ...params
    );
}

export default ROUTE_CHANGE;
