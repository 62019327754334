import ACTION_TYPES from 'constants/ACTION_TYPES';
import jwtDecode from 'jwt-decode';
import { delay, put } from 'redux-saga/effects';
import api from '../api/api';

function* handleUserResponse(json, apiUrl, refreshing, callback) {
    let servicesPermissions = [];
    const { jwt } = json;
    api.setToken(jwt);
    if (!refreshing) {
        yield put({
            type: ACTION_TYPES.GET_USER_RESPONSE,
            data: {
                user: json,
                services_permissions: servicesPermissions,
            },
        });
        callback && callback();
    }
    const decodedJwt = jwtDecode(jwt);
    const now = Date.now() / 1000;

    if (decodedJwt?.exp && new Date(decodedJwt?.exp).getTime() > now) {
        const refreshTime = (decodedJwt.exp - now) * 1000;
        yield delay(refreshTime - 20000);
        yield getUser(apiUrl, true);
    }
}

function* getUser(apiUrl, refreshing) {
    const json = yield api.get('/users/me', apiUrl);
    if (json) {
        yield handleUserResponse(json, apiUrl, refreshing);
    } else {
        yield put({
            type: ACTION_TYPES.GET_USER_RESPONSE,
            data: {
                user: null,
                services_permissions: [],
            },
        });
    }
}

function* postEmail(email, redirect, apiUrl) {
    const json = yield api.post(`/users/auth`, { email }, apiUrl);
    if (json?.sent) {
        redirect && redirect();
    } else {
        alert(json?.message);
    }
}

function* postAccessCode(auth_code, callback, apiUrl) {
    const json = yield api.post(`/users/verify`, { auth_code }, apiUrl);
    if (json?.message) {
        alert(json.message);
    } else if (json) {
        yield handleUserResponse(json, apiUrl, false, callback);
    }
}

function* logOut(apiUrl,callback){
    const json = yield api.get('/users/logout', apiUrl);
    if (json?.message) {
        localStorage.clear();
        if('caches' in window){
            caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });
        
            }
    }
}

function* handleAuthCheckWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_USER_REQUEST:
            yield getUser(apiUrl);
            break;
        case ACTION_TYPES.POST_EMAIL_REQUEST:
            yield postEmail(action.data?.email, action.data?.redirect, apiUrl);
            break;
        case ACTION_TYPES.POST_CODE_REQUEST:
            yield postAccessCode(
                action.data?.code,
                action.data?.callback,
                apiUrl
            );
            break;
        case ACTION_TYPES.LOGOUT:
            yield logOut(apiUrl,action.data?.callback,);   
            break;
        default:
            break;
    }
}

export default handleAuthCheckWorker;
