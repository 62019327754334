/* eslint-disable react-hooks/exhaustive-deps */
import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams,useNavigate } from 'react-router-dom';
import SingleFilter from '../../../components/SingleFilter';
import { MEETING_OPTIONS } from '../../../constants/MEETING_OPTIONS';
import moment from 'moment/moment';
import MeetingsDetails from './MeetingsDetails';

function mapStateToProps(state) {
    return {
        meetings: state.vhsMeetings.meetings,
        isLoadingMeetings: state.vhsMeetings.isLoadingMeetings,
        page: state.vhsMeetings.page,
        total: state.vhsMeetings.total,
        per_page: state.vhsMeetings.per_page,
        total_pages: state.vhsMeetings.total_pages,
        meeting_invitation: state.vhsMeetings.meeting_invitation,
        selectedLocationEmail: state.vhsMeetings.selectedLocationEmail,
        locationOptions: state.vhsLocations.locationOptions,
        locationFilter: state.vhsMeetings.locationFilter,
        meetingFilter: state.vhsMeetings.meetingFilter,
    };
}

const VHSMeetings = connect(mapStateToProps)(function (props) {
    const {
        per_page,
        page,
        total_pages,
        total,
        isLoadingMeetings,
        meeting_invitation,
        meetings,
        locationOptions,
        locationFilter,
        meetingFilter
    } = props;

    const {
        VHS_FETCH_LOCATION_MEETINGS_REQUEST,
        VHS_FETCH_MEETING_INVITE_REQUEST, VHS_SELECT_MEETING,
        VHS_SELECT_MEETINGS_LOCATION_FILTER,
        VHS_SELECT_MEETINGS_FILTER
    } = useActions();

    const { zoomID } = useParams();
    const navigate = useNavigate();

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState('');

    const onDetailSelection = (meeting) => {
        setSubtitle(null);
        setDetailComponent(null);
        VHS_SELECT_MEETING(meeting);
        if (meeting) {
            navigate(`/vhs/meetings/${meeting.id}`);
        } else {
            VHS_FETCH_LOCATION_MEETINGS_REQUEST({
                id: locationFilter,
                state: meetingFilter,
                per_page: per_page,
                page: page
            });
            navigate(`/vhs/meetings`);
        }
    };

    const onNextPage = () => {
        VHS_FETCH_LOCATION_MEETINGS_REQUEST({
            id: locationFilter,
            state: meetingFilter,
            per_page: per_page,
            page: page + 1
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_LOCATION_MEETINGS_REQUEST({
            id: locationFilter,
            state: meetingFilter,
            per_page: per_page,
            page: page - 1
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_LOCATION_MEETINGS_REQUEST({
            id: locationFilter,
            state: meetingFilter,
            per_page: per_page,
            page: pageSelected
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_LOCATION_MEETINGS_REQUEST({
            id: locationFilter,
            state: meetingFilter,
            per_page: perPage,
            page: page
        });
    };

    useEffect(() => {
        if (meeting_invitation) {
            setSubtitle(zoomID);
            setDetailComponent(<MeetingsDetails />);
        } else {
            setDetailComponent(null);
        }
    }, [meeting_invitation]);

    useEffect( () => {
        if (zoomID) {
            try {
                VHS_FETCH_MEETING_INVITE_REQUEST( zoomID );
            } catch (err) {
                console.log(err);
            }
        } else {
            VHS_SELECT_MEETING(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
    }, [zoomID]);

    useEffect(() => {
        VHS_FETCH_LOCATION_MEETINGS_REQUEST({
            id: locationFilter,
            state: meetingFilter,
            per_page: per_page,
            page: page
        });

    }, [locationFilter, meetingFilter, per_page, page]);

    const locationsDataTable = function () {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable
                    isLoading={isLoadingMeetings}
                    tableClass="table-fixed w-full text-left"
                >
                    <thead>
                        <tr>
                            <th>Meeting Name</th>
                            <th>Zoom ID</th>
                            <th>Start Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {meetings.map((meeting) => (
                            <tr key={`meetings-${meeting.id}`}>
                                <td>
                                    <SelectableField
                                        text={meeting.topic}
                                        action={() => {
                                            if (onDetailSelection) {
                                                onDetailSelection(meeting);
                                            }
                                            navigate(`/vhs/meetings/${meeting.id}`);
                                        }}
                                    />
                                </td>
                                <td>{meeting.id}</td>
                                <td>{moment(meeting.start_time).format('ll [@] LT')}</td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                />
            </section>
        );
    };

    function filters() {
        return [
            <SingleFilter
                options={[
                    ...locationOptions.filter(options => options.label !== "No Location"),
                ]}
                selectedValue={locationFilter}
                onSelect={(value) =>
                    VHS_SELECT_MEETINGS_LOCATION_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    ...MEETING_OPTIONS,
                ]}
                selectedValue={meetingFilter}
                onSelect={(value) =>
                    VHS_SELECT_MEETINGS_FILTER(value)
                }
            />,
        ];
    }

    return (
        <section>
            <InformationTab
                sectionName="Meetings"
                customFilters={filters()}
                showPrograms={false}
                onDetailSelected={onDetailSelection}
                dataTable={locationsDataTable()}
                detailComponent={detailComponent}
                subsectionName={subtitle}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
            />
        </section>
    );
});

export default VHSMeetings;
