import LocationEdit from './LocationEdit';
import LocationOverview from './LocationOverview';
import LocationsHistory from './LocationHistory';
import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useState } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedLocation: state.vhsLocations.selectedLocation,
    };
}

const LocationDetails = connect(mapStateToProps)(function (props) {
    const { selectedLocation } = props;
    const { VHS_UPDATE_LOCATION_REQUEST } = useActions();

    const [selectedTab, setSelectedTab] = useState(0);

    const onConfirm = (updatedLocation) => {
        VHS_UPDATE_LOCATION_REQUEST({...updatedLocation});
        setSelectedTab(0);
    };

    return (
        <section>
            <Tabs
                sectionNames={['Overview', 'Settings', 'History']}
                sectionContents={[
                    <LocationOverview location={selectedLocation} />,
                    <LocationEdit location={selectedLocation} onConfirm={onConfirm} />,
                    <LocationsHistory location={selectedLocation} />,
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
});

export default LocationDetails;
