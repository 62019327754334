import InformationTab from 'components/InformationTab';
import TableNavigator from 'components/TableNavigator';
import useActions from 'hooks/useActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import ConversionsDetail from './ConversionsDetail';
import ConversionsTable from './ConversionsTable';
import { useNavigate } from 'react-router-dom';
import TableResults from 'components/TableResults';
import ConversionsNew from './ConversionsNew';

function mapStateToProps(state) {
    return {
        conversions: state.conversions.list,
        isLoading: state.conversions.isLoadingConversions,
        selectedConversion: state.conversions.selectedConversion,
        page: state.conversions.page,
        per_page: state.conversions.per_page,
        total_pages: state.conversions.total_pages,
        total: state.conversions.total,
        selectedProgram: state.conversions.program,
        programOptions: state.programs.programOptions,
        brandOptions: state.brands.brandOptions,
        selectedPeriod: state.conversions.selectedPeriod,
    };
}

const Conversions = connect(mapStateToProps)(function (props) {
    const [detailComponent, setDetailComponent] = useState(null);
    const [programsSet, setProgramsSet] = useState({});
    const [brandsSet, setBrandsSet] = useState({});
    const [subtitle, setSubtitle] = useState('');

    const {
        page,
        conversions,
        selectedProgram,
        per_page,
        total_pages,
        selectedConversion,
        programOptions = [],
        brandOptions = [],
        selectedPeriod,
        total,
        isLoading,
    } = props;

    const {
        FETCH_CONVERSIONS_REQUEST,
        FETCH_CONVERSION_REQUEST,
        SELECT_CONVERSIONS_PROGRAM,
        SELECT_CONVERSION,
        UPDATE_CONVERSIONS_REQUEST,
        FETCH_CONVERSIONS_CHART_REQUEST,
        SELECT_CONVERSIONS_PERIOD,
    } = useActions();

    const { id } = useParams();
    const navigate = useNavigate();

    const onUpdateConversion = (conversion, newStatus) => {
        UPDATE_CONVERSIONS_REQUEST({ conversion, newStatus });
    };

    const onAdd = () => {
        setSubtitle('Add New');
        setDetailComponent(<ConversionsNew />);
    };

    const onDetailSelection = (conversion) => {
        setDetailComponent(null);
        setSubtitle(null);
        SELECT_CONVERSION(conversion);
        if (conversion) {
            navigate(`/referrals/conversions/${conversion.id}`);
        } else {
            FETCH_CONVERSIONS_REQUEST({ per_page, page, selectedProgram });
            navigate(`/referrals/conversions`);
        }
    };

    const onNextPage = () => {
        FETCH_CONVERSIONS_REQUEST({
            per_page,
            page: page + 1,
            selectedProgram,
        });
    };

    const onPrevPage = () => {
        FETCH_CONVERSIONS_REQUEST({
            per_page,
            page: page - 1,
            selectedProgram,
        });
    };

    const onPageSelect = (pageSelected) => {
        FETCH_CONVERSIONS_REQUEST({
            per_page,
            page: pageSelected,
            selectedProgram,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        FETCH_CONVERSIONS_REQUEST({ per_page: perPage, selectedProgram });
    };

    const onPeriodSelected = ({ period }) => {
        SELECT_CONVERSIONS_PERIOD({ selectedPeriod: period });
        FETCH_CONVERSIONS_CHART_REQUEST({
            selectedProgram,
            selectedPeriod: period,
        });
    };

    const onProgramSelected = (programId) => {
        SELECT_CONVERSIONS_PROGRAM(programId);
        FETCH_CONVERSIONS_CHART_REQUEST({
            selectedPeriod,
            selectedProgram: programId,
        });
        FETCH_CONVERSIONS_REQUEST({ selectedProgram: programId });
    };

    useEffect(() => {
        const prOpts = {};
        programOptions.forEach((element) => {
            prOpts[element.value] = element.label;
        });
        setProgramsSet(prOpts);
    }, [programOptions]);

    useEffect(() => {
        const brOpts = {};
        brandOptions.forEach((element) => {
            brOpts[element.value] = element.label;
        });
        setBrandsSet(brOpts);
    }, [brandOptions]);

    useEffect(() => {
        if (selectedConversion) {
            const element = (
                <ConversionsDetail
                    onUpdateConversion={onUpdateConversion}
                    conversion={selectedConversion}
                    programsSet={programsSet}
                    brandsSet={brandsSet}
                />
            );
            setSubtitle(selectedConversion?.member?.email);
            setDetailComponent(element);
        } else {
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedConversion]);

    useEffect(() => {
        if (id) {
            try {
                FETCH_CONVERSION_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            SELECT_CONVERSION(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversions, id]);

    useEffect(() => {
        FETCH_CONVERSIONS_CHART_REQUEST({ selectedProgram, selectedPeriod });
        FETCH_CONVERSIONS_REQUEST({ per_page, selectedProgram });
        return () => {
            SELECT_CONVERSION(null);
            setDetailComponent(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section>
            <InformationTab
                sectionName="Conversions"
                subsectionName={subtitle}
                onDetailSelected={onDetailSelection}
                hasChart
                detailComponent={detailComponent}
                per_page={per_page}
                onPerPageSelected={onPerPageSelected}
                onProgramSelected={onProgramSelected}
                selectedProgram={selectedProgram}
                onPeriodSelected={onPeriodSelected}
                selectedPeriod={selectedPeriod}
                dataTable={
                    <section>
                        <TableResults results={total} addTitle="Add New Conversion" onAdd={onAdd}/>
                        <ConversionsTable
                            onDetailSelected={onDetailSelection}
                            conversions={conversions}
                            onUpdateConversion={onUpdateConversion}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                            currentPage={page}
                            totalPages={total_pages}
                        />
                    </section>
                }
            />
        </section>
    );
});

export default Conversions;
