import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import handleAuthCheck from 'store/sagas/workers/handleAuthCheck';

// ALL our network requests go inside a saga
function* ROUTE_CHANGE(apiUrl, ...params) {
    yield takeEvery(
        ACTION_TYPES.ROUTE_CHANGE,
        handleAuthCheck,
        apiUrl,
        ...params
    );
}

export default ROUTE_CHANGE;
