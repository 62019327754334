import DetailOverview from 'components/DetailOverview';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        meeting: state.vhsMeetings.selectedMeeting,
        meeting_invitation: state.vhsMeetings.meeting_invitation
    };
}
const MeetingOverview = connect(mapStateToProps)(function({ meeting, meeting_invitation }) {
    return (
        <DetailOverview
            fields={[
                { name: 'Name', value: meeting.topic },
                { name: 'Zoom ID', value: meeting.id },
                { name: 'Duration', value: `${meeting.duration} minutes` },
                { name: 'Start', value: meeting.start_time, type: 'date' },
                { name: 'Created', value: meeting.created_at, type: 'date' },
                { name: 'Timezone', value: meeting.timezone },
                { name: 'Join URL', value: meeting.join_url, type: 'new_tab', url: meeting.join_url },
                { name: 'Meeting Invite', value: meeting_invitation }
            ]}
        />
    );
});

export default MeetingOverview;
