import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    lastMonthChartData: [],
    recentEpisodes: [],
    createZoomAssetBatchResult: null,
    createZoomAssetResult: null,
    createEpisodeFromClass: null,
};

function VHSReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_CREATE_ASSET_FROM_ZOOM_REQUEST: {
            return {
                ...state,
                createZoomAssetResult: null
            };
        }
        case ACTION_TYPES.VHS_CREATE_EPISODE_FROM_CLASS_REQUEST: {
            return {
                ...state,
                createEpisodeFromClass: null
            };
        }
        case ACTION_TYPES.VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_REQUEST: {
            return {
                ...state,
                createZoomAssetBatchResult: null
            };
        }
        case ACTION_TYPES.VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_RESPONSE: {
            return {
                ...state,
                createZoomAssetBatchResult: action.data.message
            };
        }
        case ACTION_TYPES.VHS_CREATE_EPISODE_FROM_CLASS_RESPONSE: {
            return {
                ...state,
                createEpisodeFromClass: action.data.message
            };
        }
        case ACTION_TYPES.VHS_CREATE_ASSET_FROM_ZOOM_RESPONSE: {
            return {
                ...state,
                createZoomAssetResult: action.data.message
            };
        }
        case ACTION_TYPES.VHS_FETCH_DASHBOARD_CHART_DATA_RESPONSE: {
            return {
                ...state,
                lastMonthChartData: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_RECENT_EPISODES_RESPONSE: {
            return {
                ...state,
                recentEpisodes: action.data,
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSReducer;
