import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    series: [],
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    isLoadingSeries: false,
    selectedSeries: null,
    assignments: [],
    assignments_page: 1,
    assignments_per_page: 10,
    assignments_total: 0,
    assignments_total_pages: 1,
    assignments_is_loading: false,
};

function PESeriesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_SERIES_REQUEST: {
            return {
                ...state,
                isLoadingSeries: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_SERIES_RESPONSE: {
            return {
                ...state,
                series: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
                isLoadingSeries: false,
            };
        }
        case ACTION_TYPES.PE_DELETE_SERIES_ASSIGNMENT_REQUEST:
        case ACTION_TYPES.PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST:
        case ACTION_TYPES.PE_SERIES_ASSIGNMENTS_REQUEST: {
            return {
                ...state,
                assignments_is_loading: true,
            };
        }
        case ACTION_TYPES.PE_SERIES_ASSIGNMENTS_RESPONSE: {
            return {
                ...state,
                assignments: action.data.list,
                assignments_page: action.data.page,
                assignments_per_page: action.data.per_page,
                assignments_total: action.data.total,
                assignments_total_pages: action.data.total_pages,
                assignments_is_loading: false,
            };
        }
        case ACTION_TYPES.PE_GET_SERIES_RESPONSE:
        case ACTION_TYPES.PE_UPDATE_SERIES_RESPONSE:
        case ACTION_TYPES.PE_POST_SERIES_RESPONSE:
        case ACTION_TYPES.PE_SELECT_SERIES:
        case ACTION_TYPES.PE_CLONE_SERIES_RESPONSE: {
            return {
                ...state,
                selectedSeries: action.data,
            };
        }
        default:
            return state;
    }
}

export default PESeriesReducer;
