import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PEPhaseDetails from './PhaseDetails';
import PEPhasesNew from './PhasesNew';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


const { default: InformationTab } = require('components/InformationTab');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        phases: state.PEPhases.phases,
        page: state.PEPhases.page,
        per_page: state.PEPhases.per_page,
        total: state.PEPhases.total,
        total_pages: state.PEPhases.total_pages,
        selectedPhase: state.PEPhases.selectedPhase,
        isLoading: state.PEPhases.isLoadingPhases,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEPhases = connect(mapStateToProps)(function (props) {
    const {
        phases,
        page,
        per_page,
        total,
        total_pages,
        selectedPhase,
        isLoading,
        selectedRegionFilter,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const { id } = useParams();
    const { term: searchTerm } = useQueryList(['term']);

    const navigate = useNavigate();

    const {
        PE_FETCH_PHASES_REQUEST,
        PE_SELECT_PHASE,
        PE_GET_PHASE_REQUEST,
        PE_DELETE_PHASE_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const onAdd = () => {
        setSubtitle('Add New Phase');
        setDetailComponent(<PEPhasesNew />);
    };

    function onNext() {
        PE_FETCH_PHASES_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
        });
    }

    function onPrev() {
        PE_FETCH_PHASES_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_PHASES_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_PHASES_REQUEST({
            per_page: perPage,
            searchTerm,
        });
    }

    function onDeleteAction() {
        PE_DELETE_PHASE_REQUEST({
            phaseId: selectedPhase?.id,
            page,
            searchTerm,
            perPage: per_page,
        });
        navigate('/program-engine/phases');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Phase?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function onDetailSelection(phase) {
        setSubtitle(null);
        setDetailComponent(null);
        PE_SELECT_PHASE(phase);
        setShowDelete(true);
        if (phase) {
            navigate(`/program-engine/phases/${phase.id}`);
        } else {
            PE_FETCH_PHASES_REQUEST({
                page,
                per_page,
                searchTerm,
            });
            navigate(`/program-engine/phases`);
        }
    }

    useEffect(() => {
        if (selectedPhase) {
            setSubtitle(selectedPhase.name);
            setDetailComponent(<PEPhaseDetails />);
            setShowDelete(true);
        } else {
            setShowDelete(false);
            setDetailComponent(null);
        }
    }, [selectedPhase]);

    useEffect(() => {
        if (id) {
            try {
                PE_GET_PHASE_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            PE_SELECT_PHASE(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        PE_FETCH_PHASES_REQUEST({ searchTerm });
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Phase" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Programs</th>
                            <th>Sequences</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {phases.map((phase) => (
                            <tr key={`phase-${phase.id}`}>
                                <td>
                                    <SelectableField
                                        url={`/program-engine/phases/${phase.id}`}
                                        text={phase.name}
                                    />
                                </td>
                                <td>{phase.programs}</td>
                                <td>{phase.sequences}</td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/phases/${phase.id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <InformationTab
            showDelete={showDelete}
            showSearchField={true}
            showPrograms={false}
            sectionName="Phases"
            deleteText= "Phase"
            dataTable={<DataTable />}
            perPage={per_page}
            detailComponent={detailComponent}
            subsectionName={subtitle}
            onPerPageSelected={onPerPageSelected}
            onDetailSelected={onDetailSelection}
            onDeleteDetail={onDeleteDetail}
        />
    );
});

export default PEPhases;
