import Tabs from 'components/Tabs';
import PECategoryEdit from '../CategoryEdit';
import PECategoryOverview from '../CategoryOverview';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        category: state.PECategories.selectedCategory,
    };
}

const PECategoryDetails = connect(mapStateToProps)(function (props) {
    const { category } = props;

    return (
        <Tabs
            sectionNames={['Overview', 'Settings']}
            sectionContents={[<PECategoryOverview />, <PECategoryEdit category={category} />]}
        />
    );
});

export default PECategoryDetails;
