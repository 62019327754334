const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsSeriesWorker } = require('../workers/vhsSeriesWorker');

function* VHS_SERIES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_FETCH_SERIES_OPTIONS_REQUEST,
            ACTION_TYPES.VHS_GET_SERIES_REQUEST,
            ACTION_TYPES.VHS_FETCH_SERIES_REQUEST,
            ACTION_TYPES.VHS_POST_SERIES_REQUEST,
            ACTION_TYPES.VHS_DELETE_SERIES_REQUEST,
            ACTION_TYPES.VHS_UPDATE_SERIES_REQUEST,
            ACTION_TYPES.VHS_FETCH_SERIES_EPISODES_REQUEST,
            ACTION_TYPES.VHS_FETCH_SERIES_ACTIONS_REQUEST,
            ACTION_TYPES.VHS_FETCH_SERIES_ACTIVITY_CHART_REQUEST,
            ACTION_TYPES.VHS_FETCH_SERIES_HISTORY_REQUEST,
        ],
        vhsSeriesWorker,
        apiUrl,
        ...params
    );
}

export default VHS_SERIES_REQUEST;
