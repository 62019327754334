import { useState } from 'react';
import { connect } from 'react-redux';
import useActions from 'hooks/useActions';

function mapStateToProps(state) {
    return {
        config: state.admins.config,
        user: state.user.user,
    };
}

const PEMatrixEdit = connect(mapStateToProps)(function (props) {
    const { config, user, matrix } = props;

    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const [name, setName] = useState(matrix?.name);
    const [region, setRegion] = useState(matrix?.region_ident);

    const { PE_UPDATE_MATRIX_REQUEST } = useActions();

    function saveChanges(event) {
        event.preventDefault();
        PE_UPDATE_MATRIX_REQUEST({
            id: matrix.id,
            name,
            region_ident: region,
        });
    }

    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    name="name"
                                    value={name}
                                    onChange={(event) =>
                                        setName(event.target.value)
                                    }
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <select
                                    name="region_ident"
                                    value={region}
                                    onChange={(event) =>
                                        setRegion(event.target.value)
                                    }
                                >
                                    {regionsToShow.map((reg) => (
                                        <option
                                            key={`matrix-${reg}`}
                                            value={reg}
                                        >
                                            {reg}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    {
                    (
                        name !== matrix.name ||
                        region !== matrix.region_ident
                    )
                    && (
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Save Changes
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default PEMatrixEdit;
