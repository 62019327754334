import useActions from 'hooks/useActions';
import EditableSection from '../EditableSection';

function LandingPage({ program, isSaving }) {
    const { UPDATE_PROGRAM_REQUEST } = useActions();

    const onConfirmSave = (field, value) => {
        UPDATE_PROGRAM_REQUEST({ id: program.id, [field]: value });
    };

    return (
        <section>
            <div className="font-bold text-xl">Landing Page URL</div>
            <EditableSection
                className="mb-4"
                title="This is the page that the referral link will end up on. Query string params will be added to it."
                value={program.redirect_url}
                onSave={(fieldValue) =>
                    onConfirmSave('redirect_url', fieldValue)
                }
                isSaving={isSaving === 'redirect_url'}
            />
            <div className="font-bold text-xl">Landing Page Javascript</div>
            <EditableSection
                className="mb-4"
                title="This script will run on the landing page. It can be used to show the discounts applied by the program."
                value={program.landing_page_script}
                isArea
                onSave={(fieldValue) =>
                    onConfirmSave('landing_page_script', fieldValue)
                }
                isSaving={isSaving === 'landing_page_script'}
                placeholder="// Javascript here"
                fieldClass="big-area"
            />
        </section>
    );
}

export default LandingPage;
