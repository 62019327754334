import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import peSeriesWorker from '../workers/peSeriesWorker';

function* PE_SERIES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_SERIES_REQUEST,
            ACTION_TYPES.PE_GET_SERIES_REQUEST,
            ACTION_TYPES.PE_POST_SERIES_REQUEST,
            ACTION_TYPES.PE_UPDATE_SERIES_REQUEST,
            ACTION_TYPES.PE_DELETE_SERIES_REQUEST,
            ACTION_TYPES.PE_CLONE_SERIES_REQUEST,
            ACTION_TYPES.PE_SERIES_ASSIGNMENTS_REQUEST,
            ACTION_TYPES.PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST,
            ACTION_TYPES.PE_DELETE_SERIES_ASSIGNMENT_REQUEST,
            ACTION_TYPES.PE_CHANGE_SERIES_ASSIGNMENT_POSITION_REQUEST,
        ],
        peSeriesWorker,
        apiUrl,
        ...params
    );
}

export default PE_SERIES_REQUEST;
