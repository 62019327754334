import PEMatrixEdit from "../MatrixEdit";
import PEMatrixOverview from "../MatrixOverview";

const { default: Tabs } = require("components/Tabs")
const { connect } = require("react-redux")

function mapStateToProps(state) {
    return {
        matrix: state.PEMatrices.selectedMatrix,
    }
}

const PEMatrixDetails = connect(mapStateToProps)(function(props) {
    const { matrix } = props;


    return <Tabs sectionNames={['Overview', 'Settings']} sectionContents={[<PEMatrixOverview />, <PEMatrixEdit matrix={matrix} />]} />
})

export default PEMatrixDetails;
