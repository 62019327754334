import RouteWrapper from 'components/RouteWrapper';
import useActions from 'hooks/useActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';

import AdminUsers from './AdminUsers';
import Dashboard from './Dashboard';
import './index.css';
import Login from './Login';
import Logout from './Logout';

import ProgramEngine from './ProgramEngine';
import PEAssignments from './ProgramEngine/Assignments';
import PECategories from './ProgramEngine/Categories';
import PEMatrices from './ProgramEngine/Matrices';
import PEMembers from './ProgramEngine/Members';
import PEPrograms from './ProgramEngine/Programs';
import PEPhases from './ProgramEngine/Phases';
import PESequences from './ProgramEngine/Sequences';
import PESeries from './ProgramEngine/Series';
import PETags from './ProgramEngine/Tags';
import PEEditTagGroup from './ProgramEngine/Tags/EditTagGroup';
import PENewTagGroup from './ProgramEngine/Tags/NewTagGroup';
import PEEditTag from './ProgramEngine/Tags/EditTag';
import PENewTag from './ProgramEngine/Tags/NewTag';

import Referrals from './Referrals';
import ReferralsConversions from './Referrals/Conversions';
import ReferralsMembers from './Referrals/Members';
import ReferralsPrograms from './Referrals/Programs';
import ReferralsViews from './Referrals/Views';

import VHS from './vhs';
import VHSAdminControls from './vhs/Admin';
import VHSAnalytics from './vhs/Analytics';
import VHSLocations from './vhs/Locations';
import VHSEpisodes from './vhs/Episodes';
import VHSMeetings from './vhs/Meetings';
import VHSPrograms from './vhs/Programs';
import VHSInstructors from './vhs/Instructors';
import VHSMembers from './vhs/Members';
import VHSSeries from './vhs/Series';
import VHSTags from './vhs/Tags';
import VHSEditTag from './vhs/Tags/EditTag';
import VHSEditTagGroup from './vhs/Tags/EditTagGroup';
import VHSNewTag from './vhs/Tags/NewTag';
import NewTagGroup from './vhs/Tags/NewTagGroup';
import CustomModal from 'components/Modal';
import LandingPage from './LandingPage';
import Authenticate from './Login/Authenticate';

import CSModerator from './Community/Moderator';
import CsArticles from './Community/Articles';
import CSQuestions from './Community/Questions';
import CSQuestionAnswers from './Community/Questions/QuestionAnswers';
import ArticleComments from './Community/Articles/ArticleComments';
import CSTags from './Community/Tags';
import CSEditTagGroup from './Community/Tags/EditTagGroup';
import CSNewTagGroup from './Community/Tags/NewTagGroup';
import CSEditTag from './Community/Tags/EditTag';
import CSNewTag from './Community/Tags/NewTag';
import CSFlags from './Community/Flags';
import CSMembers from './Community/Members';


function Home() {
    return(
        <Navigate to={'referrals'} replace/>
    )
}

function Community() {
    const location = useLocation();
    let relPath = "community/";
    if(location.pathname.split('/').at(-1) === 'community'){
       relPath = "moderator";
    }
    else{
        relPath+="moderator";
    }
    return(
        <Navigate to={relPath} replace/>
    )
}

const components = {
    Login,
    LandingPage,
    Referrals,
    ReferralsViews,
    ReferralsConversions,
    ReferralsMembers,
    ReferralsPrograms,
    AdminUsers,

    VHS,
    VHSAdminControls,
    VHSAnalytics,
    VHSEpisodes,
    VHSSeries,
    VHSInstructors,
    VHSTags,
    VHSEditTagGroup,
    VHSEditTag,
    VHSNewTag,
    NewTagGroup,
    VHSMembers,
    VHSLocations,
    VHSMeetings,
    VHSPrograms,

    ProgramEngine,
    PEAssignments,
    PECategories,
    PEMatrices,
    PEMembers,
    PEPrograms,
    PEPhases,
    PESequences,
    PESeries,
    PETags,
    PEEditTagGroup,
    PENewTagGroup,
    PEEditTag,
    PENewTag,

    Community,
    CSModerator,
    CsArticles,
    CSQuestions,
    CSQuestionAnswers,
    ArticleComments,
    CSTags,
    CSEditTagGroup,
    CSNewTagGroup,
    CSEditTag,
    CSNewTag,
    CSFlags,
    CSMembers,

    Logout,
    Dashboard,
    Home,
};

const initialPages = [
    { path: '/', component: 'Home', service: 'ref' },
    //{ path: '/', component: 'Login', hideDrawer: true },
    //{ path: '/login', component: 'Login', hideDrawer: true },

    { path: '/referrals', component: 'Referrals', service: 'ref' },
    { path: '/referrals/views', component: 'ReferralsViews', service: 'ref' },
    {
        path: '/referrals/views/:id',
        component: 'ReferralsViews',
        service: 'ref',
    },
    {
        path: '/referrals/conversions',
        component: 'ReferralsConversions',
        service: 'ref',
    },
    { path: '/referrals/conversions/:id', component: 'ReferralsConversions' },
    {
        path: '/referrals/members',
        component: 'ReferralsMembers',
        service: 'ref',
    },
    {
        path: '/referrals/members/:id/*',
        component: 'ReferralsMembers',
        service: 'ref',
    },
    {
        path: '/referrals/programs',
        component: 'ReferralsPrograms',
        service: 'ref',
    },
    {
        path: '/referrals/programs/:id/*',
        component: 'ReferralsPrograms',
        service: 'ref',
    },

    { path: '/admins', component: 'AdminUsers', service: 'usr' },
    { path: '/admins/:id/*', component: 'AdminUsers', service: 'usr' },

    { path: '/vhs', component: 'VHS', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/admin', component: 'VHSAdminControls', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/analytics', component: 'VHSAnalytics', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/analytics/:id', component: 'VHSAnalytics', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/episodes', component: 'VHSEpisodes', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/episodes/:id/*', component: 'VHSEpisodes', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/programs', component: 'VHSPrograms', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/programs/:id/*', component: 'VHSPrograms', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/series', component: 'VHSSeries', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/series/:id/*', component: 'VHSSeries', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/instructors', component: 'VHSInstructors', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/meetings', component: 'VHSMeetings', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/meetings/:zoomID/*', component: 'VHSMeetings', service: SERVICE_TYPES.VIDEO_HOSTING },

    {
        path: '/vhs/instructors/:id/*',
        component: 'VHSInstructors',
        service: SERVICE_TYPES.VIDEO_HOSTING,
    },
    { path: '/vhs/tags', component: 'VHSTags', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/tags/:id', component: 'VHSTags', service: SERVICE_TYPES.VIDEO_HOSTING },
    {
        path: '/vhs/tags/group/:id',
        component: 'VHSEditTagGroup',
        service: SERVICE_TYPES.VIDEO_HOSTING,
    },
    { path: '/vhs/tags/group/new', component: 'NewTagGroup', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/tags/tag/:id', component: 'VHSEditTag', service: SERVICE_TYPES.VIDEO_HOSTING },
    { path: '/vhs/tags/tag/new/:id', component: 'VHSNewTag', service: SERVICE_TYPES.VIDEO_HOSTING },
    {
        path: '/vhs/members',
        component: 'VHSMembers',
        service: SERVICE_TYPES.VIDEO_HOSTING,
    },
    { path: '/vhs/members/:id/*', component: 'VHSMembers' },
    { path: '/vhs/locations', component: 'VHSLocations', service: SERVICE_TYPES.VIDEO_HOSTING},
    { path: '/vhs/locations/:id/*', component: 'VHSLocations', service: SERVICE_TYPES.VIDEO_HOSTING },

    { path: '/program-engine', component: 'ProgramEngine', service: 'pe' },
    {
        path: '/program-engine/categories',
        component: 'PECategories',
        service: 'pe',
    },
    {
        path: '/program-engine/categories/:categoryId/assignments/*',
        component: 'PEAssignments',
        service: 'pe',
    },
    {
        path: '/program-engine/categories/:categoryId/assignments/:id/*',
        component: 'PEAssignments',
        service: 'pe',
    },
    {
        path: '/program-engine/categories/:categoryId/assignments/new',
        component: 'PEAssignments',
        service: 'pe',
    },
    {
        path: '/program-engine/categories/:id/*',
        component: 'PECategories',
        service: 'pe',
    },
    {
        path: '/program-engine/matrices',
        component: 'PEMatrices',
        service: 'pe',
    },
    {
        path: '/program-engine/matrices/:id/*',
        component: 'PEMatrices',
        service: 'pe',
    },
    {
        path: '/program-engine/sequences',
        component: 'PESequences',
        service: 'pe',
    },
    {
        path: '/program-engine/sequences/:id/*',
        component: 'PESequences',
        service: 'pe',
    },
    { path: '/program-engine/members', component: 'PEMembers', service: 'pe' },
    {
        path: '/program-engine/members/:id/*',
        component: 'PEMembers',
        service: 'pe',
    },
    { path: '/program-engine/phases', component: 'PEPhases', service: 'pe' },
    {
        path: '/program-engine/phases/:id/*',
        component: 'PEPhases',
        service: 'pe',
    },
    {
        path: '/program-engine/programs',
        component: 'PEPrograms',
        service: 'pe',
    },
    {
        path: '/program-engine/programs/:id/*',
        component: 'PEPrograms',
        service: 'pe',
    },
    { path: '/program-engine/series', component: 'PESeries', service: 'pe' },
    {
        path: '/program-engine/series/:id/*',
        component: 'PESeries',
        service: 'pe',
    },
    { path: '/program-engine/tags', component: 'PETags', service: 'pe' },
    { path: '/program-engine/tags/:id', component: 'PETags', service: 'pe' },
    {
        path: '/program-engine/tags/group/:id',
        component: 'PEEditTagGroup',
        service: 'pe',
    },
    {
        path: '/program-engine/tags/group/new',
        component: 'PENewTagGroup',
        service: 'pe',
    },
    {
        path: '/program-engine/tags/tag/:id',
        component: 'PEEditTag',
        service: 'pe',
    },
    {
        path: '/program-engine/tags/tag/new/:id',
        component: 'PENewTag',
        service: 'pe',
    },
    { path: '/community', component: 'Community', service: 'cs' },
    { path: '/community/moderator', component: 'CSModerator', service: 'cs' },
    { path: '/community/articles', component: 'CsArticles', service: 'cs' },
    { path: '/community/articles/:id/*', component: 'CsArticles', service: 'cs' },
    { path: '/community/articles/:id/comments', component: 'CsArticles', service: 'cs' },
    { path: '/community/articles/:id/comments/new', component: 'ArticleComments', service: 'cs' },
    { path: '/community/articles/:id/comments/:commentId/*', component: 'ArticleComments', service: 'cs' },
    { path: '/community/questions', component: 'CSQuestions', service: 'cs' },
    { path: '/community/questions/:id/*', component: 'CSQuestions', service: 'cs' },
    { path: '/community/questions/:id/answers', component: 'CSQuestions', service: 'cs' },
    { path: '/community/questions/:id/answers/new', component: 'CSQuestionAnswers', service: 'cs' },
    { path: '/community/questions/:id/answers/:answerId/*', component: 'CSQuestionAnswers', service: 'cs' },
    { path: '/community/tags', component: 'CSTags', service: 'cs' },
    { path: '/community/tags/:id', component: 'CSTags', service: 'cs' },
    { path: '/community/flags', component: 'CSFlags', service: 'cs' },
    { path: '/community/flags/:id', component: 'CSFlags', service: 'cs' },
    { path: '/community/members', component: 'CSMembers', service: 'cs' },
    {
        path: '/community/members/:id/*',
        component: 'CSMembers',
        service: 'cs',
    },
    {
        path: '/community/tags/group/:id',
        component: 'CSEditTagGroup',
        service: 'cs',
    },
    {
        path: '/community/tags/group/new',
        component: 'CSNewTagGroup',
        service: 'cs',
    },
    {
        path: '/community/tags/tag/:id',
        component: 'CSEditTag',
        service: 'cs',
    },
    {
        path: '/community/tags/tag/new/:id',
        component: 'CSNewTag',
        service: 'cs',
    },

    { path: '/logout', component: 'Logout' },
    //{ path: '/*', component: 'LandingPage', hideDrawer: true },
];

function mapStateToProps(state) {
    return {
        user: state.user.user,
        redirect: state.user.redirect,
    };
}
const App = connect(mapStateToProps)(function (props) {
    const { user } = props;
    const [pages, setPages] = useState(initialPages);

    const {
        VHS_FETCH_ALL_BRANDS_REQUEST,
        VHS_FETCH_ALL_LOCATION_REQUEST,
        VHS_FETCH_ALL_PROGRAMS_REQUEST,
        FETCH_ALL_BRANDS_REQUEST,
        FETCH_ALL_PROGRAMS_REQUEST,
        VHS_FETCH_ALL_TAG_GROUPS_REQUEST,
        VHS_FETCH_INSTRUCTOR_OPTIONS_REQUEST,
        VHS_FETCH_SERIES_OPTIONS_REQUEST,
        VHS_FETCH_MEMBER_PERMISSIONS_REQUEST,
        PE_FETCH_BRANDS_OPTIONS_REQUEST,
        GET_USER_REQUEST,
        PE_GET_ASSIGNMENT_CONFIG_REQUEST,
        PE_FETCH_ALL_CATEGORIES_REQUEST,
        PE_FETCH_ASSIGNMENTS_TAG_GROUPS_REQUEST,
        PE_FETCH_PROGRAMS_TAG_GROUPS_REQUEST,
        PE_FETCH_TAG_GROUPS_REQUEST,
        PE_FETCH_ALL_TAG_GROUPS_REQUEST,
        CS_FETCH_ALL_TAG_GROUPS_REQUEST,
        CS_FETCH_TAG_GROUPS_REQUEST,
        CS_FETCH_EXPERT_COACHES_REQUEST,
        FETCH_ADMIN_CONFIG_REQUEST,
    } = useActions();

    useEffect(() => {
        const load = () => {
            GET_USER_REQUEST();
            FETCH_ADMIN_CONFIG_REQUEST();
        };
        load();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        function loadPermissions() {
            if (!user?.permissions?.services) {
                return;
            }
            if (
                user.permissions.services.includes('all') ||
                user.permissions.services.includes('ref')
            ) {
                FETCH_ALL_BRANDS_REQUEST();
                FETCH_ALL_PROGRAMS_REQUEST();
            }
            if (
                user.permissions.services.includes('all') ||
                user.permissions.services.includes(SERVICE_TYPES.VIDEO_HOSTING)
            ) {
                VHS_FETCH_ALL_BRANDS_REQUEST();
                VHS_FETCH_ALL_LOCATION_REQUEST();
                VHS_FETCH_ALL_PROGRAMS_REQUEST();
                VHS_FETCH_ALL_TAG_GROUPS_REQUEST();
                VHS_FETCH_INSTRUCTOR_OPTIONS_REQUEST();
                VHS_FETCH_SERIES_OPTIONS_REQUEST();
                VHS_FETCH_MEMBER_PERMISSIONS_REQUEST();
            }
            if (
                user.permissions.services.includes('all') ||
                user.permissions.services.includes('pe')
            ) {
                PE_FETCH_BRANDS_OPTIONS_REQUEST();
                PE_GET_ASSIGNMENT_CONFIG_REQUEST();
                PE_FETCH_ALL_CATEGORIES_REQUEST();
                PE_FETCH_ASSIGNMENTS_TAG_GROUPS_REQUEST();
                PE_FETCH_PROGRAMS_TAG_GROUPS_REQUEST();
                PE_FETCH_TAG_GROUPS_REQUEST();
                PE_FETCH_ALL_TAG_GROUPS_REQUEST();
            }
            if (
                user.permissions.services.includes('all') ||
                user.permissions.services.includes('cs')
            ) {
                CS_FETCH_TAG_GROUPS_REQUEST();
                CS_FETCH_ALL_TAG_GROUPS_REQUEST();
                CS_FETCH_EXPERT_COACHES_REQUEST();
            }
            if (
                !user.permissions.services.includes('all') &&
                !user.permissions.services.includes('ref')
            ) {
                if (user.permissions.services.includes(SERVICE_TYPES.VIDEO_HOSTING)) {
                    setPages([
                        {
                            path: '/',
                            component: 'VHS',
                            service: SERVICE_TYPES.VIDEO_HOSTING,
                        },
                        ...initialPages,
                    ]);
                } else if (user.permissions.services.includes('pe')) {
                    setPages([
                        {
                            path: '/',
                            component: 'ProgramEngine',
                            service: 'pe',
                        },
                        ...initialPages,
                    ]);
                } else if (user.permissions.services.includes('cs')) {
                    setPages([
                        {
                            path: '/',
                            component: 'Community',
                            service: 'cs',
                        },
                        ...initialPages,
                    ]);
                } else if (user.permissions.services.includes('usr')) {
                    setPages([
                        {
                            path: '/',
                            component: 'AdminUsers',
                            service: 'usr',
                        },
                        ...initialPages,
                    ]);
                }
            }
        }
        loadPermissions();
        // eslint-disable-next-line
    }, [user]);

    function AppRoutes() {
        return (
            <Routes>
                {pages
                    .filter(
                        (p) =>
                            !p.service ||
                            user.permissions?.services?.includes('all') ||
                            user.permissions?.services?.includes(p.service)
                    )
                    .map((page) => {
                        const Component = components[page.component];
                        return (
                            <Route
                                key={page.path}
                                path={page.path}
                                element={
                                    <RouteWrapper hideDrawer={page.hideDrawer}>
                                        <Component />
                                    </RouteWrapper>
                                }
                            />
                        );
                    })}
            </Routes>
        );
    }

    function LoginRoutes() {
        return (
            <Routes>
                <Route
                    path="/login"
                    element={
                        <RouteWrapper hideDrawer contentClass="login-content">
                            <Login />
                        </RouteWrapper>
                    }
                />
                <Route
                    path="/authenticate"
                    element={
                        <RouteWrapper hideDrawer contentClass="login-content">
                            <Authenticate />
                        </RouteWrapper>
                    }
                />
                <Route
                    path="/"
                    element={
                        <RouteWrapper hideDrawer>
                            <LandingPage />
                        </RouteWrapper>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <RouteWrapper hideDrawer>
                            <LandingPage />
                        </RouteWrapper>
                    }
                />
            </Routes>
        );
    }

    return (
        <BrowserRouter>
            <CustomModal />
            {user && <AppRoutes />}
            {!user && <LoginRoutes />}
        </BrowserRouter>
    );
});

export default App;
