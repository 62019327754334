import DetailOverview from 'components/DetailOverview';
import ProgramEntryList from '../ProgramEntryList';

function VHSProgramOverview({ program }) {
    return (
        <div className="flex gap-2">
            <div className="w-1/2">
                <DetailOverview
                    fields={[
                        { name: 'Name', value: program.name },
                        { name: 'Description', value: program.description },
                        { name: 'Entries', value: program.entries.length },
                        { name: 'Visibility', value: (program.is_active ? 'Published' : 'Unpublished') },
                        { name: 'Created', value: program.created_int, type: 'date' },
                    ]}
                />
            </div>
            <div className="w-1/2">
                <ProgramEntryList program={program} showControls={false} />
            </div>
        </div>
    );
}

export default VHSProgramOverview;
