function SingleFilter({
    selectedValue = '',
    options = [],
    onSelect = function () {},
}) {
    function onOptionSelected(event) {
        onSelect(event.target.value);
    }

    return (
        <select
            value={selectedValue}
            className="muuv-select ml-1 mr-1"
            onChange={onOptionSelected}
        >
            {options.map((option) => (
                <option
                    className="muuv-select ml-1 mr-1"
                    key={`${option.label}-${option.value}`}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </select>
    );
}

export default SingleFilter;
