const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsBrandsWorker } = require('../workers/vhsBrandsWorker');

function* VHS_BRANDS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_FETCH_ALL_BRANDS_REQUEST,
            ACTION_TYPES.VHS_FETCH_BRANDS_REQUEST,
            ACTION_TYPES.VHS_POST_BRAND_REQUEST,
            ACTION_TYPES.VHS_UPDATE_BRAND_REQUEST,
            ACTION_TYPES.VHS_FETCH_BRAND_REQUEST,
        ],
        vhsBrandsWorker,
        apiUrl,
        ...params
    );
}

export default VHS_BRANDS_REQUEST;

