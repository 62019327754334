import React, {useEffect, useState} from 'react';
import useActions from 'hooks/useActions';
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useQueryList from 'hooks/useQuery';
import { CREATED_SORTING_OPTIONS, CONTENT_TYPE_OPTIONS, REASON_OPTIONS} from 'constants/COMMUNITY_STATUS';
import SelectableField from 'components/SelectableField';
import { useNavigate } from 'react-router-dom';
import LoadingTable from 'components/LoadingTable';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        items: state.CSModerator.itemList,
        isLoading: state.CSModerator.isLoading,
        per_page: state.CSModerator.itemPerPage,
        page: state.CSModerator.itemPage,
        total: state.CSModerator.itemTotal,
        total_pages: state.CSModerator.itemTotalPages,
        sortingFilter: state.CSModerator.sortFilter,
        contentFilter: state.CSModerator.contentFilter,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const CSModerator = connect(mapStateToProps)(function (props) {
    const {
        items = [],
        isLoading,
        per_page,
        page,
        total,
        total_pages,
        sortingFilter,
        contentFilter,
        selectedRegionFilter,
    } = props;
    const {
        CS_MODERATOR_REQUEST,
        CS_SELECT_MODERATOR_ORDER_FILTER,
        CS_SELECT_MODERATOR_CONTENT_FILTER,
        CS_MODERATOR_VISIBILITY_REQUEST,
        CS_MODERATOR_FLAGS_UPDATE,
        SET_MODAL_CONTENT,
        TOGGLE_MODAL,
        SET_MODAL_STYLE
    } = useActions();

    const { term: searchTerm }  = useQueryList(['term']);
    const navigate = useNavigate();


    useEffect(() => {
        CS_MODERATOR_REQUEST({
            page,
            per_page,
            sorting: sortingFilter,
            contentType: contentFilter,
            searchTerm,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentFilter, sortingFilter, selectedRegionFilter]);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Content Types' },
                    ...CONTENT_TYPE_OPTIONS,
                ]}
                selectedValue={contentFilter}
                onSelect={(value) =>
                    CS_SELECT_MODERATOR_CONTENT_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    ...CREATED_SORTING_OPTIONS,
                ]}
                selectedValue={sortingFilter}
                onSelect={(value) =>
                    CS_SELECT_MODERATOR_ORDER_FILTER(value)
                }
            />,
        ]
    }

    const onNextPage = () => {
        CS_MODERATOR_REQUEST({
            sorting: sortingFilter,
            contentType: contentFilter,
            searchTerm,
            per_page,
            page: page + 1,
        });
    }

    const onPrevPage = () => {
        CS_MODERATOR_REQUEST({
            sorting: sortingFilter,
            contentType: contentFilter,
            searchTerm,
            per_page,
            page: page - 1,
        });
    }
    const onPageSelect = (pageSelected) => {
        CS_MODERATOR_REQUEST({
            sorting: sortingFilter,
            contentType: contentFilter,
            searchTerm,
            per_page,
            page: pageSelected,
        });
    }

    const onPerPageSelected = ({ perPage }) => {
        CS_MODERATOR_REQUEST({
            sorting: sortingFilter,
            contentType: contentFilter,
            searchTerm,
            per_page: perPage,
        });
    }

    const changeVisibility = (id,contentType,visibility) => {
        if(id){
            CS_MODERATOR_VISIBILITY_REQUEST({
                id,
                contentType,
                visibility,
                sorting: sortingFilter,
                contentFilter: contentFilter,
                searchTerm,
                per_page,
                page,
            });
        }
    }

    const checkTypeContent = (item) => {
        if(item?.class_name !== "Post") return item?.class_name;
        return item.post_type;
    }

    const normalizeContent = (item) => {
        const typeOfContent = checkTypeContent(item);
        let contentObj = {
            id: item.id,
            visibility: item.visibility,
            typeContent: typeOfContent,
        };
        switch (typeOfContent) {
            case 'question':
            case 'answer':
            case 'comment':
                contentObj = {
                    ...contentObj,
                    cardTitle : typeOfContent.toUpperCase() + " Posted",
                    title: item.title,
                    body: item.body,
                    avatar_url: item.member.avatar_url,
                    postedByName: item.member.name,
                    postedByEmail: item.member.email,
                    postedBySubline: item.member.subline,
                    parentId: item.parent?.id,
                    parentTitle: item.parent?.title,
                    parentBody: item.parent?.body,
                    type: 'posts',
                }
                break;
            case 'Tag':
                contentObj = {
                    ...contentObj,
                    cardTitle : "Tag Created",
                    body: item.name,
                    avatar_url: item.first_post?.member?.avatar_url,
                    postedByName: item.first_post?.member?.name,
                    postedByEmail: item.first_post?.member?.email,
                    postedBySubline: item.first_post?.member?.subline,
                    parentTitle: item.first_post?.title,
                    parentBody: item.first_post?.body,
                    type: 'tags'
                }
                break
            case 'Flag':
                let typeContentOriginal = checkTypeContent(item.resource);
                if(
                    typeContentOriginal === 'question' ||
                    typeContentOriginal === 'answer' ||
                    typeContentOriginal === 'comment'
                ){
                    typeContentOriginal = 'posts';
                }
                contentObj = {
                    ...contentObj,
                    cardTitle : `${item.resource?.post_type} Flagged`,
                    body: item.resource?.body,
                    title: item.resource?.title,
                    avatar_url: item.resource?.member?.avatar_url,
                    postedByName: item.resource?.member?.name,
                    postedByEmail: item.resource?.member?.email,
                    postedBySubline: item.resource?.member?.subline,
                    type: 'flags',
                    typeContent: checkTypeContent(item.resource)+'-flag',
                    typeContentOriginal,
                    parentId: item.resource?.parent_id,
                    parentTitle: item.resource?.parent?.title,
                    flaggedByName: item.member?.name,
                    flaggedByEmail: item.member?.name,
                    flaggedBySubline: item.member?.subline,
                }
                break
            default:
                break;
        }
        return contentObj
    }

    const postedByFragment = (name, subline, flagged = false, flagged_name, flagged_subline, emailsObject) => {
        return (
            
            <section className="content-creator w-2/12 justify-center align-center text-center flex flex-col">
                    <div className="content-header">Posted By</div>
                    <div className={`flex flex-col`}>
                        <strong className='text-lg'>{name}</strong>
                        {
                            emailsObject?.postedByEmail && (
                                <strong className='text-md'>{emailsObject?.postedByEmail}</strong>
                            )
                        }
                        <div className="member-subline text-center uppercase text-xs p-1 mt-2 px-2 self-center font-semibold">{subline}</div>
                    </div>
            
            {flagged && (
                    <>
                    <div className="content-header mt-4">Flagged By</div>
                    <div className={`flex flex-col`}>
                        <strong className='text-lg'>{flagged_name}</strong>
                        {
                            emailsObject?.flaggedByEmail && (
                                <strong className='text-md'>{emailsObject?.flaggedByEmail}</strong>
                            )
                        }
                        <div className="member-subline text-center uppercase text-xs p-1 mt-2 px-2 self-center font-semibold">{flagged_subline}</div>
                    </div>
                    </>
            )}
            </section>
        )
    }

    const goToDetail = (itemContent) => {
        switch (itemContent.typeContent) {
            case 'question':
                navigate(`/community/questions/${itemContent.id}`);
                break;
            case 'answer':
                navigate(`/community/questions/${itemContent.parentId}/answers/${itemContent.id}`);
                break;
            case 'comment':
                navigate(`/community/articles/${itemContent.parentId}/comments/${itemContent.id}`);
                break;
            case 'Tag':
                navigate(`/community/tags/tag/${itemContent.id}`);
                break;
            case 'question-flag':
                navigate(`/community/questions/${itemContent.id}/flags`);
                break;
            case 'answer-flag':
                navigate(`/community/questions/${itemContent.parentId}/answers/${itemContent.id}/flags`);
                break;
            case 'comment-flag':
                navigate(`/community/articles/${itemContent.parentId}/comments/${itemContent.id}/flags`);
                break;
            default:
                break;
        }
    }

    const handleUpdateFlag = (id, status, reason ) => {
        CS_MODERATOR_FLAGS_UPDATE({
            id,
            status,
            reason,
            sorting: sortingFilter,
            contentFilter: contentFilter,
            searchTerm,
            per_page,
            page,
        })
    }

    const [reasonSelected, setReasonSelected] = useState('');

    const changeReasonModal = (item,itemContent) => {
        SET_MODAL_CONTENT(
            <div>
                <h2 className='text-2xl'>Select Reason</h2>
                <div 
                    onChange={
                        (event) => setReasonSelected(event.target.value)
                    }
                >
                {
                    REASON_OPTIONS.map((item,idx) => (
                            <div className='flex items-center mb-4' key={idx}>
                                <input name='reason' type={'radio'} key={'reason-'+idx} id={item.value} value={item.value} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label htmlFor={item.value} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{item.label}</label>
                            </div>
                    ))
                }
                </div>
                <button className='button' 
                    onClick={
                        () => {
                            TOGGLE_MODAL(false)
                            if (itemContent.type === 'flags'){
                                changeVisibility(item?.resource?.id,itemContent.typeContentOriginal,'denied');
                                handleUpdateFlag(item.id, 'denied_content', reasonSelected)
                            }else{
                                changeVisibility(item.id,itemContent.type,'denied');
                            }
                        }
                    }
                >Save</button>
            </div>
        )
        SET_MODAL_STYLE({width: 'auto', 'minWidth': '360px', height: 'auto', 'minHeight': '360px'})
        TOGGLE_MODAL(true);
    }

    const itemContent = (item) => {
        const itemContent = normalizeContent(item);
        const titlesPerType = {
            "Tag" : 'on question',
            "answer": 'In response to question',
            "comment": 'In response to article',
            "answer-flag": 'In response to question',
            "comment-flag": 'In response to article',
        }
        if(itemContent.typeContent === 'article'){
            return null;
        }
        return (
            <div key={"item-"+item.id} className="content comment content-pending rounded-xl my-4 flex p-6 flex-row gap-8">
                <section className="content-approving w-8/12 pr-20">
                    <div className="content-header mb-2">{itemContent.cardTitle}</div>
                    { itemContent.type === 'posts' || itemContent.type === 'flags' ? <>
                        <SelectableField
                                    action={() => goToDetail(itemContent)}
                                    text={<strong>{itemContent.title}</strong>}
                                />
                    </> : null}
                    <div className="content-body">
                        {itemContent.body}
                    </div>
                    <div className="content-parent mt-4">{titlesPerType[itemContent.typeContent]}</div>
                    <div className="content-title"><span className='text-primary-dark-color text-lg cursor-pointer' onClick={() => goToDetail(itemContent)}>{itemContent.parentTitle}</span></div>
                    <div className="content-body">{itemContent.parentBody}</div>
                </section>
                {postedByFragment(itemContent.postedByName,itemContent.postedBySubline,itemContent.type === 'flags',itemContent?.flaggedByName, itemContent?.flaggedBySubline, {postedByEmail: itemContent?.postedByEmail, flaggedByEmail: itemContent?.flaggedByEmail})}
                <section className="content-actions w-1/12">
                    <button 
                        className="button p-2 text-white"
                        disabled={item.visibility === 'denied'}
                        onClick={() => {
                            if(itemContent.type === 'flags'){
                                changeVisibility(item?.resource?.id,itemContent.typeContentOriginal, 'published');
                                handleUpdateFlag(item.id, 'allowed_content');
                            }else{
                                changeVisibility(item.id,itemContent.type, 'published');
                            }
                        } }>
                            Allow Content
                    </button>
                    <button 
                        className="button delete-button text-white flex items-center justify-center"
                        disabled={item.visibility === 'published'}
                        onClick={() => changeReasonModal(item,itemContent)}>
                            Reject Content
                    </button>
                </section>
            </div>
        )
    }

    return (
        <section>
            <InformationTab
                sectionName="Moderator Console"
                customFilters={filters()}
                dataTable={
                    items?.length > 0 ?
                    (<div>
                        <LoadingTable isLoading={isLoading} tableClass={'table-auto w-full text-left relative border-none bg-transparent'}>
                        <TableResults results={total} />
                        {items?.map(itemContent)}
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                        </LoadingTable>
                    </div>)
                    :
                    <div className='flex flex-col justify-center items-center mt-32'>
                        <img src='/assets/caught.png' alt='All caught up!' width={150} height={150}/>
                        <span className='text-center text-6xl'>All caught up!</span>
                        <span className='text-center text-2xl text-gray-600'>No new content to approve</span>
                    </div>
                
                }
                showPrograms={false}
                showSearchField={true}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                showDelete={false}
            />
        </section>
    );
});

export default CSModerator;
