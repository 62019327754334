import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import AlertModal from 'components/Modal/AlertModal';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AdminDetails from './AdminDetails';
import AdminNew from './AdminNew';
import LinkButton from 'components/LinkButton';

function mapStateToProps(state) {
    return {
        users: state.admins.users,
        total: state.admins.total,
        page: state.admins.page,
        per_page: state.admins.per_page,
        total_pages: state.admins.total_pages,
        config: state.admins.config,
        isLoading: state.admins.isLoadingUsers,
        brandFilter: state.admins.brandFilter,
        serviceFilter: state.admins.serviceFilter,
        selectedUser: state.admins.selectedUser,
    };
}

const AdminUsers = connect(mapStateToProps)(function (props) {
    const {
        users,
        total,
        page,
        per_page,
        total_pages,
        config,
        isLoading,
        brandFilter,
        serviceFilter,
        selectedUser,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState('');
    const [brandOptions, setBrandOptions] = useState([]);
    const [brandsSet, setBrandsSet] = useSetFromOptions();
    const [servicesSet, setServicesSet] = useSetFromOptions();
    const [serviceOptions, setServiceOptions] = useState([]);
    const [showDelete, setShowDelete] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const {
        FETCH_ADMINS_REQUEST,
        FETCH_ADMIN_CONFIG_REQUEST,
        SELECT_ADMINS_BRANDS_FILTER,
        SELECT_ADMINS_SERVICES_FILTER,
        GET_ADMIN_REQUEST,
        SELECT_ADMIN,
        DELETE_ADMIN_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    function onDetailSelection(user) {
        setSubtitle(null);
        setDetailComponent(null);
        SELECT_ADMIN(user);
        setShowDelete(true);
        if (user) {
            navigate(`/admins/${user.id}`);
        } else {
            FETCH_ADMINS_REQUEST({
                serviceFilter,
                brandFilter,
                per_page,
                page,
            });
            navigate(`/admins`);
        }
    }

    const onAdd = () => {
        setSubtitle('Add New Admin');
        setShowDelete(false);
        setDetailComponent(<AdminNew />);
    };

    function onPerPageSelected({ perPage }) {
        FETCH_ADMINS_REQUEST({
            per_page: perPage,
            brandFilter,
            serviceFilter,
        });
    }

    function onNext() {
        FETCH_ADMINS_REQUEST({
            brandFilter,
            serviceFilter,
            per_page,
            page: page + 1,
        });
    }

    function onPrev() {
        FETCH_ADMINS_REQUEST({
            brandFilter,
            serviceFilter,
            per_page,
            page: page - 1,
        });
    }

    function onPageSelected(pageSelected) {
        FETCH_ADMINS_REQUEST({
            brandFilter,
            serviceFilter,
            per_page,
            page: pageSelected,
        });
    }

    function onDeleteAction() {
        DELETE_ADMIN_REQUEST(selectedUser.id);
        FETCH_ADMINS_REQUEST({ brandFilter, serviceFilter, per_page, page });
        navigate('/admins');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this User?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    useEffect(() => {
        if (config && config.brands) {
            setBrandsSet(config.brands);
        }
        if (config && config.services) {
            setServicesSet(config.services);
        }
        // eslint-disable-next-line
    }, [config]);

    useEffect(() => {
        FETCH_ADMINS_REQUEST({ brandFilter, serviceFilter, per_page, page });
        // eslint-disable-next-line
    }, [brandFilter, serviceFilter]);

    useEffect(() => {
        if (selectedUser) {
            setSubtitle(
                `${selectedUser?.first_name} ${selectedUser.last_name}`
            );
            setDetailComponent(<AdminDetails />);
            setShowDelete(true);
        } else {
            setDetailComponent(null);
        }
    }, [selectedUser]);

    useEffect(() => {
        if (id) {
            try {
                GET_ADMIN_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            SELECT_ADMIN(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (config && config.brands) {
            setBrandOptions(config.brands);
        }
        if (config && config.services) {
            setServiceOptions(config.services);
        }
    }, [config]);

    useEffect(() => {
        FETCH_ADMIN_CONFIG_REQUEST();
        FETCH_ADMINS_REQUEST({ brandFilter, serviceFilter, per_page });
        // eslint-disable-next-line
    }, []);

    function dataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Admin" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Brands</th>
                            <th>Services</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={`user-${user.id}`}>
                                <td>
                                    <SelectableField
                                        url={`/admins/${user.id}`}
                                        text={`${user.first_name} ${user.last_name}`}
                                    />
                                </td>
                                <td>
                                    <SelectableField
                                        url={`/admins/${user.id}`}
                                        text={user.email}
                                    />
                                </td>
                                <td>
                                    {user.brand_permissions
                                        ?.split('-')
                                        .map((br) => (
                                            <div>{brandsSet[br]}</div>
                                        ))}
                                </td>
                                <td>
                                    {user.service_permissions
                                        ?.split('-')
                                        .map((sr) => (
                                            <div>{servicesSet[sr]}</div>
                                        ))}
                                </td>
                                <td>
                                    <LinkButton
                                            className="table-view-button"
                                            action={() => navigate(`/admins/${user.id}`)}
                                            >
                                        See Details
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPageSelected={onPageSelected}
                    onPrev={onPrev}
                />
            </section>
        );
    }

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Brands' },
                    ...brandOptions,
                ]}
                selectedValue={brandFilter}
                onSelect={(value) => SELECT_ADMINS_BRANDS_FILTER(value)}
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Services' },
                    ...serviceOptions,
                ]}
                selectedValue={serviceFilter}
                onSelect={(value) => SELECT_ADMINS_SERVICES_FILTER(value)}
            />,
        ];
    }

    return (
        <section className="referrals">
            <h1 className="admin-section-title">&#x1f465; &nbsp; Admins</h1>
            <InformationTab
                showDelete={showDelete}
                sectionName="Admins"
                customFilters={filters()}
                showPrograms={false}
                dataTable={dataTable()}
                onDetailSelected={onDetailSelection}
                detailComponent={detailComponent}
                subsectionName={subtitle}
                onPerPageSelected={onPerPageSelected}
                perPage={per_page}
                deleteText={'User'}
                onDeleteDetail={onDeleteDetail}
            />
        </section>
    );
});

export default AdminUsers;
