import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import csArticlesWorker from '../workers/csArticlesWorker';

function* CS_ARTICLES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_ARTICLES_REQUEST,
            ACTION_TYPES.CS_FETCH_ARTICLE_REQUEST,
            ACTION_TYPES.CS_DELETE_ARTICLE_REQUEST,
            ACTION_TYPES.CS_UPDATE_ARTICLE_REQUEST,
            ACTION_TYPES.CS_POST_ARTICLE_REQUEST,
            ACTION_TYPES.CS_FETCH_ARTICLE_FLAGS_REQUEST,
            ACTION_TYPES.CS_UPDATE_ARTICLE_FLAGS_REASON_REQUEST,
            ACTION_TYPES.CS_ADD_TAG_TO_ARTICLE_REQUEST,
            ACTION_TYPES.CS_REMOVE_TAG_FROM_ARTICLE_REQUEST,
            ACTION_TYPES.CS_FETCH_ARTICLE_FILES_REQUEST,
            ACTION_TYPES.CS_POST_ARTICLE_FILE_REQUEST,
            ACTION_TYPES.CS_DELETE_ARTICLE_FILE_REQUEST,
            ACTION_TYPES.CS_ASSIGN_ASSET_REQUEST,
            ACTION_TYPES.CS_UNASSIGN_ASSET_REQUEST,
            ACTION_TYPES.CS_FETCH_ARTICLE_TRANSLATIONS_REQUEST,
            ACTION_TYPES.CS_POST_ARTICLE_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_DELETE_ARTICLE_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_UPDATE_ARTICLE_TRANSLATION_REQUEST,
        ],
        csArticlesWorker,
        apiUrl,
        ...params
    );
}

export default CS_ARTICLES_REQUEST;
