import moment from 'moment';
import api from '../api/api';

const { put } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* getViews(per_page = 10, page = 1, selectedProgram = 'all', apiUrl) {
    let url = `/referrals/track_events?per_page=${per_page}&page=${page}`;
    if (selectedProgram !== 'all') {
        url += `&programs=${selectedProgram}`;
    }
    const json = yield api.get(url, apiUrl);

    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_VIEWS_RESPONSE,
            data: {
                list: json.results || [],
                total: json.total,
                total_pages: json.total_pages,
                page: json.page,
                per_page: json.per_page,
            },
        });
    }
}

function* getView(viewId, apiUrl) {
    const json = yield api.get(`/referrals/track_events/${viewId}`, apiUrl);
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_VIEW_RESPONSE,
            data: json,
        });
    }
}

function* getViewActivity(uuid, page = 1, perPage = 10, apiUrl) {
    const json = yield api.get(
        `/referrals/track_events?uuid=${uuid}&page=${page}&per_page=${perPage}`,
        apiUrl
    );
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_VIEW_ACTIVITY_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getChartData(period = '30', program = 'all', apiUrl) {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(Number.parseInt(period), 'd');
    const initialMillis = today.toDate().getTime();
    let url = `/referrals/chart?actions=view&start_int=${todayMillis}&end_int=${initialMillis}`;
    program !== 'all' && (url += `&programs=${program}`);
    period === '1' && (url += '&group_by=hour');
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_VIEWS_CHART_RESPONSE,
            data: {
                list: json.results || [],
            },
        });
    }
}

function* viewsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_VIEWS_REQUEST:
            yield getViews(
                action.data?.per_page,
                action.data?.page,
                action.data?.selectedProgram,
                apiUrl
            );
            break;
        case ACTION_TYPES.FETCH_VIEW_REQUEST:
            yield getView(action.data, apiUrl);
            break;
        case ACTION_TYPES.FETCH_VIEW_ACTIVITY_REQUEST:
            yield getViewActivity(
                action.data.uuid,
                action.data.page,
                action.data.perPage,
                apiUrl
            );
            break;
        case ACTION_TYPES.FETCH_VIEWS_CHART_REQUEST:
            yield getChartData(
                action.data.selectedPeriod,
                action.data.selectedProgram,
                apiUrl
            );
            break;
        default:
            break;
    }
}

export default viewsWorker;
