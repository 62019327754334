import DateField from 'components/DateField';

function ProgramOverview({ program, brandsSet = {} }) {
    return (
        <section>
            <table className='overview-table'>
                <tbody>
                    <tr>
                        <td className="uppercase label">Name</td>
                        <td>{program.name}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Brand</td>
                        <td>
                            {brandsSet[program.brand_ident]}
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Credit</td>
                        <td>${program.credit}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Discount</td>
                        <td>${program.discount}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Approve</td>
                        <td>
                            {program.is_auto_approved
                                ? 'Auto-Approve'
                                : 'Require Approval'}
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Enabled</td>
                        <td>{program.is_enabled ? 'Enabled' : 'Disabled'}</td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Created</td>
                        <td>
                            <DateField timeMillis={program.created_int} />
                        </td>
                    </tr>
                    <tr>
                        <td className="uppercase label">Updated</td>
                        <td>
                            <DateField timeMillis={program.updated_int} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
}

export default ProgramOverview;
