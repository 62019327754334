import MarkdownHint from 'components/StaticElements/MarkdownHint';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { useReducer } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {

    };
}
function reducer(state, action) {
    switch (action.type) {
        case 'name':
            return { ...state, name: action.data };
        case 'description':
            return { ...state, description: action.data };
        case 'is_active':
            return { ...state, is_active: action.data };
        default:
            return state;
    }
}

const VHSProgramEdit = connect(mapStateToProps)(function ({
    program,
    onSave,
}) {
    const initialState = {
        ...program
    };
    const [updatedProgram, dispatch] = useReducer(reducer, initialState);

    function onFieldChange(event) {
        dispatch({
            type: event.target.name,
            data: event.target.value,
        });
    }
    function handleMarkdownChange(name,value){
        dispatch({
            type: name,
            data: value,
        });
    }

    function onConfirmChanges(event) {
        event.preventDefault();
        onSave({
            id: program.id,
            ...updatedProgram,
        });
    }

    return (
        <section>
            <form onSubmit={onConfirmChanges}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={updatedProgram.name}
                                    onChange={onFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Description
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="description"
                                    value={updatedProgram.description}
                                    onChange={(value) => handleMarkdownChange('description',value)}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={300}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="is_active"
                                    value={updatedProgram.is_active}
                                    onChange={onFieldChange}
                                >
                                    <option value={true}>Published</option>
                                    <option value={false}>Unpublished</option>
                                </select>
                            </td>
                        </tr>
                        {updatedProgram !== initialState && (
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default VHSProgramEdit;
