import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import VHSSeriesDetails from './SeriesDetails';
import SeriesNew from './SeriesNew';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


function mapStateToProps(state) {
    return {
        series: state.vhsSeries.series,
        page: state.vhsSeries.page,
        per_page: state.vhsSeries.per_page,
        total_pages: state.vhsSeries.total_pages,
        total: state.vhsSeries.total,
        selectedSeries: state.vhsSeries.selectedSeries,
        brandsOptions: state.vhsBrands.brandsOptions,
        visibilityFilter: state.vhsSeries.visibilityFilter,
        brandsFilter: state.vhsSeries.brandsFilter,
        isLoading: state.vhsSeries.isLoadingSeries,
    };
}

const VHSSeries = connect(mapStateToProps)(function (props) {
    const {
        series,
        page,
        per_page,
        total_pages,
        total,
        selectedSeries,
        brandsOptions,
        visibilityFilter,
        brandsFilter,
        isLoading,
    } = props;
    const navigate = useNavigate();
    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [detailComponent, setDetailComponent] = useState(null);
    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);

    const {
        VHS_FETCH_SERIES_REQUEST,
        VHS_SELECT_SERIES,
        VHS_GET_SERIES_REQUEST,
        VHS_DELETE_SERIES_REQUEST,
        VHS_SELECT_SERIES_VISIBILITY_FILTER,
        VHS_SELECT_SERIES_BRANDS_FILTER,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    function onAdd() {
        setSubtitle('Add New Serie');
        setShowDelete(false);
        setDetailComponent(<SeriesNew />);
    }

    const onNextPage = () => {
        VHS_FETCH_SERIES_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page,
            page: page + 1,
            searchTerm,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_SERIES_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page,
            page: page - 1,
            searchTerm,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_SERIES_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page,
            page: pageSelected,
            searchTerm,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_SERIES_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page: perPage,
            searchTerm,
        });
    };

    function onDeleteAction() {
        VHS_DELETE_SERIES_REQUEST(selectedSeries?.id);
        navigate('/vhs/series');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Serie?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    const onDetailSelection = (series) => {
        setSubtitle(null);
        setDetailComponent(null);
        VHS_SELECT_SERIES(series);
        // setShowDelete(true);
        if (series) {
            navigate(`/vhs/series/${series.id}`);
        } else {
            VHS_FETCH_SERIES_REQUEST({
                visibility: visibilityFilter,
                brand: brandsFilter,
                per_page,
                page,
                searchTerm,
            });
            navigate(`/vhs/series`);
        }
    };

    useEffect(() => {
        if (selectedSeries) {
            setSubtitle(selectedSeries?.name);
            setDetailComponent(<VHSSeriesDetails />);
        } else {
            setDetailComponent(null);
            setSubtitle(null);
        }
        // eslint-disable-next-line
    }, [selectedSeries]);

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        if (id) {
            try {
                VHS_GET_SERIES_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            VHS_SELECT_SERIES(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        VHS_FETCH_SERIES_REQUEST({
            page,
            per_page,
            visibility: visibilityFilter,
            brand: brandsFilter,
            searchTerm,
        });
        // eslint-disable-next-line
    }, [visibilityFilter, brandsFilter]);

    useEffect(() => {
        VHS_FETCH_SERIES_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            searchTerm,
        });
        // eslint-disable-next-line
    }, []);

    function filters() {
        return [
            <SingleFilter
                selectedValue={visibilityFilter}
                options={[
                    { value: 'all', label: 'All Visibility' },
                    ...VISIBILITY_OPTIONS,
                ]}
                onSelect={(value) => VHS_SELECT_SERIES_VISIBILITY_FILTER(value)}
            />,
            <SingleFilter
                selectedValue={brandsFilter}
                options={[
                    { value: 'all', label: 'All Brands' },
                    ...brandsOptions,
                ]}
                onSelect={(value) => VHS_SELECT_SERIES_BRANDS_FILTER(value)}
            />,
        ];
    }

    function dataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Serie" onAdd={onAdd}/>
                <LoadingTable tableClass="" isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Visibility</th>
                            <th>Brand</th>
                        </tr>
                    </thead>
                    <tbody>
                        {series.map((ser) => (
                            <tr key={`series-${ser.id}`}>
                                <td>
                                    <SelectableField
                                        text={ser.name}
                                        url={`/vhs/series/${ser.id}`}
                                    />
                                </td>
                                <td>{ser.visibility}</td>
                                <td>
                                    {brandsSet[ser.brand_ident]}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                />
            </section>
        );
    }

    return (
        <section>
            <InformationTab
                sectionName="Series"
                subsectionName={subtitle}
                detailComponent={detailComponent}
                showPrograms={false}
                dataTable={dataTable()}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                onDetailSelected={onDetailSelection}
                deleteText="Serie"
                onDeleteDetail={onDeleteDetail}
                showDelete={showDelete}
                customFilters={filters()}
                showSearchField={true}
            />
        </section>
    );
});

export default VHSSeries;
