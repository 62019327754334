import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        categories: state.PECategories.categories,
        isLoading: state.PECategories.isLoading,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const CategoriesModal = connect(mapStateToProps)(function (props) {
    const { categories, isLoading, onSelect = function () {}, selectedRegionFilter } = props;

    const { PE_FETCH_CATEGORIES_REQUEST } = useActions();

    useEffect(() => {
        PE_FETCH_CATEGORIES_REQUEST({ perPage: 1000 });
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    return (
        <section className="p-8">
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Select</th>
                    </tr>
                </thead>
                <tbody>
                    {categories?.map((category) => (
                        <tr key={`category-${category.id}`}>
                            <td>
                                <SelectableField
                                    text={category.name}
                                    url={`/program-engine/categories/${category.id}`}
                                />
                            </td>
                            <td>
                                <TableButton
                                    className="select-button"
                                    text="Select"
                                    action={() => {
                                        onSelect(category);
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
});

export default CategoriesModal;
