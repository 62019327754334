import useActions from 'hooks/useActions';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        redirect: state.user.redirect,
    };
}

const Authenticate = connect(mapStateToProps)(function (props) {
    const { redirect } = props;
    const { POST_CODE_REQUEST } = useActions();
    const [code, setCode] = useState('');
    const navigate = useNavigate();

    function goInitialScreen() {
        navigate(redirect ? redirect : '/');
    }

    function onSend(event) {
        event.preventDefault();
        POST_CODE_REQUEST({ code, callback: goInitialScreen });
    }

    return (
        <section>
            <div className="text-3xl mb-8">Enter Access Code</div>
            <form onSubmit={onSend} style={{ maxWidth: '400px' }}>
                <input
                    className="mb-4"
                    required
                    placeholder="Enter Code"
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                />
                <div className="mb-4">Or click on the link in your email.</div>
                <button type="submit" className="mt-4 button save-changes-button">
                    Log Me In
                </button>
            </form>
        </section>
    );
});

export default Authenticate;
