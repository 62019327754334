const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    programs: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    isLoadingPrograms: false,
    selectedProgram: null,
    brandsFilter: 'all',
    phases: [],
    tagGroups: [],
    translations: [],
    translations_page: 1,
    translations_per_page: 10,
    translations_total: 0,
    translations_total_pages: 1,
    translations_is_loading: false,
    selectedTranslation: null,
};

function PEProgramsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_PROGRAMS_REQUEST: {
            return {
                ...state,
                isLoadingPrograms: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_PROGRAMS_RESPONSE: {
            return {
                ...state,
                programs: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingPrograms: false,
            };
        }
        case ACTION_TYPES.PE_SELECT_PROGRAMS_BRANDS_FILTER: {
            return {
                ...state,
                page:0,
                brandsFilter: action.data,
            };
        }
        case ACTION_TYPES.PE_CLONE_PROGRAM_RESPONSE:
        case ACTION_TYPES.PE_SELECT_PROGRAM: {
            return {
                ...state,
                selectedProgram: action.data,
            };
        }
        case ACTION_TYPES.PE_POST_PROGRAM_RESPONSE:
        case ACTION_TYPES.PE_UPDATE_PROGRAM_RESPONSE:
        case ACTION_TYPES.PE_GET_PROGRAM_RESPONSE: {
            return {
                ...state,
                selectedProgram: action.data,
            };
        }
        case ACTION_TYPES.PE_FETCH_PROGRAM_PHASES_RESPONSE: {
            return {
                ...state,
                phases: action.data,
            };
        }
        case ACTION_TYPES.PE_FETCH_PROGRAMS_TAG_GROUPS_RESPONSE: {
            return {
                ...state,
                tagGroups: action.data,
            };
        }
        case ACTION_TYPES.PE_FETCH_PROGRAM_TRANSLATIONS_REQUEST: {
            return {
                ...state,
                translations_is_loading: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_PROGRAM_TRANSLATIONS_RESPONSE: {
            return {
                ...state,
                translations: action.data.list,
                translations_page: action.data.page,
                translations_per_page: action.data.per_page,
                translations_total: action.data.total,
                translations_total_pages: action.data.total_pages,
                translations_is_loading: false,
            };
        }
        case ACTION_TYPES.PE_POST_PROGRAM_TRANSLATION_RESPONSE:
        case ACTION_TYPES.PE_UPDATE_PROGRAM_TRANSLATION_REQUEST:
        case ACTION_TYPES.PE_SELECT_PROGRAM_TRANSLATION: {
            return {
                ...state,
                selectedTranslation: action.data,
            };
        }
        default:
            return state;
    }
}

export default PEProgramsReducer;
