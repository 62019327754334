const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    assignments: [],
    page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1,
    selectedAssignment: null,
    isLoadingAssignments: false,
    config: [],
    tagGroups: [],
    translations: [],
    translations_page: 1,
    translations_per_page: 10,
    translations_total: 0,
    translations_total_pages: 1,
    translations_is_loading: false,
    selectedTranslation: null,
    isLoadingImage: false,
    files: [],
    isLoadingFiles: false,
};

function PEAssignmentsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_ASSIGNMENTS_REQUEST: {
            return {
                ...state,
                isLoadingAssignments: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_ASSIGNMENTS_RESPONSE: {
            return {
                ...state,
                assignments: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
                isLoadingAssignments: false,
            };
        }
        case ACTION_TYPES.PE_SELECT_ASSIGNMENT:
        case ACTION_TYPES.PE_GET_ASSIGNMENT_RESPONSE:
        case ACTION_TYPES.PE_POST_ASSIGNMENT_RESPONSE:
        case ACTION_TYPES.PE_UPDATE_ASSIGNMENT_RESPONSE: {
            return {
                ...state,
                selectedAssignment: action.data,
            };
        }
        case ACTION_TYPES.PE_GET_ASSIGNMENT_CONFIG_RESPONSE: {
            const options = action.data?.options?.map((option) => ({
                ...option,
                value: option.name,
            }));
            return {
                ...state,
                config: options,
            };
        }
        case ACTION_TYPES.PE_FETCH_ASSIGNMENTS_TAG_GROUPS_RESPONSE: {
            return {
                ...state,
                tagGroups: action.data,
            };
        }
        case ACTION_TYPES.PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST: {
            return {
                ...state,
                translations_is_loading: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_ASSIGNMENT_TRANSLATIONS_RESPONSE: {
            return {
                ...state,
                translations: action.data.list,
                translations_page: action.data.page,
                translations_per_page: action.data.per_page,
                translations_total: action.data.total,
                translations_total_pages: action.data.total_pages,
                translations_is_loading: false,
            };
        }
        case ACTION_TYPES.PE_POST_ASSIGNMENT_TRANSLATION_RESPONSE:
        case ACTION_TYPES.PE_UPDATE_ASSIGNMENT_TRANSLATION_RESPONSE:
        case ACTION_TYPES.PE_SELECT_ASSIGNMENT_TRANSLATION: {
            return {
                ...state,
                selectedTranslation: action.data,
            };
        }
        case ACTION_TYPES.PE_POST_ASSIGNMENT_POSTER_REQUEST: {
            return {
                ...state,
                isLoadingImage: true,
            };
        }
        case ACTION_TYPES.PE_POST_ASSIGNMENT_POSTER_RESPONSE: {
            return {
                ...state,
                isLoadingImage: false,
            };
        }
        case ACTION_TYPES.PE_FETCH_ASSIGNMENT_FILES_REQUEST: {
            return {
                ...state,
                isLoadingFiles: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_ASSIGNMENT_FILES_RESPONSE: {
            return {
                ...state,
                files: action.data,
                isLoadingFiles: false,
            };
        }
        default:
            return state;
    }
}

export default PEAssignmentsReducer;
