/* eslint-disable react-hooks/exhaustive-deps */
import { VISIBILITY_OPTIONS, FEATURED_OPTIONS, TRENDING_OPTIONS } from 'constants/COMMUNITY_STATUS';
import useActions from 'hooks/useActions';
import { connect } from 'react-redux';
import MarkdownEditor from '@uiw/react-markdown-editor';
import MarkdownHint from 'components/StaticElements/MarkdownHint';
import SelectOrInput from 'components/SelectOrInput';

const { useState, useEffect } = require('react');

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
        expertCoaches: state.CSMembers.expertCoaches,
        config: state.admins.config,
        user: state.user.user,
    };
}

const CSQuestionNew = connect(mapStateToProps)(function (props) {
    const { brandsOptions, expertCoaches, config, user } = props;

    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const { CS_POST_QUESTION_REQUEST, CS_FETCH_EXPERT_COACHES_REQUEST } = useActions();

    const [question, setQuestion] = useState({
        title: '',
        body: '',
        is_featured: FEATURED_OPTIONS[0].value,
        is_trending: TRENDING_OPTIONS[0].value,
        visibility: VISIBILITY_OPTIONS[0].value,
        brand_ident: '',
        region_ident: '',
        member_id: '',
    });
    const [body, setBody] = useState('');
    const [expertCoachesOptions, setExpertCoachesOptions] = useState([]);
    const [isExpertCoachesDisabled, setIsExpertCoachesDisabled] = useState(true);

    const handleFieldChange = (event) => {
        const newState = {
            ...question,
            [event.target.name]: event.target.value,
        };
        setQuestion(newState);
        if(
            event.target.name === 'region_ident' && 
            !!event.target.value
        ){
            setIsExpertCoachesDisabled(true);
            CS_FETCH_EXPERT_COACHES_REQUEST({region_ident: event.target.value});
        }
    };

    function onCreate(event) {
        event.preventDefault();
        try {
            CS_POST_QUESTION_REQUEST({
                ...question,
                body,
                post_type: 'question',
                }
            );
        } catch (err) {
            alert(
                'There was an error when trying to save the question, check all the fields are filled properly.'
            );
        }
    }


    useEffect(() => {
        if (brandsOptions && brandsOptions.length > 0) {
            setQuestion((question) => ({
                ...question,
                brand_ident: brandsOptions[0].value,
            }));
        }
    }, [brandsOptions]);
    
    useEffect(() => {
        if (regionsToShow && regionsToShow.length > 0) {
            CS_FETCH_EXPERT_COACHES_REQUEST({region_ident: regionsToShow[0]});
            setQuestion((question) => ({
                ...question,
                region_ident: regionsToShow[0],
            }));
        }
    }, []);

    useEffect(() => {
        if (expertCoaches && expertCoaches.length > 0) {
            const options = expertCoaches.map(opt => (
                {
                    id : opt.id,
                    value: opt.id,
                    label: opt.name
                }
            ))
            setExpertCoachesOptions(options);
        }
        setIsExpertCoachesDisabled(false);
    }, [expertCoaches])



    return (
        <section>
            <form onSubmit={onCreate}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">
                                Question
                            </td>
                            <td>
                                <input
                                    className='w-full'
                                    name="title"
                                    value={question.title}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Body
                            </td>
                            <td>
                                <MarkdownEditor
                                    name="body"
                                    value={body}
                                    onChange={setBody}
                                    toolbars={['bold','italic','underline','header','olist','ulist']}
                                    height={500}
                                />
                                <MarkdownHint />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Featured</td>
                            <td>
                                <select
                                    name="is_featured"
                                    value={question.is_featured}
                                    onChange={handleFieldChange}
                                >
                                    {FEATURED_OPTIONS.map((option) => (
                                        <option
                                            key={`featured-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Trending</td>
                            <td>
                                <select
                                    name="is_trending"
                                    value={question.is_trending}
                                    onChange={handleFieldChange}
                                >
                                    {TRENDING_OPTIONS.map((option) => (
                                        <option
                                            key={`trending-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={question.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand_ident"
                                    value={question.brand_ident}
                                    onChange={handleFieldChange}
                                >
                                    {brandsOptions.map((ser) => (
                                        <option
                                            key={`question-${ser.value}`}
                                            value={ser.value}
                                        >
                                            {ser.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <select
                                    name="region_ident"
                                    value={question.region_ident}
                                    onChange={handleFieldChange}
                                >
                                    {regionsToShow.map((reg) => (
                                        <option
                                            key={`region-${reg}`}
                                            value={reg}
                                        >
                                            {reg}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">
                                Member id
                            </td>
                            <td>
                                <SelectOrInput
                                    name="member_id"
                                    onChange={handleFieldChange}
                                    options={expertCoachesOptions}
                                    placeholder={"Select an Expert Coach"}
                                    CTAText={"Enter member ID manually"}
                                    disabled={isExpertCoachesDisabled}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Question
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default CSQuestionNew;
