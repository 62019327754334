import DateField from 'components/DateField';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LinkButton from 'components/LinkButton';
import { useNavigate } from 'react-router';


function mapStateToProps(state) {
    return {
        programOptions: state.programs.programOptions,
        brandOptions: state.brands.brandOptions,
    };
}

const ConversionsTable = connect(mapStateToProps)(function (props) {
    const {
        conversions = [],
        excludedColumns = [],
        onUpdateConversion = function () {},
        programOptions,
        brandOptions,
        isLoading,
    } = props;
    
    const navigate = useNavigate();
    const [programsSet, setProgramsSet] = useState({});
    const [brandsSet, setBrandsSet] = useState({});

    useEffect(() => {
        const prOpts = {};
        programOptions.forEach((element) => {
            prOpts[element.value] = element.label;
        });
        setProgramsSet(prOpts);
    }, [programOptions]);

    useEffect(() => {
        const brOpts = {};
        brandOptions.forEach((element) => {
            brOpts[element.value] = element.label;
        });
        setBrandsSet(brOpts);
    }, [brandOptions]);

    return (
        <LoadingTable
            tableClass="w-full text-left border-collapse"
            isLoading={isLoading}
        >
            <thead>
                <tr>
                    {!excludedColumns.includes('status') && <th>Status</th>}
                    {!excludedColumns.includes('timestamp') && (
                        <th>Timestamp</th>
                    )}
                    {!excludedColumns.includes('new_user') && <th>New User</th>}
                    {!excludedColumns.includes('program') && <th>Program</th>}
                    {!excludedColumns.includes('brand') && <th>Brand</th>}
                    {!excludedColumns.includes('referring_member') && (
                        <th>Referring Member</th>
                    )}
                    {!excludedColumns.includes('details') && <th>Details</th>}
                </tr>
            </thead>
            <tbody>
                {conversions.map((conversion) => (
                    <tr key={`conversion-${conversion.id}`}>
                        {!excludedColumns.includes('status') && (
                            <td className="items-center flex-wrap">
                                <span className="mr-4 pb-4">
                                    {conversion.status}
                                </span>
                                {conversion.status === 'pending' && (
                                    <button
                                        onClick={() =>
                                            onUpdateConversion(
                                                conversion,
                                                'approved'
                                            )
                                        }
                                        className="font-medium text-white bg-blue-400 p-2"
                                    >
                                        Approve
                                    </button>
                                )}
                            </td>
                        )}
                        {!excludedColumns.includes('timestamp') && (
                            <td>
                                <DateField
                                    timeMillis={conversion.created_int}
                                />
                            </td>
                        )}
                        {!excludedColumns.includes('new_user') && (
                            <td className="email">
                                <SelectableField
                                    url={`/referrals/members/${conversion.member?.id}`}
                                    text={conversion.member?.email}
                                />
                            </td>
                        )}
                        {!excludedColumns.includes('program') && (
                            <td>
                                <SelectableField
                                    url={
                                        '/referrals/programs/' +
                                        conversion.program_id
                                    }
                                    text={programsSet[conversion.program_id]}
                                />
                            </td>
                        )}
                        {!excludedColumns.includes('brand') && (
                            <td>
                                {brandsSet[conversion.brand_ident]}
                            </td>
                        )}
                        {!excludedColumns.includes('referring_member') && (
                            <td className="email">
                                <SelectableField
                                    url={`/referrals/members/${conversion.referring_member?.id}`}
                                    text={conversion.referring_member?.email}
                                />
                            </td>
                        )}
                        {!excludedColumns.includes('details') && (
                            <td>
                                <LinkButton
                                        className="table-view-button"
                                        action={() => navigate(`/referrals/conversions/${conversion.id}`)}
                                    >
                                        See Details
                                 </LinkButton>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </LoadingTable>
    );
});

export default ConversionsTable;
