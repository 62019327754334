import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    members: [],
    isLoadingMembers: false,
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    selectedMember: null,
    activities: [],
    activities_page: 1,
    activities_per_page: 10,
    activities_total: 0,
    activities_total_pages: 1,
    isLoadingActivity: false,
    attends: [],
    attends_page: 1,
    attends_per_page: 10,
    attends_total: 0,
    attends_total_pages: 1,
    isLoadingAttends: false,
    favorited: [],
    favorited_page: 1,
    favorited_per_page: 10,
    favorited_total: 0,
    favorited_total_pages: 1,
    isLoadingFavorited: false,
    history: [],
    history_page: 1,
    history_per_page: 10,
    history_total: 0,
    history_total_pages: 1,
    isLoadingHistory: false,
};

function VHSMembersReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_MEMBERS_REQUEST: {
            return {
                ...state,
                isLoadingMembers: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBERS_RESPONSE: {
            return {
                ...state,
                members: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingMembers: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_ACTIVITY_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_ACTIVITY_RESPONSE: {
            return {
                ...state,
                activities: action.data.list,
                activities_page: action.data.page,
                activities_per_page: action.data.per_page,
                activities_total: action.data.total,
                activities_total_pages: action.data.total_pages,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_ATTEND_REQUEST: {
            return {
                ...state,
                isLoadingAttends: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_ATTEND_RESPONSE: {
            return {
                ...state,
                attends: action.data.list,
                attends_page: action.data.page,
                attends_per_page: action.data.per_page,
                attends_total: action.data.total,
                attends_total_pages: action.data.total_pages,
                isLoadingAttends: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_FAVORITED_REQUEST: {
            return {
                ...state,
                isLoadingFavorited: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_FAVORITED_RESPONSE: {
            return {
                ...state,
                favorited: action.data.list,
                favorited_page: action.data.page,
                favorited_per_page: action.data.per_page,
                favorited_total: action.data.total,
                favorited_total_pages: action.data.total_pages,
                isLoadingFavorited: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_HISTORY_REQUEST: {
            return {
                ...state,
                isLoadingHistory: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_HISTORY_RESPONSE: {
            return {
                ...state,
                history: action.data.list,
                history_page: action.data.page,
                history_per_page: action.data.per_page,
                history_total: action.data.total,
                history_total_pages: action.data.total_pages,
                isLoadingHistory: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_RESPONSE: {
            return {
                ...state,
                selectedMember: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEMBER_PERMISSIONS_RESPONSE: {
            return {
                ...state,
                currentMemberPermissions: action.data
            }
        }
        case ACTION_TYPES.VHS_SELECT_MEMBER: {
            return {
                ...state,
                selectedMember: action.data,
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSMembersReducer;
