import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchSeries(page = 1, per_page = 10, searchTerm = '', region_ident = '',  apiUrl) {
    let endpoint = `/pe/series?page=${page}&per_page=${per_page}`;
    if (!!searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const regionToFilter = region_ident ? region_ident : selectedRegion;
    
    const json = yield api.get(endpoint, apiUrl, regionToFilter, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_SERIES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getSeries(seriesId, apiUrl) {
    const json = yield api.get(`/pe/series/${seriesId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_SERIES_RESPONSE,
            data: json,
        });
    }
}

function* postSeries(series, apiUrl) {
    const json = yield api.post(`/pe/series`, series, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_SERIES_RESPONSE,
            data: json,
        });
    }
}

function* updateSeries(series, apiUrl) {
    const json = yield api.put(`/pe/series/${series.id}`, series, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_SERIES_RESPONSE,
            data: json,
        });
    }
}

function* deleteSeries(seriesId, page, perPage, searchTerm, apiUrl) {
    const json = yield api.del(`/pe/series/${seriesId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_SERIES_RESPONSE,
            data: json,
        });
    }
    yield fetchSeries(page, perPage, searchTerm, '', apiUrl);
}

function* cloneSeries(seriesId, apiUrl) {
    const json = yield api.post(`/pe/series/${seriesId}/clone`, {}, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_CLONE_SERIES_RESPONSE,
            data: json,
        });
    }
}

function* getSeriesAssignments(seriesId, page = 1, per_page = 10, apiUrl) {
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);
    const json = yield api.get(`/pe/series_tasks?series=${seriesId}&page=${page}&per_page=${per_page}&include=series-task-category`, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_SERIES_ASSIGNMENTS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* pePostSeriesAssignment(seriesId, taskId, taskType, apiUrl ) {
    const json = yield api.post(
        `/pe/series_tasks`,
        {
            series_id: seriesId,
            task_type: taskType,
            task_id: taskId,
            include: 'series-task',
        },
        apiUrl
    );
    if (json) {
        yield getSeriesAssignments(seriesId, 1, 10, apiUrl);
    }
}

function* removeAssignment(
    seriesAssignmentId,
    seriesId,
    page,
    per_page,
    apiUrl
) {
    const json = yield api.del(
        `/pe/series_tasks/${seriesAssignmentId}`,
        apiUrl
    );
    if (json) {
        yield getSeriesAssignments(seriesId, page, per_page, apiUrl);
    }
}

function* changeSeriesPosition(
    seriesAssignmentId,
    seriesId,
    page,
    per_page,
    position,
    apiUrl
) {
    const json = yield api.put(
        `/pe/series_tasks/${seriesAssignmentId}?series_id=${seriesId}`,
        { position },
        apiUrl
    );
    if (json) {
        yield getSeriesAssignments(seriesId, page, per_page, apiUrl);
    }
}

function* peSeriesWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_SERIES_REQUEST:
            yield fetchSeries(
                action.data?.page,
                action.data?.per_page,
                action.data?.searchTerm,
                action.data?.region_ident,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_GET_SERIES_REQUEST:
            yield getSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_SERIES_REQUEST:
            yield postSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_UPDATE_SERIES_REQUEST:
            yield updateSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_SERIES_REQUEST:
            yield deleteSeries(
                action.data?.seriesId,
                action.data?.page,
                action.data?.perPage,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_CLONE_SERIES_REQUEST:
            yield cloneSeries(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_SERIES_ASSIGNMENTS_REQUEST:
            yield getSeriesAssignments(
                action.data?.seriesId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST:
            yield pePostSeriesAssignment(
                action.data?.seriesId,
                action.data?.taskId,
                action.data?.taskType,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_DELETE_SERIES_ASSIGNMENT_REQUEST:
            yield removeAssignment(
                action.data?.assignmentId,
                action.data?.seriesId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_CHANGE_SERIES_ASSIGNMENT_POSITION_REQUEST:
            yield changeSeriesPosition(
                action.data?.assignmentId,
                action.data?.seriesId,
                action.data?.page,
                action.data?.per_page,
                action.data?.position,
                apiUrl
            );
            break;
        default:
            break;
    }
}

export default peSeriesWorker;
