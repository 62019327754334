import { ArrowRightIcon, XCircleIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/outline';
import LinkButton from 'components/LinkButton';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import TableImage from 'components/TableImage';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useCallback, useEffect, useState } from 'react';
import PEAssignmentModalContent from 'routing/ProgramEngine/Assignments/AssignmentModalContent';
import PEMatricesModalContent from 'routing/ProgramEngine/Matrices/MatrixModalContent';
import PESeriesModalContent from 'routing/ProgramEngine/Series/SeriesModalContent';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        sequence: state.PESequences.selectedSequence,
        tasks: state.PESequences.tasks,
        categoriesOptions: state.PECategories.categoriesOptions,
    };
}

const defaultDays = [
    { value: 1, label: 'Day 1', tasks: [] },
    { value: 2, label: 'Day 2', tasks: [] },
    { value: 3, label: 'Day 3', tasks: [] },
    { value: 4, label: 'Day 4', tasks: [] },
    { value: 5, label: 'Day 5', tasks: [] },
    { value: 6, label: 'Day 6', tasks: [] },
    { value: 7, label: 'Day 7', tasks: [] },
    { value: null, label: 'Any Day', tasks: [] },
];

const TASK_TYPES = {
    Assignment: 'assignments',
    Series: 'series',
    Matrix: 'matrices',
};

const PESequencesOverview = connect(mapStateToProps)(function (props) {
    const { sequence, tasks, categoriesOptions } = props;

    const [categoriesSet, setCategoriesSet] = useSetFromOptions();
    const [days, setDays] = useState(defaultDays);

    const { PE_POST_SEQUENCE_TASK_REQUEST, TOGGLE_MODAL, SET_MODAL_CONTENT, PE_POST_SEQUENCE_CHANGE_ORDER_REQUEST } =
        useActions();

    function addAssignment(taskId, day) {
        PE_POST_SEQUENCE_TASK_REQUEST({
            sequenceId: sequence.id,
            taskType: 'Assignment',
            taskId: taskId,
            day,
        });
    }

    function addSeries(taskId, day) {
        PE_POST_SEQUENCE_TASK_REQUEST({
            sequenceId: sequence.id,
            taskType: 'Series',
            taskId: taskId,
            day,
        });
    }

    function addMatrix(taskId, day) {
        PE_POST_SEQUENCE_TASK_REQUEST({
            sequenceId: sequence.id,
            taskType: 'Matrix',
            taskId: taskId,
            day,
        });
    }

    useEffect(() => {
        if (categoriesOptions) {
            setCategoriesSet(categoriesOptions);
        }
        // eslint-disable-next-line
    }, [categoriesOptions]);

    useEffect(() => {
        const newDays = [
            ...defaultDays.map((day) => {
                day.tasks = [];
                return day;
            }),
        ];
        if (tasks?.forEach) {
            tasks.forEach((task) => {
                if (task.day) {
                    newDays[task.day - 1]?.tasks?.push(task);
                } else {
                    newDays[7]?.tasks?.push(task);
                }
            });
        }
        setDays(newDays);
    }, [tasks]);

    const Day = useCallback(
        function ({ value, label, tasks = [] }) {
            const { PE_DELETE_SEQUENCE_TASK_REQUEST } = useActions();
            return (
                <div className="bg-light-section p-4 mb-8">
                    <h2>{label}</h2>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>Tags</th>
                                <th>Move</th>
                                <th>Remove</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks?.map((task,index) => (
                                <tr key={task.id}>
                                  <TableImage
                                        alt={task.task?.name}
                                        imageUrl={task.task?.poster_url}
                                    />
                                    <td>
                                    {task.task_type === 'Assignment' ? 
                                        (task.task?.category_id ? 
                                        <SelectableField
                                            text={task.name}
                                            url={`/program-engine/categories/${task.task?.category_id}/${
                                                TASK_TYPES[task.task_type]
                                            }/${task.task_id}`}
                                        />
                                        :  <span>{task.name}</span>)
                                        : <SelectableField
                                            text={task.name}
                                            url={`/program-engine/${
                                                TASK_TYPES[task.task_type]
                                            }/${task.task_id}`}
                                        />
                                    }
                                    </td>
                                    <td>
                                        <SelectableField
                                            text={
                                                categoriesSet[
                                                    task.task?.category_id
                                                ]
                                            }
                                            url={`/program-engine/categories/${task.task?.category_id}`}
                                        />
                                    </td>
                                    <td className="tags">
                                        <div className="tagbox">
                                            {task.task?.tags?.map((tag) => (
                                                <div
                                                    className="tag"
                                                    key={`tag-${task.id}-${tag.id}`}
                                                >
                                                    {tag.name}
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                <td>
                                <div className="flex">
                                        {task.position !== 1 && (
                                            <TableButton
                                                className="order-button"
                                                action={() =>
                                                    PE_POST_SEQUENCE_CHANGE_ORDER_REQUEST(
                                                        {
                                                            taskId: task.id,
                                                            sequenceId:task.sequence_id,
                                                            day: task.day,
                                                            position:
                                                                task.position
                                                                    ? task.position -
                                                                      1
                                                                    : 1,
                                                        }
                                                    )
                                                }
                                            >
                                                <ArrowUpIcon
                                                    height={'1.3rem'}
                                                />
                                            </TableButton>
                                        )}                                        
                                        {task.position !== tasks[tasks.length-1].position && (
                                            <TableButton
                                                className="order-button"
                                                action={() =>
                                                    PE_POST_SEQUENCE_CHANGE_ORDER_REQUEST(
                                                        {
                                                            taskId: task.id,
                                                            sequenceId:task.sequence_id,
                                                            day: task.day,
                                                            position:
                                                                task.position
                                                                    ? task.position +
                                                                      1
                                                                    : 1,
                                                        }
                                                    )
                                                }
                                            >
                                                <ArrowDownIcon
                                                    height={'1.3rem'}
                                                />
                                            </TableButton>
                                    )}
                                    </div>
                                </td>
                                    <td>
                                        <TableButton
                                            className="button delete-button"
                                            action={() =>
                                                PE_DELETE_SEQUENCE_TASK_REQUEST(
                                                    {
                                                        taskId: task.id,
                                                        sequenceId: sequence.id,
                                                    }
                                                )
                                            }
                                        >
                                            <XCircleIcon
                                                height={'1.3rem'}
                                                style={{
                                                    marginRight: '0.5rem',
                                                }}
                                            />
                                            Remove
                                        </TableButton>
                                    </td>
                                    <td>
                                    {task.task_type === 'Assignment' ? 
                                        (task.task?.category_id ? 
                                            <LinkButton
                                            className="table-view-button"
                                            url={`/program-engine/categories/${task.task?.category_id}/${
                                                TASK_TYPES[task.task_type]
                                            }/${task.task_id}`}
                                            >
                                            View{' '}
                                            <ArrowRightIcon
                                                height={'1.3rem'}
                                                className="ml-2"
                                            />
                                        </LinkButton>
                                        :  <span></span>)
                                        : <LinkButton
                                            className="table-view-button"
                                            url={`/program-engine/${
                                                TASK_TYPES[task.task_type]
                                            }/${task.task_id}`}
                                            >
                                            View{' '}
                                            <ArrowRightIcon
                                                height={'1.3rem'}
                                                className="ml-2"
                                            />
                                        </LinkButton>
                                    }
                                        
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="flex">
                        <button
                            className="button p-2 mt-2 ml-2 text-white flex items-center justify-center"
                            onClick={() => {
                                SET_MODAL_CONTENT(
                                    <PEAssignmentModalContent
                                        parentRegion={sequence.region_ident}
                                        onSelect={(task) =>
                                            addAssignment(task.id, value)
                                        }
                                    />
                                );
                                TOGGLE_MODAL();
                            }}
                        >
                            Add Assignment
                        </button>
                        <button
                            className="button p-2 mt-2 ml-2 text-white flex items-center justify-center"
                            onClick={() => {
                                SET_MODAL_CONTENT(
                                    <PESeriesModalContent
                                        parentRegion={sequence.region_ident}
                                        onSelect={(task) =>
                                            addSeries(task.id, value)
                                        }
                                    />
                                );
                                TOGGLE_MODAL();
                            }}
                        >
                            Add Series
                        </button>
                        <button
                            className="bg-green-add p-2 mt-2 ml-2 text-white flex items-center justify-center"
                            onClick={() => {
                                SET_MODAL_CONTENT(
                                    <PEMatricesModalContent
                                        parentRegion={sequence.region_ident}
                                        onSelect={(task) =>
                                            addMatrix(task.id, value)
                                        }
                                    />
                                );
                                TOGGLE_MODAL();
                            }}
                        >
                            Add Matrix
                        </button>
                    </div>
                </div>
            );
        },
        // eslint-disable-next-line
        [days]
    );

    return (
        <section>
            {days.map((day) => (
                <Day
                    key={`day-${day.value}`}
                    value={day.value}
                    label={day.label}
                    tasks={day.tasks}
                />
            ))}
        </section>
    );
});

export default PESequencesOverview;
