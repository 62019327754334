import InformationTab from 'components/InformationTab';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect, useState } from 'react';
import AnalyticsTable from 'routing/vhs/Analytics/AnalyticsTable';
import VHSMemberOverview from './MemberOverview';
import AttendsTable from '../AttendsTable';
import MembersHistory from '../MemberHistory';
import { useNavigate } from 'react-router-dom';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedMember: state.vhsMembers.selectedMember,
        activities: state.vhsMembers.activities,
        activities_page: state.vhsMembers.activities_page,
        activities_per_page: state.vhsMembers.activities_per_page,
        activities_total_pages: state.vhsMembers.activities_total_pages,
        activities_total: state.vhsMembers.activities_total,
        isLoadingActivity: state.vhsMembers.isLoadingActivity,
        attends: state.vhsMembers.attends,
        attends_page: state.vhsMembers.attends_page,
        attends_per_page: state.vhsMembers.attends_per_page,
        attends_total_pages: state.vhsMembers.attends_total_pages,
        attends_total: state.vhsMembers.attends_total,
        isLoadingAttends: state.vhsMembers.isLoadingAttends,
        favorited: state.vhsMembers.favorited,
        favorited_page: state.vhsMembers.favorited_page,
        favorited_per_page: state.vhsMembers.favorited_per_page,
        favorited_total_pages: state.vhsMembers.favorited_total_pages,
        favorited_total: state.vhsMembers.favorited_total,
        isLoadingFavorited: state.vhsMembers.isLoadingFavorited,
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

const VHSMemberDetails = connect(mapStateToProps)(function (props) {
    const {
        selectedMember,
        activities,
        activities_page,
        activities_per_page,
        activities_total_pages,
        activities_total,
        attends,
        attends_page,
        attends_per_page,
        attends_total_pages,
        attends_total,
        favorited,
        favorited_page,
        favorited_per_page,
        favorited_total_pages,
        favorited_total,
        brandsOptions,
        isLoadingActivity,
        isLoadingAttends,
        isLoadingFavorited,
    } = props;

    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();

    const { 
        VHS_FETCH_MEMBER_ACTIVITY_REQUEST,
        VHS_FETCH_MEMBER_ATTEND_REQUEST,
        VHS_FETCH_MEMBER_FAVORITED_REQUEST,
        VHS_SELECT_EPISODE
    } = useActions();
    const [brandsSet, loadBrandsSet] = useSetFromOptions();

    const onNextPage = () => {
        VHS_FETCH_MEMBER_ACTIVITY_REQUEST({
            per_page: activities_per_page,
            page: activities_page + 1,
            memberId: selectedMember.id,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_MEMBER_ACTIVITY_REQUEST({
            per_page: activities_per_page,
            page: activities_page - 1,
            memberId: selectedMember.id,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_MEMBER_ACTIVITY_REQUEST({
            per_page: activities_per_page,
            page: pageSelected,
            memberId: selectedMember.id,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_MEMBER_ACTIVITY_REQUEST({
            memberId: selectedMember.id,
            per_page: perPage,
        });
    };

    const onNextAttendsPage = () => {
        VHS_FETCH_MEMBER_ATTEND_REQUEST({
            per_page: attends_per_page,
            page: attends_page + 1,
            memberId: selectedMember.id,
        });
    };

    const onPrevAttendsPage = () => {
        VHS_FETCH_MEMBER_ATTEND_REQUEST({
            per_page: attends_per_page,
            page: attends_page - 1,
            memberId: selectedMember.id,
        });
    };

    const onPageAttendsSelect = (pageSelected) => {
        VHS_FETCH_MEMBER_ATTEND_REQUEST({
            per_page: attends_per_page,
            page: pageSelected,
            memberId: selectedMember.id,
        });
    };

    const onPerPageAttendsSelected = ({ perPage }) => {
        VHS_FETCH_MEMBER_ATTEND_REQUEST({
            memberId: selectedMember.id,
            per_page: perPage,
        });
    };

    const onNextFavoritedPage = () => {
        VHS_FETCH_MEMBER_FAVORITED_REQUEST({
            per_page: favorited_per_page,
            page: favorited_page + 1,
            memberId: selectedMember.id,
        });
    };

    const onPrevFavoritedPage = () => {
        VHS_FETCH_MEMBER_FAVORITED_REQUEST({
            per_page: favorited_per_page,
            page: favorited_page - 1,
            memberId: selectedMember.id,
        });
    };

    const onPageFavoritedSelect = (pageSelected) => {
        VHS_FETCH_MEMBER_FAVORITED_REQUEST({
            per_page: favorited_per_page,
            page: pageSelected,
            memberId: selectedMember.id,
        });
    };

    const onPerPageFavoritedSelected = ({ perPage }) => {
        VHS_FETCH_MEMBER_FAVORITED_REQUEST({
            memberId: selectedMember.id,
            per_page: perPage,
        });
    };

    const onDetailSelection = (episode) => {
        VHS_SELECT_EPISODE(episode);
        navigate(`/vhs/episodes/${episode.id}`);
    };

    useEffect(() => {
        loadBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        VHS_FETCH_MEMBER_ACTIVITY_REQUEST({ memberId: selectedMember.id });
        VHS_FETCH_MEMBER_ATTEND_REQUEST({ memberId: selectedMember.id });
        VHS_FETCH_MEMBER_FAVORITED_REQUEST({ memberId: selectedMember.id });
        // eslint-disable-next-line
    }, [selectedMember]);

    function activitiesSection() {
        return (
            <InformationTab
                showPrograms={false}
                sectionName="Activity"
                perPage={activities_per_page}
                onPerPageSelected={onPerPageSelected}
                dataTable={
                    <section>
                        <TableResults results={activities_total} />
                        <AnalyticsTable
                            activities={activities}
                            isLoading={isLoadingActivity}
                        />
                        <TableNavigator
                            currentPage={activities_page}
                            totalPages={activities_total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </section>
                }
                showBreadcrumbs={false}
            ></InformationTab>
        );
    }

    function favoritedSection() {
        return (
            <InformationTab
                showPrograms={false}
                sectionName="Favorited"
                perPage={favorited_per_page}
                onPerPageSelected={onPerPageFavoritedSelected}
                dataTable={
                    <section>
                        <TableResults results={favorited_total} />
                        <AttendsTable
                            onDetailSelection={onDetailSelection}
                            episodes={favorited}
                            isLoading={isLoadingFavorited}
                        />
                        <TableNavigator
                            currentPage={favorited_page}
                            totalPages={favorited_total_pages}
                            onNext={onNextFavoritedPage}
                            onPrev={onPrevFavoritedPage}
                            onPageSelected={onPageFavoritedSelect}
                        />
                    </section>
                }
                showBreadcrumbs={false}
            ></InformationTab>
        );
    }

    function attendsSection() {
        return (
            <InformationTab
                showPrograms={false}
                sectionName="Attended"
                perPage={attends_per_page}
                onPerPageSelected={onPerPageAttendsSelected}
                dataTable={
                    <section>
                        <TableResults results={attends_total} />
                        <AttendsTable
                            onDetailSelection={onDetailSelection}
                            episodes={attends}
                            isLoading={isLoadingAttends}
                        />
                        <TableNavigator
                            currentPage={attends_page}
                            totalPages={attends_total_pages}
                            onNext={onNextAttendsPage}
                            onPrev={onPrevAttendsPage}
                            onPageSelected={onPageAttendsSelect}
                        />
                    </section>
                }
                showBreadcrumbs={false}
            ></InformationTab>
        );
    }

    return (
        <section>
            <Tabs
                sectionNames={['Overview', 'Attended', 'Favorited', 'Activity', 'History']}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
                sectionContents={[
                    <VHSMemberOverview
                        member={selectedMember}
                        brandsSet={brandsSet}
                    />,
                    attendsSection(),
                    favoritedSection(),
                    activitiesSection(),
                    <MembersHistory
                        member={selectedMember}
                    />
                ]}
            />
        </section>
    );
});

export default VHSMemberDetails;
