/* eslint-disable react-hooks/exhaustive-deps */
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';
import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EpisodesDetail from './EpisodesDetail';
import EpisodesNew from './EpisodesNew';
import EpisodesTable from './EpisodesTable';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


function mapStateToProps(state) {
    return {
        episodes: state.vhsEpisodes.episodes,
        isLoading: state.vhsEpisodes.isLoadingEpisodes,
        per_page: state.vhsEpisodes.per_page,
        page: state.vhsEpisodes.page,
        total: state.vhsEpisodes.total,
        total_pages: state.vhsEpisodes.total_pages,
        selectedEpisode: state.vhsEpisodes.selectedEpisode,
        visibilityFilter: state.vhsEpisodes.visibilityFilter,
        seriesFilter: state.vhsEpisodes.seriesFilter,
        seriesOptions: state.vhsSeries.seriesOptions,
        locationFilter: state.vhsEpisodes.locationFilter,
        locationOptions: state.vhsLocations.locationOptions,
        brandsFilter: state.vhsEpisodes.brandsFilter,
        brandsOptions: state.vhsBrands.brandsOptions,
        instructorsFilter: state.vhsEpisodes.instructorsFilter,
        instructorsOptions: state.vhsInstructors.instructorsOptions,
        episodesOrder: state.vhsEpisodes.episodesOrder,
    };
}

const VHSEpisodes = connect(mapStateToProps)(function (props) {
    const {
        episodes = [],
        per_page,
        page,
        total,
        total_pages,
        selectedEpisode,
        visibilityFilter,
        seriesFilter,
        locationFilter,
        brandsFilter,
        seriesOptions,
        locationOptions,
        brandsOptions,
        episodesOrder,
        isLoading,
    } = props;
    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [detailComponent, setDetailComponent] = useState(null);

    const navigate = useNavigate();
    const {
        VHS_SELECT_EPISODE,
        VHS_FETCH_EPISODES_REQUEST,
        VHS_FETCH_EPISODE_REQUEST,
        VHS_DELETE_EPISODE_REQUEST,
        VHS_SELECT_EPISODES_VISIBILITY_FILTER,
        VHS_SELECT_EPISODES_SERIES_FILTER,
        VHS_SELECT_EPISODES_LOCATION_FILTER,
        VHS_SELECT_EPISODES_BRANDS_FILTER,
        VHS_SELECT_EPISODES_ORDER,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);

    const onDetailSelection = (episode) => {
        setSubtitle(null);
        setDetailComponent(null);
        VHS_SELECT_EPISODE(episode);
        // setShowDelete(true);
        if (episode) {
            navigate(`/vhs/episodes/${episode.id}`);
        } else {
            VHS_FETCH_EPISODES_REQUEST({
                visibility: visibilityFilter,
                series: seriesFilter,
                brand: brandsFilter,
                episodeLocation: locationFilter,
                per_page,
                page,
                sorting: episodesOrder,
                searchTerm,
            });
            navigate(`/vhs/episodes`);
        }
    };

    function onDeleteAction() {
        VHS_DELETE_EPISODE_REQUEST(selectedEpisode?.id);
        navigate('/vhs/episodes');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Episode?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    const onAdd = () => {
        setSubtitle('Add New Episode');
        setShowDelete(false);
        setDetailComponent(<EpisodesNew />);
    };

    const onNextPage = () => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page,
            page: page + 1,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page,
            page: page - 1,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page,
            page: pageSelected,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            per_page: perPage,
            sorting: episodesOrder,
            searchTerm,
        });
    };

    useEffect(() => {
        if (selectedEpisode) {
            setSubtitle(selectedEpisode?.name);
            setDetailComponent(<EpisodesDetail />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedEpisode]);

    useEffect(() => {
        if (id) {
            try {
                VHS_FETCH_EPISODE_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            VHS_SELECT_EPISODE(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
    }, [id]);

    useEffect(() => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            page,
            per_page,
            sorting: episodesOrder,
            searchTerm,
        });
    }, [seriesFilter, visibilityFilter, brandsFilter, episodesOrder, locationFilter]);

    useEffect(() => {
        VHS_FETCH_EPISODES_REQUEST({
            visibility: visibilityFilter,
            series: seriesFilter,
            brand: brandsFilter,
            episodeLocation: locationFilter,
            sorting: episodesOrder,
            searchTerm
        });
    }, []);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Visibility' },
                    ...VISIBILITY_OPTIONS,
                ]}
                selectedValue={visibilityFilter}
                onSelect={(value) =>
                    VHS_SELECT_EPISODES_VISIBILITY_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Locations' },
                    ...locationOptions,
                ]}
                selectedValue={locationFilter}
                onSelect={(value) =>
                    VHS_SELECT_EPISODES_LOCATION_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Series' },
                    ...seriesOptions,
                ]}
                selectedValue={seriesFilter}
                onSelect={(value) => VHS_SELECT_EPISODES_SERIES_FILTER(value)}
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Brands' },
                    ...brandsOptions,
                ]}
                selectedValue={brandsFilter}
                onSelect={(value) => VHS_SELECT_EPISODES_BRANDS_FILTER(value)}
            />,
            <SingleFilter
                selectedValue={episodesOrder}
                onSelect={(value) => VHS_SELECT_EPISODES_ORDER(value)}
                options={VHS_EPISODES_SORTING}
            />,
        ];
    }

    return (
        <section>
            <InformationTab
                sectionName="Episodes"
                customFilters={filters()}
                dataTable={
                    <div>
                        <TableResults results={total} addTitle="Add New Episode" onAdd={onAdd}/>
                        <EpisodesTable
                            onDetailSelection={onDetailSelection}
                            episodes={episodes}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </div>
                }
                showPrograms={false}
                onDetailSelected={onDetailSelection}
                subsectionName={subtitle}
                onPerPageSelected={onPerPageSelected}
                perPage={per_page}
                detailComponent={detailComponent}
                onDeleteDetail={onDeleteDetail}
                deleteText="Episode"
                showDelete={showDelete}
                showSearchField={true}
            />
        </section>
    );
});

export default VHSEpisodes;
