import moment from 'moment';
import api from '../api/api';

const { put } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* getConversions(per_page = 10, page = 1, selectedProgram = 'all') {
    let url = `/referrals/conversions?per_page=${per_page}&page=${page}`;
    if (selectedProgram !== 'all') {
        url += `&programs=${selectedProgram}`;
    }
    const json = yield api.get(url);

    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_CONVERSIONS_RESPONSE,
            data: {
                list: json.results || [],
                total: json.total,
                total_pages: json.total_pages,
                page: json.page,
                per_page: json.per_page,
            },
        });
    }
}

function* getActivity(memberId, perPage = 10) {
    const json = yield api.get(
        `/referrals/track_events?uuid=${memberId}&per_page=${perPage}`
    );
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_CONVERSION_ACTIVITY_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getConversion(conversionId) {
    const json = yield api.get(`/referrals/conversions/${conversionId}`);
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_CONVERSION_RESPONSE,
            data: json,
        });
    }
}

function* updateConversion(conversion, newStatus) {
    const { id } = conversion;
    const json = yield api.put('/referrals/conversions/' + id, {
        status: newStatus,
    });
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.UPDATE_CONVERSIONS_RESPONSE,
            data: json,
        });
    }
}

function* getChartData(period = '30', program = 'all') {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(Number.parseInt(period), 'd');
    const initialMillis = today.toDate().getTime();
    let url = `/referrals/chart?actions=conversion&start_int=${todayMillis}&end_int=${initialMillis}`;
    program !== 'all' && (url += `&programs=${program}`);
    period === '1' && (url += '&group_by=hour');
    const json = yield api.get(url);
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_VIEWS_CHART_RESPONSE,
            data: {
                list: json.results || [],
            },
        });
    }
}

function* postConversion(conversion) {
    const json = yield api.post(`/referrals/conversions`, conversion);
    if (json?.error) {
        alert(json.error);
        return;
    }

    if (json) {
        yield put({
            type: ACTION_TYPES.POST_CONVERSION_RESPONSE,
            data: json,
        });
    }
}

function* conversionsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_CONVERSIONS_REQUEST:
            yield getConversions(
                action.data.per_page,
                action.data.page,
                action.data.selectedProgram
            );
            break;
        case ACTION_TYPES.UPDATE_CONVERSIONS_REQUEST: {
            yield updateConversion(
                action.data.conversion,
                action.data.newStatus
            );
            break;
        }
        case ACTION_TYPES.FETCH_CONVERSION_REQUEST: {
            yield getConversion(action.data);
            break;
        }
        case ACTION_TYPES.FETCH_CONVERSION_ACTIVITY_REQUEST: {
            yield getActivity(action.data.uuid, action.data.perPage);
            break;
        }
        case ACTION_TYPES.FETCH_CONVERSIONS_CHART_REQUEST: {
            yield getChartData(
                action.data.selectedPeriod,
                action.data.selectedProgram
            );
            break;
        }
        case ACTION_TYPES.POST_CONVERSION_REQUEST: {
            yield postConversion(action.data);
            break;
        }
        default:
            break;
    }
}

export default conversionsWorker;
