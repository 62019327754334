import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';
import {SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';


function* fetchQuestions(page = 1, per_page = 10, apiUrl, searchTerm = '', answered = 'all', featured = 'all', trending = 'all', brand = 'all', sorting = 'all', reel = 'all') {
    let endpoint = `/community/posts?post_type=question&page=${page}&per_page=${per_page}`;
    if (!!searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    if (answered !== 'all') {
        endpoint += `&is_answered=${answered}`;
    }
    if (featured !== 'all') {
        endpoint += `&is_featured=${featured}`;
    }
    if (trending !== 'all') {
        endpoint += `&is_trending=${trending}`;
    }
    if (brand !== 'all') {
        endpoint += `&brands=${brand}`;
    }
    if (sorting !== 'all') {
        endpoint += `&sort=${sorting}`;
    }
    if (reel !== 'all') {
        endpoint += `&is_reel=${reel}`;
    }

    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.COMMUNITY);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_QUESTION_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getQuestion(questionId, apiUrl){
    let url = `/community/posts/${questionId}`;
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_QUESTION_RESPONSE,
            data: json,
        });
    }
}

function* delQuestion(questionId, apiUrl) {
    const json = yield api.del(`/community/posts/${questionId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_SELECT_QUESTION,
            data: null,
        });
        yield fetchQuestions(undefined,undefined,apiUrl,undefined,undefined,undefined,undefined,undefined,SORTING_OPTIONS[0].value);
    }
}

function* updateQuestion(question, apiUrl) {
    const { id } = question;
    delete question.id;
    const json = yield api.put(`/community/posts/${id}`, question, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_QUESTION_RESPONSE,
            data: json,
        });
    }
}

function* fetchFlags(page = 1, per_page = 10, questionId, status = 'all', sorting = 'all', apiUrl){
    let url = `/community/flags?resource_type=Post&resource_id=${questionId}&page=${page}&per_page=${per_page}`;
    if (sorting !== 'all') {
        url += `&sort=${sorting}`;
    }
    if (status !== 'all') {
        url += `&status=${status}`;
    }
    const json = yield api.get(url, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_QUESTION_FLAGS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* updateReasonFlags(body,page, per_page, questionId, status, sorting, apiUrl){
    const { id } = body;
    delete body.id;
    const json = yield api.put(`/community/flags/${id}`, body, apiUrl);
    if (json) {
       yield fetchFlags(page, per_page, questionId, status, sorting, apiUrl);
    }
}

function* addTag(questionId, tagId, apiUrl) {
    const json = yield api.post(
        '/community/content_tags',
        {
            content_type: 'Post',
            content_id: questionId,
            tag_id: tagId,
        },
        apiUrl
    );
    if (json) {
        yield getQuestion(questionId, apiUrl);
    }
}
function* removeTag(questionId, tagId, apiUrl) {
    const json = yield api.del(
        `/community/content_tags/Post/${questionId}/${tagId}`,
        apiUrl
    );
    if (json) {
        yield getQuestion(questionId, apiUrl);
    }
}
function* getQuestionFiles(questionId, apiUrl) {
    const json = yield api.get(
        `/community/assets?assetable_type=Post&assetable_id=${questionId}&per_page=100`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_QUESTION_FILES_RESPONSE,
            data: json.results,
        });
    }
}

function* postAsset(questionId, brandIdent, asset_type, slot, file, done, apiUrl) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brand_ident', brandIdent);
    formData.append('assetable_id', questionId);
    formData.append('asset_type', asset_type);
    formData.append('slot', slot);
    formData.append('assetable_type', 'Post');
    const json = yield api.postForm('/community/assets',formData,apiUrl);
    if (json.status_code && ![200, 201, 202].includes(json.status_code)) {
        alert(json.message);
    } else {
        if (done) done();

        yield getQuestionFiles(questionId, apiUrl);
    }
}

function* deleteAsset(assetId, questionId, apiUrl) {
    const json = yield api.del('/community/assets/' + assetId, apiUrl);
    if (json) {
        console.log('file Deleted');
        if(questionId){
            yield getQuestionFiles(questionId, apiUrl);
        }
    }
}

function* assignAsset(questionId, assetId, apiUrl) {
    const json = yield api.post(`/community/assets/${assetId}/assign`, {}, apiUrl);
    if (json) {
        yield getQuestion(questionId, apiUrl);
    }
}
function* unAssignAsset(questionId, assetId, apiUrl) {
    const json = yield api.post(`/community/assets/${assetId}/unassign`, {}, apiUrl);
    if (json) {
        yield getQuestion(questionId, apiUrl);
    }
}

function* fetchTranslations(questionId, page = 1, per_page = 10, apiUrl) {
    const json = yield api.get(
        `/community/translations?resource_type=question&resource_id=${questionId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_QUESTION_TRANSLATIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postTranslation(resource_id, translation, apiUrl) {
    const json = yield api.post(
        `/community/translations`,
        {
            resource_id,
            resource_type: 'Post',
            ...translation,
        },
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_QUESTION_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}

function* deleteTranslation(
    translationId,
    questionId,
    page,
    perPage,
    apiUrl
) {
    const json = yield api.del(`/community/translations/${translationId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_SELECT_QUESTION_TRANSLATION,
            data: null,
        });
        yield fetchTranslations(questionId, page, perPage, apiUrl);
    }
}

function* updateTranslation(translation, apiUrl) {
    const json = yield api.put(
        `/community/translations/${translation.id}`,
        translation,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_QUESTION_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}

function* postQuestion(question, apiUrl) {
    const json = yield api.post(`/community/posts`, question, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_QUESTION_RESPONSE,
            data: json,
        });
    }
}


function* csQuestionsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_QUESTION_REQUEST:
            yield fetchQuestions(
                action.data?.page,
                action.data?.per_page,
                apiUrl,
                action.data?.searchTerm,
                action.data?.answered,
                action.data?.featured,
                action.data?.trending,
                action.data?.brand,
                action.data?.sorting,
                action.data?.reel,
            );
            break;
        case ACTION_TYPES.CS_FETCH_QUESTION_REQUEST:
            yield getQuestion(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_DELETE_QUESTION_REQUEST:
            yield delQuestion(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_UPDATE_QUESTION_REQUEST:
            yield updateQuestion(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_FETCH_QUESTION_FLAGS_REQUEST:
            yield fetchFlags(
                action.data?.page,
                action.data?.per_page,
                action.data?.questionId,
                action.data?.status,
                action.data?.sorting,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UPDATE_QUESTION_FLAGS_REASON_REQUEST:
            yield updateReasonFlags(
                action.data?.body,
                action.data?.page,
                action.data?.per_page,
                action.data?.questionId,
                action.data?.status,
                action.data?.sorting,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_ADD_TAG_TO_QUESTION_REQUEST:
            yield addTag(action.data.questionId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.CS_REMOVE_TAG_FROM_QUESTION_REQUEST:
            yield removeTag(action.data.questionId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.CS_FETCH_QUESTION_FILES_REQUEST:
                yield getQuestionFiles(action.data, apiUrl);
                break;
        case ACTION_TYPES.CS_POST_QUESTION_FILE_REQUEST:
            yield postAsset(
                action.data?.questionId,
                action.data?.brandIdent,
                action.data?.assetType,
                action.data?.slot,
                action.data?.file,
                action.data?.done,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_DELETE_QUESTION_FILE_REQUEST:
            yield deleteAsset(
                action.data?.assetId,
                action.data?.questionId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_ASSIGN_QUESTION_ASSET_REQUEST:
            yield assignAsset(
                action.data?.questionId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UNASSIGN_QUESTION_ASSET_REQUEST:
            yield unAssignAsset(
                action.data?.questionId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FETCH_QUESTION_TRANSLATIONS_REQUEST:
            yield fetchTranslations(
                action.data?.questionId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_POST_QUESTION_TRANSLATION_REQUEST:
            yield postTranslation(
                action.data?.resourceId,
                action.data?.translation,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_DELETE_QUESTION_TRANSLATION_REQUEST:
            yield deleteTranslation(
                action.data?.translationId,
                action.data?.questionId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_UPDATE_QUESTION_TRANSLATION_REQUEST:
            yield updateTranslation(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_POST_QUESTION_REQUEST:
            yield postQuestion(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default csQuestionsWorker;
