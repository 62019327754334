import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        brandsOptions: state.PEBrands.brandsOptions,
        selectedTagGroup: state.PETags.selectedTagGroup,
    };
}

const PENewTagGroup = connect(mapStateToProps)(function (props) {
    const { brandsOptions, selectedTagGroup } = props;

    const navigate = useNavigate();
    const { PE_POST_TAG_GROUP_REQUEST } = useActions();

    const [tagGroup, handleFieldChange] = useInputState({
        name: '',
        position: null,
        brand: '',
    });

    const onConfirm = (event) => {
        event.preventDefault();
        PE_POST_TAG_GROUP_REQUEST({
            name: tagGroup.name,
            position: tagGroup.position,
            brand_ident: tagGroup.brand,
        });
    };

    useEffect(() => {
        if (brandsOptions?.length > 0) {
            handleFieldChange({
                target: { name: 'brand', value: brandsOptions[0].value },
            });
        }
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        if (selectedTagGroup) {
            navigate('/program-engine/tags');
        }
        // eslint-disable-next-line
    }, [selectedTagGroup]);

    return (
        <section>
            <div className="flex mb-4 items-center h-16">
                <div className="flex flex-1 items-center">
                    <div
                        className="font-bold cursor-pointer  text-lg"
                        onClick={() => {
                            navigate('/program-engine/tags');
                        }}
                    >
                        Tag Groups
                    </div>
                    <div className="ml-8 font-medium text-blue-600">
                        New Group
                    </div>
                </div>
            </div>
            <form onSubmit={onConfirm}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    name="name"
                                    value={tagGroup.name}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Position</td>
                            <td>
                                <input
                                    name="position"
                                    type="number"
                                    value={tagGroup.position}
                                    onChange={handleFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <select
                                    name="brand"
                                    value={tagGroup.brand}
                                    onChange={handleFieldChange}
                                >
                                    {brandsOptions.map((brand) => (
                                        <option
                                            key={brand.value}
                                            value={brand.label}
                                        >
                                            {brand.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Tag Group
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default PENewTagGroup;
