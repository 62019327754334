import peTagsWorker from '../workers/peTagsWorker';

const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');

function* PE_TAGS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_TAG_GROUP_REQUEST,
            ACTION_TYPES.PE_TAG_REQUEST,
            ACTION_TYPES.PE_POST_TAG_GROUP_REQUEST,
            ACTION_TYPES.PE_POST_TAG_REQUEST,
            ACTION_TYPES.PE_UPDATE_TAG_REQUEST,
            ACTION_TYPES.PE_UPDATE_TAG_GROUP_REQUEST,
            ACTION_TYPES.PE_FETCH_TAG_GROUPS_REQUEST,
            ACTION_TYPES.PE_FETCH_ALL_TAG_GROUPS_REQUEST,
            ACTION_TYPES.PE_DELETE_TAG_GROUP_REQUEST,
            ACTION_TYPES.PE_DELETE_TAG_REQUEST,
        ],
        peTagsWorker,
        apiUrl,
        ...params
    );
}

export default PE_TAGS_REQUEST;
