const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const {
    default: vhsAnalyticsWorker,
} = require('../workers/vhsAnalyticsWorker');

function* VHS_ANALYTICS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_FETCH_ACTIVITIES_REQUEST,
            ACTION_TYPES.VHS_EXPORT_TRACKING_DATA_REQUEST,
            ACTION_TYPES.VHS_FETCH_ACTIVITY_REQUEST,
            ACTION_TYPES.VHS_FETCH_ACTIVITY_CHART_REQUEST,
        ],
        vhsAnalyticsWorker,
        apiUrl,
        ...params
    );
}

export default VHS_ANALYTICS_REQUEST;
