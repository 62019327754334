import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RetagEpisodes from './RetagEpisodes';
import EpisodeProcessHelper from './EpisodeProcessHelper';
import ReassignInstructors from './ReassignInstructors';

function mapStateToProps(state) {
    return {
        members: state.vhsMembers
    };
}

const VHSAdminControls = connect(mapStateToProps)(function(props) {
    const { members } = props;

    const [permsLoaded, setPermsLoaded] = useState(false);
    const {
        VHS_FETCH_MEMBER_PERMISSIONS_REQUEST
    } = useActions();

    useEffect(() => {
        if(!members?.currentMemberPermissions && !permsLoaded) {
            setPermsLoaded(false);
            VHS_FETCH_MEMBER_PERMISSIONS_REQUEST();
        }
    }, [VHS_FETCH_MEMBER_PERMISSIONS_REQUEST, members, permsLoaded]);

    useEffect(() => {
        if(members?.currentMemberPermissions) {
            setPermsLoaded(true);
        }
    }, [members]);

    if(!members?.currentMemberPermissions?.roles?.indexOf('Administrator') < 0) {
        return (
            <section>
                <h1>Unauthorized</h1>
            </section>
        );
    } else {
        return (
            <section>
                <h1>VHS Admin Control Panel</h1>
                <RetagEpisodes/>
                <ReassignInstructors/>
                <EpisodeProcessHelper/>
            </section>
        );
    }
});

export default VHSAdminControls;