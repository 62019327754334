import ACTION_TYPES from 'constants/ACTION_TYPES';
import { useDispatch } from 'react-redux';

function useActions() {
    const dispatch = useDispatch();
    return Object.keys(ACTION_TYPES).reduce((allActions, currentKey) => {
        const nextAllActions = allActions;
        nextAllActions[currentKey] = (data) => {
            dispatch({ type: currentKey, data });
        };
        return nextAllActions;
    }, {});
}

export default useActions;
