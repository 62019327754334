import peSequencesWorker from '../workers/peSequencesWorker';

const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');

function* PE_SEQUENCES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_SEQUENCES_REQUEST,
            ACTION_TYPES.PE_GET_SEQUENCE_REQUEST,
            ACTION_TYPES.PE_UPDATE_SEQUENCE_REQUEST,
            ACTION_TYPES.PE_DELETE_SEQUENCE_REQUEST,
            ACTION_TYPES.PE_POST_SEQUENCE_REQUEST,
            ACTION_TYPES.PE_ADD_TAG_TO_SEQUENCE_REQUEST,
            ACTION_TYPES.PE_REMOVE_TAG_FROM_SEQUENCE_REQUEST,
            ACTION_TYPES.PE_FETCH_SEQUENCE_TAG_GROUPS_REQUEST,
            ACTION_TYPES.PE_GET_SEQUENCE_TASKS_REQUEST,
            ACTION_TYPES.PE_POST_SEQUENCE_TASK_REQUEST,
            ACTION_TYPES.PE_DELETE_SEQUENCE_TASK_REQUEST,
            ACTION_TYPES.PE_POST_SEQUENCE_CHANGE_ORDER_REQUEST,
        ],
        peSequencesWorker,
        apiUrl,
        ...params
    );
}

export default PE_SEQUENCES_REQUEST;
