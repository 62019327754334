import useActions from 'hooks/useActions';
import { useEffect, useReducer } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        config: state.admins.config,
        loggedUser: state.user.user,
    };
}

const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    allBrands: [],
    allServices: [],
    allRegions: [],
    brands: [],
    services: [],
    regions: [],
};

function reducer(state, payload) {
    switch (payload.type) {
        case 'first_name':
            return {
                ...state,
                first_name: payload.data,
            };
        case 'last_name':
            return {
                ...state,
                last_name: payload.data,
            };
        case 'email':
            return {
                ...state,
                email: payload.data,
            };
        case 'brands': {
            let brands = state.brands;
            const index = brands.indexOf(payload.data);
            if (payload.data === 'all') {
                if (index > -1) {
                    brands = [];
                } else {
                    brands = [
                        'all',
                        ...state.allBrands.map((brand) => brand.value),
                    ];
                }
            } else if (index > -1) {
                brands = brands.filter((el) => el !== payload.data);
            } else {
                brands = [...brands, payload.data];
            }
            return {
                ...state,
                brands,
            };
        }
        case 'services': {
            let services = state.services;
            const index = services.indexOf(payload.data);
            if (payload.data === 'all') {
                if (index > -1) {
                    services = [];
                } else {
                    services = [
                        'all',
                        ...state.allServices.map((service) => service.value),
                    ];
                }
            } else if (index > -1) {
                services = services.filter((el) => el !== payload.data);
            } else {
                services = [...services, payload.data];
            }
            return {
                ...state,
                services,
            };
        }
        case 'regions': {
            let regions = state.regions;
            const index = regions?.indexOf(payload.data);
            if (payload.data === 'all') {
                if (index > -1) {
                    regions = [];
                } else {
                    regions = [
                        'all',
                        ...state.allRegions?.map((region) => region.value),
                    ];
                }
            } else if (index > -1) {
                regions = regions?.filter((el) => el !== payload.data);
            } else {
                regions = [...regions, payload.data];
            }

            return {
                ...state,
                regions,
            };
        }
        case 'load_config':
            return {
                ...state,
                allBrands: payload.data.brands,
                allServices: payload.data.services,
                allRegions: payload.data.regions,
            };
        default:
            return state;
    }
}

const AdminNew = connect(mapStateToProps)(function (props) {
    const { config, loggedUser } = props;
    const [user, dispatch] = useReducer(reducer, initialState);
    const { POST_ADMIN_REQUEST } = useActions();

    const isToDisplayRegions = (
        (
            user?.brands?.includes('all') ||
            user?.brands?.includes('af')
        ) &&
        (
            user?.services?.includes('all') ||
            user?.services?.includes('usr') ||
            user?.services?.includes('cs')
        )
    );
    function onFieldChange(event) {
        dispatch({
            type: event.target.name,
            data: event.target.value,
        });
    }

    function onCreate(event) {
        event.preventDefault();
        const newUser = {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            brand_permissions: user.brands.includes('all')
                ? 'all'
                : user.brands.join('-'),
            region_permissions: user.regions?.includes('all')
                ? 'all'
                : user.regions?.join('|'),
            service_permissions: user.services.includes('all')
                ? 'all'
                : user.services.join('-'),
        };
        POST_ADMIN_REQUEST(newUser);
    }

    useEffect(() => {
        if (config) {
            let brandsArray = [...config.brands];
            let regionsArray = [...config.regions];
           let servicesArray = [...config.services];
           if(!loggedUser.brand_permissions?.includes('all')){
               brandsArray = brandsArray.filter(item => item.value !== 'all')
            }
            if(!loggedUser.region_permissions?.includes('all')){
                regionsArray = regionsArray.filter(item => item.value !== 'all')
            }
            if(!loggedUser.service_permissions?.includes('all')){
            servicesArray = servicesArray.filter(item => item.value !== 'all')
            }
            dispatch({
                type: 'load_config',
                data: {
                    brands: brandsArray,
                    regions: regionsArray,
                    services: servicesArray,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    return (
        <section>
            <form onSubmit={onCreate}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">First Name</td>
                            <td>
                                <input
                                    name="first_name"
                                    value={user.first_name}
                                    onChange={onFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Last Name</td>
                            <td>
                                <input
                                    name="last_name"
                                    value={user.last_name}
                                    onChange={onFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Email</td>
                            <td>
                                <input
                                    name="email"
                                    value={user.email}
                                    onChange={onFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brands</td>
                            <td>
                                {user.allBrands.map((brand) => (
                                    <div
                                        key={`brand-${brand.value}`}
                                        className="table-checkbox"
                                    >
                                        <input
                                            id={`brand-${brand.value}`}
                                            onChange={onFieldChange}
                                            type="checkbox"
                                            name="brands"
                                            value={brand.value}
                                            checked={user.brands.includes(
                                                brand.value
                                            )}
                                            disabled={
                                                brand.value !== 'all' &&
                                                user.brands.includes('all')
                                            }
                                        />
                                        <label htmlFor={`brand-${brand.value}`}>
                                            {brand.label}
                                        </label>
                                    </div>
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Services</td>
                            <td>
                                {user.allServices.map((service) => (
                                    <div
                                        key={`service-${service.value}`}
                                        className="table-checkbox"
                                    >
                                        <input
                                            id={`service-${service.value}`}
                                            onChange={onFieldChange}
                                            type="checkbox"
                                            name="services"
                                            value={service.value}
                                            checked={user.services.includes(
                                                service.value
                                            )}
                                            disabled={
                                                service.value !== 'all' &&
                                                user.services.includes('all')
                                            }
                                        />
                                        <label
                                            htmlFor={`service-${service.value}`}
                                        >
                                            {service.label}
                                        </label>
                                    </div>
                                ))}
                            </td>
                        </tr>
                        {isToDisplayRegions && (
                            <tr>
                                <td className="font-bold label">Regions</td>
                                <td>
                                    {user?.allRegions?.map((region) => (
                                        <div
                                            key={`region-${region.value}`}
                                            className="table-checkbox"
                                        >
                                            <input
                                                id={`region-${region.value}`}
                                                onChange={onFieldChange}
                                                type="checkbox"
                                                name="regions"
                                                value={region.value}
                                                checked={user?.regions?.includes(
                                                    region.value
                                                )}
                                                disabled={
                                                    region.value !== 'all' &&
                                                    user?.regions?.includes(
                                                        'all'
                                                    )
                                                }
                                            />
                                            <label htmlFor={`region-${region.value}`}>
                                                {region.label}
                                            </label>
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Admin
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default AdminNew;
