const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsTagsWorker } = require('../workers/vhsTagsWorker');

function* VHS_TAGS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_FETCH_ALL_TAGS_REQUEST,
            ACTION_TYPES.VHS_TAG_GROUP_REQUEST,
            ACTION_TYPES.VHS_TAG_REQUEST,
            ACTION_TYPES.VHS_POST_TAG_GROUP_REQUEST,
            ACTION_TYPES.VHS_POST_TAG_REQUEST,
            ACTION_TYPES.VHS_UPDATE_TAG_REQUEST,
            ACTION_TYPES.VHS_UPDATE_TAG_GROUP_REQUEST,
            ACTION_TYPES.VHS_FETCH_TAG_GROUPS_REQUEST,
            ACTION_TYPES.VHS_FETCH_ALL_TAG_GROUPS_REQUEST,
            ACTION_TYPES.VHS_DELETE_TAG_GROUP_REQUEST,
            ACTION_TYPES.VHS_DELETE_TAG_REQUEST,
            ACTION_TYPES.VHS_REPLACE_TAGS_REQUEST
        ],
        vhsTagsWorker,
        apiUrl,
        ...params
    );
}

export default VHS_TAGS_REQUEST;
