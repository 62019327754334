import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import PEProgramEdit from '../ProgramEdit';
import PEProgramOverview from '../ProgramOverview';
import PEProgramTags from '../ProgramTags';
import PEProgramTranslations from '../ProgramTranslations';
import useQueryList from 'hooks/useQuery';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        program: state.PEPrograms.selectedProgram,
        brandsFilter: state.PEPrograms.brandsFilter,
    };
}

const PEProgramDetails = connect(mapStateToProps)(function (props) {
    const { program, brandsFilter} = props;
    const { PE_FETCH_PROGRAM_PHASES_REQUEST, PE_FETCH_PROGRAMS_REQUEST, PE_FETCH_PROGRAM_TRANSLATIONS_REQUEST } = useActions();
    const [selectedTab, setSelectedTab] = useState(0);
    const { term: searchTerm } = useQueryList(['term']);

    function goOverview() {
        PE_FETCH_PROGRAMS_REQUEST({ searchTerm, brand: brandsFilter });
        setSelectedTab(0);
    }
    function goTranslations() {
        PE_FETCH_PROGRAM_TRANSLATIONS_REQUEST({ programId: program?.id });
        setSelectedTab(3);
    }

    useEffect(() => {
        PE_FETCH_PROGRAM_PHASES_REQUEST(program?.id);
        // eslint-disable-next-line
    }, [program]);

    return (
        <Tabs
            sectionNames={['Overview', 'Settings', 'Tags', 'Translations']}
            sectionContents={[
                <PEProgramOverview />,
                <PEProgramEdit program={program} onSaveCallback={goOverview} />,
                <PEProgramTags />,
                <PEProgramTranslations onSaveCallback={goTranslations} />,
            ]}
            selectedTab={selectedTab}
            onTabChange={(tab) => setSelectedTab(tab)}
        />
    );
});

export default PEProgramDetails;
