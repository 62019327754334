import DetailOverview from 'components/DetailOverview';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        question: state.CSQuestions.selectedQuestion,
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

const CSQuestionOverview = connect(mapStateToProps)(function ({
    question,
    brandsOptions,
}) {
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    return (
        <section>
        {question.poster_url && (
                <img
                    alt={question.title}
                    src={question.poster_url}
                    style={{ maxWidth: '25rem' }}
                    className="mb-4 mt-4"
                />
            )}
        <DetailOverview
            fields={[
                { name: 'Question', value: question.title },
                { name: 'Description', value: question.body, type: 'markdown' },
                { name: 'Posted By', value: question.member.name },
                { name: 'Posted At', value: question.posted_int, type: 'date' },
                { name: 'Featured', value: question.is_featured ? 'Featured' : 'Not Featured' },
                { name: 'Trending', value: question.is_trending ? 'Trending' : 'Not Trending' },
                { name: 'Reel', value: question.is_reel ? 'Reel' : 'Not Reel' },
                { name: 'Visibility', value: question.visibility },
                {
                    name: 'Brand',
                    value: brandsSet[question.brand_ident],
                },
                { name: 'Region', value: question.region_ident },
                { name: 'Answers', value: question.responses_count },
                { name: 'Likes', value: question.liked_count },
                { name: 'Flags', value: question.flag_ids.length },
            ]}
        />
        </section>
    );
});

export default CSQuestionOverview;
