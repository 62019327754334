import { useNavigate } from 'react-router-dom';

function SelectableField({ children, text, action = function () {}, url }) {
    const navigate = useNavigate();
    return (
        <a
            className="selectable-field"
            onClick={
                url
                    ? (event) => {
                          event.preventDefault();
                          navigate(url);
                      }
                    : (event) => {
                          event.preventDefault();
                          action();
                      }
            }
            href={url}
        >
            {children}
            {text}
        </a>
    );
}

export default SelectableField;
