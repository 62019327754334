const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: vhsLocationWorker } = require('../workers/vhsLocationWorker');

function* VHS_LOCATION_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.VHS_FETCH_ALL_LOCATION_REQUEST,
            ACTION_TYPES.VHS_FETCH_LOCATIONS_REQUEST,
            ACTION_TYPES.VHS_FETCH_LOCATION_REQUEST,
            ACTION_TYPES.VHS_UPDATE_LOCATION_REQUEST,
            ACTION_TYPES.VHS_FETCH_LOCATION_HISTORY_REQUEST,
        ],
        vhsLocationWorker,
        apiUrl,
        ...params
    );
}

export default VHS_LOCATION_REQUEST;

