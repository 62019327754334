import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import VHS_EPISODES_SORTING from 'constants/VHS_EPISODES_SORTING';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import EpisodesTable from 'routing/vhs/Episodes/EpisodesTable';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        episodes: state.vhsInstructors.episodes,
        episodes_page: state.vhsInstructors.episodes_page,
        episodes_per_page: state.vhsInstructors.episodes_per_page,
        episodes_total_pages: state.vhsInstructors.episodes_total_pages,
        episodesOrder: state.vhsInstructors.episodesOrder,
        selectedInstructor: state.vhsInstructors.selectedInstructor,
        isLoading: state.vhsInstructors.isLoadingEpisodes,
    };
}

const InstructorEpisodes = connect(mapStateToProps)(function (props) {
    const {
        episodes,
        episodes_page,
        episodes_per_page,
        episodes_total_pages,
        episodesOrder,
        selectedInstructor,
        isLoading,
    } = props;
    const {
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST,
        VHS_SELECT_INSTRUCTOR_EPISODES_ORDER,
    } = useActions();

    const onNextPage = () => {
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST({
            per_page: episodes_per_page,
            page: episodes_page + 1,
            order: episodesOrder,
            instructorId: selectedInstructor?.id,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST({
            per_page: episodes_per_page,
            page: episodes_page - 1,
            order: episodesOrder,
            instructorId: selectedInstructor?.id,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST({
            per_page: episodes_per_page,
            page: pageSelected,
            order: episodesOrder,
            instructorId: selectedInstructor?.id,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST({
            per_page: perPage,
            order: episodesOrder,
            instructorId: selectedInstructor?.id,
        });
    };

    useEffect(() => {
        VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST({
            per_page: episodes_per_page,
            page: episodes_page,
            order: episodesOrder,
            instructorId: selectedInstructor?.id,
        });
        // eslint-disable-next-line
    }, [episodesOrder]);

    return (
        <section>
            <div className="flex mb-4">
                <div className="flex-1 font-bold cursor-pointer text-lg">
                    Episodes
                </div>
                <SingleFilter
                    selectedValue={episodes_per_page}
                    onSelect={onPerPageSelected}
                    options={[
                        { value: 10, label: '10 Per Page' },
                        { value: 30, label: '30 Per Page' },
                        { value: 100, label: '100 Per Page' },
                    ]}
                />
                <SingleFilter
                    selectedValue={episodesOrder}
                    onSelect={(value) =>
                        VHS_SELECT_INSTRUCTOR_EPISODES_ORDER(value)
                    }
                    options={VHS_EPISODES_SORTING}
                />
            </div>
            <EpisodesTable episodes={episodes} isLoading={isLoading} />
            <TableNavigator
                currentPage={episodes_page}
                totalPages={episodes_total_pages}
                onNext={onNextPage}
                onPrev={onPrevPage}
                onPageSelected={onPageSelect}
            />
        </section>
    );
});

export default InstructorEpisodes;
