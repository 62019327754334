/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from 'components/Tabs';
import { useState, useEffect } from 'react';
import CSQuestionOverview from '../QuestionOverview';
import CSQuestionEdit from '../QuestionEdit';
import CSQuestionFlags from '../QuestionFlags';
import CSQuestionTags from '../QuestionTags';
import CSQuestionFiles from '../QuestionFiles';
import CSQuestionTranslations from '../QuestionTranslations';
import CSQuestionAnswers from '../QuestionAnswers';
import useActions from 'hooks/useActions';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        question: state.CSQuestions.selectedQuestion,
    };
}

const QuestionDetail = connect(mapStateToProps)(function (props) {
    const { question } = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const { CS_FETCH_QUESTION_FILES_REQUEST, CS_SELECT_QUESTION_ANSWER, CS_FETCH_TAG_GROUPS_REQUEST } = useActions();
    function onSave() {
        setSelectedTab(0);
    }

    useEffect(() => {
        CS_FETCH_QUESTION_FILES_REQUEST(question?.id);
        CS_FETCH_TAG_GROUPS_REQUEST({regionIdent: question?.region_ident});
        CS_SELECT_QUESTION_ANSWER(null);
    }, []);

    return (
        <section>
            <Tabs
                sectionNames={[
                    'Overview',
                    'Settings',
                    'Answers',
                    'Flags',
                    'Tags',
                    'File',
                    'Translations',
                ]}
                sectionContents={[
                   <CSQuestionOverview/>,
                   <CSQuestionEdit question={question} onConfirm={onSave} />,
                   <CSQuestionAnswers />,
                   <CSQuestionFlags />,
                   <CSQuestionTags />,
                   <CSQuestionFiles />,
                   <CSQuestionTranslations />,
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
});

export default QuestionDetail;
