import DetailOverview from 'components/DetailOverview';

function LocationOverview({ location }) {
    return (
        <DetailOverview
            fields={[
                { name: 'Name', value: location.name },
                { name: 'Address', value: `${location.address1}, ${location.address2}<br/>${location.city}, ${location.state} ${location.zip}`, type: 'html' },
                { name: 'Email', value: location.email },
                { name: 'Phone', value: location.phone },
                { name: 'Brand', value: location.brand_name },
                { name: 'Remote ID', value: location.alt_id },
                { name: 'Latitude', value: location.lat },
                { name: 'Longitude', value: location.lng },
                { name: 'Description', value: location.description },
                { name: 'Episodes Expire After', value: `${location.episodes_expires_after} ${location.episodes_expires_after < 2 ? 'day' : 'days'}` },
                { name: 'Created', value: location.created_int, type: 'date' },
                { name: 'Updated', value: location.updated_int, type: 'date' },
            ]}
        />
    );
}

export default LocationOverview;
