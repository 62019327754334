function TableImage({ alt, imageUrl }) {
    return imageUrl ? (
        <td className="flex items-center justify-center">
            <img alt={alt} src={imageUrl} className="table-image" />
        </td>
    ) : (
        <td></td>
    );
}

export default TableImage;
