import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    activities: [],
    isLoadingActivities: false,
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    selectedActivity: null,
    actionsFilter: 'all',
    periodFilter: '30',
    chartData: [],
    isLoadingExportData: false,
    exportData: {}
};

function VHSAnalyticsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ACTIVITIES_REQUEST: {
            return {
                ...state,
                isLoadingActivities: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_ACTIVITIES_RESPONSE: {
            return {
                ...state,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                activities: action.data.list,
                isLoadingActivities: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_ACTIVITY_RESPONSE: {
            return {
                ...state,
                selectedActivity: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_ACTIVITY: {
            return {
                ...state,
                selectedActivity: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_ACTIVITY_ACTIONS_FILTER:
            return {
                ...state,
                page: 0,
                actionsFilter: action.data,
            };
        case ACTION_TYPES.VHS_SELECT_ACTIVITY_FILTER:
            return {
                ...state,
                periodFilter: action.data,
            };
        case ACTION_TYPES.VHS_FETCH_ACTIVITY_CHART_RESPONSE:
            return {
                ...state,
                chartData: action.data,
            };
        case ACTION_TYPES.VHS_EXPORT_TRACKING_DATA_REQUEST: {
            return {
                ...state,
                isLoadingExportData: true,
            };
        }
        case ACTION_TYPES.VHS_EXPORT_TRACKING_DATA_RESPONSE: {
            return {
                ...state,
                exportData: action.data,
                isLoadingExportData: false,
            };
        }
        case ACTION_TYPES.VHS_EXPORT_TRACKING_DATA_RESET: {
            return {
                ...state,
                exportData: {},
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSAnalyticsReducer;
