import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchAssignments(page = 1, per_page = 10, apiUrl, searchTerm = '', region_ident = '', tagsQuery = '') {
    let endpoint = `/pe/assignments?page=${page}&per_page=${per_page}`;
    if (!!searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    if (!!tagsQuery) {
        endpoint += `&force_tags=${tagsQuery}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const regionToFilter = region_ident ? region_ident : selectedRegion;

    const json = yield api.get(endpoint, apiUrl, regionToFilter, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_ASSIGNMENTS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getAssignment(assignmentId, apiUrl) {
    const json = yield api.get(`/pe/assignments/${assignmentId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_ASSIGNMENT_RESPONSE,
            data: json,
        });
    }
}

function* postAssignment(assignment, apiUrl) {
    const json = yield api.post(`/pe/assignments`, assignment, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_ASSIGNMENT_RESPONSE,
            data: json,
        });
        yield fetchAssignments(1,10,apiUrl);
    }
}

function* updateAssignment(assignment, apiUrl) {
    const json = yield api.put(
        `/pe/assignments/${assignment.id}`,
        assignment,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_ASSIGNMENT_RESPONSE,
            data: json,
        });
    }
}

function* deleteAssignment(assignmentId, page, perPage, searchTerm, apiUrl) {
    const json = yield api.del(`/pe/assignments/${assignmentId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_ASSIGNMENT_RESPONSE,
            data: json,
        });
    }
    yield fetchAssignments(page, perPage, apiUrl, searchTerm, '');
}

function* getConfig(apiUrl) {
    const json = yield api.get('/pe/assignments/config', apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_ASSIGNMENT_CONFIG_RESPONSE,
            data: json,
        });
    }
}

function* addTag(assignmentId, tagId, apiUrl) {
    const json = yield api.post(
        '/pe/content_tags',
        {
            content_type: 'Assignment',
            content_id: assignmentId,
            tag_id: tagId,
        },
        apiUrl
    );
    if (json) {
        yield getAssignment(assignmentId, apiUrl);
    }
}

function* removeTag(contentId, tagId, apiUrl) {
    const json = yield api.del(
        `/pe/content_tags/Assignment/${contentId}/${tagId}`,
        apiUrl
    );
    if (json) {
        yield getAssignment(contentId, apiUrl);
    }
}

function* getTagGroups(apiUrl) {
    const json = yield api.get(`/pe/tag_groups?per_page=1000`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_ASSIGNMENTS_TAG_GROUPS_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchTranslations(assignmentId, page = 1, per_page = 10, apiUrl) {
    const json = yield api.get(
        `/pe/translations?resource_type=Assignment&resource_id=${assignmentId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_ASSIGNMENT_TRANSLATIONS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* postTranslation(resource_id, translation, apiUrl) {
    const json = yield api.post(
        `/pe/translations`,
        {
            resource_id,
            resource_type: 'Assignment',
            ...translation,
        },
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_ASSIGNMENT_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}

function* deleteTranslation(
    translationId,
    assignmentId,
    page,
    perPage,
    apiUrl
) {
    const json = yield api.del(`/pe/translations/${translationId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_SELECT_ASSIGNMENT_TRANSLATION,
            data: null,
        });
        yield fetchTranslations(assignmentId, page, perPage, apiUrl);
    }
}

function* updateTranslation(
    translation, 
    assignmentId,
    page,
    perPage,
    goToList,
    apiUrl
) {
    // Call PUT endpoint to update DB
    const json = yield api.put(
        `/pe/translations/${translation.id}`,
        translation,
        apiUrl
    );

    if(goToList){
        // Refresh only the list and set selectedTranslation NULL on the state (to close the form)
        yield fetchTranslations(assignmentId, page, perPage, apiUrl);
        yield put({
            type: ACTION_TYPES.PE_SELECT_ASSIGNMENT_TRANSLATION,
            data: null,
        });
    }else{

        // Update state
        yield put({
            type: ACTION_TYPES.PE_UPDATE_ASSIGNMENT_TRANSLATION_RESPONSE,
            data: json,
        });
    }
}

function* postImage(file, assetable_id, apiUrl) {
    try {
        //console.log({ file, assetable_id, apiUrl });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('asset_type', 'image');
        formData.append('slot', 'poster');
        formData.append('assetable_type', 'Assignment');
        formData.append('assetable_id', assetable_id);
        const json = yield api.postForm(`/pe/assets`, formData, apiUrl);
        yield put({
            type: ACTION_TYPES.PE_POST_ASSIGNMENT_POSTER_RESPONSE,
            data: null,
        });
        if (json) {
            yield getAssignment(assetable_id, apiUrl);
        }
    } catch (err) {
        console.log('ERROR Loading Image', err);
    }
}

function* getAssignmentFiles(assignmentId, apiUrl) {
    const json = yield api.get(
        `/pe/assets?assetable_type=Assignment&assetable_id=${assignmentId}&per_page=100`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_ASSIGNMENT_FILES_RESPONSE,
            data: json.results,
        });
    }
}

function* postAsset(assignmentId, brandIdent, asset_type, slot, file, done, apiUrl) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brand_ident', brandIdent);
    formData.append('assetable_id', assignmentId);
    formData.append('asset_type', asset_type);
    formData.append('slot', slot);
    formData.append('assetable_type', 'Assignment');
    const json = yield api.postForm('/pe/assets',formData,apiUrl);
    if (json.status_code && ![200, 201, 202].includes(json.status_code)) {
        alert(json.message);
    } else {
        if (done) done();

        yield getAssignmentFiles(assignmentId, apiUrl);
    }
}

function* deleteAsset(assetId, assignmentId, apiUrl) {
    const json = yield api.del('/pe/assets/' + assetId, apiUrl);
    if (json) {
        console.log('file Deleted');
        if (assignmentId) {
            yield getAssignmentFiles(assignmentId, apiUrl);
        }
    }
}

function* assignAsset(assignmentId, assetId, apiUrl) {
    const json = yield api.post(`/pe/assets/${assetId}/assign`, {}, apiUrl);
    if (json) {
        yield getAssignment(assignmentId, apiUrl);
    }
}

function* unAssignAsset(assignmentId, assetId, apiUrl) {
    const json = yield api.post(`/pe/assets/${assetId}/unassign`, {}, apiUrl);
    if (json) {
        yield getAssignment(assignmentId, apiUrl);
    }
}

function* peAssignmentsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_ASSIGNMENTS_REQUEST:
            yield fetchAssignments(
                action.data?.page,
                action.data?.per_page,
                apiUrl,
                action.data?.searchTerm,
                action.data?.region_ident,
                action.data?.tagsQuery
            );
            break;
        case ACTION_TYPES.PE_GET_ASSIGNMENT_REQUEST:
            yield getAssignment(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_ASSIGNMENT_REQUEST:
            yield postAssignment(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_UPDATE_ASSIGNMENT_REQUEST:
            yield updateAssignment(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_ASSIGNMENT_REQUEST:
            yield deleteAssignment(
                action.data?.assignmentId,
                action.data?.page,
                action.data?.perPage,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_GET_ASSIGNMENT_CONFIG_REQUEST:
            yield getConfig(apiUrl);
            break;
        case ACTION_TYPES.PE_ADD_TAG_TO_ASSIGNMENT_REQUEST:
            yield addTag(action.data.assignmentId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.PE_REMOVE_TAG_FROM_ASSIGNMENT_REQUEST:
            yield removeTag(
                action.data.assignmentId,
                action.data.tagId,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_FETCH_ASSIGNMENTS_TAG_GROUPS_REQUEST:
            yield getTagGroups(apiUrl);
            break;
        case ACTION_TYPES.PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST:
            yield fetchTranslations(
                action.data?.assignmentId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_POST_ASSIGNMENT_TRANSLATION_REQUEST:
            yield postTranslation(
                action.data?.resourceId,
                action.data?.translation,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_DELETE_ASSIGNMENT_TRANSLATION_REQUEST:
            yield deleteTranslation(
                action.data?.translationId,
                action.data?.assignmentId,
                action.data?.page,
                action.data?.per_page,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_UPDATE_ASSIGNMENT_TRANSLATION_REQUEST:
            yield updateTranslation(
                action.data?.translation,
                action.data?.assignmentId,
                action.data?.page,
                action.data?.per_page,
                action.data?.goToList,
                apiUrl
                );
            break;
        case ACTION_TYPES.PE_POST_ASSIGNMENT_POSTER_REQUEST:
            yield postImage(
                action.data?.file,
                action.data?.assetableId,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_FETCH_ASSIGNMENT_FILES_REQUEST:
            yield getAssignmentFiles(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_ASSIGNMENT_FILE_REQUEST:
            yield postAsset(
                action.data?.assignmentId,
                action.data?.brandIdent,
                action.data?.assetType,
                action.data?.slot,
                action.data?.file,
                action.data?.done,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_DELETE_ASSIGNMENT_FILE_REQUEST:
        yield deleteAsset(
            action.data?.assetId,
            action.data?.assignmentId,
            apiUrl
        );
            break;
        case ACTION_TYPES.PE_ASSIGN_ASSET_REQUEST:
            yield assignAsset(
                action.data?.assignmentId,
                action.data?.assetId,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_UNASSIGN_ASSET_REQUEST:
            yield unAssignAsset(
                action.data?.assignmentId,
                action.data?.assetId,
                apiUrl
            );
            break;
        default:
            break;
    }
}

export default peAssignmentsWorker;
