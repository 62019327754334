import LineChart from 'components/LineChart';
import useActions from 'hooks/useActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        programs: state.programs.list,
        totalPages: state.programs.total_pages,
        allTimeTracks: state.programs.allTimeTracks,
        monthlyTracks: state.programs.monthlyTracks,
    };
};

function ReferralsOverview(props) {
    const { programs = [], allTimeTracks = [], monthlyTracks = [] } = props;
    // i.e. /referrals?test=someIdOrCode&testB=77777
    const {
        FETCH_PROGRAMS_REQUEST,
        FETCH_PROGRAM_EVENTS_REQUEST,
        SELECT_VIEWS_PROGRAM,
        SELECT_CONVERSIONS_PROGRAM,
        FETCH_ADMIN_CONFIG_REQUEST,
    } = useActions();

    const [monthTotals, setMonthTotals] = useState([]);
    const [mappedMonthlyViews, setMappedMonthlyViews] = useState([]);
    const [mappedMonthlyConversions, setMappedMonthlyConversions] = useState(
        []
    );

    /**
     * Map the tracks and calculate the total views and conversions
     */
    useEffect(() => {
        monthlyTracks.forEach((programTracks) => {
            let monthConversionsTotal = 0;
            let monthViewsTotal = 0;
            programTracks.forEach((track) => {
                monthConversionsTotal += track.conversion || 0;
                monthViewsTotal += track.view || 0;
            });
            setMonthTotals((prev) => [
                ...prev,
                {
                    conversions: monthConversionsTotal,
                    views: monthViewsTotal,
                },
            ]);
            setMappedMonthlyConversions((prev) => [
                ...prev,
                [...programTracks]
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((track) => ({
                        name: track.name,
                        value: track.conversion || 0,
                        type: 'conversions',
                    })),
            ]);
            setMappedMonthlyViews((prev) => [
                ...prev,
                [...programTracks]
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((track) => ({
                        name: track.name,
                        value: track.view || 0,
                        type: 'views',
                    })),
            ]);
        });
    }, [monthlyTracks, allTimeTracks]);

    useEffect(() => {
        const loadEvents = async () => {
            if (programs && programs.length > 0) {
                const ids = programs.map((program) => program.id);
                FETCH_PROGRAM_EVENTS_REQUEST(ids);
            }
        };
        loadEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programs]);

    useEffect(() => {
        FETCH_PROGRAMS_REQUEST();
        FETCH_ADMIN_CONFIG_REQUEST();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="referrals">
            <h1 className="admin-section-title">
                &#x1f4e3; &nbsp; Referrals Service
            </h1>
            {programs
                .filter((notNull) => notNull)
                .map((program, programIndex) => (
                    <div
                        className="mt-8"
                        key={`overview-program-${program.id}`}
                    >
                        <div className="font-bold text-xl">{program.name}</div>
                        <div className="flex flex-wrap-reverse">
                            <div className="grid grid-cols-3 w-full md:w-3/6 auto-rows-max pt-8 pr-8 grid-details">
                                <span className="text-center col-start-2 row-start-1 monthly">
                                    30 Days
                                </span>
                                <span className="text-center col-start-3 row-start-1 all">
                                    All Time
                                </span>
                                <span className="col-start-1 underline text-blue-400 row-start-2">
                                    <Link
                                        onClick={() => {
                                            SELECT_VIEWS_PROGRAM(program.id);
                                        }}
                                        to={`/referrals/views?program=${program.id}`}
                                    >
                                        Views
                                    </Link>
                                </span>
                                <span className="col-start-1 underline text-blue-400 row-start-3">
                                    <Link
                                        onClick={() => {
                                            SELECT_CONVERSIONS_PROGRAM(
                                                program.id
                                            );
                                        }}
                                        type=""
                                        to={`/referrals/conversions`}
                                    >
                                        Conversions
                                    </Link>
                                </span>
                                <span className="text-center col-start-2 row-start-2 font-bold">
                                    {monthTotals[programIndex]?.views}
                                </span>
                                <span className="text-center col-start-3 row-start-2 font-bold">
                                    {allTimeTracks[`${program.id}-views`] || 0}
                                </span>
                                <span className="text-center col-start-2 row-start-3 font-bold">
                                    {monthTotals[programIndex]?.conversions}
                                </span>
                                <span className="text-center col-start-3 row-start-3 font-bold">
                                    {allTimeTracks[
                                        `${program.id}-conversions`
                                    ] || 0}
                                </span>
                            </div>
                            <div>
                                <LineChart
                                    colors={['red', 'blue']}
                                    dataArrays={[
                                        mappedMonthlyViews[programIndex] || [
                                            { name: 'none', value: 0 },
                                        ],
                                        mappedMonthlyConversions[
                                            programIndex
                                        ] || [{ name: 'none', value: 0 }],
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                ))}
        </section>
    );
}

const Referrals = connect(mapStateToProps)(ReferralsOverview);
export default Referrals;
