import InformationTab from 'components/InformationTab';
import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PESequenceDetails from './SequenceDetails';
import PESequencesNew from './SequencesNew';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        sequences: state.PESequences.sequences,
        page: state.PESequences.page,
        per_page: state.PESequences.per_page,
        total: state.PESequences.total,
        total_pages: state.PESequences.total_pages,
        isLoading: state.PESequences.isLoadingSequences,
        selectedSequence: state.PESequences.selectedSequence,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PESequences = connect(mapStateToProps)(function (props) {
    const {
        sequences,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedSequence,
        selectedRegionFilter,
    } = props;

    const [subtitle, setSubtitle] = useState(null);
    const [detailComponent, setDetailComponent] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const { id } = useParams();
    const { term: searchTerm } = useQueryList(['term']);

    const navigate = useNavigate();
    const {
        PE_FETCH_SEQUENCES_REQUEST,
        PE_SELECT_SEQUENCE,
        PE_GET_SEQUENCE_REQUEST,
        PE_DELETE_SEQUENCE_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const onAdd = () => {
        setSubtitle('Add New Sequence');
        setDetailComponent(<PESequencesNew />);
    };

    function onNext() {
        PE_FETCH_SEQUENCES_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
        });
    }

    function onPrev() {
        PE_FETCH_SEQUENCES_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_SEQUENCES_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_SEQUENCES_REQUEST({
            per_page: perPage,
            searchTerm,
        });
    }

    function onDeleteAction() {
        PE_DELETE_SEQUENCE_REQUEST({
            sequenceId: selectedSequence?.id,
            page,
            searchTerm,
            perPage: per_page,
        });
        navigate('/program-engine/sequences');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Sequence?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function onDetailSelection(sequence) {
        setSubtitle(null);
        setDetailComponent(null);
        PE_SELECT_SEQUENCE(sequence);
        setShowDelete(true);
        if (sequence) {
            navigate(`/program-engine/sequences/${sequence.id}`);
        } else {
            PE_FETCH_SEQUENCES_REQUEST({
                page,
                per_page,
                searchTerm,
            });
            navigate(`/program-engine/sequences`);
        }
    }

    useEffect(() => {
        if (selectedSequence) {
            setSubtitle(selectedSequence.name);
            setDetailComponent(<PESequenceDetails />);
            setShowDelete(true);
        } else {
            setShowDelete(false);
            setDetailComponent(null);
        }
    }, [selectedSequence]);

    useEffect(() => {
        if (id) {
            try {
                PE_GET_SEQUENCE_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            PE_SELECT_SEQUENCE(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        PE_FETCH_SEQUENCES_REQUEST({ searchTerm });
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Sequence" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Tags</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sequences.map((sequence) => (
                            <tr>
                                <td>
                                    <SelectableField
                                        text={sequence.name}
                                        url={`/program-engine/sequences/${sequence.id}`}
                                    />
                                </td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {sequence.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${sequence.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <LinkButton
                                        url={`/program-engine/sequences/${sequence.id}`}
                                        className="table-view-button"
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onPageSelected={onPageSelected}
                    onNext={onNext}
                    onPrev={onPrev}
                />
            </section>
        );
    }

    return (
        <InformationTab
            showPrograms={false}
            showSearchField={true}
            showDelete={showDelete}
            sectionName="Sequences"
            subsectionName={subtitle}
            dataTable={<DataTable />}
            deleteText="Sequence"
            perPage={per_page}
            detailComponent={detailComponent}
            onPerPageSelected={onPerPageSelected}
            onDetailSelected={onDetailSelection}
            onDeleteDetail={onDeleteDetail}
        />
    );
});

export default PESequences;
