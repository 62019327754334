import moment from 'moment';
import api from '../api/api';

const { put, all } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* getAllPrograms() {
    const json = yield api.get('/referrals/programs?per_page=1000');
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_ALL_PROGRAMS_RESPONSE,
            data: {
                list: json.results || [],
            },
        });
    }
}

function* getProgram(programId) {
    const json = yield api.get(
        `/referrals/programs/${programId}?include_scripts=1`
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_PROGRAM_RESPONSE,
            data: json,
        });
    }
}

/**
 * This is to get the charts data in the main referrals screen
 * @param {*} programs
 */
function* getTracksForPrograms(programs = []) {
    const today = moment().endOf('day');
    const todayMillis = today.toDate().getTime();
    today.subtract(1, 'M');
    const monthAgoMillis = today.toDate().getTime();
    const promisesMonth = [];
    const promisesAll = [];
    programs.forEach((program) => {
        promisesMonth.push(
            api
                .get(
                    `/referrals/chart?actions=view-conversion&programs=${program}&group_by=day&start_int=${monthAgoMillis}&end_int=${todayMillis}`
                )
                .then((res) => {
                    if (res.type && ![200, 201, 202].includes(res.type)) {
                        return [];
                    } else {
                        return res.results;
                    }
                })
                .catch((err) => [])
        );
        promisesAll.push(
            api
                .get(
                    `/referrals/track_events?per_page=1&type=views&programs=${program}`
                )
                .then((res) => {
                    if (res.type && ![200, 201, 202].includes(res.type)) {
                        return {};
                    } else {
                        return { [program + '-views']: res.total };
                    }
                })
                .catch((err) => [])
        );
        promisesAll.push(
            api
                .get(`/referrals/conversions?per_page=1&programs=${program}`)
                .then((res) => {
                    if (res.type && ![200, 201, 202].includes(res.type)) {
                        return {};
                    } else {
                        return { [program + '-conversions']: res.total };
                    }
                })
                .catch((err) => [])
        );
    });
    const resultsMonth = yield all(promisesMonth);
    const resultsAllArray = yield all(promisesAll);
    const resultsAll = resultsAllArray.reduce(
        (prev, result) => ({ ...prev, ...result }),
        {}
    );
    yield put({
        type: ACTION_TYPES.FETCH_PROGRAM_EVENTS_RESPONSE,
        data: { monthlyTracks: resultsMonth, allTimeTracks: resultsAll },
    });
}

function* getPrograms(per_page = 10, page = 1) {
    const json = yield api.get(
        `/referrals/programs?per_page=${per_page}&page=${page}&include_scripts=1`
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_PROGRAMS_RESPONSE,
            data: {
                list: json.results || [],
                total: json.total,
                total_pages: json.total_pages,
                per_page: json.per_page,
                page: json.page,
            },
        });
    }
}

function* postProgram(data) {
    const json = yield api.post('/referrals/programs', data);
    if (json) {
        yield put({
            type: ACTION_TYPES.POST_PROGRAM_RESPONSE,
            data: json,
        });
    }
}

function* updateProgram(data) {
    const { id } = data;
    delete data.id;
    delete data.class_name;
    delete data.created_int;
    delete data.updated_int;
    //delete data.brand_id;
    delete data.is_validated;
    const json = yield api.put('/referrals/programs/' + id, data);
    if (!json) {
        yield put({
            type: ACTION_TYPES.PROGRAM_LOADING,
            data: null,
        });
    } else {
        yield put({
            type: ACTION_TYPES.UPDATE_PROGRAM_RESPONSE,
            data: json,
        });
    }
}

function* getProgramConversions(id, perPage = 10, page = 1) {
    const json = yield api.get(
        `/referrals/conversions?programs=${id}&per_page=${perPage}&page=${page}`
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_PROGRAM_CONVERSIONS_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getActivity(programId, page = 1, perPage = 10) {
    const json = yield api.get(
        `/referrals/track_events?programs=${programId}&page=${page}&per_page=${perPage}`
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.FETCH_PROGRAM_ACTIVITY_RESPONSE,
            data: {
                list: json.results || [],
                per_page: json.per_page,
                page: json.page,
                total_pages: json.total_pages,
            },
        });
    }
}

function* updateConversion(conversion, newStatus) {
    const { id } = conversion;
    const json = yield api.put('/referrals/conversions/' + id, {
        status: newStatus,
    });
    if (json) {
        yield put({
            type: ACTION_TYPES.UPDATE_PROGRAM_CONVERSION_RESPONSE,
            data: json,
        });
    }
}

function* updateSocialImage(programId, brandIdent, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('brand_ident', brandIdent);
    formData.append('assetable_id', programId);
    formData.append('asset_type', 'image');
    formData.append('slot', 'social_image');
    formData.append('assetable', 'Program');
    const json = yield api.postForm('/referrals/assets',formData,process.env.REACT_APP_API_URL);
    if (json.status_code && ![200, 201, 202].includes(json.status_code)) {
        alert(json.message);
        yield put({
            type: ACTION_TYPES.PROGRAM_LOADING,
            data: null,
        });
    } else {
        yield put({
            type: ACTION_TYPES.UPLOAD_PROGRAM_SOCIAL_IMAGE_RESPONSE,
            data: json,
        });
    }
}

function* fetchProgramsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ALL_PROGRAMS_REQUEST: {
            yield getAllPrograms();
            break;
        }
        case ACTION_TYPES.FETCH_PROGRAM_REQUEST: {
            yield getProgram(action.data);
            break;
        }
        case ACTION_TYPES.FETCH_PROGRAMS_REQUEST: {
            yield getPrograms(action.data?.per_page, action.data?.page);
            break;
        }
        case ACTION_TYPES.POST_PROGRAM_REQUEST:
            yield postProgram(action.data);
            break;
        case ACTION_TYPES.UPDATE_PROGRAM_REQUEST:
            yield updateProgram(action.data);
            break;
        case ACTION_TYPES.FETCH_PROGRAM_EVENTS_REQUEST:
            yield getTracksForPrograms(action.data);
            break;
        case ACTION_TYPES.FETCH_PROGRAM_ACTIVITY_REQUEST: {
            yield getActivity(
                action.data.programId,
                action.data.page,
                action.data.perPage
            );
            break;
        }
        case ACTION_TYPES.FETCH_PROGRAM_CONVERSIONS_REQUEST:
            yield getProgramConversions(
                action.data.id,
                action.data.perPage,
                action.data.page
            );
            break;
        case ACTION_TYPES.UPDATE_PROGRAM_CONVERSION_REQUEST:
            yield updateConversion(
                action.data.conversion,
                action.data.newStatus
            );
            break;
        case ACTION_TYPES.UPLOAD_PROGRAM_SOCIAL_IMAGE_REQUEST:
            yield updateSocialImage(
                action.data.programId,
                action.data.brandIdent,
                action.data.file
            );
            break;
        default:
            break;
    }
}

export default fetchProgramsWorker;
