import DateField from 'components/DateField';
import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        history: state.vhsInstructors.history,
        page: state.vhsInstructors.history_page,
        per_page: state.vhsInstructors.history_page,
        total_pages: state.vhsInstructors.history_total_pages,
        total: state.vhsInstructors.history_total,
        isLoading: state.vhsInstructors.isLoadingHistory,
        instructor: state.vhsInstructors.selectedInstructor,
    };
}

const InstructorHistory = connect(mapStateToProps)(function (props) {
    const { history, page, per_page, total_pages, total, isLoading, instructor } =
        props;
    const { VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST } = useActions();

    function onNext() {
        VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST({
            per_page,
            page: page + 1,
            instructorId: instructor.id,
        });
    }

    function onPrev() {
        VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST({
            per_page,
            page: page - 1,
            instructorId: instructor.id,
        });
    }

    function onPageSelected(pageSelected) {
        VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST({
            per_page,
            page: pageSelected,
            instructorId: instructor.id,
        });
    }

    function onPerPageSelected({ perPage }) {
        VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST({
            instructorId: instructor.id,
            page: 1,
            per_page: perPage,
        });
    }

    useEffect(() => {
        VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST({
            instructorId: instructor.id});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   

    return (
        <InformationTab
            dataTable={
                <section>
                    <TableResults results={total} />
                    <LoadingTable
                        isLoading={isLoading}
                        tableClass="w-full text-left border-collapse table-fixed"
                    >
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Object</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((element) => (
                                <tr>
                                    <td>{element.action}</td>
                                    <td>
                                        <DateField
                                            timeMillis={element.created_int}
                                        />
                                    </td>
                                    <td>{element.name}</td>
                                    <td style={{ wordBreak: 'break-all' }}>
                                        {element.obj_json}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </LoadingTable>
                    <TableNavigator
                        currentPage={page}
                        totalPages={total_pages}
                        onPageSelected={onPageSelected}
                        onNext={onNext}
                        onPrev={onPrev}
                    />
                </section>
            }
            showPrograms={false}
            perPage={per_page}
            sectionName="History"
            onPerPageSelected={onPerPageSelected}
            showBreadcrumbs={false}
        />
    );
});

export default InstructorHistory;
