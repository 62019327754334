import peProgramsWorker from '../workers/peProgramsWorker';

const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');

function* PE_PROGRAMS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_GET_PROGRAM_REQUEST,
            ACTION_TYPES.PE_FETCH_PROGRAMS_REQUEST,
            ACTION_TYPES.PE_POST_PROGRAM_REQUEST,
            ACTION_TYPES.PE_UPDATE_PROGRAM_REQUEST,
            ACTION_TYPES.PE_DELETE_PROGRAM_REQUEST,
            ACTION_TYPES.PE_FETCH_PROGRAM_PHASES_REQUEST,
            ACTION_TYPES.PE_POST_PROGRAM_PHASE_REQUEST,
            ACTION_TYPES.PE_UPDATE_PROGRAM_PHASE_REQUEST,
            ACTION_TYPES.PE_DELETE_PROGRAM_PHASE_REQUEST,
            ACTION_TYPES.PE_FETCH_PROGRAMS_TAG_GROUPS_REQUEST,
            ACTION_TYPES.PE_ADD_TAG_TO_PROGRAM_REQUEST,
            ACTION_TYPES.PE_REMOVE_TAG_FROM_PROGRAM_REQUEST,
            ACTION_TYPES.PE_CLONE_PROGRAM_REQUEST,
            ACTION_TYPES.PE_UPDATE_PROGRAM_TRANSLATION_REQUEST,
            ACTION_TYPES.PE_FETCH_PROGRAM_TRANSLATIONS_REQUEST,
            ACTION_TYPES.PE_POST_PROGRAM_TRANSLATION_REQUEST,
            ACTION_TYPES.PE_DELETE_PROGRAM_TRANSLATION_REQUEST,
        ],
        peProgramsWorker,
        apiUrl,
        ...params
    );
}

export default PE_PROGRAMS_REQUEST;
