import fetchProgramsWorker from 'store/sagas/workers/fetchPrograms';

const { takeEvery } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* PROGRAMS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.FETCH_ALL_PROGRAMS_REQUEST,
            ACTION_TYPES.FETCH_PROGRAM_REQUEST,
            ACTION_TYPES.FETCH_PROGRAMS_REQUEST,
            ACTION_TYPES.POST_PROGRAM_REQUEST,
            ACTION_TYPES.UPDATE_PROGRAM_REQUEST,
            ACTION_TYPES.FETCH_PROGRAM_CONVERSIONS_REQUEST,
            ACTION_TYPES.FETCH_PROGRAM_EVENTS_REQUEST,
            ACTION_TYPES.FETCH_PROGRAM_ACTIVITY_REQUEST,
            ACTION_TYPES.UPDATE_PROGRAM_CONVERSION_REQUEST,
            ACTION_TYPES.UPLOAD_PROGRAM_SOCIAL_IMAGE_REQUEST,
        ],
        fetchProgramsWorker,
        apiUrl,
        ...params
    );
}

export default PROGRAMS_REQUEST;
