import ACTION_TYPES from 'constants/ACTION_TYPES';
import {CREATED_SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';


const initialState = {
    isLoading: false,
    itemList: [],
    itemTotal: 0,
    itemTotalPages: 1,
    itemPage: 1,
    itemPerPage: 10,
    sortFilter: CREATED_SORTING_OPTIONS[0].value,
    contentFilter: 'all',
};

function ModeratorReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_MODERATOR_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case ACTION_TYPES.CS_MODERATOR_RESPONSE: {
            return {
                ...state,
                isLoading: false,
                itemList: action.data.list,
                itemTotal: action.data.total,
                itemTotalPages: action.data.total_pages,
                itemPage: action.data.page,
                itemPerPage: action.data.per_page,
            };
        }
        case ACTION_TYPES.CS_SELECT_MODERATOR_ORDER_FILTER:{
            return {
                ...state,
                itemPage: 1,
                sortFilter: action.data,
            }
        }
        case ACTION_TYPES.CS_SELECT_MODERATOR_CONTENT_FILTER:{
            return {
                ...state,
                itemPage: 1,
                contentFilter: action.data,
            }
        }
        default:
            return state;
    }
}

export default ModeratorReducer