import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import peAssignmentsWorker from '../workers/peAssignmentsWorker';

function* PE_ASSIGNMENTS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_ASSIGNMENTS_REQUEST,
            ACTION_TYPES.PE_GET_ASSIGNMENT_REQUEST,
            ACTION_TYPES.PE_POST_ASSIGNMENT_REQUEST,
            ACTION_TYPES.PE_UPDATE_ASSIGNMENT_REQUEST,
            ACTION_TYPES.PE_DELETE_ASSIGNMENT_REQUEST,
            ACTION_TYPES.PE_GET_ASSIGNMENT_CONFIG_REQUEST,
            ACTION_TYPES.PE_ADD_TAG_TO_ASSIGNMENT_REQUEST,
            ACTION_TYPES.PE_REMOVE_TAG_FROM_ASSIGNMENT_REQUEST,
            ACTION_TYPES.PE_FETCH_ASSIGNMENTS_TAG_GROUPS_REQUEST,
            ACTION_TYPES.PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST,
            ACTION_TYPES.PE_POST_ASSIGNMENT_TRANSLATION_REQUEST,
            ACTION_TYPES.PE_UPDATE_ASSIGNMENT_TRANSLATION_REQUEST,
            ACTION_TYPES.PE_DELETE_ASSIGNMENT_TRANSLATION_REQUEST,
            ACTION_TYPES.PE_POST_ASSIGNMENT_POSTER_REQUEST,
            ACTION_TYPES.PE_POST_ASSIGNMENT_FILE_REQUEST,
            ACTION_TYPES.PE_FETCH_ASSIGNMENT_FILES_REQUEST,
            ACTION_TYPES.PE_ASSIGN_ASSET_REQUEST,
            ACTION_TYPES.PE_UNASSIGN_ASSET_REQUEST,
            ACTION_TYPES.PE_DELETE_ASSIGNMENT_FILE_REQUEST,
        ],
        peAssignmentsWorker,
        apiUrl,
        ...params
    );
}

export default PE_ASSIGNMENTS_REQUEST;
