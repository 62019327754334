import useActions from 'hooks/useActions';
export default function AlertModal(props){
    const {
        onDeleteAction,
        title
    } = props;
    const {
        TOGGLE_MODAL
    } = useActions();
    
    const deleteAction = () => {
        TOGGLE_MODAL(false);
        onDeleteAction();
    }

    return(
        <div className='flex flex-col gap-6'>
            <div className='alert-title'>{title}</div>
            <div className='flex flex-row gap-8 justify-center alert-modal-buttons-container'>
                <button className='cancel-btn'  onClick={() => TOGGLE_MODAL(false)}>No</button>
                <button className='ok-btn' onClick={() => deleteAction()}>Yes</button>
            </div>
        </div>
    )
}