import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';


function* fetchContent(page = 1, per_page = 10, sorting = 'all', contentType = 'all',searchTerm = '',apiUrl) {
    let endpoint = `/community/pending_content?page=${page}&per_page=${per_page}`;
    if (!!searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    if (contentType !== 'all') {
        endpoint += `&content=${contentType}`;
    }
    if (sorting !== 'all') {
        endpoint += `&sort=${sorting}`;
    }

    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.COMMUNITY);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_MODERATOR_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* changeVisibility(id,contentType,visibility, apiUrl,page = 1, per_page = 10, sorting = 'all', contentFilter = 'all',searchTerm = '') {
    const json = yield api.put(`/community/${contentType}/${id}`, {visibility: visibility}, apiUrl);
    if (json) {
        yield fetchContent(page,per_page,sorting,contentFilter,searchTerm,apiUrl)
    }
}

function* changeReason(id,reason,apiUrl,page = 1, per_page = 10, sorting = 'all', contentFilter = 'all',searchTerm = '') {
    const json = yield api.put(`/community/flags/${id}`, {reason}, apiUrl);
    if (json) {
        yield fetchContent(page,per_page,sorting,contentFilter,searchTerm,apiUrl)
    }
}
function* updateFlag(id,status,reason, apiUrl,page = 1, per_page = 10, sorting = 'all', contentFilter = 'all',searchTerm = '') {
    const params = {status}
    if(reason) params.reason = reason;
    const json = yield api.put(`/community/flags/${id}`, params, apiUrl);
    if (json) {
        yield fetchContent(page,per_page,sorting,contentFilter,searchTerm,apiUrl)
    }
}
function* postReason(resourceType, resourceId, reason, status,apiUrl,page = 1, per_page = 10, sorting = 'all', contentFilter = 'all',searchTerm = '') {
    const json = yield api.post('/community/flags/', {resourceType, resourceId, reason, status}, apiUrl);
    if (json) {
        yield fetchContent(page,per_page,sorting,contentFilter,searchTerm,apiUrl)
    }
}

function* csModeratorWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_MODERATOR_REQUEST:
            yield fetchContent(
                action.data?.page,
                action.data?.per_page,
                action.data?.sorting,
                action.data?.contentType,
                action.data?.searchTerm,
                apiUrl,
            );
            break;
        case ACTION_TYPES.CS_MODERATOR_VISIBILITY_REQUEST:
            yield changeVisibility(
                action.data?.id,
                action.data?.contentType,
                action.data?.visibility,
                apiUrl,
                action.data?.page,
                action.data?.per_page,
                action.data?.sorting,
                action.data?.contentFilter,
                action.data?.searchTerm,
            );
            break;
        case ACTION_TYPES.CS_MODERATOR_FLAGS_REASON_REQUEST:
            yield changeReason(
                action.data?.id,
                action.data?.reason,
                apiUrl,
                action.data?.page,
                action.data?.per_page,
                action.data?.sorting,
                action.data?.contentFilter,
                action.data?.searchTerm,
            )
            break;
        case ACTION_TYPES.CS_MODERATOR_FLAGS_UPDATE:
            yield updateFlag(
                action.data?.id,
                action.data?.status,
                action.data?.reason,
                apiUrl,
                action.data?.page,
                action.data?.per_page,
                action.data?.sorting,
                action.data?.contentFilter,
                action.data?.searchTerm,
            )
            break;
        case ACTION_TYPES.CS_MODERATOR_FLAGS_REASON_CREATE:
            yield postReason(
                action.data?.resourceType,
                action.data?.resourceId,
                action.data?.reason,
                action.data?.status,
                apiUrl,
                action.data?.page,
                action.data?.per_page,
                action.data?.sorting,
                action.data?.contentFilter,
                action.data?.searchTerm,
            )
            break;
        default:
            break;
    }
}

export default csModeratorWorker;
