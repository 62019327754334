import DetailOverview from 'components/DetailOverview';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

const SeriesOverview = connect(mapStateToProps)(function ({
    series,
    brandsOptions,
}) {
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    useEffect(() => {
        if (brandsOptions) {
            setBrandsSet(brandsOptions);
        }
        // eslint-disable-next-line
    }, [brandsOptions]);

    return (
        <DetailOverview
            fields={[
                { name: 'Name', value: series.name },
                { name: 'Description', value: series.description, type: 'markdown' },
                { name: 'Visibility', value: series.visibility },
                {
                    name: 'Brand',
                    value: brandsSet[series.brand_ident],
                },
                { name: 'Created', value: series.created_int },
                { name: 'Updated', value: series.updated_int },
            ]}
        />
    );
});

export default SeriesOverview;
