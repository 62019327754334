import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    list: [],
    isLoadingConversions: false,
    per_page: 10,
    program: 'all',
    page: 1,
    total_pages: 1,
    selectedConversion: null,
    activity: [],
    activity_per_page: 10,
    activity_page: 1,
    activity_total_pages: 1,
    isLoadingActivity: false,
    chartData: [],
    selectedPeriod: '30',
};

function conversionsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_CONVERSIONS_REQUEST: {
            return {
                ...state,
                isLoadingConversions: true,
            };
        }
        case ACTION_TYPES.FETCH_CONVERSIONS_RESPONSE: {
            return {
                ...state,
                per_page: action.data.per_page,
                list: action.data.list,
                page: action.data.page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingConversions: false,
            };
        }
        case ACTION_TYPES.POST_CONVERSION_RESPONSE:
        case ACTION_TYPES.FETCH_CONVERSION_RESPONSE:
        case ACTION_TYPES.SELECT_CONVERSION: {
            return {
                ...state,
                selectedConversion: action.data,
            };
        }
        case ACTION_TYPES.SELECT_CONVERSIONS_PROGRAM: {
            return {
                ...state,
                program: action.data,
            };
        }
        case ACTION_TYPES.UPDATE_CONVERSIONS_RESPONSE: {
            const newArray = [...state.list];
            const index = newArray.findIndex(
                (con) => con.id === action.data?.id
            );
            if (index !== -1) {
                newArray[index] = action.data;
            }
            return {
                ...state,
                selectedConversion: state.selectedConversion
                    ? action.data
                    : null,
                list: [...newArray],
            };
        }
        case ACTION_TYPES.FETCH_CONVERSION_ACTIVITY_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.FETCH_CONVERSION_ACTIVITY_RESPONSE: {
            return {
                ...state,
                activity: action.data.list,
                activity_page: action.data.page,
                activity_per_page: action.data.per_page,
                activity_total_pages: action.data.total_pages,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.SELECT_CONVERSIONS_PERIOD: {
            return {
                ...state,
                selectedPeriod: action.data.selectedPeriod,
            };
        }
        case ACTION_TYPES.FETCH_CONVERSIONS_CHART_RESPONSE: {
            return {
                ...state,
                chartData: action.data.list,
            };
        }
        default:
            return state;
    }
}

export default conversionsReducer;
