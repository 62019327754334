import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchFlags(page = 1, per_page = 10, sorting = 'all', status = 'all', apiUrl){
    let url = `/community/flags?page=${page}&per_page=${per_page}&include=resource-parent`;
    if (status !== 'all') {
        url += `&status=${status}`;
    }
    if (sorting !== 'all') {
        url += `&sort=${sorting}`;
    }

    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(url, apiUrl, selectedRegion, SERVICE_TYPES.COMMUNITY);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_FLAGS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* changeStatus(flag, page = 1, per_page = 10, sorting = 'all', status = 'all', apiUrl){
    const { id } = flag;
    delete flag.id;
    const json = yield api.put(`/community/flags/${id}`, flag, apiUrl);
    if (json) {
        yield fetchFlags(page, per_page, sorting, status, apiUrl);
    }
}


function* csFlagsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_FETCH_FLAGS_REQUEST:
            yield fetchFlags(
                action.data?.page,
                action.data?.per_page,
                action.data?.sorting,
                action.data?.status,
                apiUrl
            );
            break;
        case ACTION_TYPES.CS_FLAGS_CHANGE_REASON_REQUEST:
            yield changeStatus(
                action.data?.body,
                action.data?.page,
                action.data?.per_page,
                action.data?.sorting,
                action.data?.status,
                apiUrl
            );
            break;
        default:
            break;
    }
}

export default csFlagsWorker;
