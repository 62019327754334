import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import peCategoriesWorker from '../workers/peCategoriesWorker';

function* PE_CATEGORIES_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_CATEGORIES_REQUEST,
            ACTION_TYPES.PE_GET_CATEGORY_REQUEST,
            ACTION_TYPES.PE_POST_CATEGORY_REQUEST,
            ACTION_TYPES.PE_UPDATE_CATEGORY_REQUEST,
            ACTION_TYPES.PE_DELETE_CATEGORY_REQUEST,
            ACTION_TYPES.PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST,
            ACTION_TYPES.PE_FETCH_ALL_CATEGORIES_REQUEST,
        ],
        peCategoriesWorker,
        apiUrl,
        ...params
    );
}

export default PE_CATEGORIES_REQUEST;
