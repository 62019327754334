import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import csQuestionsWorker from '../workers/csQuestionsWorker';

function* CS_QUESTIONS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_QUESTION_REQUEST,
            ACTION_TYPES.CS_FETCH_QUESTION_REQUEST,
            ACTION_TYPES.CS_UPDATE_QUESTION_REQUEST,
            ACTION_TYPES.CS_DELETE_QUESTION_REQUEST,
            ACTION_TYPES.CS_FETCH_ANSWERS_PER_QUESTION_REQUEST,
            ACTION_TYPES.CS_SELECT_QUESTION_ANSWER,
            ACTION_TYPES.CS_DELETE_QUESTION_ANSWER_REQUEST,
            ACTION_TYPES.CS_FETCH_ANSWER_REQUEST,
            ACTION_TYPES.CS_SELECT_QUESTION_ANSWER_ORDER_FILTER,
            ACTION_TYPES.CS_FETCH_QUESTION_FLAGS_REQUEST,
            ACTION_TYPES.CS_ADD_TAG_TO_QUESTION_REQUEST,
            ACTION_TYPES.CS_REMOVE_TAG_FROM_QUESTION_REQUEST,
            ACTION_TYPES.CS_FETCH_QUESTION_FILES_REQUEST,
            ACTION_TYPES.CS_ASSIGN_QUESTION_ASSET_REQUEST,
            ACTION_TYPES.CS_UNASSIGN_QUESTION_ASSET_REQUEST,
            ACTION_TYPES.CS_DELETE_QUESTION_FILE_REQUEST,
            ACTION_TYPES.CS_FETCH_QUESTION_TRANSLATIONS_REQUEST,
            ACTION_TYPES.CS_POST_QUESTION_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_UPDATE_QUESTION_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_DELETE_QUESTION_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_POST_QUESTION_REQUEST,
            ACTION_TYPES.CS_UPDATE_QUESTION_FLAGS_REASON_REQUEST,
        ],
        csQuestionsWorker,
        apiUrl,
        ...params
    );
}

export default CS_QUESTIONS_REQUEST;
