function MarkdownHint() {
    return (
        <div className="mt-4 text-xs">
            Format as Markdown.
            <a
                href="https://www.markdownguide.org/cheat-sheet/"
                target="_blank"
                style={{ color: '#3182ce', marginLeft: '0.125rem' }}
                rel="noreferrer"
            >
                Open Markdown Cheat Sheet
            </a>
        </div>
    );
}

export default MarkdownHint;
