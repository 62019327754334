import ACTION_TYPES from 'constants/ACTION_TYPES';
import {CREATED_SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';

const initialState = {
    flagList: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    isLoading: false,
    statusFilter: 'all',
    flagsOrder: CREATED_SORTING_OPTIONS[0].value
};

function CSTagsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_FETCH_FLAGS_RESPONSE: {
            return {
                ...state,
                isLoading: false,
                flagList: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
            };
        }
        case ACTION_TYPES.CS_SELECT_FLAGS_STATUS: {
            return {
                ...state,
                page: 1,
                statusFilter: action.data,
            }
        }
        case ACTION_TYPES.CS_SELECT_FLAGS_ORDER_FILTER: {
            return {
                ...state,
                flagsOrder: action.data,
            }
        }
        default:
            return state;
    }
}

export default CSTagsReducer;
