import React from 'react';
import { useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function useQueryList(queryList = []) {
    const query = useQuery();

    return queryList.reduce((allQ, q) => {
        const nextAllQ = allQ;
        nextAllQ[q] = query.get(q);
        return nextAllQ;
    }, {});
}

export default useQueryList;
