import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchPhases(page = 1, per_page = 10, searchTerm = '', apiUrl) {
    let endpoint = `/pe/phases?page=${page}&per_page=${per_page}`;
    if (searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_PHASES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getPhase(phaseId, apiUrl) {
    const json = yield api.get(`/pe/phases/${phaseId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_PHASE_RESPONSE,
            data: json,
        });
    }
}

function* updatePhase(phase, apiUrl) {
    const json = yield api.put(`/pe/phases/${phase.id}`, phase, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_PHASE_RESPONSE,
            data: json,
        });
    }
}

function* postPhase(phase, apiUrl) {
    const json = yield api.post(`/pe/phases`, phase, apiUrl);
    if (json) {
        put({
            type: ACTION_TYPES.PE_POST_PHASE_RESPONSE,
            data: json,
        });
    }
}

function* deletePhase(phaseId, page, perPage, searchTerm, apiUrl) {
    const json = yield api.del(`/pe/phases/${phaseId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_PHASE_RESPONSE,
            data: json,
        });
    }
    yield fetchPhases(page, perPage, searchTerm, apiUrl);
}

function* fetchSequences(phaseId, apiUrl) {
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(`/pe/phase_sequences?phases=${phaseId}&per_page=1000&include=task-sequence`, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_PHASES_SEQUENCES_RESPONSE,
            data: json.results,
        });
    }
}

function* addPhaseSequence(phase_id, sequence_id, week, apiUrl) {
    const body = {
        phase_id,
        sequence_id,
        week,
    };
    const json = yield api.post(`/pe/phase_sequences`, body, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_ADD_PHASE_SEQUENCE_RESPONSE,
            data: json,
        });
    }
}

function* removePhaseSequence(phaseId, phaseSequenceId, apiUrl) {
    const json = yield api.del(
        `/pe/phase_sequences/${phaseSequenceId}`,
        apiUrl
    );
    if (json) {
        yield fetchSequences(phaseId, apiUrl);
    }
}

function* pePhasesWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_PHASES_REQUEST:
            yield fetchPhases(
                action.data?.page,
                action.data?.per_page,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_GET_PHASE_REQUEST:
            yield getPhase(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_UPDATE_PHASE_REQUEST:
            yield updatePhase(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_PHASE_REQUEST:
            yield postPhase(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_PHASE_REQUEST:
            yield deletePhase(
                action.data?.phaseId,
                action.data?.page,
                action.data?.perPage,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_FETCH_PHASES_SEQUENCES_REQUEST:
            yield fetchSequences(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_ADD_PHASE_SEQUENCE_REQUEST:
            yield addPhaseSequence(
                action.data?.phaseId,
                action.data?.sequenceId,
                action.data?.week,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_REMOVE_PHASE_SEQUENCE_REQUEST:
            yield removePhaseSequence(
                action.data?.phaseId,
                action.data?.phaseSequenceId,
                apiUrl
            );
            break;
        default:
            break;
    }
}

export default pePhasesWorker;
