const VHS_EVENTS_ACTIONS = [
    { value: 'all', label: 'All' },
    { value: 'loaded', label: 'Loaded' },
    { value: 'start', label: 'Start' },
    { value: 'play', label: 'Play' },
    { value: 'pause', label: 'Pause' },
    { value: 'minutes_watched', label: 'Minutes Watched' },
    { value: 'watched_90', label: 'Watched 90%' },
    { value: 'end', label: 'End' },
    { value: 'zoom_click', label: 'Zoom Click' },
    { value: 'zoom_join', label: 'Zoom Join' },
];

export default VHS_EVENTS_ACTIONS;
