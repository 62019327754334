import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchSequences(page = 1, per_page = 10, searchTerm = '', apiUrl) {
    let endpoint = `/pe/sequences?page=${page}&per_page=${per_page}`;
    if (searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_SEQUENCES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getSequence(sequenceId, apiUrl) {
    const json = yield api.get(`/pe/sequences/${sequenceId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_SEQUENCE_RESPONSE,
            data: json,
        });
    }
}

function* updateSequence(sequence, apiUrl) {
    const json = yield api.put(
        `/pe/sequences/${sequence.id}`,
        sequence,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_SEQUENCE_RESPONSE,
            data: json,
        });
    }
}

function* deleteSequence(sequenceId, page, perPage, searchTerm, apiUrl) {
    const json = yield api.del(`/pe/sequences/${sequenceId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_SEQUENCE_RESPONSE,
            data: json,
        });
    }
    yield fetchSequences(page, perPage, searchTerm, apiUrl);
}

function* postSequence(sequence, apiUrl) {
    const json = yield api.post(`/pe/sequences`, sequence, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_SEQUENCE_RESPONSE,
            data: json,
        });
    }
}

function* addTag(sequenceId, tagId, apiUrl) {
    const json = yield api.post(
        '/pe/content_tags',
        {
            content_type: 'Sequence',
            content_id: sequenceId,
            tag_id: tagId,
        },
        apiUrl
    );
    if (json) {
        yield getSequence(sequenceId, apiUrl);
    }
}

function* removeTag(contentId, tagId, apiUrl) {
    const json = yield api.del(
        `/pe/content_tags/Sequence/${contentId}/${tagId}`,
        apiUrl
    );
    if (json) {
        yield getSequence(contentId, apiUrl);
    }
}

function* getTagGroups(apiUrl) {
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(`/pe/tag_groups?per_page=1000`, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_SEQUENCE_TAG_GROUPS_RESPONSE,
            data: json.results,
        });
    }
}

function* getTasks(sequenceId, apiUrl) {
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);
    const json = yield api.get(`/pe/sequence_tasks?sequences=${sequenceId}&per_page=1000&include=series-task-matrix`, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_SEQUENCE_TASKS_RESPONSE,
            data: json.results,
        });
    }
}

function* postTask(taskType, taskId, day, sequenceId, apiUrl) {
    const body = {
        sequence_id: sequenceId,
        task_type: taskType,
        task_id: taskId,
        apiUrl,
    };
    if (day > 0) {
        body['day'] = day;
    }
    const json = yield api.post(`/pe/sequence_tasks`, body, apiUrl);
    if (json) {
        yield getTasks(sequenceId, apiUrl);
    }
}

function* deleteTask(taskId, sequenceId, apiUrl) {
    const json = yield api.del(`/pe/sequence_tasks/${taskId}`, apiUrl);
    if (json) {
        yield getTasks(sequenceId, apiUrl);
    }
}

function* changeSequencePosition(
    taskId,
    sequenceId,
    day,
    position,
    apiUrl
) {
    const json = yield api.put(
        `/pe/sequence_tasks/${taskId}`,
        { 
            position, 
            day
        },
        apiUrl
    );
    if (json) {
        yield getTasks(sequenceId, apiUrl);
    }
}

function* peSequencesWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_SEQUENCES_REQUEST:
            yield fetchSequences(
                action.data?.page,
                action.data?.per_page,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_GET_SEQUENCE_REQUEST:
            yield getSequence(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_UPDATE_SEQUENCE_REQUEST:
            yield updateSequence(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_SEQUENCE_REQUEST:
            yield deleteSequence(
                action.data?.sequenceId,
                action.data?.page,
                action.data?.perPage,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_POST_SEQUENCE_REQUEST:
            yield postSequence(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_ADD_TAG_TO_SEQUENCE_REQUEST:
            yield addTag(action.data.sequenceId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.PE_REMOVE_TAG_FROM_SEQUENCE_REQUEST:
            yield removeTag(action.data.sequenceId, action.data.tagId, apiUrl);
            break;
        case ACTION_TYPES.PE_FETCH_SEQUENCE_TAG_GROUPS_REQUEST:
            yield getTagGroups(apiUrl);
            break;
        case ACTION_TYPES.PE_GET_SEQUENCE_TASKS_REQUEST:
            yield getTasks(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_SEQUENCE_TASK_REQUEST:
            yield postTask(
                action.data?.taskType,
                action.data?.taskId,
                action.data?.day,
                action.data?.sequenceId,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_DELETE_SEQUENCE_TASK_REQUEST:
            yield deleteTask(
                action.data?.taskId,
                action.data?.sequenceId,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_POST_SEQUENCE_CHANGE_ORDER_REQUEST:
            yield changeSequencePosition(
                action.data?.taskId,
                action.data?.sequenceId,
                action.data?.day,
                action.data?.position,
                apiUrl
            );
            break;
        default:
            break;
    }
}

export default peSequencesWorker;
