import DetailOverview from 'components/DetailOverview';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        answer: state.CSAnswers.selectedAnswer,
        brandsOptions: state.vhsBrands.brandsOptions,
    };
}

const CSAnswerOverview = connect(mapStateToProps)(function ({
    answer,
    brandsOptions,
}) {
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    return (
        <section>
        {answer.poster_url && (
                <img
                    alt={answer.title}
                    src={answer.poster_url}
                    style={{ maxWidth: '25rem' }}
                    className="mb-4 mt-4"
                />
            )}
        <DetailOverview
            fields={[
                { name: 'Body', value: answer.body, type: 'markdown' },
                { name: 'Posted By', value: answer.member.name },
                { name: 'Posted At', value: answer.posted_int, type: 'date' },
                { name: 'Reel', value: answer.is_reel ? 'Reel' : 'Not Reel' },
                { name: 'Visibility', value: answer.visibility },
                {
                    name: 'Brand',
                    value: brandsSet[answer.brand_ident],
                },
                { name: 'Region', value: answer.region_ident },
                { name: 'Likes', value: answer.liked_count },
                { name: 'Flags', value: answer.flag_ids.length },
            ]}
        />
        </section>
    );
});

export default CSAnswerOverview;
