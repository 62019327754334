import DetailOverview from 'components/DetailOverview';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        episode: state.vhsEpisodes.selectedEpisode,
        brandsOptions: state.vhsBrands.brandsOptions,
        locationOptions: state.vhsLocations.locationOptions,
    };
}

const EpisodesOverview = connect(mapStateToProps)(function ({
    episode,
    brandsOptions,
    locationOptions,
}) {
    const [brandsSet, setBrandsSet] = useSetFromOptions();
    const [locationsSet, setLocationsSet] = useSetFromOptions();

    useEffect(() => {
        setLocationsSet(locationOptions);
        // eslint-disable-next-line
    }, [locationOptions]);

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    return (
        <DetailOverview
            fields={[
                { name: 'Name', value: episode.name },
                { name: 'Description', value: episode.description, type: 'markdown' },
                { name: 'Start', value: episode.start_int, type: 'date' },
                { name: 'End', value: episode.stop_int, type: 'date' },
                {
                    name: 'Duration',
                    value: episode.duration_ms
                        ? `${episode.duration_ms / 60000} minutes`
                        : null,
                },
                { name: 'Visibility', value: episode.visibility },
                {
                    name: 'Instructor',
                    value: episode.instructors,
                    type: 'list-links',
                    urlPattern: `/vhs/instructors/`,
                },
                {
                    name: 'Location',
                    value: locationsSet[episode.location_id]
                        ? locationsSet[episode.location_id]
                        : 'No Location',
                },
                {
                    name: 'Series',
                    value: episode.series?.name,
                    type: 'link',
                    url: `/vhs/series/${episode.series?.id}`,
                },
                {
                    name: 'Brand',
                    value: brandsSet[episode.brand_ident],
                },
                {
                    name: 'Zoom URL',
                    value: episode.zoom_join_url,
                    type: 'new-tab',
                    url: episode.zoom_join_url,
                },
                { name: 'Created', value: episode.created_int, type: 'date' },
                { name: 'Updated', value: episode.updated_int, type: 'date' },
            ]}
        />
    );
});

export default EpisodesOverview;
