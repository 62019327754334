import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    brandsOptions: [],
    brands: [],
    isLoadingBrands: false,
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    selectedBrand: null,
};

function VHSBrandsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_BRANDS_RESPONSE: {
            const options = action.data?.map((brand) => ({
                value: brand.ident,
                label: brand.name,
            }));
            return {
                ...state,
                brandsOptions: options,
            };
        }
        case ACTION_TYPES.VHS_FETCH_BRANDS_REQUEST: {
            return {
                ...state,
                isLoadingBrands: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_BRANDS_RESPONSE: {
            return {
                ...state,
                brands: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingBrands: false,
            };
        }
        case ACTION_TYPES.VHS_FETCH_BRAND_RESPONSE: {
            return {
                ...state,
                selectedBrand: action.data,
            };
        }
        case ACTION_TYPES.VHS_UPDATE_BRAND_RESPONSE: {
            return {
                ...state,
                selectedBrand: action.data,
            };
        }
        case ACTION_TYPES.VHS_SELECT_BRAND: {
            return {
                ...state,
                selectedBrand: action.data,
            };
        }
        case ACTION_TYPES.VHS_POST_BRAND_RESPONSE: {
            return {
                ...state,
                selectedBrand: action.data.brand,
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSBrandsReducer;
