import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    programOptions: [],
    programs: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    selectedProgram: null,
};

function VHSProgramReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_PROGRAMS_RESPONSE: {
            const options = [{ value: '', label: 'No Program' }].concat(
                action.data?.map((program) => ({
                    value: program.id,
                    label: program.name,
                }))
            );
            return {
                ...state,
                programOptions: options,
            };
        }
        case ACTION_TYPES.VHS_FETCH_PROGRAMS_RESPONSE: {
            return {
                ...state,
                programs: action.data.results,
                total: action.data.total,
                total_pages: action.data.total_pages,
                page: action.data.page,
            }
        }
        case ACTION_TYPES.VHS_FETCH_PROGRAM_RESPONSE: {
            return {
                ...state,
                selectedProgram: action.data,
            }
        }
        case ACTION_TYPES.VHS_SELECT_PROGRAM: {
            return {
                ...state,
                selectedProgram: action.data,
            }
        }
        case ACTION_TYPES.VHS_POST_PROGRAM_RESPONSE: {
            return {
                ...state,
                selectedProgram: action.data,
            }
        }
        case ACTION_TYPES.VHS_POST_PROGRAM_EPISODE_RESPONSE: {
            const { program, episode, progEp } = action.data;
            program.entries = (program.entries?.length >= 0) ? program.entries : [];
            program.entries.push({ ...progEp, episode });

            return {
                ...state,
                selectedProgram: { ...program },
            }
        }
        case ACTION_TYPES.VHS_UPDATE_PROGRAM_RESPONSE: {
            return {
                ...state,
                selectedProgram: action.data,
            }
        }
        case ACTION_TYPES.VHS_DEL_PROGRAM_EPISODE_RESPONSE: {
            var prog = { ...state.selectedProgram };
            if (prog.entries && prog.entries.length > 0) {
                const index = prog.entries.findIndex(e => e.id === action.data.id)
                prog.entries.splice(index, 1)
            } else {
                prog.entries = [];
            }

            return {
                ...state,
                selectedProgram: prog
            }
        }
        case ACTION_TYPES.VHS_UPDATE_PROGRAM_EPISODE_RESPONSE: {
            const { program, progEp } = action.data;
            const index = program.entries.findIndex(e => e.id === progEp.id)
            if (index >= 0) {
                program.entries[index].sort_index = progEp.sort_index
            }

            return {
                ...state,
                selectedProgram: { ...program },
            }
        }
        default: {
            return state;
        }
    }
}

export default VHSProgramReducer;
