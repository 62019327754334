import { CheckIcon } from '@heroicons/react/outline';
import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        series: state.PESeries.series,
        page: state.PESeries.page,
        per_page: state.PESeries.per_page,
        total: state.PESeries.total,
        total_pages: state.PESeries.total_pages,
        isLoading: state.PESeries.isLoadingSeries,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PESeriesModalContent = connect(mapStateToProps)(function (props) {
    const {
        series,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        onSelect = function () {},
        selectedRegionFilter,
        parentRegion
    } = props;

    const { PE_FETCH_SERIES_REQUEST } = useActions();

    function onNext() {
        PE_FETCH_SERIES_REQUEST({
            page: page + 1,
            per_page,
            region_ident: parentRegion,
        });
    }

    function onPrev() {
        PE_FETCH_SERIES_REQUEST({
            page: page - 1,
            per_page,
            region_ident: parentRegion,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_SERIES_REQUEST({
            per_page,
            page: pageSelected,
            region_ident: parentRegion,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_SERIES_REQUEST({
            per_page: perPage,
            region_ident: parentRegion,
        });
    }

    useEffect(() => {
        PE_FETCH_SERIES_REQUEST({
            per_page,
            region_ident: parentRegion,
        });
        // eslint-disable-next-line
    }, [selectedRegionFilter, parentRegion]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {series.map((ser) => (
                            <tr key={`series-${ser.id}`}>
                                <td>
                                    <SelectableField
                                        text={ser.name}
                                        url={`/program-engine/series/${ser.id}`}
                                    />
                                </td>
                                <td>
                                    <TableButton
                                        className="select-button"
                                        action={() => {
                                            onSelect(ser);
                                        }}
                                    >
                                        <CheckIcon
                                            height={'1.3rem'}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Select
                                    </TableButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <section className="p-4">
            <InformationTab
                dataTable={<DataTable />}
                showPrograms={false}
                sectionName="Series"
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
            />
        </section>
    );
});

export default PESeriesModalContent;
