const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    brands: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    isLoadingBrands: false,
    selectedBrand: null,
    brandsOptions: [],
};

function PEBrandsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_BRANDS_REQUEST: {
            return {
                ...state,
                isLoadingBrands: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_BRANDS_RESPONSE: {
            console.log(action.data);
            return {
                ...state,
                brands: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingBrands: false,
            };
        }
        case ACTION_TYPES.PE_GET_BRAND_RESPONSE:
        case ACTION_TYPES.PE_SELECT_BRAND:
        case ACTION_TYPES.PE_POST_BRAND_RESPONSE:
        case ACTION_TYPES.PE_UPDATE_BRAND_RESPONSE: {
            return {
                ...state,
                selectedBrand: action.data,
            };
        }
        case ACTION_TYPES.PE_FETCH_BRANDS_OPTIONS_RESPONSE: {
            const options = action.data?.map((brand) => ({
                id: brand.id,
                value: brand.ident,
                label: brand.name,
            }));
            return {
                ...state,
                brandsOptions: options,
            };
        }
        default:
            return state;
    }
}

export default PEBrandsReducer;
