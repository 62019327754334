const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

const initialState = {
    phases: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    isLoadingPhases: false,
    selectedPhase: null,
    sequences: [],
};

function PEPhasesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_PHASES_REQUEST: {
            return {
                ...state,
                isLoadingPhases: true,
            };
        }
        case ACTION_TYPES.PE_FETCH_PHASES_RESPONSE: {
            return {
                ...state,
                phases: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
                isLoadingPhases: false,
            };
        }
        case ACTION_TYPES.PE_POST_PHASE_RESPONSE:
        case ACTION_TYPES.PE_UPDATE_PHASE_RESPONSE:
        case ACTION_TYPES.PE_GET_PHASE_RESPONSE:
        case ACTION_TYPES.PE_SELECT_PHASE: {
            return {
                ...state,
                selectedPhase: action.data,
            };
        }
        case ACTION_TYPES.PE_FETCH_PHASES_SEQUENCES_RESPONSE:
            return {
                ...state,
                sequences: action.data,
            };
        default:
            return state;
    }
}

export default PEPhasesReducer;
