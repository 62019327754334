import LoadingTable from 'components/LoadingTable';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';
import { connect } from 'react-redux';

const { default: DateField } = require('components/DateField');
const { default: SelectableField } = require('components/SelectableField');

function mapStateToProps(state) {
    return {
        brandsOptions: state.vhsBrands.brandsOptions,
        seriesOptions: state.vhsSeries.seriesOptions,
    };
}

const AnalyticsTable = connect(mapStateToProps)(function ({
    activities = [],
    brandsOptions,
    seriesOptions,
    isLoading = false,
}) {
    const [brandsSet, setBrandsSet] = useSetFromOptions();
    const [seriesSet, setSeriesSet] = useSetFromOptions();

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        setSeriesSet(seriesOptions);
        // eslint-disable-next-line
    }, [seriesOptions]);

    const getVideoTime = (durationMs) => {
        let date = new Date( durationMs );
        let dateUtc = Date.UTC( date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds() );
        return new Date( dateUtc ).toISOString().split('T')[1].split('.')[0];
    }

    return (
        <LoadingTable isLoading={isLoading} tableClass="">
            <thead>
                <tr>
                    <th>Timestamp</th>
                    <th>Action</th>
                    <th>Video Time</th>
                    <th>Member</th>
                    <th>Episode</th>
                    <th>Series</th>
                    <th>Brand</th>
                    <th>Details</th>
                </tr>
            </thead>
            <tbody>
                {activities.map((activity) => (
                    <tr key={`activity-${activity.id}`}>
                        <td>
                            <DateField timeMillis={activity.created_int} />
                        </td>
                        <td>{activity.action}</td>
                        <td>{getVideoTime(activity.duration_ms)}</td>
                        <td>
                            <SelectableField
                                text={activity.member?.name}
                                url={`/vhs/members/${activity.member?.id}`}
                            />
                        </td>
                        <td>
                            <SelectableField
                                text={activity.episode?.name}
                                url={`/vhs/episodes/${activity.episode_id}`}
                            />
                        </td>
                        <td>
                            <SelectableField
                                text={seriesSet[activity.series_id]}
                                url={`/vhs/series/${activity.series_id}`}
                            />
                        </td>
                        <td>
                            {brandsSet[activity.brand_ident]}
                        </td>
                        <td>
                            <SelectableField
                                text="See Details"
                                url={`/vhs/analytics/${activity.id}`}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </LoadingTable>
    );
});

export default AnalyticsTable;
