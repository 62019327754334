/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import useActions from 'hooks/useActions';
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import ArticlesTable from './ArticlesTable';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useQueryList from 'hooks/useQuery';
import { useNavigate, useParams } from 'react-router-dom';
import {COMMENT_OPTIONS,TRENDING_OPTIONS,FEATURED_OPTIONS,SORTING_OPTIONS} from 'constants/COMMUNITY_STATUS';
import ArticleDetail from './ArticleDetail';
import CsArticleNew from './ArticleNew';
import AlertModal from 'components/Modal/AlertModal';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        articles: state.CsArticles.articlesList,
        isLoading: state.CsArticles.isLoadingArticles,
        per_page: state.CsArticles.articlesPerPage,
        page: state.CsArticles.articlesPage,
        total: state.CsArticles.articlesTotal,
        total_pages: state.CsArticles.articlesTotalPages,
        selectedArticle: state.CsArticles.selectedArticle,
        commentedFilter: state.CsArticles.commentedFilter,
        trendingFilter: state.CsArticles.trendingFilter,
        featuredFilter: state.CsArticles.featuredFilter,
        brandFilter: state.CsArticles.brandsFilter,
        sortingFilter: state.CsArticles.articlesOrder,
        brandOptions: state.vhsBrands.brandsOptions,
        selectedComment: state.CSComments.selectedComment,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const CsArticles = connect(mapStateToProps)(function (props) {
    const {
        articles = [],
        isLoading,
        per_page,
        page,
        total,
        total_pages,
        selectedArticle,
        commentedFilter,
        trendingFilter,
        featuredFilter,
        brandFilter,
        brandOptions,
        sortingFilter,
        selectedRegionFilter,
    } = props;
    const navigate = useNavigate();
    const {
        CS_ARTICLES_REQUEST,
        CS_SELECT_COMMENTED_FILTER,
        CS_SELECT_ARTICLE_FEATURED_FILTER,
        CS_SELECT_ARTICLE_TRENDING_FILTER,
        CS_SELECT_ARTICLE_BRANDS_FILTER,
        CS_SELECT_ARTICLE_ORDER_FILTER,
        CS_SELECT_ARTICLE,
        CS_FETCH_ARTICLE_REQUEST,
        CS_DELETE_ARTICLE_REQUEST,
        CS_SELECT_COMMENT,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(true);
    const [detailComponent, setDetailComponent] = useState(null);

    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);

    const onDetailSelection = (article) => {
        setSubtitle(null);
        setDetailComponent(null);
        CS_SELECT_ARTICLE(article);
        setShowDelete(true);
        if (article) {
            navigate(`/community/articles/${article.id}`);
        } else {
            CS_ARTICLES_REQUEST({
                commented: commentedFilter,
                featured: featuredFilter,
                trending: trendingFilter,
                brand: brandFilter,
                page,
                per_page,
                sorting: sortingFilter,
                searchTerm,
            });
            navigate(`/community/articles`);
        }
    };

    function onDeleteAction() {
        CS_DELETE_ARTICLE_REQUEST(selectedArticle?.id);
        CS_ARTICLES_REQUEST({
            commented: commentedFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            page,
            per_page,
            sorting: sortingFilter,
            searchTerm,
        });
        navigate(`/community/articles`);
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Article?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    useEffect(() => {
        CS_ARTICLES_REQUEST({
            commented: commentedFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            page,
            per_page,
            sorting: sortingFilter,
            searchTerm,
        });
    }, [commentedFilter, featuredFilter, trendingFilter, sortingFilter, brandFilter, selectedRegionFilter]);

    useEffect(() => {
        CS_ARTICLES_REQUEST({
            commented: commentedFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            searchTerm,
        });
        CS_SELECT_COMMENT(null);
    }, []);

    useEffect(() => {
        if (selectedArticle) {
            setSubtitle(selectedArticle?.title);
            setDetailComponent(<ArticleDetail />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedArticle]);

    useEffect(() => {
        if (id) {
            try {
                CS_FETCH_ARTICLE_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            CS_SELECT_ARTICLE(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
    }, [id]);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'Commented or Not' },
                    ...COMMENT_OPTIONS,
                ]}
                selectedValue={commentedFilter}
                onSelect={(value) =>
                    CS_SELECT_COMMENTED_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'Featured or Not' },
                    ...FEATURED_OPTIONS,
                ]}
                selectedValue={featuredFilter}
                onSelect={(value) =>
                    CS_SELECT_ARTICLE_FEATURED_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'Trending or Not' },
                    ...TRENDING_OPTIONS,
                ]}
                selectedValue={trendingFilter}
                onSelect={(value) =>
                    CS_SELECT_ARTICLE_TRENDING_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Brands' },
                    ...brandOptions,
                ]}
                selectedValue={brandFilter}
                onSelect={(value) =>
                    CS_SELECT_ARTICLE_BRANDS_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    ...SORTING_OPTIONS,
                ]}
                selectedValue={sortingFilter}
                onSelect={(value) =>
                    CS_SELECT_ARTICLE_ORDER_FILTER(value)
                }
        />,
        ]
    }

    const onNextPage = () => {
        CS_ARTICLES_REQUEST({
            commented: commentedFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            searchTerm,
            per_page,
            page: page + 1,
        });
    }

    const onPrevPage = () => {
        CS_ARTICLES_REQUEST({
            commented: commentedFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            searchTerm,
            per_page,
            page: page - 1,
        });
    }
    const onPageSelect = (pageSelected) => {
        CS_ARTICLES_REQUEST({
            commented: commentedFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            searchTerm,
            per_page,
            page: pageSelected,
        });
    }

    const onPerPageSelected = ({ perPage }) => {
        CS_ARTICLES_REQUEST({
            commented: commentedFilter,
            featured: featuredFilter,
            trending: trendingFilter,
            brand: brandFilter,
            sorting: sortingFilter,
            searchTerm,
            per_page: perPage,
        });
    }

    const onAdd = () => {
        setSubtitle('Add New Article');
        setDetailComponent(<CsArticleNew/>);
        setShowDelete(false);
    }

    return (
        <section>
            <InformationTab
                sectionName="Articles"
                customFilters={filters()}
                dataTable={
                    <div>
                        <TableResults results={total} addTitle="Add New Article" onAdd={onAdd}/>
                        <ArticlesTable
                            onDetailSelection={onDetailSelection}
                            articles={articles}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </div>
                }
                showPrograms={false}
                showSearchField={true}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                detailComponent={detailComponent}
                onDeleteDetail={onDeleteDetail}
                deleteText={'Article'}
                subsectionName={subtitle}
                showDelete={showDelete}
                onDetailSelected={onDetailSelection}
            />
        </section>
    );
});

export default CsArticles;
