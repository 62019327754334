import DateField from 'components/DateField';
import SelectableField from 'components/SelectableField';
import { useEffect, useState } from 'react';

function MemberOverview({
    member,
    programOptions,
    brandsSet = {},
    conversionsTotal = 0,
}) {
    const [programsSet, setProgramsSet] = useState({});

    useEffect(() => {
        const pr = {};
        programOptions.forEach((element) => {
            pr[element.value] = element.label;
        });
        setProgramsSet(pr);
    }, [programOptions]);

    return (
        <table className='overview-table'>
            <tbody>
                <tr>
                    <td className="uppercase label">Email</td>
                    <td>{member.email}</td>
                </tr>
                <tr>
                    <td className="uppercase label">User ID</td>
                    <td>{member.brand_user_id}</td>
                </tr>
                <tr>
                    <td className="uppercase label">Brand</td>
                    <td>
                        {member.brand_name}
                    </td>
                </tr>
                <tr>
                    <td className="uppercase label">Program</td>
                    <td>
                        <SelectableField
                            url={`/referrals/programs/${member.program_id}`}
                            text={programsSet[member.program_id]}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="uppercase label">Total Conversions</td>
                    <td>{conversionsTotal}</td>
                </tr>
                <tr>
                    <td className="uppercase label">Balance</td>
                    <td>${member.balance}</td>
                </tr>
                <tr>
                    <td className="uppercase label">Share Code</td>
                    <td>{member.share_code}</td>
                </tr>
                <tr>
                    <td className="uppercase label">Share URL</td>
                    <td>{member.share_url}</td>
                </tr>
                <tr>
                    <td className="uppercase label">Enabled</td>
                    <td>{member.is_enabled ? 'Enabled' : 'Disabled'}</td>
                </tr>
                <tr>
                    <td className="uppercase label">Created</td>
                    <td>
                        <DateField timeMillis={member.created_int} />
                    </td>
                </tr>
                <tr>
                    <td className="uppercase label">Updated</td>
                    <td>
                        <DateField timeMillis={member.updated_int} />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default MemberOverview;
