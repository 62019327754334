import useInputState from 'hooks/useInputState';
import { DEFAULT_EPISODE_VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';

function LocationEdit({ location, onConfirm = function () {} }) {
    const initialState = {
        name: location.name,
        address1: location.address1,
        address2: location.address2,
        city: location.city,
        state: location.state,
        zip: location.zip,
        email: location.email,
        phone: location.phone,
        brand_name: location.brand_name,
        alt_id: location.alt_id,
        latitude: location.latitude,
        longitude: location.longitude,
        description: location.description,
        is_episode_published: location.is_episode_published,
        episodes_expires_after: location.episodes_expires_after,
    };

    const [updatedLocation, onFieldChange] = useInputState(initialState);

    const onSave = function (event) {
        event.preventDefault();
        onConfirm({
            id: location.id,
            address1: updatedLocation.address1,
            address2: updatedLocation.address2,
            city: updatedLocation.city,
            state: updatedLocation.state,
            zip: updatedLocation.zip,
            email: updatedLocation.email,
            phone: updatedLocation.phone,
            brand_name: updatedLocation.brand_name,
            alt_id: updatedLocation.alt_id,
            latitude: updatedLocation.latitude,
            longitude: updatedLocation.longitude,
            description: updatedLocation.description,
            is_episode_published: updatedLocation.is_episode_published,
            episodes_expires_after: updatedLocation.episodes_expires_after,
            updated_int: new Date()
        });
    };

    return (
        <section>
            <form onSubmit={onSave}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="name"
                                    value={updatedLocation.name}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Address 1</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="address1"
                                    value={updatedLocation.address1}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Address 2</td>
                            <td>
                                <input
                                    className='w-full'
                                    name="address2"
                                    value={updatedLocation.address2}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">City</td>
                            <td>
                                <input
                                    name="city"
                                    value={updatedLocation.city}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">State</td>
                            <td>
                                <input
                                    name="state"
                                    value={updatedLocation.state}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">ZIP Code</td>
                            <td>
                                <input
                                    name="zip"
                                    value={updatedLocation.zip}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Email</td>
                            <td>
                                <input
                                    name="email"
                                    value={updatedLocation.email}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Phone</td>
                            <td>
                                <input
                                    name="phone"
                                    value={updatedLocation.phone}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Brand</td>
                            <td>
                                <input
                                    name="brand_name"
                                    value={updatedLocation.brand_name}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Remote ID</td>
                            <td>
                                <input
                                    name="alt_id"
                                    value={updatedLocation.alt_id}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Latitude</td>
                            <td>
                                <input
                                    name="lat"
                                    value={updatedLocation.lat}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Longitude</td>
                            <td>
                                <input
                                    name="lng"
                                    value={updatedLocation.lng}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Description</td>
                            <td>
                                <input
                                    name="description"
                                    value={updatedLocation.description}
                                    onChange={onFieldChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Episodes Expire After</td>
                            <td>
                                <select
                                    name="episodes_expires_after"
                                    value={updatedLocation.episodes_expires_after}
                                    onChange={onFieldChange}
                                    >
                                    {[...Array.from({length: 60}, (_, i) => i + 1)].map((option) =>(
                                        <option
                                            key={`expires-at-${option}`}
                                            value={option}
                                        >
                                            {`${option} ${option < 2 ? 'day' : 'days' }`}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Publish Episodes When Recording is Ready</td>
                            <td>
                                <select
                                    name="is_episode_published"
                                    value={updatedLocation.is_episode_published}
                                    onChange={onFieldChange}
                                    >
                                    {DEFAULT_EPISODE_VISIBILITY_OPTIONS.map((option) =>(
                                            <option
                                                key={`visibility-${option.value}`}
                                                value={option.value}
                                                >
                                                {option.label}
                                            </option>
                                            ))}
                                </select>
                            </td>
                        </tr>
                        {initialState !== updatedLocation && (
                            <tr>
                                <td colSpan={2}>
                                    <button type="submit" className="mt-4 button save-changes-button">
                                        Save Changes
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </form>
        </section>
    );
}

export default LocationEdit;
