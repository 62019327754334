import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useActions from 'hooks/useActions';

function mapStateToProps(state) {
    return {
        config: state.admins.config,
        user: state.user.user,
    };
}

const PESeriesNew = connect(mapStateToProps)(function (props) {
    const { config, user } = props;

    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const [name, setName] = useState();
    const [region, setRegion] = useState('');

    const { PE_POST_SERIES_REQUEST } = useActions();

    useEffect(() => {
        if (regionsToShow.length > 0) {
            setRegion(regionsToShow[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function saveChanges(event) {
        event.preventDefault();
        PE_POST_SERIES_REQUEST({
            name,
            region_ident: region
        });
    }

    return (
        <section>
            <form onSubmit={saveChanges}>
                <table className='edit-table'>
                    <tr>
                        <td className="font-bold label">Name</td>
                        <td>
                            <input
                                className='w-full'
                                name="name"
                                value={name}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                                required
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="font-bold label">Region</td>
                        <td>
                            <select
                                name="region_ident"
                                value={region}
                                onChange={(event) =>
                                    setRegion(event.target.value)
                                }
                            >
                                {regionsToShow.map((reg) => (
                                    <option
                                        key={`series-${reg}`}
                                        value={reg}
                                    >
                                        {reg}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button type="submit" className="mt-4 button save-changes-button">
                                Add Serie
                            </button>
                        </td>
                    </tr>
                </table>
            </form>
        </section>
    );
});

export default PESeriesNew;
