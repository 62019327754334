import ACTION_TYPES from 'constants/ACTION_TYPES';
import { List } from 'immutable';

const initialState = {
    selectedProgram: null,
    // all the programs as options for the pages that present data
    programOptions: [],
    tracks: [], // chart tracks
    isLoading: false,
    // main programs and its pagination
    list: [],
    isLoadingPrograms: false,
    total: 0,
    total_pages: 0,
    page: 1,
    per_page: 10,
    // conversions for program and its pagination
    conversions: [],
    conversions_per_page: 10,
    conversions_total_pages: 0,
    conversions_page: 1,
    conversions_total: 0,
    isLoadingConversions: false,
    //activity for program
    activity: [],
    activity_page: 1,
    activity_per_page: 10,
    activity_total_pages: 1,
    isLoadingActivity: false,
    // shown in referrals main screen
    monthlyTracks: [],
    allTimeTracks: [],
};

function programsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ALL_PROGRAMS_RESPONSE: {
            const programOptions = action.data.list.map((program) => ({
                value: program.id,
                label: program.name,
            }));
            return {
                ...state,
                programOptions: [
                    {
                        label: 'All Programs',
                        value: 'all',
                    },
                    ...programOptions,
                ],
            };
        }
        case ACTION_TYPES.FETCH_PROGRAM_REQUEST: {
            return {
                ...state,
            };
        }
        case ACTION_TYPES.FETCH_PROGRAM_RESPONSE: {
            return {
                ...state,
                selectedProgram: action.data,
            };
        }
        case ACTION_TYPES.FETCH_PROGRAMS_REQUEST: {
            return {
                ...state,
                isLoadingPrograms: true,
            };
        }
        case ACTION_TYPES.FETCH_PROGRAMS_RESPONSE: {
            return {
                ...state,
                isLoading: null,
                isLoadingPrograms: false,
                list: action.data.list,
                total: action.data.total,
                total_pages: action.data.total_pages,
                page: action.data.page,
                per_page: action.data.per_page,
            };
        }
        case ACTION_TYPES.POST_PROGRAM_RESPONSE: {
            return {
                ...state,
                total: state.total + 1,
                selectedProgram: action.data,
                programOptions: [
                    ...state.programOptions,
                    {
                        label: action.data?.name,
                        value: action.data?.id,
                    },
                ],
            };
        }
        case ACTION_TYPES.UPDATE_PROGRAM_REQUEST: {
            const field = Object.keys(action?.data).filter(
                (key) => key !== 'id'
            );
            return { ...state, isLoading: field[0] };
        }
        case ACTION_TYPES.UPDATE_PROGRAM_RESPONSE: {
            let optionsList = List.of(...state.programOptions);
            optionsList = optionsList.set(optionsList.indexOf(action.data.id), {
                value: action.data.id,
                label: action.data.name,
            });

            return {
                ...state,
                isLoading: false,
                selectedProgram: action.data,
                programOptions: optionsList.toArray(),
            };
        }
        case ACTION_TYPES.FETCH_PROGRAM_CONVERSIONS_REQUEST: {
            return {
                ...state,
                isLoadingConversions: true,
            };
        }
        case ACTION_TYPES.FETCH_PROGRAM_CONVERSIONS_RESPONSE: {
            return {
                ...state,
                conversions: action.data.list,
                conversions_page: action.data.page,
                conversions_per_page: action.data.per_page,
                conversions_total_pages: action.data.total_pages,
                conversions_total: action.data.total,
                isLoadingConversions: false,
            };
        }
        case ACTION_TYPES.SELECT_PROGRAM: {
            return {
                ...state,
                selectedProgram: action.data,
            };
        }
        case ACTION_TYPES.PROGRAM_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case ACTION_TYPES.SET_PROGRAMS_PER_PAGE: {
            return {
                ...state,
                per_page: action.data,
            };
        }
        case ACTION_TYPES.FETCH_PROGRAM_EVENTS_RESPONSE: {
            return {
                ...state,
                monthlyTracks: action.data.monthlyTracks,
                allTimeTracks: action.data.allTimeTracks,
            };
        }
        case ACTION_TYPES.FETCH_PROGRAM_ACTIVITY_REQUEST: {
            return {
                ...state,
                isLoadingActivity: true,
            };
        }
        case ACTION_TYPES.FETCH_PROGRAM_ACTIVITY_RESPONSE: {
            return {
                ...state,
                activity: action.data.list,
                activity_page: action.data.page,
                activity_per_page: action.data.per_page,
                activity_total_pages: action.data.total_pages,
                isLoadingActivity: false,
            };
        }
        case ACTION_TYPES.UPDATE_PROGRAM_CONVERSION_RESPONSE: {
            let list = List.of(...state.conversions);
            list = list.set(
                list.indexOf((element) => element.id === action.data.id),
                action.data
            );
            return {
                ...state,
                conversions: [...list.toArray()],
            };
        }
        case ACTION_TYPES.UPLOAD_PROGRAM_SOCIAL_IMAGE_REQUEST: {
            return {
                ...state,
                isLoading: 'social_image',
            };
        }
        case ACTION_TYPES.UPLOAD_PROGRAM_SOCIAL_IMAGE_RESPONSE: {
            const current = {
                ...state.selectedProgram,
                social_image_url: action.data.media,
            };
            return {
                ...state,
                isLoading: null,
                selectedProgram: { ...current },
            };
        }
        default:
            return state;
    }
}

export default programsReducer;
