import TagGroupSection from 'components/Tags/TagGroupSection';
import useActions from 'hooks/useActions';
import { useNavigate } from 'react-router-dom';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        program: state.PEPrograms.selectedProgram,
        tagGroups: state.PEPrograms.tagGroups,
    };
}

const PEProgramTags = connect(mapStateToProps)(function (props) {
    const { program, tagGroups } = props;

    const {
        PE_ADD_TAG_TO_PROGRAM_REQUEST,
        PE_REMOVE_TAG_FROM_PROGRAM_REQUEST,
        PE_SELECT_TAG_GROUP,
    } = useActions();

    const navigate = useNavigate();

    function onAdd(tagId) {
        PE_ADD_TAG_TO_PROGRAM_REQUEST({
            programId: program.id,
            tagId,
        });
    }

    function onRemove(tagId) {
        PE_REMOVE_TAG_FROM_PROGRAM_REQUEST({
            programId: program.id,
            tagId,
        });
    }

    function onSelectTagGroup(tagGroup) {
        PE_SELECT_TAG_GROUP(tagGroup);
        navigate(`/program-engine/tags/tag/new/${tagGroup.id}`);
    }

    return (
        <section>
            {tagGroups &&
                tagGroups.map((tagGroup) => (
                    <TagGroupSection
                        key={tagGroup.id}
                        tagGroup={tagGroup}
                        selectedTags={program?.tags}
                        onAdd={onAdd}
                        onRemove={onRemove}
                        onSelectTagGroup={onSelectTagGroup}
                    />
                ))}
        </section>
    );
});

export default PEProgramTags;
