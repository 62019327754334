import ACTION_TYPES from 'constants/ACTION_TYPES';
import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* fetchCategories(page = 1, per_page = 10, searchTerm = '', apiUrl) {
    let endpoint = `/pe/categories?page=${page}&per_page=${per_page}`;
    if (!!searchTerm) {
        endpoint += `&term=${searchTerm}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_CATEGORIES_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getCategory(categoryId, apiUrl) {
    const json = yield api.get(`/pe/categories/${categoryId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_GET_CATEGORY_RESPONSE,
            data: json,
        });
    }
}

function* postCategory(category, apiUrl) {
    const json = yield api.post(`/pe/categories`, category, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_POST_CATEGORY_RESPONSE,
            data: json,
        });
        yield getCategoryOptions(apiUrl);
    }
}

function* updateCategory(category, apiUrl) {
    const json = yield api.put(
        `/pe/categories/${category.id}`,
        category,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_UPDATE_CATEGORY_RESPONSE,
            data: json,
        });
    }
}

function* deleteCategory(categoryId, page, perPage, searchTerm, apiUrl) {
    const json = yield api.del(`/pe/categories/${categoryId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_DELETE_CATEGORY_RESPONSE,
            data: json,
        });
    }
    yield fetchCategories(page, perPage, searchTerm, apiUrl);
}

function* getAssignments(categoryId, page = 1, per_page = 10, term, apiUrl) {
    let endpoint = `/pe/assignments?categories=${categoryId}&page=${page}&per_page=${per_page}`;
    if (term) {
        endpoint += `&term=${term}`;
    }
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get(endpoint, apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_CATEGORY_ASSIGNMENTS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total: json.total,
                total_pages: json.total_pages,
            },
        });
    }
}

function* getCategoryOptions(apiUrl) {
    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);

    const json = yield api.get('/pe/categories?page=1&per_page=1000', apiUrl, selectedRegion, SERVICE_TYPES.PROGRAM_ENGINE);
    if (json) {
        yield put({
            type: ACTION_TYPES.PE_FETCH_ALL_CATEGORIES_RESPONSE,
            data: json.results,
        });
    }
}

function* peCategoriesWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.PE_FETCH_CATEGORIES_REQUEST:
            yield fetchCategories(
                action.data?.page,
                action.data?.per_page,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_GET_CATEGORY_REQUEST:
            yield getCategory(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_POST_CATEGORY_REQUEST:
            yield postCategory(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_UPDATE_CATEGORY_REQUEST:
            yield updateCategory(action.data, apiUrl);
            break;
        case ACTION_TYPES.PE_DELETE_CATEGORY_REQUEST: {
            yield deleteCategory(
                action.data?.categoryId,
                action.data?.page,
                action.data?.perPage,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        }
        case ACTION_TYPES.PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST:
            yield getAssignments(
                action.data?.categoryId,
                action.data?.page,
                action.data?.per_page,
                action.data?.searchTerm,
                apiUrl
            );
            break;
        case ACTION_TYPES.PE_FETCH_ALL_CATEGORIES_REQUEST:
            yield getCategoryOptions(apiUrl);
            break;
        default:
            break;
    }
}

export default peCategoriesWorker;
