import DateField from 'components/DateField';
import SelectableField from 'components/SelectableField';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedActivity: state.vhsAnalytics.selectedActivity,
        brandsOptions: state.vhsBrands.brandsOptions,
        seriesOptions: state.vhsSeries.seriesOptions,
    };
}

const AnalyticsDetail = connect(mapStateToProps)(function (props) {
    const { selectedActivity, brandsOptions, seriesOptions } = props;
    const [brandsSet, setBrandsSet] = useSetFromOptions();
    const [seriesSet, setSeriesSet] = useSetFromOptions();

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        setSeriesSet(seriesOptions);
        // eslint-disable-next-line
    }, [seriesOptions]);

    return (
        <section>
            <table className='overview-table'>
                <tbody>
                    <tr>
                        <td className='uppercase label'>Timestamp</td>
                        <td>
                            <DateField
                                timeMillis={selectedActivity.created_int}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='uppercase label'>Action</td>
                        <td>{selectedActivity.action}</td>
                    </tr>
                    <tr>
                        <td className='uppercase label'>Member</td>
                        <td>
                            <SelectableField
                                text={selectedActivity.member?.name}
                                url={`/vhs/members/${selectedActivity.member?.id}`}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='uppercase label'>Episode</td>
                        <td>
                            <SelectableField
                                text={selectedActivity.episode?.name}
                                url={`/vhs/episodes/${selectedActivity.episode_id}`}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='uppercase label'>Series</td>
                        <td>
                            <SelectableField
                                text={seriesSet[selectedActivity.series_id]}
                                url={`/vhs/series/${selectedActivity.series_id}`}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='uppercase label'>Brand</td>
                        <td>
                            {brandsSet[selectedActivity.brand_ident]}
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
});

export default AnalyticsDetail;
