import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import PEPhaseEdit from '../PhaseEdit';
import PEPhasesOverview from '../PhasesOverview';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        phase: state.PEPhases.selectedPhase,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEPhaseDetails = connect(mapStateToProps)(function (props) {
    const { phase, selectedRegionFilter } = props;

    const { PE_FETCH_PHASES_SEQUENCES_REQUEST } = useActions();

    useEffect(() => {
        PE_FETCH_PHASES_SEQUENCES_REQUEST(phase.id);
        // eslint-disable-next-line
    }, [selectedRegionFilter, phase]);

    return (
        <Tabs
            sectionNames={['Overview', 'Settings']}
            sectionContents={[
                <PEPhasesOverview />,
                <PEPhaseEdit phase={phase} />,
            ]}
        />
    );
});

export default PEPhaseDetails;
