import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { ChartBarIcon, ViewListIcon } from '@heroicons/react/outline';
import TAG_GROUPS from 'constants/TAG_GROUPS';
import LineChart from 'components/LineChart';
import SearchField from 'components/SearchField';

const dateOptions = [
    { value: '90', label: '90 Days' },
    { value: '60', label: '60 Days' },
    { value: '30', label: '30 Days' },
    { value: '28', label: '28 Days' },
    { value: '14', label: '14 Days' },
    { value: '7', label: '7 Days' },
    { value: '1', label: '1 Day' },
];

function mapStateToProps(state) {
    return {
        programOptions: state.programs.programOptions,
        tagGroups: state.PEAssignments.tagGroups,
    };
}

const InformationTab = connect(mapStateToProps)(function ({
    sectionName,
    subsectionName,
    chartData = [],
    programOptions = [],
    hasChart,
    dataTable = <></>,
    detailComponent = null,
    showPrograms = true,
    tagGroups,
    showFilterTags = false,
    onTagsFilter = function (tagsQuery = '') {},
    addTitle,
    onAdd,
    ExtraControls,
    onDetailSelected = function () {},
    onPerPageSelected = function () {},
    onProgramSelected = function () {},
    selectedProgram = 'all',
    perPage = 10,
    selectedPeriod = '30',
    onPeriodSelected = function () {},
    showFilters = true,
    deleteText,
    onDeleteDetail,
    showDelete,
    customFilters,
    chartName,
    showSearchField = false,
    preventReloadOnSearch = false,
    searchUpdateAsync = function (value = '') {},
    showBreadcrumbs = true,
}) {
    const [showChart, setShowChart] = useState(false);
    const [showAllFilters, setShowAllFilters] = useState(false);
    const perPageOptions = [10, 30, 100];

    const returnTagsByTagGroupId = ( tagGroups = [], idToFilter = null ) => {
        if(!idToFilter){ return tagGroups; }

        return tagGroups.reduce((acc, tagGroup) => {
          if (tagGroup.id === idToFilter) {
            return [...acc, ...tagGroup.tags];
          }
      
          return acc;
        }, []);
    };

    const contentTypeOptions = returnTagsByTagGroupId(tagGroups, TAG_GROUPS.CONTENT_TYPE);
    const workoutTypeOptions = returnTagsByTagGroupId(tagGroups, TAG_GROUPS.WORKOUT_TYPE);
    const pillarOptions = returnTagsByTagGroupId(tagGroups, TAG_GROUPS.PILLARS);

    const [pillarTagSelected, setPillarTagSelected] = useState('');
    const [contentTypeSelected, setContentTypeSelected] = useState('');
    const [workoutTypeSelected, setWorkoutTypeSelected] = useState('');

    useEffect(() => {
        handleTagsFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pillarTagSelected, contentTypeSelected, workoutTypeSelected]);

    const handleTagsFilter = () => {

        let tagsArray = [];
        if(pillarTagSelected !== '') {
            tagsArray.push(pillarTagSelected);
        }
        if(contentTypeSelected !== '') {
            tagsArray.push(contentTypeSelected);
        }
        if(workoutTypeSelected !== '') {
            tagsArray.push(workoutTypeSelected);
        }

        if(tagsArray.length > 0) {
            const queryTags = tagsArray.join('-');
            onTagsFilter(queryTags);
        }else{
            onTagsFilter('');
        }
    };

    const onPageSelection = (event) => {
        onPerPageSelected({ perPage: event.target.value });
    };

    const selectProgram = (event) => {
        onProgramSelected(event.target.value);
    };

    const selectPeriod = (event) => {
        onPeriodSelected({ period: event.target.value });
    };
    const breadcrumbs = useBreadcrumbs();
    return (
        <div>
        {showBreadcrumbs && <nav className='breadcrumbs block pt-3'>
                    {breadcrumbs.map(({ match, breadcrumb }, index, row) => (
                        <>
                            <NavLink 
                                reloadDocument to={match.pathname} 
                                className={
                                    ({ isActive }) => {
                                        return isActive ? 'active rounded-md p-2' : 'hover:rounded-md p-2'; 
                                    }
                                }
                                key={`bc-${index}`}
                            >
                                {breadcrumb}
                            </NavLink>
                            {index+1 !== row.length && <i className="arrow right"></i>}
                        </>
                    ))}
                </nav>
        }
            <div className="mb-4 mt-4 items-center">
                <div className="flex flex-1">
                    <div className="flex flex-1 items-center">
                        {/* <div
                            className="font-bold cursor-pointer  text-2xl"
                            onClick={() => {
                                onDetailSelected(null);
                            }}
                        >
                            {hasChart &&
                            !detailComponent &&
                            showChart &&
                            chartName
                                ? chartName
                                : sectionName}
                        </div> */}
                        {subsectionName && (
                            <div className="ml-2 font-medium text-md subsectionName">
                                {subsectionName}
                            </div>
                        )}
                    </div>

                   
                    
                    {!detailComponent && ExtraControls && ExtraControls}

                    {!!detailComponent &&
                        deleteText &&
                        onDeleteDetail &&
                        showDelete && (
                            <button
                                className="button delete-button"
                                onClick={onDeleteDetail}
                            >
                                <img src="/assets/trash-icon.png" alt="Delete"/>Delete {deleteText}
                            </button>
                        )}
                </div>
                
                {!detailComponent && showFilters && (
                    <div className="flex flex-wrap mt-4 filter-box py-4">
                        <div className={`flex gap-2 flex-wrap${customFilters?.length > 3 ? " flex-col" : "flex-row"}`}>
                        {customFilters?.length > 0 &&
                        <div className='box1 flex flex-row gap-2'>
                           { customFilters.slice(0, 4).map((fil, index) => (
                                <div className={`py-1 filter`} key={`filter-${index}`}>
                                    {fil}
                                </div>
                            ))}
                            {customFilters?.length > 3 && !showAllFilters && (
                                <div className='py-1 filter'>
                                    <button className='flex flex-row gap-2' onClick={() => setShowAllFilters(!showAllFilters)}>
                                    <img src='/assets/filter-icon.png' alt='All Filters' className='filter-icon'/>
                                        All Filters
                                    </button>
                                </div>
                            )}
                            </div>
                            }
                        
                        <div className={`box2 flex flex-row gap-2${customFilters?.length > 3 ? !showAllFilters ? " extra-filters" : " block" : ""}`}>
                        {customFilters?.length > 0 &&
                            customFilters.slice(4, customFilters.length).map((fil, index) => (
                                <div className={`py-1 filter`} key={`filter-${index}`}>
                                    {fil}
                                </div>
                            ))}
                        
                        {!showChart && (
                            <div className={`py-1 filter`}>
                                <select
                                    defaultValue={perPage}
                                    className="muuv-select ml-1 mr-1"
                                    onChange={onPageSelection}
                                >
                                    {perPageOptions.map((option) => (
                                        <option
                                            value={option}
                                            key={`per-page-${option}`}
                                        >
                                            {`${option} Per Page`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        </div>
                        {showChart && (
                            <div className="py-1 filter">
                                <select
                                    className="muuv-select ml-1 mr-1"
                                    onChange={selectPeriod}
                                    defaultValue={selectedPeriod}
                                >
                                    {dateOptions.map((option) => (
                                        <option
                                            value={option.value}
                                            key={`days-${option.value}`}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {showPrograms && (
                            <div className="py-1 filter">
                                <select
                                    value={selectedProgram}
                                    className="muuv-select ml-1 mr-1"
                                    onChange={selectProgram}
                                >
                                    {programOptions.map((option) => (
                                        <option
                                            value={option.value}
                                            key={`program-${option.value}`}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {showFilterTags && (
                            <>
                                <div className='filter-label-container relative'>
                                    <label className='absolute bottom-10 text-gray-600'>Content Type</label>
                                    <div className="py-1 filter">
                                        <select
                                            id='tagFilter-content-type'
                                            value={contentTypeSelected}
                                            className="muuv-select ml-1 mr-1"
                                            onChange={(event) => setContentTypeSelected(event.target.value)}
                                        >
                                            <option value='' key='tag-content-type-all'>All</option>
                                            {contentTypeOptions.map((option) => (
                                                <option
                                                    value={option.id}
                                                    key={`tag-${option.id}`}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='filter-label-container relative'>
                                    <label className='absolute bottom-10 text-gray-600'>Workout Type</label>
                                    <div className="py-1 filter">
                                        <select
                                            id='tagFilter-workout-type'
                                            value={workoutTypeSelected}
                                            className="muuv-select ml-1 mr-1"
                                            onChange={(event) => setWorkoutTypeSelected(event.target.value)}
                                        >
                                            <option value='' key='tag-workout-type-all'>All</option>
                                            {workoutTypeOptions.map((option) => (
                                                <option
                                                    value={option.id}
                                                    key={`tag-${option.id}`}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='filter-label-container relative'>
                                    <label className='absolute bottom-10 text-gray-600'>Pillar</label>
                                    <div className="py-1 filter">
                                        <select
                                            id='tagFilter-pillar'
                                            value={pillarTagSelected}
                                            className="muuv-select ml-1 mr-1"
                                            onChange={(event) => setPillarTagSelected(event.target.value)}
                                        >
                                            <option value='' key='tag-pillar-all'>All</option>
                                            {pillarOptions.map((option) => (
                                                <option
                                                    value={option.id}
                                                    key={`tag-${option.id}`}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}
                        </div>
                        {showSearchField && (
                            <SearchField searchUpdateAsync={searchUpdateAsync} preventReloadOnSearch={preventReloadOnSearch} />
                        )}
                        {hasChart && !detailComponent && (
                            <div className="flex">
                                <ChartBarIcon
                                    onClick={function () {
                                        setShowChart(true);
                                    }}
                                    className={`h-8 w-8 font-500 cursor-pointer opacity-25 ${
                                        showChart && 'opacity-100 text-blue-500'
                                    }`}
                                />
                                <ViewListIcon
                                    onClick={function () {
                                        setShowChart(false);
                                    }}
                                    className={`h-8 w-8 font-500 cursor-pointer opacity-25 ${
                                        !showChart &&
                                        'opacity-100 text-blue-500'
                                    }`}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            {hasChart && !detailComponent && showChart && (
                <div>
                    <LineChart dataArrays={chartData} colors={['blue']} />
                </div>
            )}
            {!showChart && !detailComponent && dataTable}
            {detailComponent}
        </div>
    );
});

export default InformationTab;
