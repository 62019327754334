import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VISIBILITY_OPTIONS } from 'constants/COMMUNITY_STATUS';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedTag: state.CSTags.selectedTag,
        tagGroupOptions: state.CSTags.tagGroupOptions,
        config: state.admins.config,
        user: state.user.user,
    };
}

const CSEditTag = connect(mapStateToProps)(function (props) {
    const { selectedTag, tagGroupOptions, config, user } = props;
    const allRegions = config?.regions;
    const userRegions = user?.permissions?.regions;
    const allNamedRegions = allRegions?.filter(({value}) => value !== 'all').map(({value}) => value);
    const userNamedRegions = userRegions?.filter((el) => el !== 'all');
    const regionsToShow = (userRegions?.includes('all')) ? allNamedRegions : userNamedRegions;

    const navigate = useNavigate();
    const { CS_UPDATE_TAG_REQUEST, CS_TAG_REQUEST, CS_DELETE_TAG_REQUEST } =
        useActions();
    const { id } = useParams();
    const [updatedTag, setUpdatedTag] = useState({
        name: selectedTag?.name,
        tag_group_id: selectedTag?.tag_group_id,
        visibility: selectedTag?.visibility,
        region_ident: selectedTag?.region_ident,
    });

    function onSubmit(event) {
        event.preventDefault();
        CS_UPDATE_TAG_REQUEST({
            id: selectedTag.id,
            name: updatedTag.name,
            tag_group_id: updatedTag.tag_group_id,
            visibility: updatedTag.visibility,
            region_ident: updatedTag.region_ident,
        });
        navigate('/community/tags/tag');
    }

    function handleFieldChange(event) {
        const newState = {
            ...updatedTag,
            [event.target.name]: event.target.value,
        };
        setUpdatedTag(newState);
    }

    function onDelete() {
        // eslint-disable-next-line
        const confirmed = confirm(
            `Are you sure you want to delete ${selectedTag?.name}?`
        );
        if (confirmed) {
            CS_DELETE_TAG_REQUEST(selectedTag?.id);
            navigate('/community/tags');
        }
    }

    useEffect(() => {
        if (id) {
            try {
                CS_TAG_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (!id && !selectedTag) {
            navigate('/community/tags');
        } else if (selectedTag) {
            setUpdatedTag({
                name: selectedTag.name,
                tag_group_id: selectedTag.tag_group_id,
                visibility: selectedTag.visibility,
                region_ident: selectedTag.region_ident,
            });
        }
        // eslint-disable-next-line
    }, [id, selectedTag]);

    return (
        <section>
            <div className="flex mb-4 items-center h-16">
                <div className="flex flex-1 items-center">
                    <div
                        className="font-bold cursor-pointer  text-lg"
                        onClick={() => {
                            navigate('/community/tags');
                        }}
                    >
                        Tags
                    </div>
                    <div className="ml-8 font-medium text-blue-600">
                        {selectedTag?.name}
                    </div>
                </div>
                <button className="button delete-button" onClick={onDelete}>
                    Delete "{selectedTag?.name}"
                </button>
            </div>
            <form onSubmit={onSubmit}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className="font-bold label">Name</td>
                            <td>
                                <input
                                    type='text'
                                    name="name"
                                    value={updatedTag.name || ''}
                                    onChange={handleFieldChange}
                                    required
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Tag Group</td>
                            <td>
                                <select
                                    name="tag_group_id"
                                    value={updatedTag.tag_group_id || ''}
                                    onChange={handleFieldChange}
                                >
                                    {tagGroupOptions.map((tagg) => (
                                        <option
                                            key={tagg.value}
                                            value={tagg.value}
                                        >
                                            {tagg.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Visibility</td>
                            <td>
                                <select
                                    name="visibility"
                                    value={updatedTag.visibility}
                                    onChange={handleFieldChange}
                                >
                                    {VISIBILITY_OPTIONS.map((option) => (
                                        <option
                                            key={`visibility-${option.value}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="font-bold label">Region</td>
                            <td>
                                <select
                                    name="region_ident"
                                    value={updatedTag.region_ident || ''}
                                    onChange={handleFieldChange}
                                >
                                    {regionsToShow.map((reg) => (
                                        <option
                                            key={reg}
                                            value={reg}
                                        >
                                            {reg}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        {
                            (
                                updatedTag.name !== selectedTag?.name ||
                                updatedTag.tag_group_id !== selectedTag?.tag_group_id ||
                                updatedTag.visibility !== selectedTag?.visibility ||
                                updatedTag.region_ident !== selectedTag?.region_ident
                            ) && 
                            (
                                <tr>
                                    <td colSpan={2}>
                                        <button type="submit" className="mt-4 button save-changes-button">
                                            Save Changes
                                        </button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </form>
        </section>
    );
});

export default CSEditTag;
