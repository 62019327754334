import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import LoadingTable from 'components/LoadingTable';
import TableResults from 'components/TableResults';
import TableImage from 'components/TableImage';
import TableNavigator from 'components/TableNavigator';
import useSetFromOptions from 'hooks/useSetFromOptions';



const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedMatrix: state.PEMatrices.selectedMatrix,
        results: state.PEMatrices.results_list,
        results_total: state.PEMatrices.results_total,
        results_total_pages:state.PEMatrices.results_total_pages,
        results_per_page: state.PEMatrices.results_per_page,
        results_page: state.PEMatrices.results_page,
        results_isLoading: state.PEMatrices.results_isLoading,
        categoriesOptions: state.PECategories.categoriesOptions,
    };
}

const MatrixResultsDetails = connect(mapStateToProps)(function (props) {
    const { 
        selectedMatrix,
        query,
        xAxis,
        yAxis,
        results,
        categoriesOptions,
        results_total,
        results_isLoading,
        results_total_pages,
        results_per_page,
        results_page,
    } = props;
    const { PE_GET_MATRIX_RESULTS_REQUEST } = useActions();
    const [categoriesSet, setCategoriesSet] = useSetFromOptions();
    

    useEffect(() => {
        PE_GET_MATRIX_RESULTS_REQUEST({
            query
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        setCategoriesSet(categoriesOptions);
        // eslint-disable-next-line
    }, [categoriesOptions]);

    function onNext() {
        PE_GET_MATRIX_RESULTS_REQUEST({
            page: results_page + 1,
            per_page: results_per_page,
            query,
        });
    }

    function onPrev() {
        PE_GET_MATRIX_RESULTS_REQUEST({
            page: results_page - 1,
            per_page : results_per_page,
            query,
        });
    }

    function onPageSelected(pageSelected) {
        PE_GET_MATRIX_RESULTS_REQUEST({
            page: pageSelected,
            per_page: results_per_page,
            query
        });
    }
    
    function DataTable() {
        return (
            <section>
                <TableResults results={results_total} />
                <LoadingTable isLoading={results_isLoading}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Tags</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((item) => (
                            <tr key={`item-${item.id}`}>
                                <TableImage
                                    alt={item.name}
                                    imageUrl={item.poster_url}
                                />
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {categoriesSet[item.category_id]}
                                </td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {item.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${item.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    totalPages={results_total_pages}
                    currentPage={results_page}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }
  
    return (
        <section>
            <div className="p-8 pt-0">
                <div className="text-2xl font-bold mb-2">Results For:</div>
                <div className="flex">
                    <div className="text-2xl font-bold mb-2">Matrix Name:</div>
                    <div className="text-2xl ml-4">{selectedMatrix?.name}</div>
                </div>
                <div className="flex">
                    <div className="text-2xl font-bold mb-2">Y-Axis Rule:</div>
                    <div className="text-2xl mb-2 ml-4">{selectedMatrix?.rules_obj?.columns[yAxis][0] && selectedMatrix?.rules_obj?.columns[yAxis][0][0]+" " + selectedMatrix?.rules_obj.columns[yAxis][0][1] + " " + selectedMatrix?.rules_obj.columns[yAxis][0][3]}</div>
                </div>
                <div className="flex">
                    <div className="text-2xl font-bold mb-2">X-Axis Rule:</div>
                    <div className="text-2xl mb-2 ml-3">{selectedMatrix?.rules_obj?.rows[xAxis][0] && selectedMatrix?.rules_obj?.rows[xAxis][0][0]+" " + selectedMatrix?.rules_obj?.columns[yAxis][0][1] 
                    + " " + selectedMatrix?.rules_obj?.rows[xAxis][0][3]}</div>
                </div>
            </div>
            <div className='p-8 pt-0'>
                <DataTable />
            </div>
        </section>
    );
});

export default MatrixResultsDetails;
