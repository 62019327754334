/* eslint-disable react-hooks/exhaustive-deps */
import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import EpisodeEdit from './EpisodeEdit';
import EpisodeFiles from './EpisodeFiles';
import EpisodesAnalytics from './EpisodesAnalytics';
import EpisodesOverview from './EpisodesOverview';
import EpisodesTags from './EpisodesTags';
import EpisodesHistory from './EpisodesHistory';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        episode: state.vhsEpisodes.selectedEpisode,
        files: state.vhsEpisodes.files,
    };
}

const EpisodesDetail = connect(mapStateToProps)(function (props) {
    const { episode } = props;

    const {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST,
        VHS_FETCH_EPISODES_TAG_GROUPS_REQUEST,
        VHS_FETCH_EPISODE_FILES_REQUEST,
    } = useActions();
    const [selectedTab, setSelectedTab] = useState();

    function onSave() {
        setSelectedTab(0);
    }

    useEffect(() => {
        VHS_FETCH_EPISODES_ACTIONS_REQUEST({ episodeId: episode?.id });
        VHS_FETCH_EPISODES_TAG_GROUPS_REQUEST(episode?.brand_ident);
        VHS_FETCH_EPISODE_FILES_REQUEST(episode?.id);
    }, []);

    return (
        <section>
            <Tabs
                sectionNames={[
                    'Overview',
                    'Settings',
                    'Tags',
                    'Analytics',
                    'Files',
                    'History',
                ]}
                sectionContents={[
                    <EpisodesOverview />,
                    <EpisodeEdit episode={episode} onConfirm={onSave} />,
                    <EpisodesTags />,
                    <EpisodesAnalytics />,
                    <EpisodeFiles />,
                    <EpisodesHistory />
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
});

export default EpisodesDetail;
