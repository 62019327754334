const { useState } = require('react');

function useSetFromOptions(options = []) {
    const [object, setObject] = useState(getSetFromOptions(options));

    function getSetFromOptions(options = []) {
        try {
            const obj = {};
            options.forEach((opt) => (obj[opt.value] = opt.label));
            return obj;
        } catch (err) {
            return {};
        }
    }

    function loadSet(options) {
        const obj = getSetFromOptions(options);
        setObject(obj);
    }

    return [object, loadSet];
}

export default useSetFromOptions;
