import { PencilIcon } from '@heroicons/react/outline';
import useActions from 'hooks/useActions';
import { createRef, useState } from 'react';
import EditableSection from '../EditableSection';

const ShareDialogs = function ({
    program,
    isSaving,
    onUploadImage = function () {},
}) {
    const { UPDATE_PROGRAM_REQUEST } = useActions();
    const [file, setFile] = useState(program.social_image_url);
    const inputRef = createRef();

    const handleImageChange = (event) => {
        try {
            const fileUrl = URL.createObjectURL(event.target.files[0]);
            onUploadImage(event.target.files[0]);
            setFile(fileUrl);
        } catch (err) {
            console.log('ERROR LOADING IMAGE', err);
        }
    };

    const openImageDialog = () => {
        inputRef && inputRef.current.click();
    };

    const onConfirmSave = (field, value) => {
        UPDATE_PROGRAM_REQUEST({ id: program.id, [field]: value });
    };

    return (
        <section>
            <div>
                <div className="font-bold text-2xl">Share Dialogs</div>
                <div>
                    Customize the sharing dialogs for this program, or use the
                    defaults
                </div>
            </div>

            <EditableSection
                className="mb-4"
                title="Title"
                value={program.social_title}
                onSave={(fieldValue) =>
                    onConfirmSave('social_title', fieldValue)
                }
                isSaving={isSaving === 'social_title'}
            />
            <EditableSection
                className="mb-4"
                title="Description"
                value={program.social_description}
                isArea
                onSave={(fieldValue) =>
                    onConfirmSave('social_description', fieldValue)
                }
                isSaving={isSaving === 'social_description'}
            />
            <div className="mb-4">
                <div className="flex justify-between mb-4 items-center">
                    <span>Image</span>
                    {isSaving !== 'social_image' ? (
                        <span
                            className="h-8 w-8 flex items-center justify-center bg-black br-4 rounded opacity-75 cursor-pointer"
                            onClick={openImageDialog}
                        >
                            <PencilIcon fill="#FFFFFF" className="h-6 w-6" />
                        </span>
                    ) : (
                        <span className="h-8 pl-4 pr-4 flex items-center justify-center bg-black br-4 rounded ml-2 text-white opacity-75">
                            Saving...
                        </span>
                    )}
                </div>
                <div className="p-2 rounded-none border-solid border-2 border-black w-full resize-none bg-gray-300">
                    <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                    <img src={file} alt="img" />
                </div>
            </div>
            <div className="font-bold text-2xl">Text Messages</div>
            <EditableSection
                className="mb-4"
                title="Body"
                value={program.text_body}
                note="The referral link will be added at the end."
                onSave={(fieldValue) => onConfirmSave('text_body', fieldValue)}
                isSaving={isSaving === 'text_body'}
            />
            <div className="font-bold text-2xl">Twitter</div>
            <EditableSection
                className="mb-4"
                title="Tweet"
                value={program.twitter_body}
                isArea
                note="The referral link will be added at the end."
                onSave={(fieldValue) =>
                    onConfirmSave('twitter_body', fieldValue)
                }
                isSaving={isSaving === 'twitter_body'}
            />
            <div className="font-bold text-2xl">Facebook</div>
            <EditableSection
                className="mb-4"
                title="App ID"
                value={program.facebook_app_id}
                note="The title, description, and image will come from the Social Info above."
                onSave={(fieldValue) =>
                    onConfirmSave('facebook_app_id', fieldValue)
                }
                isSaving={isSaving === 'facebook_app_id'}
            />
            <div className="font-bold text-2xl">Email</div>
            <EditableSection
                className="mb-4"
                title="Subject"
                value={program.email_subject}
                onSave={(fieldValue) =>
                    onConfirmSave('email_subject', fieldValue)
                }
                isSaving={isSaving === 'email_subject'}
            />
            <EditableSection
                className="mb-4"
                title="Body"
                value={program.email_body}
                isArea
                note="The [REFERRAL_URL] will be replaced by the referral link. If that is not in the body, we'll add it at the end."
                onSave={(fieldValue) => onConfirmSave('email_body', fieldValue)}
                isSaving={isSaving === 'email_body'}
            />
        </section>
    );
};

export default ShareDialogs;
