import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import csModeratorWorker from '../workers/csModeratorWorker';

function* CS_MODERATOR_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_MODERATOR_REQUEST,
            ACTION_TYPES.CS_MODERATOR_VISIBILITY_REQUEST,
            ACTION_TYPES.CS_MODERATOR_FLAGS_REASON_REQUEST,
            ACTION_TYPES.CS_MODERATOR_FLAGS_REASON_CREATE,
            ACTION_TYPES.CS_MODERATOR_FLAGS_UPDATE,
        ],
        csModeratorWorker,
        apiUrl,
        ...params
    );
}

export default CS_MODERATOR_REQUEST;
