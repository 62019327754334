import InformationTab from 'components/InformationTab';
import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PESeriesDetails from './SeriesDetails';
import PESeriesNew from './SeriesDetails/SeriesNew';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        series: state.PESeries.series,
        page: state.PESeries.page,
        per_page: state.PESeries.per_page,
        total: state.PESeries.total,
        total_pages: state.PESeries.total_pages,
        isLoading: state.PESeries.isLoadingSeries,
        selectedSeries: state.PESeries.selectedSeries,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PESeries = connect(mapStateToProps)(function (props) {
    const {
        series,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedSeries,
        selectedRegionFilter,
    } = props;

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const {
        PE_FETCH_SERIES_REQUEST,
        PE_GET_SERIES_REQUEST,
        PE_SELECT_SERIES,
        PE_CLONE_SERIES_REQUEST,
        PE_DELETE_SERIES_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();
    const { id } = useParams();
    const { term: searchTerm } = useQueryList(['term']);

    const navigate = useNavigate();

    const onAdd = () => {
        setSubtitle('Add New Serie');
        setDetailComponent(<PESeriesNew />);
    };

    function onNext() {
        PE_FETCH_SERIES_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
        });
    }

    function onPrev() {
        PE_FETCH_SERIES_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_SERIES_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_SERIES_REQUEST({
            per_page: perPage,
            searchTerm,
        });
    }

    function onDeleteAction() {
        PE_DELETE_SERIES_REQUEST({
            seriesId: selectedSeries?.id,
            page,
            searchTerm,
            perPage: per_page,
        });
        navigate('/program-engine/series');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Serie?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function onDetailSelection(ser) {
        setSubtitle(null);
        setDetailComponent(null);
        PE_SELECT_SERIES(ser);
        setShowDelete(true);
        if (ser) {
            navigate(`/program-engine/series/${ser.id}`);
        } else {
            PE_FETCH_SERIES_REQUEST({
                page,
                per_page,
                searchTerm,
            });
            navigate(`/program-engine/series`);
        }
    }

    useEffect(() => {
        if (selectedSeries) {
            setSubtitle(selectedSeries.name);
            setDetailComponent(<PESeriesDetails />);
            setShowDelete(true);
        } else {
            setShowDelete(false);
            setDetailComponent(null);
        }
    }, [selectedSeries]);

    useEffect(() => {
        if (id) {
            try {
                PE_GET_SERIES_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            PE_SELECT_SERIES(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        PE_FETCH_SERIES_REQUEST({ searchTerm });
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Series" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Clone</td>
                            <td>View</td>
                        </tr>
                    </thead>
                    <tbody>
                        {series.map((ser) => (
                            <tr key={`series-${ser.id}`}>
                                <td>
                                    <SelectableField
                                        text={ser.name}
                                        url={`/program-engine/series/${ser.id}`}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="bg-green-add p-2 ml-4 text-white"
                                        onClick={() =>
                                            PE_CLONE_SERIES_REQUEST(ser.id)
                                        }
                                    >
                                        Clone Series
                                    </button>
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/series/${ser.id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onPageSelected={onPageSelected}
                    onNext={onNext}
                    onPrev={onPrev}
                />
            </section>
        );
    }

    return (
        <InformationTab
            sectionName="Series"
            subsectionName={subtitle}
            dataTable={<DataTable />}
            onPerPageSelected={onPerPageSelected}
            showPrograms={false}
            perPage={per_page}
            showDelete={showDelete}
            detailComponent={detailComponent}
            onDetailSelected={onDetailSelection}
            onDeleteDetail={onDeleteDetail}
            deleteText="Serie"
            showSearchField={true}
        />
    );
});

export default PESeries;
