import useActions from 'hooks/useActions';
import useInputState from 'hooks/useInputState';

function ConversionsNew() {
    const { POST_CONVERSION_REQUEST } = useActions();

    const [conversion, onFieldChange] = useInputState({
        brand_ident: 'su',
        order_id: 0,
        share_code: '',
        brand_user_id: '',
        email: '',
        created_int: new Date(),
    });

    function onSubmit(event) {
        event.preventDefault();
        try {
            const newConversion = {
                ...conversion,
                created_int: new Date(conversion.created_int).getTime(),
            };
            POST_CONVERSION_REQUEST(newConversion);
        } catch (err) {}
    }

    return (
        <section>
            <form onSubmit={onSubmit}>
                <table className='edit-table'>
                    <tbody>
                        <tr>
                            <td className='text-bold label'>Order Id in Wordpress</td>
                            <td>
                                <input
                                    name="order_id"
                                    onChange={onFieldChange}
                                    value={conversion.order_id}
                                    type="number"
                                    min={0}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Share Code of Referring Member</td>
                            <td>
                                <input
                                    name="share_code"
                                    onChange={onFieldChange}
                                    value={conversion.share_code}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>New Member Wordpress ID</td>
                            <td>
                                <input
                                    name="brand_user_id"
                                    onChange={onFieldChange}
                                    value={conversion.brand_user_id}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>New Member Email</td>
                            <td>
                                <input
                                    name="email"
                                    onChange={onFieldChange}
                                    value={conversion.email}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='text-bold label'>Referral Time</td>
                            <td>
                                <input
                                    type={'datetime-local'}
                                    name="created_int"
                                    onChange={onFieldChange}
                                    value={conversion.created_int}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" className="mt-4 button save-changes-button">
                                    Add Conversion
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>
    );
}

export default ConversionsNew;
