import {
    VictoryAxis,
    VictoryChart,
    VictoryLine,
    VictoryTooltip,
    VictoryVoronoiContainer,
} from 'victory';

const LineChart = function ({ dataArrays = [], colors = ['black'] }) {
    return (
        <VictoryChart
            style={{
                parent: {
                    backgroundColor: 'rgb(238, 239, 238)',
                },
            }}
            containerComponent={
                <VictoryVoronoiContainer
                    voronoiDimension="x"
                    labels={({ datum }) => `${datum.name} - ${datum.type}: ${datum.value}`}
                    labelComponent={
                        <VictoryTooltip
                            cornerRadius={0}
                            flyoutStyle={{ fill: 'white' }}
                        />
                    }
                />
            }
        >
            <VictoryAxis dependentAxis />
            <VictoryAxis tickValues={dataArrays[0]?.map(val => {return val.name?.split('-').slice(1).join('-')})} tickFormat={((tick, index) => index % 5 === 0 ? tick : '')}/>
            {dataArrays.length > 0 &&
                dataArrays.map((data, index) => {return (
                    <VictoryLine
                        data={data}
                        key={`line-chart-${index}`}
                        interpolation="natural"
                        style={{
                            data: {
                                stroke:
                                    index <= colors.length
                                        ? colors[index]
                                        : colors[0],
                                strokeWidth: ({ active }) => (active ? 4 : 2),
                            },
                        }}
                        y="value"
                    />
                )})}
        </VictoryChart>
    );
};

export default LineChart;
