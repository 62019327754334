import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ProgramDetails from './ProgramsDetail';
import ProgramNew from './ProgramsDetail/ProgramNew';

function mapStateToProps(state) {
    return {
        programs: state.vhsPrograms.programs,
        page: state.vhsPrograms.page,
        total: state.vhsPrograms.total,
        per_page: state.vhsPrograms.per_page,
        total_pages: state.vhsPrograms.total_pages,
        selectedProgram: state.vhsPrograms.selectedProgram,
    };
}

const VHSPrograms = connect(mapStateToProps)(function (props) {
    const {
        programs,
        per_page,
        page,
        total_pages,
        selectedProgram,
        total,
    } = props;
    const navigate = useNavigate();

    const {
        VHS_FETCH_PROGRAMS_REQUEST,
        VHS_SELECT_PROGRAM,
        VHS_FETCH_PROGRAM_REQUEST,
    } = useActions();

    const { id } = useParams();

    const [detailComponent, setDetailComponent] = useState(null);
    const [subtitle, setSubtitle] = useState('');

    const onDetailSelection = (program) => {
        setSubtitle(null);
        setDetailComponent(null);
        VHS_SELECT_PROGRAM(program);
        if (program) {
            navigate(`/vhs/programs/${program.id}`);
        } else {
            VHS_FETCH_PROGRAMS_REQUEST({ per_page, page });
            navigate(`/vhs/programs`);
        }
    };

    const onNextPage = () => {
        VHS_FETCH_PROGRAMS_REQUEST({ per_page, page: page + 1 });
    };

    const onPrevPage = () => {
        VHS_FETCH_PROGRAMS_REQUEST({ per_page, page: page - 1 });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_PROGRAMS_REQUEST({ per_page, page: pageSelected });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_PROGRAMS_REQUEST({ per_page: perPage });
    };

    function onAdd() {
        setSubtitle('Add New Program');
        setDetailComponent(<ProgramNew />);
    }

    useEffect(() => {
        if (selectedProgram) {
            setSubtitle(selectedProgram?.name);
            setDetailComponent(<ProgramDetails />);
        } else {
            setDetailComponent(null);
            setSubtitle(null);
            VHS_FETCH_PROGRAMS_REQUEST({ per_page, page });
        }
        // eslint-disable-next-line
    }, [selectedProgram]);

    useEffect(() => {
        if (id) {
            try {
                VHS_FETCH_PROGRAM_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            VHS_SELECT_PROGRAM(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    const dataTable = function () {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Program" onAdd={onAdd}/>
                <LoadingTable
                    tableClass="table-fixed w-full text-left"
                >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Entries</th>
                        </tr>
                    </thead>
                    <tbody>
                        {programs?.map((program) => (
                            <tr key={`program-${program.id}`}>
                                <td>
                                    <SelectableField
                                        text={program.name}
                                        url={`/vhs/programs/${program.id}`}
                                    />
                                </td>
                                <td>{program.description}</td>
                                <td>{program.entries.length}</td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                />
            </section>
        );
    };

    return (
        <section>
            <InformationTab
                sectionName="Programs"
                showPrograms={false}
                dataTable={dataTable()}
                onDetailSelected={onDetailSelection}
                detailComponent={detailComponent}
                subsectionName={subtitle}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
            />
        </section>
    );
});

export default VHSPrograms;
