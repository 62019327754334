const ACTION_TYPES = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    GET_USER_REQUEST: 'GET_USER_REQUEST',
    SET_USER_SELECTED_REGION: 'SET_USER_SELECTED_REGION',
    GET_USER_RESPONSE: 'GET_USER_RESPONSE',
    POST_EMAIL_REQUEST: 'POST_EMAIL_REQUEST',
    POST_EMAIL_RESPONSE: 'POST_EMAIL_RESPONSE',
    POST_CODE_REQUEST: 'POST_CODE_REQUEST',
    POST_CODE_RESPONSE: 'POST_CODE_RESPONSE',
    REMOVE_REDIRECT: 'REMOVE_REDIRECT',
    GO_LOGIN: 'GO_LOGIN',
    TOGGLE_DRAWER: 'TOGGLE_DRAWER',
    CLOSE_DRAWER: 'CLOSE_DRAWER',
    OPEN_DRAWER: 'OPEN_DRAWER',
    ROUTE_CHANGE: 'ROUTE_CHANGE',
    TOGGLE_MODAL: 'TOGGLE_MODAL',
    SET_MODAL_CONTENT: 'SET_MODAL_CONTENT',
    SET_MODAL_STYLE: 'SET_MODAL_STYLE',

    /**
     * Admins
     */
    FETCH_USER_INFO: 'FETCH_USER_INFO',
    FETCH_ADMINS_REQUEST: 'FETCH_ADMINS_REQUEST',
    FETCH_ADMINS_RESPONSE: 'FETCH_ADMINS_RESPONSE',
    GET_ADMIN_REQUEST: 'GET_ADMIN_REQUEST',
    GET_ADMIN_RESPONSE: 'GET_ADMIN_RESPONSE',
    POST_ADMIN_REQUEST: 'POST_ADMIN_REQUEST',
    POST_ADMIN_RESPONSE: 'POST_ADMIN_RESPONSE',
    UPDATE_ADMIN_REQUEST: 'UPDATE_ADMIN_REQUEST',
    UPDATE_ADMIN_RESPONSE: 'UPDATE_ADMIN_RESPONSE',
    DELETE_ADMIN_REQUEST: 'DELETE_ADMIN_REQUEST',
    DELETE_ADMIN_RESPONSE: 'DELETE_ADMIN_RESPONSE',
    FETCH_ADMIN_CONFIG_REQUEST: 'FETCH_ADMIN_CONFIG_REQUEST',
    FETCH_ADMIN_CONFIG_REPONSE: 'FETCH_ADMIN_CONFIG_REPONSE',
    SELECT_ADMINS_BRANDS_FILTER: 'SELECT_ADMINS_BRANDS_FILTER',
    SELECT_ADMINS_REGIONS_FILTER: 'SELECT_ADMINS_REGIONS_FILTER',
    SELECT_ADMINS_SERVICES_FILTER: 'SELECT_ADMINS_SERVICES_FILTER',
    SELECT_ADMIN: 'SELECT_ADMIN',
    /**
     * Referrals
     */

    // Members
    FETCH_MEMBER_REQUEST: 'FETCH_MEMBER_REQUEST',
    FETCH_MEMBER_RESPONSE: 'FETCH_MEMBER_RESPONSE',
    FETCH_MEMBER_CONVERSIONS_REQUEST: 'FETCH_MEMBER_CONVERSIONS_REQUEST',
    FETCH_MEMBER_CONVERSIONS_RESPONSE: 'FETCH_MEMBER_CONVERSIONS_RESPONSE',
    FETCH_MEMBERS_REQUEST: 'FETCH_MEMBERS_REQUEST',
    FETCH_MEMBERS_RESPONSE: 'FETCH_MEMBERS_RESPONSE',
    UPDATE_MEMBERS_REQUEST: 'UPDATE_MEMBERS_REQUEST',
    UPDATE_MEMBERS_RESPONSE: 'UPDATE_MEMBERS_RESPONSE',
    FETCH_MEMBER_ACTIVITY_REQUEST: 'FETCH_MEMBER_ACTIVITY_REQUEST',
    FETCH_MEMBER_ACTIVITY_RESPONSE: 'FETCH_MEMBER_ACTIVITY_RESPONSE',
    UPDATE_MEMBER_CONVERSION_REQUEST: 'UPDATE_MEMBER_CONVERSION_REQUEST',
    UPDATE_MEMBER_CONVERSION_RESPONSE: 'UPDATE_MEMBER_CONVERSION_RESPONSE',
    FETCH_MEMBER_HISTORY_REQUEST: 'FETCH_MEMBER_HISTORY_REQUEST',
    FETCH_MEMBER_HISTORY_RESPONSE: 'FETCH_MEMBER_HISTORY_RESPONSE',
    SELECT_MEMBER: 'SELECT_MEMBER',
    SELECT_MEMBERS_PROGRAM: 'SELECT_MEMBERS_PROGRAM',

    // Programs
    PROGRAM_LOADING: 'PROGRAM_LOADING',
    FETCH_ALL_PROGRAMS_REQUEST: 'FETCH_ALL_PROGRAMS_REQUEST',
    FETCH_ALL_PROGRAMS_RESPONSE: 'FETCH_ALL_PROGRAMS_RESPONSE',
    FETCH_PROGRAMS_REQUEST: 'FETCH_PROGRAMS_REQUEST',
    FETCH_PROGRAMS_RESPONSE: 'FETCH_PROGRAMS_RESPONSE',
    FETCH_PROGRAM_REQUEST: 'FETCH_PROGRAM_REQUEST',
    FETCH_PROGRAM_RESPONSE: 'FETCH_PROGRAM_RESPONSE',
    POST_PROGRAM_REQUEST: 'POST_PROGRAM_REQUEST',
    POST_PROGRAM_RESPONSE: 'POST_PROGRAM_RESPONSE',
    FETCH_PROGRAM_CONVERSIONS_REQUEST: 'FETCH_PROGRAM_CONVERSIONS_REQUEST',
    FETCH_PROGRAM_CONVERSIONS_RESPONSE: 'FETCH_PROGRAM_CONVERSIONS_RESPONSE',
    FETCH_PROGRAM_EVENTS_REQUEST: 'FETCH_PROGRAM_EVENTS_REQUEST',
    FETCH_PROGRAM_EVENTS_RESPONSE: 'FETCH_PROGRAM_EVENTS_RESPONSE',
    FETCH_PROGRAM_ACTIVITY_REQUEST: 'FETCH_PROGRAM_ACTIVITY_REQUEST',
    FETCH_PROGRAM_ACTIVITY_RESPONSE: 'FETCH_PROGRAM_ACTIVITY_RESPONSE',
    UPDATE_PROGRAM_CONVERSION_REQUEST: 'UPDATE_PROGRAM_CONVERSION_REQUEST',
    UPDATE_PROGRAM_CONVERSION_RESPONSE: 'UPDATE_PROGRAM_CONVERSION_RESPONSE',
    SELECT_PROGRAM: 'SELECT_PROGRAM',
    SET_PROGRAMS_PER_PAGE: 'SET_PROGRAMS_PER_PAGE',
    UPLOAD_PROGRAM_SOCIAL_IMAGE_REQUEST: 'UPLOAD_PROGRAM_SOCIAL_IMAGE_REQUEST',
    UPLOAD_PROGRAM_SOCIAL_IMAGE_RESPONSE:
        'UPLOAD_PROGRAM_SOCIAL_IMAGE_RESPONSE',
    UPDATE_PROGRAM_REQUEST: 'UPDATE_PROGRAM_REQUEST',
    UPDATE_PROGRAM_RESPONSE: 'UPDATE_PROGRAM_RESPONSE',

    // Brands
    FETCH_ALL_BRANDS_REQUEST: 'FETCH_ALL_BRANDS_REQUEST',
    FETCH_ALL_BRANDS_RESPONSE: 'FETCH_ALL_BRANDS_RESPONSE',
    FETCH_BRANDS_REQUEST: 'FETCH_BRANDS_REQUEST',
    FETCH_BRANDS_RESPONSE: 'FETCH_BRANDS_RESPONSE',
    FETCH_BRAND_REQUEST: 'FETCH_BRAND_REQUEST',
    FETCH_BRAND_RESPONSE: 'FETCH_BRAND_RESPONSE',
    POST_BRAND_REQUEST: 'POST_BRAND_REQUEST',
    POST_BRAND_RESPONSE: 'POST_BRAND_RESPONSE',
    UPDATE_BRAND_REQUEST: 'UPDATE_BRAND_REQUEST',
    UPDATE_BRAND_RESPONSE: 'UPDATE_BRAND_RESPONSE',
    SET_BRANDS_PER_PAGE: 'SET_BRANDS_PER_PAGE',
    SELECT_BRAND: 'SELECT_BRAND',

    // Conversions
    FETCH_CONVERSIONS_REQUEST: 'FETCH_CONVERSIONS_REQUEST',
    FETCH_CONVERSIONS_RESPONSE: 'FETCH_CONVERSIONS_RESPONSE',
    FETCH_CONVERSION_REQUEST: 'FETCH_CONVERSION_REQUEST',
    FETCH_CONVERSION_RESPONSE: 'FETCH_CONVERSION_RESPONSE',
    FETCH_CONVERSION_ACTIVITY_REQUEST: 'FETCH_CONVERSION_ACTIVITY_REQUEST',
    FETCH_CONVERSION_ACTIVITY_RESPONSE: 'FETCH_CONVERSION_ACTIVITY_RESPONSE',
    UPDATE_CONVERSIONS_REQUEST: 'UPDATE_CONVERSIONS_REQUEST',
    UPDATE_CONVERSIONS_RESPONSE: 'UPDATE_CONVERSIONS_RESPONSE',
    FETCH_CONVERSIONS_CHART_REQUEST: 'FETCH_CONVERSIONS_CHART_REQUEST',
    FETCH_CONVERSIONS_CHART_RESPONSE: 'FETCH_CONVERSIONS_CHART_RESPONSE',
    POST_CONVERSION_REQUEST: 'POST_CONVERSION_REQUEST',
    POST_CONVERSION_RESPONSE: 'POST_CONVERSION_RESPONSE',
    SELECT_CONVERSIONS_PROGRAM: 'SELECT_CONVERSIONS_PROGRAM',
    SELECT_CONVERSION: 'SELECT_CONVERSION',
    SELECT_CONVERSIONS_PERIOD: 'SELECT_CONVERSIONS_PERIOD',

    // Views
    FETCH_VIEWS_REQUEST: 'FETCH_VIEWS_REQUEST',
    FETCH_VIEWS_RESPONSE: 'FETCH_VIEWS_RESPONSE',
    FETCH_VIEW_ACTIVITY_REQUEST: 'FETCH_VIEW_ACTIVITY_REQUEST',
    FETCH_VIEW_ACTIVITY_RESPONSE: 'FETCH_VIEW_ACTIVITY_RESPONSE',
    FETCH_VIEW_REQUEST: 'FETCH_VIEW_REQUEST',
    FETCH_VIEW_RESPONSE: 'FETCH_VIEW_RESPONSE',
    SELECT_VIEWS_PROGRAM: 'SELECT_VIEWS_PROGRAM',
    SELECT_VIEW: 'SELECT_VIEW',
    FETCH_VIEWS_CHART_REQUEST: 'FETCH_VIEWS_CHART_REQUEST',
    FETCH_VIEWS_CHART_RESPONSE: 'FETCH_VIEWS_CHART_RESPONSE',
    SELECT_VIEWS_PERIOD: 'SELECT_VIEWS_PERIOD',

    /**
     * VHS
     */

    // Admin
    VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_REQUEST: 'VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_REQUEST',
    VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_RESPONSE: 'VHS_CREATE_ZOOM_ASSETS_FOR_MEETINGS_RESPONSE',
    VHS_CREATE_EPISODE_FROM_CLASS_REQUEST: 'VHS_CREATE_EPISODE_FROM_CLASS_REQUEST',
    VHS_CREATE_EPISODE_FROM_CLASS_RESPONSE: 'VHS_CREATE_EPISODE_FROM_CLASS_RESPONSE',
    VHS_CREATE_ASSET_FROM_ZOOM_REQUEST: 'VHS_CREATE_ASSET_FROM_ZOOM_REQUEST',
    VHS_CREATE_ASSET_FROM_ZOOM_RESPONSE: 'VHS_CREATE_ASSET_FROM_ZOOM_RESPONSE',


    // Dashboard
    VHS_FETCH_DASHBOARD_CHART_DATA_REQUEST:
        'VHS_FETCH_DASHBOARD_CHART_DATA_REQUEST',
    VHS_FETCH_DASHBOARD_CHART_DATA_RESPONSE:
        'VHS_FETCH_DASHBOARD_CHART_DATA_RESPONSE',
    VHS_FETCH_RECENT_EPISODES_REQUEST: 'VHS_FETCH_RECENT_EPISODES_REQUEST',
    VHS_FETCH_RECENT_EPISODES_RESPONSE: 'VHS_FETCH_RECENT_EPISODES_RESPONSE',
    VHS_SELECT_ACTIVITY_FILTER: 'VHS_SELECT_ACTIVITY_FILTER',

    // Analytics
    VHS_FETCH_ACTIVITIES_REQUEST: 'VHS_FETCH_ACTIVITIES_REQUEST',
    VHS_FETCH_ACTIVITIES_RESPONSE: 'VHS_FETCH_ACTIVITIES_RESPONSE',
    VHS_EXPORT_TRACKING_DATA_REQUEST: 'VHS_EXPORT_TRACKING_DATA_REQUEST',
    VHS_EXPORT_TRACKING_DATA_RESPONSE: 'VHS_EXPORT_TRACKING_DATA_RESPONSE',
    VHS_EXPORT_TRACKING_DATA_RESET: 'VHS_EXPORT_TRACKING_DATA_RESET',
    VHS_FETCH_ACTIVITY_REQUEST: 'VHS_FETCH_ACTIVITY_REQUEST',
    VHS_FETCH_ACTIVITY_RESPONSE: 'VHS_FETCH_ACTIVITY_RESPONSE',
    VHS_SELECT_ACTIVITY: 'VHS_SELECT_ACTIVITY',
    VHS_SELECT_ACTIVITY_ACTIONS_FILTER: 'VHS_SELECT_ACTIVITY_ACTIONS_FILTER',
    VHS_FETCH_ACTIVITY_CHART_REQUEST: 'VHS_FETCH_ACTIVITY_CHART_REQUEST',
    VHS_FETCH_ACTIVITY_CHART_RESPONSE: 'VHS_FETCH_ACTIVITY_CHART_RESPONSE',

    // Episodes
    VHS_FETCH_EPISODES_REQUEST: 'VHS_FETCH_EPISODES_REQUEST',
    VHS_FETCH_EPISODES_RESPONSE: 'VHS_FETCH_EPISODES_RESPONSE',
    VHS_FETCH_EPISODE_REQUEST: 'VHS_FETCH_EPISODE_REQUEST',
    VHS_FETCH_EPISODE_RESPONSE: 'VHS_FETCH_EPISODE_RESPONSE',
    VHS_POST_EPISODE_REQUEST: 'VHS_POST_EPISODE_REQUEST',
    VHS_POST_EPISODE_RESPONSE: 'VHS_POST_EPISODE_RESPONSE',
    VHS_UPDATE_EPISODE_REQUEST: 'VHS_UPDATE_EPISODE_REQUEST',
    VHS_UPDATE_EPISODE_RESPONSE: 'VHS_UPDATE_EPISODE_RESPONSE',
    VHS_GET_EPISODE_ANALYTICS_REQUEST: 'VHS_GET_EPISODE_ANALYTICS_REQUEST',
    VHS_GET_EPISODE_ANALYTICS_RESPONE: 'VHS_GET_EPISODE_ANALYTICS_RESPONE',
    VHS_DELETE_EPISODE_REQUEST: 'VHS_DELETE_EPISODE_REQUEST',
    VHS_DELETE_EPISODE_RESPONSE: 'VHS_DELETE_EPISODE_RESPONSE',
    VHS_FETCH_EPISODES_ACTIONS_REQUEST: 'VHS_FETCH_EPISODES_ACTIONS_REQUEST',
    VHS_FETCH_EPISODES_ACTIONS_RESPONSE: 'VHS_FETCH_EPISODES_ACTIONS_RESPONSE',
    VHS_FETCH_EPISODE_FILES_REQUEST: 'VHS_FETCH_EPISODE_FILES_REQUEST',
    VHS_FETCH_EPISODE_FILES_RESPONSE: 'VHS_FETCH_EPISODE_FILES_RESPONSE',
    VHS_FETCH_EPISODE_HISTORY_REQUEST: 'VHS_FETCH_EPISODE_HISTORY_REQUEST',
    VHS_FETCH_EPISODE_HISTORY_RESPONSE: 'VHS_FETCH_EPISODE_HISTORY_RESPONSE',
    VHS_POST_EPISODE_FILE_REQUEST: 'VHS_POST_EPISODE_FILE_REQUEST',
    VHS_POST_EPISODE_FILE_RESPONSE: 'VHS_POST_EPISODE_FILE_RESPONSE',
    VHS_POST_EPISODE_RECORDING_REQUEST: 'VHS_POST_EPISODE_RECORDING_REQUEST',
    VHS_POST_EPISODE_RECORDING_RESPONSE: 'VHS_POST_EPISODE_RECORDING_RESPONSE',
    VHS_DELETE_EPISODE_FILE_REQUEST: 'VHS_DELETE_EPISODE_FILE_REQUEST',
    VHS_DELETE_EPISODE_FILE_RESPONSE: 'VHS_DELETE_EPISODE_FILE_RESPONSE',
    VHS_ASSIGN_ASSET_REQUEST: 'VHS_ASSIGN_ASSET_REQUEST',
    // VHS_UNASSIGN_ASSET_REQUEST: 'VHS_UNASSIGN_ASSET_REQUEST',
    VHS_FETCH_EPISODES_TAG_GROUPS_REQUEST:
        'VHS_FETCH_EPISODES_TAG_GROUPS_REQUEST',
    VHS_FETCH_EPISODES_TAG_GROUPS_RESPONSE:
        'VHS_FETCH_EPISODES_TAG_GROUPS_RESPONSE',
    VHS_ADD_TAG_TO_EPISODE_REQUEST: 'VHS_ADD_TAG_TO_EPISODE_REQUEST',
    VHS_ADD_TAG_TO_EPISODE_RESPONSE: 'VHS_ADD_TAG_TO_EPISODE_RESPONSE',
    VHS_REMOVE_TAG_FROM_EPISODE_REQUEST: 'VHS_REMOVE_TAG_FROM_EPISODE_REQUEST',
    VHS_REMOVE_TAG_FROM_EPISODE_RESPONSE:
        'VHS_REMOVE_TAG_FROM_EPISODE_RESPONSE',
    VHS_FETCH_EPISODE_INSTRUCTORS_REQUEST:
        'VHS_FETCH_EPISODE_INSTRUCTORS_REQUEST',
    VHS_FETCH_EPISODE_INSTRUCTORS_RESPONSE:
        'VHS_FETCH_EPISODE_INSTRUCTORS_RESPONSE',
    VHS_FETCH_EPISODES_ACTIVITY_CHART_REQUEST:
        'VHS_FETCH_EPISODES_ACTIVITY_CHART_REQUEST',
    VHS_FETCH_EPISODES_ACTIVITY_CHART_RESPONSE:
        'VHS_FETCH_EPISODES_ACTIVITY_CHART_RESPONSE',
    VHS_SELECT_EPISODE: 'VHS_SELECT_EPISODE',
    VHS_SELECT_EPISODES_VISIBILITY_FILTER:
        'VHS_SELECT_EPISODES_VISIBILITY_FILTER',
    VHS_SELECT_EPISODES_SERIES_FILTER: 'VHS_SELECT_EPISODES_SERIES_FILTER',
    VHS_SELECT_EPISODES_LOCATION_FILTER: 'VHS_SELECT_EPISODES_LOCATION_FILTER',
    VHS_SELECT_EPISODES_BRANDS_FILTER: 'VHS_SELECT_EPISODES_BRANDS_FILTER',
    VHS_SELECT_EPISODES_ACTIONS_FILTER: 'VHS_SELECT_EPISODES_ACTIONS_FILTER',
    VHS_SELECT_EPISODES_PERIOD_FILTER: 'VHS_SELECT_EPISODES_PERIOD_FILTER',
    VHS_SELECT_EPISODES_ORDER: 'VHS_SELECT_EPISODES_ORDER',

    // Series
    VHS_FETCH_SERIES_OPTIONS_REQUEST: 'VHS_FETCH_SERIES_OPTIONS_REQUEST',
    VHS_FETCH_SERIES_OPTIONS_RESPONSE: 'VHS_FETCH_SERIES_OPTIONS_RESPONSE',
    VHS_FETCH_SERIES_REQUEST: 'VHS_FETCH_SERIES_REQUEST',
    VHS_FETCH_SERIES_RESPONSE: 'VHS_FETCH_SERIES_RESPONSE',
    VHS_GET_SERIES_REQUEST: 'VHS_GET_SERIES_REQUEST',
    VHS_GET_SERIES_RESPONSE: 'VHS_GET_SERIES_RESPONSE',
    VHS_POST_SERIES_REQUEST: 'VHS_POST_SERIES_REQUEST',
    VHS_POST_SERIES_RESPONSE: 'VHS_POST_SERIES_RESPONSE',
    VHS_UPDATE_SERIES_REQUEST: 'VHS_UPDATE_SERIES_REQUEST',
    VHS_UPDATE_SERIES_RESPONSE: 'VHS_UPDATE_SERIES_RESPONSE',
    VHS_FETCH_SERIES_EPISODES_REQUEST: 'VHS_FETCH_SERIES_EPISODES_REQUEST',
    VHS_FETCH_SERIES_EPISODES_RESPONSE: 'VHS_FETCH_SERIES_EPISODES_RESPONSE',
    VHS_FETCH_SERIES_ACTIONS_REQUEST: 'VHS_FETCH_SERIES_ACTIONS_REQUEST',
    VHS_FETCH_SERIES_ACTIONS_RESPONSE: 'VHS_FETCH_SERIES_ACTIONS_RESPONSE',
    VHS_SELECT_SERIES: 'VHS_SELECT_SERIES',
    VHS_DELETE_SERIES_REQUEST: 'VHS_DELETE_SERIES_REQUEST',
    VHS_DELETE_SERIES_RESPONSE: 'VHS_DELETE_SERIES_RESPONSE',
    VHS_FETCH_SERIES_ACTIVITY_CHART_REQUEST:
        'VHS_FETCH_SERIES_ACTIVITY_CHART_REQUEST',
    VHS_FETCH_SERIES_ACTIVITY_CHART_RESPONSE:
        'VHS_FETCH_SERIES_ACTIVITY_CHART_RESPONSE',
    VHS_SELECT_SERIES_VISIBILITY_FILTER: 'VHS_SELECT_SERIES_VISIBILITY_FILTER',
    VHS_SELECT_SERIES_BRANDS_FILTER: 'VHS_SELECT_SERIES_BRANDS_FILTER',
    VHS_SELECT_SERIES_ACTIVITIES_FILTER: 'VHS_SELECT_SERIES_ACTIVITIES_FILTER',
    VHS_SELECT_SERIES_EPISODES_ORDER: 'VHS_SELECT_SERIES_EPISODES_ORDER',
    VHS_SELECT_SERIES_PERIOD_FILTER: 'VHS_SELECT_SERIES_PERIOD_FILTER',
    VHS_FETCH_SERIES_HISTORY_REQUEST: 'VHS_FETCH_SERIES_HISTORY_REQUEST',
    VHS_FETCH_SERIES_HISTORY_RESPONSE: 'VHS_FETCH_SERIES_HISTORY_RESPONSE',

    // Instructors
    VHS_FETCH_INSTRUCTORS_REQUEST: 'VHS_FETCH_INSTRUCTORS_REQUEST',
    VHS_FETCH_INSTRUCTORS_RESPONSE: 'VHS_FETCH_INSTRUCTORS_RESPONSE',
    VHS_FETCH_INSTRUCTOR_REQUEST: 'VHS_FETCH_INSTRUCTOR_REQUEST',
    VHS_FETCH_INSTRUCTOR_RESPONSE: 'VHS_FETCH_INSTRUCTOR_RESPONSE',
    VHS_DELETE_INSTRUCTOR_REQUEST: 'VHS_DELETE_INSTRUCTOR_REQUEST',
    VHS_DELETE_INSTRUCTOR_RESPONSE: 'VHS_DELETE_INSTRUCTOR_RESPONSE',
    VHS_POST_INSTRUCTOR_REQUEST: 'VHS_POST_INSTRUCTOR_REQUEST',
    VHS_POST_INSTRUCTOR_RESPONSE: 'VHS_POST_INSTRUCTOR_RESPONSE',
    VHS_UPDATE_INSTRUCTOR_REQUEST: 'VHS_UPDATE_INSTRUCTOR_REQUEST',
    VHS_UPDATE_INSTRUCTOR_RESPONSE: 'VHS_UPDATE_INSTRUCTOR_RESPONSE',
    VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_REQUEST:
        'VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_REQUEST',
    VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_RESPONSE:
        'VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_RESPONSE',
    VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST:
        'VHS_FETCH_INSTRUCTOR_EPISODES_REQUEST',
    VHS_FETCH_INSTRUCTOR_EPISODES_RESPONSE:
        'VHS_FETCH_INSTRUCTOR_EPISODES_RESPONSE',
    VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST:
        'VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST',
    VHS_FETCH_INSTRUCTOR_ACTIONS_RESPONSE:
        'VHS_FETCH_INSTRUCTOR_ACTIONS_RESPONSE',
    VHS_SELECT_INSTRUCTOR: 'VHS_SELECT_INSTRUCTOR',
    VHS_FETCH_INSTRUCTOR_OPTIONS_REQUEST:
        'VHS_FETCH_INSTRUCTOR_OPTIONS_REQUEST',
    VHS_FETCH_INSTRUCTOR_OPTIONS_RESPONSE:
        'VHS_FETCH_INSTRUCTOR_OPTIONS_RESPONSE',
    VHS_SELECT_INSTRUCTOR_VISIBILITY_FILTER:
        'VHS_SELECT_INSTRUCTOR_VISIBILITY_FILTER',
    VHS_SELECT_INSTRUCTOR_BRANDS_FILTER: 'VHS_SELECT_INSTRUCTOR_BRANDS_FILTER',
    VHS_SELECT_INSTRUCTOR_ACTIONS_FILTER:
        'VHS_SELECT_INSTRUCTOR_ACTIONS_FILTER',
    VHS_SELECT_INSTRUCTOR_EPISODES_ORDER:
        'VHS_SELECT_INSTRUCTOR_EPISODES_ORDER',
    VHS_SELECT_INSTRUCTOR_PERIOD_FILTER: 'VHS_SELECT_INSTRUCTOR_PERIOD_FILTER',
    VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST:
    'VHS_FETCH_INSTRUCTOR_HISTORY_REQUEST',
    VHS_FETCH_INSTRUCTOR_HISTORY_RESPONSE:
    'VHS_FETCH_INSTRUCTOR_HISTORY_RESPONSE',
    VHS_REPLACE_INSTRUCTORS_REQUEST:
    'VHS_REPLACE_INSTRUCTORS_REQUEST',
    VHS_REPLACE_INSTRUCTORS_RESPONSE: 
    'VHS_REPLACE_INSTRUCTORS_RESPONSE',

    // Tags
    VHS_FETCH_ALL_TAGS_REQUEST: 'VHS_FETCH_ALL_TAGS_REQUEST',
    VHS_FETCH_ALL_TAGS_RESPONSE: 'VHS_FETCH_ALL_TAGS_RESPONSE',
    VHS_FETCH_ALL_TAG_GROUPS_REQUEST: 'VHS_FETCH_ALL_TAG_GROUPS_REQUEST',
    VHS_FETCH_ALL_TAG_GROUPS_RESPONSE: 'VHS_FETCH_ALL_TAG_GROUPS_RESPONSE',
    VHS_FETCH_TAG_GROUPS_REQUEST: 'VHS_FETCH_TAG_GROUPS_REQUEST',
    VHS_FETCH_TAG_GROUPS_RESPONSE: 'VHS_FETCH_TAG_GROUPS_RESPONSE',
    VHS_TAG_GROUP_REQUEST: 'VHS_TAG_GROUP_REQUEST',
    VHS_TAG_GROUP_RESPONSE: 'VHS_TAG_GROUP_RESPONSE',
    VHS_TAG_REQUEST: 'VHS_TAG_REQUEST',
    VHS_TAG_RESPONSE: 'VHS_TAG_RESPONSE',
    VHS_POST_TAG_GROUP_REQUEST: 'VHS_POST_TAG_GROUP_REQUEST',
    VHS_POST_TAG_GROUP_RESPONSE: 'VHS_POST_TAG_GROUP_RESPONSE',
    VHS_POST_TAG_REQUEST: 'VHS_POST_TAG_REQUEST',
    VHS_POST_TAG_RESPONSE: 'VHS_POST_TAG_RESPONSE',
    VHS_UPDATE_TAG_GROUP_REQUEST: 'VHS_UPDATE_TAG_GROUP_REQUEST',
    VHS_UPDATE_TAG_GROUP_RESPONSE: 'VHS_UPDATE_TAG_GROUP_RESPONSE',
    VHS_DELETE_TAG_GROUP_REQUEST: 'VHS_DELETE_TAG_GROUP_REQUEST',
    VHS_DELETE_TAG_GROUP_RESPONSE: 'VHS_DELETE_TAG_GROUP_RESPONSE',
    VHS_UPDATE_TAG_REQUEST: 'VHS_UPDATE_TAG_REQUEST',
    VHS_UPDATE_TAG_RESPONSE: 'VHS_UPDATE_TAG_RESPONSE',
    VHS_DELETE_TAG_REQUEST: 'VHS_DELETE_TAG_REQUEST',
    VHS_DELETE_TAG_RESPONSE: 'VHS_DELETE_TAG_RESPONSE',
    VHS_REPLACE_TAGS_REQUEST: 'VHS_REPLACE_TAGS_REQUEST',
    VHS_REPLACE_TAGS_RESPONSE: 'VHS_REPLACE_TAGS_RESPONSE',
    VHS_SELECT_TAG_GROUP: 'VHS_SELECT_TAG_GROUP',
    VHS_SELECT_TAG: 'VHS_SELECT_TAG',

    // Members
    VHS_FETCH_MEMBERS_REQUEST: 'VHS_FETCH_MEMBERS_REQUEST',
    VHS_FETCH_MEMBERS_RESPONSE: 'VHS_FETCH_MEMBERS_RESPONSE',
    VHS_FETCH_MEMBER_PERMISSIONS_REQUEST: 'VHS_FETCH_MEMBER_PERMISSIONS_REQUEST',
    VHS_FETCH_MEMBER_PERMISSIONS_RESPONSE: 'VHS_FETCH_MEMBER_PERMISSIONS_RESPONSE',
    VHS_FETCH_MEMBER_REQUEST: 'VHS_FETCH_MEMBER_REQUEST',
    VHS_FETCH_MEMBER_RESPONSE: 'VHS_FETCH_MEMBER_RESPONSE',
    VHS_FETCH_MEMBER_ACTIVITY_REQUEST: 'VHS_FETCH_MEMBER_ACTIVITY_REQUEST',
    VHS_FETCH_MEMBER_ACTIVITY_RESPONSE: 'VHS_FETCH_MEMBER_ACTIVITY_RESPONSE',
    VHS_FETCH_MEMBER_ATTEND_REQUEST: 'VHS_FETCH_MEMBER_ATTEND_REQUEST',
    VHS_FETCH_MEMBER_ATTEND_RESPONSE: 'VHS_FETCH_MEMBER_ATTEND_RESPONSE',
    VHS_FETCH_MEMBER_FAVORITED_REQUEST: 'VHS_FETCH_MEMBER_FAVORITED_REQUEST',
    VHS_FETCH_MEMBER_FAVORITED_RESPONSE: 'VHS_FETCH_MEMBER_FAVORITED_RESPONSE',
    VHS_FETCH_MEMBER_HISTORY_REQUEST: 'VHS_FETCH_MEMBER_HISTORY_REQUEST',
    VHS_FETCH_MEMBER_HISTORY_RESPONSE: 'VHS_FETCH_MEMBER_HISTORY_RESPONSE',
    VHS_SELECT_MEMBER: 'VHS_SELECT_MEMBER',

    // Brands
    VHS_FETCH_ALL_BRANDS_REQUEST: 'VHS_FETCH_ALL_BRANDS_REQUEST',
    VHS_FETCH_ALL_BRANDS_RESPONSE: 'VHS_FETCH_ALL_BRANDS_RESPONSE',
    VHS_FETCH_BRANDS_REQUEST: 'VHS_FETCH_BRANDS_REQUEST',
    VHS_FETCH_BRANDS_RESPONSE: 'VHS_FETCH_BRANDS_RESPONSE',
    VHS_FETCH_BRAND_REQUEST: 'VHS_FETCH_BRAND_REQUEST',
    VHS_FETCH_BRAND_RESPONSE: 'VHS_FETCH_BRAND_RESPONSE',
    VHS_POST_BRAND_REQUEST: 'VHS_POST_BRAND_REQUEST',
    VHS_POST_BRAND_RESPONSE: 'VHS_POST_BRAND_RESPONSE',
    VHS_UPDATE_BRAND_REQUEST: 'VHS_UPDATE_BRAND_REQUEST',
    VHS_UPDATE_BRAND_RESPONSE: 'VHS_UPDATE_BRAND_RESPONSE',
    VHS_DELETE_BRAND_RESPONSE: 'VHS_DELETE_BRAND_RESPONSE',
    VHS_DELETE_BRAND_REQUEST: 'VHS_DELETE_BRAND_REQUEST',
    VHS_SELECT_BRAND: 'VHS_SELECT_BRAND',

    // Locations
    VHS_FETCH_ALL_LOCATION_REQUEST: 'VHS_FETCH_ALL_LOCATION_REQUEST',
    VHS_FETCH_ALL_LOCATION_RESPONSE: 'VHS_FETCH_ALL_LOCATION_RESPONSE',
    VHS_FETCH_LOCATIONS_REQUEST: 'VHS_FETCH_LOCATIONS_REQUEST',
    VHS_FETCH_LOCATIONS_RESPONSE: 'VHS_FETCH_LOCATIONS_RESPONSE',
    VHS_SELECT_LOCATION: 'VHS_SELECT_LOCATION',
    VHS_FETCH_LOCATION_REQUEST: 'VHS_FETCH_LOCATION_REQUEST',
    VHS_FETCH_LOCATION_RESPONSE: 'VHS_FETCH_LOCATION_RESPONSE',
    VHS_UPDATE_LOCATION_REQUEST: 'VHS_UPDATE_LOCATION_REQUEST',
    VHS_UPDATE_LOCATION_RESPONSE: 'VHS_UPDATE_LOCATION_RESPONSE',
    VHS_FETCH_LOCATION_HISTORY_REQUEST: 'VHS_FETCH_LOCATION_HISTORY_REQUEST',
    VHS_FETCH_LOCATION_HISTORY_RESPONSE: 'VHS_FETCH_LOCATION_HISTORY_RESPONSE',

    // Meetings
    VHS_FETCH_LOCATION_MEETINGS_REQUEST: 'VHS_FETCH_LOCATION_MEETINGS_REQUEST',
    VHS_FETCH_LOCATION_MEETINGS_RESPONSE: 'VHS_FETCH_LOCATION_MEETINGS_RESPONSE',
    VHS_FETCH_LOCATION_MEETINGS_RESPONSE_FAILURE: 'VHS_FETCH_LOCATION_MEETINGS_RESPONSE_FAILURE',
    VHS_FETCH_MEETING_INVITE_REQUEST: 'VHS_FETCH_MEETING_INVITE_REQUEST',
    VHS_FETCH_MEETING_INVITE_RESPONSE: 'VHS_FETCH_MEETING_INVITE_RESPONSE',
    VHS_SELECT_MEETING: 'VHS_SELECT_MEETING',
    VHS_SELECT_MEETINGS_LOCATION_FILTER: 'VHS_SELECT_MEETINGS_LOCATION_FILTER',
    VHS_SELECT_MEETINGS_FILTER: 'VHS_SELECT_MEETINGS_FILTER',

    // Programs
    VHS_FETCH_ALL_PROGRAMS_REQUEST: 'VHS_FETCH_ALL_PROGRAMS_REQUEST',
    VHS_FETCH_ALL_PROGRAMS_RESPONSE: 'VHS_FETCH_ALL_PROGRAMS_RESPONSE',
    VHS_FETCH_PROGRAMS_REQUEST: 'VHS_FETCH_PROGRAMS_REQUEST',
    VHS_FETCH_PROGRAMS_RESPONSE: 'VHS_FETCH_PROGRAMS_RESPONSE',
    VHS_FETCH_PROGRAM_REQUEST: 'VHS_FETCH_PROGRAM_REQUEST',
    VHS_FETCH_PROGRAM_RESPONSE: 'VHS_FETCH_PROGRAM_RESPONSE',
    VHS_SELECT_PROGRAM: 'VHS_SELECT_PROGRAM',
    VHS_POST_PROGRAM_REQUEST: 'VHS_POST_PROGRAM_REQUEST',
    VHS_POST_PROGRAM_RESPONSE: 'VHS_POST_PROGRAM_RESPONSE',
    VHS_POST_PROGRAM_EPISODE_REQUEST: 'VHS_POST_PROGRAM_EPISODE_REQUEST',
    VHS_POST_PROGRAM_EPISODE_RESPONSE: 'VHS_POST_PROGRAM_EPISODE_RESPONSE',
    VHS_UPDATE_PROGRAM_REQUEST: 'VHS_UPDATE_PROGRAM_REQUEST',
    VHS_UPDATE_PROGRAM_RESPONSE: 'VHS_UPDATE_PROGRAM_RESPONSE',
    VHS_DEL_PROGRAM_EPISODE_REQUEST: 'VHS_DEL_PROGRAM_EPISODE_REQUEST',
    VHS_DEL_PROGRAM_EPISODE_RESPONSE: 'VHS_DEL_PROGRAM_EPISODE_RESPONSE',
    VHS_UPDATE_PROGRAM_EPISODE_REQUEST: 'VHS_UPDATE_PROGRAM_EPISODE_REQUEST',
    VHS_UPDATE_PROGRAM_EPISODE_RESPONSE: 'VHS_UPDATE_PROGRAM_EPISODE_RESPONSE',

    /**
     * Program Engine
     */

    // Assignments
    PE_FETCH_ASSIGNMENTS_REQUEST: 'PE_FETCH_ASSIGNMENTS_REQUEST',
    PE_FETCH_ASSIGNMENTS_RESPONSE: 'PE_FETCH_ASSIGNMENTS_RESPONSE',
    PE_GET_ASSIGNMENT_REQUEST: 'PE_GET_ASSIGNMENT_REQUEST',
    PE_GET_ASSIGNMENT_RESPONSE: 'PE_GET_ASSIGNMENT_RESPONSE',
    PE_POST_ASSIGNMENT_REQUEST: 'PE_POST_ASSIGNMENT_REQUEST',
    PE_POST_ASSIGNMENT_RESPONSE: 'PE_POST_ASSIGNMENT_RESPONSE',
    PE_UPDATE_ASSIGNMENT_REQUEST: 'PE_UPDATE_ASSIGNMENT_REQUEST',
    PE_UPDATE_ASSIGNMENT_RESPONSE: 'PE_UPDATE_ASSIGNMENT_RESPONSE',
    PE_DELETE_ASSIGNMENT_REQUEST: 'PE_DELETE_ASSIGNMENT_REQUEST',
    PE_DELETE_ASSIGNMENT_RESPONSE: 'PE_DELETE_ASSIGNMENT_RESPONSE',
    PE_GET_ASSIGNMENT_CONFIG_REQUEST: 'PE_GET_ASSIGNMENT_CONFIG_REQUEST',
    PE_GET_ASSIGNMENT_CONFIG_RESPONSE: 'PE_GET_ASSIGNMENT_CONFIG_RESPONSE',
    PE_FETCH_ASSIGNMENTS_TAG_GROUPS_REQUEST:
        'PE_FETCH_ASSIGNMENTS_TAG_GROUPS_REQUEST',
    PE_FETCH_ASSIGNMENTS_TAG_GROUPS_RESPONSE:
        'PE_FETCH_ASSIGNMENTS_TAG_GROUPS_RESPONSE',
    PE_SELECT_ASSIGNMENT: 'PE_SELECT_ASSIGNMENT',
    PE_ADD_TAG_TO_ASSIGNMENT_REQUEST: 'PE_ADD_TAG_TO_ASSIGNMENT_REQUEST',
    PE_ADD_TAG_TO_ASSIGNMENT_RESPONSE: 'PE_ADD_TAG_TO_ASSIGNMENT_RESPONSE',
    PE_REMOVE_TAG_FROM_ASSIGNMENT_REQUEST:
        'PE_REMOVE_TAG_FROM_ASSIGNMENT_REQUEST',
    PE_REMOVE_TAG_FROM_ASSIGNMENT_RESPONSE:
        'PE_REMOVE_TAG_FROM_ASSIGNMENT_RESPONSE',
    PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST:
        'PE_FETCH_ASSIGNMENT_TRANSLATIONS_REQUEST',
    PE_FETCH_ASSIGNMENT_TRANSLATIONS_RESPONSE:
        'PE_FETCH_ASSIGNMENT_TRANSLATIONS_RESPONSE',
    PE_POST_ASSIGNMENT_TRANSLATION_REQUEST:
        'PE_POST_ASSIGNMENT_TRANSLATION_REQUEST',
    PE_POST_ASSIGNMENT_TRANSLATION_RESPONSE:
        'PE_POST_ASSIGNMENT_TRANSLATION_RESPONSE',
    PE_DELETE_ASSIGNMENT_TRANSLATION_REQUEST:
        'PE_DELETE_ASSIGNMENT_TRANSLATION_REQUEST',
    PE_DELETE_ASSIGNMENT_TRANSLATION_RESPONSE:
        'PE_DELETE_ASSIGNMENT_TRANSLATION_RESPONSE',
    PE_UPDATE_ASSIGNMENT_TRANSLATION_REQUEST:
        'PE_UPDATE_ASSIGNMENT_TRANSLATION_REQUEST',
    PE_UPDATE_ASSIGNMENT_TRANSLATION_RESPONSE:
        'PE_UPDATE_ASSIGNMENT_TRANSLATION_RESPONSE',
    PE_SELECT_ASSIGNMENT_TRANSLATION: 'PE_SELECT_ASSIGNMENT_TRANSLATION',
    PE_POST_ASSIGNMENT_POSTER_REQUEST: 'PE_POST_ASSIGNMENT_POSTER_REQUEST',
    PE_POST_ASSIGNMENT_POSTER_RESPONSE: 'PE_POST_ASSIGNMENT_POSTER_RESPONSE',
    PE_FETCH_ASSIGNMENT_FILES_REQUEST: 'PE_FETCH_ASSIGNMENT_FILES_REQUEST',
    PE_FETCH_ASSIGNMENT_FILES_RESPONSE: 'PE_FETCH_ASSIGNMENT_FILES_RESPONSE',
    PE_POST_ASSIGNMENT_FILE_REQUEST: 'PE_POST_ASSIGNMENT_FILE_REQUEST',
    PE_POST_ASSIGNMENT_FILE_RESPONSE: 'PE_POST_ASSIGNMENT_FILE_RESPONSE',
    PE_DELETE_ASSIGNMENT_FILE_REQUEST: 'PE_DELETE_ASSIGNMENT_FILE_REQUEST',
    PE_ASSIGN_ASSET_REQUEST: 'PE_ASSIGN_ASSET_REQUEST',
    PE_UNASSIGN_ASSET_REQUEST: 'PE_UNASSIGN_ASSET_REQUEST',

    // Brands
    PE_FETCH_BRANDS_OPTIONS_REQUEST: 'PE_FETCH_BRANDS_OPTIONS_REQUEST',
    PE_FETCH_BRANDS_OPTIONS_RESPONSE: 'PE_FETCH_BRANDS_OPTIONS_RESPONSE',
    PE_FETCH_BRANDS_REQUEST: 'PE_FETCH_BRANDS_REQUEST',
    PE_FETCH_BRANDS_RESPONSE: 'PE_FETCH_BRANDS_RESPONSE',
    PE_UPDATE_BRAND_REQUEST: 'PE_UPDATE_BRAND_REQUEST',
    PE_UPDATE_BRAND_RESPONSE: 'PE_UPDATE_BRAND_RESPONSE',
    PE_DELETE_BRAND_REQUEST: 'PE_DELETE_BRAND_REQUEST',
    PE_DELETE_BRAND_RESPONSE: 'PE_DELETE_BRAND_RESPONSE',
    PE_GET_BRAND_REQUEST: 'PE_GET_BRAND_REQUEST',
    PE_GET_BRAND_RESPONSE: 'PE_GET_BRAND_RESPONSE',
    PE_POST_BRAND_REQUEST: 'PE_POST_BRAND_REQUEST',
    PE_POST_BRAND_RESPONSE: 'PE_POST_BRAND_RESPONSE',
    PE_SELECT_BRAND: 'PE_SELECT_BRAND',

    // Categories
    PE_FETCH_CATEGORIES_REQUEST: 'PE_FETCH_CATEGORIES_REQUEST',
    PE_FETCH_CATEGORIES_RESPONSE: 'PE_FETCH_CATEGORIES_RESPONSE',
    PE_FETCH_ALL_CATEGORIES_REQUEST: 'PE_FETCH_ALL_CATEGORIES_REQUEST',
    PE_FETCH_ALL_CATEGORIES_RESPONSE: 'PE_FETCH_ALL_CATEGORIES_RESPONSE',
    PE_GET_CATEGORY_REQUEST: 'PE_GET_CATEGORY_REQUEST',
    PE_GET_CATEGORY_RESPONSE: 'PE_GET_CATEGORY_RESPONSE',
    PE_UPDATE_CATEGORY_REQUEST: 'PE_UPDATE_CATEGORY_REQUEST',
    PE_UPDATE_CATEGORY_RESPONSE: 'PE_UPDATE_CATEGORY_RESPONSE',
    PE_POST_CATEGORY_REQUEST: 'PE_POST_CATEGORY_REQUEST',
    PE_POST_CATEGORY_RESPONSE: 'PE_POST_CATEGORY_RESPONSE',
    PE_DELETE_CATEGORY_REQUEST: 'PE_DELETE_CATEGORY_REQUEST',
    PE_DELETE_CATEGORY_RESPONSE: 'PE_DELETE_CATEGORY_RESPONSE',
    PE_SELECT_CATEGORY: 'PE_SELECT_CATEGORY',
    PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST:
        'PE_FETCH_CATEGORY_ASSIGNMENTS_REQUEST',
    PE_FETCH_CATEGORY_ASSIGNMENTS_RESPONSE:
        'PE_FETCH_CATEGORY_ASSIGNMENTS_RESPONSE',
    PE_POST_CATEGORY_ASSIGNMENT_REQUEST: 'PE_POST_CATEGORY_ASSIGNMENT_REQUEST',
    PE_POST_CATEGORY_ASSIGNMENT_RESPONSE:
        'PE_POST_CATEGORY_ASSIGNMENT_RESPONSE',

    // Matrices
    PE_FETCH_MATRICES_REQUEST: 'PE_FETCH_MATRICES_REQUEST',
    PE_FETCH_MATRICES_RESPONSE: 'PE_FETCH_MATRICES_RESPONSE',
    PE_GET_MATRIX_REQUEST: 'PE_GET_MATRIX_REQUEST',
    PE_GET_MATRIX_RESPONSE: 'PE_GET_MATRIX_RESPONSE',
    PE_POST_MATRIX_REQUEST: 'PE_POST_MATRIX_REQUEST',
    PE_POST_MATRIX_RESPONSE: 'PE_POST_MATRIX_RESPONSE',
    PE_GET_MATRIX_CONFIG_REQUEST: 'PE_GET_MATRIX_CONFIG_REQUEST',
    PE_GET_MATRIX_CONFIG_RESPONSE: 'PE_GET_MATRIX_CONFIG_RESPONSE',
    PE_UPDATE_MATRIX_REQUEST: 'PE_UPDATE_MATRIX_REQUEST',
    PE_UPDATE_MATRIX_RESPONSE: 'PE_UPDATE_MATRIX_RESPONSE',
    PE_DELETE_MATRIX_REQUEST: 'PE_DELETE_MATRIX_REQUEST',
    PE_DELETE_MATRIX_RESPONSE: 'PE_DELETE_MATRIX_RESPONSE',
    PE_CLONE_MATRIX_REQUEST: 'PE_CLONE_MATRIX_REQUEST',
    PE_CLONE_MATRIX_RESPONSE: 'PE_CLONE_MATRIX_RESPONSE',
    PE_SELECT_MATRIX: 'PE_SELECT_MATRIX',
    PE_GET_MATRIX_RESULTS_REQUEST: 'PE_GET_MATRIX_RESULTS_REQUEST',
    PE_GET_MATRIX_RESULTS_RESPONSE: 'PE_GET_MATRIX_RESULTS_RESPONSE',

    // Members
    PE_FETCH_MEMBERS_REQUEST: 'PE_FETCH_MEMBERS_REQUEST',
    PE_FETCH_MEMBERS_RESPONSE: 'PE_FETCH_MEMBERS_RESPONSE',
    PE_GET_MEMBER_REQUEST: 'PE_GET_MEMBER_REQUEST',
    PE_GET_MEMBER_RESPONSE: 'PE_GET_MEMBER_RESPONSE',
    PE_SELECT_MEMBER_BRANDS_FILTER: 'PE_SELECT_MEMBER_BRANDS_FILTER',
    PE_SELECT_MEMBER: 'PE_SELECT_MEMBER',

    // Programs
    PE_FETCH_PROGRAMS_REQUEST: 'PE_FETCH_PROGRAMS_REQUEST',
    PE_FETCH_PROGRAMS_RESPONSE: 'PE_FETCH_PROGRAMS_RESPONSE',
    PE_GET_PROGRAM_REQUEST: 'PE_GET_PROGRAM_REQUEST',
    PE_GET_PROGRAM_RESPONSE: 'PE_GET_PROGRAM_RESPONSE',
    PE_POST_PROGRAM_REQUEST: 'PE_POST_PROGRAM_REQUEST',
    PE_POST_PROGRAM_RESPONSE: 'PE_POST_PROGRAM_RESPONSE',
    PE_UPDATE_PROGRAM_REQUEST: 'PE_UPDATE_PROGRAM_REQUEST',
    PE_UPDATE_PROGRAM_RESPONSE: 'PE_UPDATE_PROGRAM_RESPONSE',
    PE_CLONE_PROGRAM_REQUEST: 'PE_CLONE_PROGRAM_REQUEST',
    PE_CLONE_PROGRAM_RESPONSE: 'PE_CLONE_PROGRAM_RESPONSE',
    PE_DELETE_PROGRAM_REQUEST: 'PE_DELETE_PROGRAM_REQUEST',
    PE_DELETE_PROGRAM_RESPONSE: 'PE_DELETE_PROGRAM_RESPONSE',
    PE_SELECT_PROGRAMS_BRANDS_FILTER: 'PE_SELECT_PROGRAMS_BRANDS_FILTER',
    PE_SELECT_PROGRAM: 'PE_SELECT_PROGRAM',
    PE_FETCH_PROGRAM_PHASES_REQUEST: 'PE_FETCH_PROGRAM_PHASES_REQUEST',
    PE_FETCH_PROGRAM_PHASES_RESPONSE: 'PE_FETCH_PROGRAM_PHASES_RESPONSE',
    PE_POST_PROGRAM_PHASE_REQUEST: 'PE_POST_PROGRAM_PHASE_REQUEST',
    PE_POST_PROGRAM_PHASE_RESPONSE: 'PE_POST_PROGRAM_PHASE_RESPONSE',
    PE_DELETE_PROGRAM_PHASE_REQUEST: 'PE_DELETE_PROGRAM_PHASE_REQUEST',
    PE_DELETE_PROGRAM_PHASE_RESPONSE: 'PE_DELETE_PROGRAM_PHASE_RESPONSE',
    PE_UPDATE_PROGRAM_PHASE_REQUEST: 'PE_UPDATE_PROGRAM_PHASE_REQUEST',
    PE_UPDATE_PROGRAM_PHASE_RESPONSE: 'PE_UPDATE_PROGRAM_PHASE_RESPONSE',
    PE_FETCH_PROGRAM_TRANSLATIONS_REQUEST:
        'PE_FETCH_PROGRAM_TRANSLATIONS_REQUEST',
    PE_FETCH_PROGRAM_TRANSLATIONS_RESPONSE:
        'PE_FETCH_PROGRAM_TRANSLATIONS_RESPONSE',
    PE_POST_PROGRAM_TRANSLATION_REQUEST: 'PE_POST_PROGRAM_TRANSLATION_REQUEST',
    PE_POST_PROGRAM_TRANSLATION_RESPONSE:
        'PE_POST_PROGRAM_TRANSLATION_RESPONSE',
    PE_DELETE_PROGRAM_TRANSLATION_REQUEST:
        'PE_DELETE_PROGRAM_TRANSLATION_REQUEST',
    PE_DELETE_PROGRAM_TRANSLATION_RESPONSE:
        'PE_DELETE_PROGRAM_TRANSLATION_RESPONSE',
    PE_UPDATE_PROGRAM_TRANSLATION_REQUEST:
        'PE_UPDATE_PROGRAM_TRANSLATION_REQUEST',
    PE_UPDATE_PROGRAM_TRANSLATION_RESPONSE:
        'PE_UPDATE_PROGRAM_TRANSLATION_RESPONSE',
    PE_SELECT_PROGRAM_TRANSLATION: 'PE_SELECT_PROGRAM_TRANSLATION',
    PE_FETCH_PROGRAMS_TAG_GROUPS_REQUEST:
        'PE_FETCH_PROGRAMS_TAG_GROUPS_REQUEST',
    PE_FETCH_PROGRAMS_TAG_GROUPS_RESPONSE:
        'PE_FETCH_PROGRAMS_TAG_GROUPS_RESPONSE',
    PE_ADD_TAG_TO_PROGRAM_REQUEST: 'PE_ADD_TAG_TO_PROGRAM_REQUEST',
    PE_ADD_TAG_TO_PROGRAM_RESPONSE: 'PE_ADD_TAG_TO_PROGRAM_RESPONSE',
    PE_REMOVE_TAG_FROM_PROGRAM_REQUEST: 'PE_REMOVE_TAG_FROM_PROGRAM_REQUEST',
    PE_REMOVE_TAG_FROM_PROGRAM_RESPONSE: 'PE_REMOVE_TAG_FROM_PROGRAM_RESPONSE',

    // Phases
    PE_FETCH_PHASES_REQUEST: 'PE_FETCH_PHASES_REQUEST',
    PE_FETCH_PHASES_RESPONSE: 'PE_FETCH_PHASES_RESPONSE',
    PE_GET_PHASE_REQUEST: 'PE_GET_PHASE_REQUEST',
    PE_GET_PHASE_RESPONSE: 'PE_GET_PHASE_RESPONSE',
    PE_POST_PHASE_REQUEST: 'PE_POST_PHASE_REQUEST',
    PE_POST_PHASE_RESPONSE: 'PE_POST_PHASE_RESPONSE',
    PE_UPDATE_PHASE_REQUEST: 'PE_UPDATE_PHASE_REQUEST',
    PE_UPDATE_PHASE_RESPONSE: 'PE_UPDATE_PHASE_RESPONSE',
    PE_DELETE_PHASE_REQUEST: 'PE_DELETE_PHASE_REQUEST',
    PE_DELETE_PHASE_RESPONSE: 'PE_DELETE_PHASE_RESPONSE',
    PE_FETCH_PHASES_SEQUENCES_REQUEST: 'PE_FETCH_PHASES_SEQUENCES_REQUEST',
    PE_FETCH_PHASES_SEQUENCES_RESPONSE: 'PE_FETCH_PHASES_SEQUENCES_RESPONSE',
    PE_ADD_PHASE_SEQUENCE_REQUEST: 'PE_ADD_PHASE_SEQUENCE_REQUEST',
    PE_ADD_PHASE_SEQUENCE_RESPONSE: 'PE_ADD_PHASE_SEQUENCE_RESPONSE',
    PE_REMOVE_PHASE_SEQUENCE_REQUEST: 'PE_REMOVE_PHASE_SEQUENCE_REQUEST',
    PE_REMOVE_PHASE_SEQUENCE_RESPONSE: 'PE_REMOVE_PHASE_SEQUENCE_RESPONSE',
    PE_SELECT_PHASE: 'PE_SELECT_PHASE',

    // Sequences
    PE_FETCH_SEQUENCES_REQUEST: 'PE_FETCH_SEQUENCES_REQUEST',
    PE_FETCH_SEQUENCES_RESPONSE: 'PE_FETCH_SEQUENCES_RESPONSE',
    PE_GET_SEQUENCE_REQUEST: 'PE_GET_SEQUENCE_REQUEST',
    PE_GET_SEQUENCE_RESPONSE: 'PE_GET_SEQUENCE_RESPONSE',
    PE_POST_SEQUENCE_REQUEST: 'PE_POST_SEQUENCE_REQUEST',
    PE_POST_SEQUENCE_RESPONSE: 'PE_POST_SEQUENCE_RESPONSE',
    PE_UPDATE_SEQUENCE_REQUEST: 'PE_UPDATE_SEQUENCE_REQUEST',
    PE_UPDATE_SEQUENCE_RESPONSE: 'PE_UPDATE_SEQUENCE_RESPONSE',
    PE_DELETE_SEQUENCE_REQUEST: 'PE_DELETE_SEQUENCE_REQUEST',
    PE_DELETE_SEQUENCE_RESPONSE: 'PE_DELETE_SEQUENCE_RESPONSE',
    PE_GET_SEQUENCE_TASKS_REQUEST: 'PE_GET_SEQUENCE_TASKS_REQUEST',
    PE_GET_SEQUENCE_TASKS_RESPONSE: 'PE_GET_SEQUENCE_TASKS_RESPONSE',
    PE_SELECT_SEQUENCE: 'PE_SELECT_SEQUENCE',
    PE_ADD_TAG_TO_SEQUENCE_REQUEST: 'PE_ADD_TAG_TO_SEQUENCE_REQUEST',
    PE_ADD_TAG_TO_SEQUENCE_RESPONSE: 'PE_ADD_TAG_TO_SEQUENCE_RESPONSE',
    PE_REMOVE_TAG_FROM_SEQUENCE_REQUEST: 'PE_REMOVE_TAG_FROM_SEQUENCE_REQUEST',
    PE_REMOVE_TAG_FROM_SEQUENCE_RESPONSE:
        'PE_REMOVE_TAG_FROM_SEQUENCE_RESPONSE',
    PE_FETCH_SEQUENCE_TAG_GROUPS_REQUEST:
        'PE_FETCH_SEQUENCE_TAG_GROUPS_REQUEST',
    PE_FETCH_SEQUENCE_TAG_GROUPS_RESPONSE:
        'PE_FETCH_SEQUENCE_TAG_GROUPS_RESPONSE',
    PE_POST_SEQUENCE_TASK_REQUEST: 'PE_POST_SEQUENCE_TASK_REQUEST',
    PE_POST_SEQUENCE_TASK_RESPONSE: 'PE_POST_SEQUENCE_TASK_RESPONSE',
    PE_DELETE_SEQUENCE_TASK_REQUEST: 'PE_DELETE_SEQUENCE_TASK_REQUEST',
    PE_DELETE_SEQUENCE_TASK_RESPONSE: 'PE_DELETE_SEQUENCE_TASK_RESPONSE',
    PE_POST_SEQUENCE_CHANGE_ORDER_REQUEST: 'PE_POST_SEQUENCE_CHANGE_ORDER_REQUEST',
    PE_POST_SEQUENCE_CHANGE_ORDER_RESPONSE: 'PE_POST_SEQUENCE_CHANGE_ORDER_RESPONSE',

    // Series
    PE_FETCH_SERIES_REQUEST: 'PE_FETCH_SERIES_REQUEST',
    PE_FETCH_SERIES_RESPONSE: 'PE_FETCH_SERIES_RESPONSE',
    PE_GET_SERIES_REQUEST: 'PE_GET_SERIES_REQUEST',
    PE_GET_SERIES_RESPONSE: 'PE_GET_SERIES_RESPONSE',
    PE_POST_SERIES_REQUEST: 'PE_POST_SERIES_REQUEST',
    PE_POST_SERIES_RESPONSE: 'PE_POST_SERIES_RESPONSE',
    PE_UPDATE_SERIES_REQUEST: 'PE_UPDATE_SERIES_REQUEST',
    PE_UPDATE_SERIES_RESPONSE: 'PE_UPDATE_SERIES_RESPONSE',
    PE_DELETE_SERIES_REQUEST: 'PE_DELETE_SERIES_REQUEST',
    PE_DELETE_SERIES_RESPONSE: 'PE_DELETE_SERIES_RESPONSE',
    PE_CLONE_SERIES_REQUEST: 'PE_CLONE_SERIES_REQUEST',
    PE_CLONE_SERIES_RESPONSE: 'PE_CLONE_SERIES_RESPONSE',
    PE_SERIES_ASSIGNMENTS_REQUEST: 'PE_SERIES_ASSIGNMENTS_REQUEST',
    PE_SERIES_ASSIGNMENTS_RESPONSE: 'PE_SERIES_ASSIGNMENTS_RESPONSE',
    PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST: 'PE_POST_SERIES_ASSIGNMENT_MATRIX_REQUEST',
    PE_POST_SERIES_ASSIGNMENT_MATRIX_RESPONSE: 'PE_POST_SERIES_ASSIGNMENT_MATRIX_RESPONSE',
    PE_DELETE_SERIES_ASSIGNMENT_REQUEST: 'PE_DELETE_SERIES_ASSIGNMENT_REQUEST',
    PE_DELETE_SERIES_ASSIGNMENT_RESPONSE:
        'PE_DELETE_SERIES_ASSIGNMENT_RESPONSE',
    PE_CHANGE_SERIES_ASSIGNMENT_POSITION_REQUEST:
        'PE_CHANGE_SERIES_ASSIGNMENT_POSITION_REQUEST',
    PE_CHANGE_SERIES_ASSIGNMENT_POSITION_RESPONSE:
        'PE_CHANGE_SERIES_ASSIGNMENT_POSITION_RESPONSE',
    PE_SELECT_SERIES: 'PE_SELECT_SERIES',

    // Tags
    PE_FETCH_ALL_TAG_GROUPS_REQUEST: 'PE_FETCH_ALL_TAG_GROUPS_REQUEST',
    PE_FETCH_ALL_TAG_GROUPS_RESPONSE: 'PE_FETCH_ALL_TAG_GROUPS_RESPONSE',
    PE_FETCH_TAG_GROUPS_REQUEST: 'PE_FETCH_TAG_GROUPS_REQUEST',
    PE_FETCH_TAG_GROUPS_RESPONSE: 'PE_FETCH_TAG_GROUPS_RESPONSE',
    PE_TAG_GROUP_REQUEST: 'PE_TAG_GROUP_REQUEST',
    PE_TAG_GROUP_RESPONSE: 'PE_TAG_GROUP_RESPONSE',
    PE_TAG_REQUEST: 'PE_TAG_REQUEST',
    PE_TAG_RESPONSE: 'PE_TAG_RESPONSE',
    PE_POST_TAG_GROUP_REQUEST: 'PE_POST_TAG_GROUP_REQUEST',
    PE_POST_TAG_GROUP_RESPONSE: 'PE_POST_TAG_GROUP_RESPONSE',
    PE_POST_TAG_REQUEST: 'PE_POST_TAG_REQUEST',
    PE_POST_TAG_RESPONSE: 'PE_POST_TAG_RESPONSE',
    PE_UPDATE_TAG_GROUP_REQUEST: 'PE_UPDATE_TAG_GROUP_REQUEST',
    PE_UPDATE_TAG_GROUP_RESPONSE: 'PE_UPDATE_TAG_GROUP_RESPONSE',
    PE_DELETE_TAG_GROUP_REQUEST: 'PE_DELETE_TAG_GROUP_REQUEST',
    PE_DELETE_TAG_GROUP_RESPONSE: 'PE_DELETE_TAG_GROUP_RESPONSE',
    PE_UPDATE_TAG_REQUEST: 'PE_UPDATE_TAG_REQUEST',
    PE_UPDATE_TAG_RESPONSE: 'PE_UPDATE_TAG_RESPONSE',
    PE_DELETE_TAG_REQUEST: 'PE_DELETE_TAG_REQUEST',
    PE_DELETE_TAG_RESPONSE: 'PE_DELETE_TAG_RESPONSE',
    PE_SELECT_TAG_GROUP: 'PE_SELECT_TAG_GROUP',
    PE_SELECT_TAG: 'PE_SELECT_TAG',

     /**
     * Community
     */

    // Moderator
    CS_MODERATOR_REQUEST: 'CS_MODERATOR_REQUEST',
    CS_MODERATOR_RESPONSE: 'CS_MODERATOR_RESPONSE',
    CS_SELECT_MODERATOR_ORDER_FILTER: 'CS_SELECT_MODERATOR_ORDER_FILTER',
    CS_SELECT_MODERATOR_CONTENT_FILTER: 'CS_SELECT_MODERATOR_CONTENT_FILTER',
    CS_MODERATOR_VISIBILITY_REQUEST: 'CS_MODERATOR_VISIBILITY_REQUEST',
    CS_MODERATOR_VISIBILITY_RESPONSE: 'CS_MODERATOR_VISIBILITY_RESPONSE',
    CS_MODERATOR_FLAGS_REASON_REQUEST: 'CS_MODERATOR_FLAGS_REASON_REQUEST',
    CS_MODERATOR_FLAGS_UPDATE: 'CS_MODERATOR_FLAGS_UPDATE',
    CS_MODERATOR_FLAGS_REASON_CREATE: 'CS_MODERATOR_FLAGS_REASON_CREATE',

    // Articles
    CS_ARTICLES_REQUEST: 'CS_ARTICLES_REQUEST',
    CS_ARTICLES_RESPONSE: 'CS_ARTICLES_RESPONSE',
    CS_SELECT_COMMENTED_FILTER: 'CS_SELECT_COMMENTED_FILTER',
    CS_SELECT_ARTICLE_FEATURED_FILTER: 'CS_SELECT_ARTICLE_FEATURED_FILTER',
    CS_SELECT_ARTICLE_TRENDING_FILTER: 'CS_SELECT_ARTICLE_TRENDING_FILTER',
    CS_SELECT_ARTICLE_BRANDS_FILTER: 'CS_SELECT_ARTICLE_BRANDS_FILTER',
    CS_SELECT_ARTICLE_ORDER_FILTER: 'CS_SELECT_ARTICLE_ORDER_FILTER',
    CS_SELECT_ARTICLE: 'CS_SELECT_ARTICLE',
    CS_FETCH_ARTICLE_REQUEST: 'CS_FETCH_ARTICLE_REQUEST',
    CS_FETCH_ARTICLE_RESPONSE: 'CS_FETCH_ARTICLE_RESPONSE',
    CS_DELETE_ARTICLE_REQUEST: 'CS_DELETE_ARTICLE_REQUEST',
    CS_UPDATE_ARTICLE_REQUEST: 'CS_UPDATE_ARTICLE_REQUEST',
    CS_UPDATE_ARTICLE_RESPONSE: 'CS_UPDATE_ARTICLE_RESPONSE',
    CS_POST_ARTICLE_REQUEST: 'CS_POST_ARTICLE_REQUEST',
    CS_POST_ARTICLE_RESPONSE: 'CS_POST_ARTICLE_RESPONSE',
    CS_FETCH_ARTICLE_FLAGS_REQUEST : 'CS_FETCH_ARTICLE_FLAGS_REQUEST',
    CS_FETCH_ARTICLE_FLAGS_RESPONSE : 'CS_FETCH_ARTICLE_FLAGS_RESPONSE',
    CS_SELECT_ARTICLE_FLAGS_ORDER_FILTER: 'CS_SELECT_ARTICLE_FLAGS_ORDER_FILTER',
    CS_SELECT_ARTICLE_FLAGS_STATUS_FILTER: 'CS_SELECT_ARTICLE_FLAGS_STATUS_FILTER',
    CS_UPDATE_ARTICLE_FLAGS_REASON_REQUEST: 'CS_UPDATE_ARTICLE_FLAGS_REASON_REQUEST',
    CS_UPDATE_ARTICLE_FLAGS_REASON_RESPONSE: 'CS_UPDATE_ARTICLE_FLAGS_REASON_RESPONSE',
    CS_FETCH_ARTICLE_TAG_GROUPS_REQUEST: 'CS_FETCH_ARTICLE_TAG_GROUPS_REQUEST',
    CS_FETCH_ARTICLE_TAG_GROUPS_RESPONSE: 'CS_FETCH_ARTICLE_TAG_GROUPS_RESPONSE',
    CS_ADD_TAG_TO_ARTICLE_REQUEST: 'CS_ADD_TAG_TO_ARTICLE_REQUEST',
    CS_ADD_TAG_TO_ARTICLE_RESPONSE: 'CS_ADD_TAG_TO_ARTICLE_RESPONSE',
    CS_REMOVE_TAG_FROM_ARTICLE_REQUEST: 'CS_REMOVE_TAG_FROM_ARTICLE_REQUEST',
    CS_REMOVE_TAG_FROM_ARTICLE_RESPONSE: 'CS_REMOVE_TAG_FROM_ARTICLE_RESPONSE',
    CS_FETCH_ARTICLE_FILES_REQUEST: 'CS_FETCH_ARTICLE_FILES_REQUEST',
    CS_FETCH_ARTICLE_FILES_RESPONSE: 'CS_FETCH_ARTICLE_FILES_RESPONSE',
    CS_POST_ARTICLE_FILE_REQUEST: 'CS_POST_ARTICLE_FILE_REQUEST',
    CS_POST_ARTICLE_FILE_RESPONSE: 'CS_POST_ARTICLE_FILE_RESPONSE',
    CS_DELETE_ARTICLE_FILE_REQUEST: 'CS_DELETE_ARTICLE_FILE_REQUEST',
    CS_ASSIGN_ASSET_REQUEST: 'CS_ASSIGN_ASSET_REQUEST',
    CS_UNASSIGN_ASSET_REQUEST: 'CS_UNASSIGN_ASSET_REQUEST',
    CS_FETCH_ARTICLE_TRANSLATIONS_REQUEST: 'CS_FETCH_ARTICLE_TRANSLATIONS_REQUEST',
    CS_FETCH_ARTICLE_TRANSLATIONS_RESPONSE: 'CS_FETCH_ARTICLE_TRANSLATIONS_RESPONSE',
    CS_POST_ARTICLE_TRANSLATION_REQUEST: 'CS_POST_ARTICLE_TRANSLATION_REQUEST',
    CS_POST_ARTICLE_TRANSLATION_RESPONSE: 'CS_POST_ARTICLE_TRANSLATION_RESPONSE',
    CS_DELETE_ARTICLE_TRANSLATION_REQUEST: 'CS_DELETE_ARTICLE_TRANSLATION_REQUEST',
    CS_DELETE_ARTICLE_TRANSLATION_RESPONSE: 'CS_DELETE_ARTICLE_TRANSLATION_RESPONSE',
    CS_UPDATE_ARTICLE_TRANSLATION_REQUEST: 'CS_UPDATE_ARTICLE_TRANSLATION_REQUEST',
    CS_UPDATE_ARTICLE_TRANSLATION_RESPONSE: 'CS_UPDATE_ARTICLE_TRANSLATION_RESPONSE',
    CS_SELECT_ARTICLE_TRANSLATION: 'CS_SELECT_ARTICLE_TRANSLATION',

    // Comments

    CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST: 'CS_FETCH_COMMENTS_PER_ARTICLE_REQUEST',
    CS_FETCH_COMMENTS_PER_ARTICLE_RESPONSE: 'CS_FETCH_COMMENTS_PER_ARTICLE_RESPONSE',
    CS_SELECT_COMMENT_ORDER_FILTER: 'CS_SELECT_COMMENT_ORDER_FILTER',
    CS_SELECT_COMMENT: 'CS_SELECT_COMMENT',
    CS_FETCH_COMMENT_REQUEST: 'CS_FETCH_COMMENT_REQUEST',
    CS_FETCH_COMMENT_RESPONSE: 'CS_FETCH_COMMENT_RESPONSE',
    CS_DELETE_COMMENT_REQUEST: 'CS_DELETE_COMMENT_REQUEST',
    CS_DELETE_COMMENT_RESPONSE: 'CS_DELETE_COMMENT_RESPONSE',
    CS_UPDATE_COMMENT_REQUEST: 'CS_UPDATE_COMMENT_REQUEST',
    CS_UPDATE_COMMENT_RESPONSE: 'CS_UPDATE_COMMENT_RESPONSE',
    CS_POST_COMMENT_REQUEST: 'CS_POST_COMMENT_REQUEST',
    CS_POST_COMMENT_RESPONSE: 'CS_POST_COMMENT_RESPONSE',
    CS_FETCH_COMMENT_FLAGS_REQUEST : 'CS_FETCH_COMMENT_FLAGS_REQUEST',
    CS_FETCH_COMMENT_FLAGS_RESPONSE : 'CS_FETCH_COMMENT_FLAGS_RESPONSE',
    CS_SELECT_COMMENT_FLAGS_ORDER_FILTER: 'CS_SELECT_COMMENT_FLAGS_ORDER_FILTER',
    CS_SELECT_COMMENT_FLAGS_STATUS_FILTER: 'CS_SELECT_COMMENT_FLAGS_STATUS_FILTER',
    CS_UPDATE_COMMENT_FLAGS_REASON_REQUEST: 'CS_UPDATE_COMMENT_FLAGS_REASON_REQUEST',
    CS_UPDATE_COMMENT_FLAGS_REASON_RESPONSE: 'CS_UPDATE_COMMENT_FLAGS_REASON_RESPONSE',
    CS_FETCH_COMMENT_FILES_REQUEST: 'CS_FETCH_COMMENT_FILES_REQUEST',
    CS_FETCH_COMMENT_FILES_RESPONSE: 'CS_FETCH_COMMENT_FILES_RESPONSE',
    CS_POST_COMMENT_FILE_REQUEST: 'CS_POST_COMMENT_FILE_REQUEST',
    CS_POST_COMMENT_FILE_RESPONSE: 'CS_POST_COMMENT_FILE_RESPONSE',
    CS_DELETE_COMMENT_FILE_REQUEST: 'CS_DELETE_COMMENT_FILE_REQUEST',
    CS_ASSIGN_COMMENT_ASSET_REQUEST: 'CS_ASSIGN_COMMENT_ASSET_REQUEST',
    CS_UNASSIGN_COMMENT_ASSET_REQUEST: 'CS_UNASSIGN_COMMENT_ASSET_REQUEST',
    CS_FETCH_COMMENT_TRANSLATIONS_REQUEST: 'CS_FETCH_COMMENT_TRANSLATIONS_REQUEST',
    CS_FETCH_COMMENT_TRANSLATIONS_RESPONSE: 'CS_FETCH_COMMENT_TRANSLATIONS_RESPONSE',
    CS_POST_COMMENT_TRANSLATION_REQUEST: 'CS_POST_COMMENT_TRANSLATION_REQUEST',
    CS_POST_COMMENT_TRANSLATION_RESPONSE: 'CS_POST_COMMENT_TRANSLATION_RESPONSE',
    CS_DELETE_COMMENT_TRANSLATION_REQUEST: 'CS_DELETE_COMMENT_TRANSLATION_REQUEST',
    CS_DELETE_COMMENT_TRANSLATION_RESPONSE: 'CS_DELETE_COMMENT_TRANSLATION_RESPONSE',
    CS_UPDATE_COMMENT_TRANSLATION_REQUEST: 'CS_UPDATE_COMMENT_TRANSLATION_REQUEST',
    CS_UPDATE_COMMENT_TRANSLATION_RESPONSE: 'CS_UPDATE_COMMENT_TRANSLATION_RESPONSE',
    CS_SELECT_COMMENT_TRANSLATION: 'CS_SELECT_COMMENT_TRANSLATION',

    // Questions

    CS_QUESTION_REQUEST: 'CS_QUESTION_REQUEST',
    CS_QUESTION_RESPONSE: 'CS_QUESTION_RESPONSE',
    CS_SELECT_QUESTION_ANSWERED_FILTER: 'CS_SELECT_QUESTION_ANSWERED_FILTER',
    CS_SELECT_QUESTION_FEATURED_FILTER: 'CS_SELECT_QUESTION_FEATURED_FILTER',
    CS_SELECT_QUESTION_TRENDING_FILTER: 'CS_SELECT_QUESTION_TRENDING_FILTER',
    CS_SELECT_QUESTION_REEL_FILTER: 'CS_SELECT_QUESTION_REEL_FILTER',
    CS_SELECT_QUESTION_BRANDS_FILTER: 'CS_SELECT_QUESTION_BRANDS_FILTER',
    CS_SELECT_QUESTION_ORDER_FILTER: 'CS_SELECT_QUESTION_ORDER_FILTER',
    CS_SELECT_QUESTION: 'CS_SELECT_QUESTION',
    CS_FETCH_QUESTION_REQUEST: 'CS_FETCH_QUESTION_REQUEST',
    CS_FETCH_QUESTION_RESPONSE: 'CS_FETCH_QUESTION_RESPONSE',
    CS_DELETE_QUESTION_REQUEST: 'CS_DELETE_QUESTION_REQUEST',
    CS_UPDATE_QUESTION_REQUEST: 'CS_UPDATE_QUESTION_REQUEST',
    CS_UPDATE_QUESTION_RESPONSE: 'CS_UPDATE_QUESTION_RESPONSE',
    CS_SELECT_QUESTION_ANSWER: 'CS_SELECT_QUESTION_ANSWER',
    CS_DELETE_QUESTION_ANSWER_REQUEST: 'CS_DELETE_QUESTION_ANSWER',
    CS_UPDATE_QUESTION_ANSWER_REQUEST: 'CS_UPDATE_QUESTION_ANSWER',
    CS_SELECT_QUESTION_ANSWER_REEL_FILTER: 'CS_SELECT_QUESTION_ANSWER_REEL_FILTER',
    CS_SELECT_QUESTION_ANSWER_ORDER_FILTER: 'CS_SELECT_QUESTION_ANSWER_ORDER_FILTER',
    CS_FETCH_QUESTION_FLAGS_REQUEST : 'CS_FETCH_QUESTION_FLAGS_REQUEST',
    CS_FETCH_QUESTION_FLAGS_RESPONSE : 'CS_FETCH_QUESTION_FLAGS_RESPONSE',
    CS_SELECT_QUESTION_FLAGS_ORDER_FILTER: 'CS_SELECT_QUESTION_FLAGS_ORDER_FILTER',
    CS_SELECT_QUESTION_FLAGS_STATUS_FILTER: 'CS_SELECT_QUESTION_FLAGS_STATUS_FILTER',
    CS_UPDATE_QUESTION_FLAGS_REASON_REQUEST: 'CS_UPDATE_QUESTION_FLAGS_REASON_REQUEST',
    CS_UPDATE_QUESTION_FLAGS_REASON_RESPONSE: 'CS_UPDATE_QUESTION_FLAGS_REASON_RESPONSE',
    CS_FETCH_QUESTION_TAG_GROUPS_REQUEST: 'CS_FETCH_QUESTION_TAG_GROUPS_REQUEST',
    CS_FETCH_QUESTION_TAG_GROUPS_RESPONSE: 'CS_FETCH_QUESTION_TAG_GROUPS_RESPONSE',
    CS_ADD_TAG_TO_QUESTION_REQUEST: 'CS_ADD_TAG_TO_QUESTION_REQUEST',
    CS_ADD_TAG_TO_QUESTION_RESPONSE: 'CS_ADD_TAG_TO_QUESTION_RESPONSE',
    CS_REMOVE_TAG_FROM_QUESTION_REQUEST: 'CS_REMOVE_TAG_FROM_QUESTION_REQUEST',
    CS_REMOVE_TAG_FROM_QUESTION_RESPONSE: 'CS_REMOVE_TAG_FROM_QUESTION_RESPONSE',
    CS_FETCH_QUESTION_FILES_REQUEST: 'CS_FETCH_QUESTION_FILES_REQUEST',
    CS_FETCH_QUESTION_FILES_RESPONSE: 'CS_FETCH_QUESTION_FILES_RESPONSE',
    CS_POST_QUESTION_FILE_REQUEST: 'CS_POST_QUESTION_FILE_REQUEST',
    CS_POST_QUESTION_FILE_RESPONSE: 'CS_POST_QUESTION_FILE_RESPONSE',
    CS_DELETE_QUESTION_FILE_REQUEST: 'CS_DELETE_QUESTION_FILE_REQUEST',
    CS_ASSIGN_QUESTION_ASSET_REQUEST: 'CS_ASSIGN_QUESTION_ASSET_REQUEST',
    CS_UNASSIGN_QUESTION_ASSET_REQUEST: 'CS_UNASSIGN_QUESTION_ASSET_REQUEST',
    CS_FETCH_QUESTION_TRANSLATIONS_REQUEST: 'CS_FETCH_QUESTION_TRANSLATIONS_REQUEST',
    CS_FETCH_QUESTION_TRANSLATIONS_RESPONSE: 'CS_FETCH_QUESTION_TRANSLATIONS_RESPONSE',
    CS_POST_QUESTION_TRANSLATION_REQUEST: 'CS_POST_QUESTION_TRANSLATION_REQUEST',
    CS_POST_QUESTION_TRANSLATION_RESPONSE: 'CS_POST_QUESTION_TRANSLATION_RESPONSE',
    CS_DELETE_QUESTION_TRANSLATION_REQUEST: 'CS_DELETE_QUESTION_TRANSLATION_REQUEST',
    CS_DELETE_QUESTION_TRANSLATION_RESPONSE: 'CS_DELETE_QUESTION_TRANSLATION_RESPONSE',
    CS_UPDATE_QUESTION_TRANSLATION_REQUEST: 'CS_UPDATE_QUESTION_TRANSLATION_REQUEST',
    CS_UPDATE_QUESTION_TRANSLATION_RESPONSE: 'CS_UPDATE_QUESTION_TRANSLATION_RESPONSE',
    CS_SELECT_QUESTION_TRANSLATION: 'CS_SELECT_QUESTION_TRANSLATION',
    CS_POST_QUESTION_REQUEST: 'CS_POST_QUESTION_REQUEST',
    CS_POST_QUESTION_RESPONSE: 'CS_POST_QUESTION_RESPONSE',

    // Answers
    CS_FETCH_ANSWERS_PER_QUESTION_REQUEST: 'CS_FETCH_ANSWERS_PER_QUESTION_REQUEST',
    CS_FETCH_ANSWERS_PER_QUESTION_RESPONSE: 'CS_FETCH_ANSWERS_PER_QUESTION_RESPONSE',
    CS_FETCH_ANSWER_REQUEST: 'CS_FETCH_ANSWER_REQUEST',
    CS_FETCH_ANSWER_RESPONSE: 'CS_FETCH_ANSWER_RESPONSE',
    CS_POST_ANSWER_REQUEST: 'CS_POST_ANSWER_REQUEST',
    CS_POST_ANSWER_RESPONSE: 'CS_POST_ANSWER_RESPONSE',
    CS_UPDATE_ANSWER_REQUEST: 'CS_UPDATE_ANSWER_REQUEST',
    CS_UPDATE_ANSWER_RESPONSE: 'CS_UPDATE_ANSWER_RESPONSE',
    CS_DELETE_ANSWER_REQUEST : 'CS_DELETE_ANSWER_REQUEST',
    CS_DELETE_ANSWER_RESPONSE : 'CS_DELETE_ANSWER_RESPONSE',
    CS_FETCH_ANSWER_FLAGS_REQUEST : 'CS_FETCH_ANSWER_FLAGS_REQUEST',
    CS_FETCH_ANSWER_FLAGS_RESPONSE : 'CS_FETCH_ANSWER_FLAGS_RESPONSE',
    CS_SELECT_ANSWER_FLAGS_ORDER_FILTER: 'CS_SELECT_ANSWER_FLAGS_ORDER_FILTER',
    CS_SELECT_ANSWER_FLAGS_STATUS_FILTER: 'CS_SELECT_ANSWER_FLAGS_STATUS_FILTER',
    CS_UPDATE_ANSWER_FLAGS_REASON_REQUEST: 'CS_UPDATE_ANSWER_FLAGS_REASON_REQUEST',
    CS_UPDATE_ANSWER_FLAGS_REASON_RESPONSE: 'CS_UPDATE_ANSWER_FLAGS_REASON_RESPONSE',
    CS_FETCH_ANSWER_FILES_REQUEST: 'CS_FETCH_ANSWER_FILES_REQUEST',
    CS_FETCH_ANSWER_FILES_RESPONSE: 'CS_FETCH_ANSWER_FILES_RESPONSE',
    CS_POST_ANSWER_FILE_REQUEST: 'CS_POST_ANSWER_FILE_REQUEST',
    CS_POST_ANSWER_FILE_RESPONSE: 'CS_POST_ANSWER_FILE_RESPONSE',
    CS_DELETE_ANSWER_FILE_REQUEST: 'CS_DELETE_ANSWER_FILE_REQUEST',
    CS_ASSIGN_ANSWER_ASSET_REQUEST: 'CS_ASSIGN_ANSWER_ASSET_REQUEST',
    CS_UNASSIGN_ANSWER_ASSET_REQUEST: 'CS_UNASSIGN_ANSWER_ASSET_REQUEST',
    CS_FETCH_ANSWER_TRANSLATIONS_REQUEST: 'CS_FETCH_ANSWER_TRANSLATIONS_REQUEST',
    CS_FETCH_ANSWER_TRANSLATIONS_RESPONSE: 'CS_FETCH_ANSWER_TRANSLATIONS_RESPONSE',
    CS_POST_ANSWER_TRANSLATION_REQUEST: 'CS_POST_ANSWER_TRANSLATION_REQUEST',
    CS_POST_ANSWER_TRANSLATION_RESPONSE: 'CS_POST_ANSWER_TRANSLATION_RESPONSE',
    CS_DELETE_ANSWER_TRANSLATION_REQUEST: 'CS_DELETE_ANSWER_TRANSLATION_REQUEST',
    CS_DELETE_ANSWER_TRANSLATION_RESPONSE: 'CS_DELETE_ANSWER_TRANSLATION_RESPONSE',
    CS_UPDATE_ANSWER_TRANSLATION_REQUEST: 'CS_UPDATE_ANSWER_TRANSLATION_REQUEST',
    CS_UPDATE_ANSWER_TRANSLATION_RESPONSE: 'CS_UPDATE_ANSWER_TRANSLATION_RESPONSE',
    CS_SELECT_ANSWER_TRANSLATION: 'CS_SELECT_ANSWER_TRANSLATION',

    // Tags
    CS_FETCH_ALL_TAG_GROUPS_REQUEST: 'CS_FETCH_ALL_TAG_GROUPS_REQUEST',
    CS_FETCH_ALL_TAG_GROUPS_RESPONSE: 'CS_FETCH_ALL_TAG_GROUPS_RESPONSE',
    CS_FETCH_TAG_GROUPS_REQUEST: 'CS_FETCH_TAG_GROUPS_REQUEST',
    CS_FETCH_TAG_GROUPS_RESPONSE: 'CS_FETCH_TAG_GROUPS_RESPONSE',
    CS_TAG_GROUP_REQUEST: 'CS_TAG_GROUP_REQUEST',
    CS_TAG_GROUP_RESPONSE: 'CS_TAG_GROUP_RESPONSE',
    CS_TAG_REQUEST: 'CS_TAG_REQUEST',
    CS_TAG_RESPONSE: 'CS_TAG_RESPONSE',
    CS_POST_TAG_GROUP_REQUEST: 'CS_POST_TAG_GROUP_REQUEST',
    CS_POST_TAG_GROUP_RESPONSE: 'CS_POST_TAG_GROUP_RESPONSE',
    CS_POST_TAG_REQUEST: 'CS_POST_TAG_REQUEST',
    CS_POST_TAG_RESPONSE: 'CS_POST_TAG_RESPONSE',
    CS_UPDATE_TAG_GROUP_REQUEST: 'CS_UPDATE_TAG_GROUP_REQUEST',
    CS_UPDATE_TAG_GROUP_RESPONSE: 'CS_UPDATE_TAG_GROUP_RESPONSE',
    CS_DELETE_TAG_GROUP_REQUEST: 'CS_DELETE_TAG_GROUP_REQUEST',
    CS_DELETE_TAG_GROUP_RESPONSE: 'CS_DELETE_TAG_GROUP_RESPONSE',
    CS_UPDATE_TAG_REQUEST: 'CS_UPDATE_TAG_REQUEST',
    CS_UPDATE_TAG_RESPONSE: 'CS_UPDATE_TAG_RESPONSE',
    CS_DELETE_TAG_REQUEST: 'CS_DELETE_TAG_REQUEST',
    CS_DELETE_TAG_RESPONSE: 'CS_DELETE_TAG_RESPONSE',
    CS_SELECT_TAG_GROUP: 'CS_SELECT_TAG_GROUP',
    CS_SELECT_TAG: 'CS_SELECT_TAG',

    //Members
    CS_FETCH_MEMBERS_REQUEST: 'CS_FETCH_MEMBERS_REQUEST',
    CS_FETCH_MEMBERS_RESPONSE: 'CS_FETCH_MEMBERS_RESPONSE',
    CS_GET_MEMBER_REQUEST: 'CS_GET_MEMBER_REQUEST',
    CS_GET_MEMBER_RESPONSE: 'CS_GET_MEMBER_RESPONSE',
    CS_SELECT_MEMBER_BRANDS_FILTER: 'CS_SELECT_MEMBER_BRANDS_FILTER',
    CS_SELECT_MEMBER: 'CS_SELECT_MEMBER',
    CS_FETCH_EXPERT_COACHES_REQUEST: 'CS_FETCH_EXPERT_COACHES_REQUEST',
    CS_FETCH_EXPERT_COACHES_RESPONSE: 'CS_FETCH_EXPERT_COACHES_RESPONSE',

    //Flags
    CS_FETCH_FLAGS_REQUEST: 'CS_FETCH_FLAGS_REQUEST',
    CS_FETCH_FLAGS_RESPONSE: 'CS_FETCH_FLAGS_RESPONSE',
    CS_SELECT_FLAGS_STATUS: 'CS_SELECT_FLAGS_STATUS',
    CS_SELECT_FLAGS_ORDER_FILTER: 'CS_SELECT_FLAGS_ORDER_FILTER',
    CS_FLAGS_CHANGE_REASON_REQUEST: 'CS_FLAGS_CHANGE_REASON_REQUEST',
};

export default ACTION_TYPES;
