import api from '../api/api';

const { put } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* postBrands(data) {
    const json = yield api.post('/referrals/brands', data).then((res) => res);
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.POST_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* getAllBrands() {
    const json = yield api.get('/referrals/brands?per_page=1000');
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_ALL_BRANDS_RESPONSE,
            data: {
                list: json.results || [],
            },
        });
    }
}

function* getBrand(brandId) {
    const json = yield api.get(`/referrals/brands/${brandId}`);
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* getBrands(per_page = 10, page = 1) {
    const json = yield api.get(
        `/referrals/brands?per_page=${per_page}&page=${page}`
    );
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.FETCH_BRANDS_RESPONSE,
            data: {
                list: json.results || [],
                total: json.total,
                total_pages: json.total_pages,
                page: json.page,
                per_page: json.per_page,
            },
        });
    }
}

function* updateBrand(data) {
    const json = yield api
        .put('/referrals/brands/' + data.ident, {
            is_enabled: data.is_enabled,
            name: data.name,
            subdomain: data.subdomain,
        })
        .then((res) => res);
    if (json.type && ![200, 201, 202].includes(json.type)) {
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.UPDATE_BRAND_RESPONSE,
            data: json,
        });
    }
}

function* brandsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ALL_BRANDS_REQUEST: {
            yield getAllBrands();
            break;
        }
        case ACTION_TYPES.FETCH_BRAND_REQUEST: {
            yield getBrand(action.data);
            break;
        }
        case ACTION_TYPES.FETCH_BRANDS_REQUEST:
            yield getBrands(action.data?.per_page, action.data?.page);
            break;
        case ACTION_TYPES.POST_BRAND_REQUEST: {
            yield postBrands(action.data);
            break;
        }
        case ACTION_TYPES.UPDATE_BRAND_REQUEST: {
            yield updateBrand(action.data);
            break;
        }
        default:
            break;
    }
}

export default brandsWorker;
