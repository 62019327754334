import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const { default: LoadingIcon } = require('components/Icons/Loading');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        loading: state.user.loading,
        user: state.user.user,
    };
}

const LandingPage = connect(mapStateToProps)(function (props) {
    const { loading, user } = props;
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const { GO_LOGIN, POST_CODE_REQUEST } = useActions();

    useEffect(() => {
        const query = new URLSearchParams(search);
        const code = query.get('auth_code');

        if (!loading && code && code.length > 0) {
            POST_CODE_REQUEST({ code });
        } else if (!user && !loading) {
            if(pathname + search !== '/logout') GO_LOGIN(pathname + search);
            else GO_LOGIN(null);
            navigate(`/login`);
        }
        // eslint-disable-next-line
    }, [search, loading, user]);

    return (
        <div className="h-full w-full absolute top-0 left-0 bg-gray-200 z-10 opacity-75 flex justify-center items-center">
            <LoadingIcon />
        </div>
    );
});

export default LandingPage;
