import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    tagGroups: [],
    page: 1,
    per_page: 10,
    total_pages: 1,
    total: 0,
    selectedTagGroup: null,
    selectedTag: null,
    tagGroupOptions: [],
    recentRetags: null
};

function VHSTagsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_ALL_TAGS_RESPONSE: {
            const options = action.data?.map(tag => ({
                value: tag.id,
                label: tag.name
            }));
            return {
                ...state,
                tagOptions: options,
            };
        }
        case ACTION_TYPES.VHS_FETCH_ALL_TAG_GROUPS_RESPONSE: {
            const options = action.data?.map((tagG) => ({
                value: tagG.id,
                label: tagG.name,
            }));
            return {
                ...state,
                tagGroupOptions: options,
            };
        }
        case ACTION_TYPES.VHS_FETCH_TAG_GROUPS_RESPONSE: {
            return {
                ...state,
                tagGroups: action.data.list,
                page: action.data.page,
                per_page: action.data.per_page,
                total_pages: action.data.total_pages,
                total: action.data.total,
            };
        }
        case ACTION_TYPES.VHS_SELECT_TAG_GROUP: {
            return { ...state, selectedTagGroup: action.data };
        }
        case ACTION_TYPES.VHS_SELECT_TAG: {
            return { ...state, selectedTag: action.data };
        }
        case ACTION_TYPES.VHS_POST_TAG_GROUP_RESPONSE: {
            return {
                ...state,
                selectedTagGroup: action.data,
            };
        }
        case ACTION_TYPES.VHS_TAG_GROUP_RESPONSE: {
            return {
                ...state,
                selectedTagGroup: action.data,
            };
        }
        case ACTION_TYPES.VHS_TAG_RESPONSE: {
            return {
                ...state,
                selectedTag: action.data,
            };
        }
        case ACTION_TYPES.VHS_POST_TAG_RESPONSE: {
            return {
                ...state,
                selectedTag: action.data,
            };
        }
        case ACTION_TYPES.VHS_REPLACE_TAGS_REQUEST: {
            return {
                ...state,
                recentRetags: null
            };
        }
        case ACTION_TYPES.VHS_REPLACE_TAGS_RESPONSE: {
            return {
                ...state,
                recentRetags: action.data.updatedEpisodes
            };
        }
        default: {
            return state;
        }
    }
}

export default VHSTagsReducer;
