import LineChart from 'components/LineChart';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import EpisodesTable from './Episodes/EpisodesTable';

function mapStateToProps(state) {
    return {
        recentEpisodes: state.vhs.recentEpisodes,
        dashboardChartData: state.vhs.lastMonthChartData,
    };
}

function VHSDashboard(props) {
    const { dashboardChartData, recentEpisodes } = props;
    console.log(dashboardChartData);
    const {
        VHS_FETCH_DASHBOARD_CHART_DATA_REQUEST,
        VHS_FETCH_RECENT_EPISODES_REQUEST,
    } = useActions();

    useEffect(() => {
        VHS_FETCH_DASHBOARD_CHART_DATA_REQUEST();
        VHS_FETCH_RECENT_EPISODES_REQUEST();
        // eslint-disable-next-line
    }, []);

    return (
        <section>
            <h1 className="admin-section-title">&#x1f4fc; &nbsp; Video Hosting Service</h1>
            <div className='flex space-x-4'>
                <div className="w-1/2">
                    <div>Plays in last 30 Days</div>
                    {
                        <LineChart
                            dataArrays={[
                                dashboardChartData?.map((data) => ({
                                    name: data.name,
                                    value: data.play || 0,
                                    type: 'plays'
                                })),
                            ]}
                            colors={['blue']}
                        />
                    }
                </div>
                <div className="w-1/2">
                    <div>Minutes watched in last 30 Days</div>
                    {
                        <LineChart
                            dataArrays={[
                                dashboardChartData?.map((data) => ({
                                    name: data.name,
                                    value: data.minutes_watched || 0,
                                    type: 'minutes_watched'
                                })),
                            ]}
                            colors={['blue']}
                        />
                    }
                </div>
            </div>
            <div className="mt-8">
                <div>Recent Episodes</div>
                <EpisodesTable episodes={recentEpisodes} />
            </div>
        </section>
    );
}

const VHS = connect(mapStateToProps)(VHSDashboard);
export default VHS;
