import useQueryList from 'hooks/useQuery';
import {useState} from 'react'

function SearchField({
    searchUpdateAsync = function (value = '') {},
    preventReloadOnSearch = false,
}){
    const { term: searchTerm }  = useQueryList(['term']);
    const [searchText, setSearchText] = useState(searchTerm);

    const clearSearchQuery= (e) => {
        e.preventDefault();
        const url = window.location.href;
        window.location.href =  url.split("?")[0];
    }

    const handleSubmit= (e) => {
        e.preventDefault();
        if(preventReloadOnSearch){
            searchUpdateAsync(searchText);
        }else{
            let url = window.location.href;
            url = url.split("?")[0];
            window.location.href =  url + "?term=" + searchText;
        }
    }
    return(
        <div className="flex items-start justify-start ml-auto">
        <form method="GET" onSubmit={handleSubmit}>
            <div className="relative text-gray-600 focus-within:text-gray-400 flex-1 flex">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="submit" className="search-btn p-1 focus:outline-none focus:shadow-outline">
                    <img src='/assets/search-lg.png' alt='Search' className='search-icon'/>
                </button>
            </span>
            <input
                type="input"
                name="term"
                className="py-2 w-full text-md bg-white rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900 search-input"
                placeholder="Search"
                autoComplete="off"
                value={!!searchText ? searchText : ''} 
                onChange={ (e) => setSearchText(e.target.value) }
            />
            {!!searchTerm && (
                <button className="absolute inset-y-0 right-0 flex items-center bg-white clearSearchBox" onClick={(e) => clearSearchQuery(e)}>
                    <svg viewBox="-5 -5 22 22">
                    <line x1="1" y1="11" x2="11" y2="1" stroke="white" stroke-width="2"></line>
                    <line x1="1" y1="1" x2="11" y2="11" stroke="white" stroke-width="2"></line>
                    </svg>
                </button>
            )}
            </div>
        </form>   
        </div>
    )
}
export default SearchField;