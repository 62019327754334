import ACTION_TYPES from 'constants/ACTION_TYPES';
import { takeEvery } from 'redux-saga/effects';
import csAnswersWorker from '../workers/csAnswersWorker';

function* CS_ANSWERS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.CS_FETCH_ANSWERS_PER_QUESTION_REQUEST,
            ACTION_TYPES.CS_FETCH_ANSWER_REQUEST,
            ACTION_TYPES.CS_POST_ANSWER_REQUEST,
            ACTION_TYPES.CS_UPDATE_ANSWER_REQUEST,
            ACTION_TYPES.CS_DELETE_ANSWER_REQUEST,
            ACTION_TYPES.CS_FETCH_ANSWER_FLAGS_REQUEST,
            ACTION_TYPES.CS_UPDATE_ANSWER_FLAGS_REASON_REQUEST,
            ACTION_TYPES.CS_FETCH_ANSWER_FILES_REQUEST,
            ACTION_TYPES.CS_POST_ANSWER_FILE_REQUEST,
            ACTION_TYPES.CS_DELETE_ANSWER_FILE_REQUEST,
            ACTION_TYPES.CS_ASSIGN_ANSWER_ASSET_REQUEST,
            ACTION_TYPES.CS_UNASSIGN_ANSWER_ASSET_REQUEST,
            ACTION_TYPES.CS_FETCH_ANSWER_TRANSLATIONS_REQUEST,
            ACTION_TYPES.CS_POST_ANSWER_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_DELETE_ANSWER_TRANSLATION_REQUEST,
            ACTION_TYPES.CS_UPDATE_ANSWER_TRANSLATION_REQUEST,
        ],
        csAnswersWorker,
        apiUrl,
        ...params
    );
}

export default CS_ANSWERS_REQUEST;
