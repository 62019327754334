import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';
import { connect } from 'react-redux';

const { default: DetailOverview } = require('components/DetailOverview');

function mapStateToProps(state) {
    return {
        categoriesOptions: state.PECategories.categoriesOptions,
    };
}

const PEAssignmentOverview = connect(mapStateToProps)(function ({
    assignment,
    categoriesOptions,
}) {
    const [categoriesSet, setCategoriesSet] = useSetFromOptions();

    useEffect(() => {
        setCategoriesSet(categoriesOptions);
        // eslint-disable-next-line
    }, [categoriesOptions]);

    return (
        <section>
            {assignment.poster_url && (
                <img
                    alt={assignment.name}
                    src={assignment.poster_url}
                    style={{ maxWidth: '25rem' }}
                    className="mb-4 mt-4"
                />
            )}
            <DetailOverview
                fields={[
                    { name: 'Name', value: assignment.name },
                    { name: 'Technical Name', value: assignment.technical_name },
                    { name: 'Description', value: assignment.description, type: 'markdown' },
                    {
                        name: 'Category',
                        value: categoriesSet[assignment.category_id],
                        type: 'link',
                        url: `/program-engine/categories/${assignment.category_id}`,
                    },
                    { name: 'Region', value: assignment.region_ident },
                    { name: 'Action', value: assignment.action },
                    { name: 'Duration', value: assignment.duration_ms && `${assignment.duration_ms/1000/60} min` },
                    {
                        name: 'Created',
                        value: assignment.created_int,
                        type: 'date',
                    },
                    {
                        name: 'Updated',
                        value: assignment.updated_int,
                        type: 'date',
                    },
                ]}
            />
        </section>
    );
});

export default PEAssignmentOverview;
