import { CheckIcon } from '@heroicons/react/outline';
import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import TableImage from 'components/TableImage';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect, useState } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        assignments: state.PEAssignments.assignments,
        page: state.PEAssignments.page,
        per_page: state.PEAssignments.per_page,
        total: state.PEAssignments.total,
        total_pages: state.PEAssignments.total_pages,
        isLoading: state.PEAssignments.isLoadingAssignments,
        categoriesOptions: state.PECategories.categoriesOptions,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEAssignmentModalContent = connect(mapStateToProps)(function (props) {
    const {
        assignments,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        forcedLoading,
        categoriesOptions,
        onSelect = function () {},
        selectedRegionFilter,
        parentRegion,
    } = props;

    const { PE_FETCH_ASSIGNMENTS_REQUEST } = useActions();
    const [categoriesSet, setCategoriesSet] = useSetFromOptions();
    const [searchTerm, setSearchTerm] = useState('');

    function onNext() {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
            region_ident: parentRegion,
        });
    }

    function onPrev() {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
            region_ident: parentRegion,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
            region_ident: parentRegion,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_ASSIGNMENTS_REQUEST({ 
            per_page: perPage,
            searchTerm,
            region_ident: parentRegion,
        });
    }

    function onTagsFilter(tagsQuery) {
        PE_FETCH_ASSIGNMENTS_REQUEST({ 
            page: 1,
            per_page,
            searchTerm,
            region_ident: parentRegion,
            tagsQuery,
        });
    }

    useEffect(() => {
        if (categoriesOptions) {
            setCategoriesSet(categoriesOptions);
        }
        // eslint-disable-next-line
    }, [categoriesOptions]);

    useEffect(() => {
        PE_FETCH_ASSIGNMENTS_REQUEST({
            per_page,
            searchTerm, 
            region_ident: parentRegion,
            page: 1
        });
        // eslint-disable-next-line
    }, [selectedRegionFilter, searchTerm, parentRegion]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} />
                <LoadingTable isLoading={isLoading || forcedLoading}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Tags</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {assignments.map((assignment) => (
                            <tr key={`assign-${assignment.id}`}>
                                <TableImage
                                    alt={assignment.name}
                                    imageUrl={assignment.poster_url}
                                />
                                <td>
                                    <SelectableField
                                        text={assignment.name}
                                        url={`/program-engine/assignments/${assignment.id}`}
                                    />
                                </td>
                                <td>
                                    <SelectableField
                                        text={
                                            categoriesSet[
                                                assignment.category_id
                                            ]
                                        }
                                        url={`/program-engine/categories/${assignment.category_id}`}
                                    />
                                </td>
                                <td className="tags">
                                    <div className="tagbox">
                                        {assignment.tags?.map((tag) => (
                                            <div
                                                className="tag"
                                                key={`tag-${assignment.id}-${tag.id}`}
                                            >
                                                {tag.name}
                                            </div>
                                        ))}
                                    </div>
                                </td>
                                <td>
                                    <TableButton
                                        className="select-button"
                                        action={() => {
                                            onSelect(assignment);
                                        }}
                                    >
                                        <CheckIcon
                                            height={'1.3rem'}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Select
                                    </TableButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }
    return (
        <section className="p-4">
            <InformationTab
                dataTable={<DataTable />}
                showPrograms={false}
                showSearchField={true}
                preventReloadOnSearch={true}
                searchUpdateAsync={setSearchTerm}
                sectionName="Assignments"
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                showFilterTags={true}
                onTagsFilter={onTagsFilter}
            />
        </section>
    );
});

export default PEAssignmentModalContent;

