import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';


function mapStateToProps(state) {
    return {
        tags: state.vhsTags
    };
}

const RetagEpisodes = connect(mapStateToProps)(function(props) {
    const { tags } = props;

    const {
        VHS_FETCH_ALL_TAGS_REQUEST,
        VHS_REPLACE_TAGS_REQUEST
    } = useActions();

    const [targetTag, setTargetTag] = useState(null);
    const [newTag, setNewTag] = useState(null);
    const [waitingCompletion, setWaitingCompletion] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    let submitButtonClasses = "p-2 mr-4 text-white self-end";
    let submitText = "ReTag Episodes";
    if(targetTag) {
        if(newTag) {
            submitButtonClasses += " bg-green-add";
        } else {
            submitButtonClasses += " delete-button";
            submitText = "Untag Episodes";
        }
    } else {
        submitButtonClasses += " disabled-button";
    }

    useEffect(() => {
        if(!tags.tagOptions) {
            VHS_FETCH_ALL_TAGS_REQUEST({ per_page:10000, page:1 });
        }
    });

    useEffect(() => {
        if(tags.recentRetags !== null && waitingCompletion) {
            setWaitingCompletion(false);
            setTargetTag(null);
            setNewTag(null);

            if(newTag) {
                setSuccessMessage(`Retag Successful. All (${tags.recentRetags}) episodes tagged with ${targetTag.label} have been retagged with ${newTag.label}`);
            } else {
                setSuccessMessage(`Retag Successful. All (${tags.recentRetags}) episodes tagged with ${targetTag.label} have had this tag removed.`);
            }
        }
    }, [targetTag, newTag, waitingCompletion, tags.recentRetags]);

    function onSubmit() {
        if(!targetTag) {
            return;
        }

        setSuccessMessage(null);
        setWaitingCompletion(true);
        VHS_REPLACE_TAGS_REQUEST({ target_tag_id: targetTag.value, new_tag_id: newTag?.value });
    }

    return (
        <div className="content content-pending rounded-xl my-4 p-6">
            <h2 className="text-xl mb-0">Retag Episodes</h2>
            <label className="block">All episodes that have the target Tag will have it removed or reassigned.</label>
            <small className="block">NOTE: This does NOT delete the tag nor remove it from tag groups.</small>
            <div className="flex mt-4">
                <div className="w-1/3">
                    <label className="block mb-1">
                        Target Tag <br/>
                        <small>(to find and replace or remove)</small>
                    </label>
                    <Select
                        options={tags?.tagOptions?.filter((tagOp)=> { return tagOp.value !== newTag?.value })} 
                        value={targetTag}
                        onChange={(selected)=> setTargetTag(selected)}
                        labelledBy="TargetTagSelect"
                    />
                </div>
                <div className="w-1/3 px-2">
                    <label className="block mb-1">
                        Replacement Tag <br/>
                        <small>(Leave blank to remove all usage of the target tag)</small>
                    </label>
                    <Select
                        options={tags?.tagOptions?.filter((tagOp)=> { return tagOp.value !== targetTag?.value })} 
                        value={newTag}
                        onChange={(selected)=> setNewTag(selected)}
                        labelledBy="NewTagSelect"
                    />
                </div>
                <button className={submitButtonClasses} type="button" onClick={onSubmit} disabled={!targetTag || waitingCompletion}>{submitText}</button>
            </div>
            {successMessage && 
                <p className="text-xl mt-4">{successMessage}</p>
            }
        </div>
    );
});

export default RetagEpisodes;