import LinkButton from 'components/LinkButton';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PEMatrixDetails from './MatrixDetails';
import PEMatrixNew from './MatrixNew';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


const { default: InformationTab } = require('components/InformationTab');
const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        matrices: state.PEMatrices.matrices,
        page: state.PEMatrices.page,
        per_page: state.PEMatrices.per_page,
        total: state.PEMatrices.total,
        total_pages: state.PEMatrices.total_pages,
        isLoading: state.PEMatrices.isLoadingMatrices,
        selectedMatrix: state.PEMatrices.selectedMatrix,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PEMatrices = connect(mapStateToProps)(function (props) {
    const {
        matrices,
        page,
        per_page,
        total,
        total_pages,
        isLoading,
        selectedMatrix,
        selectedRegionFilter,
    } = props;

    const { id } = useParams();
    const { term: searchTerm } = useQueryList(['term']);

    const navigate = useNavigate();

    const [subtitle, setSubtitle] = useState(null);
    const [detailComponent, setDetailComponent] = useState(null);
    const [showDelete, setShowDelete] = useState(false);

    const {
        PE_FETCH_MATRICES_REQUEST,
        PE_CLONE_MATRIX_REQUEST,
        PE_SELECT_MATRIX,
        PE_GET_MATRIX_REQUEST,
        PE_DELETE_MATRIX_REQUEST,
        PE_GET_MATRIX_CONFIG_REQUEST,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();

    const onAdd = () => {
        setSubtitle('Add New Matrix');
        setDetailComponent(<PEMatrixNew />);
    };

    function onNext() {
        PE_FETCH_MATRICES_REQUEST({
            page: page + 1,
            per_page,
            searchTerm,
        });
    }

    function onPrev() {
        PE_FETCH_MATRICES_REQUEST({
            page: page - 1,
            per_page,
            searchTerm,
        });
    }

    function onPageSelected(pageSelected) {
        PE_FETCH_MATRICES_REQUEST({
            page: pageSelected,
            per_page,
            searchTerm,
        });
    }

    function onPerPageSelected({ perPage }) {
        PE_FETCH_MATRICES_REQUEST({
            per_page: perPage,
            searchTerm,
        });
    }

    function onDeleteAction() {
        PE_DELETE_MATRIX_REQUEST({
            matrixId: selectedMatrix?.id,
            page,
            searchTerm,
            perPage: per_page,
        });
        navigate('/program-engine/matrices');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Matrix?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function onDetailSelection(matrix) {
        setSubtitle(null);
        setDetailComponent(null);
        PE_SELECT_MATRIX(matrix);
        setShowDelete(true);
        if (matrix) {
            navigate(`/program-engine/matrices/${matrix.id}`);
        } else {
            PE_FETCH_MATRICES_REQUEST({
                page,
                per_page,
                searchTerm,
            });
            navigate(`/program-engine/matrices`);
        }
    }

    useEffect(() => {
        if (selectedMatrix) {
            setSubtitle(selectedMatrix?.name);
            setDetailComponent(<PEMatrixDetails />);
            setShowDelete(true);
        } else {
            setShowDelete(false);
            setDetailComponent(null);
        }
    }, [selectedMatrix]);

    useEffect(() => {
        if (id) {
            try {
                PE_GET_MATRIX_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            PE_SELECT_MATRIX(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        PE_FETCH_MATRICES_REQUEST({ searchTerm });
        PE_GET_MATRIX_CONFIG_REQUEST();
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    function DataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Matrix" onAdd={onAdd}/>
                <LoadingTable isLoading={isLoading}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Clone</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matrices.map((matrix) => (
                            <tr>
                                <td>
                                    <SelectableField
                                        text={matrix.name}
                                        url={`/program-engine/matrices/${matrix.id}`}
                                    />
                                </td>
                                <td>
                                    <button
                                        className="bg-green-add p-2 ml-4 text-white"
                                        onClick={() =>
                                            PE_CLONE_MATRIX_REQUEST(matrix.id)
                                        }
                                    >
                                        Clone Matrix
                                    </button>
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/matrices/${matrix.id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNext}
                    onPrev={onPrev}
                    onPageSelected={onPageSelected}
                />
            </section>
        );
    }

    return (
        <InformationTab
            sectionName="Matrices"
            dataTable={<DataTable />}
            subsectionName={subtitle}
            showPrograms={false}
            perPage={per_page}
            onPerPageSelected={onPerPageSelected}
            showDelete={showDelete}
            deleteText="Matrix"
            detailComponent={detailComponent}
            onDetailSelected={onDetailSelection}
            onDeleteDetail={onDeleteDetail}
            showSearchField={true}
        />
    );
});

export default PEMatrices;
