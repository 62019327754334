import ActivityRecord from 'components/ActivityRecord';
import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useEffect, useState } from 'react';
import LandingPage from '../LandingPage';
import ProgramConversions from '../ProgramConversions';
import ProgramEdit from '../ProgramEdit';
import ProgramOverview from '../ProgramOverview';
import ShareDialogs from '../ShareDialogs';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        brandOptions: state.brands.brandOptions,
        isLoading: state.programs.isLoading,
        conversions: state.programs.conversions,
        conversionsPage: state.programs.conversions_page,
        conversionsPerPage: state.programs.conversions_per_page,
        conversionsTotalPages: state.programs.conversions_total_pages,
        conversionsTotal: state.programs.conversions_total,
        isLoadingConversions: state.programs.isLoadingConversions,
        activity: state.programs.activity,
        activityPage: state.programs.activity_page,
        activityPerPage: state.programs.activity_per_page,
        activityTotalPages: state.programs.activity_total_pages,
        isLoadingActivity: state.programs.isLoadingActivity,
        program: state.programs.selectedProgram,
    };
}

const ProgramsDetail = connect(mapStateToProps)(function (props) {
    const {
        program,
        brandOptions,
        isLoading,
        brandsSet,
        conversions,
        conversionsPage,
        conversionsPerPage,
        conversionsTotalPages,
        conversionsTotal,
        isLoadingConversions,
        activityPage,
        activityPerPage,
        activityTotalPages,
        activity,
        isLoadingActivity,
    } = props;

    const {
        UPDATE_PROGRAM_REQUEST,
        FETCH_PROGRAM_CONVERSIONS_REQUEST,
        FETCH_PROGRAM_ACTIVITY_REQUEST,
        UPDATE_PROGRAM_CONVERSION_REQUEST,
        UPLOAD_PROGRAM_SOCIAL_IMAGE_REQUEST,
    } = useActions();

    const [selectedTab, setSelectedTab] = useState(0);

    const onApproveConversion = (conversion, newState) => {
        UPDATE_PROGRAM_CONVERSION_REQUEST({ conversion, newState });
    };

    const onConfirm = (updatedProgram) => {
        UPDATE_PROGRAM_REQUEST(updatedProgram);
        setSelectedTab(0);
    };

    const onUploadImage = (file) => {
        UPLOAD_PROGRAM_SOCIAL_IMAGE_REQUEST({
            programId: program.id,
            brandIdent: program.brand_ident,
            file,
        });
    };

    const onConversionsPerPageSelected = ({ perPage }) => {
        FETCH_PROGRAM_CONVERSIONS_REQUEST({ id: program.id, perPage });
    };

    const onConversionsNext = () => {
        FETCH_PROGRAM_CONVERSIONS_REQUEST({
            id: program.id,
            perPage: conversionsPerPage,
            page: conversionsPage + 1,
        });
    };

    const onConversionsPrev = () => {
        FETCH_PROGRAM_CONVERSIONS_REQUEST({
            id: program.id,
            perPage: conversionsPerPage,
            page: conversionsPage - 1,
        });
    };

    const onConversionsPageSelected = (page) => {
        FETCH_PROGRAM_CONVERSIONS_REQUEST({
            id: program.id,
            perPage: conversionsPerPage,
            page,
        });
    };

    const onActivityPerPageChange = (perPage) => {
        FETCH_PROGRAM_ACTIVITY_REQUEST({
            programId: program.id,
            perPage,
            page: 1,
        });
    };

    const onActivityNext = () => {
        FETCH_PROGRAM_ACTIVITY_REQUEST({
            programId: program.id,
            perPage: activityPerPage,
            page: activityPage + 1,
        });
    };

    const onActivityPrev = () => {
        FETCH_PROGRAM_ACTIVITY_REQUEST({
            programId: program.id,
            perPage: activityPerPage,
            page: activityPage - 1,
        });
    };

    const onActivityPageSelected = (page) => {
        FETCH_PROGRAM_ACTIVITY_REQUEST({
            programId: program.id,
            perPage: activityPerPage,
            page,
        });
    };

    useEffect(() => {
        if (program?.id) {
            FETCH_PROGRAM_CONVERSIONS_REQUEST({ id: program.id });
            FETCH_PROGRAM_ACTIVITY_REQUEST({ programId: program.id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [program]);

    return (
        <Tabs
            sectionNames={[
                'Overview',
                'Settings',
                'Conversions',
                'Activity',
                'Share Dialogs',
                'Landing Page',
            ]}
            sectionContents={[
                <ProgramOverview program={program} brandsSet={brandsSet} />,
                <ProgramEdit
                    program={program}
                    brandOptions={brandOptions}
                    onConfirm={onConfirm}
                />,
                <ProgramConversions
                    conversions={conversions}
                    currentPage={conversionsPage}
                    perPage={conversionsPerPage}
                    totalPages={conversionsTotalPages}
                    total={conversionsTotal}
                    onPerPageSelected={onConversionsPerPageSelected}
                    onPageSelected={onConversionsPageSelected}
                    onNext={onConversionsNext}
                    onPrev={onConversionsPrev}
                    onApprove={onApproveConversion}
                    isLoading={isLoadingConversions}
                />,
                <ActivityRecord
                    activities={activity}
                    page={activityPage}
                    perPage={activityPerPage}
                    totalPages={activityTotalPages}
                    onPerPageChange={onActivityPerPageChange}
                    onPrev={onActivityPrev}
                    onNext={onActivityNext}
                    onPageSelected={onActivityPageSelected}
                    showPrograms={false}
                    title="All Activity for this Program"
                    isLoading={isLoadingActivity}
                />,
                <ShareDialogs
                    program={program}
                    isSaving={isLoading}
                    onUploadImage={onUploadImage}
                />,
                <LandingPage program={program} isSaving={isLoading} />,
            ]}
            selectedTab={selectedTab}
            onTabChange={(selected) => {
                setSelectedTab(selected);
            }}
        />
    );
});

export default ProgramsDetail;
