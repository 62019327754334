/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import useActions from 'hooks/useActions';
import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import FlagsTable from './FlagsTable';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import { useParams } from 'react-router-dom';
import {SORTING_OPTIONS,STATUS_OPTIONS} from 'constants/COMMUNITY_STATUS';


const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        flags: state.CSQuestions.flagsSelectedQuestion,
        isLoading: state.CSQuestions.isLoadingFlags,
        per_page: state.CSQuestions.flagsPerPage,
        page: state.CSQuestions.flagsPage,
        total: state.CSQuestions.flagsTotal,
        total_pages: state.CSQuestions.flagsTotalPages,
        sortingFilter: state.CSQuestions.flagsOrder,
        statusFilter: state.CSQuestions.flagsStatus,
    };
}

const CSQuestionFlags = connect(mapStateToProps)(function (props) {
    const {
        flags,
        isLoading,
        per_page,
        page,
        total,
        total_pages,
        sortingFilter,
        statusFilter,
    } = props;
    const {
        CS_FETCH_QUESTION_FLAGS_REQUEST,
        CS_SELECT_QUESTION_FLAGS_ORDER_FILTER,
        CS_SELECT_QUESTION_FLAGS_STATUS_FILTER,
        CS_UPDATE_QUESTION_FLAGS_REASON_REQUEST
    } = useActions();

    const { id } = useParams();

    useEffect(() => {
        CS_FETCH_QUESTION_FLAGS_REQUEST({
            questionId: id,
            page,
            per_page,
            sorting: sortingFilter,
            status: statusFilter,
        });
    }, []);

    useEffect(() => {
        CS_FETCH_QUESTION_FLAGS_REQUEST({
            questionId: id,
            page,
            per_page,
            sorting: sortingFilter,
            status: statusFilter,
        });
    }, [sortingFilter,statusFilter]);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Statuses'},
                    ...STATUS_OPTIONS,
                ]}
                selectedValue={statusFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_FLAGS_STATUS_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    ...SORTING_OPTIONS,
                ]}
                selectedValue={sortingFilter}
                onSelect={(value) =>
                    CS_SELECT_QUESTION_FLAGS_ORDER_FILTER(value)
                }
            />,
        ]
    }

    const onNextPage = () => {
        CS_FETCH_QUESTION_FLAGS_REQUEST({
            questionId: id,
            per_page,
            page: page + 1,
            sorting: sortingFilter,
            status: statusFilter,
        });
    }

    const onPrevPage = () => {
        CS_FETCH_QUESTION_FLAGS_REQUEST({
            questionId: id,
            per_page,
            page: page - 1,
            sorting: sortingFilter,
            status: statusFilter,
        });
    }
    const onPageSelect = (pageSelected) => {
        CS_FETCH_QUESTION_FLAGS_REQUEST({
            questionId: id,
            per_page,
            page: pageSelected,
            sorting: sortingFilter,
            status: statusFilter,
        });
    }

    const onPerPageSelected = ({ perPage }) => {
        CS_FETCH_QUESTION_FLAGS_REQUEST({
            questionId: id,
            per_page: perPage,
            page: page,
            sorting: sortingFilter,
            status: statusFilter,
        });
    }

    const handleChangeReason = (body) => {
        CS_UPDATE_QUESTION_FLAGS_REASON_REQUEST({
            body,
            questionId: id,
            per_page,
            page: page,
            sorting: sortingFilter,
            status: statusFilter,
        })
    }


    return (
        <section>
            <InformationTab
                sectionName="Flags"
                customFilters={filters()}
                dataTable={
                    <div>
                        <TableResults results={total} />
                        <FlagsTable
                            flags={flags}
                            isLoading={isLoading}
                            handleChangeReason={handleChangeReason}
                        />
                        <TableNavigator
                            currentPage={page}
                            totalPages={total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </div>
                }
                showPrograms={false}
                perPage={per_page}
                onPerPageSelected={onPerPageSelected}
                showBreadcrumbs={false}
            />
        </section>
    );
});

export default CSQuestionFlags;
