import useActions from 'hooks/useActions';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
    const { POST_EMAIL_REQUEST } = useActions();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    function goAuthCode() {
        navigate(`/authenticate`);
    }

    function onSend(event) {
        event.preventDefault();
        POST_EMAIL_REQUEST({ email, redirect: goAuthCode });
    }

    return (
        <section>
            <div className="text-3xl mb-8">Please Log In</div>
            <form onSubmit={onSend} style={{ maxWidth: '400px' }}>
                <input
                    type='email'
                    className="mb-4"
                    required
                    placeholder="Enter Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
                <div className="mb-4">
                    We'll send you an access code to log in, or click on the
                    link in your email.
                </div>
                <button type="submit" className="mt-4 button save-changes-button">
                    Email My Login Code
                </button>
            </form>
        </section>
    );
}

export default Login;
