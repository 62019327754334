import ACTION_TYPES from 'constants/ACTION_TYPES';

const initialState = {
    meetings: [],
    isLoadingMeetings: false,
    isLoadingMeetingDetails: false,
    page: 1,
    per_page: 30,
    total_pages: 1,
    total: 0,
    selectedMeeting: null,
    meeting_invitation: null,
    locationFilter: '1',
    meetingFilter: 'all'
};

function VHSMeetingReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_LOCATION_MEETINGS_REQUEST:{
            return {
                ...state,
                isLoadingMeetings: true,
            };
        }
        case ACTION_TYPES.VHS_FETCH_LOCATION_MEETINGS_RESPONSE: {
            return {
                ...state,
                meetings: action.data.meetings,
                per_page: action.data.page_size,
                total: action.data.total_records,
                isLoadingMeetings: false,
                total_pages: action.data.page_count,
                page: action.data.page_number,
            }
        }
        case ACTION_TYPES.VHS_FETCH_LOCATION_MEETINGS_RESPONSE_FAILURE: {
            return {
                ...state,
                isLoadingMeetings: false,
                meetings:[],
                total: 0,
                total_pages: 0
            }
        }
        case ACTION_TYPES.VHS_SELECT_MEETINGS_LOCATION_FILTER: {
            return {
                ...state,
                locationFilter: action.data,
            };
        }
        case ACTION_TYPES.VHS_FETCH_MEETING_INVITE_REQUEST: {
            return {
                ...state,
                isLoadingMeetingDetails: true
            }
        }
        case ACTION_TYPES.VHS_FETCH_MEETING_INVITE_RESPONSE: {
            return {
                ...state,
                meeting_invitation: action.data.meetingInvitation.invitation,
                isLoadingMeetingDetails: false,
            }
        }
        case ACTION_TYPES.VHS_SELECT_MEETING: {
            return {
                ...state,
                selectedMeeting: action.data,
            }
        }
        case ACTION_TYPES.VHS_SELECT_MEETINGS_FILTER: {
            return {
                ...state,
                meetingFilter: action.data,
            }
        }
        default: {
            return state;
        }
    }
}

export default VHSMeetingReducer;
