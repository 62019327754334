import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import useActions from 'hooks/useActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import NewProgram from './NewProgram';
import ProgramsDetail from './ProgramsDetail';
import LinkButton from 'components/LinkButton';

const mapStateToProps = (state) => {
    return {
        selectedProgram: state.programs.selectedProgram,
        programs: state.programs.list,
        total: state.programs.total,
        total_pages: state.programs.total_pages,
        page: state.programs.page,
        per_page: state.programs.per_page,
        brandOptions: state.brands.brandOptions,
        isLoading: state.programs.isLoadingPrograms,
    };
};

const Programs = connect(mapStateToProps)(function (props) {
    const {
        programs,
        selectedProgram,
        brandOptions,
        total_pages,
        total,
        page,
        per_page,
        isLoading,
    } = props;
    const [subtitle, setSubtitle] = useState(null);
    const [detailComponent, setDetailComponent] = useState(null);
    const [brandsSet, setBrandsSet] = useState({});
    const {
        POST_PROGRAM_REQUEST,
        SELECT_PROGRAM,
        FETCH_PROGRAMS_REQUEST,
        FETCH_PROGRAM_REQUEST,
    } = useActions();

    const navigate = useNavigate();

    const { id } = useParams();

    const onDetailSelection = (program) => {
        setSubtitle(null);
        setDetailComponent(null);
        SELECT_PROGRAM(program);
        if (program) {
            navigate(`/referrals/programs/${program.id}`);
        } else {
            FETCH_PROGRAMS_REQUEST({ per_page, page });
            navigate(`/referrals/programs`);
        }
    };

    const onConfirmNew = (program) => {
        POST_PROGRAM_REQUEST(program);
    };

    const onAdd = () => {
        setSubtitle('New Program');
        setDetailComponent(
            <NewProgram brandOptions={brandOptions} onConfirm={onConfirmNew} />
        );
    };

    const onNextPage = () => {
        FETCH_PROGRAMS_REQUEST({ per_page, page: page + 1 });
    };

    const onPrevPage = () => {
        FETCH_PROGRAMS_REQUEST({ per_page, page: page - 1 });
    };

    const onPageSelect = (pageSelected) => {
        FETCH_PROGRAMS_REQUEST({ per_page, page: pageSelected });
    };

    const onPerPageSelected = ({ perPage }) => {
        FETCH_PROGRAMS_REQUEST({ per_page: perPage });
    };

    useEffect(() => {
        if (selectedProgram) {
            setSubtitle(selectedProgram?.name);
            setDetailComponent(<ProgramsDetail brandsSet={brandsSet} />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedProgram, brandsSet]);

    useEffect(() => {
        const populateBrandsSet = () => {
            const setObject = {};
            brandOptions.forEach((brand) => {
                if (brand) {
                    setObject[brand.value] = brand.label;
                }
            });
            setBrandsSet(setObject);
        };
        populateBrandsSet();
    }, [brandOptions]);

    useEffect(() => {
        if (id) {
            try {
                const intId = Number.parseInt(id);
                FETCH_PROGRAM_REQUEST(intId);
            } catch (err) {
                console.log(err);
            }
        } else {
            SELECT_PROGRAM(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        FETCH_PROGRAMS_REQUEST({ per_page });
        return () => {
            SELECT_PROGRAM(null);
            setSubtitle(null);
            setDetailComponent(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataTable = () => {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Program" onAdd={onAdd}/>
                <LoadingTable
                    isLoading={isLoading}
                    className="table-fixed w-full text-left"
                >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Brand</th>
                            <th>Credit</th>
                            <th>Discount</th>
                            <th>Approval</th>
                            <th>Enabled</th>
                            <th>See Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {programs
                            .filter((notNull) => notNull)
                            .map((program) => (
                                <tr key={`program-${program.id}`}>
                                    <td>
                                        <SelectableField
                                            url={`/referrals/programs/${program.id}`}
                                            action={() =>
                                                onDetailSelection(program)
                                            }
                                            text={program.name}
                                        />
                                    </td>
                                    <td>
                                        {brandsSet[program.brand_ident]}
                                    </td>
                                    <td>{program.credit}</td>
                                    <td>{program.discount}</td>
                                    <td>
                                        {program.is_auto_approved
                                            ? 'Auto-Approve'
                                            : 'Require Approval'}
                                    </td>
                                    <td>
                                        {program.is_enabled
                                            ? 'Enabled'
                                            : 'Disabled'}
                                    </td>
                                    <td>
                                        <LinkButton
                                            className="table-view-button"
                                            action={() => navigate(`/referrals/programs/${program.id}`)}
                                            >
                                        See Details
                                        </LinkButton>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                />
            </section>
        );
    };

    return (
        <section>
            <InformationTab
                sectionName="Programs"
                showPrograms={false}
                dataTable={dataTable()}
                onDetailSelected={onDetailSelection}
                detailComponent={detailComponent}
                subsectionName={subtitle}
                onPerPageSelected={onPerPageSelected}
                perPage={per_page}
            />
        </section>
    );
});

export default Programs;
