import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useEffect } from 'react';
import PESequenceEdit from '../SequenceEdit';
import PESequencesOverview from '../SequencesOverview';
import PESequenceTags from '../SequenceTags';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        sequence: state.PESequences.selectedSequence,
        selectedRegionFilter: state.user.selectedRegion,
    };
}

const PESequenceDetails = connect(mapStateToProps)(function (props) {
    const { sequence, selectedRegionFilter } = props;

    const {
        PE_FETCH_SEQUENCE_TAG_GROUPS_REQUEST,
        PE_GET_SEQUENCE_TASKS_REQUEST,
    } = useActions();

    useEffect(() => {
        PE_FETCH_SEQUENCE_TAG_GROUPS_REQUEST();
        PE_GET_SEQUENCE_TASKS_REQUEST(sequence.id);
        // eslint-disable-next-line
    }, [selectedRegionFilter]);

    return (
        <Tabs
            sectionNames={['Overview', 'Settings', 'Tags']}
            sectionContents={[
                <PESequencesOverview />,
                <PESequenceEdit sequence={sequence} />,
                <PESequenceTags />,
            ]}
        />
    );
});

export default PESequenceDetails;
