import SERVICE_TYPES from 'constants/SERVICE_TYPES';
import ACTION_TYPES from 'constants/ACTION_TYPES';
import { select, put } from 'redux-saga/effects';
import api from '../api/api';

function* getTagGroupOptions(apiUrl) {
    const json = yield api.get(`/community/tag_groups?per_page=1000`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_ALL_TAG_GROUPS_RESPONSE,
            data: json.results,
        });
    }
}

function* fetchTagGroups(page = 1, per_page = 30, apiUrl, regionIdent) {

    const getSelectedRegion = state => state.user.selectedRegion;
    const selectedRegion = yield select(getSelectedRegion);
    const regionToFilter = (regionIdent) ? regionIdent : selectedRegion;

    const json = yield api.get(`/community/tag_groups?page=${page}&per_page=${per_page}`, apiUrl, regionToFilter, SERVICE_TYPES.COMMUNITY);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_FETCH_TAG_GROUPS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                total_pages: json.total_pages,
                total: json.total,
                per_page: json.per_page,
            },
        });
    }
}

function* getTagGroup(tagGroupId, apiUrl) {
    const json = yield api.get(`/community/tag_groups/${tagGroupId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_TAG_GROUP_RESPONSE,
            data: json,
        });
    }
}

function* getTag(tagId, apiUrl) {
    const json = yield api.get(`/community/tags/${tagId}`, apiUrl);
    if (json) {
        yield put({ type: ACTION_TYPES.CS_TAG_RESPONSE, data: json });
    }
}

function* postTagGroup(tagGroup, apiUrl) {
    const json = yield api.post('/community/tag_groups', tagGroup, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_TAG_GROUP_RESPONSE,
            data: json,
        });
        yield getTagGroupOptions(apiUrl);
    }
}

function* postTag(tag, apiUrl) {
    const json = yield api.post('/community/tags', tag, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_POST_TAG_RESPONSE,
            data: json,
        });
    }
}

function* updateTagGroup(tagGroup, apiUrl) {
    const json = yield api.put(
        `/community/tag_groups/${tagGroup.id}`,
        tagGroup,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_TAG_GROUP_RESPONSE,
            data: json.data,
        });
        yield getTagGroupOptions(apiUrl);
    }
}

function* updateTag(tag, apiUrl) {
    const json = yield api.put(`/community/tags/${tag.id}`, tag, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_UPDATE_TAG_RESPONSE,
            data: json,
        });
    }
}

function* deleteTagGroup(tagGroupId, apiUrl) {
    const json = yield api.del(`/community/tag_groups/${tagGroupId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_SELECT_TAG_GROUP,
            data: null,
        });
        yield fetchTagGroups(1, 30, apiUrl);
    }
}

function* deleteTag(tagId, apiUrl) {
    const json = yield api.del(`/community/tags/${tagId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.CS_SELECT_TAG,
            data: null,
        });
        yield fetchTagGroups(1, 10, apiUrl);
    }
}

function* csTagsWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.CS_FETCH_ALL_TAG_GROUPS_REQUEST:
            yield getTagGroupOptions(apiUrl);
            break;
        case ACTION_TYPES.CS_FETCH_TAG_GROUPS_REQUEST:
            yield fetchTagGroups(
                action.data?.page,
                action.data?.per_page,
                apiUrl,
                action.data?.regionIdent,
            );
            break;
        case ACTION_TYPES.CS_POST_TAG_GROUP_REQUEST:
            yield postTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_POST_TAG_REQUEST:
            yield postTag(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_UPDATE_TAG_GROUP_REQUEST:
            yield updateTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_UPDATE_TAG_REQUEST:
            yield updateTag(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_TAG_GROUP_REQUEST:
            yield getTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_TAG_REQUEST:
            yield getTag(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_DELETE_TAG_GROUP_REQUEST:
            yield deleteTagGroup(action.data, apiUrl);
            break;
        case ACTION_TYPES.CS_DELETE_TAG_REQUEST:
            yield deleteTag(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default csTagsWorker;
