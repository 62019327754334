import InformationTab from 'components/InformationTab';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import VHS_EVENTS_ACTIONS from 'constants/VHS_EVENTS_ACTIONS';
import useActions from 'hooks/useActions';
import useVhsAnalyticsChartData from 'hooks/useVhsAnalyticsChartData';
import { useEffect, useState } from 'react';
import AnalyticsTable from 'routing/vhs/Analytics/AnalyticsTable';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        activities: state.vhsInstructors.activities,
        isLoading: state.vhsInstructors.isLoadingActivity,
        activities_page: state.vhsInstructors.activities_page,
        activities_per_page: state.vhsInstructors.activities_per_page,
        activities_total: state.vhsInstructors.activities_total,
        activities_total_pages: state.vhsInstructors.activities_total_pages,
        selectedInstructor: state.vhsInstructors.selectedInstructor,
        actionsFilter: state.vhsInstructors.actionsFilter,
        chartData: state.vhsInstructors.chartData,
        periodFilter: state.vhsInstructors.periodFilter,
    };
}

const InstructorAnalytics = connect(mapStateToProps)(function (props) {
    const {
        activities,
        isLoading,
        activities_total,
        activities_page,
        activities_per_page,
        activities_total_pages,
        selectedInstructor,
        actionsFilter,
        chartData,
        periodFilter,
    } = props;

    const {
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST,
        VHS_SELECT_INSTRUCTOR_ACTIONS_FILTER,
        VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_REQUEST,
        VHS_SELECT_INSTRUCTOR_PERIOD_FILTER,
    } = useActions();
    const [parsedChartData, loadParsedChartData] = useVhsAnalyticsChartData();

    const [sectionName, setSectionName] = useState('');
    const [chartName, setChartName] = useState('');

    const onNextPage = () => {
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST({
            per_page: activities_per_page,
            page: activities_page + 1,
            instructorId: selectedInstructor.id,
            actions: actionsFilter,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST({
            per_page: activities_per_page,
            page: activities_page - 1,
            instructorId: selectedInstructor.id,
            actions: actionsFilter,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST({
            per_page: activities_per_page,
            page: pageSelected,
            instructorId: selectedInstructor.id,
            actions: actionsFilter,
        });
    };

    const onPerPageSelected = (perPage) => {
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST({
            per_page: perPage,
            instructorId: selectedInstructor.id,
            actions: actionsFilter,
        });
    };

    const onPeriodSelected = ({ period }) => {
        VHS_SELECT_INSTRUCTOR_PERIOD_FILTER(period);
        VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_REQUEST({
            period,
            actions: actionsFilter,
            instructorId: selectedInstructor?.id,
        });
        const label = VHS_EVENTS_ACTIONS.find(
            (act) => act.value === actionsFilter
        )?.label;
        setChartName(
            `${
                label === 'All' ? 'All Actions' : label
            } in the last ${period} Days`
        );
    };

    useEffect(() => {
        loadParsedChartData(chartData, actionsFilter);
        // eslint-disable-next-line
    }, [chartData]);

    useEffect(() => {
        VHS_FETCH_INSTRUCTOR_ACTIONS_REQUEST({
            per_page: activities_per_page,
            page: activities_page,
            instructorId: selectedInstructor.id,
            actions: actionsFilter,
        });
        VHS_FETCH_INSTRUCTOR_ACTIVITY_CHART_REQUEST({
            period: periodFilter,
            actions: actionsFilter,
            instructorId: selectedInstructor?.id,
        });
        const label = VHS_EVENTS_ACTIONS.find(
            (act) => act.value === actionsFilter
        )?.label;
        setChartName(
            `${
                label === 'All' ? 'All Actions' : label
            } in the last ${periodFilter} Days`
        );
        setSectionName(label === 'All' ? 'All Actions' : label);
        // eslint-disable-next-line
    }, [actionsFilter]);

    return (
        <section>
            <InformationTab
                showPrograms={false}
                sectionName={sectionName}
                chartName={chartName}
                perPage={activities_per_page}
                onPerPageSelected={onPerPageSelected}
                customFilters={[
                    <SingleFilter
                        selectedValue={actionsFilter}
                        options={VHS_EVENTS_ACTIONS}
                        onSelect={(value) =>
                            VHS_SELECT_INSTRUCTOR_ACTIONS_FILTER(value)
                        }
                    />,
                ]}
                dataTable={[
                    <section>
                        <TableResults results={activities_total} />
                        <AnalyticsTable
                            activities={activities}
                            isLoading={isLoading}
                        />
                        <TableNavigator
                            currentPage={activities_page}
                            totalPages={activities_total_pages}
                            onNext={onNextPage}
                            onPrev={onPrevPage}
                            onPageSelected={onPageSelect}
                        />
                    </section>,
                ]}
                hasChart
                chartData={parsedChartData}
                selectedPeriod={periodFilter}
                onPeriodSelected={onPeriodSelected}
                showBreadcrumbs={false}
            />
        </section>
    );
});

export default InstructorAnalytics;
