import viewsWorker from '../workers/viewsWorker';

const { takeEvery } = require('@redux-saga/core/effects');
const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');

function* VIEWS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.FETCH_VIEWS_REQUEST,
            ACTION_TYPES.FETCH_VIEW_REQUEST,
            ACTION_TYPES.FETCH_VIEW_ACTIVITY_REQUEST,
            ACTION_TYPES.FETCH_VIEWS_CHART_REQUEST,
        ],
        viewsWorker,
        apiUrl,
        ...params
    );
}

export default VIEWS_REQUEST;
