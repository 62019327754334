import Tabs from 'components/Tabs';
import useActions from 'hooks/useActions';
import { useState } from 'react';
import VHSProgramOverview from './ProgramOverview';
import VHSProgramEdit from './ProgramEdit';
import VHSProgramPlanning from './ProgramPlanning';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        selectedProgram: state.vhsPrograms.selectedProgram,
    };
}

const ProgramDetails = connect(mapStateToProps)(function (props) {
    const { selectedProgram } = props;
    const { VHS_UPDATE_PROGRAM_REQUEST } = useActions();

    const [selectedTab, setSelectedTab] = useState(0);

    const onSave = (updated) => {
        VHS_UPDATE_PROGRAM_REQUEST({...updated});
        setSelectedTab(0);
    };

    return (
        <section>
            <Tabs
                sectionNames={['Overview', 'Settings', 'Planning']}
                sectionContents={[
                    <VHSProgramOverview program={selectedProgram} />,
                    <VHSProgramEdit program={selectedProgram} onSave={onSave} />,
                    <VHSProgramPlanning program={selectedProgram} />
                ]}
                selectedTab={selectedTab}
                onTabChange={(selection) => setSelectedTab(selection)}
            />
        </section>
    );
});

export default ProgramDetails;
