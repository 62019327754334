import { useState } from 'react';

function useVhsAnalyticsChartData() {
    const [chartData, setChartData] = useState([]);

    function loadChartData(unparsedChartData, filter = 'all') {
        const arr = [];
        if (unparsedChartData) {
            for (let key in unparsedChartData[0]) {
                if (key !== 'name' && (key === filter || filter === 'all')) {
                    const subArray = [];
                    for (let day of unparsedChartData) {
                        subArray.push({
                            name: day.name,
                            value: day[key],
                            type: key,
                        });
                    }
                    arr.push(subArray);
                }
            }
        }
        setChartData(arr);
    }

    return [chartData, loadChartData];
}

export default useVhsAnalyticsChartData;
