import DateField from 'components/DateField';
import InformationTab from 'components/InformationTab';
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import SingleFilter from 'components/SingleFilter';
import TableNavigator from 'components/TableNavigator';
import TableResults from 'components/TableResults';
import { VISIBILITY_OPTIONS } from 'constants/VISIBILITY_OPTIONS';
import useActions from 'hooks/useActions';
import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import VHSInstructorDetails from './InstructorDetails';
import InstructorNew from './InstructorNew';
import useQueryList from 'hooks/useQuery';
import AlertModal from 'components/Modal/AlertModal';


function mapStateToProps(state) {
    return {
        instructors: state.vhsInstructors.instructors,
        isLoading: state.vhsInstructors.isLoadingInstructors,
        page: state.vhsInstructors.page,
        per_page: state.vhsInstructors.per_page,
        total: state.vhsInstructors.total,
        total_pages: state.vhsInstructors.total_pages,
        selectedInstructor: state.vhsInstructors.selectedInstructor,
        brandsOptions: state.vhsBrands.brandsOptions,
        seriesOptions: state.vhsSeries.seriesOptions,
        brandsFilter: state.vhsInstructors.brandsFilter,
        visibilityFilter: state.vhsInstructors.visibilityFilter,
    };
}

const VHSInstructors = connect(mapStateToProps)(function (props) {
    const {
        instructors,
        page,
        per_page,
        total_pages,
        total,
        selectedInstructor,
        brandsOptions,
        brandsFilter,
        visibilityFilter,
        isLoading,
    } = props;

    const navigate = useNavigate();
    const {
        VHS_FETCH_INSTRUCTORS_REQUEST,
        VHS_SELECT_INSTRUCTOR,
        VHS_FETCH_INSTRUCTOR_REQUEST,
        VHS_DELETE_INSTRUCTOR_REQUEST,
        VHS_SELECT_INSTRUCTOR_VISIBILITY_FILTER,
        VHS_SELECT_INSTRUCTOR_BRANDS_FILTER,
        SET_MODAL_CONTENT,
        SET_MODAL_STYLE,
        TOGGLE_MODAL
    } = useActions();
    const { id } = useParams();
    const { term: searchTerm }  = useQueryList(['term']);

    const [subtitle, setSubtitle] = useState('');
    const [detailComponent, setDetailComponent] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    const onDetailSelection = (instructor) => {
        setSubtitle(null);
        setDetailComponent(null);
        VHS_SELECT_INSTRUCTOR(instructor);
        // setShowDelete(true);
        if (instructor) {
            navigate(`/vhs/instructors/${instructor.id}`);
        } else {
            VHS_FETCH_INSTRUCTORS_REQUEST({
                visibility: visibilityFilter,
                brand: brandsFilter,
                per_page,
                page,
                searchTerm,
            });
            navigate(`/vhs/instructors`);
        }
    };

    function onDeleteAction() {
        VHS_DELETE_INSTRUCTOR_REQUEST(selectedInstructor?.id);
        navigate('/vhs/instructors');
    }

    function onDeleteDetail() {
        SET_MODAL_CONTENT(
            <AlertModal onDeleteAction={onDeleteAction} title="Do you want to delete this Instructor?"/>
        )
        SET_MODAL_STYLE({alertStyle: true})
        TOGGLE_MODAL(true);
    }

    function onAdd() {
        setSubtitle('Add New Instructor');
        setShowDelete(false);
        setDetailComponent(<InstructorNew />);
    }

    const onNextPage = () => {
        VHS_FETCH_INSTRUCTORS_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page,
            page: page + 1,
            searchTerm,
        });
    };

    const onPrevPage = () => {
        VHS_FETCH_INSTRUCTORS_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page,
            page: page - 1,
            searchTerm,
        });
    };

    const onPageSelect = (pageSelected) => {
        VHS_FETCH_INSTRUCTORS_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page,
            page: pageSelected,
            searchTerm,
        });
    };

    const onPerPageSelected = ({ perPage }) => {
        VHS_FETCH_INSTRUCTORS_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            per_page: perPage,
            searchTerm,
        });
    };

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    useEffect(() => {
        if (selectedInstructor) {
            setSubtitle(selectedInstructor?.name);
            setDetailComponent(<VHSInstructorDetails />);
        } else {
            setDetailComponent(null);
        }
    }, [selectedInstructor]);

    useEffect(() => {
        if (id) {
            try {
                VHS_FETCH_INSTRUCTOR_REQUEST(id);
            } catch (err) {
                console.log(err);
            }
        } else {
            VHS_SELECT_INSTRUCTOR(null);
            setSubtitle(null);
            setDetailComponent(null);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        VHS_FETCH_INSTRUCTORS_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            searchTerm,
        });
        // eslint-disable-next-line
    }, [visibilityFilter, brandsFilter]);

    useEffect(() => {
        VHS_FETCH_INSTRUCTORS_REQUEST({
            visibility: visibilityFilter,
            brand: brandsFilter,
            searchTerm,
        });

        // eslint-disable-next-line
    }, []);

    function filters() {
        return [
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Visibility' },
                    ...VISIBILITY_OPTIONS,
                ]}
                selectedValue={visibilityFilter}
                onSelect={(value) =>
                    VHS_SELECT_INSTRUCTOR_VISIBILITY_FILTER(value)
                }
            />,
            <SingleFilter
                options={[
                    { value: 'all', label: 'All Visibility' },
                    ...brandsOptions,
                ]}
                selectedValue={brandsFilter}
                onSelect={(value) => VHS_SELECT_INSTRUCTOR_BRANDS_FILTER(value)}
            />,
        ];
    }

    function dataTable() {
        return (
            <section>
                <TableResults results={total} addTitle="Add New Instructor" onAdd={onAdd}/>
                <LoadingTable
                    isLoading={isLoading}
                    tableClass="table-fixed w-full text-left"
                >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Photo</th>
                            <th>Email</th>
                            <th>Favorite Barre Move</th>
                            <th>Visibility</th>
                            <th>Start Date</th>
                            <th>Brand</th>
                        </tr>
                    </thead>
                    <tbody>
                        {instructors.map((instructor) => (
                            <tr key={`instructor-${instructor.id}`}>
                                <td>
                                    <SelectableField
                                        text={instructor.name}
                                        url={`/vhs/instructors/${instructor.id}`}
                                    />
                                </td>
                                <td className="flex items-center justify-center">
                                    <img
                                        alt={instructor.name}
                                        src={instructor.photo_url}
                                        style={{
                                            maxHeight: 80,
                                            maxWidth: '100%',
                                        }}
                                    />
                                </td>
                                <td className="email">{instructor.email}</td>
                                <td>{instructor.favorite}</td>
                                <td>{instructor.visibility}</td>
                                <td>
                                    <DateField
                                        timeMillis={instructor.start_int}
                                    />
                                </td>
                                <td>
                                    {brandsSet[instructor.brand_ident]}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </LoadingTable>
                <TableNavigator
                    currentPage={page}
                    totalPages={total_pages}
                    onNext={onNextPage}
                    onPrev={onPrevPage}
                    onPageSelected={onPageSelect}
                />
            </section>
        );
    }

    return (
        <section>
            <InformationTab
                sectionName="Instructors"
                showPrograms={false}
                subsectionName={subtitle}
                onPerPageSelected={onPerPageSelected}
                perPage={per_page}
                dataTable={dataTable()}
                detailComponent={detailComponent}
                onDetailSelected={onDetailSelection}
                deleteText="Instructor"
                onDeleteDetail={onDeleteDetail}
                showDelete={showDelete}
                customFilters={filters()}
                showSearchField={true}
            />
        </section>
    );
});

export default VHSInstructors;
