import useSetFromOptions from 'hooks/useSetFromOptions';
import { useEffect } from 'react';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        tagGroups: state.PETags.tagGroups,
        brandsOptions: state.PEBrands.brandsOptions,
    };
}

const TagsModalContent = connect(mapStateToProps)(function (props) {
    const { tagGroups, brandsOptions, onSelect = function () {} } = props;
    const [brandsSet, setBrandsSet] = useSetFromOptions();

    useEffect(() => {
        setBrandsSet(brandsOptions);
        // eslint-disable-next-line
    }, [brandsOptions]);

    function TableSection({ tagGroup, brandsSet = {} }) {
        return (
            <section className="mt-8 mb-8">
                <div className="flex items-center relative">
                    <span className="text-lg mr-4">{tagGroup.name}</span>
                    <span className="font-bold bg-gray-400">
                        {brandsSet[tagGroup.brand_ident]}
                    </span>
                </div>
                <div className="mt-4 tags-container-border tags-bg p-4 pt-2 pl-2 flex flex-wrap">
                    {tagGroup.tags &&
                        tagGroup.tags.map((tag) => (
                            <button
                                key={`tag-${tag.id}`}
                                className="blue-button-color tags-border p-2 h-10 flex items-center justify-center text-white ml-2 mt-2"
                                onClick={() => onSelect(tag)}
                            >
                                {tag.name}
                            </button>
                        ))}
                </div>
            </section>
        );
    }

    return (
        <section className="p-8">
            {tagGroups.map((tagGroup) => (
                <TableSection
                    key={'tag-group-' + tagGroup.id}
                    tagGroup={tagGroup}
                    brandsSet={brandsSet}
                />
            ))}
        </section>
    );
});

export default TagsModalContent;
