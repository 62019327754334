import {
    ArrowDownIcon,
    ArrowUpIcon,
    XCircleIcon,
} from '@heroicons/react/outline';
import LinkButton from 'components/LinkButton';
import SelectableField from 'components/SelectableField';
import TableButton from 'components/TableButton';
import useActions from 'hooks/useActions';
import PEPhasesModalContent from 'routing/ProgramEngine/Phases/PhasesModalContent';

const { connect } = require('react-redux');

function mapStateToProps(state) {
    return {
        program: state.PEPrograms.selectedProgram,
        phases: state.PEPrograms.phases,
    };
}

const PEProgramOverview = connect(mapStateToProps)(function (props) {
    const { phases, program } = props;

    const {
        PE_UPDATE_PROGRAM_PHASE_REQUEST,
        TOGGLE_MODAL,
        SET_MODAL_CONTENT,
        PE_POST_PROGRAM_PHASE_REQUEST,
        PE_DELETE_PROGRAM_PHASE_REQUEST,
    } = useActions();

    function addPhase(phase) {
        PE_POST_PROGRAM_PHASE_REQUEST({
            programId: program.id,
            phaseId: phase.id,
        });
    }

    function openPhasesModal() {
        SET_MODAL_CONTENT(<PEPhasesModalContent onSelect={addPhase} />);
        TOGGLE_MODAL();
    }

    return (
        <section>
            <div className="flex justify-between items-center mb-4">
                <div className="font-bold text-xl">{phases?.length} Phases</div>
                <button
                    className="button add-button p-2 ml-4 text-white"
                    onClick={openPhasesModal}
                >
                    Add New Phase
                </button>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Name</th>
                            <th>Sequences</th>
                            <th>Move</th>
                            <th>Remove</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {phases.map((phase) => (
                            <tr>
                                <td>{phase.position}</td>
                                <td>
                                    <SelectableField
                                        text={phase.name}
                                        url={`/program-engine/phases/${phase.phase_id}`}
                                    />
                                </td>
                                <td>{phase?.phase?.sequences}</td>
                                <td>
                                    <div className="flex">
                                        {phase.position !== 1 && (
                                            <TableButton
                                                className="order-button"
                                                action={() =>
                                                    PE_UPDATE_PROGRAM_PHASE_REQUEST(
                                                        {
                                                            programPhaseId:
                                                                phase.id,
                                                            programId:
                                                                program.id,
                                                            position:
                                                                phase.position
                                                                    ? phase.position -
                                                                      1
                                                                    : 1,
                                                        }
                                                    )
                                                }
                                            >
                                                <ArrowUpIcon
                                                    height={'1.3rem'}
                                                />
                                            </TableButton>
                                        )}
                                        {
                                            <TableButton
                                                className="order-button"
                                                action={() =>
                                                    PE_UPDATE_PROGRAM_PHASE_REQUEST(
                                                        {
                                                            programPhaseId:
                                                                phase.id,
                                                            programId:
                                                                program.id,
                                                            position:
                                                                phase.position
                                                                    ? phase.position +
                                                                      1
                                                                    : 1,
                                                        }
                                                    )
                                                }
                                            >
                                                <ArrowDownIcon
                                                    height={'1.3rem'}
                                                />
                                            </TableButton>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <TableButton
                                        className="button delete-button"
                                        action={() =>
                                            PE_DELETE_PROGRAM_PHASE_REQUEST({
                                                programPhaseId: phase.id,
                                                programId: program.id,
                                            })
                                        }
                                    >
                                        <XCircleIcon
                                            height={'1.3rem'}
                                            style={{ marginRight: '0.5rem' }}
                                        />
                                        Remove
                                    </TableButton>
                                </td>
                                <td>
                                    <LinkButton
                                        className="table-view-button"
                                        url={`/program-engine/phases/${phase.phase_id}`}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    );
});

export default PEProgramOverview;
