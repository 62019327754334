const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { put } = require('redux-saga/effects');
const { default: api } = require('../api/api');

function* getLocationMeetings(id , state, per_page, page, apiUrl) {
    const json = yield api.get(`/vhs/zoom/meetings/${id}?state=${state}&per_page=${per_page}&page=${page}`, apiUrl);

    if (!json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_LOCATION_MEETINGS_RESPONSE_FAILURE,
        });
        alert(json.message);
    } else {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_LOCATION_MEETINGS_RESPONSE,
            data: json,
        });
    }
}

function* getMeetingInvite(zoomId, apiUrl) {
    const json = yield api.get(`/vhs/zoom/meetings/${zoomId}/invitation`, apiUrl);

    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEETING_INVITE_RESPONSE,
            data: json,
        });
    }
}

function* vhsMeetingsWorker(apiUrl, action) {
    switch(action.type) {
        case ACTION_TYPES.VHS_FETCH_LOCATION_MEETINGS_REQUEST:
            yield getLocationMeetings(
                action.data?.id,
                action.data?.state,
                action.data?.per_page,
                action.data?.page,
                apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_MEETING_INVITE_REQUEST:
            yield getMeetingInvite(action.data, apiUrl);
            break;
        default:
            break;
    }
}

export default vhsMeetingsWorker;
