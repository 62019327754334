import ACTION_TYPES from 'constants/ACTION_TYPES';
import { put } from 'redux-saga/effects';
import api from '../api/api';

function* getMembers(page = 1, per_page = 10, apiUrl,searchTerm = '') {
    let url = `/vhs/members?page=${page}&per_page=${per_page}`;
    if(!!searchTerm){
        url += `&term=${searchTerm}`;
    }
    const json = yield api.get(url,apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEMBERS_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getMember(memberId, apiUrl) {
    const json = yield api.get(`/vhs/members/${memberId}`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEMBER_RESPONSE,
            data: json,
        });
    }
}

function* getCurrentMemberPermissions(apiUrl) {
    const json = yield api.get(`/vhs/members/permissions`, apiUrl);
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEMBER_PERMISSIONS_RESPONSE,
            data: json,
        });
    }
}

function* getActivities(page = 1, per_page = 10, memberId, apiUrl) {
    const json = yield api.get(
        `/vhs/track_events?page=${page}&per_page=${per_page}&member_id=${memberId}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEMBER_ACTIVITY_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getAttended(page = 1, per_page = 10, memberId, apiUrl) {
    const json = yield api.get(
        `/vhs/member_episodes?page=${page}&is_attended=1&per_page=${per_page}&member_id=${memberId}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEMBER_ATTEND_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getFavorited(page = 1, per_page = 10, memberId, apiUrl) {
    const json = yield api.get(
        `/vhs/member_episodes?page=${page}&is_favorited=1&per_page=${per_page}&member_id=${memberId}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEMBER_FAVORITED_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* getHistory(page = 1, per_page = 10, memberId, apiUrl) {
    const json = yield api.get(
        `/vhs/activities?affected_type=Member&affected_id=${memberId}&page=${page}&per_page=${per_page}`,
        apiUrl
    );
    if (json) {
        yield put({
            type: ACTION_TYPES.VHS_FETCH_MEMBER_HISTORY_RESPONSE,
            data: {
                list: json.results,
                page: json.page,
                per_page: json.per_page,
                total_pages: json.total_pages,
                total: json.total,
            },
        });
    }
}

function* vhsMembersWorker(apiUrl, action) {
    switch (action.type) {
        case ACTION_TYPES.VHS_FETCH_MEMBERS_REQUEST:
            yield getMembers(action.data?.page, action.data?.per_page, apiUrl,action.data?.searchTerm);
            break;
        case ACTION_TYPES.VHS_FETCH_MEMBER_REQUEST:
            yield getMember(action.data, apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_MEMBER_PERMISSIONS_REQUEST:
            yield getCurrentMemberPermissions(apiUrl);
            break;
        case ACTION_TYPES.VHS_FETCH_MEMBER_ACTIVITY_REQUEST:
            yield getActivities(
                action.data?.page,
                action.data?.per_page,
                action.data?.memberId,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_MEMBER_ATTEND_REQUEST:
            yield getAttended(
                action.data?.page,
                action.data?.per_page,
                action.data?.memberId,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_MEMBER_FAVORITED_REQUEST:
            yield getFavorited(
                action.data?.page,
                action.data?.per_page,
                action.data?.memberId,
                apiUrl
            );
            break;
        case ACTION_TYPES.VHS_FETCH_MEMBER_HISTORY_REQUEST:
            yield getHistory(
                action.data?.page,
                action.data?.per_page,
                action.data?.memberId,
                apiUrl
            )
            break;
        default:
            break;
    }
}

export default vhsMembersWorker;
