const { default: ACTION_TYPES } = require('constants/ACTION_TYPES');
const { takeEvery } = require('redux-saga/effects');
const { default: peBrandsWorker } = require('../workers/peBrandsWorker');

function* PE_BRANDS_REQUEST(apiUrl, ...params) {
    yield takeEvery(
        [
            ACTION_TYPES.PE_FETCH_BRANDS_OPTIONS_REQUEST,
            ACTION_TYPES.PE_GET_BRAND_REQUEST,
            ACTION_TYPES.PE_FETCH_BRANDS_REQUEST,
            ACTION_TYPES.PE_POST_BRAND_REQUEST,
            ACTION_TYPES.PE_UPDATE_BRAND_REQUEST,
            ACTION_TYPES.PE_DELETE_BRAND_REQUEST,
        ],
        peBrandsWorker,
        apiUrl,
        ...params
    );
}

export default PE_BRANDS_REQUEST;
