/* eslint-disable react-hooks/exhaustive-deps */
import LoadingTable from 'components/LoadingTable';
import SelectableField from 'components/SelectableField';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LinkButton from 'components/LinkButton';


function mapStateToProps(state) {
    return {
     };
}

function AnswersTableRaw({
    answers = [],
    onDetailSelection = null,
    isLoading,
}) {
    const navigate = useNavigate();
    const { id } = useParams();
    return (
        <section>
            <LoadingTable isLoading={isLoading}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Answer</th>
                        <th>Posted By</th>
                        <th>Status</th>
                        <th>Visibility</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {answers.map((answer) => (
                        <tr key={`comment-${answer.id}`}>
                            <td>
                            {answer.poster_url && 
                                <SelectableField
                                    url={`/community/questions/${id}/answers/${answer.id}`}
                                >
                                    <img
                                        src={answer.poster_url}
                                        alt={answer.name}
                                        style={{
                                            maxHeight: '4rem',
                                            maxWidth: '5rem',
                                            minHeight: '4rem',
                                            minWidth: '5rem',
                                        }}
                                    />
                                </SelectableField>
                            }
                            </td>
                            <td>
                                <SelectableField
                                    action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(answer);
                                        }
                                        //navigate(`/community/questions/${id}/answers/${answer.id}`);
                                    }}
                                    text={<strong className='link-black'>{answer.body}</strong>}
                                />
                            </td>
                            <td>
                                <strong>{answer.member.name}</strong>
                            </td>
                            <td>
                                <div className='flex flex-col'>
                                {answer?.is_reel ? <span>Reel</span> : <span>None</span>}
                                {answer?.flags?.length && <span><strong style={{color: 'red'}}>Flagged</strong> 🚩</span>}
                                </div>
                            </td>
                            <td>
                                <span className={`capitalize ${answer.visibility === 'published' && 'font-bold'}`}>{answer.visibility}</span>
                            </td>
                            <td>
                                    <LinkButton
                                        className="table-view-button"
                                        action={() => {
                                        if (onDetailSelection) {
                                            onDetailSelection(answer);
                                        }
                                        navigate(`/community/questions/${id}/answers/${answer.id}`);
                                    }}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                        </tr>
                    ))}
                </tbody>
            </LoadingTable>
        </section>
    );
}

const AnswersTable = connect(mapStateToProps)(AnswersTableRaw);
export default AnswersTable;
